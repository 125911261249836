import { Paper, Stack } from "@mui/material";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";
import Grid from "@mui/material/Unstable_Grid2";
import MUIDate from "../../HOC/MUI/MUIDate";

import React from "react";
import { useRequestContext } from "../Context/RequestContext";
import ListBranches from "../../HOC/ComponentWithSpecificQuery/ListBranches";
import { CustomAutocomplete } from "../../HOC/MUI/CustomAutocomplete";
import {
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
} from "../../../GlobalsQuery/ListDropdown/ListDropdown";

export const Step1 = () => {
  const {
    control,
    errors,
    t,
    disabled,
    dateRange,
    setDateRange,
    setting,
    requestId,
    firstCalcUpdate,
    setValue,
    autocompleteValues,
    watch,
    parseLoadingMode,
  } = useRequestContext();
  return (
    <Stack spacing={2}>
      <Stack direction={{ md: "row" }} gap={2}>
        <Paper
          container
          component={Grid}
          spacing={2}
          m={0}
          width={{ md: "50%" }}
        >
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"code"}
              label={t("requestCode")}
              disabled={disabled.addPackage}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <MUIDate
              name={"requestDate"}
              label={t("requestDate")}
              control={control}
              value={dateRange.requestDate}
              defaultValue={dateRange.requestDate}
              minDate={
                setting?.freightSettings?.createRequestInPast === false &&
                (requestId ? dateRange.requestDate : new Date() - 1)
              }
              onChange={(e) => {
                setDateRange((prev) => ({ ...prev, requestDate: e }));
                firstCalcUpdate.current = false;
              }}
              disabled={disabled.addPackage}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <MUIDate
              name={"shippingDate"}
              label={t("shippingDate")}
              control={control}
              value={dateRange.shippingDate}
              defaultValue={dateRange.shippingDate}
              onChange={(e) => {
                setDateRange((prev) => ({ ...prev, shippingDate: e }));
              }}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <MUIDate
              name={"finishDate"}
              label={t("finishDate")}
              rules={{ required: t("fieldIsRequired") }}
              control={control}
              value={dateRange.finishDate}
              defaultValue={dateRange.finishDate ?? ""}
              onChange={(e) => {
                setDateRange((prev) => ({ ...prev, finishDate: e }));
              }}
            />
          </Grid>
        </Paper>
        <Paper
          container
          component={Grid}
          spacing={2}
          m={0}
          width={{ md: "50%" }}
        >
          <Grid sm={4} xs={12}>
            <ListBranches
              control={control}
              errors={errors}
              name={"branchId"}
              rules={{ required: t("fieldIsRequired") }}
              defaultValue={autocompleteValues.branch}
              onChangeValue={(e) => {
                setValue("transactionTypeId", "");
              }}
              disabled={disabled.addPackage}
            />
          </Grid>
          <Grid sm={4} xs={12}>
            <CustomAutocomplete
              valueKey="code"
              control={control}
              hideCode={true}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              name={"shippingMode"}
              label={t("shippingMode")}
              onChangeValue={(e) => {
                setValue("loadingMode", "");
                setValue("shippingDirection", "");
                setValue("transactionTypeId", "");
                setValue("originPortId", "");
                setValue("destinationPortId", "");
                firstCalcUpdate.current = false;
              }}
              parseData={(data) => data}
              variables={{ input: { code: "FR_SHIPPING_MODE" } }}
              query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
              defaultValue={autocompleteValues?.shippingMode}
              disabled={disabled.addPackage}
            />
          </Grid>
          <Grid sm={4} xs={12}>
            <CustomAutocomplete
              valueKey="code"
              control={control}
              hideCode={true}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              name={"shippingDirection"}
              label={t("shippingDirection")}
              onChangeValue={(e) => {
                setValue("originPortId", "");
                setValue("destinationPortId", "");
                firstCalcUpdate.current = false;
                setValue("transactionTypeId", "");
              }}
              parseData={(data) => data}
              variables={{ input: { code: "FR_SHIPPING_DIRECTION" } }}
              query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
              defaultValue={autocompleteValues?.shippingDirection}
              disabled={disabled.addPackage}
            />
          </Grid>
          <Grid sm={6} xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={Boolean(
                !watch("shippingDirection") ||
                  !watch("shippingMode") ||
                  !watch("branchId") ||
                  disabled.addPackage
              )}
              parseData={(data) => data}
              name={"transactionTypeId"}
              label={t("transactionType")}
              rules={{ required: t("fieldIsRequired") }}
              query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
              skip={Boolean(
                !watch("shippingDirection") ||
                  !watch("shippingMode") ||
                  !watch("branchId")
              )}
              variables={{
                input: {
                  ...(watch("branchId") && {
                    branchId: {
                      value: watch("branchId"),
                      includeNull: true,
                    },
                  }),
                  shippingDirection: watch("shippingDirection"),
                  shippingMode: watch("shippingMode"),
                  type: "RQST",
                },
              }}
              defaultValue={autocompleteValues.transactionType}
            />
          </Grid>
          {/* {watch("shippingMode") !== "AIR" && ( */}
          <Grid sm={6} xs={12}>
            <CustomAutocomplete
              valueKey="code"
              control={control}
              errors={errors}
              rules={{
                required:
                  watch("shippingMode") !== "AIR" && t("fieldIsRequired"),
              }}
              name={"loadingMode"}
              label={t("loadingMode")}
              parseData={(data) => parseLoadingMode(data)}
              variables={{ input: { code: "FR_LOADING_MODE" } }}
              query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
              defaultValue={autocompleteValues?.loadingMode}
              disabled={disabled.addPackage || watch("shippingMode") === "AIR"}
            />
          </Grid>
          {/* )} */}
        </Paper>
      </Stack>
      <Stack p={1} component={Paper}>
        <ControlMUItextField
          control={control}
          errors={errors}
          name={"remarks"}
          label={t("notes")}
          rows={2}
        />
      </Stack>
    </Stack>
  );
};
