import * as gqlb from "gql-query-builder";
const fields = [
  "id",
  "code",
  "scheduledDepartureDate",
  "actualDepartureDate",
  "scheduledArrivalDate",
  "actualArrivalDate",
  "shipmentDate",
  "consigneeName",
  "consigneeAddress",
  "consigneePostalCode",
  "consigneeMobile",
  "shipperName",
  "shipperAddress",
  "shipperPostalCode",
  "shipperMobile",
  // "hasPackages",
  {
    containers: ["id"],
  },
  {
    consigneeState: ["id", "name", "code"],
  },
  {
    shipperState: ["id", "name", "code"],
  },

  {
    branch: ["id", "name", "code"],
  },
  {
    shippingDirection: ["id", "name", "code"],
  },
  {
    shippingMode: ["id", "name", "code"],
  },
  {
    loadingMode: ["id", "name", "code"],
  },
  {
    originCountry: ["id", "name", "code"],
  },
  {
    originPort: ["id", "name", "code"],
  },
  {
    destinationCountry: ["id", "name", "code"],
  },
  {
    destinationPort: ["id", "name", "code"],
  },
  {
    status: ["id", "name", "code"],
  },
  {
    originAgency: ["id", "code", "name"],
  },
  {
    destinationAgency: ["id", "code", "name"],
  },
];
export const LIST_SHIPMENTS = gqlb.query({
  operation: "listShipments",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: fields,
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListShipmentsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});
export const SCHEDULE_SHIPMENTS = gqlb.mutation({
  operation: "schedulingShipment",
  fields: ["id"],
  variables: {
    input: {
      type: "SchedulingShipmentInput",
      required: true,
    },
  },
});

export const TRANSPORTING_SHIPMENTS = gqlb.mutation({
  operation: "transportingShipment",
  fields: ["id"],
  variables: {
    input: {
      type: "TransporingShipmentInput",
      required: true,
    },
  },
});

export const ARRIVED_SHIPMENTS = gqlb.mutation({
  operation: "markShipmentAsArrived",
  fields: ["id"],
  variables: {
    input: {
      type: "MarkShipmentAsArrivedInput",
      required: true,
    },
  },
});
