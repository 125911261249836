import * as React from "react";
import { useTranslation } from "react-i18next";
import { FormHelperText, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

import { LIST_PACKAGE_TYPES_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";

import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useEffect, useState } from "react";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { SAVE_PACKAGE_TYPE_LIST_ROUTE } from "./Graphql";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { usePriceListContext } from "./PriceListContext";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";

export default function PriceListPackageRouts(props) {
  const {
    RouteList,
    routesIndex,
    setRoutesList,
    selectedNames,
    onChangeNames,
    handelCloseDialog,
    packageErrorMessage,
    setPackageErrorMessage,
    packageIndex,
    pricListData,
    dialog,
  } = usePriceListContext();
  const { handleSubmit, setValue, control, setError, errors } = useForm();
  const { t } = useTranslation();

  const [savePackageTypeRoutes, { loading: savePriceListLoad }] = useMutation(
    gql`
      ${SAVE_PACKAGE_TYPE_LIST_ROUTE.query}
    `
  );
  const [autocompleteValues, setAutocompleteValues] = useState({
    packageTypeId: null,
  });
  useEffect(() => {
    if (packageIndex?.update) {
      const packageType =
        RouteList[routesIndex?.index]?.packageTypes[packageIndex?.index];
      setAutocompleteValues({
        packageTypeId: packageType?.packageType,
      });
      setValue("price", packageType?.price);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageIndex]);

  const onSubmit = (data) => {
    for (const key in data) {
      if (
        [undefined, "", null].includes(data[key]) ||
        (Array.isArray(data[key]) && data[key].length === 0)
      ) {
        delete data[key];
      }
    }
    const newRoute = {
      packageType: {
        id: data.packageTypeId,
        name: selectedNames.packageTypeId,
      },
      price: parseFloat(data.price),
    };
    const packageTypes = RouteList[routesIndex?.index]?.packageTypes
      ? [...RouteList[routesIndex?.index]?.packageTypes]
      : [];
    const updateRoutes = RouteList ? [...RouteList] : [];

    if (packageIndex?.update) {
      savePackageTypeRoutes({
        variables: {
          input: {
            id: packageTypes[packageIndex?.index]?.id,
            routeId: RouteList[routesIndex?.index]?.id,
            packageTypeId: newRoute.packageType?.id,
            price: parseFloat(newRoute.price),
          },
        },
      })
        .then((data) => {
          newRoute.id = data.data?.savePackageTypeRoute?.id;
          updateRoutes[routesIndex?.index].packageTypes[packageIndex?.index] =
            newRoute;
          setRoutesList(updateRoutes);
          handelCloseDialog();
        })
        .catch(({ graphQLErrors }) => {
          console.log(graphQLErrors);
          if (graphQLErrors?.[0]?.extensions?.category === "validation") {
            setValidationError(graphQLErrors, setError);
          } else {
            setPackageErrorMessage(graphQLErrors[0]?.message);
          }
        });
    } else {
      savePackageTypeRoutes({
        variables: {
          input: {
            routeId: RouteList[routesIndex?.index]?.id,
            packageTypeId: newRoute.packageType?.id,
            price: parseFloat(newRoute.price),
          },
        },
      })
        .then((data) => {
          newRoute.id = data.data?.savePackageTypeRoute?.id;
          packageTypes.push(newRoute);
          updateRoutes[routesIndex.index] = {
            ...updateRoutes[routesIndex.index],
            packageTypes: packageTypes,
          };
          setRoutesList(updateRoutes);
          handelCloseDialog();
        })
        .catch(({ graphQLErrors }) => {
          console.log(graphQLErrors);
          if (graphQLErrors?.[0]?.extensions?.category === "validation") {
            setValidationError(graphQLErrors, setError);
          } else {
            setPackageErrorMessage(graphQLErrors[0]?.message);
          }
        });
    }
  };

  const parseData = (data) => {
    return data;
  };
  const body = (
    <Grid container justifyContent="flex-start" aligns="center" spacing={2}>
      <Grid xs={12} sm={12} aligns="flex-start">
        <CustomAutocomplete
          rules={{ required: t("fieldIsRequired") }}
          control={control}
          errors={errors}
          name={"packageTypeId"}
          label={t("packageType")}
          parseData={(data) => parseData(data)}
          query={LIST_PACKAGE_TYPES_DROPDOWN.query}
          variables={{
            input: {
              shippingMode: pricListData?.priceList?.shippingMode?.code,
              shippingDirection:
                pricListData?.priceList?.shippingDirection?.code,
            },
          }}
          defaultValue={autocompleteValues.packageTypeId}
          onChangeValue={(e) => {
            onChangeNames(e, "packageTypeId");
          }}
        />
      </Grid>
      <Grid xs={12} sm={12} aligns="flex-start">
        <ControlMUItextField
          control={control}
          errors={errors}
          type={"number"}
          name={"price"}
          label={t("price")}
          rules={{ required: t("fieldIsRequired") }}
        />
      </Grid>
      {packageErrorMessage && (
        <FormHelperText error>{packageErrorMessage}</FormHelperText>
      )}
    </Grid>
  );
  return (
    <CustomDialog
      fullWidth
      maxWidth="xs"
      open={dialog.dialog}
      onClose={handelCloseDialog}
      title={t("packageTypePrice")}
      content={body}
      actions={
        <>
          <Button onClick={handelCloseDialog}>{t("cancel")}</Button>
          <Button onClick={handleSubmit(onSubmit)}>
            {savePriceListLoad ? <ButtonLoading /> : t("confirm")}
          </Button>
        </>
      }
    ></CustomDialog>
  );
}
