import { Paper, Stack } from "@mui/material";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";
import Grid from "@mui/material/Unstable_Grid2";
import MUIDate from "../../HOC/MUI/MUIDate";

import React from "react";
import ListBranches from "../../HOC/ComponentWithSpecificQuery/ListBranches";
import { CustomAutocomplete } from "../../HOC/MUI/CustomAutocomplete";
import {
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
} from "../../../GlobalsQuery/ListDropdown/ListDropdown";
import { useShipmentContext } from "../Context/ShipmentContext";

export const Step1 = () => {
  const {
    control,
    errors,
    t,
    disabled,
    dateRange,
    setDateRange,
    setting,
    shipmentId,
    firstCalcUpdate,
    setValue,
    autocompleteValues,
    watch,
    parseLoadingMode,
    onChangeShippingMode,
    actualArrivalDate,
    setActualDepartureDate,
    setActualArrivalDate,
    actualDepartureDate,
    data,
  } = useShipmentContext();
  const shippingModeNAME = (type) => {
    if (["OCEAN"].includes(type)) {
      return (
        <Paper container component={Grid} spacing={2} m={0}>
          <Grid sm={12} xs={12}>
            <ControlMUItextField
              name="vesselName"
              control={control}
              label={t("vesselName")}
            />
          </Grid>
        </Paper>
      );
    } else if (["AIR"].includes(type)) {
      return (
        <Paper container component={Grid} spacing={2} m={0}>
          <Grid sm={6} xs={12}>
            <ControlMUItextField
              name="flightNumber"
              control={control}
              label={t("flightNumber")}
            />
          </Grid>
          <Grid sm={6} xs={12}>
            <ControlMUItextField
              name="ticketNumber"
              control={control}
              label={t("ticketNumber")}
            />
          </Grid>
        </Paper>
      );
    } else if (["LAND"].includes(type)) {
      return (
        <Paper container component={Grid} spacing={2} m={0}>
          <Grid sm={6} xs={12}>
            <ControlMUItextField
              name="truckType"
              control={control}
              label={t("truckType")}
            />
          </Grid>
          <Grid sm={6} xs={12}>
            <ControlMUItextField
              name="plateNumber"
              control={control}
              label={t("plateNumber")}
            />
          </Grid>
        </Paper>
      );
    }
  };
  return (
    <Stack spacing={2}>
      <Stack direction={{ md: "row" }} gap={2}>
        <Paper
          container
          component={Grid}
          spacing={2}
          m={0}
          width={{ md: "50%" }}
        >
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"code"}
              label={t("shipmentCode")}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <MUIDate
              name={"shipmentDate"}
              label={t("shipmentDate")}
              control={control}
              value={dateRange.shipmentDate}
              defaultValue={dateRange.shipmentDate}
              minDate={
                setting?.freightSettings?.createShipmentInPast === false &&
                (shipmentId ? dateRange.shipmentDate : new Date() - 1)
              }
              onChange={(e) => {
                setDateRange((prev) => ({ ...prev, shipmentDate: e }));
                firstCalcUpdate.current = false;
              }}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <MUIDate
              name={"scheduledDepartureDate"}
              label={t("scheduledDepartureDate")}
              control={control}
              value={dateRange.scheduledDepartureDate}
              defaultValue={dateRange.scheduledDepartureDate}
              onChange={(e) => {
                setDateRange((prev) => ({
                  ...prev,
                  scheduledDepartureDate: e,
                }));
              }}
            />
          </Grid>

          <Grid xs={12} sm={6} alignItems="flex-start">
            <MUIDate
              name="actualDepartureDate"
              label={t("actualDepartureDate")}
              control={control}
              value={actualDepartureDate}
              defaultValue={""}
              onChange={(date) => setActualDepartureDate(date)}
              disabled={Boolean(data?.shipment?.actualDepartureDate)}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <MUIDate
              name={"scheduledArrivalDate"}
              label={t("scheduledArrivalDate")}
              rules={{ required: t("fieldIsRequired") }}
              control={control}
              value={dateRange.scheduledArrivalDate}
              defaultValue={dateRange.scheduledArrivalDate ?? ""}
              onChange={(e) => {
                setDateRange((prev) => ({
                  ...prev,
                  scheduledArrivalDate: e,
                }));
              }}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <MUIDate
              name="actualArrivalDate"
              label={t("actualArrivalDate")}
              control={control}
              value={actualArrivalDate}
              defaultValue={""}
              onChange={(date) => setActualArrivalDate(date)}
              disabled={Boolean(data?.shipment?.actualArrivalDate)}
            />
          </Grid>
        </Paper>
        <Paper
          container
          component={Grid}
          spacing={2}
          m={0}
          width={{ md: "50%" }}
        >
          <Grid sm={12} xs={12}>
            <ListBranches
              control={control}
              errors={errors}
              name={"branchId"}
              rules={{ required: t("fieldIsRequired") }}
              defaultValue={autocompleteValues.branch}
              // skipDefaultBranch={true}
            />
          </Grid>
          <Grid sm={6} xs={12}>
            <CustomAutocomplete
              valueKey="code"
              selectFirst
              control={control}
              hideCode={true}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              name={"shippingMode"}
              label={t("shippingMode")}
              onChangeValue={(e) => {
                onChangeShippingMode(e);
              }}
              parseData={(data) => data}
              variables={{ input: { code: "FR_SHIPPING_MODE" } }}
              query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
              defaultValue={autocompleteValues?.shippingMode}
              disabled={disabled.addContainer}
            />
          </Grid>
          <Grid sm={6} xs={12}>
            <CustomAutocomplete
              valueKey="code"
              control={control}
              hideCode={true}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              name={"shippingDirection"}
              label={t("shippingDirection")}
              onChangeValue={(e) => {
                setValue("originPortId", "");
                setValue("destinationPortId", "");
                firstCalcUpdate.current = false;
                setValue("transactionTypeId", "");
                setValue("incoTermId", "");
              }}
              parseData={(data) => data}
              variables={{ input: { code: "FR_SHIPPING_DIRECTION" } }}
              query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
              defaultValue={autocompleteValues?.shippingDirection}
              disabled={disabled.addContainer}
            />
          </Grid>
          <Grid sm={6} xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={Boolean(
                !watch("shippingDirection") || !watch("shippingMode")
              )}
              parseData={(data) => data}
              name={"transactionTypeId"}
              label={t("transactionType")}
              rules={{ required: t("fieldIsRequired") }}
              query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
              skip={Boolean(
                !watch("shippingDirection") || !watch("shippingMode")
              )}
              variables={{
                input: {
                  shippingDirection: watch("shippingDirection"),
                  shippingMode: watch("shippingMode"),
                  type: "SHPMNT",
                },
              }}
              defaultValue={autocompleteValues.transactionType}
              // onCompleted={(data) =>
              //   handelAutocompleteDefaultValue(data, "transactionTypeId")
              // }
            />
          </Grid>
          {/* {watch("shippingMode") !== "AIR" && ( */}
          <Grid sm={6} xs={12}>
            <CustomAutocomplete
              valueKey="code"
              control={control}
              errors={errors}
              rules={{
                required:
                  watch("shippingMode") !== "AIR" && t("fieldIsRequired"),
              }}
              name={"loadingMode"}
              label={t("loadingMode")}
              parseData={(data) => parseLoadingMode(data)}
              variables={{ input: { code: "FR_LOADING_MODE" } }}
              query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
              defaultValue={autocompleteValues?.loadingMode}
              disabled={
                disabled.addContainer || watch("shippingMode") === "AIR"
              }
            />
          </Grid>
          {/* )} */}
        </Paper>
      </Stack>
      {watch("shippingMode") && (
        <Stack gap={2}>{shippingModeNAME(watch("shippingMode"))}</Stack>
      )}
      <Stack p={1} component={Paper}>
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} aligns="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"mawbNumber"}
              label={t("mawbNumber")}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"bookingNumber"}
              label={t("bookingNumber")}
            />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};
