import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Toolbar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { Add, Delete, Edit } from "@mui/icons-material";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { gql, useMutation } from "@apollo/client";
import { DELETE_CUSTOMER_PRICE_MODE } from "./Graphql";
import CustomTab from "../HOC/FunctionComponents/CustomTab";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { isEmpty } from "lodash";
import CellLink from "../HOC/CustomComponents/CellLink";
import { useCustomersContext } from "./Context/CustomersContext";

const PREFIX = "CustomerTables";

const classes = {
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  tableContainerTabes: `${PREFIX}-tableContainerTabes`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  focus: `${PREFIX}-focus`,
  tableRow: `${PREFIX}-tableRow`,
  packageType: `${PREFIX}-packageType`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.tableContainer}`]: {
    minHeight: "250px",
  },
  [`& .${classes.tableContainerTabes}`]: {
    minHeight: "205px",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.toolbar}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.focus}`]: {
    backgroundColor: theme.palette.action.hover,
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      cursor: "pointer",
    },
  },

  [`& .${classes.packageType}`]: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function CustomerTables(props) {
  const {
    setDialogState,
    setUpdatePriceModes,
    customerId,
    priceModes,
    setPriceModes,
    loading,
    setUpdateSales,
    sales,
  } = useCustomersContext();

  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  //////////// package Functions ////////////
  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const openDialog = (subzone) => {
    setDialogState((prev) => ({
      ...prev,
      priceModes: true,
    }));
    subzone && setUpdatePriceModes(subzone);
  };
  const openSalesDialog = (subzone) => {
    setDialogState((prev) => ({
      ...prev,
      sales: true,
    }));
    subzone && setUpdateSales(subzone);
  };

  const [deleteCustomerPriceMode, { loading: deleteLoad }] = useMutation(
    gql`
      ${DELETE_CUSTOMER_PRICE_MODE.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const priceModesTab = (
    <Grid
      container
      item
      sm={12}
      md={12}
      className={classes.packageType}
      component={Paper}
    >
      <Toolbar className={classes.toolbar} variant="dense">
        <Typography className={classes.title} color="inherit" variant="h6">
          {t("priceModes")}
        </Typography>
        {customerId ? (
          <IconButton
            disabled={!customerId}
            onClick={() => openDialog()}
            aria-label="Add priceModes"
            color="primary"
            size="large"
          >
            <Add />
          </IconButton>
        ) : null}
      </Toolbar>
      <Grid container className={classes.table}>
        <TableFixedHeaderWraper>
          {loading || isEmpty(priceModes) ? (
            <EmptyTableMessage loading={loading} message={t("noResult")} />
          ) : (
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <FixedTableCell>{t("id")}</FixedTableCell>
                  <FixedTableCell>{t("name")}</FixedTableCell>
                  <FixedTableCell>{t("shippingMode")}</FixedTableCell>
                  <FixedTableCell> </FixedTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {priceModes &&
                  (rowsPerPage > 0
                    ? priceModes.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : priceModes
                  )?.map((row, index) => (
                    <TableRow hover key={index}>
                      <FixedTableCell>{row?.id}</FixedTableCell>
                      <FixedTableCell>{row?.priceMode?.name}</FixedTableCell>
                      <FixedTableCell>{row?.shippingMode?.name}</FixedTableCell>
                      {customerId ? (
                        <>
                          <FixedTableCell>
                            <IconButton
                              size="small"
                              onClick={() => openDialog(row)}
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              size="small"
                              disabled={deleteLoad}
                              onClick={() => {
                                setPriceModes((prev) => {
                                  const filterd = prev.filter(
                                    (i, deleteIndex) =>
                                      deleteIndex !== page * rowsPerPage + index
                                  );
                                  return filterd;
                                });
                                deleteCustomerPriceMode({
                                  variables: {
                                    id: parseInt(row.id),
                                  },
                                });
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </FixedTableCell>
                        </>
                      ) : (
                        <>
                          <FixedTableCell> </FixedTableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableFixedHeaderWraper>
        <MUITablePagination
          count={priceModes.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePage}
          onRowsPerPageChange={handleRowsPerPage}
        />
      </Grid>
    </Grid>
  );

  const salesTab = (
    <Grid
      container
      item
      sm={12}
      md={12}
      className={classes.packageType}
      component={Paper}
    >
      <Toolbar className={classes.toolbar} variant="dense">
        <Typography className={classes.title} color="inherit" variant="h6">
          {t("sales")}
        </Typography>
        {customerId ? (
          <IconButton
            disabled={!customerId}
            onClick={() => openSalesDialog()}
            aria-label="Add sales"
            color="primary"
            size="large"
          >
            <Add />
          </IconButton>
        ) : null}
      </Toolbar>
      <Grid container className={classes.table}>
        <TableFixedHeaderWraper>
          {loading || isEmpty(sales) ? (
            <EmptyTableMessage loading={loading} message={t("noResult")} />
          ) : (
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <FixedTableCell>{t("id")}</FixedTableCell>
                  <FixedTableCell>{t("employeeName")}</FixedTableCell>
                  <FixedTableCell>{t("fromDate")}</FixedTableCell>
                  <FixedTableCell>{t("toDate")}</FixedTableCell>
                  <FixedTableCell> </FixedTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sales &&
                  (rowsPerPage > 0
                    ? sales.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : sales
                  )?.map((row, index) => (
                    <TableRow hover key={index}>
                      <FixedTableCell>{row?.id}</FixedTableCell>
                      <CellLink
                        pathname={`/admin/employees/${row?.employee?.id}`}
                      >
                        {row?.employee?.name}
                      </CellLink>
                      <FixedTableCell>{row?.fromDate}</FixedTableCell>
                      <FixedTableCell>{row?.toDate}</FixedTableCell>
                      {customerId && row?.editable ? (
                        <FixedTableCell>
                          <IconButton
                            size="small"
                            onClick={() => openSalesDialog(row)}
                          >
                            <Edit />
                          </IconButton>
                        </FixedTableCell>
                      ) : (
                        <FixedTableCell> </FixedTableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableFixedHeaderWraper>
        <MUITablePagination
          count={sales.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePage}
          onRowsPerPageChange={handleRowsPerPage}
        />
      </Grid>
    </Grid>
  );

  const tapsArray = [
    {
      tabHead: t("priceModes"),
      panel: priceModesTab,
    },
    {
      tabHead: t("sales"),
      panel: salesTab,
    },
  ];
  return (
    <StyledGrid container alignItems="flex-start">
      <Grid
        container
        item
        sm={12}
        md={12}
        className={classes.packageType}
        component={Paper}
      >
        <CustomTab tapDetails={tapsArray} />
      </Grid>
    </StyledGrid>
  );
}
