import React, { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { TableBody, TableRow, Button, Icon } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Search } from "@mui/icons-material";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { EXPOERT_WAREHOUSE_PACKAGES, LIST_WAREHOUSE_PACKAGES } from "./Graphql";
import { useTranslation } from "react-i18next";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { useForm } from "react-hook-form";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import useWidth from "../../Hooks/useWidth";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_COUNTRIES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_PACKAGE_TYPES_DROPDOWN,
  LIST_SHIPPING_PORTS_DROPDOWN,
  LIST_VENDORS_DROPDOWN,
  LIST_WAREHOUSE_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";

import { RootStyleList, classes } from "../../GlobalStyles/ListStyle";
import CellLink from "../HOC/CustomComponents/CellLink";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import CustomExportExcel from "../HOC/CustomComponents/CustomExportExcel";
import MUIselect from "../HOC/MUI/MUIselect";
import moment from "moment";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import { TableCellColor } from "../HOC/CustomComponents/TableCellColor";

const WarehousePackagesList = (props) => {
  const { t } = useTranslation();
  const [reFetches, setReFetches] = useState(true);
  const [autocompleteValues, setAutocompleteValues] = useState({
    warehouse: null,
    packageTypeId: null,
    originCountry: null,
    destinationCountry: null,
    customer: null,
    vendor: null,
    destinationPort: null,
    originPort: null,
  });
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });
  const [dialogDetails, setDialogDetails] = useState({
    state: false,
    function: null,
    confirmAction: true,
    title: "",
    content: "",
  });
  const [openExport, setOpenExport] = useState(false);
  const [date, setDate] = useState([
    urlQuery["fromDate"] ? urlQuery["fromDate"] : null,
    urlQuery["toDate"] ? urlQuery["toDate"] : null,
  ]);
  const fromDate = moment(date[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(date[1]).locale("en").format("YYYY-MM-DD");
  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;

    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };
  const closeConfirmationDialog = () => {
    setDialogDetails((prev) => ({
      ...prev,
      state: false,
    }));
  };
  // const openSignatureDialog = (imgPath) => {
  //   setDialogDetails((prev) => ({
  //     state: true,
  //     title: null,
  //     content: (
  //       <Box
  //         sx={{ background: "#fff", width: "100%" }}
  //         component="img"
  //         alt="signature"
  //         src={getBackendUri(imgPath.path)}
  //       />
  //     ),
  //     confirmAction: false,
  //     function: () => {},
  //   }));
  // };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const [rowsPerPage, setRowsPerPage] = useState(
    urlQuery["rowsPerPage"] ?? config.app.pageSize
  );
  const initSearch = () => {
    let searchParameters = {
      ...(date[0] && { fromDate }),
      ...(date[1] && { toDate }),
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["search"])
        urlQuery["search"] = urlQuery["search"].toString();
      if (urlQuery["requestCode"])
        urlQuery["requestCode"] = urlQuery["requestCode"].toString();
      if (urlQuery.fromDate || urlQuery.toDate) {
        const date = {
          ...(urlQuery?.fromDate && { fromDate: urlQuery?.fromDate }),
          ...(urlQuery?.toDate && { toDate: urlQuery?.toDate }),
        };
        delete urlQuery["fromDate"];
        delete urlQuery["toDate"];
        urlQuery.date = date;
      }
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["search"] && setValue("search", urlQuery["search"]);
    urlQuery["requestCode"] && setValue("requestCode", urlQuery["requestCode"]);
    urlQuery["status"] !== undefined && setValue("status", urlQuery["status"]);
    urlQuery["fragile"] !== undefined &&
      setValue("fragile", urlQuery["fragile"]);
    setAutocompleteValues((prev) => ({
      ...(urlQuery["warehouseId"] && {
        warehouse: urlQuery["warehouseId"],
      }),
      ...(urlQuery["packageTypeId"] && {
        packageTypeId: urlQuery["packageTypeId"],
      }),
      ...(urlQuery["destinationPortId"] && {
        destinationPort: urlQuery["destinationPortId"],
      }),
      ...(urlQuery["destinationCountryId"] && {
        destinationCountry: urlQuery["destinationCountryId"],
      }),
      ...(urlQuery["originCountryId"] && {
        originCountry: urlQuery["originCountryId"],
      }),
      ...(urlQuery["originPortId"] && {
        originPort: urlQuery["originPortId"],
      }),
      ...(urlQuery["customerId"] && {
        customer: urlQuery["customerId"],
      }),
      ...(urlQuery["vendorId"] && {
        vendor: urlQuery["vendorId"],
      }),
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/Warehouse-Packages",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const resetDate = () => {
    setDate([null, null]);
  };
  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    refetch ? setReFetches(false) : setReFetches(true);
    let handledData = {
      ...(date[0] && { fromDate }),
      ...(date[1] && { toDate }),
      warehouseId: data.warehouseId,
      status: data.status,
      packageTypeId: data.packageTypeId,
      fragile: data.fragile,
      requestCode: String(data.requestCode),
      search: String(data.search),
      vendorId: data.vendorId,
      customerId: data.customerId,
      destinationCountryId: data.destinationCountryId,
      destinationPortId: data.destinationPortId,
      originCountryId: data.originCountryId,
      originPortId: data.originPortId,
      page: 0,
    };
    data["fragile"] === "" && delete handledData["fragile"];
    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
    if (handledData.fromDate || handledData.toDate) {
      const date = {
        ...(handledData?.fromDate && { fromDate: handledData?.fromDate }),
        ...(handledData?.toDate && { toDate: handledData?.toDate }),
      };
      delete handledData["fromDate"];
      delete handledData["toDate"];
      handledData.date = date;
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });
    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });
    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  const { data, loading, refetch } = useQuery(
    gql`
      ${LIST_WAREHOUSE_PACKAGES.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: reFetches,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          fragile: search.fragile,
          ...(search.search && { search: search.search }),
          ...(search.warehouseId && { warehouseId: search.warehouseId }),
          ...(search.packageTypeId && { packageTypeId: search.packageTypeId }),
          ...(search.requestCode && { requestCode: search.requestCode }),
          ...(search.date && { date: search.date }),
          ...(search.status && { status: search.status }),
          ...(search.customerId && {
            customerId: search.customerId,
          }),
          ...(search.vendorId && {
            vendorId: search.vendorId,
          }),
          ...(search.destinationPortId && {
            destinationPortId: search.destinationPortId,
          }),
          ...(search.originPortId && { originPortId: search.originPortId }),
          ...(search.originCountryId && {
            originCountryId: search.originCountryId,
          }),
          ...(search.destinationCountryId && {
            destinationCountryId: search.destinationCountryId,
          }),
        },
      },
      onCompleted: (data) => {},
    }
  );
  const filters = {
    fragile: search.fragile,
    ...(search.search && { search: search.search }),
    ...(search.warehouseId && { warehouseId: search.warehouseId }),
    ...(search.packageTypeId && { packageTypeId: search.packageTypeId }),
    ...(search.requestCode && { requestCode: search.requestCode }),
    ...(search.date && { date: search.date }),
    ...(search.status && { status: search.status }),
    ...(search.customerId && {
      customerId: search.customerId,
    }),
    ...(search.vendorId && {
      vendorId: search.vendorId,
    }),
    ...(search.destinationPortId && {
      destinationPortId: search.destinationPortId,
    }),
    ...(search.originPortId && { originPortId: search.originPortId }),
    ...(search.originCountryId && {
      originCountryId: search.originCountryId,
    }),
    ...(search.destinationCountryId && {
      destinationCountryId: search.destinationCountryId,
    }),
  };
  const fields = [
    { name: "CODE", label: t("code") },
    { name: "DATE", label: t("date") },
    { name: "STATUS", label: t("status") },
    { name: "BRANCH", label: t("branch") },
    { name: "WAREHOUSE", label: t("wareHouse") },
    { name: "PACKAGETYPE", label: t("packageType") },
    { name: "DESCRIPTION", label: t("description") },
    { name: "COUNT", label: t("count") },
    { name: "PIECES_COUNT", label: t("pieceCount") },
    { name: "WEIGHT", label: t("weight") },
    { name: "TOTAL_WEIGHT", label: t("totalWeight") },
    { name: "IMAGE", label: t("image") },
    { name: "FRAGILE", label: t("fragile") },
  ];
  const listWarehousePackages =
    data?.listWarehousePackages?.data !== null
      ? data?.listWarehousePackages?.data
      : null;
  const openExportDialog = () => {
    setOpenExport(true);
  };
  const icons = [
    {
      id: "filterList",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
    },
    {
      id: "export",
      action: openExportDialog,
      disabled: !Boolean(data),
    },
  ];

  return (
    <RootStyleList>
      {openExport && (
        <CustomExportExcel
          filters={filters}
          fields={fields}
          localStorageName={"export-warehouse-packages"}
          ExportMutation={EXPOERT_WAREHOUSE_PACKAGES}
          mutationName={"exportWarehousePackages"}
          openExport={openExport}
          setOpenExport={setOpenExport}
        />
      )}
      {dialogDetails.state && (
        <CustomDialog
          title={dialogDetails.title}
          fullWidth
          maxWidth="xs"
          onClose={closeConfirmationDialog}
          content={dialogDetails.content}
          open={dialogDetails.state}
          actions={
            dialogDetails.function !== null && (
              <>
                <Button color="primary" onClick={closeConfirmationDialog}>
                  {dialogDetails.confirmAction ? t("cancel") : t("close")}
                </Button>
                {dialogDetails.confirmAction && (
                  <Button color="primary" onClick={dialogDetails.function}>
                    {t("confirm")}
                  </Button>
                )}
              </>
            )
          }
        >
          {dialogDetails.function === null && " "}
        </CustomDialog>
      )}
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={listWarehousePackages?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  startText={t("date")}
                  value={date}
                  onChangeValue={(value) => setDate(value)}
                  resetDate={resetDate}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"search"}
                  label={t("search")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"requestCode"}
                  label={t("requestCode")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"status"}
                  label={t("status")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("packed"), value: "PCKD" },
                    { key: t("receiveInWarehouse"), value: "RCVD" },
                  ]}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={`warehouseId`}
                  label={t("wareHouse")}
                  query={LIST_WAREHOUSE_DROPDOWN.query}
                  defaultValue={autocompleteValues.warehouse}
                  parseData={(data) => data}
                />
              </Grid>

              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"packageTypeId"}
                  label={t("packageType")}
                  parseData={(data) => data}
                  query={LIST_PACKAGE_TYPES_DROPDOWN.query}
                  defaultValue={autocompleteValues.packageTypeId}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"vendorId"}
                  label={t("sender")}
                  parseData={(data) => data}
                  query={LIST_VENDORS_DROPDOWN.query}
                  defaultValue={autocompleteValues.vendor}
                />
              </Grid>

              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"customerId"}
                  label={t("customer")}
                  parseData={(data) => data}
                  query={LIST_CUSTOMERS_DROPDOWN.query}
                  defaultValue={autocompleteValues.customer}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"originCountryId"}
                  label={t("originCountry")}
                  parseData={(data) => data}
                  query={LIST_COUNTRIES_DROPDOWN.query}
                  onChangeValue={(e) => {
                    setValue("originPortId", "");
                  }}
                  defaultValue={autocompleteValues.originCountry}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"originPortId"}
                  label={t("originPort")}
                  parseData={(data) => data}
                  query={LIST_SHIPPING_PORTS_DROPDOWN.query}
                  variables={{
                    input: {
                      countryId: watch("originCountryId"),
                    },
                  }}
                  defaultValue={autocompleteValues.originPort}
                  skip={!watch("originCountryId")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"destinationCountryId"}
                  label={t("destinationCountry")}
                  parseData={(data) => data}
                  query={LIST_COUNTRIES_DROPDOWN.query}
                  onChangeValue={(e) => {
                    setValue("destinationPortId", "");
                  }}
                  defaultValue={autocompleteValues.destinationCountry}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"destinationPortId"}
                  label={t("destinationPort")}
                  parseData={(data) => data}
                  query={LIST_SHIPPING_PORTS_DROPDOWN.query}
                  variables={{
                    input: {
                      countryId: watch("destinationCountryId"),
                    },
                  }}
                  defaultValue={autocompleteValues.destinationPort}
                  skip={!watch("destinationCountryId")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"fragile"}
                  label={t("fragile")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("yes"), value: true },
                    { key: t("no"), value: false },
                  ]}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={[
          {
            name: "requestCode",
          },
          {
            name: "date",
          },
          {
            name: "status",
          },
          {
            name: "wareHouse",
          },
          {
            name: "branch",
          },
          {
            name: "packageType",
          },
          {
            name: "description",
          },
          {
            name: "count",
          },
          {
            name: "pieces",
          },
          {
            name: "weight",
          },
          {
            name: "totalWeight",
          },

          {
            name: "image",
          },
          {
            name: "fragile",
          },
        ]}
        tableBody={
          <TableBody>
            {listWarehousePackages &&
              listWarehousePackages?.map((row, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <CellLink pathname={`/admin/requests/${row?.request?.id}`}>
                    {row?.request?.code}
                  </CellLink>
                  <FixedTableCell> {row?.date} </FixedTableCell>
                  <TableCellColor align="left" status={row?.request?.status} />
                  <CellLink pathname={`/admin/warehouse/${row?.warehouse?.id}`}>
                    {row?.warehouse?.name}
                  </CellLink>
                  <CellLink
                    pathname={`/admin/branches/${row?.request?.branch?.id}`}
                  >
                    {row?.request?.branch?.name}
                  </CellLink>
                  <CellLink
                    pathname={`/admin/package-types/${row?.packageType?.id}`}
                  >
                    {row?.packageType?.name}
                  </CellLink>
                  <FixedTableCell> {row?.description} </FixedTableCell>
                  <FixedTableCell> {row?.count} </FixedTableCell>
                  <FixedTableCell> {row?.pieces} </FixedTableCell>
                  <FixedTableCell> {row?.weight} </FixedTableCell>
                  <FixedTableCell> {row?.totalWeight} </FixedTableCell>
                  {row?.image ? (
                    <FixedTableCell>
                      <img
                        width="75px"
                        src={getBackendUri(row?.image?.path)}
                        alt="RowImage"
                      />

                      {/* <IconButton
                        size="small"
                        onClick={() => openSignatureDialog(row.image)}
                      > */}
                      {/* <Image fontSize="small" /> */}
                      {/* </IconButton> */}
                    </FixedTableCell>
                  ) : (
                    <FixedTableCell> </FixedTableCell>
                  )}
                  <FixedTableCell>
                    {row?.fragile ? (
                      <Icon className={classes.iconColor}>
                        check_circle_outline
                      </Icon>
                    ) : (
                      <Icon color="error">highlight_off</Icon>
                    )}
                  </FixedTableCell>
                </TableRow>
              ))}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listWarehousePackages?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listWarehousePackages ? 0 : search?.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </RootStyleList>
  );
};

export default WarehousePackagesList;
