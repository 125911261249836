import React from "react";
import { classesForm } from "../../../GlobalStyles/FormStyle";
import { useRequestContext } from "../Context/RequestContext";
import Grid from "@mui/material/Unstable_Grid2";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";
import { LIST_CURRENCIES_DROPDOWN } from "../../../GlobalsQuery/ListDropdown/ListDropdown";
import { CustomAutocomplete } from "../../HOC/MUI/CustomAutocomplete";
import clsx from "clsx";
import MuiSwitch from "../../HOC/MUI/MUIswitch";
import { Paper } from "@mui/material";

export const Step3 = () => {
  const {
    control,
    errors,
    t,
    setValue,
    autocompleteValues,
    watch,
    calcFeesHandler,
  } = useRequestContext();
  return (
    <>
      <Paper
        container
        component={Grid}
        spacing={1}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid xs={12} sm={3} alignItems="flex-start">
          <MuiSwitch
            edge="end"
            name="payToVendor"
            label={t("payToVendor")}
            control={control}
            onChange={(e) => {
              if (!e?.target.checked) {
                setValue("payableAmount", "");
                setValue("payableCurrencyId", "");
                setValue("commissionAmount", "");
              }
            }}
          />
        </Grid>
        <Grid xs={12} sm={3} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            disabled={!watch("payToVendor")}
            name={"payableCurrencyId"}
            label={t("payableCurrency")}
            parseData={(data) => data}
            query={LIST_CURRENCIES_DROPDOWN.query}
            defaultValue={autocompleteValues.payableCurrency}
          />
        </Grid>
        <Grid xs={12} sm={3} aligns="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            disabled={!watch("payToVendor")}
            type={"number"}
            name={"payableAmount"}
            label={t("payableAmount")}
            onChange={(e) => {
              setTimeout(() => {
                calcFeesHandler({
                  value: parseInt(e.target.value),
                  name: "payableAmount",
                });
              }, 1000);
            }}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            type={"number"}
            readOnly={true}
            name={"commissionAmount"}
            label={t("commissionAmount")}
          />
        </Grid>
        <Grid xs={12} sm={6} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"currencyId"}
            label={t("currency")}
            parseData={(data) => data}
            query={LIST_CURRENCIES_DROPDOWN.query}
            defaultValue={autocompleteValues.currency}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            type={"number"}
            readOnly={true}
            name={"exchangeRate"}
            label={t("exchangeRate")}
            rules={{
              min: {
                value: 0,
                message: t("validation:min", {
                  field: t("exchangeRate"),
                  number: "0",
                }),
              },
            }}
          />
        </Grid>
      </Paper>
    </>
  );
};
