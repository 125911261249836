import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Toolbar,
  TableContainer,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { AddCircleOutline } from "@mui/icons-material";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { isEmpty } from "lodash";
import Row from "./Row";
import { useBillContext } from "./Context/BillContext";

const PREFIX = "BillTables";

const classes = {
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  tableContainerTabes: `${PREFIX}-tableContainerTabes`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  header: `${PREFIX}-header`,
  focus: `${PREFIX}-focus`,
  tableRow: `${PREFIX}-tableRow`,
  packageType: `${PREFIX}-packageType`,
  addIcon: `${PREFIX}-addIcon`,
  borderTable: `${PREFIX}-borderTable`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.borderTable}`]: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.tableContainer}`]: {
    minHeight: "250px",
  },
  [`& .${classes.tableContainerTabes}`]: {
    minHeight: "205px",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.header}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },
  [`& .${classes.toolbar}`]: {
    borderBottom: "1px solid" + theme.palette.background.paper,
  },

  [`& .${classes.focus}`]: {
    backgroundColor: theme.palette.action.hover,
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      cursor: "pointer",
    },
  },

  [`& .${classes.packageType}`]: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },
  [`& .${classes.addIcon}`]: {
    color: theme.palette.success.main,
  },
}));

export default function BillTables(props) {
  const {
    openLineDialog,
    id,
    line,
    loading,
    capitalPathname,
    billData,
    totalSum,
  } = useBillContext();
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const priceModesTab = (
    <Grid
      container
      item
      sm={12}
      md={12}
      className={classes.packageType}
      component={Paper}
    >
      <Toolbar className={classes.header} variant="dense">
        <Typography className={classes.title} color="inherit" variant="h6">
          {/* {t("line")} */}
        </Typography>
        {id && !billData?.approved ? (
          <IconButton
            disabled={!id}
            onClick={() => openLineDialog()}
            aria-label="Add priceModes"
            className={classes.addIcon}
            size="small"
          >
            <AddCircleOutline />
          </IconButton>
        ) : null}
      </Toolbar>
      <Grid container className={classes.table}>
        <TableFixedHeaderWraper>
          {loading || isEmpty(line) ? (
            <EmptyTableMessage loading={loading} message={t("noResult")} />
          ) : (
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell></FixedTableCell>
                    <FixedTableCell>{t("priceModes")}</FixedTableCell>
                    <FixedTableCell>
                      {capitalPathname === "Bill"
                        ? t("chargeElementBill")
                        : t("chargeElementInvc")}
                    </FixedTableCell>
                    <FixedTableCell>{t("chargeableUnits")}</FixedTableCell>
                    <FixedTableCell>{t("chargePrice")}</FixedTableCell>
                    <FixedTableCell>{t("value")}</FixedTableCell>
                    <FixedTableCell>{t("tax")}</FixedTableCell>
                    <FixedTableCell>{t("total")}</FixedTableCell>
                    <FixedTableCell>{t("currency")}</FixedTableCell>
                    <FixedTableCell>{t("totallocal")}</FixedTableCell>
                    {id && !billData?.approved && (
                      <>
                        <FixedTableCell> {t("addTax")}</FixedTableCell>
                        <FixedTableCell> </FixedTableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {line &&
                    (rowsPerPage > 0
                      ? line.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : line
                    )?.map((row, index) => {
                      // totalAmount = row.totalAmountInLocalCurrency;

                      return (
                        <Row
                          key={index}
                          row={row}
                          index={index}
                          classes={classes}
                        />
                      );
                    })}
                  <TableRow className={classes.borderTable}>
                    <FixedTableCell colSpan={5} />
                    <FixedTableCell>{t("total")}</FixedTableCell>
                    <FixedTableCell>
                      {totalSum?.totalTaxAmount?.toFixed(2)}
                    </FixedTableCell>
                    <FixedTableCell>
                      {totalSum?.totalAmount?.toFixed(2)}
                    </FixedTableCell>
                    <FixedTableCell> </FixedTableCell>
                    <FixedTableCell>
                      {totalSum?.totalAmountInLocalCurrency?.toFixed(2)}
                    </FixedTableCell>
                    {id && !billData?.approved && (
                      <FixedTableCell colSpan={2} />
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TableFixedHeaderWraper>
        <MUITablePagination
          count={line?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePage}
          onRowsPerPageChange={handleRowsPerPage}
        />
      </Grid>
    </Grid>
  );

  return (
    <StyledGrid container alignItems="flex-start">
      <Grid
        container
        item
        sm={12}
        md={12}
        className={classes.packageType}
        component={Paper}
      >
        {priceModesTab}
      </Grid>
    </StyledGrid>
  );
}
