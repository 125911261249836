import { useTranslation } from "react-i18next";

import {
  LIST_AGENCIES_DROPDOWN,
  LIST_CARRIER_DROPDOWN,
  LIST_CUSTOM_AGENT_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_EMPLOYEE_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_VENDORS_DROPDOWN,
} from "../GlobalsQuery/ListDropdown/ListDropdown";

export const useAccountType = (
  accountType,
  autocompleteValues,
  documentType
) => {
  const { t } = useTranslation();
  switch (accountType) {
    case "VNDR":
    case "VENDOR":
      return {
        label: t("vendor"),
        name: "vendorId",
        queryName: "vendor",
        query: LIST_VENDORS_DROPDOWN.query,
        input: {
          ...(documentType === "RQST" && { hasRequests: true }),
        },
        defaultValue: autocompleteValues?.vendor,
      };
    case "CUST":
    case "CUSTOMER":
      return {
        label: t("customer"),
        name: "customerId",
        queryName: "customer",
        query: LIST_CUSTOMERS_DROPDOWN.query,
        input: {
          ...(documentType === "RQST" && { hasRequests: true }),
        },
        defaultValue: autocompleteValues?.customer,
      };
    case "AGNT":
    case "AGENT":
      return {
        label: t("customAgent"),
        name: "customAgentId",
        queryName: "customAgent",
        query: LIST_CUSTOM_AGENT_DROPDOWN.query,
        defaultValue: autocompleteValues?.customAgent,
      };
    case "EMP":
    case "EMPLOYEE":
      return {
        label: t("employee"),
        name: "employeeId",
        queryName: "employee",
        query: LIST_EMPLOYEE_DROPDOWN.query,
        defaultValue: autocompleteValues?.employee,
      };
    case "AGNCY":
    case "AGENCY":
      return {
        label: t("agency"),
        name: "agencyId",
        queryName: "agency",
        query: LIST_AGENCIES_DROPDOWN.query,
        defaultValue: autocompleteValues?.agency,
      };
    case "CARIER":
    case "CARRIER":
      return {
        label: t("carrier"),
        name: "carrierId",
        queryName: "carrier",
        query: LIST_CARRIER_DROPDOWN.query,
        defaultValue: autocompleteValues?.carrier,
      };
    case "ACT":
      return {
        label: t("glAccount"),
        name: "glAccountId",
        queryName: "glAccount",
        query: LIST_GL_ACCOUNTS_DROPDOWN.query,
        defaultValue: autocompleteValues?.glAccount,
      };
    default:
      return {
        label: t("accountName"), // Default label
        name: "customerId",
        queryName: "customer",
        query: LIST_CUSTOMERS_DROPDOWN.query,
        defaultValue: autocompleteValues?.customer,
      };
  }
};
