import React, { useEffect } from "react";
import clsx from "clsx";
import {
  Paper,
  Switch,
  FormControlLabel,
  Collapse,
  Button,
  FormHelperText,
} from "@mui/material";
import { SAFE_ID, SAVE_SAFE } from "./Graphql";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import { useState } from "react";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import TreasurersTable from "./TreasurersTable";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import {
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_USERS_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";

const SafeForm = (props) => {
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    glAccount: null,
  });
  const [treasurersList, setTreasurersList] = useState([]);

  const [treasurersIndex, setTreasurersIndex] = useState({
    index: 0,
    update: false,
  });
  const [dialog, setDialog] = useState(false);
  const [treasurersErrorMessage, setTreasurersErrorMessage] = useState(false);
  const [selectedNames, setSelectedNames] = useState({
    user: "",
  });

  const handelCloseDialog = () => {
    setDialog(false);
    setTreasurersErrorMessage(false);
    setTreasurersIndex((prev) => ({ ...prev, update: false }));
  };

  const addTreasurersDialog = (index) => {
    treasurersReset();
    if (index || index === 0) {
      setTreasurersIndex({
        index,
        update: true,
      });
    } else {
      setTreasurersIndex({
        index,
        update: false,
      });
    }
    setDialog(true);
  };

  const onChangeNames = (e, parameter, remove) => {
    const name = e.name;
    setSelectedNames((prev) => ({
      ...prev,
      [parameter]: name,
      ...(remove && { [remove]: "" }),
    }));
    setTreasurersErrorMessage(false);
  };

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState, watch, setValue, setError } =
    useForm();

  const {
    control: treasurersControl,
    formState: { errors: treasurersErrors },
    handleSubmit: treasurersHandleSubmit,
    setValue: treasurersSetValue,
    watch: treasurersWatch,
    reset: treasurersReset,
  } = useForm({
    defaultValues: {
      userId: "",
      active: true,
    },
  });

  const { errors } = formState;

  const parseData = (data) => {
    return data;
  };

  const onSubmitTreasurers = (data) => {
    const newTreasurers = {
      user: {
        id: data.userId,
        username: selectedNames.user,
      },
      active: data.active,
    };
    const updateTreasurers = [...treasurersList];
    const invalidTreasurers = updateTreasurers.some((i, index) =>
      treasurersIndex.update && index === treasurersIndex.index
        ? false
        : i.user.id === newTreasurers.user.id
    );

    if (invalidTreasurers) {
      setTreasurersErrorMessage(true);
    } else {
      if (treasurersIndex.update) {
        updateTreasurers[treasurersIndex.index] = {
          ...updateTreasurers[treasurersIndex.index],
          ...newTreasurers,
        };
        setTreasurersIndex({ index: treasurersIndex.index, update: false });
      } else {
        updateTreasurers.push(newTreasurers);
        setTreasurersIndex({
          index: 0,
          update: false,
        });
      }
      setTreasurersList(updateTreasurers);
      handelCloseDialog();
    }
  };

  const [saveSafe, { loading: saveSafeLoading }] = useMutation(
    gql`
      ${SAVE_SAFE.query}
    `
  );
  const [activeUser, setActiveUser] = useState(true);
  const [depositeUser, setDepositeUser] = useState(true);
  const [withdrawUser, setWithdrawUser] = useState(true);
  const [transferInUser, setTransferInUser] = useState(true);
  const [transferOutUser, setTransferOutUser] = useState(true);

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActiveUser(active);
  };
  const handelDeposite = (e) => {
    const deposite = e.target.checked;
    setDepositeUser(deposite);
  };
  const handelWithdraw = (e) => {
    const withdraw = e.target.checked;
    setWithdrawUser(withdraw);
  };
  const handelTransferIn = (e) => {
    const transferIn = e.target.checked;
    setTransferInUser(transferIn);
  };
  const handelTransferOut = (e) => {
    const transferOut = e.target.checked;
    setTransferOutUser(transferOut);
  };
  // update
  const safeId = parseInt(props.match.params.id);

  const { data, loading } = useQuery(
    gql`
      ${SAFE_ID.query}
    `,

    {
      skip: !safeId,
      variables: { id: safeId },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const safesData = data.safe;
        setActiveUser(safesData.active);
        setDepositeUser(safesData.deposite);
        setWithdrawUser(safesData.withdraw);
        setTransferInUser(safesData.transferIn);
        setTransferOutUser(safesData.transferOut);
        setTreasurersList(safesData.treasurers);
        const saveSafesParams = [
          "id",
          "code",
          "name",
          "deposite",
          "withdraw",
          "transferIn",
          "transferOut",
          "treasurers",
        ];
        saveSafesParams.forEach((i) => {
          safesData[i] && setValue(i, safesData[i]);
        });
        setValue("active", safesData["active"]);

        setAutocompleteValues({
          branch: safesData?.branch,
          glAccount: safesData?.glAccount,
        });
      },
    }
  );

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    const treasurers = treasurersList
      ? treasurersList.map((i) => {
          const treasurers = i.user.id;
          // {
          //   userId: i.user.id,
          //   active: i.active,
          // };
          return treasurers;
        })
      : [];
    saveSafe({
      variables: {
        input: {
          ...data,
          active: activeUser,
          deposite: depositeUser,
          withdraw: withdrawUser,
          transferIn: transferInUser,
          transferOut: transferOutUser,
          treasurers: treasurers,
        },
      },
    })
      .then((data) => {
        pushUrl(props, `/admin/safes/${data?.data?.saveSafe?.id}`);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };
  const treasurersForm = (
    <TreasurersForm
      handleSubmit={treasurersHandleSubmit}
      onSubmitTreasurers={onSubmitTreasurers}
      control={treasurersControl}
      errors={treasurersErrors}
      setValue={treasurersSetValue}
      watch={treasurersWatch}
      parseData={parseData}
      onChangeNames={onChangeNames}
      setSelectedNames={setSelectedNames}
      treasurersIndex={treasurersIndex}
      treasurersList={treasurersList}
      treasurersErrorMessage={treasurersErrorMessage}
      handelCloseDialog={handelCloseDialog}
      dialog={dialog}
    />
  );

  const body = (
    <RootStyleForm>
      <TitleAppBar path={props.match.path} />
      <Grid
        component={"form"}
        onSubmit={handleSubmit(onSubmit)}
        container
        justifyContent="flex-start"
        alignItems="center"
        className={clsx(classesForm.mainGrid)}
        spacing={2}
      >
        <Paper container component={Grid} className={clsx(classesForm.spacing)}>
          <Grid container justifyContent="end" xs={12}>
            <FormControlLabel
              checked={activeUser}
              control={<Switch color="primary" />}
              label={t("active")}
              labelPlacement="start"
              onChange={handelActivate}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"code"}
              label={t("code")}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"name"}
              label={t("name")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ListBranches
              control={control}
              errors={errors}
              name={"branchId"}
              rules={{ required: t("fieldIsRequired") }}
              onChangeValue={() => {
                setValue("glAccountId", "");
              }}
              defaultValue={autocompleteValues.branch}
              skipDefaultBranch={Boolean(safeId)}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"glAccountId"}
              label={t("glAccount")}
              parseData={(data) => parseData(data)}
              query={LIST_GL_ACCOUNTS_DROPDOWN.query}
              variables={{
                input: {
                  typeCode: "SUB",
                  ...(watch("branchId") && {
                    branchId: { value: watch("branchId") },
                  }),
                },
              }}
              defaultValue={autocompleteValues.glAccount}
            />
          </Grid>

          <Grid justifyContent="space-between" xs={12} sm={6}>
            <FormControlLabel
              checked={depositeUser}
              control={<Switch color="primary" />}
              label={t("deposite")}
              labelPlacement="start"
              onChange={handelDeposite}
            />
          </Grid>
          <Grid justifyContent="space-between" xs={12} sm={6}>
            <FormControlLabel
              checked={transferInUser}
              control={<Switch color="primary" />}
              label={t("transferIn")}
              labelPlacement="start"
              onChange={handelTransferIn}
            />
          </Grid>
          <Grid justifyContent="space-between" xs={12} sm={6}>
            <FormControlLabel
              checked={withdrawUser}
              control={<Switch color="primary" />}
              label={t("withdraw")}
              labelPlacement="start"
              onChange={handelWithdraw}
            />
          </Grid>
          <Grid justifyContent="space-between" xs={12} sm={6}>
            <FormControlLabel
              checked={transferOutUser}
              control={<Switch color="primary" />}
              label={t("transferOut")}
              labelPlacement="start"
              onChange={handelTransferOut}
            />
          </Grid>
        </Paper>
        {!loading && (
          <TreasurersTable
            treasurers={treasurersList}
            addTreasurers={addTreasurersDialog}
            setTreasurersList={setTreasurersList}
          />
        )}

        <Grid
          container
          justifyContent="flex-end"
          className={classesForm.spacing}
        >
          <FormButton disabled={saveSafeLoading}>
            {saveSafeLoading ? <ButtonLoading /> : t("save")}
          </FormButton>
        </Grid>
      </Grid>
    </RootStyleForm>
  );
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.safe && safeId ? (
    <NotFound />
  ) : (
    <>
      {dialog && treasurersForm}
      {body}
    </>
  );
};

export default SafeForm;

function TreasurersForm({
  handleSubmit,
  onSubmitTreasurers,
  control,
  errors,
  parseData,
  onChangeNames,
  treasurersIndex,
  treasurersList,
  setSelectedNames,
  treasurersErrorMessage,
  handelCloseDialog,
  dialog,
}) {
  const { t } = useTranslation();
  const [autocompleteValues, setAutocompleteValues] = useState({
    user: null,
  });
  useEffect(() => {
    if (treasurersIndex.update) {
      const update = treasurersList[treasurersIndex.index];
      setSelectedNames({ user: update?.user?.username });
      setAutocompleteValues({
        user: { id: update?.user?.id, name: update?.user?.username },
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const body = (
    <>
      <CustomAutocomplete
        control={control}
        errors={errors}
        rules={{ required: t("fieldIsRequired") }}
        name={"userId"}
        label={t("treasurers")}
        parseData={(data) => parseData(data)}
        query={LIST_USERS_DROPDOWN.query}
        onChangeValue={(e) => {
          onChangeNames(e, "user");
        }}
        hideCode={true}
        variables={{
          input: {
            accountId: null,
            active: true,
          },
        }}
        defaultValue={autocompleteValues.user}
      />
      {treasurersErrorMessage && (
        <FormHelperText error>{t("thisSecretaryAlreadyExists")}</FormHelperText>
      )}
    </>
  );
  return (
    <CustomDialog
      fullWidth
      maxWidth="xs"
      open={dialog}
      onClose={handelCloseDialog}
      title={t("addTreasurers")}
      content={body}
      actions={
        <>
          <Button onClick={handelCloseDialog}>{t("cancel")}</Button>
          <Button onClick={handleSubmit(onSubmitTreasurers)}>
            {t("confirm")}
          </Button>
        </>
      }
    ></CustomDialog>
  );
}
