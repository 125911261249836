/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Paper, Icon, IconButton, Box, Button } from "@mui/material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { VENDOR_ID } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import { Image } from "@mui/icons-material";
import config from "../../config.json";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";
const VendorView = (props) => {
  const { t } = useTranslation();
  const vendorId = props.match.params.id?.trim();
  const user = Globals.user;
  const [dialogDetails, setDialogDetails] = useState({
    state: false,
    function: null,
    confirmAction: true,
    title: "",
    content: "",
  });
  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;
    console.log(
      `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`
    );

    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };
  const closeConfirmationDialog = () => {
    setDialogDetails((prev) => ({
      ...prev,
      state: false,
    }));
  };
  const openSignatureDialog = (imgPath) => {
    setDialogDetails((prev) => ({
      state: true,
      title: null,
      content: (
        <Box
          sx={{ background: "#fff", width: "100%" }}
          component="img"
          alt="signature"
          src={getBackendUri(imgPath.path)}
        />
      ),
      confirmAction: false,
      function: () => {},
    }));
  };
  const { loading, data } = useQuery(
    gql`
      ${VENDOR_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(vendorId) },
    }
  );
  const canEdit = user.hasPermission("freight.vendor.update");

  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/vendors/${vendorId}/edit`),
      permission: canEdit,
    },
  ];
  const vendorData = data?.vendor;
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !vendorData ? (
    <NotFound />
  ) : (
    <>
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>
      {dialogDetails.state && (
        <CustomDialog
          title={dialogDetails.title}
          fullWidth
          maxWidth="xs"
          onClose={closeConfirmationDialog}
          content={dialogDetails.content}
          open={dialogDetails.state}
          actions={
            dialogDetails.function !== null && (
              <>
                <Button color="primary" onClick={closeConfirmationDialog}>
                  {dialogDetails.confirmAction ? t("cancel") : t("close")}
                </Button>
                {dialogDetails.confirmAction && (
                  <Button color="primary" onClick={dialogDetails.function}>
                    {t("confirm")}
                  </Button>
                )}
              </>
            )
          }
        >
          {dialogDetails.function === null && " "}
        </CustomDialog>
      )}
      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair title={t("code")} value={vendorData?.code} />
          <KeyValuePair title={t("name")} value={vendorData?.name} />
          <KeyValuePair title={t("date")} value={vendorData?.date} />
          <KeyValuePair
            title={t("creditLimit")}
            value={vendorData?.creditLimit}
          />
          <KeyValuePair
            title={t("currency")}
            value={vendorData?.currency?.name}
          />
          <KeyValuePair
            title={t("glAccount")}
            value={vendorData?.glAccount?.name}
          />
          <KeyValuePair
            title={t("idIssueDate")}
            value={vendorData?.idIssueDate}
          />
          <KeyValuePair title={t("idType")} value={vendorData?.idType?.name} />
          <KeyValuePair title={t("idNumber")} value={vendorData?.idNumber} />
          <KeyValuePair
            title={t("branch")}
            value={
              <SpanLink pathname={`/admin/branches/${vendorData?.branch?.id}`}>
                {vendorData?.branch?.name}
              </SpanLink>
            }
          />
          <KeyValuePair
            title={t("country")}
            value={
              <SpanLink
                pathname={`/admin/countries/${vendorData?.country?.id}`}
              >
                {vendorData?.country?.name}
              </SpanLink>
            }
          />
          <KeyValuePair title={t("state")} value={vendorData?.state?.name} />
          <KeyValuePair
            title={t("category")}
            value={vendorData?.category?.name}
          />
          <KeyValuePair title={t("address")} value={vendorData?.address} />
          <KeyValuePair title={t("email")} value={vendorData?.email} />
          <KeyValuePair
            title={t("phone")}
            value={<span dir="ltr">{vendorData?.phone}</span>}
          />
          <KeyValuePair
            title={t("mobile")}
            value={<span dir="ltr">{vendorData?.mobile}</span>}
          />
          <KeyValuePair
            title={t("businessName")}
            value={vendorData?.businessName}
          />
          <KeyValuePair
            title={t("businessField")}
            value={vendorData?.businessField}
          />
          <KeyValuePair
            title={t("businessActivity")}
            value={vendorData?.businessActivity}
          />
          <KeyValuePair
            title={t("responsibleName")}
            value={vendorData?.responsibleName}
          />

          {vendorData?.image ? (
            <KeyValuePair
              title={t("image")}
              value={
                <IconButton
                  size="small"
                  onClick={() => openSignatureDialog(vendorData?.image)}
                >
                  <Image fontSize="small" />
                </IconButton>
              }
            />
          ) : (
            <KeyValuePair title={t("image")}></KeyValuePair>
          )}
          <KeyValuePair
            title={t("postalCode")}
            value={vendorData?.postalCode}
          />
          <KeyValuePair
            title={t("mailBoxNumber")}
            value={vendorData?.mailBoxNumber}
          />
          <KeyValuePair
            title={t("registerationNumber")}
            value={vendorData?.registerationNumber}
          />
          <KeyValuePair
            title={t("active")}
            value={
              vendorData?.active ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
        </Paper>
      </RootStyleView>
    </>
  );
};

export default VendorView;
