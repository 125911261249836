import * as gqlb from "gql-query-builder";
const fields = ["id", "code", "name", "active"];
export const LIST_COUNTRIES = gqlb.query({
  operation: "listCountries",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["count", "currentPage", "lastPage", "perPage", "total"],
      variables: {},
    },
    {
      operation: "data",
      fields: fields,
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListCountriesFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const COUNTRY_ID = gqlb.query({
  operation: "country",
  fields: [...fields, { states: ["id", "name", "code", "active"] }],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_COUNTRY = gqlb.mutation({
  operation: "saveCountry",
  fields: ["id", "code", "name", "active"],
  variables: {
    input: {
      type: "CountryInput",
      required: true,
    },
  },
});
export const SAVE_STATE = gqlb.mutation({
  operation: "saveState",
  fields: ["id", "code", "name", "active"],
  variables: {
    input: {
      type: "StateInput",
      required: true,
    },
  },
});
