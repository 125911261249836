import React, { useState } from "react";
import {
  Paper,
  Collapse,
  Stack,
  FormControlLabel,
  Switch,
  Typography,
  Box,
} from "@mui/material";
import { SAVE_TAX, TAX_ID } from "./Graphql";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import FormButton from "../CustomComponents/Buttons/FormButton";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
// import { Globals } from "../HOC/Classes/Globals";
// import { useHistory } from "react-router";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import FlightIcon from "@mui/icons-material/Flight";
import DirectionsBoatFilledOutlinedIcon from "@mui/icons-material/DirectionsBoatFilledOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import { TbPackageExport } from "react-icons/tb";
import { TbPackageImport } from "react-icons/tb";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";

const TaxForm = (props) => {
  const [pathURL] = useState(props.match.path);
  const [active, setActive] = useState(true);
  // const history = useHistory();
  const { t } = useTranslation(["translation", "validation"]);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(parseInt(props.match.params.id));
  const { handleSubmit, control, formState, setValue, setError } = useForm();
  const { errors } = formState;
  const [autocompleteValues, setAutocompleteValues] = useState({
    type: null,
    glAccount: null,
  });

  const [saveTax, { loading: saveTaxLoading }] = useMutation(
    gql`
      ${SAVE_TAX.query}
    `
  );

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActive(active);
  };
  const parseData = (data) => {
    return data;
  };

  const { data: updateTax, loading: updateTaxLoading } = useQuery(
    gql`
      ${TAX_ID.query}
    `,

    {
      skip: !id,
      variables: { id: id },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const taxData = data.tax;
        setActive(taxData.active);
        const params = [
          "code",
          "name",
          "active",
          "rate",
          "import",
          "export",
          "air",
          "land",
          "ocean",
        ];
        params.forEach((i) => {
          taxData[i] !== null && setValue(i, taxData[i]);
        });
        setAutocompleteValues((prev) => ({
          ...prev,
          type: taxData.type,
          glAccount: taxData.glAccount,
        }));
      },
    }
  );

  const onSubmit = (data) => {
    for (const key in data) {
      if (["rate"].includes(key)) {
        data[key] = parseFloat(data[key]);
      }
      if (data[key] === "" && key === "code") {
        delete data[key];
      }
      if (data[key] === "") {
        data[key] = null;
        continue;
      }
    }

    saveTax({
      variables: {
        input: {
          ...data,
          active: active,
          ...(id && { id: id }),
        },
      },
    })
      .then((data) => {
        setId(data?.data?.saveTax?.id);
        pushUrl(props, `/admin/tax/${data?.data?.saveTax?.id}`);
        // const url = history.createHref({
        //   pathname: `/admin/package-types/${data?.data?.savePackageType?.id}/edit`,
        // });
        // false && setPathURL(url);
        // false && windowReplaceUrl(url);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid
          justifyContent="end"
          sx={{ display: "flex", flexWrap: "wrap" }}
          xs={12}
        >
          <FormControlLabel
            checked={active}
            control={<Switch color="primary" />}
            label={t("active")}
            labelPlacement="start"
            onChange={handelActivate}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"code"}
            label={t("code")}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"name"}
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            type={"number"}
            name={"rate"}
            label={t("rate")}
            rules={{
              required: t("fieldIsRequired"),
              min: {
                value: 0,
                message: t("validation:min", {
                  field: t("length"),
                  number: "0",
                }),
              },
            }}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <CustomAutocomplete
            valueKey="code"
            control={control}
            errors={errors}
            name={"type"}
            label={t("type")}
            disabled={updateTax && !updateTax?.tax?.editable}
            parseData={(data) => parseData(data)}
            rules={{ required: t("fieldIsRequired") }}
            variables={{ input: { code: "FR_TAX_TYPE" } }}
            query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
            defaultValue={autocompleteValues.type}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"glAccountId"}
            label={t("glAccount")}
            disabled={updateTax && !updateTax?.tax?.editable}
            parseData={(data) => parseData(data)}
            rules={{ required: t("fieldIsRequired") }}
            query={LIST_GL_ACCOUNTS_DROPDOWN.query}
            variables={{
              input: {
                typeCode: "SUB",
              },
            }}
            defaultValue={autocompleteValues.glAccount}
          />
        </Grid>
      </Paper>
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <Paper
            className={clsx(classesForm.spacing)}
            sx={{ p: 1, height: "10rem" }}
          >
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <DirectionsBoatFilledOutlinedIcon />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("ocean")}
                  </Typography>
                </Box>
                <MuiSwitch edge="end" name="ocean" control={control} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <LocalShippingOutlinedIcon />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("land")}
                  </Typography>
                </Box>

                <MuiSwitch edge="end" name="land" control={control} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <FlightIcon />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("air")}
                  </Typography>
                </Box>

                <MuiSwitch edge="end" name="air" control={control} />
              </Stack>
            </Grid>
          </Paper>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Paper
            className={clsx(classesForm.spacing)}
            sx={{ p: 1, height: "10rem" }}
          >
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <TbPackageExport size={"20px"} />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("export")}
                  </Typography>
                </Box>

                <MuiSwitch edge="end" name="export" control={control} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <TbPackageImport size={"20px"} />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("import")}
                  </Typography>
                </Box>

                <MuiSwitch edge="end" name="import" control={control} />
              </Stack>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" className={classesForm.spacing}>
        <FormButton disabled={saveTaxLoading}>
          {saveTaxLoading ? <ButtonLoading /> : t("save")}
        </FormButton>
      </Grid>
    </form>
  );
  let DOM;

  if (id) {
    DOM = updateTax ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return updateTaxLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !updateTax && id ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={pathURL} />
      <Stack spacing={2} p={2}>
        {DOM}
      </Stack>
    </RootStyleForm>
  );
};

export default TaxForm;
