import { createContext, useContext } from "react";

const PriceListContext = createContext();
export const usePriceListContext = () => {
  const context = useContext(PriceListContext);
  if (context === "undefined") {
    throw new Error("PriceListContext was used outside of the provider");
  }
  return context;
};
export const PriceListProvider = ({ children, value }) => {
  return (
    <PriceListContext.Provider value={value}>
      {children}
    </PriceListContext.Provider>
  );
};
