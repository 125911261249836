import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { TableBody, TableRow, Icon } from "@mui/material";
import { LIST_JOURNAL_TYPE } from "./Graphql";
import JournalTypeForm from "./JournalTypeForm";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { Globals } from "../HOC/Classes/Globals";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import { RootStyleList, classes } from "../../GlobalStyles/ListStyle";
const JournalTypeList = (props) => {
  const [open, setOpen] = React.useState(false);
  const [journalTypeObject, setJournalTypeObject] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setJournalTypeObject();
  };

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const { data, loading, refetch } = useQuery(
    gql`
      ${LIST_JOURNAL_TYPE.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
      },
      onCompleted: (data) => {},
    }
  );

  const listJournalTypes = data?.listJournalTypes?.data;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const user = Globals.user;
  const hasCreatePermission = user.hasPermission(
    "accounting.journal_type.create"
  );
  const hasUpdatePermission = user.hasPermission(
    "accounting.journal_type.update"
  );

  const icons = [
    {
      id: "add",
      action: () => handleClickOpen(),
      permission: hasCreatePermission,
    },
  ];

  return (
    <RootStyleList>
      {open && (
        <JournalTypeForm
          open={open}
          handleClose={handleClose}
          refetch={refetch}
          journalTypeObject={journalTypeObject}
        />
      )}
      <ListWrapper
        drawerState={false}
        icons={icons}
        path={props.match.path}
        empty={listJournalTypes?.length === 0}
        loading={loading}
        filters={false}
        tableHeaders={[
          {
            name: "name",
          },
          {
            name: "code",
          },
          {
            name: "branch",
          },
          {
            name: "sequence",
          },
          {
            name: "reference",
          },
          {
            name: "active",
          },
        ]}
        tableBody={
          <TableBody>
            {listJournalTypes &&
              listJournalTypes?.map((row, index) => {
                const branchId = row.branch?.id;
                const canAccessBranch = branchId
                  ? user.canAccessBranch(branchId)
                  : true;

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {(hasUpdatePermission ? canAccessBranch : false) ? (
                      <CellLink
                        onClick={() => {
                          handleClickOpen();
                          setJournalTypeObject(row);
                        }}
                      >
                        {row.name}
                      </CellLink>
                    ) : (
                      <FixedTableCell>{row.name}</FixedTableCell>
                    )}

                    <FixedTableCell>{row.code}</FixedTableCell>
                    <FixedTableCell>{row?.branch?.name}</FixedTableCell>
                    <FixedTableCell>{row.sequence.name}</FixedTableCell>
                    <FixedTableCell>{row.reference.name}</FixedTableCell>
                    <FixedTableCell>
                      {row?.active ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listJournalTypes?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listJournalTypes ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </RootStyleList>
  );
};

export default JournalTypeList;
