import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import NotFound from "../../../Error/NotFound";
import FullScreenLoading from "../../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { dateFormatLL } from "../../../helpers/dateFunctions";
import { zoom } from "./ManifestPrintFunc";
import "./print.css";
import usePagesCount from "./usePagesCount";
// import { GetStatus } from "../../helpers/manifestStatus";
import { useState } from "react";
import LogoImg from "../../../Layout/LogoImg";
import ProductsTable from "./PrintTable";
import { useAccountType } from "../../../Hooks/useAccountType";

const PREFIX = "ManifestPrint";

const classes = {
  headerCells: `${PREFIX}-headerCells`,
  title: `${PREFIX}-title`,
  notes: `${PREFIX}-notes`,
  noteCell: `${PREFIX}-noteCell`,
  barcodeFont: `${PREFIX}-barcodeFont`,
  cairoFont: `${PREFIX}-cairoFont`,
  barcode: `${PREFIX}-barcode`,
  headerTableCell: `${PREFIX}-headerTableCell`,
  mainCellBorder: `${PREFIX}-mainCellBorder`,
  signature: `${PREFIX}-signature`,
  reportHeader: `${PREFIX}-reportHeader`,
  hiddenFooter: `${PREFIX}-hiddenFooter`,
  pageFooter: `${PREFIX}-pageFooter`,
  logoSection: `${PREFIX}-logoSection`,
  headerDate: `${PREFIX}-headerDate`,
  whiteSpace: `${PREFIX}-whiteSpace`,
  approve: `${PREFIX}-approve`,
  rowWithoutBorder: `${PREFIX}-rowWithoutBorder`,
  mainDetailsCell: `${PREFIX}-mainDetailsCell`,
  tableHeadCell: `${PREFIX}-tableHeadCell`,
  tableWidth: `${PREFIX}-tableWidth`,
  shipmentsTable: `${PREFIX}-shipmentsTable`,
  signatureBlock: `${PREFIX}-signatureBlock`,
};

const Root = styled("div")(({ theme }) => ({
  // fontFamily: ['"Cairo"', "sans-serif"].join(","),

  [`& .${classes.cairoFont}`]: {
    // fontFamily: ['"Cairo"', "sans-serif"].join(","),
  },

  [`& .${classes.headerCells}`]: {
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.title}`]: {
    color: theme.palette.info.main,
    // whiteS;pace: "break-spaces",
  },

  [`& .${classes.notes}`]: {
    padding: theme.spacing(1),
    border: "1px solid " + theme.palette.action.disabled,
    wordBreak: "break-all",
    width: "100%",
    minHeight: 66,
  },

  [`& .${classes.noteCell}`]: {
    width: "35%",
    overflow: "hidden",
  },

  [`& .${classes.barcodeFont}`]: {
    fontFamily: '"Libre Barcode 39 Text"',
  },

  [`& .${classes.barcode}`]: {
    fontSize: 40,
    textAlign: "center",
  },

  [`& .${classes.headerTableCell}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classes.mainCellBorder}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classes.signature}`]: {
    textAlign: "center",
    padding: theme.spacing(2, 1, 1, 1),
    border: "none",
  },

  [`&.${classes.reportHeader}`]: {
    whiteSpace: "pre-line",
    paddingLeft: theme.spacing(2),
    lineHeight: "120%",
    fontSize: 12,
  },

  [`& .${classes.hiddenFooter}`]: {
    visibility: "hidden",
  },

  [`& .${classes.pageFooter}`]: {
    //   "&::after": {
    //    " content:  counter(page)  of  counter(pages)"
    //   },
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: "1px solid " + theme.palette.text.secondary,
    display: "flex",
    alignItems: "flex-end",
  },

  [`& .${classes.logoSection}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.headerDate}`]: {
    textAlign: "end",
  },

  [`& .${classes.whiteSpace}`]: {
    "& tr": {
      "& td": {
        whiteSpace: "initial",
      },
    },
  },

  [`& .${classes.approve}`]: {
    textAlign: "end",
  },

  [`& .${classes.rowWithoutBorder}`]: {
    "& td": {
      border: "none",
      padding: "2px",
    },
  },

  [`& .${classes.mainDetailsCell}`]: {
    "& td": { border: "none", verticalAlign: "top" },
    "& td:first-of-type": { paddingLeft: 0 },
    "& td:last-of-type": { paddingRight: "2px" },

    border: "none",
    padding: theme.spacing(1, 0),
  },

  [`& .${classes.tableHeadCell}`]: {
    padding: 0,
  },

  [`& .${classes.tableWidth}`]: {
    width: "100vw",
  },

  [`& .${classes.shipmentsTable}`]: {
    "& tr": {
      "&:hover": {
        backgroundColor: "#ffffff00 !important",
      },
    },
    "& th": {
      padding: theme.spacing(1),
    },
    "& td": {
      padding: theme.spacing(0, 1),
    },
  },

  [`& .${classes.signatureBlock}`]: {
    breakInside: "avoid",
  },
}));

const ManifestPrint = (props) => {
  const manifestId = +props.match.params.id;
  const printType = props.match.params.type;
  const transfer = props.match.params.transfer;
  const manifestTransactionType = props.match.params?.trxType;

  const {
    reportDetails,
    loading,
    organization,
    printTableDetails,
    printTablePickupsDetails,
    printTableConciergeDetails,
    operationName,
    allowedConcierge,
  } = usePagesCount(props);

  const receivedShipment = [];
  transfer &&
    reportDetails?.referredManifests?.map((e) =>
      receivedShipment.push(...e.manifestShipments)
    );
  const referredManifestData = Boolean(transfer);

  const { details: tableDetails } = printTableDetails({
    type: manifestTransactionType ?? printType,
    typeData: reportDetails,
    receivedShipment,
    referredManifestData,
    print: true,
  });

  const { details: tableConciergeDetails } =
    (printType === "payment" || printType === "collection") &&
    printTableConciergeDetails({
      type: manifestTransactionType,
      typeData: reportDetails,
    });

  const { details: tablePickupDetails } =
    printType === "payment" &&
    printTablePickupsDetails({
      type: manifestTransactionType,
      typeData: reportDetails,
    });

  const handelHeaderFooterHeight = (width) => {
    const elementPercentage = (selector) => {
      const element = document.querySelector(selector);
      const rect = element.getBoundingClientRect();
      const pxToCm = rect.height;
      element.style.height = `${pxToCm}px`;
      return pxToCm;
    };
    document.getElementById("page-footer-space").style.width = width + "px";

    const pageFooter = elementPercentage(".page-footer-space");
    const pageHeader = elementPercentage(".page-header-space");
    document.querySelector(".page-footer").style.height = pageFooter + "px";
    document.querySelector(".page-header").style.height = pageHeader + "px";
  };

  const [zoomed, setZoomed] = useState(false);
  useEffect(() => {
    if (reportDetails) {
      if (reportDetails === null) return;
      if (!zoomed) {
        const zoomDetails = zoom();
        handelHeaderFooterHeight(zoomDetails.width);
        setZoomed(true);
        let newLink = document.createElement("link");
        newLink.rel = "stylesheet";
        newLink.type = "text/css";
        newLink.href = process.env.PUBLIC_URL + "/" + zoomDetails?.css;

        document.querySelectorAll("#shipmentsTableContainer").forEach((i) => {
          i.style.minWidth = "100%";
          i.style.width = "100vw";
        });
        // handelHeaderFooterHeight();
        newLink.onload = () => {
          // document.fonts.onloadingdone = function (fontFaceSetEvent) {
          window.print();
          newLink.onload = null;
        };

        document.getElementById("maniContainer").appendChild(newLink);
      }
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePickupDetails, tableDetails, reportDetails]);

  return (
    <Root id="maniContainer">
      {loading ? (
        <>
          <FullScreenLoading minHeight="20%" />
          {/* this spans for download font before print */}
          <span className={clsx(classes.barcodeFont)} />
          <span style={{ opacity: 0 }} className={classes.cairoFont}>
            lا
          </span>
        </>
      ) : reportDetails === null ? (
        <NotFound />
      ) : (
        <>
          {manifestId ? (
            <PrintDetails
              reportDetails={reportDetails}
              organization={organization}
              loading={loading}
              printType={printType}
              tableDetails={tableDetails}
              tablePickupDetails={tablePickupDetails}
              tableConciergeDetails={tableConciergeDetails}
              manifestTransactionType={manifestTransactionType}
              operationName={operationName}
              allowedConcierge={allowedConcierge}
            />
          ) : (
            reportDetails?.map((i, index) => (
              <PrintDetails
                arrayIndex={index + 1}
                key={i.id}
                reportDetails={i}
                organization={organization}
                loading={loading}
                printType={printType}
                tableDetails={tableDetails}
                tablePickupDetails={tablePickupDetails}
                tableConciergeDetails={tableConciergeDetails}
                manifestTransactionType={manifestTransactionType}
                operationName={operationName}
                allowedConcierge={allowedConcierge}
              />
            ))
          )}
          <div className="page-footer">{organization?.reportFooter}</div>
        </>
      )}
    </Root>
  );
};

export default ManifestPrint;

const PrintDetails = (props) => {
  const {
    reportDetails,
    organization,
    loading,
    tableDetails,
    manifestTransactionType,
    operationName,
    printType,
  } = props;
  const { t } = useTranslation();

  const typeCheck = (type) => type === manifestTransactionType;

  // const reportDetails = reportData?.[printType];
  if (reportDetails?.[`${operationName}_0`]) {
    let concatData = [];
    for (const key in reportDetails) {
      if (key.startsWith(`${operationName}_`)) {
        const listData = reportDetails[key].data;
        concatData = concatData.concat(listData);
      }
    }
  }

  const tableData = typeCheck("rcvd")
    ? reportDetails?.request?.packages
    : printType === "operation"
    ? reportDetails?.manifests
    : reportDetails?.lines;
  const userValue = useAccountType(reportDetails?.accountType?.code);
  const header = !loading && reportDetails !== null && (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell sx={{ padding: 0 }}>
            <Table>
              <TableBody>
                <TableRow className={classes.rowWithoutBorder}>
                  <TableCell>
                    <Box className={classes.logoSection}>
                      <LogoImg height={30} forceLight={true} />
                      <Typography
                        sx={{
                          whiteSpace: "pre-line !important",
                          paddingLeft: "10px",
                          lineHeight: "120%",
                          fontSize: 12,
                        }}
                        className={clsx(classes.reportHeader, "landscape-zoom")}
                      >
                        {organization?.reportHeader}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell className={clsx(classes.headerDate)}>
                    <Typography variant="h6">
                      {moment(new Date()).locale("en").format("ll")}
                    </Typography>
                    <TypographyKeyValue
                      title={t("registrationNumber")}
                      value={organization?.registrationNumber}
                    />
                    <TypographyKeyValue
                      title={t("taxNumber")}
                      value={organization?.taxNumber}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className={classes.headerTableCell}>
            <Table className={classes.whiteSpace}>
              <TableBody>
                <TableRow className={classes.rowWithoutBorder}>
                  <TableCell>
                    <Typography variant="h5">
                      {reportDetails?.transactionType?.name ??
                        reportDetails?.type?.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={clsx(classes.barcode, classes.barcodeFont)}
                    >
                      *{reportDetails.code}*
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TypographyKeyValue
                      variant="h6"
                      className={clsx(classes.keyValue, classes.approve)}
                      title={t("status")}
                      value={
                        reportDetails?.approved
                          ? t("approved")
                          : t("notApproved")
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell
            className={clsx(classes.mainDetailsCell, "landscape-zoom")}
          >
            <Table className={classes.whiteSpace}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <TypographyKeyValue
                      title={t("theDate")}
                      value={dateFormatLL(reportDetails.operationDate)}
                    />
                    <TypographyKeyValue
                      title={t("theDate")}
                      value={dateFormatLL(reportDetails.billDate)}
                    />
                    <TypographyKeyValue
                      title={t("theDate")}
                      value={dateFormatLL(reportDetails.invoiceDate)}
                    />
                    <TypographyKeyValue
                      title={t("branch")}
                      value={reportDetails.branch?.name}
                    />
                    <TypographyKeyValue
                      title={t("transactionType")}
                      value={reportDetails.transactionType?.name}
                    />

                    <TypographyKeyValue
                      title={t("documentType")}
                      value={reportDetails?.documentType?.name}
                    />
                    <TypographyKeyValue
                      title={t("paymentType")}
                      value={reportDetails?.paymentType?.name}
                    />
                    <TypographyKeyValue
                      title={t("journalEntry")}
                      value={reportDetails?.journalEntry?.code}
                    />
                  </TableCell>
                  <TableCell>
                    <TypographyKeyValue
                      title={t("wareHouse")}
                      value={
                        reportDetails.warehouse?.id &&
                        reportDetails?.warehouse?.name
                      }
                    />
                    {printType === "operation" && (
                      <>
                        <TypographyKeyValue
                          title={t("shipmentCode")}
                          value={reportDetails?.shipment?.code}
                        />
                        <TypographyKeyValue
                          title={t("requestCode")}
                          value={reportDetails?.request?.code}
                        />
                      </>
                    )}

                    <TypographyKeyValue
                      title={t("accountType")}
                      value={reportDetails?.accountType?.name}
                    />

                    <TypographyKeyValue
                      title={userValue?.label}
                      value={reportDetails[userValue?.queryName]?.name}
                    />

                    <TypographyKeyValue
                      title={t("currency")}
                      value={reportDetails?.currency?.name}
                    />
                    <TypographyKeyValue
                      title={t("exchangeRate")}
                      value={reportDetails?.exchangeRate}
                    />
                  </TableCell>
                  <TableCell className={classes.noteCell}>
                    <Box component="div" className={classes.notes}>
                      <Typography variant="body2">
                        {reportDetails.remarks}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
        {printType !== "operation" && (
          <TableRow>
            <TableCell
              className={clsx(classes.mainDetailsCell, "landscape-zoom")}
            >
              {
                <Table className={classes.whiteSpace}>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <TypographyKeyValue
                          title={t("shipmentCode")}
                          value={reportDetails?.shipment?.code}
                        />
                        <TypographyKeyValue
                          title={t("requestCode")}
                          value={reportDetails?.request?.code}
                        />
                        <TypographyKeyValue
                          title={t("requestDate")}
                          value={reportDetails?.request?.requestDate}
                        />
                        <TypographyKeyValue
                          title={t("shipmentDate")}
                          value={reportDetails?.shipment?.shipmentDate}
                        />

                        <TypographyKeyValue
                          title={t("shippingMode")}
                          value={
                            reportDetails?.request?.shippingMode?.name ??
                            reportDetails?.shipment?.shippingMode?.name
                          }
                        />
                        <TypographyKeyValue
                          title={t("loadingMode")}
                          value={
                            reportDetails?.request?.loadingMode?.name ??
                            reportDetails?.shipment?.loadingMode?.name
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TypographyKeyValue
                          title={t("senderName")}
                          value={
                            reportDetails?.request?.shipperName ??
                            reportDetails?.shipment?.shipperName
                          }
                        />
                        <TypographyKeyValue
                          title={t("senderState")}
                          value={
                            reportDetails?.request?.shipperState?.name ??
                            reportDetails?.shipment?.shipperState?.name
                          }
                        />
                        <TypographyKeyValue
                          title={t("senderPhone")}
                          value={
                            (reportDetails?.request?.shipperMobile ||
                              reportDetails?.shipment?.shipperMobile) && (
                              <span dir="ltr">
                                {reportDetails?.request?.shipperMobile ??
                                  reportDetails?.shipment?.shipperMobile}
                              </span>
                            )
                          }
                        />
                        <TypographyKeyValue
                          title={t("senderAddress")}
                          value={
                            reportDetails?.request?.shipperAddress ??
                            reportDetails?.shipment?.shipperAddress
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TypographyKeyValue
                          title={t("consigneeName")}
                          value={
                            reportDetails?.request?.consigneeName ??
                            reportDetails?.shipment?.consigneeName
                          }
                        />
                        <TypographyKeyValue
                          title={t("recipientState")}
                          value={
                            reportDetails?.request?.consigneeState?.name ??
                            reportDetails?.shipment?.consigneeState?.name
                          }
                        />
                        <TypographyKeyValue
                          title={t("recipientPhone")}
                          value={
                            (reportDetails?.request?.consigneeMobile ||
                              reportDetails?.shipment?.consigneeMobile) && (
                              <span dir="ltr">
                                {reportDetails?.request?.consigneeMobile ??
                                  reportDetails?.shipment?.consigneeMobile}
                              </span>
                            )
                          }
                        />
                        <TypographyKeyValue
                          title={t("recipientAddress")}
                          value={
                            reportDetails?.request?.consigneeAddress ??
                            reportDetails?.shipment?.consigneeAddress
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              }
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
  return (
    <div id="printManifestContainer">
      <div className="page-header">{header}</div>

      <table>
        <thead>
          <tr>
            <td>
              <div className="page-header-space">{header}</div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div
                className={clsx(classes.shipmentsTable, {})}
                id="shipmentsTableContainer"
              >
                {tableDetails && (
                  <ProductsTable
                    id="manifestPrintTable"
                    className="page-content"
                    data={tableData}
                    headCells={tableDetails.tableHead}
                    parseBodyCell={tableDetails.tableBody}
                    total={tableDetails.tableTotal}
                  />
                )}
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot
          id="page-footer-space"
          style={{ display: "flex", width: "100px" }}
        >
          <tr>
            <td>
              <div className="page-footer-space">
                {/* {organization.reportFooter} */}
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

function TypographyKeyValue(props) {
  const { title, value, hidden, ...restProps } = props;
  return hidden || !value ? null : (
    <Typography
      variant="body2"
      className={clsx(classes.keyValue)}
      {...restProps}
    >
      <span className={classes.title}>{title}:</span> <span>{value}</span>
    </Typography>
  );
}
