import * as gqlb from "gql-query-builder";

const fields = ["id", "name", "code"];

export const LIST_BRANCHES_DROPDOWN = gqlb.query({
  operation: "listBranchesDropdown",
  fields,
  variables: {
    input: {
      type: "ListBranchesFilterInput",
    },
  },
});

export const LIST_ZONES_DROPDOWN = gqlb.query({
  operation: "listZonesDropdown",
  fields,
  variables: {
    input: {
      type: "ListZonesFilterInput",
    },
  },
});

export const LIST_GL_ACCOUNTS_DROPDOWN = gqlb.query({
  operation: "listGLAccountsDropdown",
  fields,
  variables: {
    input: {
      type: "ListGLAccountsFilterInput",
    },
  },
});

export const LIST_JOURNAL_TYPES_DROPDOWN = gqlb.query({
  operation: "listJournalTypesDropdown",
  fields,
  variables: {
    input: {
      type: "ListJournalTypesFilterInput",
    },
  },
});

export const LIST_SUBSIDIARIES_DROPDOWN = gqlb.query({
  operation: "listSubsidiariesDropdown",
  fields,
  variables: {},
});

export const LIST_COUNTRIES_DROPDOWN = gqlb.query({
  operation: "listCountriesDropdown",
  fields,
  variables: {},
});

export const LIST_STATES_DROPDOWN = gqlb.query({
  operation: "listStatesDropdown",
  fields,
  variables: {
    countryId: {
      type: "Int",
    },
  },
});

export const LIST_CITIES_DROPDOWN = gqlb.query({
  operation: "listCitiesDropdown",
  fields,
  variables: {
    stateId: {
      type: "Int",
    },
  },
});

export const LIST_AREAS_DROPDOWN = gqlb.query({
  operation: "listAreasDropdown",
  fields,
  variables: {
    cityId: {
      type: "Int",
    },
  },
});

export const LIST_SEQUENCES_DROPDOWN = gqlb.query({
  operation: "listSequencesDropdown",
  fields,
  variables: {},
});

export const LIST_DRIVERS_DROPDOWN = gqlb.query({
  operation: "listDriversDropdown",
  fields,
  variables: {},
});

export const LIST_VEHICLES_DROPDOWN = gqlb.query({
  operation: "listVehiclesDropdown",
  fields,
  variables: {},
});

export const LIST_CUSTOMERS_DROPDOWN = gqlb.query({
  operation: "listCustomersDropdown",
  fields,
  variables: {
    input: {
      type: "ListCustomersFilterInput",
    },
  },
});
export const LIST_CUSTOM_AGENT_DROPDOWN = gqlb.query({
  operation: "listCustomAgentsDropdown",
  fields,
  variables: {
    input: {
      type: "ListCustomAgentsFilterInput",
    },
  },
});
export const LIST_AGENCIES_DROPDOWN = gqlb.query({
  operation: "listAgenciesDropdown",
  fields,
  variables: {
    input: {
      type: "ListAgenciesFilterInput",
    },
  },
});
export const LIST_CARRIER_DROPDOWN = gqlb.query({
  operation: "listCarriersDropdown",
  fields,
  variables: {
    input: {
      type: "ListCarriersFilterInput",
    },
  },
});

export const LIST_DELIVERY_AGENTS_DROPDOWN = gqlb.query({
  operation: "listDeliveryAgentsDropdown",
  fields,
  variables: {
    input: {
      type: "ListDeliveryAgentsFilterInput",
    },
  },
});

export const LIST_USERS_DROPDOWN = gqlb.query({
  operation: "listUsersDropdown",
  fields,
  variables: {
    input: {
      type: "ListUsersFilterInput",
    },
  },
});

export const LIST_SAFES_DROPDOWN = gqlb.query({
  operation: "listSafesDropdown",
  fields,
  variables: {
    input: {
      type: "ListSafesFilterInput",
    },
  },
});

export const LIST_ROLES_DROPDOWN = gqlb.query({
  operation: "listRolesDropdown",
  fields,
  variables: {},
});

export const LIST_CANCELLATION_REASONS_DROPDOWN = gqlb.query({
  operation: "listCancellationReasonsDropdown",
  fields,
  variables: {
    input: {
      type: "ListCancellationReasonsFilterInput",
    },
  },
});

export const LIST_COMMISSION_LSIT_DROPDOWN = gqlb.query({
  operation: "listCommissionListsDropdown",
  fields,
  variables: {},
});
export const LIST_COMMODITIES_DROPDOWN = gqlb.query({
  operation: "listCommoditiesDropdown",
  fields,
  variables: {},
});

export const LIST_PRICE_LIST_DROPDOWN = gqlb.query({
  operation: "listPriceListsDropdown",

  fields,

  variables: {
    input: {
      type: "ListPriceListsFilterInput",
    },
  },
});

export const LIST_ROUTES_DROPDOWN = gqlb.query({
  operation: "listRoutesDropdown",
  fields,
  variables: {},
});
// export const LIST_BANK_DROPDOWN = gqlb.query({
//   operation: "listBanksDropdown",
//   fields,
//   variables: {},
// });

export const LIST_TRANSACTION_TYPES_DROPDOWN = gqlb.query({
  operation: "listTransactionTypesDropdown",
  fields,
  variables: {
    input: {
      type: "ListTransactionTypesFilterInput",
    },
  },
});

export const LIST_SHIPPING_SERVICES_DROPDOWN = gqlb.query({
  operation: "listShippingServicesDropdown",
  fields,
  variables: {
    input: {
      type: "ListShippingServicesFilterInput",
    },
  },
});
export const LIST_SHIPMENT_TYPES_DROPDOWN = gqlb.query({
  operation: "listShipmentTypesDropdown",
  fields,
  variables: {
    serviceId: {
      type: "Int",
    },
  },
});
export const LIST_LOOKUP_ENTRIES_DROPDOWN = gqlb.query({
  operation: "listLookupEntriesDropdown",
  fields,
  variables: {
    input: {
      type: "ListLookupEntriesFilterInput",
      required: true,
    },
  },
});
export const LIST_CONSIGNEES_DROPDOWN = gqlb.query({
  operation: "listConsigneesDropdown",
  fields,
  variables: {
    input: {
      type: "ListConsigneesFilterInput",
      required: true,
    },
  },
});
export const LIST_STATEMENTS_DROPDOWN = gqlb.query({
  operation: "listStatementsDropdown",
  fields,
  variables: {},
});

export const LIST_WAREHOUSE_DROPDOWN = gqlb.query({
  operation: "listWarehousesDropdown",
  fields,
  variables: {
    input: {
      type: "ListWarehousesFilterInput",
    },
  },
});
export const LIST_WAREHOUSE_MANIFEST_DROPDOWN = gqlb.query({
  operation: "listWarehouseManifestsDropdown",
  fields,
  variables: {
    input: {
      type: "ListWarehouseManifestFilterInput",
      required: true,
    },
  },
});
export const LIST_PRODUCT_DROPDOWN = gqlb.query({
  operation: "listProductsDropdown",
  fields,
  variables: {
    input: {
      type: "ListProductsFilterInput",
    },
  },
});
export const LIST_PAYMENT_TYPES_DROPDOWN = gqlb.query({
  operation: "listPaymentTypesDropdown",
  fields,
  variables: {
    input: {
      type: "ListPaymentTypesDropdownInput",
    },
  },
});
export const LIST_SUPPORT_CATEGORIES_DROPDOWN = gqlb.query({
  operation: "listSupportCategoriesDropdown",
  fields,
  variables: {
    input: {
      type: "ListSupportCategoriesFilterInput",
    },
  },
});
export const LIST_MEMBERS_DROPDOWN = gqlb.query({
  operation: "listMembersDropdown",
  fields,
  variables: {
    input: {
      type: "ListMembersFilterInput",
      required: true,
    },
  },
});
export const LIST_PAYMENT_DROPDOWN = gqlb.query({
  operation: "listPaymentsDropdown",
  fields,
  variables: {
    input: {
      type: "ListPaymentFilterInput",
      required: true,
    },
  },
});
export const LIST_MANIFEST_DROPDOWN = gqlb.query({
  operation: "listManifestsDropdown",
  fields,
  variables: {
    input: {
      type: "ListManifestsFilterInput",
      required: true,
    },
  },
});
export const LIST_CURRENCIES_DROPDOWN = gqlb.query({
  operation: "listCurrenciesDropdown",
  fields,
  variables: {
    input: {
      type: "ListCurrenciesFilterInput",
    },
  },
});
export const LIST_CONCIERGE_PROVIDERS_DROPDOWN = gqlb.query({
  operation: "listConciergeProvidersDropdown",
  fields,
  variables: {
    input: {
      type: "ListConciergeProvidersFilterInput",
    },
  },
});
export const LIST_ENUM_DROPDOWN = gqlb.query({
  operation: "__type",
  fields: [
    "name",
    {
      enumValues: ["name"],
    },
  ],
  variables: {
    name: {
      type: "String",
      required: true,
    },
  },
});
export const LIST_BANKS_DROPDOWN = gqlb.query({
  operation: "listBanksDropdown",
  fields,
  variables: {
    input: {
      type: "ListBanksFilterInput",
    },
  },
});
export const LIST_SHIPPING_PORTS_DROPDOWN = gqlb.query({
  operation: "listShippingPortsDropdown",
  fields,
  variables: {
    input: {
      type: "ListShippingPortsFilterInput",
    },
  },
});
export const LIST_PACKAGE_TYPES_DROPDOWN = gqlb.query({
  operation: "listPackageTypesDropdown",
  fields,
  variables: {
    input: {
      type: "ListPackageTypesFilterInput",
    },
  },
});
export const LIST_EMPLOYEE_DROPDOWN = gqlb.query({
  operation: "listEmployeesDropdown",
  fields,
  variables: {
    input: {
      type: "ListEmployeesFilterInput",
    },
  },
});
export const LIST_FINANCIAL_SHEETS_DROPDOWN = gqlb.query({
  operation: "listFinancialSheetsDropDown",
  fields,
  variables: {
    input: {
      type: "ListFinancialSheetsFilterInput",
    },
  },
});
export const LIST_CHARGE_ELEMENT_DROPDOWN = gqlb.query({
  operation: "listChargeElementsDropdown",
  fields,
  variables: {
    input: {
      type: "ListChargeElementsFilterInput",
    },
  },
});
export const LIST_VENDORS_DROPDOWN = gqlb.query({
  operation: "listVendorsDropdown",
  fields,
  variables: {
    input: {
      type: "ListVendorsFilterInput",
    },
  },
});
export const LIST_CHARGE_ELEMENTS_DROPDOWN = gqlb.query({
  operation: "listChargeElementsDropdown",
  fields,
  variables: {
    input: {
      type: "ListChargeElementsFilterInput",
    },
  },
});
export const LIST_TAXES_DROPDOWN = gqlb.query({
  operation: "listTaxesDropDown",
  fields,
  variables: {
    input: {
      type: "ListTaxesFilterInput",
    },
  },
});
export const LIST_BANK_BRANCHES_DROPDOWN = gqlb.query({
  operation: "listBankBranchesDropdown",
  fields,
  variables: {
    input: {
      type: "ListBankBranchFilterInput",
    },
  },
});
export const LIST_BANK_ACCOUNT_DROPDOWN = gqlb.query({
  operation: "listBankAccountsDropdown",
  fields,
  variables: {
    input: {
      type: "ListBankAccountsFilterInput",
    },
  },
});
export const LIST_PAYMENT_METHODS_DROPDOWN = gqlb.query({
  operation: "listPaymentMethodsDropdown",
  fields,
  variables: {},
});
export const LIST_INCO_TERMS_DROPDOWN = gqlb.query({
  operation: "listIncoTermsDropdown",
  fields,
  variables: {
    input: {
      type: "ListIncoTermsFilterInput",
    },
  },
});
