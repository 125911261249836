import * as gqlb from "gql-query-builder";
const fields = [
  "id",
  "code",
  "name",
  "active",
  "import",
  "air",
  "land",
  "ocean",
  "export",
  {
    type: ["code", "name"],
  },
  {
    sequence: ["id", "name"],
  },
  {
    branch: ["id", "name"],
  },
  {
    glAccount: ["id", "name"],
  },
  {
    journalType: ["id", "name"],
  },
  {
    subsidiary: ["id", "name"],
  },
];
export const LIST_TRANSACTION_TYPES = gqlb.query({
  operation: "listTransactionTypes",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: fields,
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListTransactionTypesFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const SAVE_TRANSACTION_TYPE = gqlb.mutation({
  operation: "saveTransactionType",
  fields: ["id", "code", "active"],
  variables: {
    input: {
      type: "TransactionTypeInput",
      required: true,
    },
  },
});

export const TRANSACTION_TYP_ID = gqlb.query({
  operation: "transactionType",
  fields: fields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
