/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  Toolbar,
  IconButton,
  Collapse,
  InputAdornment,
  CircularProgress,
  TableHead,
  TableRow,
  Checkbox,
  Icon,
} from "@mui/material";
import { Cancel, CheckCircle } from "@mui/icons-material";
import clsx from "clsx";
// import MUItextField from "../../HOC/MUI/MUItextField";
import PropTypes from "prop-types";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
// import CustomTableRow from "./CustomTableRow";
// import { EnhancedTableHead } from "./ShipmentListDetails";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
// import CustomSpinner from "../../HOC/FunctionComponents/CustomSpinner";
// import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";
// import ManifestShipmentsProvider from "./ManifestShipmentsProvider";
// import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import Backdrop from "@mui/material/Backdrop";
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
// import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
// import { EnhancedTableHead } from "./CommoditiesList/CommoditiesListDetails";
// import CustomTableRow, { EnhancedTableHead } from "./CustomTableRow";
// import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
// import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import MUItextField from "../../HOC/MUI/MUItextField";
import { CREATE_MANIFEST, LIST_PACKAGE_DETAILS } from "../Graphql";
import { useTabsContext } from "../../HOC/CustomComponents/PackagesTableView/TabContext";
import CustomSpinner from "../../HOC/FunctionComponents/CustomSpinner";
// import { SAVE_MANIFEST_ENTRIES_MUTATION } from "../ManifestGraphQl";
// import { SHIPMENT_QUERY } from "./listShipmentsForCollection";
// import soundSuccess from "../../../assets/Sound/success.mp3";
// import soundFail from "../../../assets/Sound/fail.mp3";
import soundSuccess from "../../../assets/Sound/success.mp3";
import soundFail from "../../../assets/Sound/fail.mp3";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import CellLink from "../../HOC/CustomComponents/CellLink";
const TOOLBAR_PREFIX = "styledToolbar";

const toolbarClasses = {
  highlight: `${TOOLBAR_PREFIX}-highlight`,
  title: `${TOOLBAR_PREFIX}-title`,
  filterButton: `${TOOLBAR_PREFIX}-filterButton`,
};

const StyledToolbar = styled("div")(({ theme }) => ({
  width: "100%",
  zIndex: 1,

  [`& .${toolbarClasses.highlight}`]:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.grey[300],
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.grey[800],
        },

  [`& .${toolbarClasses.title}`]: {
    flex: "1 1 100%",
  },

  [`& .${toolbarClasses.filterButton}`]: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const PREFIX = "BarcodeSelector";

const classes = {
  track: `${PREFIX}-track`,
  paper: `${PREFIX}-paper`,
  filters: `${PREFIX}-filters`,
  barcode: `${PREFIX}-barcode`,
  iconColor: `${PREFIX}-iconColor`,
};

const Root = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0, 0),
  width: "100%",
  backgroundColor: theme.palette.background.paper,

  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.paper}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    overflowX: "auto",
    maxHeight: `calc(100vh - ${theme.mixins.toolbar["minHeight"]}px)`,
  },

  [`& .${classes.filters}`]: {
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 72px)",
    },
    [theme.breakpoints.up("sm")]: {
      width: "360px",
    },

    height: "100%",
    overflow: "hidden",
  },
  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 30,
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const { numSelected, done, clear, disabled } = props;
  return (
    <StyledToolbar>
      <Toolbar
        className={clsx(
          toolbarClasses.toolbar,
          {
            [toolbarClasses.highlight]: numSelected > 0,
          },
          classes.header
        )}
      >
        {numSelected > 0 ? (
          <Typography
            className={toolbarClasses.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {t("packagesCount", { count: numSelected })}
          </Typography>
        ) : (
          <Typography
            className={toolbarClasses.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t("packages")}
          </Typography>
        )}
        <IconButton
          aria-label="done"
          disabled={disabled}
          onClick={done}
          sx={{ color: "success.main" }}
          size="large"
        >
          <CheckCircle />
        </IconButton>
        {/* </Tooltip> */}

        <IconButton
          aria-label="close"
          onClick={() => clear()}
          color={"primary"}
          size="large"
        >
          <Cancel />
        </IconButton>
      </Toolbar>
    </StyledToolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  done: PropTypes.func.isRequired,
};

//*********Table Function*********

const BarcodeSelector = (props) => {
  const { done, type } = props;
  const { container, operationId, handleManifest } = useTabsContext();
  const audioSuccess = new Audio(soundSuccess);
  const audioFail = new Audio(soundFail);
  const [shipments, setShipments] = useState([]);
  // const manifestId = variables.id;

  const [packageCode, setPackageCode] = useState();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { register, setValue } = useForm();

  const { loading } = useQuery(
    gql`
      ${LIST_PACKAGE_DETAILS.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !packageCode,
      variables: {
        input: {
          barcode: packageCode,
          ...(type &&
            type?.toUpperCase() === "UNLOADED" && {
              containerId: container,
            }),
          inOperation: {
            inOperation: false,
            ...(type && {
              type: type?.toUpperCase(),
            }),
            // ...(type &&
            //   type?.toUpperCase() === "UNLOADED" && {
            //     type: "UNLOADED",
            //   }),
            // ...(type &&
            //   type?.toUpperCase() === "DELIVERED" && {
            //     type: "DELIVERED",
            //   }),
          },
          damaged: false,
          missing: false,
        },
      },
      onCompleted: (data) => {
        if (data?.listPackageDetails?.data.length > 0) {
          setShipments((prev) => {
            const newShipment = data?.listPackageDetails?.data[0];
            return newShipment ? [...prev, newShipment] : prev;
          });
          setValue("barCode", "");
          setCheckedIds((prev) => [
            ...prev,
            data?.listPackageDetails?.data[0]?.id,
          ]);
          setPackageCode(null);
          audioSuccess.play();
        } else {
          enqueueSnackbar(t("noPackageWithCode"), {
            variant: "warning",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
          audioFail.play();
        }
      },
    }
  );

  // const onCheckAll = (event, rows) => {
  //   const selected = shipments?.map((i) => {
  //     if (event.target.checked) {
  //       return {
  //         ...i,
  //         select: true,
  //       };
  //     } else {
  //       return {
  //         ...i,
  //         select: false,
  //       };
  //     }
  //   });
  //   setShipments(selected);
  // };

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const [checkedIds, setCheckedIds] = useState([]); // Local state to manage checked IDs
  const currentIds = shipments?.map((i) => i?.id);
  const isChecked = (id) => {
    return checkedIds.includes(id);
  };
  const fieldSelected = currentIds?.filter((i) =>
    checkedIds.includes(i)
  )?.length;

  const indeterminate = () =>
    fieldSelected > 0 && fieldSelected < currentIds?.length;

  const selectAllChecked = () =>
    currentIds?.length > 0 && fieldSelected === currentIds?.length;

  const onCheckAll = (e) => {
    let ids = [];
    if (e.target.checked) {
      const checkedAll = new Set([...checkedIds, ...currentIds]);
      ids = [...checkedAll];
    } else {
      ids = checkedIds.filter((i) => !currentIds.includes(i));
    }
    setCheckedIds(ids);
  };

  const toggleCheck = (e, id) => {
    const checked = e.target.checked;
    let updateCheckedIds = [...checkedIds];
    if (checked) {
      updateCheckedIds.push(id);
    } else {
      updateCheckedIds = updateCheckedIds.filter((i) => i !== id);
    }
    setCheckedIds(updateCheckedIds);
  };

  // const selectedValidShipment = selectedShipment;

  let tableBody = null;
  const handleOnClose = () => {
    setShipments([]);
  };
  // const validShipments = () => {
  //   return type === "RTS"
  //     ? selectedShipmentLength <= 0 ||
  //         selectedShipment.some((i) => i.valid === false)
  //     : selectedShipmentLength <= 0;
  // };

  const [createManifest, { loading: saveLoading }] = useMutation(
    gql`
      ${CREATE_MANIFEST.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const onSaveManifest = (data) => {
    data.length > 0 &&
      createManifest({
        variables: {
          input: {
            operationId: operationId,
            containerId: container,
            packageDetailId: data,
          },
        },
      })
        .then(() => {
          enqueueSnackbar(t("saveSuccessful"), {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
          handleManifest(container);
          // refetch();
          done();
        })
        .catch((error) => {
          enqueueSnackbar(error.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        });
  };

  if (shipments.length !== 0) {
    // if (true) {
    tableBody = (
      <Grid container justifyContent="center" sx={{ width: "100%" }}>
        {/* *******Table******* */}
        <Grid container item>
          <div className={classes.paper}>
            <TableFixedHeaderWraper containerScroll>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <FixedTableCell
                      className={clsx(classes.toolbar, classes.checkbox)}
                    >
                      <Checkbox
                        edge="start"
                        id="check-all-details"
                        indeterminate={indeterminate()}
                        checked={selectAllChecked()}
                        inputProps={{
                          "aria-label": "select all items",
                        }}
                        tabIndex={-1}
                        onChange={(e) => onCheckAll(e)}
                        disableRipple
                      />
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("pieceNumber")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("requestCode")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("loadingMode")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("packageType")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("product")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("weight")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("fragile")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("sender")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("originCountry")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("recipient")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("destinationCountry")}
                    </FixedTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    // stableSort(, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    shipments?.map((shipment, index) => {
                      // <
                      // const isItemSelected = isSelected(row);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <FixedTableCell key={`${index}-che`}>
                            <Checkbox
                              edge="start"
                              checked={isChecked(shipment?.id)}
                              onChange={(e) => toggleCheck(e, shipment?.id)}
                              disableRipple
                            />
                          </FixedTableCell>
                          <FixedTableCell>
                            {`${shipment?.pieceNumber}/${shipment?.package?.request?.detailsCount}`}
                          </FixedTableCell>
                          <CellLink
                            pathname={`/admin/requests/${shipment?.package?.request?.id}`}
                          >
                            {shipment?.package?.request?.code}
                          </CellLink>
                          <FixedTableCell>
                            {shipment?.package?.request?.loadingMode?.name}
                          </FixedTableCell>
                          <FixedTableCell>
                            {shipment?.package?.packageType?.name}
                          </FixedTableCell>
                          <FixedTableCell>
                            {shipment?.package?.product
                              ? shipment?.package?.product?.name
                              : shipment?.package?.description}
                          </FixedTableCell>
                          <FixedTableCell>
                            {shipment?.package?.weight}
                          </FixedTableCell>
                          <FixedTableCell>
                            {shipment?.package?.fragile ? (
                              <Icon className={classes.iconColor}>
                                check_circle_outline
                              </Icon>
                            ) : (
                              <Icon color="error">highlight_off</Icon>
                            )}
                          </FixedTableCell>
                          <CellLink
                            pathname={`/admin/vendors/${shipment?.package?.request?.vendor?.id}`}
                          >
                            {shipment?.package?.request?.vendor?.name}
                          </CellLink>
                          <CellLink
                            pathname={`/admin/countries/${shipment?.package?.request?.originCountry?.id}`}
                          >
                            {shipment?.package?.request?.originCountry?.name}
                          </CellLink>
                          {/* <FixedTableCell>
                          {shipment?.package?.request?.originCountry}
                        </FixedTableCell> */}
                          <CellLink
                            pathname={`/admin/customers/${shipment?.package?.request?.customer?.id}`}
                          >
                            {shipment?.package?.request?.customer?.name}
                          </CellLink>
                          <CellLink
                            pathname={`/admin/countries/${shipment?.package?.request?.destinationCountry?.id}`}
                          >
                            {
                              shipment?.package?.request?.destinationCountry
                                ?.name
                            }
                          </CellLink>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableFixedHeaderWraper>
          </div>
        </Grid>
      </Grid>
    );
  }
  return (
    <>
      <Backdrop
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading || saveLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Root container justifyContent="center">
        <EnhancedTableToolbar
          clear={() => {
            handleOnClose();
            done();
            setValue("barCode", "");
          }}
          done={() => {
            onSaveManifest(checkedIds);
          }}
          disabled={checkedIds.length === 0}
          numSelected={checkedIds.length}
        />

        <Grid container item sx={{ p: 3 }} justifyContent="center" xs={12}>
          <MUItextField
            label={t("barcode")}
            autoFocus
            name={"barCode"}
            register={register}
            inputProps={{
              dir: "ltr",
            }}
            readOnly={loading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {loading && (
                    <CustomSpinner name="PulseLoader" size={5} margin={2} />
                  )}
                </InputAdornment>
              ),
            }}
            defaultValue=""
            onKeyPress={(e) => {
              const value = e.target.value;

              const duplicatedCode = shipments.findIndex(
                (i) => i.barcode === value
              );
              if (duplicatedCode !== -1) {
                audioFail.play();
                enqueueSnackbar(t("thisPackagesIsRepeated"), {
                  variant: "info",
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                  TransitionComponent: Collapse,
                });
                setValue("barCode", "");
                return;
              }
              if (e.key === "Enter") {
                setPackageCode(value);
              }
            }}
          />
        </Grid>
        {shipments && shipments?.length !== 0 ? (
          tableBody
        ) : (
          <EmptyTableMessage message={t("noResult")} />
        )}
      </Root>
    </>
  );
};

export default BarcodeSelector;
