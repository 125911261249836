import React, { useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { TableBody, TableRow, Button, IconButton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Clear, Search } from "@mui/icons-material";
import { LIST_PRICE_LIST_PAGED } from "./Graphql";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { useState } from "react";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { useHistory } from "react-router";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useForm } from "react-hook-form";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import useWidth from "../../Hooks/useWidth";
import moment from "moment";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";
import { dateFormatLL } from "../../helpers/dateFunctions";
import { Globals } from "../HOC/Classes/Globals";
import MUIDate from "../HOC/MUI/MUIDate";
import { LIST_CURRENCIES_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { RootStyleList, classes } from "../../GlobalStyles/ListStyle";
const ListPriceLists = (props) => {
  const { t } = useTranslation();

  const [refetch, setrefetch] = useState(true);

  const urlQuery = urlParameters(window.location.search);

  const validUrlParameters = Object.keys(urlQuery).length !== 0;

  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const initDate = urlQuery["date"] ? urlQuery["date"] : null;
  const [dateRange, setDateRange] = useState(initDate);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [rowsPerPage, setRowsPerPage] = useState(
    urlQuery["rowsPerPage"] ?? config.app.pageSize
  );
  const [autocompleteValues, setAutocompleteValues] = useState({
    currency: null,
  });
  const [noData, setNoData] = useState(true);
  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };
  const date = moment(dateRange).locale("en").format("YYYY-MM-DD");
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      ...(dateRange && { date }),
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());
  useEffect(() => {
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    setAutocompleteValues((prev) => ({
      ...(urlQuery["currencyId"] && {
        currency: urlQuery["currencyId"],
      }),
    }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: `/admin/price-lists`,
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";
  const resetDate = () => {
    setDateRange(null);
  };
  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    refetch ? setrefetch(false) : setrefetch(true);
    let handledData = {
      ...(dateRange && {
        date,
      }),
      name: data.name,
      code: data.code,
      currencyId: data.currencyId,
      shippingDirection: data.shippingDirection,
      shippingMode: data.shippingMode,
      page: 0,
    };

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };
  const { data, loading } = useQuery(
    gql`
      ${LIST_PRICE_LIST_PAGED.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          ...(search.name && { name: search.name }),
          ...(search.code && { code: search.code }),
          ...(search.date && { date: search.date }),
          ...(search.currencyId && { currencyId: search.currencyId }),
          ...(search.shippingMode && { shippingMode: search.shippingMode }),
          ...(search.shippingDirection && {
            shippingDirection: search.shippingDirection,
          }),

          // ...(listType && { customerTypeCode: listType }),
        },
      },
      onCompleted: () => {
        setNoData(false);
      },
    }
  );
  const listPriceLists = data?.listPriceLists?.data;

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };
  const parseData = (data) => {
    return data;
  };
  const user = Globals.user;

  const addURL = () => {
    pushUrl(props, `/admin/price-lists/create`);
  };
  const icons = [
    {
      id: "filterList",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
    },
    {
      id: "add",
      action: addURL,
      permission: user?.hasPermission("freight.price_list.create"),
    },
  ];

  return (
    <RootStyleList>
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={listPriceLists?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  skip={noData}
                  errors={errors}
                  name={"currencyId"}
                  label={t("currency")}
                  parseData={(data) => parseData(data)}
                  query={LIST_CURRENCIES_DROPDOWN.query}
                  onChangeValue={(e) => {
                    setValue("stateId", "");
                  }}
                  defaultValue={autocompleteValues.currency}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <LookupDropdown
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"shippingMode"}
                  label={t("shippingMode")}
                  variables={{
                    input: { code: "FR_SHIPPING_MODE" },
                  }}
                  onCompleted={(data) =>
                    lookupsComplete(
                      data,
                      urlQuery["shippingMode"],
                      "shippingMode"
                    )
                  }
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <LookupDropdown
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"shippingDirection"}
                  label={t("shippingDirection")}
                  variables={{
                    input: { code: "FR_SHIPPING_DIRECTION" },
                  }}
                  onCompleted={(data) =>
                    lookupsComplete(
                      data,
                      urlQuery["shippingDirection"],
                      "shippingDirection"
                    )
                  }
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <MUIDate
                  clearable
                  readOnly={false}
                  name={"date"}
                  label={t("date")}
                  control={control}
                  errors={errors}
                  value={dateRange}
                  onChange={(e) => {
                    setDateRange(e);
                  }}
                  InputProps={{
                    ...{
                      endAdornment: (
                        <IconButton
                          size="small"
                          key={"date"}
                          onClick={() => {
                            resetDate();
                          }}
                        >
                          <Clear size="small" />
                        </IconButton>
                      ),
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={[
          {
            name: "manifestName",
          },
          {
            name: "code",
          },

          {
            name: "shippingMode",
          },
          {
            name: "currency",
          },
          {
            name: "shippingDirection",
          },
          {
            name: "validFromDate",
          },
          {
            name: "validToDate",
          },
          {
            name: "description",
          },
        ]}
        tableBody={
          <TableBody>
            {listPriceLists &&
              listPriceLists?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <CellLink pathname={`/admin/price-lists/${row.id}`}>
                      {row?.name}
                    </CellLink>
                    <FixedTableCell>{row.code}</FixedTableCell>
                    <FixedTableCell>{row.shippingMode?.name}</FixedTableCell>
                    <FixedTableCell>{row?.currency?.name}</FixedTableCell>
                    <FixedTableCell>
                      {row?.shippingDirection?.name}
                    </FixedTableCell>
                    <FixedTableCell>
                      {dateFormatLL(row?.fromDate)}
                    </FixedTableCell>
                    <FixedTableCell>{dateFormatLL(row?.toDate)}</FixedTableCell>
                    <FixedTableCell>{row?.description}</FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listPriceLists?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listPriceLists ? 0 : search.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </RootStyleList>
  );
};

export default ListPriceLists;
