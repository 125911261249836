import { Icon, Paper } from "@mui/material";
import React, { Fragment } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import {
  DELETE_VOUCHER_MUTATION,
  DISAPPROVE_VOUCHER_MUTATION,
  VOUCHER_ID_AND_PRINT,
} from "./Graphql";

import Grid from "@mui/material/Unstable_Grid2";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import { useState } from "react";
import { useAccountType } from "../../Hooks/useAccountType";
import { formatIdIssueDate } from "../../helpers/dateFunctions";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";

const VoucherView = (props) => {
  const { t } = useTranslation();

  /******************************************* Start Pagination Functions ********************************************/

  /******************************************* Start voucher By Id ********************************************/
  const voucherId = props.match.params.id?.trim();

  const user = Globals.user;

  const {
    data,
    loading: voucherByIdLoad,
    refetch,
  } = useQuery(
    gql`
      ${VOUCHER_ID_AND_PRINT.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !voucherId,
      variables: {
        id: parseInt(voucherId),
      },
      onCompleted: (data) => {
        if (data?.voucher === null) return;
      },
    }
  );

  const voucher = data?.voucher;

  const branchId = data?.voucher?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;
  const canDeleteVoucher =
    user.hasPermission("cash.voucher.delete") &&
    canAccessBranch &&
    !data?.voucher?.approved &&
    !voucherByIdLoad;

  const canEditVoucher =
    user.hasPermission("cash.voucher.update") &&
    canAccessBranch &&
    !data?.voucher?.glApproved &&
    !voucherByIdLoad;

  const canDisapproveVoucher =
    !voucherByIdLoad &&
    user.hasPermission("cash.voucher.disapprove") &&
    canAccessBranch &&
    data?.voucher?.approved;

  /******************************************* End voucher List ********************************************/

  let viewBody = null;

  const [openDelete, setOpenDelete] = useState(false);
  const [openDisapprove, setOpenDisapprove] = useState(false);

  const openDeleteDialog = (type) => {
    setOpenDelete(true);
  };
  const openDisapproveDialog = (type) => {
    setOpenDisapprove(true);
  };

  const editURL = () => {
    pushUrl(props, `/admin/vouchers/${voucherId}/edit`);
  };

  const icons = [
    {
      id: "edit",
      action: editURL,
      permission: canEditVoucher,
    },

    {
      id: "delete",
      action: openDeleteDialog,
      permission: Boolean(canDeleteVoucher),
    },
    {
      id: "disapprove",
      action: openDisapproveDialog,
      permission: Boolean(canDisapproveVoucher),
    },
    {
      id: "print",
      action: () =>
        window.open(`${window.location.origin}/voucher/print/${voucherId}`),

      disabled: voucherByIdLoad,
    },
  ];
  const userValue = useAccountType(voucher?.payeeType?.code);

  viewBody = (
    <Fragment>
      <TitleAppBar
        path={props.match.path}
        type={voucher?.type?.code.toLowerCase()}
      >
        <LongMenu icons={icons} />
        {openDelete && (
          <MutationWithDialog
            mutaion={DELETE_VOUCHER_MUTATION.query}
            setOpenDelete={setOpenDelete}
            openDelete={openDelete}
            dialogTitle={t("deleteRecord")}
            dialogContent={t("deleteRecordMessage")}
            mutaionProps={{ variables: { id: parseInt(voucherId) } }}
            onCompleted={() =>
              pushUrl(
                props,
                voucher?.type?.code === "PAYMENT"
                  ? `/admin/vouchers/payment`
                  : `/admin/vouchers/receipt`
              )
            }
            onCompleteMessage={t("successfullyDeletedRecord")}
          />
        )}

        {openDisapprove && (
          <MutationWithDialog
            mutaion={DISAPPROVE_VOUCHER_MUTATION.query}
            setOpenDelete={setOpenDisapprove}
            openDelete={openDisapprove}
            dialogTitle={t("disapprove")}
            dialogContent={t("disapprovedMessage")}
            mutaionProps={{ variables: { id: parseInt(voucherId) } }}
            onCompleted={() => refetch()}
            onCompleteMessage={t("successfullyDisapproved")}
          />
        )}
      </TitleAppBar>
    </Fragment>
  );

  return voucherByIdLoad ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.voucher ? (
    <NotFound />
  ) : (
    <>
      {viewBody}
      <RootStyleView spacing={2} p={2} justifyContent="center">
        {props.children}

        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair title={t("code")} value={voucher?.code} />
          <KeyValuePair title={t("voucherDate")} value={voucher?.voucherDate} />
          <KeyValuePair
            title={t("branch")}
            value={
              <SpanLink pathname={`/admin/branches/${voucher?.branch?.id}`}>
                {voucher?.branch?.name}
              </SpanLink>
            }
          />
          <KeyValuePair
            title={t("paymentMethod")}
            value={voucher?.paymentMethod?.name}
          />
          {voucher?.bankAccount ? (
            <KeyValuePair
              title={t("bankAccount")}
              value={voucher?.bankAccount?.name}
            />
          ) : (
            <KeyValuePair title={t("safe")} value={voucher?.safe?.name} />
          )}
          <KeyValuePair
            title={t("payeeType")}
            value={voucher?.payeeType?.name}
          />
          {userValue?.queryName === "glAccount" ? (
            <KeyValuePair
              title={userValue?.label}
              value={voucher?.glAccount?.name}
            />
          ) : (
            <KeyValuePair
              title={userValue?.label}
              value={
                userValue?.queryName === "customAgent" ? (
                  <SpanLink
                    pathname={`/admin/custom-agents/${voucher?.agent?.id}`}
                  >
                    {voucher?.agent?.name}
                  </SpanLink>
                ) : userValue?.queryName === "agency" ? (
                  <SpanLink
                    pathname={`/admin/$agencies/${
                      voucher[userValue?.queryName]?.id
                    }`}
                  >
                    {voucher[userValue?.queryName]?.name}
                  </SpanLink>
                ) : (
                  <SpanLink
                    pathname={`/admin/${userValue?.queryName}s/${
                      voucher[userValue?.queryName]?.id
                    }`}
                  >
                    {voucher[userValue?.queryName]?.name}
                  </SpanLink>
                )
              }
            />
          )}
          {voucher?.bank ? (
            <>
              <KeyValuePair title={t("bank")} value={voucher?.bank?.name} />
              <KeyValuePair
                title={t("chequeNumber")}
                value={voucher?.chequeNumber}
              />
              <KeyValuePair
                title={t("nameOnCheque")}
                value={voucher?.nameOnCheque}
              />
              <KeyValuePair
                title={t("chequeDate")}
                value={voucher?.chequeDate}
              />
            </>
          ) : (
            <>
              <KeyValuePair
                title={t("nameOnCard")}
                value={voucher?.nameOnCard}
              />
              <KeyValuePair
                title={t("cardNumber")}
                value={voucher?.cardNumber}
              />
              <KeyValuePair
                title={t("expiryDate")}
                value={formatIdIssueDate(voucher?.expiryDate)}
              />
            </>
          )}
          <KeyValuePair
            title={t("documentType")}
            value={voucher?.payeeTransactionType?.name}
          />
          <KeyValuePair
            title={t("documentCode")}
            value={
              <SpanLink
                pathname={
                  voucher?.invoice
                    ? `/admin/invoices/${voucher?.invoice?.id}`
                    : voucher?.bill
                    ? `/admin/bills/${voucher?.bill?.id}`
                    : voucher?.adjustment
                    ? `/admin/adjustments/${voucher?.adjustment?.id}`
                    : voucher?.voucher
                    ? `/admin/vouchers/${voucher?.voucher?.id}`
                    : ""
                }
              >
                {voucher?.invoice
                  ? voucher?.invoice?.code
                  : voucher?.bill
                  ? voucher?.bill?.code
                  : voucher?.adjustment
                  ? voucher?.adjustment?.code
                  : voucher?.voucher
                  ? voucher?.voucher?.code
                  : ""}
              </SpanLink>
            }
          />
          <KeyValuePair
            title={t("referenceNumber")}
            value={voucher?.referenceNumber}
          />
          <KeyValuePair title={t("currency")} value={voucher?.currency?.name} />
          <KeyValuePair
            title={t("exchangeRate")}
            value={voucher?.exchangeRate}
          />
          <KeyValuePair title={t("value")} value={voucher?.amount} />
          <KeyValuePair title={t("notes")} value={voucher?.notes} />
          <KeyValuePair
            title={t("documentType")}
            value={
              voucher?.invoice ? t("invoice") : voucher?.bill ? t("bill") : ""
            }
          />
          <KeyValuePair title={t("type")} value={voucher?.type?.name} />
          <KeyValuePair
            title={t("journalEntry")}
            value={
              <SpanLink
                pathname={`/admin/finance/journal-entries/${voucher?.journalEntry?.id}`}
              >
                {voucher?.journalEntry?.code}
              </SpanLink>
            }
          />
          <KeyValuePair
            title={t("approved")}
            value={
              voucher?.approved ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("glApproved")}
            value={
              voucher?.glApproved ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
        </Paper>
      </RootStyleView>
    </>
  );
};

export default VoucherView;
