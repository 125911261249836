import React, { useState } from "react";
import {
  Paper,
  Collapse,
  Stack,
  FormControlLabel,
  Switch,
  Typography,
  Box,
} from "@mui/material";
import { PACKAGE_TYPE_ID, SAVE_PACKAGE_TYPE } from "./Graphql";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import FormButton from "../CustomComponents/Buttons/FormButton";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import FlightIcon from "@mui/icons-material/Flight";
import DirectionsBoatFilledOutlinedIcon from "@mui/icons-material/DirectionsBoatFilledOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import { LuPackage2 } from "react-icons/lu";
import { LuContainer } from "react-icons/lu";
import { TbPackageExport } from "react-icons/tb";
import { TbPackageImport } from "react-icons/tb";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";
const PackageTypeForm = (props) => {
  const [pathURL] = useState(props.match.path);
  const [active, setActive] = useState(true);
  const { t } = useTranslation(["translation", "validation"]);
  const { enqueueSnackbar } = useSnackbar();
  const [packageTypeId, setPackageTypeId] = useState(
    parseInt(props.match.params.id)
  );
  const { handleSubmit, control, formState, setValue, setError, watch } =
    useForm();
  const { errors } = formState;

  const [savePackagetype, { loading: savePackagetypeLoading }] = useMutation(
    gql`
      ${SAVE_PACKAGE_TYPE.query}
    `
  );

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActive(active);
  };

  const { data: updatePackageType, loading: updatePackageTypeLoading } =
    useQuery(
      gql`
        ${PACKAGE_TYPE_ID.query}
      `,

      {
        skip: !packageTypeId,
        variables: { id: packageTypeId },
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
          const packagetypeData = data.packageType;
          setActive(packagetypeData.active);
          const packagetypeParams = [
            "id",
            "code",
            "name",
            "length",
            "active",
            "breadth",
            "height",
            "weight",
            "isRoro",
            "import",
            "air",
            "land",
            "isContainer",
            "ocean",
            "export",
          ];
          packagetypeParams.forEach((i) => {
            packagetypeData[i] !== null && setValue(i, packagetypeData[i]);
          });
          !packagetypeData.isContainer && setValue("weight", "");
          !packagetypeData.isContainer && setValue("isRoro", false);
        },
      }
    );

  const onSubmit = (data) => {
    for (const key in data) {
      if (["height", "weight", "length", "breadth"].includes(key)) {
        data[key] = parseFloat(data[key]);
      }
      if (data[key] === "") {
        delete data[key];
        continue;
      }
      if (data["isContainer"] === false) {
        delete data["weight"];
        delete data["isRoro"];
        continue;
      }
    }

    savePackagetype({
      variables: {
        input: {
          ...data,
          active: active,
          ...(packageTypeId && { id: packageTypeId }),
          ...(data.isContainer && { weight: data.weight }),
          ...(data.isContainer && { isRoro: data.isRoro }),
        },
      },
    })
      .then((data) => {
        setPackageTypeId(data?.data?.savePackageType?.id);
        pushUrl(
          props,
          `/admin/package-types/${data?.data?.savePackageType?.id}`
        );

        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid
          justifyContent="end"
          sx={{ display: "flex", flexWrap: "wrap" }}
          xs={12}
        >
          <FormControlLabel
            checked={active}
            control={<Switch color="primary" />}
            label={t("active")}
            labelPlacement="start"
            onChange={handelActivate}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"code"}
            label={t("code")}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"name"}
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid xs={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            type={"number"}
            name={"length"}
            label={t("length")}
            rules={{
              min: {
                value: 0,
                message: t("validation:min", {
                  field: t("length"),
                  number: "0",
                }),
              },
            }}
          />
        </Grid>
        <Grid xs={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            type={"number"}
            name={"breadth"}
            label={t("breadth")}
            rules={{
              min: {
                value: 0,
                message: t("validation:min", {
                  field: t("width"),
                  number: "0",
                }),
              },
            }}
          />
        </Grid>
        <Grid xs={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            type={"number"}
            name={"height"}
            label={t("height")}
            rules={{
              min: {
                value: 0,
                message: t("validation:min", {
                  field: t("height"),
                  number: "0",
                }),
              },
            }}
          />
        </Grid>
      </Paper>
      <Grid container spacing={1}>
        <Grid item sm={4} xs={12}>
          <Paper className={clsx(classesForm.spacing)} sx={{ p: 1 }}>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <LuPackage2 size={"20px"} />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("container")}
                  </Typography>
                </Box>
                <MuiSwitch
                  edge="end"
                  name="isContainer"
                  control={control}
                  onChange={(event) => {
                    if (watch("isContainer")) {
                      updatePackageType?.packageType?.weight !== null &&
                        setValue(
                          "weight",
                          updatePackageType?.packageType?.weight
                        );
                      setValue(
                        "isRoro",
                        updatePackageType?.packageType?.isRoro
                      );
                    } else {
                      setValue("weight", "");
                      setValue("isRoro", false);
                    }
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <LuContainer size={"20px"} />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("roro")}
                  </Typography>
                </Box>

                <MuiSwitch
                  edge="end"
                  name="isRoro"
                  control={control}
                  disabled={!watch("isContainer")}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <ControlMUItextField
                control={control}
                errors={errors}
                disabled={!watch("isContainer")}
                type={"number"}
                name={"weight"}
                label={t("packageWeight")}
                rules={{
                  min: {
                    value: 0,
                    message: t("weightValidation"),
                  },
                }}
              />
            </Grid>
          </Paper>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Paper
            className={clsx(classesForm.spacing)}
            sx={{ p: 1, height: "10rem" }}
          >
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <DirectionsBoatFilledOutlinedIcon />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("ocean")}
                  </Typography>
                </Box>
                <MuiSwitch edge="end" name="ocean" control={control} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <LocalShippingOutlinedIcon />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("land")}
                  </Typography>
                </Box>

                <MuiSwitch edge="end" name="land" control={control} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <FlightIcon />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("air")}
                  </Typography>
                </Box>

                <MuiSwitch edge="end" name="air" control={control} />
              </Stack>
            </Grid>
          </Paper>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Paper
            className={clsx(classesForm.spacing)}
            sx={{ p: 1, height: "10rem" }}
          >
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <TbPackageExport size={"20px"} />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("export")}
                  </Typography>
                </Box>

                <MuiSwitch edge="end" name="export" control={control} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <TbPackageImport size={"20px"} />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("import")}
                  </Typography>
                </Box>

                <MuiSwitch edge="end" name="import" control={control} />
              </Stack>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" className={classesForm.spacing}>
        <FormButton disabled={savePackagetypeLoading}>
          {savePackagetypeLoading ? <ButtonLoading /> : t("save")}
        </FormButton>
      </Grid>
    </form>
  );
  let DOM;

  if (packageTypeId) {
    DOM = updatePackageType ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return updatePackageTypeLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !updatePackageType && packageTypeId ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={pathURL} />
      <Stack spacing={2} p={2}>
        {DOM}
      </Stack>
    </RootStyleForm>
  );
};

export default PackageTypeForm;
