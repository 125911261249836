import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";

export default function BasicCard(props) {
  const { item } = props;

  return (
    <Card
      sx={{
        borderRadius: "10px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
        height: "100%",
        background: item?.color ? item?.color : "tomato",
      }}
    >
      <CardContent
        sx={{ padding: 0, paddingBottom: "0px" }}
        style={{ paddingBottom: "0px" }}
      >
        <Grid
          container
          m={0}
          justifyContent={"center"}
          alignItems={"center"}
          color={"grey"}
        >
          <Grid item xs={8}>
            <Typography
              variant="body2"
              component="div"
              sx={{ overflow: "hidden" }}
            >
              {item?.name}
            </Typography>
            <Typography variant="h6"> {item?.total}</Typography>
          </Grid>
          <Grid item xs={4} fontSize={"1.7rem"} textAlign={"end"}>
            <props.item.icon
              style={{
                color: `${item?.iconColor ? item?.iconColor : "white"}`,
                fontSize: "1.7rem",
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
