import {
  IconButton,
  TableBody,
  TableRow,
  TableHead,
  Table,
} from "@mui/material";
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";
import { useEffect } from "react";
import MUITablePagination from "../../HOC/MUI/TablePagination/MUITablePagination";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useTranslation } from "react-i18next";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import { Delete } from "@mui/icons-material";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";
import { gql, useMutation } from "@apollo/client";
import { DELETE_PACKAGE_COMMODITY } from "../Graphql";
export const CommodityTableForm = (props) => {
  const {
    classes,
    commoditiesSelected,
    setChange,
    EmptyTextFields,
    setPickedCommodity,
    errors,
    control,
    clearErrors,
    register,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
  } = props;
  const { t } = useTranslation();

  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const [deletePackageCommodityMutation] = useMutation(
    gql`
      ${DELETE_PACKAGE_COMMODITY.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    commoditiesSelected.forEach((commodity, index) => {
      register(`commodities${index}count`, {
        required: "This field is required",
        min: {
          value: 1,
          message: "Count must be at least 1",
        },
      });
    });
  }, [commoditiesSelected, register, page, rowsPerPage]);

  return (
    <>
      <TableFixedHeaderWraper>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <FixedTableCell>#</FixedTableCell>
              <FixedTableCell>{t("name")}</FixedTableCell>

              <FixedTableCell>{t("count")}</FixedTableCell>
              <FixedTableCell> </FixedTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {commoditiesSelected &&
              (rowsPerPage > 0
                ? commoditiesSelected.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : commoditiesSelected
              )?.map((commodity, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={page * rowsPerPage + index + 1}
                    className={classes.tableBodyRow}
                  >
                    <FixedTableCell>
                      {page * rowsPerPage + index + 1}
                    </FixedTableCell>
                    <FixedTableCell>
                      {commodity?.commodity?.name}
                    </FixedTableCell>
                    <FixedTableCell>
                      <ControlMUItextField
                        className={classes.tableField}
                        control={control}
                        errors={errors}
                        variant="outlined"
                        type={"number"}
                        name={`commodities${page * rowsPerPage + index}count`}
                        label={t("count")}
                        rules={{
                          required: t("fieldIsRequired"),
                          min: {
                            value: 1,
                            message: t("validation:min", {
                              field: t("count"),
                              number: "1",
                            }),
                          },
                        }}
                        onChange={(e) => {
                          const value = e.target.value;
                          setPickedCommodity((prev) => {
                            prev.commodities[page * rowsPerPage + index].count =
                              value;
                            return prev;
                          });
                          setChange((prev) => !prev);
                        }}
                      />
                    </FixedTableCell>
                    <FixedTableCell component="th" scope="row">
                      <IconButton
                        size="small"
                        onClick={() => {
                          const targetId = commodity.commodity.id;
                          setPickedCommodity((current) => {
                            const currentCommodities = current.commodities;
                            currentCommodities.length === 1 &&
                              EmptyTextFields();
                            const filterCommodities = currentCommodities.filter(
                              (ele) => ele.commodity.id !== targetId
                            );
                            return {
                              commodities: filterCommodities,
                            };
                          });
                          setChange((prev) => !prev);
                          commodity?.id &&
                            deletePackageCommodityMutation({
                              variables: {
                                id: parseInt(commodity.id),
                              },
                            });
                          clearErrors([
                            `commodities${page * rowsPerPage + index}count`,
                          ]);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableFixedHeaderWraper>
      {commoditiesSelected.length > 4 && (
        <MUITablePagination
          classeName={classes.pagination}
          onRowsPerPageChange={handleRowsPerPage}
          count={commoditiesSelected?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePage}
          rowsPerPageOptions={[5, 10, 15]}
          disableLastPage
        />
      )}

      {/* {(!watch("shipment.id") ) && (
                <Box className={classes.overlay}>
                    <Typography color="textPrimary" variant="body1">
                        {t("saveManifestFirst")}
                    </Typography>
                </Box>
            )} */}
      <Grid container justifyContent="center" alignItems="center">
        {commoditiesSelected.length === 0 && (
          <EmptyTableMessage message={t("noResult")} />
        )}
      </Grid>
    </>
  );
};
