/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Paper, Icon, IconButton, Button, Box } from "@mui/material";
import { Image } from "@mui/icons-material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { EMPLOYEE_ID } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import config from "../../config.json";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";

const EmployeeView = (props) => {
  const { t } = useTranslation();
  const employeeId = props.match.params.id?.trim();
  const user = Globals.user;
  const [dialogDetails, setDialogDetails] = useState({
    state: false,
    function: null,
    confirmAction: true,
    title: "",
    content: "",
  });

  const { loading, data } = useQuery(
    gql`
      ${EMPLOYEE_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(employeeId) },
    }
  );
  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;

    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };
  const closeConfirmationDialog = () => {
    setDialogDetails((prev) => ({
      ...prev,
      state: false,
    }));
  };
  const openSignatureDialog = (imgPath) => {
    setDialogDetails((prev) => ({
      state: true,
      title: null,
      content: (
        <Box
          sx={{ background: "#fff", width: "100%" }}
          component="img"
          alt="signature"
          src={getBackendUri(imgPath.path)}
        />
      ),
      confirmAction: false,
      function: () => {},
    }));
  };
  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/employees/${employeeId}/edit`),
      permission: user.hasPermission("freight.employee.update"),
    },
  ];
  const employeeData = data?.employee;
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !employeeData ? (
    <NotFound />
  ) : (
    <>
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>

      {dialogDetails.state && (
        <CustomDialog
          title={dialogDetails.title}
          fullWidth
          maxWidth="xs"
          onClose={closeConfirmationDialog}
          content={dialogDetails.content}
          open={dialogDetails.state}
          actions={
            dialogDetails.function !== null && (
              <>
                <Button color="primary" onClick={closeConfirmationDialog}>
                  {dialogDetails.confirmAction ? t("cancel") : t("close")}
                </Button>
                {dialogDetails.confirmAction && (
                  <Button color="primary" onClick={dialogDetails.function}>
                    {t("confirm")}
                  </Button>
                )}
              </>
            )
          }
        >
          {dialogDetails.function === null && " "}
        </CustomDialog>
      )}
      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair title={t("code")} value={employeeData?.code} />
          <KeyValuePair title={t("name")} value={employeeData?.name} />
          <KeyValuePair title={t("createdAt")} value={employeeData?.date} />
          <KeyValuePair
            title={t("active")}
            value={
              employeeData?.active ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("branch")}
            value={
              <SpanLink
                pathname={`/admin/branches/${employeeData?.branch?.id}`}
              >
                {employeeData?.branch?.name}
              </SpanLink>
            }
          />
          <KeyValuePair
            title={t("nationalityId")}
            value={
              <SpanLink
                pathname={`/admin/countries/${employeeData?.nation?.id}`}
              >
                {employeeData?.nation?.name}
              </SpanLink>
            }
          />
          <KeyValuePair title={t("salary")} value={employeeData?.salary} />
          <KeyValuePair
            title={t("transferAmount")}
            value={employeeData?.transferAmount}
          />
          <KeyValuePair title={t("bank")} value={employeeData?.bank?.name} />
          <KeyValuePair
            title={t("bankAccountNumber")}
            value={employeeData?.bankAccountNumber}
          />
          <KeyValuePair
            title={t("glAccount")}
            value={employeeData?.glAccount?.name}
          />
          <KeyValuePair
            title={t("idType")}
            value={employeeData?.idType?.name}
          />
          <KeyValuePair title={t("idNumber")} value={employeeData?.idNumber} />
          <KeyValuePair
            title={t("issuePlace")}
            value={employeeData?.idIssuePlace}
          />
          <KeyValuePair
            title={t("idIssueDate")}
            value={employeeData?.idIssueDate}
          />
          <KeyValuePair
            title={t("expiryDate")}
            value={employeeData?.idExpiryDate}
          />
          {employeeData?.image ? (
            <KeyValuePair
              title={t("image")}
              value={
                <IconButton
                  size="small"
                  onClick={() => openSignatureDialog(employeeData?.image)}
                >
                  <Image fontSize="small" />
                </IconButton>
              }
            />
          ) : (
            <KeyValuePair title={t("image")}></KeyValuePair>
          )}
          <KeyValuePair
            title={t("users")}
            value={
              employeeData?.users &&
              employeeData?.users.map((user, index) => (
                <SpanLink
                  key={user.id}
                  sx={{ display: "inline" }}
                  pathname={`/admin/users/${user.id}`}
                >
                  {user.username}{" "}
                  {!(employeeData?.users.length - 1 === index) && " , "}
                </SpanLink>
              ))
            }
          />
        </Paper>
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair
            title={t("gender")}
            value={employeeData?.gender?.name}
          />
          <KeyValuePair
            title={t("socialStatus")}
            value={employeeData?.socialStatus?.name}
          />
          <KeyValuePair
            title={t("birthPlace")}
            value={employeeData?.birthPlace}
          />
          <KeyValuePair
            title={t("birthDate")}
            value={employeeData?.birthDate}
          />
          <KeyValuePair
            title={t("country")}
            value={
              <SpanLink
                pathname={`/admin/countries/${employeeData?.country?.id}`}
              >
                {employeeData?.country?.name}
              </SpanLink>
            }
          />
          <KeyValuePair title={t("state")} value={employeeData?.state?.name} />
          <KeyValuePair title={t("address")} value={employeeData?.address} />
          <KeyValuePair
            title={t("phone")}
            value={<span dir="ltr">{employeeData?.phone}</span>}
          />
          <KeyValuePair
            title={t("mobile")}
            value={<span dir="ltr">{employeeData?.mobile}</span>}
          />
        </Paper>
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair
            title={t("graduationName")}
            value={employeeData?.graduationName}
          />
          <KeyValuePair
            title={t("professionName")}
            value={employeeData?.professionName}
          />
          <KeyValuePair
            title={t("universityName")}
            value={employeeData?.universityName}
          />
          <KeyValuePair
            title={t("graduationYear")}
            value={employeeData?.graduationYear}
          />
          <KeyValuePair
            title={t("graduationGrade")}
            value={employeeData?.graduationGrade}
          />
          <KeyValuePair
            title={t("postalCode")}
            value={employeeData?.postalCode}
          />
          <KeyValuePair
            title={t("mailBoxNumber")}
            value={employeeData?.mailBoxNumber}
          />
        </Paper>
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair
            title={t("administration")}
            value={
              employeeData?.administration ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("operation")}
            value={
              employeeData?.operation ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("salesMan")}
            value={
              employeeData?.salesMan ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("deliveryAgent")}
            value={
              employeeData?.deliveryAgent ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
        </Paper>
      </RootStyleView>
    </>
  );
};

export default EmployeeView;
