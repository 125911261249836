import {
  Alert,
  Box,
  Button,
  Dialog,
  Paper,
  Stack,
  useTheme,
} from "@mui/material";
import React, { Suspense, useReducer, useState } from "react";
import {
  APPROVE_JOURNAL_ENTRY,
  CURRENCY_ID,
  JOURNAL_ENTRY,
  SAVE_JOURNAL_ENTRY,
} from "./Graphql";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Done, DoneAll } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import NotFound from "../../Error/NotFound";
import {
  LIST_CURRENCIES_DROPDOWN,
  LIST_JOURNAL_TYPES_DROPDOWN,
  LIST_SUBSIDIARIES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import CustomButton from "../HOC/MUI/CustomButton";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import TitleAppBar from "../../Layout/TitleAppBar";
import MUIDateTime from "../HOC/MUI/MUIDateTime";
import { useEnqueueSnackbar } from "../../helpers/snackBar";
import RecordsForm from "./Components/RecordsForm";
import RecordsTable from "./Components/RecordsTable";
import { Globals } from "../HOC/Classes/Globals";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";

const initialState = {
  branch: null,
  type: null,
  subsidiary: null,
  currency: null,
  createdAt: new Date(),
  journalDetails: null,
  records: 0,
  debit: 0,
  credit: 0,
  dialogState: {
    state: false,
    title: null,
    function: () => {},
    content: null,
  },
};

function reducer(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

const dateFormat = (date) =>
  moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");

const JournalEntryForm = (props) => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    setError,
  } = useForm();
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const enqueueSnackbarFunc = useEnqueueSnackbar();
  const localCurrency = Globals?.settings?.localCurrency;
  const journalId = parseInt(props?.match?.params?.id) || watch("id");
  const [pathURL, setPathURL] = useState(props.match.path);

  const [JournalEntryData, dispatch] = useReducer(reducer, initialState);
  const [disabled, setDisabled] = useState({
    formEdit: false,
  });
  const [refetchFunction, setRefetchFunction] = useState(null); // Store the child function
  // Call the child's function when the button is clicked
  const callRefetchFunction = () => {
    if (refetchFunction) {
      refetchFunction(); // Call the child function
    }
  };

  const parseData = (data) => {
    return data;
  };

  const { loading: journalEntryLoading, data } = useQuery(
    gql`
      ${JOURNAL_ENTRY.query}
    `,
    {
      skip: !journalId,
      variables: {
        id: parseInt(journalId),
      },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const journalEntryData = data?.journalEntry;
        if (journalEntryData === null) return;
        dispatch({
          payload: {
            branch: journalEntryData?.branch,
            type: journalEntryData?.type,
            subsidiary:
              journalEntryData?.subsidiary ?? journalEntryData?.subsidiary,
            createdAt: new Date(),
            journalDetails: journalEntryData,
            records: journalEntryData?.records?.paginatorInfo?.total,
            debit: journalEntryData.debit,
            credit: journalEntryData.credit,
            currency: journalEntryData.currency,
          },
        });
        setValue("date", journalEntryData?.date);
        setDisabled((prev) => ({
          ...prev,
          formEdit: true,
        }));
        const saveJournalParams = [
          "id",
          "code",
          "date",
          "description",
          "exchangeRate",
        ];
        saveJournalParams.forEach((i) => {
          journalEntryData[i] && setValue(i, journalEntryData[i]);
        });
      },
    }
  );

  const notApproved = !JournalEntryData.journalDetails?.approved;
  const deviationValidation =
    JournalEntryData?.debit - JournalEntryData?.credit === 0;

  const [saveJournalEntry, { loading: saveJournalLoading }] = useMutation(
    gql`
      ${SAVE_JOURNAL_ENTRY.query}
    `
  );
  const [approveJournalEntry, { loading: approveJournalLoading }] = useMutation(
    gql`
      ${APPROVE_JOURNAL_ENTRY.query}
    `
  );

  const onSubmit = (data) => {
    if (
      data.branchId !== JournalEntryData.journalDetails?.branch?.id &&
      JournalEntryData.records > 0
    ) {
      dispatch({
        payload: {
          dialogState: {
            state: true,
            title: t("updateRecord"),
            function: () => saveData(null, true),
            content: t("updateWarningMessage", { type: t("records") }),
          },
        },
      });
      return;
    }

    saveData(data);
  };

  const saveData = (getterData, updateBranch) => {
    const data = getterData ?? watch();
    data["date"] = dateFormat(data["date"]);
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }
    saveJournalEntry({
      variables: {
        input: {
          ...data,
          exchangeRate: parseFloat(data?.exchangeRate),
        },
      },
    })
      .then((data) => {
        const saveJournalEntry = data?.data?.saveJournalEntry;
        setValue("id", saveJournalEntry.id);
        setValue("code", saveJournalEntry.code);
        const url = history.createHref({
          pathname: `/admin/finance/journal-entries/${saveJournalEntry?.id}/edit`,
        });
        setPathURL(url);
        windowReplaceUrl(url);
        dispatch({
          payload: {
            journalDetails: saveJournalEntry,
          },
        });
        setDisabled((prev) => ({
          ...prev,
          formEdit: true,
        }));
        if (updateBranch) {
          callRefetchFunction();
          closeConfirmationDialog();
        }
        enqueueSnackbarFunc(t("saveSuccessful"), "success");
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        setValidationError(graphQLErrors, setError);
      });
  };

  const closeConfirmationDialog = () =>
    dispatch({
      payload: {
        dialogState: initialState.dialogState,
      },
    });

  const openApproveDialog = () =>
    dispatch({
      payload: {
        dialogState: {
          state: true,
          title: t("approveJournalEntry"),
          function: approve,
          content: t("approveJournalEntryConfirmationMessage"),
        },
      },
    });

  const approve = () => {
    approveJournalEntry({ variables: { id: journalId } })
      .then((data) => {
        enqueueSnackbarFunc(t("successfullyApproved"), "success");
        closeConfirmationDialog();
        pushUrl(
          props,
          `/admin/finance/journal-entries/${data.data.approveJournalEntry?.id}`
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [exchangeRate, setExchangeRate] = React.useState({
    updatable: true,
  });
  useQuery(
    gql`
      ${CURRENCY_ID.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !watch("currencyId"),
      variables: {
        id: parseInt(watch("currencyId")),
        input: {},
      },
      onCompleted: (data) => {
        const exchangeRate = data.currency.exchangeRate;
        watch("exchangeRate") === "" &&
          setValue("exchangeRate", exchangeRate?.rate);
        setExchangeRate((prev) => ({
          ...prev,
          updatable: exchangeRate.updatable,
        }));
      },
    }
  );
  const [recordFormState, setRecordFormState] = useState(false);
  const [recordData, setRecordData] = useState(null);
  const openRecordFormDialog = (row) => {
    row && setRecordData(row);
    setRecordFormState(true);
  };
  const closeRecordFormDialog = () => {
    setRecordData(null);
    setRecordFormState(false);
  };

  return journalEntryLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data && journalId ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={pathURL} />
      {recordFormState && (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={recordFormState}
          onClose={closeRecordFormDialog}
        >
          <Suspense fallback={<FullScreenLoading minHeight={"100px"} />}>
            <RecordsForm
              branchId={watch("branchId")}
              entryId={journalId}
              callRefetchFunction={callRefetchFunction}
              closeRecordFormDialog={closeRecordFormDialog}
              dispatch={dispatch}
              recordData={recordData}
              records={JournalEntryData.records}
            />
          </Suspense>
        </Dialog>
      )}
      {JournalEntryData.dialogState.state && (
        <CustomDialog
          title={JournalEntryData.dialogState.title}
          fullWidth
          maxWidth="xs"
          onClose={closeConfirmationDialog}
          content={JournalEntryData.dialogState.content}
          open={JournalEntryData.dialogState.state}
          actions={
            <>
              <Button color="primary" onClick={closeConfirmationDialog}>
                {t("cancel")}
              </Button>
              <Button
                color="primary"
                disabled={approveJournalLoading || saveJournalLoading}
                onClick={JournalEntryData.dialogState.function}
              >
                {approveJournalLoading || saveJournalLoading ? (
                  <ButtonLoading />
                ) : (
                  t("confirm")
                )}
              </Button>
            </>
          }
        />
      )}

      {(journalId || journalId === 0) && journalEntryLoading ? (
        <FullScreenLoading minHeight="10%" />
      ) : (
        <Stack m={2} spacing={2}>
          {journalId && disabled.formEdit ? (
            <Grid sm={12} xs={12}>
              <Alert
                severity="warning"
                action={
                  !data?.journalEntry?.approved && (
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setDisabled((prev) => ({
                          ...prev,
                          formEdit: false,
                        }));
                      }}
                    >
                      {t("update")}
                    </Button>
                  )
                }
              >
                {data?.journalEntry?.approved
                  ? t("updateRecordsForbidden")
                  : t("editJournalEntry")}
              </Alert>
            </Grid>
          ) : (
            ""
          )}

          <Paper
            className={classesForm.paper}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack sx={{ position: "relative" }}>
              {disabled.formEdit && (
                <Box
                  className={classesForm.overlay}
                  sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
                ></Box>
              )}
              <Grid
                container
                spacing={2}
                m={0}
                className={classesForm.mainForm}
              >
                <Grid xs={12} sm={6}>
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"code"}
                    label={t("code")}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  <MUIDateTime
                    name="date"
                    label={t("theDate")}
                    control={control}
                    // defaultValue={voucher?.date}
                    value={JournalEntryData.createdAt}
                    onChange={(e) => dispatch({ payload: e })}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  <ListBranches
                    control={control}
                    errors={errors}
                    name={"branchId"}
                    rules={{ required: t("fieldIsRequired") }}
                    defaultValue={JournalEntryData.branch}
                    skipDefaultBranch={journalId}
                    onChangeValue={() => setValue("typeId", "")}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    name={"typeId"}
                    label={t("journalType")}
                    rules={{ required: t("fieldIsRequired") }}
                    parseData={(data) => parseData(data)}
                    selectFirst={true}
                    variables={{
                      input: {
                        referenceCode: "GL_MNL",
                        ...(watch("branchId") && {
                          branchId: {
                            value: watch("branchId"),
                            includeNull: true,
                          },
                        }),
                      },
                    }}
                    query={LIST_JOURNAL_TYPES_DROPDOWN.query}
                    defaultValue={JournalEntryData.type}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    name={"subsidiaryId"}
                    label={t("subsidiary")}
                    parseData={(data) => parseData(data)}
                    query={LIST_SUBSIDIARIES_DROPDOWN.query}
                    defaultValue={JournalEntryData.subsidiary}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"description"}
                    label={t("description")}
                  />
                </Grid>
                <Grid xs={12} sm={6} alignItems="flex-start">
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    name={"currencyId"}
                    label={t("currency")}
                    onChangeValue={(e) => {
                      setValue("exchangeRate", "");
                    }}
                    rules={{ required: t("fieldIsRequired") }}
                    parseData={(data) => parseData(data)}
                    query={LIST_CURRENCIES_DROPDOWN.query}
                    defaultValue={JournalEntryData.currency ?? localCurrency}
                  />
                </Grid>
                <Grid xs={12} sm={6} alignItems="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    type="number"
                    name={"exchangeRate"}
                    label={t("exchangeRate")}
                    rules={{ required: t("fieldIsRequired") }}
                    disabled={!exchangeRate?.updatable}
                  />
                </Grid>
                {/* <Grid xs={12}>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent={"flex-end"}
                  >
                    <CustomButton
                      customcolor={theme.palette.success.main}
                      type="submit"
                      className={classesForm.button}
                      disabled={saveJournalLoading}
                      variant="contained"
                      size="medium"
                      loading={saveJournalLoading}
                      // className={classesform.button}
                      startIcon={!saveJournalLoading && <Done />}
                    >
                      {!saveJournalLoading && t("save")}
                    </CustomButton>
                    {journalId && (
                      <Button
                        disabled={
                          JournalEntryData.journalDetails?.approved ||
                          saveJournalLoading ||
                          JournalEntryData?.records === 0 ||
                          !deviationValidation
                        }
                        className={classesForm.button}
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={openApproveDialog}
                        name="approved"
                        startIcon={<DoneAll />}
                      >
                        {t("approve")}
                      </Button>
                    )}
                  </Stack>
                </Grid> */}
              </Grid>
            </Stack>
            <Grid sm={12} xs={12} container justifyContent="flex-end">
              {!disabled.formEdit && (
                <CustomButton
                  customcolor={theme.palette.success.main}
                  type="submit"
                  className={classesForm.button}
                  disabled={saveJournalLoading}
                  variant="contained"
                  size="medium"
                  loading={saveJournalLoading}
                  // className={classesform.button}
                  startIcon={!saveJournalLoading && <Done />}
                >
                  {!saveJournalLoading && t("save")}
                </CustomButton>
              )}

              {journalId && disabled.formEdit && (
                <Button
                  disabled={
                    JournalEntryData.journalDetails?.approved ||
                    saveJournalLoading ||
                    JournalEntryData?.records === 0 ||
                    !deviationValidation
                  }
                  className={classesForm.button}
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={openApproveDialog}
                  name="approved"
                  startIcon={<DoneAll />}
                >
                  {t("approve")}
                </Button>
              )}
            </Grid>
          </Paper>
          {journalId && (
            <RecordsTable
              addRecord={openRecordFormDialog}
              journalId={journalId}
              notApproved={notApproved}
              branchId={watch("branchId")}
              setExposeRefetchFunction={setRefetchFunction}
              dispatch={dispatch}
            />
          )}
        </Stack>
      )}
    </RootStyleForm>
  );
};

export default JournalEntryForm;
