import React, { useState } from "react";
import {
  Paper,
  Collapse,
  Stack,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { PRODUCT_ID, SAVE_PRODUCT } from "./Graphql";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import FormButton from "../CustomComponents/Buttons/FormButton";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { LIST_COMMODITIES_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";

import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";

const ProductForm = (props) => {
  const [pathURL] = useState(props.match.path);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [productId, setProductId] = useState(parseInt(props.match.params.id));
  const [activeProduct, setActiveProduct] = useState(true);

  const [autocompleteValues, setAutocompleteValues] = useState({
    commodityId: null,
  });
  const { handleSubmit, control, formState, setValue, setError } = useForm();
  const { errors } = formState;

  const [saveProduct, { loading: saveProductLoading }] = useMutation(
    gql`
      ${SAVE_PRODUCT.query}
    `
  );

  const { data, loading: updateProductLoading } = useQuery(
    gql`
      ${PRODUCT_ID.query}
    `,

    {
      skip: !productId,
      variables: { id: productId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const productData = data.product;
        setActiveProduct(productData.active);
        const productParams = ["id", "code", "name"];
        productParams.forEach((i) => {
          productData[i] !== null && setValue(i, productData[i]);
        });

        setAutocompleteValues((prev) => ({
          ...prev,
          commodityId: productData.commodity,
        }));
      },
    }
  );
  const parseData = (data) => {
    return data;
  };

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActiveProduct(active);
  };

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "" && key === "code") {
        delete data[key];
      }
      if (data[key] === "") {
        data[key] = null;
        continue;
      }
    }

    saveProduct({
      variables: {
        input: {
          ...data,
          active: activeProduct,
          ...(productId && { id: productId }),
        },
      },
    })
      .then((data) => {
        setProductId(data?.data?.saveProduct?.id);

        pushUrl(props, `/admin/product/${data?.data?.saveProduct?.id}`);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid
          justifyContent="end"
          sx={{ display: "flex", flexWrap: "wrap" }}
          xs={12}
        >
          <FormControlLabel
            checked={activeProduct}
            control={<Switch color="primary" />}
            label={t("active")}
            labelPlacement="start"
            onChange={handelActivate}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"code"}
            label={t("code")}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"name"}
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid xs={12} sm={4} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"commodityId"}
            label={t("commodity")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => parseData(data)}
            query={LIST_COMMODITIES_DROPDOWN.query}
            defaultValue={autocompleteValues.commodityId}
          />
        </Grid>
      </Paper>
      <Grid container justifyContent="flex-end" className={classesForm.spacing}>
        <FormButton disabled={saveProductLoading}>
          {saveProductLoading ? <ButtonLoading /> : t("save")}
        </FormButton>
      </Grid>
    </form>
  );
  let DOM;

  if (productId) {
    DOM = data ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return updateProductLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data && productId ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={pathURL} />
      <Stack spacing={2} p={2}>
        {DOM}
      </Stack>
    </RootStyleForm>
  );
};

export default ProductForm;
