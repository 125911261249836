import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { TableBody, TableRow, Icon, Button } from "@mui/material";
import { Search } from "@mui/icons-material";
import { FREIGHT_SETTING, QUERY_LIST } from "./Graphql";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useWidth from "../../Hooks/useWidth";
import config from "../../config.json";
import Grid from "@mui/material/Unstable_Grid2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_COUNTRIES_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_STATES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import MUIselect from "../HOC/MUI/MUIselect";
import { RootStyleList, classes } from "../../GlobalStyles/ListStyle";

const CustomAgentsList = (props) => {
  const { t } = useTranslation();
  const screenWidth = useWidth();
  const history = useHistory();
  const pathURL = props?.match?.path;
  let pathname = pathURL;
  if (pathURL.includes("custom-agents")) {
    pathname = "customAgent";
  } else if (pathURL.includes("carriers")) {
    pathname = "carrier";
  } else {
    pathname = "agencie";
  }

  const urlPath =
    pathname === "customAgent"
      ? "custom-agents"
      : pathname === "agencie"
      ? "agencies"
      : "carriers";

  const [refetch, setrefetch] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const [autocompleteValues, setAutocompleteValues] = useState({
    country: null,
    state: null,
    branch: null,
    glAccount: null,
  });
  const [noData, setNoData] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(
    urlQuery["rowsPerPage"] ?? config.app.pageSize
  );
  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    urlQuery["active"] !== undefined && setValue("active", urlQuery["active"]);
    setAutocompleteValues((prev) => ({
      ...(urlQuery["branchId"] && {
        branch: urlQuery["branchId"],
      }),
      ...(urlQuery["countryId"] && {
        country: urlQuery["countryId"],
      }),
      ...(urlQuery["stateId"] && {
        state: urlQuery["stateId"],
      }),
      ...(urlQuery["glAccountId"] && {
        glAccount: urlQuery["glAccountId"],
      }),
    }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find((i) => i.code === id);
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    const url = history.createHref({
      pathname: `/admin/${urlPath}`,
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    refetch ? setrefetch(false) : setrefetch(true);
    let handledData = {
      name: data.name,
      code: data.code,
      branchId: data.branchId,
      countryId: data.countryId,
      stateId: data.stateId,
      glAccountId: data.glAccountId,
      active: data.active,
      page: 0,
    };
    data["active"] === "" && delete handledData["active"];
    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const parseData = (data) => {
    return data;
  };
  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };
  const SETTING = FREIGHT_SETTING(pathname);
  const { data: setting } = useQuery(
    gql`
      ${SETTING.query}
    `,

    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {},
    }
  );
  const parentId = setting?.freightSettings?.mainCustomAgentGLAccount?.id;
  const parentIdCarrier = setting?.freightSettings?.mainCarrierGLAccount?.id;
  const parentIdAgency = setting?.freightSettings?.mainAgencyGLAccount?.id;
  const capitalPathname = pathname.charAt(0).toUpperCase() + pathname.slice(1);

  const LIST_QUERY = QUERY_LIST(capitalPathname);
  const { data, loading } = useQuery(
    gql`
      ${LIST_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          ...(search.code && { code: search.code }),
          ...(search.name && { name: search.name }),
          ...(search.branchId && {
            branchId: search.branchId,
          }),
          ...(search.countryId && {
            countryId: search.countryId,
          }),
          ...(search.stateId && {
            stateId: search.stateId,
          }),
          ...(search.glAccountId && {
            glAccountId: search.glAccountId,
          }),
          active: search.active,
        },
      },
      onCompleted: () => {
        setNoData(false);
      },
    }
  );

  const listData = data?.[`list${capitalPathname}s`]?.data;
  console.log(`/admin/${urlPath}/create`);

  const icons = [
    {
      id: "filterList",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
    },
    {
      id: "add",
      action: () => pushUrl(props, `/admin/${urlPath}/create`),
      permission: `freight.${
        pathname === "customAgent"
          ? "custom_agent"
          : pathname === "carrier"
          ? "carrier"
          : "agency"
      }.create`,
    },
  ];

  return (
    <RootStyleList>
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={listData?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ListBranches
                  control={control}
                  errors={errors}
                  name={"branchId"}
                  defaultValue={autocompleteValues.branch}
                  skipDefaultBranch={true}
                  skip={noData}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"countryId"}
                  label={t("country")}
                  parseData={(data) => parseData(data)}
                  query={LIST_COUNTRIES_DROPDOWN.query}
                  onChangeValue={(e) => {
                    setValue("stateId", "");
                  }}
                  defaultValue={autocompleteValues.country}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  disabled={!watch("countryId")}
                  skip={!watch("countryId")}
                  name={"stateId"}
                  label={t("state")}
                  parseData={(data) => parseData(data)}
                  query={LIST_STATES_DROPDOWN.query}
                  variables={{
                    countryId: watch("countryId"),
                  }}
                  defaultValue={autocompleteValues.state}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"glAccountId"}
                  label={t("glAccount")}
                  parseData={(data) => parseData(data)}
                  query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                  variables={{
                    input: {
                      typeCode: "SUB",
                      ...(watch("branchId") && {
                        branchId: {
                          value: watch("branchId"),
                          includeNull: true,
                        },
                        parentId:
                          pathname === "customAgent"
                            ? parentId
                            : pathname === "carrier"
                            ? parentIdCarrier
                            : parentIdAgency,
                      }),
                    },
                  }}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["glAccountId"],
                      "glAccount"
                    )
                  }
                  defaultValue={autocompleteValues.glAccount}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"active"}
                  label={t("active")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("notActive"), value: false },
                    { key: t("active"), value: true },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={[
          {
            name: "name",
          },
          {
            name: "code",
          },
          {
            name: "branch",
          },
          {
            name: "country",
          },
          {
            name: "currency",
          },
          {
            name: "phone",
          },
          {
            name: "mobile",
          },
          {
            name: "active",
          },
        ]}
        tableBody={
          <TableBody>
            {listData &&
              listData?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <CellLink pathname={`/admin/${urlPath}/${row?.id}`}>
                      {row?.name}
                    </CellLink>
                    <FixedTableCell>{row?.code}</FixedTableCell>
                    <CellLink pathname={`/admin/branches/${row?.branch?.id}`}>
                      {row?.branch?.name}
                    </CellLink>
                    <CellLink pathname={`/admin/countries/${row?.country?.id}`}>
                      {row?.country?.name}
                    </CellLink>
                    <FixedTableCell>{row?.currency?.name}</FixedTableCell>

                    <FixedTableCell dir={"ltr"}>{row?.phone}</FixedTableCell>
                    <FixedTableCell dir={"ltr"}>{row?.mobile}</FixedTableCell>
                    <FixedTableCell>
                      {row?.active ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.[`list${capitalPathname}s`]?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.[`list${capitalPathname}s`] ? 0 : search.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </RootStyleList>
  );
};

export default CustomAgentsList;
