import React, { memo, useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
// import Grid from "@mui/material/Unstable_Grid2";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import * as gqlb from "gql-query-builder";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import SearchFilter from "../HOC/CustomComponents/CustomListDialog/SearchFilter";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";

const StyledCard = styled(Paper)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "stretch",
  borderRadius: "10px",
}));

const Root = styled("div")(({ theme }) => ({
  display: "grid",
  alignContent: "space-between",
}));

const SEARCH = (dataFields = []) => ({
  operation: "listEmployees",
  fields: [
    {
      operation: "data",
      fields: [...dataFields],
      variables: {},
    },
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    input: { type: "ListEmployeesFilterInput" },
    first: { type: "Int" },
    page: { type: "Int" },
  },
});

const EmployeesList = ({ tableHeadCell, tableBody, queryFields = [] }) => {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [queryVariables, setQueryVariables] = useState({ refetch: true });
  const employee = gqlb.query(SEARCH(queryFields));
  const { data, loading } = useQuery(
    gql`
      ${employee.query}
    `,
    {
      variables: {
        input: queryVariables.search
          ? { name: queryVariables.search[0] }
          : undefined,
        first: rowsPerPage,
        page: page + 1,
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    const height = document.getElementById("StyledCard").offsetHeight;
    document.getElementById("StyledCard").style.minHeight = height + "px";
    return () => {};
  }, [data]);

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const resetPage = () => setPage(0);
  const filtersForm = (
    <SearchFilter
      {...{ resetPage, loading }}
      preventPushUrl
      queryVariables={(variables) => setQueryVariables(variables)}
    />
  );
  let Dom = null;
  Dom = (
    <Root>
      <TableFixedHeaderWraper>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              {tableHeadCell.map((cell, index) => (
                <FixedTableCell key={index}>{t(cell)}</FixedTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.listEmployees.data.map((employee, index) =>
                tableBody(employee, index)
              )}
          </TableBody>
        </Table>
      </TableFixedHeaderWraper>

      <MUITablePagination
        count={data?.listEmployees?.paginatorInfo?.total}
        rowsPerPage={rowsPerPage}
        page={!data?.listEmployees ? 0 : page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 15]}
        disableLastPage
      />
    </Root>
  );
  return (
    <div style={{ paddingInline: "8px" }}>
      <StyledCard elevation={5} id="StyledCard">
        <Grid container>
          <Grid item xs={12}>
            <Toolbar>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="h6" id="tableTitle" component="div">
                    {t("employeesList")}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {filtersForm}
                </Grid>
              </Grid>
            </Toolbar>
          </Grid>
          <Grid item xs={12}>
            {loading || data?.listEmployees.data.length === 0 ? (
              <Grid container alignItems="center" justifyContent="center">
                {loading ? (
                  <Grid
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    item
                    style={{ height: 250 }}
                  >
                    <CustomSpinner name="PulseLoader" size={12} margin={2} />
                  </Grid>
                ) : (
                  <EmptyTableMessage message={t("noResult")} />
                )}
              </Grid>
            ) : (
              <Grid container alignItems="flex-start" justifyContent="center">
                <Grid item xs={12}>
                  {Dom}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </StyledCard>
    </div>
  );
};

export default memo(EmployeesList);
