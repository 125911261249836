import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Collapse,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { SAVE_BANK_ACCOUNT } from "./Graphql";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_CURRENCIES_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";

export default function AccountsForm(props) {
  const {
    autocompleteValues,
    bankBranchId,
    updateAccounts,
    accounts,
    setAccounts,
    closeDialog,
    setAutocomplete,
  } = props;
  const { handleSubmit, control, setError, errors, setValue } = useForm();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [active, setActive] = React.useState(true);

  const [saveBankAccount, { loading: saveBankAccountLoading }] = useMutation(
    gql`
      ${SAVE_BANK_ACCOUNT.query}
    `
  );

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActive(active);
  };

  useEffect(() => {
    if (updateAccounts) {
      const bankAccountData = updateAccounts;
      setActive(bankAccountData?.active);
      const bankBranchParams = ["id", "code", "name", "active"];
      bankBranchParams.forEach((i) => {
        bankAccountData[i] !== null && setValue(i, bankAccountData[i]);
      });
      setAutocomplete((prev) => ({
        ...prev,
        currency: updateAccounts?.currency,
        type: updateAccounts?.type,
        glAccount: updateAccounts?.glAccount,
      }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parseData = (data) => {
    return data;
  };

  const onSubmit = (data, bankBranchId, id) => {
    for (const key in data) {
      if (data[key] === "" && key === "code") {
        delete data[key];
      }

      if (data[key] === "") {
        data[key] = null;
        continue;
      }
    }
    // delete data["active"];
    saveBankAccount({
      variables: {
        input: {
          ...data,
          active: active,
          ...(bankBranchId && { bankBranchId: bankBranchId }),
          ...(id && { id: id }),
        },
      },
    })
      .then((data) => {
        const checksubZone = accounts.map(
          (ele) => ele.id === data.data.saveBankAccount.id
        );
        const check = checksubZone.includes(true);
        if (check) {
          const index = accounts.findIndex(
            (x) => x.id === data.data.saveBankAccount.id
          );
          accounts[index] = data.data.saveBankAccount;
          setAccounts(accounts);
        } else {
          accounts.push(data.data.saveBankAccount);
          setAccounts(accounts);
        }
        closeDialog();
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        component="form"
        onSubmit={handleSubmit((data) =>
          onSubmit(data, bankBranchId, updateAccounts?.id)
        )}
      >
        <Grid
          container
          justifyContent="space-between"
          xs={12}
          alignItems={"center"}
        >
          <Typography variant="h6" color={"text.primary"}>
            {t("accounts")}
          </Typography>

          <FormControlLabel
            checked={active}
            control={<Switch color="primary" />}
            label={t("active")}
            labelPlacement="start"
            onChange={handelActivate}
          />
        </Grid>
        <Grid xs={12} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"code"}
            label={t("code")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid xs={12} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"name"}
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"currencyId"}
            label={t("currency")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => parseData(data)}
            query={LIST_CURRENCIES_DROPDOWN.query}
            defaultValue={autocompleteValues.currency}
          />
        </Grid>
        <Grid xs={12} aligns="flex-start">
          <CustomAutocomplete
            valueKey="code"
            control={control}
            errors={errors}
            name={"type"}
            label={t("type")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => parseData(data)}
            variables={{ input: { code: "BANK_ACCOUNTS_TYPE" } }}
            query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
            defaultValue={autocompleteValues.type}
          />
        </Grid>

        <Grid xs={12} alignItems="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"glAccountId"}
            label={t("glAccount")}
            parseData={(data) => parseData(data)}
            query={LIST_GL_ACCOUNTS_DROPDOWN.query}
            variables={{
              input: {
                typeCode: "SUB",
              },
            }}
            defaultValue={autocompleteValues.glAccount}
          />
        </Grid>

        <Grid container sm={12} justifyContent="flex-end">
          <Button onClick={closeDialog}>{t("cancel")}</Button>
          <Button type="submit">
            {saveBankAccountLoading ? <ButtonLoading /> : t("confirm")}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
