import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Collapse,
  Paper,
  Typography,
  Badge,
  IconButton,
  Dialog,
  DialogContent,
  styled,
  Toolbar,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { setValidationError } from "../../HOC/CustomFunctions/setValidationError";
import {
  AVAILABLE_PRICE_LIST,
  CALCULATE_FEES_QUERY,
  CURRENCY_ID,
  FREIGHT_SETTING,
  PACKAGE_ID,
  SAVE_REQUEST_PACKAGE,
} from "../Graphql";
import { CustomAutocomplete } from "../../HOC/MUI/CustomAutocomplete";
import {
  LIST_COUNTRIES_DROPDOWN,
  LIST_CURRENCIES_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_PACKAGE_TYPES_DROPDOWN,
  LIST_PRODUCT_DROPDOWN,
} from "../../../GlobalsQuery/ListDropdown/ListDropdown";
import ButtonLoading from "../../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";
import MuiSwitch from "../../HOC/MUI/MUIswitch";
import MUIselect from "../../HOC/MUI/MUIselect";
import { AddCircleOutline, Clear } from "@mui/icons-material";
import UploadFile from "../../HOC/MUI/UploadFile";
import config from "../../../config.json";
import CommodityList from "../CommoditiesList/CommoditiesList";
import useWidth from "../../../Hooks/useWidth";
import { CommodityTableForm } from "../CommoditiesList/FormTables";
import { useTabsContext } from "../../HOC/CustomComponents/PackagesTableView/TabContext";

const PREFIX = "RequestForm";
const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`,
  main: `${PREFIX}-main`,
  addIcon: `${PREFIX}-addIcon`,
  toolbar: `${PREFIX}-toolbar`,
  paperTitle: `${PREFIX}-paperTitle`,
  background: `${PREFIX}-background`,
  titles: `${PREFIX}-titles`,
  tableBodyRow: `${PREFIX}-tableBodyRow`,
  tableField: `${PREFIX}-tableField`,
  pagination: `${PREFIX}-pagination`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  },

  [`& .${classes.addIcon}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },
  [`& .${classes.toolbar}`]: {
    justifyContent: "space-between",
    width: "100%",
  },
  [`& .${classes.paperTitle}`]: {
    justifyContent: "center",
    background: `${theme.palette.background.appTitle} !important`,
  },
  [`& .${classes.tableBodyRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: theme.palette.background.hover,
      },
    },
  },
  [`& .${classes.tableField}`]: {
    width: "100px",
    margin: theme.spacing(1, 0),
  },
  [`& .${classes.pagination}`]: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
}));
export default function PackagesForm(props) {
  const {
    operationData,
    autocompleteValues,
    updatePackages,
    packages,
    setPackages,
    closeDialog,
    setAutocompleteValues,
    setDisabled,
    handleFetchPackageDetails,
  } = useTabsContext();
  const firstCalcUpdate = React.useRef(true);
  const {
    handleSubmit,
    control,
    setError,
    errors,
    setValue,
    watch,
    register,
    clearErrors,
  } = useForm();

  const requestData = operationData?.request;
  const requestId = operationData?.request?.id;
  const { t } = useTranslation(["translation", "validation"]);
  const { enqueueSnackbar } = useSnackbar();
  const [previewImage, setPreviewImage] = React.useState(null);
  const [change, setChange] = React.useState(false);
  const setClear = () => {
    setAutocompleteValues((prev) => ({
      ...prev,
      image: "",
    }));
    setPreviewImage(null);
    setValue("fileName", "");
    setValue("image", "null");
  };
  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;
    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };

  const [saveRequestPackage, { loading: ssaveRequestPackageLoading }] =
    useMutation(
      gql`
        ${SAVE_REQUEST_PACKAGE.query}
      `
    );

  useEffect(() => {
    if (updatePackages) {
      const params = [
        "fragile",
        "height",
        "weight",
        "length",
        "breadth",
        "count",
        "totalWeight",
        "pieces",
        "volume",
        "volumetricWeight",
        "volumetricFactor",
        "chargeableUnits",
        "chargePrice",
        "chargeAmount",
        "description",
        "exchangeRate",
        "productPrice",
      ];
      params.forEach((i) => {
        updatePackages[i] !== null && setValue(i, updatePackages[i]);
      });
      setCalcFeesState((prev) => ({
        ...prev,
        count: updatePackages?.count,
        weight: updatePackages?.weight,
        height: updatePackages?.height,
        length: updatePackages?.length,
        breadth: updatePackages?.breadth,
        priceMode: updatePackages?.priceMode?.code,
        volumetricFactor: updatePackages?.volumetricFactor,
        packageTypeId: parseInt(updatePackages?.packageType?.id),
      }));
      setAutocompleteValues((prev) => ({
        ...prev,
        packageTypeId: updatePackages?.packageType,
        // warehouseId: updatePackages?.warehouse,
        priceMode: updatePackages?.priceMode,
        originCountryId: updatePackages?.originCountry,
        image: updatePackages?.image?.path,
        currencyId: updatePackages?.currency,
        productId: updatePackages?.product,
      }));
      if (updatePackages?.commodities) {
        setPickedCommodity({ commodities: updatePackages?.commodities });
      }
      setChange((prev) => !prev);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const parseData = (data) => {
    return data;
  };
  const shippingModeCode = requestData?.shippingMode?.code;
  const selectedPriceMode = requestData?.customer?.priceModes?.find(
    (mode) => mode.shippingMode.code === shippingModeCode
  )?.priceMode;

  const [calcFeesState, setCalcFeesState] = React.useState({
    count: null,
    weight: null,
    height: null,
    length: null,
    breadth: null,
    priceMode: null,
    volumetricFactor: null,
    packageTypeId: null,
  });

  const calcFeesHandler = ({ value, name, feesAvailable }) => {
    let deleteFees = {
      deliveryFees: null,
      collectionFees: null,
    };

    if (feesAvailable) {
      deleteFees = {};
    }
    let fieldValue = {
      // eslint-disable-next-line eqeqeq
      [name]: value || value === 0 ? value : null,
    };
    firstCalcUpdate.current = false;
    setCalcFeesState((prev) => ({
      ...prev,
      ...deleteFees,
      ...fieldValue,
      render: !calcFeesState.render,
    }));
  };

  const validateCalcFeesField = (name) => calcFeesState[name] !== null;
  const canCalculate = () =>
    validateCalcFeesField("priceMode") &&
    validateCalcFeesField("breadth") &&
    validateCalcFeesField("length") &&
    validateCalcFeesField("height") &&
    validateCalcFeesField("weight") &&
    validateCalcFeesField("count") &&
    validateCalcFeesField("priceMode") &&
    validateCalcFeesField("packageTypeId") &&
    validateCalcFeesField("volumetricFactor");

  const [pickedCommodity, setPickedCommodity] = React.useState({
    commodities: [],
  });
  const onSubmit = (data, requestId, packageId) => {
    const commodities =
      pickedCommodity?.commodities &&
      pickedCommodity?.commodities.map((item) => ({
        commodityId: parseInt(item.commodity.id),
        count: parseInt(item.count),
        requestPackageId: packageId ?? null,
        ...(item?.id && { id: parseInt(item?.id) }),
      }));
    const packageData = {};
    for (const key in data) {
      if (
        data[key] === "" ||
        data[key] === null ||
        key === "totalWeight" ||
        key === "volume" ||
        key === "chargeAmount" ||
        key === "chargeableUnits" ||
        key === "chargePrice" ||
        key === "volumetricWeight" ||
        key === "fileName"
      ) {
        delete data[key];
      }
      if (data[key] === "" && key === "image") {
        delete data[key];
      }
      if (key === "image" && data[key] === "null") {
        data[key] = null;
        packageData[key] = null;
      }
      if (
        [
          "height",
          "weight",
          "length",
          "breadth",
          "count",
          "pieces",
          "volumetricFactor",
          "packageTypeId",
          "originCountryId",
          "currencyId",
          "exchangeRate",
          "productPrice",
          "productId",
        ].includes(key)
      ) {
        packageData[key] = parseFloat(data[key]);
      }
    }
    const image = data?.image && data?.image[0];
    // if (commodities?.length > 0) {
    //   // If commodities exist, set productId to null
    //   packageData.productId = null;
    // } else if (packageData.productId) {
    //   // If productId exists, set commodities to an empty array
    //   commodities = [];
    // }
    console.log(packageData);

    console.log(operationData?.warehouse?.id);
    console.log(operationData?.warehouse?.id);
    saveRequestPackage({
      variables: {
        input: {
          ...(requestId && { requestId: requestId }),
          ...(packageId && { id: packageId }),
          ...(operationData?.id && { operationId: operationData?.id }),
          warehouseId: parseInt(operationData?.warehouse?.id),
          ...packageData,
          priceMode: data?.priceMode,
          fragile: data?.fragile,
          description: data?.description,
          commodities: commodities,
          ...(data.image && {
            image: { file: image },
          }),
        },
      },
    })
      .then((data) => {
        const checkData = packages.map(
          (ele) => ele.id === data.data.saveRequestPackage.id
        );
        setDisabled((prev) => ({
          ...prev,
          addPackage: true,
        }));
        const check = checkData.includes(true);
        if (check) {
          const index = packages.findIndex(
            (x) => x.id === data.data.saveRequestPackage.id
          );
          packages[index] = data.data.saveRequestPackage;
          setPackages(packages);
        } else {
          packages.push(data.data.saveRequestPackage);
          setPackages(packages);
        }
        handleFetchPackageDetails(data.data.saveRequestPackage.id, 0, 10);
        closeDialog();
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };
  const [exchangeRate, setExchangeRate] = React.useState({
    updatable: true,
    updateCurrent: true,
    updateExchangeRate: false,
  });
  useQuery(
    gql`
      ${CURRENCY_ID.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !watch("currencyId"),
      variables: {
        id: parseInt(watch("currencyId")),
        input: {},
      },
      onCompleted: (data) => {
        const exchangeRateData = data.currency.exchangeRate;
        exchangeRate.updateExchangeRate &&
          setValue("exchangeRate", exchangeRateData?.rate);

        setExchangeRate((prev) => ({
          ...prev,
          updatable: exchangeRateData.updatable,
          updateCurrent: false,
        }));
      },
    }
  );
  useQuery(
    gql`
      ${PACKAGE_ID.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !watch("packageTypeId") || firstCalcUpdate.current,
      variables: {
        id: parseInt(watch("packageTypeId")),
        input: {},
      },
      onCompleted: (data) => {
        const packageType = data.packageType;
        setValue("height", packageType?.height);
        setValue("length", packageType?.length);
        setValue("breadth", packageType?.breadth);
        setValue("volume", packageType?.volume);
        setCalcFeesState((prev) => ({
          ...prev,
          height: packageType?.height,
          length: packageType?.length,
          breadth: packageType?.breadth,
        }));
      },
    }
  );

  useQuery(
    gql`
      ${FREIGHT_SETTING.query}
    `,

    {
      skip: updatePackages,
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setValue("volumetricFactor", data?.freightSettings?.volumetricFactor);
        setCalcFeesState((prev) => ({
          ...prev,

          volumetricFactor: data?.freightSettings?.volumetricFactor,
        }));
      },
    }
  );

  useQuery(
    gql`
      ${AVAILABLE_PRICE_LIST.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      skip: updatePackages,
      variables: {
        input: {
          mode: requestData?.shippingMode?.code,
          direction: requestData?.shippingDirection?.code,
          date: requestData?.requestDate,
        },
      },
      onCompleted: (data) => {
        const availablePriceList = data.availablePriceList;
        setAutocompleteValues((prev) => ({
          ...prev,
          currencyId: availablePriceList?.currency,
        }));
        setExchangeRate((prev) => ({
          ...prev,
          updateCurrent: false,
          updateExchangeRate: true,
        }));
      },
      onError: ({ graphQLErrors }) => {
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      },
    }
  );
  useQuery(
    gql`
      ${CALCULATE_FEES_QUERY.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: calcFeesState.render,
      fetchPolicy: "no-cache",
      skip: !canCalculate() || firstCalcUpdate.current,
      variables: {
        input: {
          weight: calcFeesState?.weight,
          length: calcFeesState?.length,
          height: calcFeesState?.height,
          count: calcFeesState?.count,
          packageTypeId: calcFeesState?.packageTypeId,
          priceMode: calcFeesState?.priceMode,
          volumetricFactor: calcFeesState?.volumetricFactor,
          breadth: calcFeesState?.breadth,
          requestId: requestId,
        },
      },
      onCompleted: (data) => {
        const packageFees = data.calculateRequestPackageFees;
        const params = [
          "totalWeight",
          "volume",
          "chargeableUnits",
          "chargePrice",
          "chargeAmount",
          "volumetricWeight",
        ];
        params.forEach((i) => {
          packageFees[i] !== null && setValue(i, packageFees[i]);
        });
      },
      onError: ({ graphQLErrors }) => {
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      },
    }
  );

  const screenWidth = useWidth();
  const anchor = screenWidth === "xs" ? "bottom" : "right";
  const [state, setState] = React.useState({
    productList: {
      left: false,
      bottom: false,
      right: false,
    },
  });
  const commoditiesSelected = pickedCommodity.commodities;
  const setSelect = (obj) => {
    setPickedCommodity((prev) => {
      const seen = new Set();
      const duplicateId = [];
      const commodities = [...prev.commodities, ...obj];

      const filteredCommodity = commodities.filter((el) => {
        if (!el.count) {
          el.commodity.count = "";
          el.count = "";
        }
        const duplicate = seen.has(el.commodity.id);
        if (duplicate) {
          duplicateId.push(el.commodity.id);
        }
        seen.add(el.commodity.id);
        return !duplicate;
      });
      for (let i = 0; i < duplicateId.length; i++) {
        for (let j = 0; j < filteredCommodity.length; j++) {
          if (duplicateId[i] === filteredCommodity[j].commodity.id) {
            filteredCommodity[j].count = Number(filteredCommodity[j].count) + 1;
          }
        }
      }
      return { commodities: filteredCommodity };
    });
    setChange((prev) => !prev);
  };
  const productsSelectedLength = commoditiesSelected.length === 0;
  useEffect(() => {
    if (!productsSelectedLength) {
      const totalCount = commoditiesSelected.reduce((total, item) => {
        // Check if count is not empty and is a valid number
        if (item.count !== "" && !isNaN(item.count)) {
          return total + parseInt(item.count);
        }
        return total; // If it's empty or NaN, just return the total unchanged
      }, 0);
      commoditiesSelected.forEach((commodity, index) => {
        setValue(`commodities${index}count`, commodity.count);
        if (parseInt(watch("count")) < totalCount) {
          setError(`commodities${index}count`, {
            type: "custom",
            message: "count",
          });
        } else {
          clearErrors([`commodities${index}count`]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change]);

  const EmptyTextFields = () => {
    setPickedCommodity({ commodities: [] });
  };
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const registerIsValid = () => {
    const index = commoditiesSelected.findIndex(
      (item) => item.count === "" || item.count === "0"
    );

    let newPage = 0; // Default to page 0

    if (index !== -1) {
      if (index < 5) {
        newPage = 0;
      } else {
        // Calculate the page number based on the index
        newPage = Math.floor(index / 5);
      }
    }

    // Set the page state
    setPage(newPage);
  };

  const commodityDrawerHandler = (anchor, open) => {
    setState((prev) => ({
      ...prev,
      productList: { ...prev.productList, [anchor]: open },
    }));
  };
  return (
    <Root>
      <Dialog
        anchor={anchor}
        fullWidth
        maxWidth="sm"
        open={state.productList[anchor]}
        onClose={() => {
          commodityDrawerHandler(anchor, false);
        }}
        className={classes.dialog}
      >
        <DialogContent style={{ overflow: "hidden", padding: 0 }}>
          <CommodityList
            setSelected={(obj) => {
              setSelect(obj);
            }}
            filter={state.productList[anchor] ? false : true}
            type={"PADD"}
            selected={commoditiesSelected}
            done={() => {
              commodityDrawerHandler(anchor, false);
            }}
            variables={requestId}
          />
        </DialogContent>
      </Dialog>
      <Grid
        container
        spacing={1}
        component="form"
        onSubmit={handleSubmit((data) =>
          onSubmit(data, requestId, updatePackages?.id)
        )}
      >
        <Grid
          container
          justifyContent="space-between"
          xs={12}
          alignItems={"center"}
        >
          <Typography variant="h6" color={"text.primary"}>
            {t("packages")}
          </Typography>
          <MuiSwitch
            edge="end"
            name="fragile"
            label={t("fragile")}
            control={control}
          />
        </Grid>
        <Paper
          container
          component={Grid}
          spacing={1}
          sx={{ p: 1, m: 1 }}
          xs={12}
        >
          <Grid xs={12} sm={4} aligns="flex-start">
            <CustomAutocomplete
              rules={{ required: t("fieldIsRequired") }}
              control={control}
              errors={errors}
              name={"packageTypeId"}
              label={t("packageType")}
              parseData={(data) => parseData(data)}
              query={LIST_PACKAGE_TYPES_DROPDOWN.query}
              variables={{
                input: {
                  shippingMode: requestData?.shippingMode?.code,
                  shippingDirection: requestData?.shippingDirection?.code,
                  inPriceList: {
                    originPortId: requestData?.originPort?.id,
                    destinationPortId: requestData?.destinationPort?.id,
                    priceListFilterInput: {
                      mode: requestData?.shippingMode?.code,
                      direction: requestData?.shippingDirection?.code,
                      date: requestData?.requestDate,
                    },
                  },
                  ...(requestData?.shippingMode?.code !== "AIR" && {
                    isContainer: ["FTL", "FCL"].includes(
                      requestData?.loadingMode?.code
                    )
                      ? true
                      : false,
                  }),
                },
              }}
              defaultValue={autocompleteValues.packageTypeId}
              onChangeValue={(e) => {
                setValue("height", "");
                setValue("length", "");
                setValue("breadth", "");
                calcFeesHandler({
                  value: parseInt(e?.id),
                  name: "packageTypeId",
                });
                // onChangeNames(e, "packageTypeId");
              }}
            />
          </Grid>
          <Grid xs={12} sm={4} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              type={"number"}
              name={"count"}
              label={t("count")}
              rules={{
                min: {
                  value: 0,
                  message: t("validation:min", {
                    field: t("count"),
                    number: "0",
                  }),
                },
                required: t("fieldIsRequired"),
              }}
              onChange={(e) => {
                setTimeout(() => {
                  calcFeesHandler({
                    value: parseInt(e.target.value),
                    name: "count",
                  });
                }, 1000);
              }}
            />
          </Grid>

          <Grid xs={12} sm={4} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              type={"number"}
              name={"pieces"}
              label={t("pieces")}
              rules={{
                min: {
                  value: 0,
                  message: t("validation:min", {
                    field: t("pieces"),
                    number: "0",
                  }),
                },
              }}
            />
          </Grid>
          <Grid xs={12} sm={4} alignItems="flex-start">
            <CustomAutocomplete
              valueKey="code"
              control={control}
              errors={errors}
              name={"priceMode"}
              label={t("priceModes")}
              rules={{ required: t("fieldIsRequired") }}
              parseData={(data) => parseData(data)}
              variables={{ input: { code: "FR_PRICE_MODE" } }}
              query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
              disabled={
                ["FTL", "FCL"].includes(requestData?.loadingMode?.code) &&
                requestData?.shippingMode?.code !== "AIR"
                  ? true
                  : false
              }
              defaultValue={
                ["FTL", "FCL"].includes(requestData?.loadingMode?.code)
                  ? {
                      code: "PKG",
                      name: "Package",
                    }
                  : autocompleteValues.priceMode ?? selectedPriceMode
              }
              onCompleted={(data) => {
                ["FTL", "FCL"].includes(requestData?.loadingMode?.code)
                  ? setCalcFeesState((prev) => ({
                      ...prev,
                      priceMode: "PKG",
                    }))
                  : (autocompleteValues.priceMode?.code ||
                      selectedPriceMode?.code) &&
                    setCalcFeesState((prev) => ({
                      ...prev,
                      priceMode:
                        autocompleteValues.priceMode?.code ??
                        selectedPriceMode?.code,
                    }));
              }}
              onChangeValue={(e) => {
                calcFeesHandler({
                  value: e?.code,
                  name: "priceMode",
                });
              }}
            />
          </Grid>
          <Grid xs={12} sm={4} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              type={"number"}
              name={"weight"}
              label={t("weight")}
              rules={{
                min: {
                  value: 0,
                  message: t("validation:min", {
                    field: t("weight"),
                    number: "0",
                  }),
                },
                required: t("fieldIsRequired"),
              }}
              onChange={(e) => {
                setTimeout(() => {
                  calcFeesHandler({
                    value: parseInt(e.target.value),
                    name: "weight",
                  });
                }, 1000);
              }}
            />
          </Grid>
          <Grid xs={12} sm={4} alignItems="flex-start">
            <ControlMUItextField
              readOnly={true}
              control={control}
              errors={errors}
              type={"number"}
              name={"totalWeight"}
              label={t("totalWeight")}
              rules={{
                min: {
                  value: 0,
                  message: t("validation:min", {
                    field: t("totalWeight"),
                    number: "0",
                  }),
                },
              }}
            />
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"productId"}
              label={t("product")}
              disabled={commoditiesSelected.length > 0}
              parseData={(data) => parseData(data)}
              query={LIST_PRODUCT_DROPDOWN.query}
              defaultValue={autocompleteValues.productId}
            />
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"productPrice"}
              label={t("productPrice")}
              // margin="normal"
            />
          </Grid>
        </Paper>

        <Paper
          container
          component={Grid}
          spacing={1}
          // className={classes.spacing}
          sx={{ p: 1, m: 1 }}
          xs={12}
        >
          <Grid xs={12} sm={4} aligns="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              type={"number"}
              name={"length"}
              label={t("length")}
              rules={{
                min: {
                  value: 0,
                  message: t("validation:min", {
                    field: t("length"),
                    number: "0",
                  }),
                },
                required: t("fieldIsRequired"),
              }}
              onChange={(e) => {
                setTimeout(() => {
                  calcFeesHandler({
                    value: parseInt(e.target.value),
                    name: "length",
                  });
                }, 1000);
              }}
            />
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              type={"number"}
              name={"breadth"}
              label={t("breadth")}
              rules={{
                min: {
                  value: 0,
                  message: t("validation:min", {
                    field: t("breadth"),
                    number: "0",
                  }),
                },
                required: t("fieldIsRequired"),
              }}
              onChange={(e) => {
                setTimeout(() => {
                  calcFeesHandler({
                    value: parseInt(e.target.value),
                    name: "breadth",
                  });
                }, 1000);
              }}
            />
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              type={"number"}
              name={"height"}
              label={t("height")}
              rules={{
                min: {
                  value: 0,
                  message: t("validation:min", {
                    field: t("height"),
                    number: "0",
                  }),
                },
                required: t("fieldIsRequired"),
              }}
              onChange={(e) => {
                setTimeout(() => {
                  calcFeesHandler({
                    value: parseInt(e.target.value),
                    name: "height",
                  });
                }, 1000);
              }}
            />
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <ControlMUItextField
              readOnly={true}
              control={control}
              errors={errors}
              type={"number"}
              name={"volume"}
              label={t("volume")}
              rules={{
                min: {
                  value: 0,
                  message: t("validation:min", {
                    field: t("volume"),
                    number: "0",
                  }),
                },
              }}
            />
          </Grid>

          <Grid xs={12} sm={4} aligns="flex-start">
            <MUIselect
              name={"volumetricFactor"}
              label={t("volumetricFactor")}
              rules={{ required: t("fieldIsRequired") }}
              control={control}
              errors={errors}
              data={[
                { key: t("5000"), value: 5000 },
                { key: t("6000"), value: 6000 },
              ]}
              onChanges={(e) => {
                calcFeesHandler({
                  value: e.target.value,
                  name: "volumetricFactor",
                });
              }}
            />
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <ControlMUItextField
              readOnly={true}
              control={control}
              errors={errors}
              type={"number"}
              name={"volumetricWeight"}
              label={t("volumetricWeight")}
              rules={{
                min: {
                  value: 0,
                  message: t("validation:min", {
                    field: t("volumetricWeight"),
                    number: "0",
                  }),
                },
              }}
            />
          </Grid>
        </Paper>
        <Paper
          container
          component={Grid}
          spacing={1}
          // className={classes.spacing}
          sx={{ p: 1, m: 1 }}
          xs={12}
        >
          <Grid xs={12} sm={4} alignItems="flex-start">
            <ControlMUItextField
              readOnly={true}
              control={control}
              errors={errors}
              type={"number"}
              name={"chargeableUnits"}
              label={t("chargeableUnits")}
              rules={{
                min: {
                  value: 0,
                  message: t("validation:min", {
                    field: t("chargeableUnits"),
                    number: "0",
                  }),
                },
              }}
            />
          </Grid>
          <Grid xs={12} sm={4} alignItems="flex-start">
            <ControlMUItextField
              readOnly={true}
              control={control}
              errors={errors}
              type={"number"}
              name={"chargePrice"}
              label={t("chargePrice")}
              rules={{
                min: {
                  value: 0,
                  message: t("validation:min", {
                    field: t("chargePrice"),
                    number: "0",
                  }),
                },
              }}
            />
          </Grid>
          <Grid xs={12} sm={4} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              readOnly={true}
              type={"number"}
              name={"chargeAmount"}
              label={t("chargeAmount")}
              rules={{
                min: {
                  value: 0,
                  message: t("validation:min", {
                    field: t("chargeAmount"),
                    number: "0",
                  }),
                },
              }}
            />
          </Grid>
          <Grid xs={12} sm={4} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"currencyId"}
              label={t("currency")}
              disabled={true}
              rules={{
                required: t("fieldIsRequired"),
              }}
              onChangeValue={(e) => {
                setExchangeRate((prev) => ({
                  ...prev,
                  updateCurrent: false,
                  updateExchangeRate: true,
                }));
              }}
              parseData={(data) => parseData(data)}
              query={LIST_CURRENCIES_DROPDOWN.query}
              defaultValue={autocompleteValues.currencyId}
            />
          </Grid>
          <Grid xs={12} sm={4} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              disabled={!exchangeRate?.updatable}
              // readOnly={!exchangeRate?.updatable}
              type={"number"}
              name={"exchangeRate"}
              label={t("exchangeRate")}
              rules={{
                min: {
                  value: 0,
                  message: t("validation:min", {
                    field: t("exchangeRate"),
                    number: "0",
                  }),
                },
                required: t("fieldIsRequired"),
              }}
            />
          </Grid>
        </Paper>

        <Paper
          container
          component={Grid}
          spacing={1}
          // className={classes.spacing}
          sx={{ p: 1, m: 1 }}
          xs={12}
        >
          {/* <Grid xs={12} sm={4} aligns="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={`warehouseId`}
              label={t("wareHouse")}
              query={LIST_WAREHOUSE_DROPDOWN.query}
              rules={{
                required: t("fieldIsRequired"),
              }}
              defaultValue={autocompleteValues.warehouseId}
              parseData={(data) => data}
              variables={{
                input: {},
              }}
            />
          </Grid> */}
          <Grid xs={12} sm={4} aligns="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"originCountryId"}
              label={t("originCountry")}
              rules={{ required: t("fieldIsRequired") }}
              parseData={(data) => parseData(data)}
              query={LIST_COUNTRIES_DROPDOWN.query}
              defaultValue={autocompleteValues.originCountryId}
            />
          </Grid>
          <Grid
            xs={autocompleteValues.image || previewImage ? 8 : 12}
            sm={autocompleteValues.image || previewImage ? 3 : 4}
            alignItems="flex-start"
          >
            <UploadFile
              control={control}
              setValue={setValue}
              name="image"
              icon={"add_photo_alternate"}
              label={t("uploadImage")}
              accept=".png,.jpg"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    setPreviewImage(reader.result);
                  };
                  reader.readAsDataURL(file);
                } else {
                  setPreviewImage(null);
                }
                setAutocompleteValues((prev) => ({
                  ...prev,
                  image: "",
                }));
              }}
            />
          </Grid>
          {(autocompleteValues.image || previewImage) && (
            <Grid xs={4} sm={1} textAlign="center">
              {previewImage && (
                <Badge
                  style={{ padding: 0 }}
                  onClick={setClear}
                  badgeContent={<Clear sx={{ fontSize: "10px" }} />}
                  color="error"
                >
                  <img
                    src={previewImage}
                    alt="Preview"
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "1%",
                    }}
                    onClick={(event) => event.stopPropagation()}
                  />
                </Badge>
              )}
              {autocompleteValues.image && (
                <Badge
                  onClick={() => {
                    setClear();
                  }}
                  badgeContent={<Clear sx={{ fontSize: "10px" }} />}
                  color="error"
                >
                  <Box
                    sx={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "1%",
                    }}
                    component="img"
                    alt="signature"
                    src={getBackendUri(autocompleteValues.image)}
                    onClick={(event) => event.stopPropagation()}
                  />
                </Badge>
              )}
            </Grid>
          )}
        </Paper>
        <Paper
          container
          component={Grid}
          spacing={1}
          // className={classes.spacing}
          sx={{ p: 1, m: 1 }}
          xs={12}
        >
          <Toolbar className={classes.toolbar} variant="dense">
            <Typography
              className={classes.titles}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {t("commodities") + " " + commoditiesSelected?.length}
            </Typography>

            <IconButton
              aria-label="Add"
              className={classes.addIcon}
              onClick={() => commodityDrawerHandler(anchor, true)}
              disabled={Boolean(watch("productId"))}
              size="large"
            >
              <AddCircleOutline />
            </IconButton>
          </Toolbar>
          <Grid xs={12} sm={12}>
            <CommodityTableForm
              classes={classes}
              commoditiesSelected={commoditiesSelected}
              setChange={setChange}
              EmptyTextFields={EmptyTextFields}
              setPickedCommodity={setPickedCommodity}
              errors={errors}
              control={control}
              commodityDrawerHandler={commodityDrawerHandler}
              clearErrors={clearErrors}
              register={register}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          </Grid>
        </Paper>
        <Paper
          container
          component={Grid}
          spacing={1}
          // className={classes.spacing}
          sx={{ p: 1, m: 1 }}
          xs={12}
        >
          <Grid xs={12} sm={12}>
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"description"}
              rows={3}
              label={t("description")}
              margin="normal"
            />
          </Grid>
        </Paper>

        <Grid container sm={12} justifyContent="flex-end">
          <Button onClick={closeDialog}>{t("cancel")}</Button>
          <Button onClick={(event) => registerIsValid(event)} type="submit">
            {ssaveRequestPackageLoading ? <ButtonLoading /> : t("confirm")}
          </Button>
        </Grid>
      </Grid>
    </Root>
  );
}
