import React, { useState, Fragment, useEffect } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  IconButton,
  Collapse,
} from "@mui/material";
import { gql, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import * as gqlb from "gql-query-builder";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { useTranslation } from "react-i18next";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useForm } from "react-hook-form";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import FormButton from "../CustomComponents/Buttons/FormButton";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import { Globals } from "../HOC/Classes/Globals";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import {
  LIST_CURRENCIES_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { OpenInBrowser } from "@mui/icons-material";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { useHistory } from "react-router";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import formatMoney from "../../helpers/numbersDot";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import { useSnackbar } from "notistack";
const PREFIX = "MainFinanceStatement";

const classes = {
  divider: `${PREFIX}-divider`,
  ingButton: `${PREFIX}-ingButton`,
  mainSearch: `${PREFIX}-mainSearch`,
  table: `${PREFIX}-table`,
  toolbar: `${PREFIX}-toolbar`,
  tableContainer: `${PREFIX}-tableContainer`,
  balanceFont: `${PREFIX}-balanceFont`,
  fieldsContainer: `${PREFIX}-fieldsContainer`,
  balance: `${PREFIX}-balance`,
  headerToolbar: `${PREFIX}-headerToolbar`,
  main: `${PREFIX}-main`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.divider}`]: {
    minWidth: "93%",
  },

  [`& .${classes.ingButton}`]: {
    margin: theme.spacing(2, 1, 2),
    height: "3.5em",
    padding: "0 30px",
    width: "100%",
  },

  [`& .${classes.mainSearch}`]: {
    margin: theme.spacing(0),
    width: "100%",
  },

  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.toolbar}`]: {
    padding: theme.spacing(1.5, 0),
    borderBottom: "1px solid #ccd1d6",
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.tableContainer}`]: {
    position: "relative",
    borderRadius: 0,
    boxShadow: "none",
  },

  [`& .${classes.balanceFont}`]: {
    "& .MuiTableCell-body": {
      fontWeight: 600,
      fontSize: "1rem",
    },
  },

  [`& .${classes.fieldsContainer}`]: {
    padding: theme.spacing(1),
    textAlign: "center",
  },

  [`& .${classes.balance}`]: {
    textAlign: "center",
  },

  [`& .${classes.headerToolbar}`]: {
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

//*********Table Function*********
export const EXPOERT_MAIN_ACCOUNT_STATEMENT = gqlb.mutation({
  operation: "exportGlAccounts",
  variables: {
    input: {
      type: "ExportGlAccountsInput",
      required: true,
    },
  },
});

export const FINANCE = gqlb.query([
  {
    operation: "listGLAccounts",
    fields: [
      {
        paginatorInfo: ["total"],
      },
      {
        data: [
          "id",
          "code",
          "name",
          { type: ["code"] },
          {
            operation: "sumEntryRecords",
            fields: ["debit", "credit"],
            variables: {
              fromDate: { type: "Date" },
              toDate: { type: "Date" },
              currencyId: { type: "Int" },
              localCurrency: { type: "Boolean" },
            },
          },
          {
            operation: "startBalance:balance",
            fields: [],
            variables: {
              startBalanceDate: { name: "date", type: "Date", required: true },
              currencyId: { type: "Int" },
              localCurrency: { type: "Boolean" },
            },
          },
          {
            operation: "endBalance:balance",
            fields: [],
            variables: {
              endBalanceDate: { name: "date", type: "Date", required: true },
              currencyId: { type: "Int" },
              localCurrency: { type: "Boolean" },
              endDayEdge: {
                name: "dayEdge",
                type: "DayStartEnd",
              },
            },
          },
        ],
      },
    ],
    variables: {
      input: {
        type: "ListGLAccountsFilterInput",
      },
      first: {
        type: "Int",
      },
      page: {
        type: "Int",
      },
    },
  },
  {
    operation: "sumGlAccounts",
    fields: [
      "startSumDebit",
      "startSumCredit",
      "trxSumDebit",
      "trxSumCredit",
      "endSumDebit",
      "endSumCredit",
    ],
    variables: {
      sumInput: {
        name: "input",
        type: "SumGlAccountsInput",
      },
    },
  },
]);

//   [
//   {
//     operation: "listGLAccounts",
//     fields: [
//       "id",
//       "code",
//       "name",
//       { type: ["code"] },
//       {
//         operation: "sumEntryRecords",
//         fields: ["debit", "credit"],
//         variables: {
//           fromDate: { type: "Date" },
//           toDate: { type: "Date" },
//         },
//       },
//       {
//         operation: "startBalance:balance",
//         fields: [],
//         variables: {
//           startBalanceDate: { name: "date", type: "Date", required: true },
//         },
//       },
//       {
//         operation: "endBalance:balance",
//         fields: [],
//         variables: {
//           endBalanceDate: { name: "date", type: "Date", required: true },
//           endDayEdge: {
//             name: "dayEdge",
//             type: "DayStartEnd",
//           },
//         },
//       },
//       {
//         paginatorInfo: ["total"],
//       },
//     ],
//     variables: {
//       input: {
//         type: "ListGLAccountsFilterInput",
//       },
//     },
//   },
// ]
const initstartDate = moment(new Date())
  .locale("en")
  .subtract(1, "month")
  .add(1, "day")
  .format("YYYY-MM-DD");
const initEndDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

const MainFinanceStatement = (props) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    handleSubmit,
    control,
    setError,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      localCurrency: true,
    },
  });
  // const theme = useTheme();
  // const dir = theme.direction
  const urlQuery = urlParameters(window.location.search);
  const [filterLoad, setFilterLoad] = useState(true);
  const initDateRange = [
    urlQuery["startDate"] ? urlQuery["startDate"] : initstartDate,
    urlQuery["endDate"] ? urlQuery["endDate"] : initEndDate,
  ];
  const [dateRange, setDateRange] = useState(initDateRange);

  const [autocompleteValues, setAutocompleteValues] = useState({
    glAccount: null,
    currency: null,
  });
  const startDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const endDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
  const [search, setSearch] = useState({
    glAccountId: null,
    currencyId: urlQuery["currencyId"] ?? null,
    startDate: startDate,
    endDate: endDate,
    localCurrency: urlQuery["localCurrency"] ?? true,
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const glAccountListPermission = Globals.user.hasPermission(
    "accounting.gl_account.list"
  );

  const history = useHistory();

  const searchParams = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    return queryString;
  };

  const pushUrlSearch = (param, pathname, render) => {
    const params = searchParams(param);
    const url = history.createHref({
      pathname,
      search: "?" + params,
    });
    render ? pushUrl(props, url) : windowUrl(url);
    //this will not effect on history.location.search
  };

  useEffect(() => {
    const initCurrency = urlQuery["localCurrency"];
    const initCurrencyID = urlQuery["currencyId"];
    if (initCurrency !== undefined) {
      setValue("localCurrency", initCurrency);
    }
    if (!glAccountListPermission) {
      setSearch({
        startDate: startDate,
        endDate: endDate,
        renderState: true,
        localCurrency: initCurrency,
        ...(initCurrencyID && { currencyId: initCurrencyID }),
      });
    }
    if (props.match?.params?.id) {
      setSearch({
        startDate: startDate,
        endDate: endDate,
        glAccountId: parseInt(props.match.params.id),
        renderState: true,
        localCurrency: initCurrency,
        ...(initCurrencyID && { currencyId: initCurrencyID }),
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(search);
  const { data, loading } = useQuery(
    gql`
      ${FINANCE.query}
    `,
    {
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          parentId: search?.["glAccountId"],
        },
        sumInput: {
          parentId: search?.["glAccountId"],
          fromDate: search?.["startDate"],
          toDate: search?.["endDate"],
          localCurrency: search?.localCurrency,
          ...(!search?.localCurrency &&
            search?.currencyId && {
              currencyId: parseInt(search?.currencyId),
            }),
        },
        startBalanceDate: search?.["startDate"],
        endBalanceDate: search?.["endDate"],
        fromDate: search?.["startDate"],
        toDate: search?.["endDate"],
        endDayEdge: "END_OF_DAY",
        localCurrency: search?.localCurrency,
        ...(!search?.localCurrency &&
          search?.currencyId && {
            currencyId: parseInt(search?.currencyId),
          }),
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      notifyOnNetworkStatusChange: !search?.["renderState"],
      skip: !search,
      onError: ({ graphQLErrors }) => {
        console.log(graphQLErrors);
        setValidationError(graphQLErrors, setError);
      },
    }
  );
  const tableData = data?.listGLAccounts?.data;
  const tableTotalData = data?.sumGlAccounts;
  const parseData = (data) => {
    return data;
  };
  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const onSubmit = (data) => {
    setFilterLoad(false);
    setPage(0);
    const localCurrency = data.localCurrency;
    const currencyId = data.currencyId;
    pushUrlSearch(
      { startDate, endDate, localCurrency, ...(currencyId && { currencyId }) },
      `/admin/finance/statement/main${
        data["glAccountId"] && "/" + data["glAccountId"]
      }`
    );
    delete data["branchId"];
    data["startDate"] = startDate;
    data["endDate"] = endDate;
    if (data["glAccountId"] === "") {
      data["glAccountId"] = null;
    }
    if (data["branch"] === "") delete data["branch"];

    setSearch({ ...data, renderState: !search?.["renderState"] });
  };

  // const totals = (array = [], paramter, type) => {
  //   let sum = 0;

  //   array.forEach((ele) => {
  //     if (type === "credit" && ele[paramter] >= 0) {
  //       sum += ele[paramter];
  //     } else if (type === "debit" && ele[paramter] <= 0) {
  //       sum += ele[paramter];
  //     }
  //   });
  //   return sum;
  // };

  // const totalEntryRecords = (array = [], type) => {
  //   let sum = 0;

  //   array.forEach((ele) => {
  //     sum += ele?.["sumEntryRecords"]?.[type];
  //   });
  //   return sum;
  // };

  // const startBalanceDebit = totals(tableData, "startBalance", "debit");
  // const startBalanceCredit = totals(tableData, "startBalance", "credit");
  const startBalance =
    tableTotalData?.startSumCredit - tableTotalData?.startSumDebit;

  // const endBalanceDebit = totals(tableData, "endBalance", "debit");
  // const endBalanceCredit = totals(tableData, "endBalance", "credit");
  const endBalance = tableTotalData?.endSumCredit - tableTotalData?.endSumDebit;

  // const entryRacordsDebit = totalEntryRecords(tableData, "debit");
  // const entryRacordsCredit = totalEntryRecords(tableData, "credit");
  const entryRacords =
    tableTotalData?.trxSumCredit - tableTotalData?.trxSumDebit;

  // const onPrint = (param) => {
  //   const params = searchParams(search);
  //   const domain = `${window.location.origin}`;
  //   window.open(`${domain}${param}?${params}`);
  // };

  const { enqueueSnackbar } = useSnackbar();
  const [ExportAccountStatements] = useMutation(
    gql`
      ${EXPOERT_MAIN_ACCOUNT_STATEMENT.query}
    `,
    {
      onCompleted: (data) => {
        if (data.exportGlAccounts) {
          enqueueSnackbar(t("successExport"), {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        } else {
          enqueueSnackbar(t("errorExport"), {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const handelExportAccountStatement = () => {
    ExportAccountStatements({
      variables: {
        input: {
          listFilters: {
            ...(!search?.level &&
              search?.glAccountId !== "null" && {
                parentId: search?.glAccountId,
              }),
            ...(search?.level && { level: parseInt(search?.level) }),
          },
          dates: {
            fromDate: search?.startDate,
            toDate: search?.endDate,
            dayEdge: "END_OF_DAY",
          },
          sumInput: {
            fromDate: search?.["startDate"],
            toDate: search?.["endDate"],
            localCurrency: search?.localCurrency,
            ...(!search?.localCurrency &&
              search?.currencyId && {
                currencyId: parseInt(search?.currencyId),
              }),
          },
        },
      },
    });
  };

  const icons = [
    {
      id: "export",
      action: handelExportAccountStatement,
      disabled: !Boolean(data),
    },
    // {
    //   id: "print",
    //   title: "print",
    //   action: () => onPrint("/finance/statement/main"),
    //   icon: Print,
    //   disabled: !Boolean(data)
    // },
  ];

  return (
    <Root>
      <Grid
        container
        justifyContent="center"
        className={clsx(classes.mainSearch)}
      >
        {/* *******Table******* */}
        <Grid container item className={classes.table}>
          <TitleAppBar path={props.match.path}>
            <LongMenu icons={icons} />
          </TitleAppBar>
          <Toolbar className={classes.toolbar} variant="regular">
            <Grid
              container
              item
              component={"form"}
              alignItems="center"
              justifyContent="flex-start"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.fieldsContainer}
              >
                <MUIDateRangeCustom
                  value={dateRange}
                  onChangeValue={(value) => setDateRange(value)}
                />
              </Grid>
              {glAccountListPermission && (
                <Fragment>
                  <Grid
                    item
                    sm={6}
                    md={4}
                    xs={12}
                    className={classes.fieldsContainer}
                  >
                    <ListBranches
                      label={t("branch")}
                      control={control}
                      errors={errors}
                      skip={loading && filterLoad}
                      name={"branchId"}
                      onChangeValue={() => {
                        setValue("glAccountId", "");
                      }}
                      skipDefaultBranch={true}
                    />
                  </Grid>

                  <Grid
                    item
                    sm={6}
                    md={4}
                    xs={12}
                    className={classes.fieldsContainer}
                  >
                    <CustomAutocomplete
                      control={control}
                      errors={errors}
                      name={"glAccountId"}
                      label={t("glAccount")}
                      skip={loading && filterLoad}
                      parseData={(data) => parseData(data)}
                      query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                      variables={{
                        input: {
                          typeCode: "MAIN",
                          ...(watch("branchId") && {
                            branchId: {
                              value: watch("branchId"),
                              includeNull: true,
                            },
                          }),
                        },
                      }}
                      onCompleted={(data) =>
                        handelAutocompleteDefaultValue(
                          data,
                          props?.match?.params?.id,
                          "glAccount"
                        )
                      }
                      defaultValue={autocompleteValues.glAccount}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    md={4}
                    xs={12}
                    p={1}
                    // className={classes.fieldsContainer}
                  >
                    <MuiSwitch
                      edge="end"
                      name="localCurrency"
                      label={t("localCurrency")}
                      control={control}
                      onChange={(e) => {
                        setValue("currencyId", "");
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    md={4}
                    xs={12}
                    className={classes.fieldsContainer}
                  >
                    <CustomAutocomplete
                      control={control}
                      errors={errors}
                      name={"currencyId"}
                      label={t("currency")}
                      skip={
                        loading && filterLoad && Boolean(watch("localCurrency"))
                      }
                      parseData={(data) => parseData(data)}
                      disabled={Boolean(watch("localCurrency"))}
                      query={LIST_CURRENCIES_DROPDOWN.query}
                      variables={{
                        input: {},
                      }}
                      onCompleted={(data) =>
                        handelAutocompleteDefaultValue(
                          data,
                          urlQuery["currencyId"],
                          "currency"
                        )
                      }
                      defaultValue={autocompleteValues.currency}
                    />
                  </Grid>
                </Fragment>
              )}

              <Grid item sm={6} md={4} xs={12} p={1} sx={{ textAlign: "end" }}>
                <FormButton disabled={loading}>{t("search")}</FormButton>
              </Grid>
            </Grid>
          </Toolbar>
          {search && loading ? (
            <StyledLoading
              container
              item
              justifyContent="center"
              className={classes.main}
            >
              <FullScreenLoading height={"100%"} />
            </StyledLoading>
          ) : (
            <Fragment>
              <TableFixedHeaderWraper
                component={Paper}
                className={classes.tableContainer}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <FixedTableCell colSpan={2} />

                      <FixedTableCell className={classes.balance} colSpan={2}>
                        {t("startBalance")}
                      </FixedTableCell>
                      <FixedTableCell className={classes.balance} colSpan={2}>
                        {t("sumEntryRecords")}
                      </FixedTableCell>
                      <FixedTableCell className={classes.balance} colSpan={2}>
                        {t("balance")}
                      </FixedTableCell>
                      <FixedTableCell />
                    </TableRow>
                    <TableRow>
                      <FixedTableCell>{t("code")}</FixedTableCell>
                      <FixedTableCell>{t("name")}</FixedTableCell>
                      <FixedTableCell>{t("debit")}</FixedTableCell>
                      <FixedTableCell>{t("credit")}</FixedTableCell>
                      <FixedTableCell>{t("debit")}</FixedTableCell>
                      <FixedTableCell>{t("credit")}</FixedTableCell>
                      <FixedTableCell>{t("debit")}</FixedTableCell>
                      <FixedTableCell>{t("credit")}</FixedTableCell>
                      <FixedTableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData?.map((row, index) => {
                      return (
                        <TableRow key={Math.random()}>
                          <FixedTableCell component="th" scope="row">
                            {row.code}
                          </FixedTableCell>
                          <FixedTableCell>{row.name}</FixedTableCell>

                          <FixedTableCell>
                            {row?.startBalance >= 0
                              ? "0"
                              : formatMoney(
                                  Math.abs(row?.startBalance)?.toFixed(2)
                                ) || "0"}
                          </FixedTableCell>
                          <FixedTableCell>
                            {row?.startBalance <= 0
                              ? "0"
                              : formatMoney(
                                  Math.abs(row?.startBalance)?.toFixed(2)
                                ) || "0"}
                          </FixedTableCell>
                          <FixedTableCell>
                            {formatMoney(
                              row?.sumEntryRecords?.debit?.toFixed(2)
                            )}
                          </FixedTableCell>
                          <FixedTableCell>
                            {formatMoney(
                              row?.sumEntryRecords?.credit?.toFixed(2)
                            )}
                          </FixedTableCell>
                          <FixedTableCell>
                            {row?.endBalance >= 0
                              ? "0"
                              : formatMoney(
                                  Math.abs(row?.endBalance)?.toFixed(2)
                                ) || "0"}
                          </FixedTableCell>
                          <FixedTableCell>
                            {row?.endBalance <= 0
                              ? "0"
                              : formatMoney(
                                  Math.abs(row?.endBalance)?.toFixed(2)
                                ) || "0"}
                          </FixedTableCell>
                          <FixedTableCell>
                            <IconButton
                              color="primary"
                              onClick={() =>
                                pushUrlSearch(
                                  {
                                    startDate,
                                    endDate,
                                  },
                                  `/admin/finance/statement/${
                                    row.type.code === "SUB" ? "sub" : "main"
                                  }/${row.id}`,
                                  true
                                )
                              }
                              size="large"
                            >
                              <OpenInBrowser />
                            </IconButton>
                          </FixedTableCell>
                        </TableRow>
                      );
                    })}

                    <TableRow className={classes.balanceFont}>
                      <FixedTableCell />
                      <FixedTableCell>{t("total")}</FixedTableCell>
                      <FixedTableCell>
                        {formatMoney(tableTotalData?.startSumDebit)}
                      </FixedTableCell>
                      <FixedTableCell>
                        {formatMoney(tableTotalData?.startSumCredit)}
                      </FixedTableCell>

                      <FixedTableCell>
                        {formatMoney(tableTotalData?.trxSumDebit)}
                      </FixedTableCell>
                      <FixedTableCell>
                        {formatMoney(tableTotalData?.trxSumCredit)}
                      </FixedTableCell>

                      <FixedTableCell>
                        {formatMoney(tableTotalData?.endSumDebit)}
                      </FixedTableCell>
                      <FixedTableCell>
                        {formatMoney(tableTotalData?.endSumCredit)}
                      </FixedTableCell>
                      <FixedTableCell />
                    </TableRow>

                    <TableRow className={classes.balanceFont}>
                      <FixedTableCell />
                      <FixedTableCell>{t("balance")}</FixedTableCell>
                      <FixedTableCell>
                        {startBalance >= 0
                          ? "0"
                          : formatMoney(Math.abs(startBalance)?.toFixed(2)) ||
                            "0"}
                      </FixedTableCell>
                      <FixedTableCell>
                        {startBalance <= 0
                          ? "0"
                          : formatMoney(Math.abs(startBalance)?.toFixed(2)) ||
                            "0"}
                      </FixedTableCell>

                      <FixedTableCell>
                        {entryRacords >= 0
                          ? "0"
                          : formatMoney(Math.abs(entryRacords)?.toFixed(2)) ||
                            "0"}
                      </FixedTableCell>
                      <FixedTableCell>
                        {entryRacords <= 0
                          ? "0"
                          : formatMoney(Math.abs(entryRacords)?.toFixed(2)) ||
                            "0"}
                      </FixedTableCell>

                      <FixedTableCell>
                        {endBalance >= 0
                          ? "0"
                          : formatMoney(Math.abs(endBalance)?.toFixed(2)) ||
                            "0"}
                      </FixedTableCell>
                      <FixedTableCell>
                        {endBalance <= 0
                          ? "0"
                          : formatMoney(Math.abs(endBalance)?.toFixed(2)) ||
                            "0"}
                      </FixedTableCell>
                      <FixedTableCell />
                    </TableRow>
                  </TableBody>
                </Table>
              </TableFixedHeaderWraper>
              <MUITablePagination
                count={data?.listGLAccounts?.paginatorInfo?.total}
                rowsPerPage={rowsPerPage}
                page={!tableData?.length === 0 ? 0 : page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                disableLastPage
              />
            </Fragment>
          )}
        </Grid>
      </Grid>
    </Root>
  );
};
export default MainFinanceStatement;
