/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Paper, Icon, IconButton, Box, Button } from "@mui/material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { CUSTOMER_ID } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import CustomerTables from "./CustomerTables";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import { CustomersProvider } from "./Context/CustomersContext";
import { Image } from "@mui/icons-material";
import config from "../../config.json";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";

const CustomerView = (props) => {
  const { t } = useTranslation();
  const customerId = props.match.params.id?.trim();
  const user = Globals.user;
  const [dialogDetails, setDialogDetails] = useState({
    state: false,
    function: null,
    confirmAction: true,
    title: "",
    content: "",
  });
  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;
    console.log(
      `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`
    );

    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };
  const closeConfirmationDialog = () => {
    setDialogDetails((prev) => ({
      ...prev,
      state: false,
    }));
  };
  const openSignatureDialog = (imgPath) => {
    setDialogDetails((prev) => ({
      state: true,
      title: null,
      content: (
        <Box
          sx={{ background: "#fff", width: "100%" }}
          component="img"
          alt="signature"
          src={getBackendUri(imgPath.path)}
        />
      ),
      confirmAction: false,
      function: () => {},
    }));
  };
  const { loading, data } = useQuery(
    gql`
      ${CUSTOMER_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(customerId) },
    }
  );

  const customerData = data?.customer;
  const value = {
    priceModes: customerData?.priceModes,
    sales: customerData?.sales,
    loading: loading,
  };
  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/customers/${customerId}/edit`),
      permission: user.hasPermission("freight.customer.update"),
    },
  ];

  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !customerData ? (
    <NotFound />
  ) : (
    <>
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>

      <RootStyleView spacing={2} p={2} justifyContent="center">
        {dialogDetails.state && (
          <CustomDialog
            title={dialogDetails.title}
            fullWidth
            maxWidth="xs"
            onClose={closeConfirmationDialog}
            content={dialogDetails.content}
            open={dialogDetails.state}
            actions={
              dialogDetails.function !== null && (
                <>
                  <Button color="primary" onClick={closeConfirmationDialog}>
                    {dialogDetails.confirmAction ? t("cancel") : t("close")}
                  </Button>
                  {dialogDetails.confirmAction && (
                    <Button color="primary" onClick={dialogDetails.function}>
                      {t("confirm")}
                    </Button>
                  )}
                </>
              )
            }
          >
            {dialogDetails.function === null && " "}
          </CustomDialog>
        )}
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair title={t("code")} value={customerData?.code} />
          <KeyValuePair title={t("name")} value={customerData?.name} />
          <KeyValuePair title={t("date")} value={customerData?.date} />
          <KeyValuePair
            title={t("active")}
            value={
              customerData?.active ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("branch")}
            value={
              <SpanLink
                pathname={`/admin/branches/${customerData?.branch?.id}`}
              >
                {customerData?.branch?.name}
              </SpanLink>
            }
          />
          <KeyValuePair
            title={t("glAccount")}
            value={customerData?.glAccount?.name}
          />
          <KeyValuePair
            title={t("responsibleName")}
            value={customerData?.responsibleName}
          />
          <KeyValuePair
            title={t("businessName")}
            value={customerData?.businessName}
          />
          <KeyValuePair
            title={t("businessActivity")}
            value={customerData?.businessActivity}
          />
          <KeyValuePair
            title={t("businessField")}
            value={customerData?.businessField}
          />

          <KeyValuePair title={t("type")} value={customerData?.type?.name} />
          <KeyValuePair
            title={t("commission")}
            value={customerData?.commission}
          />
          {customerData?.image ? (
            <KeyValuePair
              title={t("image")}
              value={
                <IconButton
                  size="small"
                  onClick={() => openSignatureDialog(customerData?.image)}
                >
                  <Image fontSize="small" />
                </IconButton>
              }
            />
          ) : (
            <KeyValuePair title={t("image")}></KeyValuePair>
          )}

          <KeyValuePair
            title={t("users")}
            value={
              customerData?.users &&
              customerData?.users.map((user, index) => (
                <SpanLink
                  key={user.id}
                  sx={{ display: "inline" }}
                  pathname={`/admin/users/${user.id}`}
                >
                  {user.username}{" "}
                  {!(customerData?.users.length - 1 === index) && " , "}
                </SpanLink>
              ))
            }
          />
        </Paper>
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair
            title={t("country")}
            value={
              <SpanLink
                pathname={`/admin/countries/${customerData?.country?.id}`}
              >
                {customerData?.country?.name}
              </SpanLink>
            }
          />
          <KeyValuePair title={t("state")} value={customerData?.state?.name} />
          <KeyValuePair
            title={t("phone")}
            value={<span dir="ltr">{customerData?.phone}</span>}
          />
          <KeyValuePair
            title={t("mobile")}
            value={<span dir="ltr">{customerData?.mobile}</span>}
          />
          <KeyValuePair title={t("address")} value={customerData?.address} />
          <KeyValuePair
            title={t("idType")}
            value={customerData?.idType?.name}
          />
          <KeyValuePair title={t("idNumber")} value={customerData?.idNumber} />
          <KeyValuePair
            title={t("idIssueDate")}
            value={customerData?.idIssueDate}
          />
        </Paper>
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair
            title={t("identificationType")}
            value={customerData?.identificationType?.name}
          />
          <KeyValuePair
            title={t("taxCardNumber")}
            value={customerData?.taxCardNumber}
          />
          <KeyValuePair
            title={t("registerationNumber")}
            value={customerData?.registerationNumber}
          />
          <KeyValuePair
            title={t("registerationDate")}
            value={customerData?.registerationDate}
          />
          <KeyValuePair
            title={t("postalCode")}
            value={customerData?.postalCode}
          />
          <KeyValuePair
            title={t("mailBoxNumber")}
            value={customerData?.mailBoxNumber}
          />
          <KeyValuePair
            title={t("currency")}
            value={customerData?.currency?.name}
          />
          <KeyValuePair
            title={t("creditLimit")}
            value={customerData?.creditLimit}
          />
          <KeyValuePair
            title={t("category")}
            value={customerData?.customerCategory?.name}
          />
        </Paper>
        <CustomersProvider value={value}>
          <CustomerTables />
        </CustomersProvider>
      </RootStyleView>
    </>
  );
};

export default CustomerView;
