import { useQuery, gql, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { PRICE_LIST, SAVE_PRICE_LIST } from "./Graphql";
import PriceListTable from "./PriceListTables";
import {
  Button,
  Paper,
  Collapse,
  IconButton,
  Alert,
  Box,
  Stack,
} from "@mui/material";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { useSnackbar } from "notistack";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { LIST_CURRENCIES_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { useHistory } from "react-router";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import moment from "moment";
import MUIDate from "../HOC/MUI/MUIDate";
import { dateFormat } from "../../helpers/dateFunctions";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import PriceListPackageRouts from "./packageForm";
import PriceListWeightRouts from "./RouteWeightForm";
import { Clear } from "@mui/icons-material";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";
import { PriceListProvider } from "./PriceListContext";
import RoutesForm from "./RoutesForm";
const PriceListForm = (props) => {
  const { id } = props;
  const history = useHistory();

  const [RouteList, setRoutesList] = useState();
  const [routesIndex, setRoutesIndex] = useState({
    index: 0,
    update: false,
  });
  const [packageIndex, setPackageIndex] = useState({
    index: 0,
    update: false,
  });
  const [weightIndex, setWeightIndex] = useState({
    index: 0,
    update: false,
  });
  const [dialog, setDialog] = useState({
    dialog: false,
    routes: false,
    package: false,
    weight: false,
  });
  const [routesErrorMessage, setRoutesErrorMessage] = useState();
  const [disabled, setDisabled] = useState({
    routeId: false,
    toDate: false,
    fromDate: false,
    formEdit: false,
  });
  const [packageErrorMessage, setPackageErrorMessage] = useState(false);
  const [weightErrorMessage, setWeightErrorMessage] = useState(false);
  const [selectedNames, setSelectedNames] = useState({
    destinationPortId: "",
    originPortId: "",
    cpmPrice: "",
  });

  const [pathURL, setPathURL] = useState(props.match.path);

  const [priceListId, setPriceListId] = useState(
    props?.match?.params?.id === "0" ? 0 : parseInt(props?.match?.params?.id)
  );
  useEffect(() => {
    id && setPriceListId(id === "0" ? 0 : parseInt(id));
    return () => {};
  }, [id]);

  const priceListCopyId = parseInt(props?.match?.params?.copyId);
  const { t } = useTranslation();

  const {
    control: mainControl,
    formState: { errors: mainErrors },
    handleSubmit: mainHandleSubmit,
    setValue: mainSetValue,
    setError: mainSetError,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const [savePriceList, { loading: savePriceListLoad }] = useMutation(
    gql`
      ${SAVE_PRICE_LIST.query}
    `
  );

  const [autocompleteValues, setAutocompleteValues] = useState({
    currencyId: null,
    shippingDirection: null,
    shippingMode: null,
  });
  const initFromDate = moment(new Date())
    .locale("en")
    .subtract("month")
    .add("day")
    .format("YYYY-MM-DD");
  const [dateRange, setDateRange] = useState({
    fromDate: initFromDate,
    toDate: null,
  });

  const resetToDate = () => {
    setDateRange((prev) => ({
      ...prev,
      toDate: null,
    }));
  };
  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      mainSetValue(name, updateValue);
    }
  };
  const { data: pricListData, loading: priceListLoading } = useQuery(
    gql`
      ${PRICE_LIST.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !priceListId && priceListId !== 0,
      variables: { id: priceListId | priceListCopyId },
      onCompleted: (data) => {
        data?.priceList?.routes && setRoutesList(data?.priceList.routes);
        data?.priceList?.description &&
          mainSetValue("description", data?.priceList?.description);
        (priceListId || priceListId === 0) &&
          mainSetValue("code", data?.priceList.code);
        const nameValue =
          priceListCopyId || priceListCopyId === 0
            ? t("copy") + " " + data?.priceList.name
            : data?.priceList.name;
        mainSetValue("name", nameValue);
        setDateRange((prev) => ({
          ...prev,
          fromDate: data?.priceList.fromDate,
          toDate: data?.priceList.toDate,
        }));
        if (data?.priceList.toDate) {
          setDisabled((prev) => ({
            ...prev,
            toDate: true,
          }));
        }
        if (data?.priceList?.routes[0]?.id) {
          setDisabled((prev) => ({
            ...prev,
            routeId: true,
          }));
        }
        if (data?.priceList?.fromDate) {
          setDisabled((prev) => ({
            ...prev,
            fromDate: true,
          }));
        }

        setDisabled((prev) => ({
          ...prev,
          formEdit: true,
        }));
        setAutocompleteValues({
          currencyId: data?.priceList.currency,
          shippingMode: data?.priceList.shippingDirection,
          shippingDirection: data?.priceList?.shippingMode,
          // chargeElement: data?.priceList?.chargeElement,
        });
      },
    }
  );

  const parseData = (data) => {
    return data;
  };

  const handelCloseDialog = () => {
    setDialog({
      dialog: false,
      routes: false,
      package: false,
      weight: false,
    });
    setRoutesErrorMessage(false);
    setPackageErrorMessage(false);
    setWeightErrorMessage(false);
    setRoutesIndex((prev) => ({ ...prev, update: false }));
    setPackageIndex((prev) => ({ ...prev, update: false }));
    setWeightIndex((prev) => ({ ...prev, update: false }));
  };

  const onChangeNames = (e, parameter, remove) => {
    const name = e?.name;
    setSelectedNames((prev) => ({
      ...prev,
      [parameter]: name,
      ...(remove && { [remove]: "" }),
    }));
    setRoutesErrorMessage(false);
    setPackageErrorMessage(false);
    setWeightErrorMessage(false);
  };

  const addRouteDialog = (index) => {
    if (index || index === 0) {
      setRoutesIndex((prev) => ({
        ...prev,
        index,
        update: true,
      }));
    } else {
      setRoutesIndex({
        index,
        update: false,
      });
    }
    setDialog({
      dialog: true,
      routes: true,
      package: false,
      weight: false,
    });
  };
  const addPackageDialog = (routesIndex, packageIndex) => {
    setRoutesIndex({
      index: routesIndex,
      update: false,
    });
    if (packageIndex || packageIndex === 0) {
      setPackageIndex({
        index: packageIndex,
        update: true,
      });
    }
    setDialog({
      dialog: true,
      package: true,
      routes: false,
    });
  };
  const addWeightDialog = (routesIndex, weightIndex) => {
    setRoutesIndex({
      index: routesIndex,
      update: false,
    });
    if (weightIndex || weightIndex === 0) {
      setWeightIndex({
        index: weightIndex,
        update: true,
      });
    }
    setDialog({
      dialog: true,
      weight: true,
      package: false,
      routes: false,
    });
  };

  const submitMutation = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
      if (["fromDate", "toDate"].includes(key)) {
        delete data[key];
      }
    }
    savePriceList({
      variables: {
        input: {
          ...(priceListId && { id: priceListId }),
          ...data,
          // ...(dateRange?.fromDate && {
          fromDate: dateFormat(dateRange?.fromDate),
          // }),
          ...(dateRange?.toDate && { toDate: dateFormat(dateRange?.toDate) }),
        },
      },
    })
      .then((data) => {
        const url = history.createHref({
          pathname: data?.data?.savePriceList.editable
            ? `/admin/price-lists/${data?.data?.savePriceList?.id}/edit`
            : `/admin/price-lists/${data?.data?.savePriceList?.id}`,
        });
        pushUrl(props, url);
        setPathURL(url);
        windowReplaceUrl(url);
        setPriceListId(data?.data?.savePriceList?.id);
        setDisabled((prev) => ({
          ...prev,
          formEdit: true,
        }));

        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        if (graphQLErrors?.[0]?.extensions?.category === "validation") {
          setValidationError(graphQLErrors, mainSetError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      });
  };

  const value = {
    priceListId,
    parseData,
    onChangeNames,
    routesIndex,
    RouteList,
    routesErrorMessage,
    handelCloseDialog,
    setRoutesErrorMessage,
    setDisabled,
    setSelectedNames,
    pricListData,
    packageIndex,
    packageErrorMessage,
    setPackageErrorMessage,
    setRoutesList,
    setRoutesIndex,
    selectedNames,
    weightIndex,
    weightErrorMessage,
    setWeightErrorMessage,
    dialog,
    addRout: addRouteDialog,
    addPackage: addPackageDialog,
    addWeight: addWeightDialog,
  };

  return priceListLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !pricListData && priceListId ? (
    <NotFound />
  ) : (
    <PriceListProvider value={value}>
      <RootStyleForm>
        {dialog.routes && <RoutesForm />}
        {dialog.package && <PriceListPackageRouts />}
        {dialog.weight && <PriceListWeightRouts />}
        {pathURL && <TitleAppBar path={pathURL} />}
        <Stack spacing={2} p={2}>
          <Paper
            className={classesForm.box}
            component="form"
            onSubmit={mainHandleSubmit(submitMutation)}
            sx={{ marginTop: !props?.match?.path && "68px !important" }}
          >
            {priceListId && disabled.formEdit ? (
              <Grid sm={12} xs={12}>
                <Alert
                  severity="warning"
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setDisabled((prev) => ({
                          ...prev,
                          formEdit: false,
                        }));
                      }}
                    >
                      {t("update")}
                    </Button>
                  }
                >
                  {t("updateRecord")}
                </Alert>
              </Grid>
            ) : (
              ""
            )}
            <Grid container spacing={2} sx={{ position: "relative" }}>
              {(disabled.formEdit || disabled?.toDate) && (
                <Box
                  className={classesForm.overlay}
                  sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
                ></Box>
              )}
              <Grid xs={12} sm={4}>
                <ControlMUItextField
                  control={mainControl}
                  errors={mainErrors}
                  name="code"
                  label={t("code")}
                  disabled={
                    disabled.routeId || disabled.toDate || disabled.formEdit
                  }
                />
              </Grid>
              <Grid xs={12} sm={4}>
                <ControlMUItextField
                  control={mainControl}
                  errors={mainErrors}
                  name="name"
                  label={t("name")}
                  rules={{ required: t("fieldIsRequired") }}
                  disabled={disabled.toDate || disabled.formEdit}
                />
              </Grid>
              <Grid xs={12} sm={4}>
                <CustomAutocomplete
                  rules={{ required: t("fieldIsRequired") }}
                  control={mainControl}
                  errors={mainErrors}
                  name={"currencyId"}
                  label={t("currency")}
                  disabled={
                    disabled.routeId || disabled.toDate || disabled.formEdit
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_CURRENCIES_DROPDOWN.query}
                  variables={{
                    input: {},
                  }}
                  defaultValue={autocompleteValues.currencyId}
                />
              </Grid>

              <Grid xs={12} sm={6}>
                <LookupDropdown
                  control={mainControl}
                  errors={mainErrors}
                  name={"shippingDirection"}
                  label={t("shippingDirection")}
                  disabled={
                    disabled.routeId || disabled.toDate || disabled.formEdit
                  }
                  rules={{ required: t("fieldIsRequired") }}
                  variables={{
                    input: { code: "FR_SHIPPING_DIRECTION" },
                  }}
                  onCompleted={(data) => {
                    lookupsComplete(
                      data,
                      pricListData?.priceList?.shippingDirection?.code,
                      "shippingDirection"
                    );
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <LookupDropdown
                  control={mainControl}
                  errors={mainErrors}
                  disabled={
                    disabled.routeId || disabled.toDate || disabled.formEdit
                  }
                  name={"shippingMode"}
                  label={t("shippingMode")}
                  rules={{ required: t("fieldIsRequired") }}
                  variables={{
                    input: { code: "FR_SHIPPING_MODE" },
                  }}
                  onCompleted={(data) => {
                    lookupsComplete(
                      data,
                      pricListData?.priceList?.shippingMode?.code,
                      "shippingMode"
                    );
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <MUIDate
                  name={"fromDate"}
                  label={t("validFromDate")}
                  disabled={disabled.fromDate || disabled.formEdit}
                  control={mainControl}
                  value={dateRange.fromDate}
                  defaultValue={dateRange.fromDate}
                  disablePastDates
                  onChange={(e) => {
                    setDateRange((prev) => ({ ...prev, fromDate: e }));
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <MUIDate
                  clearable
                  disabled={disabled.toDate || disabled.formEdit}
                  name={"toDate"}
                  label={t("validToDate")}
                  control={mainControl}
                  value={dateRange.toDate}
                  defaultValue={dateRange.toDate}
                  onChange={(e) => {
                    setDateRange((prev) => ({ ...prev, toDate: e }));
                  }}
                  InputProps={{
                    ...{
                      endAdornment: (
                        <IconButton
                          size="small"
                          key={"toDate"}
                          onClick={() => {
                            resetToDate();
                            mainSetValue("toDate", "");
                          }}
                        >
                          <Clear size="small" />
                        </IconButton>
                      ),
                    },
                  }}
                />
              </Grid>

              <Grid xs={12} sm={12}>
                <ControlMUItextField
                  control={mainControl}
                  errors={mainErrors}
                  disabled={disabled.toDate || disabled.formEdit}
                  name="description"
                  label={t("description")}
                />
              </Grid>

              <Grid xs={12} justifyContent="flex-end" display="flex">
                <FormButton
                  className={classesForm.button}
                  disabled={
                    savePriceListLoad || disabled.toDate || disabled.formEdit
                  }
                >
                  {savePriceListLoad ? <ButtonLoading /> : t("save")}
                </FormButton>
              </Grid>
            </Grid>
          </Paper>
          <Grid container sx={{ position: "relative" }}>
            {(!priceListId || !disabled?.formEdit || disabled?.toDate) && (
              <Box className={classesForm.overlay}></Box>
            )}
            <PriceListTable />
          </Grid>
        </Stack>
      </RootStyleForm>
    </PriceListProvider>
  );
};

export default PriceListForm;
