import { createContext, useContext } from "react";

const ContainersContext = createContext();
export const useContainersContext = () => {
  const context = useContext(ContainersContext);
  if (context === "undefined") {
    throw new Error("ContainersContext was used outside of the provider");
  }
  return context;
};
export const ContainersProvider = ({ children, value }) => {
  return (
    <ContainersContext.Provider value={value}>
      {children}
    </ContainersContext.Provider>
  );
};
