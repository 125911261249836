import React, { createContext, useContext } from "react";

const ContextProvider = createContext();

export const RequestContext = ({ children, value }) => {
  return (
    <ContextProvider.Provider value={value}>
      {children}
    </ContextProvider.Provider>
  );
};

export const useRequestContext = () => {
  const context = useContext(ContextProvider);
  if (context === "undefined") {
    throw new Error("RequestContext was used outside of the provider");
  }
  return context;
};
