import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  Stack,
  useTheme,
} from "@mui/material";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  AddCircleOutline,
  AttachMoney,
  Done,
  DoneAll,
} from "@mui/icons-material";
import moment from "moment";
import { useForm } from "react-hook-form";
import {
  APPROVE_VOUCHER_MUTATION,
  CURRENCY_ID,
  GL_APPROVE_VOUCHER_MUTATION,
  PAYMENT_METHOD_ID,
  SAVE_VOUCHER_MUTATION,
  VOUCHER_BY_ID_QUERY,
} from "./Graphql";
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import NotFound from "../../Error/NotFound";
import {
  LIST_BANK_ACCOUNT_DROPDOWN,
  LIST_BANKS_DROPDOWN,
  LIST_CURRENCIES_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_PAYMENT_METHODS_DROPDOWN,
  LIST_SAFES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import CustomButton from "../HOC/MUI/CustomButton";
import TitleAppBar from "../../Layout/TitleAppBar";
import { Globals } from "../HOC/Classes/Globals";
import MUIDate from "../HOC/MUI/MUIDate";
import { dateFormat } from "../../helpers/dateFunctions";
import { useAccountType } from "../../Hooks/useAccountType";
import { useDocumentTypeType } from "../../Hooks/useDocumentType";
import CustomList from "../HOC/CustomComponents/CustomListDialog/CustomList";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";

/////////////////DIALOG///////////////

const VoucherForm = (props) => {
  const { t } = useTranslation();
  // const listType = props.match.params.type;
  const [pathURL] = useState(props.match.path);
  const [listType, setListType] = useState(props.match.params.type);
  const [exchangeRate, setExchangeRate] = useState(false);
  const [disabled, setDisabled] = useState({
    formEdit: false,
  });
  const paymentMethodId =
    props.match.path === "/admin/vouchers/create" ||
    props.match.path === "/admin/vouchers/:id/edit"
      ? 1
      : 2;
  const theme = useTheme();
  const [autocompleteValues, setAutocompleteValues] = useState({
    customer: null,
    employee: null,
    vendor: null,
    customAgent: null,
    agency: null,
    carrier: null,
    GLAccount: null,
    branch: null,
    safe: null,
    bankAccount: null,
    deliveryAgent: null,
    transactionType: null,
    paymentMethod: null,
    // type: null,
    payeeTransactionType: null,
    payeeType: null,
    bank: null,
    currency: null,
  });
  const initFromDate = moment(new Date())
    .locale("en")
    .subtract("month")
    .add("day")
    .format("YYYY-MM-DD");
  const [date, setDate] = useState({
    expiryDate: initFromDate,
    chequeDate: initFromDate,
    voucherDate: initFromDate,
  });

  const [pickedRequest, setPickedRequest] = React.useState();

  const setSelect = (obj) => {
    setPickedRequest(obj?.data);
    setValue(dynamicTextFiled?.name, obj?.data?.code);
  };
  const [dialogDetails, setDialogDetails] = useState({
    state: false,
    function: null,
    confirmAction: true,
    title: "",
    content: "",
  });

  const openPickedRequestDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: null,
      content: (
        <CustomList
          setSelected={(obj) => {
            setSelect(obj);
          }}
          filters={billFilters}
          selected={pickedRequest}
          pathType={dynamicTextFiled?.queryName}
          done={() => {
            setDialogDetails((prev) => ({
              ...prev,
              state: false,
            }));
          }}
        />
      ),
      confirmAction: true,
      function: null,
    }));
  };
  const openApproveDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: t("approveVoucher"),
      content: t("approveVoucherConfirmationMessage"),
      confirmAction: true,
      function: approveVoucher,
    }));
  };
  const openGlApproveDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: t("glApprove"),
      content: t("glApproveRecordMessage"),
      confirmAction: true,
      function: glApproveVoucher,
    }));
  };
  const closeConfirmationDialog = () => {
    setDialogDetails((prev) => ({
      ...prev,
      state: false,
    }));
  };

  const [voucherData, setVoucherData] = useState();

  const { enqueueSnackbar } = useSnackbar();
  const user = Globals.user;

  const {
    formState: { errors },
    handleSubmit,
    setError,
    control,
    setValue,
    watch,
  } = useForm();

  const voucherId = props.match.params.id;

  const { data: paymentMethodType, loading } = useQuery(
    gql`
      ${PAYMENT_METHOD_ID.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !watch("paymentMethodId"),
      variables: {
        id: parseInt(watch("paymentMethodId")),
        input: {},
      },
      onCompleted: (data) => {},
    }
  );
  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const [saveVoucherMutation, { loading: saveVoucherLoad }] = useMutation(
    gql`
      ${SAVE_VOUCHER_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const [approveVoucherMutation, { loading: approveLoad }] = useMutation(
    gql`
      ${APPROVE_VOUCHER_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => setVoucherData(data["approveVoucher"]),
    }
  );

  const [glApproveVoucherMutation, { loading: glApproveLoad }] = useMutation(
    gql`
      ${GL_APPROVE_VOUCHER_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => setVoucherData(data["glApproveVoucher"]),
    }
  );

  const { data: voucherByIdQuery, loading: voucherByIdLoad } = useQuery(
    gql`
      ${VOUCHER_BY_ID_QUERY.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !voucherId,
      variables: {
        id: +voucherId,
      },
      onCompleted: (data) => {
        if (data?.voucher === null) return;
        const voucher = data?.voucher;

        setVoucherData(voucher);
        setValue("id", +voucher?.id);
        setValue("code", voucher?.code);
        setValue("voucherDate", voucher?.voucherDate);
        setValue("amount", voucher?.amount);
        setValue("exchangeRate", voucher?.exchangeRate);

        // "chequeNumber",
        // "nameOnCheque",
        // "nameOnCard",
        // "cardNumber",
        setDisabled((prev) => ({
          ...prev,
          formEdit: true,
        }));

        voucher?.chequeNumber &&
          setValue("chequeNumber", voucher?.chequeNumber);
        voucher?.nameOnCheque &&
          setValue("nameOnCheque", voucher?.nameOnCheque);
        voucher?.nameOnCard && setValue("nameOnCard", voucher?.nameOnCard);
        voucher?.cardNumber && setValue("cardNumber", voucher?.cardNumber);
        voucher?.notes && setValue("notes", voucher?.notes);
        voucher?.referenceNumber &&
          setValue("referenceNumber", voucher?.referenceNumber);
        setDate((prev) => ({
          ...prev,
          voucherDate: voucher.voucherDate,
          chequeDate: voucher.chequeDate ?? initFromDate,
          expiryDate: voucher.expiryDate ?? initFromDate,
        }));
        const documentCode = documentTypeData(
          voucher.payeeTransactionType?.code,
          voucher
        );

        // setValue(
        //   documentTypeName(voucher.payeeTransactionType?.code),

        //   documentCode.code
        // );
        setPickedRequest(documentCode);
        setListType(voucher?.type?.code);
        setAutocompleteValues({
          branch: voucher.branch,
          customer: voucher.customer,
          employee: voucher.employee,
          vendor: voucher.vendor,
          customAgent: voucher.agent,
          agency: voucher.agency,
          carrier: voucher.carrier,
          ...(voucher?.deliveryAgent && {
            deliveryAgent: voucher?.deliveryAgent,
          }),
          ...(voucher?.glAccount && {
            glAccount: voucher?.glAccount,
          }),
          ...(voucher?.bankAccount && {
            bankAccount: voucher?.bankAccount,
          }),
          ...(voucher?.bank && {
            bank: voucher?.bank,
          }),
          ...(voucher?.safe && {
            safe: voucher?.safe,
          }),
          type: voucher?.type,
          paymentMethod: voucher?.paymentMethod,
          payeeType: voucher?.payeeType,
          payeeTransactionType: voucher?.payeeTransactionType,

          currency: voucher?.currency,
        });
      },
    }
  );

  ///////
  /////////////END DRAWER////////////////

  const saveVoucher = (data) => {
    if (paymentMethodType?.paymentMethod?.type?.code === "CRD") {
      data["expiryDate"] = dateFormat(data["expiryDate"]);
      data["chequeDate"] = null;
      data["bankId"] = null;
      data["nameOnCheque"] = null;
      data["chequeNumber"] = null;
    } else if (paymentMethodType?.paymentMethod.type?.code === "CHQ") {
      data["chequeDate"] = dateFormat(data["chequeDate"]);
      data["expiryDate"] = null;
      data["bankAccountId"] = null;
      data["cardNumber"] = null;
      data["nameOnCard"] = null;
    } else if (paymentMethodType?.paymentMethod.type?.code === "CSH") {
      // data["chequeDate"] = dateFormat(data["chequeDate"]);
      data["expiryDate"] = null;
      data["bankAccountId"] = null;
      data["cardNumber"] = null;
      data["nameOnCard"] = null;
      data["chequeDate"] = null;
      data["bankId"] = null;
      data["nameOnCheque"] = null;
      data["chequeNumber"] = null;
    } else if (paymentMethodType?.paymentMethod.type?.code === "BDW") {
      // data["chequeDate"] = dateFormat(data["chequeDate"]);
      data["expiryDate"] = null;
      data["safeId"] = null;
      data["cardNumber"] = null;
      data["nameOnCard"] = null;
      data["chequeDate"] = null;
      data["bankId"] = null;
      data["nameOnCheque"] = null;
      data["chequeNumber"] = null;
    } else {
      delete data["expiryDate"];
      delete data["chequeDate"];
    }
    data["voucherDate"] = dateFormat(data["voucherDate"]);

    data["amount"] = +data["amount"];
    data["exchangeRate"] = +data["exchangeRate"];
    for (const key in data) {
      if (data.payeeType === "ACT") {
        data["payeeTransactionType"] = null;
        data[dynamicTextFiled?.name] = null;
        delete data["documentId"];
      }
      if (
        (data[key] === "" && !["referenceNumber", "notes"].includes(key)) ||
        [
          "billCode",
          "invoiceId",
          "billId",
          "adjustmentId",
          "voucherId",
          "documentId",
        ].includes(key)
      ) {
        delete data[key];
      }
    }
    console.log(data);

    return saveVoucherMutation({
      variables: {
        input: {
          ...data,
          type: listType.toUpperCase(),
          ...(watch("payeeTransactionType") === "INVOICE" &&
            data.payeeType !== "ACT" && {
              invoiceId: pickedRequest?.id,
              billId: null,
              voucherId: null,
              adjustmentId: null,
            }),
          ...(watch("payeeTransactionType") === "BILL" &&
            data.payeeType !== "ACT" && {
              billId: pickedRequest?.id,
              invoiceId: null,
              voucherId: null,
              adjustmentId: null,
            }),
          ...(watch("payeeTransactionType") === "VOUCHER" &&
            data.payeeType !== "ACT" && {
              voucherId: pickedRequest?.id,
              invoiceId: null,
              billId: null,
              adjustmentId: null,
            }),
          ...(watch("payeeTransactionType") === "ADJUSTMENT" &&
            data.payeeType !== "ACT" && {
              adjustmentId: pickedRequest?.id,
              invoiceId: null,
              voucherId: null,
              billId: null,
            }),
          // paymentMethodId: paymentMethodId,
        },
      },
    })
      .then((data) => {
        setValue("id", data.data?.saveVoucher?.id);
        setValue("code", data.data?.saveVoucher?.code);
        // setVoucherData(data.data["glApproveVoucher"]);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        pushUrl(props, `/admin/vouchers/${data?.data?.saveVoucher?.id}/edit`);
        // const url = history.createHref({
        //   pathname: `/admin/vouchers/${data?.data?.saveVoucher?.id}`,
        // });
        // setPathURL(url);
        // windowReplaceUrl(url);
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };

  const approveVoucher = () => {
    approveVoucherMutation({
      variables: {
        id: parseInt(watch("id")),
        paymentMethodId: paymentMethodId,
      },
    })
      .then((data) => {
        closeConfirmationDialog();
        enqueueSnackbar(t("successfullyApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        console.log(props.match.path);

        pushUrl(props, `/admin/vouchers/${watch("id")}`);
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        closeConfirmationDialog();

        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const glApproveVoucher = () => {
    glApproveVoucherMutation({
      variables: {
        id: parseInt(watch("id")),
        paymentMethodId: paymentMethodId,
      },
    })
      .then((data) => {
        closeConfirmationDialog();
        enqueueSnackbar(t("successfullyGlApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        closeConfirmationDialog();

        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const parseData = (data) => {
    return data;
  };

  const voucher = voucherByIdQuery?.voucher;
  const payeeType = watch("payeeType");

  const documentType = watch("documentType");
  const dynamicAutocompleteProps = useAccountType(
    payeeType,
    autocompleteValues,
    documentType
  );
  const payeeTransaction = watch("payeeTransactionType");
  const dynamicTextFiled = useDocumentTypeType(
    payeeTransaction,
    setValue,
    voucher
  );
  function documentTypeData(type, data) {
    switch (type) {
      case "INVOICE":
        return data.invoice;
      case "BILL":
        return data.bill;
      case "VOUCHER":
        return data.voucher;
      case "ADJUSTMENT":
        return data.adjustment; // or handle as needed
      default:
        return null;
    }
  }
  const billFilters = {
    ...(dynamicAutocompleteProps.name !== "glAccountId" && {
      [dynamicAutocompleteProps.name]: watch(dynamicAutocompleteProps.name),
    }),

    ...(watch("payeeTransactionType") === "VOUCHER"
      ? { glApproved: true }
      : { glApproved: true }),
    ...(watch("payeeTransactionType") === "VOUCHER" && {
      ...(listType.toUpperCase() === "RECEIPT"
        ? { type: "PAYMENT" }
        : { type: "RECEIPT" }),
    }),
    forVoucher: {
      type: listType?.toUpperCase(),
      voucherId: parseInt(voucherId),
    },
  };
  useQuery(
    gql`
      ${CURRENCY_ID.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !parseInt(watch("currencyId")),
      variables: {
        id: parseInt(watch("currencyId")),
        input: {},
      },
      onCompleted: (data) => {
        const exchangeRate = data.currency.exchangeRate;
        watch("exchangeRate") === "" &&
          setValue("exchangeRate", exchangeRate?.rate);
        setExchangeRate(exchangeRate.updatable);
      },
    }
  );
  useEffect(() => {
    if (pickedRequest?.currency) {
      setValue("exchangeRate", pickedRequest?.exchangeRate);
      // setValue("currencyId", pickedRequest?.currency?.name);
      setAutocompleteValues((prev) => ({
        ...prev,
        currency: pickedRequest?.currency,
      }));
    }
    if (pickedRequest?.totalAmount || pickedRequest?.amount) {
      setValue("amount", pickedRequest?.totalAmount ?? pickedRequest?.amount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickedRequest]);

  const embtyFils = (type) => {
    const params = [
      "bankId",
      "chequeNumber",
      "nameOnCheque",
      "nameOnCard",
      "cardNumber",
      "safeId",
      "bankAccountId",
    ];
    params.forEach((i) => {
      watch(params) && setValue(i, "");
    });
  };

  const payeeTransactionType = (type) => {
    return (
      <Grid xs={12} sm={4} alignItems="flex-start">
        <Stack direction={"row"}>
          <ControlMUItextField
            control={control}
            errors={errors}
            name={dynamicTextFiled?.name}
            readOnly={true}
            label={t("documentCode")}
            disabled={Boolean(watch("payeeType") === "ACT")}
          />

          <IconButton
            onClick={() => openPickedRequestDialog()}
            size="large"
            disabled={
              !watch("payeeTransactionType") ||
              !watch(dynamicAutocompleteProps.name) ||
              Boolean(watch("payeeType") === "ACT")
            }
          >
            <AddCircleOutline />
          </IconButton>
        </Stack>
      </Grid>
    );
  };

  const paymentMethodField = (type) => {
    if (["CHQ", "CSH"].includes(type?.type?.code)) {
      return (
        <Grid sm={4} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"safeId"}
            disabled={watch("branchId") && !watch("branchId")}
            label={t("safe")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => parseData(data)}
            query={LIST_SAFES_DROPDOWN.query}
            skip={watch("branchId") && !watch("branchId")}
            variables={{
              input: {
                ...(watch("branchId") && {
                  branchId: watch("branchId"),
                }),
              },
            }}
            defaultValue={autocompleteValues.safe}
          />
        </Grid>
      );
    } else {
      return (
        <Grid sm={4} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            disabled={!Boolean(type?.type)}
            name={"bankAccountId"}
            label={t("bankAccount")}
            skip={loading}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => parseData(data)}
            query={LIST_BANK_ACCOUNT_DROPDOWN.query}
            variables={{
              input: {
                ...(type?.type?.code === "BDW" && {
                  type: "CASH",
                }),
                ...(type?.type?.code === "CRD" && {
                  type: "CARD",
                }),
              },
            }}
            defaultValue={autocompleteValues.bankAccount}
          />
        </Grid>
      );
    }
  };
  const acountDetails = (type) => {
    if (["CHQ"].includes(type?.type?.code)) {
      return (
        <>
          <Grid sm={3} xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"bankId"}
              label={t("bank")}
              parseData={(data) => parseData(data)}
              query={LIST_BANKS_DROPDOWN.query}
              defaultValue={autocompleteValues.bank}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid sm={3} xs={12}>
            <ControlMUItextField
              name="chequeNumber"
              control={control}
              label={t("chequeNumber")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid sm={3} xs={12}>
            <ControlMUItextField
              name="nameOnCheque"
              control={control}
              label={t("nameOnCheque")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid sm={3} xs={12}>
            <MUIDate
              name="chequeDate"
              label={t("chequeDate")}
              rules={{ required: t("fieldIsRequired") }}
              control={control}
              value={date.chequeDate}
              defaultValue={date.chequeDate}
              onChange={(date) =>
                setDate((prev) => ({ ...prev, chequeDate: date }))
              }
              onError={(resone, value) => {
                setError("chequeDate", { message: resone });
              }}
            />
          </Grid>
        </>
      );
    } else if (["CRD"].includes(type?.type?.code)) {
      return (
        <>
          <Grid sm={4} xs={12}>
            <ControlMUItextField
              name="nameOnCard"
              control={control}
              label={t("nameOnCard")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid sm={4} xs={12}>
            <ControlMUItextField
              name="cardNumber"
              control={control}
              label={t("cardNumber")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid sm={4} xs={12}>
            <MUIDate
              name="expiryDate"
              label={t("expiryDate")}
              rules={{ required: t("fieldIsRequired") }}
              control={control}
              value={date.expiryDate}
              defaultValue={date.expiryDate}
              onChange={(date) =>
                setDate((prev) => ({ ...prev, expiryDate: date }))
              }
              onError={(resone, value) => {
                setError("expiryDate", { message: resone });
              }}
            />
          </Grid>
        </>
      );
    }
  };

  let formBody = null;

  const clearPayeeFeild = () => {
    setValue("customerId", "");
    setValue("vendorId", "");
    setValue("glAccountId", "");
    setValue("agencyId", "");
    setValue("employeeId", "");
    setValue("customAgentId", "");
    setValue("carrierId", "");
  };

  /////////////////////// Customer Shipments Table /////////////////////////////

  const formCondition = voucherId ? !voucherByIdLoad : true;
  formBody = (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(saveVoucher)}
      spacing={2}
      sx={{ padding: "24px", width: "100%", margin: "0" }}
    >
      {formCondition ? (
        <Paper container component={Grid} sx={{ padding: 1.25, flexGrow: 1 }}>
          <Grid
            spacing={2}
            container
            sx={{ position: "relative", flexGrow: 1 }}
          >
            {" "}
            {disabled.formEdit && (
              <Box
                className={classesForm.overlay}
                sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
              ></Box>
            )}
            <Grid sm={3} xs={12}>
              <ControlMUItextField
                name="code"
                control={control}
                label={t("recordCode")}
                rules={{ required: watch("id") ? t("fieldIsRequired") : false }}
              />
            </Grid>
            <Grid sm={3} xs={12}>
              <MUIDate
                name="voucherDate"
                label={t("voucherDate")}
                rules={{ required: t("fieldIsRequired") }}
                control={control}
                value={date.voucherDate}
                defaultValue={date.voucherDate}
                onChange={(date) =>
                  setDate((prev) => ({ ...prev, voucherDate: date }))
                }
                onError={(resone, value) => {
                  setError("voucherDate", { message: resone });
                }}
              />
            </Grid>
            <Grid sm={3} xs={12}>
              <ListBranches
                control={control}
                errors={errors}
                name={"branchId"}
                rules={{ required: t("fieldIsRequired") }}
                defaultValue={autocompleteValues.branch}
                onChangeValue={(e) => {
                  setValue("safeId", "");
                }}
              />
            </Grid>
            <Grid sm={3} xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"paymentMethodId"}
                label={t("paymentMethod")}
                selectFirst={true}
                parseData={(data) => parseData(data)}
                rules={{ required: t("fieldIsRequired") }}
                query={LIST_PAYMENT_METHODS_DROPDOWN.query}
                defaultValue={autocompleteValues.paymentMethod}
                onChangeValue={(e) => {
                  embtyFils();
                }}
              />
            </Grid>
            {paymentMethodField(paymentMethodType?.paymentMethod)}
            <Grid xs={12} sm={4} alignItems="flex-start">
              <CustomAutocomplete
                valueKey="code"
                control={control}
                errors={errors}
                name={"payeeType"}
                label={t("payeeType")}
                parseData={(data) => parseData(data)}
                rules={{ required: t("fieldIsRequired") }}
                variables={{ input: { code: "PAYEE_TYPE" } }}
                query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                defaultValue={autocompleteValues.payeeType}
                onChangeValue={(e) => {
                  clearPayeeFeild();
                }}
              />
            </Grid>
            <Grid xs={12} sm={4} alignItems="flex-start">
              <CustomAutocomplete
                control={control}
                errors={errors}
                disabled={!watch("payeeType")}
                name={dynamicAutocompleteProps.name} // Dynamically set name
                label={dynamicAutocompleteProps.label}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={dynamicAutocompleteProps.query}
                defaultValue={dynamicAutocompleteProps.defaultValue}
                variables={{
                  input: dynamicAutocompleteProps?.input,
                }}
                onChangeValue={(e) => {
                  setPickedRequest(null);
                  setValue(dynamicTextFiled?.name, "");
                }}
              />
            </Grid>
            {paymentMethodType &&
              acountDetails(paymentMethodType?.paymentMethod)}
            <Grid xs={12} sm={4} alignItems="flex-start">
              <CustomAutocomplete
                valueKey="code"
                control={control}
                errors={errors}
                name={"payeeTransactionType"}
                label={t("documentType")}
                disabled={Boolean(watch("payeeType") === "ACT")}
                parseData={(data) => parseData(data)}
                rules={{
                  required:
                    watch("payeeType") !== "ACT" && t("fieldIsRequired"),
                }}
                variables={{ input: { code: "PAYEE_TRX_TYPES" } }}
                query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                defaultValue={autocompleteValues.payeeTransactionType}
                onChangeValue={(e) => {
                  setPickedRequest(null);
                  setValue(dynamicTextFiled?.name, "");
                }}
              />
            </Grid>
            {payeeTransactionType(watch("payeeTransactionType"))}
            <Grid sm={4} xs={12}>
              <ControlMUItextField
                name="referenceNumber"
                control={control}
                label={t("referenceNumber")}
              />
            </Grid>
            <Grid xs={12} sm={4} aligns="flex-start">
              <CustomAutocomplete
                control={control}
                errors={errors}
                disabled={watch("payeeType") !== "ACT"}
                name={"currencyId"}
                label={t("currency")}
                parseData={(data) => parseData(data)}
                query={LIST_CURRENCIES_DROPDOWN.query}
                defaultValue={autocompleteValues.currency}
                rules={{ required: t("fieldIsRequired") }}
                onChangeValue={(e) => {
                  setValue("exchangeRate", "");
                  // setExchangeRate(false);
                }}
              />
            </Grid>
            <Grid xs={12} sm={4} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                type="number"
                name={"exchangeRate"}
                label={t("exchangeRate")}
                rules={{ required: t("fieldIsRequired") }}
                readOnly={!exchangeRate}
              />
            </Grid>
            <Grid xs={12} sm={4} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                type="number"
                name={"amount"}
                label={t("amount")}
                readOnly={watch("payeeType") !== "ACT" && true}
                rules={{ required: t("fieldIsRequired") }}
              />
            </Grid>
            <Grid sm={12} xs={12}>
              <ControlMUItextField
                name="notes"
                control={control}
                label={t("notes")}
                rows={2}
              />
            </Grid>
            {voucherData?.approved && (
              <Box className={classesForm.overlay}></Box>
            )}
          </Grid>

          <Grid
            sm={12}
            xs={12}
            container
            justifyContent="flex-end"
            // className={classes.spacing}
          >
            {!disabled.formEdit && (
              <CustomButton
                customcolor={theme.palette.success.main}
                type="submit"
                className={classesForm.button}
                disabled={
                  saveVoucherLoad || disabled.formEdit || voucherData?.approvedd
                }
                variant="contained"
                loading={saveVoucherLoad}
                startIcon={!saveVoucherLoad && <Done />}
              >
                {!saveVoucherLoad && t("save")}
              </CustomButton>
            )}
            {disabled.formEdit && (
              <Button
                disabled={
                  saveVoucherLoad ||
                  voucherData?.approved ||
                  !user.hasPermission("cash.voucher.approve")
                }
                className={classesForm.button}
                variant="contained"
                onClick={openApproveDialog}
                name="approved"
                startIcon={<DoneAll />}
              >
                {t("approve")}
              </Button>
            )}
            {voucherData?.approved && !voucherData?.glApproved && (
              <Button
                disabled={voucherData?.glApproved}
                className={classesForm.glApprovedButton}
                variant="contained"
                onClick={openGlApproveDialog}
                name="glApproved"
                startIcon={<AttachMoney />}
              >
                {t("glApprove")}
              </Button>
            )}
          </Grid>
        </Paper>
      ) : (
        <FullScreenLoading />
      )}
    </Grid>
  );

  return voucherByIdLoad ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !voucherByIdQuery && voucherId ? (
    <NotFound />
  ) : (
    // <Can permission={pagePermission}>
    <RootStyleForm>
      <TitleAppBar path={pathURL} type={listType} />
      {props.children}
      {voucherId && disabled.formEdit ? (
        <Grid sm={12} xs={12}>
          <Alert
            severity="warning"
            action={
              !voucherData?.approved && (
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setDisabled((prev) => ({
                      ...prev,
                      formEdit: false,
                    }));
                  }}
                >
                  {t("update")}
                </Button>
              )
            }
          >
            {voucherData?.approved
              ? t("updateRecordsForbidden")
              : t("updateRecord")}
          </Alert>
        </Grid>
      ) : (
        ""
      )}
      {formBody}
      {dialogDetails.state && (
        <CustomDialog
          styleContent={
            dialogDetails.title == null && { overflow: "hidden", padding: 0 }
          }
          title={dialogDetails.title}
          fullWidth
          maxWidth={dialogDetails.title == null ? "md" : "xs"}
          onClose={closeConfirmationDialog}
          content={dialogDetails.content}
          open={dialogDetails.state}
          actions={
            dialogDetails.function !== null && (
              <>
                <Button color="primary" onClick={closeConfirmationDialog}>
                  {dialogDetails.confirmAction ? t("cancel") : t("close")}
                </Button>
                {dialogDetails.confirmAction && (
                  <Button
                    color="primary"
                    disabled={approveLoad || glApproveLoad}
                    onClick={dialogDetails.function}
                  >
                    {approveLoad || glApproveLoad ? (
                      <ButtonLoading />
                    ) : (
                      t("confirm")
                    )}
                  </Button>
                )}
              </>
            )
          }
        >
          {dialogDetails.function === null && ""}
        </CustomDialog>
      )}
    </RootStyleForm>
  );
};

export default withRouter(VoucherForm);
