import React, { useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Toolbar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { Add, Delete, Edit } from "@mui/icons-material";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { gql, useMutation } from "@apollo/client";
import { DELETE_SHIPMENT_CONTAINER } from "./Graphql";
import CellLink from "../HOC/CustomComponents/CellLink";
import { useContainersContext } from "./ShipmentContainerContext";
import { StyledTableRow } from "../HOC/CustomComponents/PackagesTableView/Tabs";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";

export default function ShipmentContainerTables(props) {
  const {
    shipmentId,
    containers,
    setContainers,
    setUpdateContainers,
    setDialogState,
    shipmentDate,
    setDisabled,
    classes,
  } = useContainersContext();

  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  //////////// package Functions ////////////
  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const openDialog = (subzone) => {
    setDialogState((prev) => ({
      ...prev,
      containers: true,
    }));
    subzone && setUpdateContainers(subzone);
  };

  const [deleteShipmentContainer, { loading: deleteLoad }] = useMutation(
    gql`
      ${DELETE_SHIPMENT_CONTAINER.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  return (
    <Grid
      container
      item
      sm={12}
      md={12}
      className={classes.tabpanel}
      component={Paper}
    >
      {shipmentId ? (
        <Toolbar className={classes.toolbar} variant="dense">
          <Typography className={classes.title} color="inherit" variant="h6">
            {t("containers")}
          </Typography>
          <IconButton
            disabled={
              !shipmentId ||
              ["LCL", "LTL"].includes(shipmentDate?.loadingMode?.code) ||
              (shipmentDate?.shippingMode?.code === "AIR" &&
                containers?.length > 0)
            }
            onClick={() => openDialog()}
            aria-label="Add containers"
            color="primary"
            size="large"
          >
            <Add />
          </IconButton>
        </Toolbar>
      ) : null}
      <Grid container className={classes.table}>
        {!containers ? (
          <Paper>
            <FullScreenLoading minHeight={"100px"} />
          </Paper>
        ) : (
          <TableFixedHeaderWraper>
            <Table aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <FixedTableCell>{t("packageType")}</FixedTableCell>
                  <FixedTableCell>{t("containerNumber")}</FixedTableCell>
                  <FixedTableCell>{t("packagesCounts")}</FixedTableCell>
                  <FixedTableCell> </FixedTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {containers &&
                  (rowsPerPage > 0
                    ? containers.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : containers
                  )?.map((row, index) => (
                    <TableRow hover key={index}>
                      <CellLink
                        pathname={`/admin/package-types/${row?.packageType?.id}`}
                      >
                        {row?.packageType?.name}
                      </CellLink>
                      <FixedTableCell>{row?.containerNumber}</FixedTableCell>
                      <FixedTableCell>{row?.packagesCount}</FixedTableCell>

                      {shipmentId ? (
                        <>
                          <FixedTableCell>
                            <IconButton
                              size="small"
                              onClick={() => openDialog(row)}
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              size="small"
                              disabled={deleteLoad}
                              onClick={() => {
                                setContainers((prev) => {
                                  const filterd = prev.filter(
                                    (i, deleteIndex) =>
                                      deleteIndex !== page * rowsPerPage + index
                                  );
                                  return filterd;
                                });
                                page * rowsPerPage + index === 0 &&
                                  setDisabled((prev) => ({
                                    ...prev,
                                    addContainer: false,
                                  }));
                                deleteShipmentContainer({
                                  variables: {
                                    id: parseInt(row.id),
                                  },
                                });
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </FixedTableCell>
                        </>
                      ) : (
                        <>
                          <FixedTableCell> </FixedTableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {!(containers?.length > 0) && (
              <EmptyTableMessage message={t("noResult")} />
            )}
          </TableFixedHeaderWraper>
        )}

        <MUITablePagination
          count={containers?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePage}
          onRowsPerPageChange={handleRowsPerPage}
        />
      </Grid>
    </Grid>
  );
}
