import * as gqlb from "gql-query-builder";
const fields = [
  "code",
  "id",
  "amount",
  "voucherDate",
  "approved",
  "glApproved",
  {
    branch: ["id", "name"],
  },
  {
    payeeType: ["code", "name"],
  },
  {
    vendor: ["id", "code", "name"],
  },
  {
    employee: ["id", "code", "name"],
  },
  {
    customer: ["id", "code", "name"],
  },
  {
    agent: ["id", "code", "name"],
  },
  {
    agency: ["id", "code", "name"],
  },
  {
    carrier: ["id", "code", "name"],
  },
  {
    glAccount: ["id", "code", "name"],
  },
  {
    payeeTransactionType: ["code", "name", "id"],
  },
  {
    type: ["code", "name"],
  },
  {
    invoice: ["id", "code"],
  },
  {
    bill: ["id", "code"],
  },
  {
    adjustment: ["id", "code"],
  },
  {
    voucher: ["id", "code"],
  },
  {
    currency: ["id", "code", "name"],
  },
  {
    safe: ["id", "name"],
  },

  {
    bankAccount: ["code", "name", "id"],
  },
];
export const LIST_VOUCHER_QUERY = gqlb.query({
  operation: "listVouchers",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "total",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: fields,
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListVouchersFilterInput",
      required: true,
    },
    first: {
      type: "Int",
      required: true,
      value: 15,
    },
    page: {
      type: "Int",
      value: 1,
    },
  },
});

const voucherFields = [
  "notes",
  "bankExpenses",
  "exchangeRate",
  "referenceNumber",
  "nameOnCard",
  "expiryDate",
  "cardNumber",
  "nameOnCheque",
  "chequeDate",
  "chequeNumber",
  {
    paymentMethod: ["code", "name", "id"],
  },

  {
    bank: ["code", "name", "id"],
  },
  {
    journalEntry: ["id", "code"],
  },
];

export const VOUCHER_BY_ID_QUERY = gqlb.query({
  operation: "voucher",
  fields: [...fields, ...voucherFields],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const PAYMENT_METHOD_ID = gqlb.query({
  operation: "paymentMethod",
  fields: [{ type: ["code", "id"] }],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const VOUCHER_ID_AND_PRINT = gqlb.query([
  {
    operation: "voucher",
    fields: [...fields, ...voucherFields],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
]);

export const SAVE_VOUCHER_MUTATION = gqlb.mutation({
  operation: "saveVoucher",
  fields: ["id", "code"],
  variables: {
    input: {
      type: "VoucherInput",
      required: true,
    },
  },
});

export const DELETE_VOUCHER_MUTATION = gqlb.mutation({
  operation: "deleteVoucher",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const APPROVE_VOUCHER_MUTATION = gqlb.mutation({
  operation: "approveVoucher",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DISAPPROVE_VOUCHER_MUTATION = gqlb.mutation({
  operation: "disapproveVoucher",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const GL_APPROVE_VOUCHER_MUTATION = gqlb.mutation({
  operation: "glApproveVoucher",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const CURRENCY_ID = gqlb.query({
  operation: "currency",
  fields: [{ exchangeRate: ["updatable", "rate", "id"] }],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const EXPOERT_VOUCHERS = gqlb.mutation({
  operation: "exportVouchers",
  fields: [],
  variables: {
    input: {
      type: "ExportVouchersInput",
      required: true,
    },
  },
});
