/* eslint-disable no-useless-concat */
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Paper, Icon } from "@mui/material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { BANK_BRANCH } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import BankBranchesTable from "./BankBranchesTable";

import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";

const BankBranchView = (props) => {
  const { t } = useTranslation();
  const id = props.match.params.id?.trim();
  const user = Globals.user;
  const { loading, data } = useQuery(
    gql`
      ${BANK_BRANCH.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(id) },
    }
  );
  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/bank-branches/${id}/edit`),
      permission: user.hasPermission("cash.bank_branch.update"),
    },
  ];
  const bankBranchData = data?.bankBranch;
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !bankBranchData ? (
    <NotFound />
  ) : (
    <>
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>

      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair title={t("code")} value={bankBranchData?.code} />
          <KeyValuePair title={t("name")} value={bankBranchData?.name} />
          <KeyValuePair
            title={t("active")}
            value={
              bankBranchData?.active ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair title={t("bank")} value={bankBranchData?.bank?.name} />
          <KeyValuePair
            title={t("country")}
            value={
              <SpanLink
                pathname={`/admin/countries/${bankBranchData?.country?.id}`}
              >
                {bankBranchData?.country?.name}
              </SpanLink>
            }
          />
          <KeyValuePair
            title={t("state")}
            value={bankBranchData?.state?.name}
          />
          <KeyValuePair
            title={t("phone")}
            value={<span dir="ltr">{bankBranchData?.phone}</span>}
          />
          <KeyValuePair title={t("address")} value={bankBranchData?.address} />
          <KeyValuePair
            title={t("postalCode")}
            value={bankBranchData?.postalCode}
          />
        </Paper>
        {user.hasPermission("cash.bank_account.list") && (
          <BankBranchesTable
            accounts={bankBranchData?.accounts}
            loading={loading}
          />
        )}
      </RootStyleView>
    </>
  );
};

export default BankBranchView;
