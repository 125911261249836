/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Paper,
  Toolbar,
  Icon,
  Table,
  TableRow,
  TableHead,
  TableBody,
} from "@mui/material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { COUNTRY_ID } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { Globals } from "../HOC/Classes/Globals";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";
const CountryView = (props) => {
  const countryId = props.match.params.id?.trim();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const { loading, data } = useQuery(
    gql`
      ${COUNTRY_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(countryId) },
    }
  );
  const user = Globals.user;

  const canEdit = user.hasPermission("core.country.update");

  const states = data?.country?.states ?? [];

  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/countries/${countryId}/edit`),
      permission: canEdit,
    },
  ];
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data.country ? (
    <NotFound />
  ) : (
    <>
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>
      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair title={t("code")} value={data?.country?.code} />
          <KeyValuePair title={t("name")} value={data?.country?.name} />
          <KeyValuePair
            title={t("active")}
            value={
              data?.country?.active ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
        </Paper>

        <Paper
          container
          sm={12}
          md={8}
          className={classesView.table}
          component={Grid}
        >
          <Toolbar className={classesView.toolbarTable} variant="dense">
            <Typography
              className={classesView.title}
              color="inherit"
              variant="h6"
            >
              {t("state")}
            </Typography>
          </Toolbar>
          <TableFixedHeaderWraper component={Paper}>
            {loading || states.length <= 0 ? (
              <EmptyTableMessage
                loading={loading}
                message={t("searchNoResult")}
              />
            ) : (
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell>{t("code")}</FixedTableCell>
                    <FixedTableCell>{t("name")}</FixedTableCell>
                    <FixedTableCell>{t("active")}</FixedTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {states &&
                    (rowsPerPage > 0
                      ? states.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : states
                    )?.map((row, index) => (
                      <TableRow hover key={index}>
                        <FixedTableCell>{row.code}</FixedTableCell>
                        <FixedTableCell>{row.name}</FixedTableCell>
                        <FixedTableCell>
                          {row?.active ? (
                            <Icon className={classesView.iconColor}>
                              check_circle_outline
                            </Icon>
                          ) : (
                            <Icon color="error">highlight_off</Icon>
                          )}
                        </FixedTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </TableFixedHeaderWraper>
          <MUITablePagination
            count={states.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePage}
            onRowsPerPageChange={handleRowsPerPage}
          />
        </Paper>
      </RootStyleView>
    </>
  );
};

export default CountryView;
