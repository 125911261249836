import { createContext, useContext } from "react";

const CustomersContext = createContext();

export const useCustomersContext = () => {
  const context = useContext(CustomersContext);
  if (context === "undefined") {
    throw new Error("CustomersContext was used outside of the provider");
  }
  return context;
};
export const CustomersProvider = ({ children, value }) => {
  return (
    <CustomersContext.Provider value={value}>
      {children}
    </CustomersContext.Provider>
  );
};
