import * as gqlb from "gql-query-builder";
const fields = [
  "id",
  "code",
  "name",
  "creditLimit",
  "active",
  {
    type: ["id", "name", "code"],
  },
  {
    customerCategory: ["name"],
  },
  {
    branch: ["name", "id", "code"],
  },
  {
    country: ["name", "id", "code"],
  },
  { currency: ["name", "id", "code"] },
  "commission",
];

export const SAVE_CUSTOMER = gqlb.mutation({
  operation: "saveCustomer",
  fields: ["id", "code", "active"],
  variables: {
    input: {
      type: "CustomerInput",
      required: true,
    },
  },
});
export const SAVE_CUSTOMER_PRICE_MODE = gqlb.mutation({
  operation: "saveCustomerPriceMode",
  fields: [
    "id",
    { shippingMode: ["id", "code", "name"] },
    { priceMode: ["id", "code", "name"] },
  ],
  variables: {
    input: {
      type: "CustomerPriceModeInput",
      required: true,
    },
  },
});
export const SAVE_CUSTOMER_SALES = gqlb.mutation({
  operation: "saveCustomerSales",
  fields: [
    "id",
    "fromDate",
    "toDate",
    "editable",
    { employee: ["id", "code", "name"] },
    { customer: ["id", "code", "name"] },
  ],
  variables: {
    input: {
      type: "CustomerSalesInput",
      required: true,
    },
  },
});

export const LIST_CUSTOMERS = gqlb.query({
  operation: "listCustomers",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: fields,
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
    input: {
      type: "ListCustomersFilterInput",
    },
  },
});

export const CUSTOMER_ID = gqlb.query([
  {
    operation: "customer",
    fields: [
      ...fields,
      "date",
      "address",
      "postalCode",
      "mailBoxNumber",
      "phone",
      "mobile",
      "idNumber",
      "idIssueDate",
      "taxCardNumber",
      "registerationNumber",
      "registerationDate",
      "businessName",
      "businessActivity",
      "businessField",
      "responsibleName",
      {
        image: ["path"],
      },
      {
        users: ["id", "username"],
      },
      {
        identificationType: ["id", "name", "code"],
      },
      {
        idType: ["id", "name", "code"],
      },
      {
        priceModes: [
          "id",
          "date",
          { shippingMode: ["id", "code", "name"] },
          { priceMode: ["id", "code", "name"] },
        ],
      },
      {
        sales: [
          "id",
          "fromDate",
          "toDate",
          "editable",
          { customer: ["name", "id", "code"] },
          { employee: ["name", "id", "code"] },
        ],
      },
      {
        glAccount: ["name", "id", "code"],
      },
      {
        state: ["name", "id", "code"],
      },
    ],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
]);
export const DELETE_CUSTOMER_PRICE_MODE = gqlb.mutation({
  operation: "deleteCustomerPriceMode",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DELETE_CUSTOMER_SALES = gqlb.mutation({
  operation: "deleteCustomerSales",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const FREIGHT_SETTING = gqlb.query({
  operation: "freightSettings",
  fields: [{ mainCustomerGLAccount: ["id", "code", "name"] }],
  variables: {},
});
