import React, { useState } from "react";
import { Collapse, Stack, Box, Alert, Button } from "@mui/material";
import {
  FREIGHT_SETTING,
  SHIPMENT_ID,
  SAVE_SHIPMENT,
  AGENCY_ID,
} from "./Graphql";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, gql, useQuery } from "@apollo/client";
// import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
// import FormButton from "../CustomComponents/Buttons/FormButton";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";

import { dateFormat } from "../../helpers/dateFunctions";
import getMobileData, {
  getFormatNumber,
  validNumber,
} from "../../helpers/asYouType";
import moment from "moment";
import LongMenu from "../../Layout/MenuAppBar";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import { ContainersProvider } from "./ShipmentContainerContext";
import ShipmentContainer from "./ShipmentContainer";
import ShipmentContainerTables from "./ShipmentContainerTable";
import HorizontalLinearStepper from "../HOC/MUI/Stepper/stepper";
import { StepperProvider } from "../HOC/MUI/Stepper/StepperContext";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";
import { ShipmentContext } from "./Context/ShipmentContext";
import { Step1 } from "./Steps/Step1";
import { Step3 } from "./Steps/Step3";
import { Step2 } from "./Steps/Step2";
import { CgNotes } from "react-icons/cg";
import { GoContainer, GoOrganization } from "react-icons/go";
const ShipmentForm = (props) => {
  const pathURL = props.match.path;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [shipmentId, setShipmentId] = useState(parseInt(props.match.params.id));
  const [disabled, setDisabled] = useState({
    formEdit: false,
    addContainer: false,
  });
  const [autocompleteValues, setAutocompleteValues] = useState({
    originCountry: null,
    originPort: null,
    originAgency: null,
    destinationCountry: null,
    destinationPort: null,
    destinationAgency: null,
    transactionType: null,
    branch: null,
    shippingMode: null,
    shippingDirection: null,
    loadingMode: null,
    currency: null,
    carrier: null,
    incoTerm: null,
    priceMode: null,
    packageTypeId: null,
    warehouseId: null,
    originCountryId: null,
    currencyId: null,
    warehouse: null,
    productId: null,
  });
  const [actualArrivalDate, setActualArrivalDate] = useState(null);
  const [actualDepartureDate, setActualDepartureDate] = useState(null);

  const firstCalcUpdate = React.useRef(true);
  const [dialogState, setDialogState] = useState({
    containers: false,
    details: false,
  });
  const [updateContainers, setUpdateContainers] = useState(null);

  const [containers, setContainers] = useState();
  const initFromDate = moment(new Date())
    .locale("en")
    .subtract("month")
    .add("day")
    .format("YYYY-MM-DD");
  const [dateRange, setDateRange] = useState({
    scheduledDepartureDate: initFromDate,
    scheduledArrivalDate: null,
    shipmentDate: initFromDate,
  });
  const {
    handleSubmit,
    control,
    formState,
    setValue,
    setError,
    watch,
    getValues,
  } = useForm();
  const { errors } = formState;

  const [saveShipment, { loading }] = useMutation(
    gql`
      ${SAVE_SHIPMENT.query}
    `
  );

  const { data, loading: updateShipmentLoading } = useQuery(
    gql`
      ${SHIPMENT_ID.query}
    `,
    {
      skip: !shipmentId,
      variables: { id: shipmentId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const shipmentData = data.shipment;

        setContainers(data?.shipment?.containers ?? []);
        if (shipmentData?.containers?.length > 0) {
          setDisabled((prev) => ({
            ...prev,
            addContainer: true,
          }));
        }

        const shipmentParams = [
          "code",
          "mawbNumber",
          "commissionAmount",
          "remarks",
          "consigneeName",
          "consigneeAddress",
          "consigneePostalCode",
          "shipperName",
          "shipperAddress",
          "shipperPostalCode",
          "bookingNumber",
          "plateNumber",
          "truckType",
          "ticketNumber",
          "flightNumber",
          "vesselName",
          "actualDepartureDate",
          "actualArrivalDate",
        ];
        shipmentParams.forEach((i) => {
          shipmentData[i] !== null && setValue(i, shipmentData[i]);
        });
        setDateRange((prev) => ({
          scheduledDepartureDate: shipmentData?.scheduledDepartureDate,
          scheduledArrivalDate: shipmentData?.scheduledArrivalDate,
          shipmentDate: shipmentData?.shipmentDate,
        }));
        setActualDepartureDate(shipmentData.actualDepartureDate);
        setActualArrivalDate(shipmentData.actualArrivalDate);
        setAutocompleteValues((prev) => ({
          ...prev,
          currency: shipmentData.currency,
          branch: shipmentData.branch,
          shippingMode: shipmentData.shippingMode,
          originCountry: shipmentData.originCountry,
          destinationAgency: shipmentData.destinationAgency,
          originAgency: shipmentData.originAgency,
          originPort: shipmentData.originPort,
          destinationCountry: shipmentData.destinationCountry,
          destinationPort: shipmentData.destinationPort,
          transactionType: shipmentData.transactionType,
          shippingDirection: shipmentData?.shippingDirection,
          loadingMode: shipmentData.loadingMode,
          carrier: shipmentData?.carrier,
          incoTerm: shipmentData?.incoTerm,
        }));

        setDisabled((prev) => ({
          ...prev,
          formEdit: true,
        }));
      },
    }
  );
  const { data: originAgencyData } = useQuery(
    gql`
      ${AGENCY_ID.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !watch("originAgencyId"),
      variables: {
        id: parseInt(watch("originAgencyId")),
        input: {},
      },
      onCompleted: (data) => {
        const originAgency = data.agency;
        const numbersObj = [
          {
            name: "shipperMobile",
            codeName: "shipperMobileCode",
            value: getMobileData(originAgency?.mobile),
          },
        ];
        numbersObj.forEach((i) => {
          if (i.value !== null) {
            setValue(i.codeName, i.value.country.toLowerCase());
            setValue(i.name, i.value.nationalNumber);
          }
        });
        originAgency.address &&
          setValue("shipperAddress", originAgency.address);
        setValue("shipperName", originAgency.name);

        originAgency.postalCode &&
          setValue("shipperPostalCode", originAgency.postalCode);
        originAgency.state &&
          setValue("shipperStateId", originAgency.state?.name);
      },
    }
  );

  const { data: destinationAgencyData } = useQuery(
    gql`
      ${AGENCY_ID.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !watch("destinationAgencyId"),
      variables: {
        id: parseInt(watch("destinationAgencyId")),
        input: {},
      },
      onCompleted: (data) => {
        const destinationAgency = data.agency;
        const numbersObj = [
          {
            name: "consigneeMobile",
            codeName: "consigneeMobileCode",
            value: getMobileData(destinationAgency?.mobile),
          },
        ];
        numbersObj.forEach((i) => {
          if (i.value !== null) {
            setValue(i.codeName, i.value.country.toLowerCase());
            setValue(i.name, i.value.nationalNumber);
          }
        });
        destinationAgency.address &&
          setValue("consigneeAddress", destinationAgency.address);
        setValue("consigneeName", destinationAgency.name);

        destinationAgency.postalCode &&
          setValue("consigneePostalCode", destinationAgency.postalCode);
        destinationAgency.state &&
          setValue("consigneeStateId", destinationAgency.state?.name);
      },
    }
  );

  const { data: setting } = useQuery(
    gql`
      ${FREIGHT_SETTING.query}
    `,

    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {},
    }
  );

  const closeDialog = () => {
    setDialogState((prev) => ({
      ...prev,
      containers: false,
    }));
    setUpdateContainers(null);
  };

  const onSubmit = (data) => {
    const shipperMobile = validNumber(
      data.shipperMobile,
      data.shipperMobileCode
    );
    const consigneeMobile = validNumber(
      data.consigneeMobile,
      data.consigneeMobileCode
    );
    let names = [
      {
        name: "shipperMobile",
        validate: shipperMobile.valid,
        message: shipperMobile.message,
      },
      {
        name: "consigneeMobile",
        validate: consigneeMobile.valid,
        message: consigneeMobile.message,
      },
    ];
    if (!shipperMobile.valid || !consigneeMobile.valid) {
      names.map(
        (e) =>
          !e.validate &&
          setError(e.name, { type: "custom", message: t(e.message) })
      );
      return;
    }
    data.consigneeMobile = getFormatNumber(
      data.consigneeMobile,
      data.consigneeMobileCode
    );
    data.shipperMobile = getFormatNumber(
      data.shipperMobile,
      data.shipperMobileCode
    );

    for (const key in data) {
      if (
        (data[key] === "" && key === "code") ||
        key === "shipperMobileCode" ||
        key === "consigneeMobileCode"
      ) {
        delete data[key];
      }
      if (data[key] === "") {
        data[key] = null;
        continue;
      }
      if (["creditLimit"].includes(key)) {
        data[key] = parseFloat(data[key]);
      }
    }
    saveShipment({
      variables: {
        input: {
          ...(shipmentId && {
            id: shipmentId,
          }),
          ...(data.code && {
            code: data.code,
          }),
          branchId: data?.branchId,
          shippingDirection: data?.shippingDirection,
          shippingMode: data?.shippingMode,
          ...(data?.shippingMode !== "AIR" && {
            loadingMode: data?.loadingMode,
          }),
          ...(data?.shippingMode === "AIR" && {
            loadingMode: null,
          }),
          transactionTypeId: data?.transactionTypeId,
          originCountryId: data?.originCountryId,
          destinationAgencyId: data?.destinationAgencyId,
          originAgencyId: data?.originAgencyId,
          originPortId: data?.originPortId,
          destinationCountryId: data?.destinationCountryId,
          destinationPortId: data?.destinationPortId,
          shipperName: data?.shipperName,
          shipperAddress: data?.shipperAddress,
          shipperPostalCode: data?.shipperPostalCode,
          shipperStateId: originAgencyData?.agency?.state?.id,
          shipperMobile: data?.shipperMobile,
          consigneeName: data?.consigneeName,
          consigneeMobile: data?.consigneeMobile,
          consigneeAddress: data?.consigneeAddress,
          consigneePostalCode: data?.consigneePostalCode,
          consigneeStateId: destinationAgencyData?.agency?.state?.id,
          mawbNumber: data?.mawbNumber,
          bookingNumber: data?.bookingNumber,
          remarks: data?.remarks,
          currencyId: data?.currencyId,
          carrierId: data?.carrierId,
          incoTermId: data?.incoTermId,
          scheduledArrivalDate: dateFormat(dateRange.scheduledArrivalDate),
          scheduledDepartureDate: dateFormat(dateRange.scheduledDepartureDate),
          ...(dateRange.shipmentDate && {
            shipmentDate: dateFormat(dateRange.shipmentDate),
          }),
          plateNumber: data?.plateNumber,
          truckType: data?.truckType,
          ticketNumber: data?.ticketNumber,
          flightNumber: data?.flightNumber,
          vesselName: data?.vesselName,
          ...(data.actualArrivalDate && {
            actualArrivalDate: dateFormat(data.actualArrivalDate),
          }),
          ...(data.actualDepartureDate && {
            actualDepartureDate: dateFormat(data.actualDepartureDate),
          }),
        },
      },
    })
      .then((data) => {
        setShipmentId(data?.data?.saveShipment?.id);
        ["LCL", "LTL"].includes(watch("loadingMode"))
          ? pushUrl(props, `/admin/shipments/${data?.data?.saveShipment?.id}`)
          : pushUrl(
              props,
              `/admin/shipments/${data?.data?.saveShipment?.id}/edit`
            );

        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      });
  };

  const onChangeAgency = (data) => {
    setValue("shipperName", "");
    setValue("shipperAddress", " ");
    setValue("shipperPostalCode", "");
    setValue("shipperStateId", " ");
    setValue("shipperMobile", "");
    setValue("shipperMobileCode", "+20");
  };
  const onChangeCustomer = (data) => {
    setValue("consigneeName", "");
    setValue("consigneeAddress", " ");
    setValue("consigneePostalCode", "");
    setValue("consigneeStateId", " ");
    setValue("consigneeMobile", "");
    setValue("consigneeMobileCode", "+20");
    setValue("commissionAmount", "");
  };

  const parseLoadingMode = (data) => {
    const filteredData =
      watch("shippingMode") === "OCEAN"
        ? data?.filter((item) => item.code === "FCL" || item.code === "LCL")
        : watch("shippingMode") === "LAND"
        ? data?.filter((item) => item.code === "LTL" || item.code === "FTL")
        : data;
    return filteredData;
  };

  const onChangeShippingMode = (data) => {
    setValue("loadingMode", "");
    setValue("shippingDirection", "");
    setValue("transactionTypeId", "");
    setValue("originPortId", "");
    setValue("destinationPortId", "");
    setValue("incoTermId", "");
    firstCalcUpdate.current = false;
    setValue("vesselName", "");
    setValue("flightNumber", "");
    setValue("ticketNumber", "");
    setValue("truckType", "");
    setValue("plateNumber", "");
  };

  const value = {
    shipmentId,
    closeDialog,
    containers,
    setContainers,
    updateContainers,
    setUpdateContainers,
    shipmentDate: data?.shipment,
    setDisabled,
    setDialogState,
    classes: classesForm,
  };

  const tapsArray = [
    {
      tabHead: t("shippingData"),
      panel: <Step1 />,
      panelFields: [
        "code",
        "shipmentDate",
        "scheduledDepartureDate",
        "scheduledArrivalDate",
        "actualArrivalDate",
        "actualDepartureDate",
        "branchId",
        "shippingDirection",
        "shippingMode",
        "loadingMode",
        "transactionTypeId",
        "remarks",
      ].some((field) => errors.hasOwnProperty(field)),
      icon: <GoContainer />,
    },
    {
      tabHead: t("agenciesDetails"),
      panel: <Step2 />,
      panelFields: [
        "originAgencyId",
        "shipperName",
        "shipperAddress",
        "shipperPostalCode",
        "shipperStateId",
        "shipperMobile",
        "destinationAgencyId",
        "consigneeName",
        "consigneeAddress",
        "consigneePostalCode",
        "consigneeStateId",
        "consigneeMobile",
        "destinationPortId",
        "destinationCountryId",
        "originCountryId",
        "originPortId",
      ].some((field) => errors.hasOwnProperty(field)),
      icon: <GoOrganization />,
    },
    {
      tabHead: t("others"),
      panel: <Step3 />,
      panelFields: [
        "incoTermId",
        "carrierId",
        "mawbNumber",
        "bookingNumber",
        "currencyId",
        "vesselName",
        "flightNumber",
        "ticketNumber",
        "truckType",
        "plateNumber",
      ].some((field) => errors.hasOwnProperty(field)),
      icon: <CgNotes />,
    },
  ];

  const stepperValue = {
    tapsArray,
    loading,
  };
  const shipmentValue = {
    control,
    errors,
    t,
    disabled,
    dateRange,
    setDateRange,
    setting,
    shipmentId,
    firstCalcUpdate,
    setValue,
    autocompleteValues,
    watch,
    loading,
    getValues,
    onChangeCustomer,
    dateFormat,
    parseLoadingMode,
    onChangeShippingMode,
    actualArrivalDate,
    setActualDepartureDate,
    setActualArrivalDate,
    actualDepartureDate,
    data,
    onChangeAgency,
  };
  const body = (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Stack sx={{ position: "relative" }}>
        {disabled.formEdit && (
          <Box
            className={classesForm.overlay}
            sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
          ></Box>
        )}

        <StepperProvider value={stepperValue}>
          <HorizontalLinearStepper />
        </StepperProvider>
      </Stack>
    </form>
  );
  let DOM;

  if (shipmentId) {
    DOM = data ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return updateShipmentLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data && shipmentId ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={pathURL}>
        <LongMenu />
      </TitleAppBar>
      <ContainersProvider value={value}>
        {dialogState.containers && (
          <CustomDialog
            fullWidth
            maxWidth="xs"
            content={null}
            title={t("container")}
            open={dialogState.containers}
            onClose={() => {
              closeDialog();
            }}
          >
            {<ShipmentContainer />}
          </CustomDialog>
        )}
        {shipmentId && disabled.formEdit ? (
          <Grid sm={12} xs={12}>
            <Alert
              severity="warning"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setDisabled((prev) => ({
                      ...prev,
                      formEdit: false,
                    }));
                  }}
                >
                  {t("update")}
                </Button>
              }
            >
              {t("updateRecord")}
            </Alert>
          </Grid>
        ) : (
          ""
        )}
        <Stack spacing={2} p={2} sx={{ position: "relative" }}>
          <ShipmentContext value={shipmentValue}>{DOM}</ShipmentContext>
        </Stack>
        {shipmentId &&
        !["LCL", "LTL"].includes(data?.shipment?.loadingMode?.code) ? (
          <Grid
            container
            spacing={2}
            mx={2}
            my={0}
            className={classesForm.mainForm}
            sx={{ position: "relative" }}
          >
            {(!shipmentId || !disabled?.formEdit) && (
              <Box className={classesForm.overlay}></Box>
            )}
            <ShipmentContainerTables />
          </Grid>
        ) : (
          ""
        )}
      </ContainersProvider>
    </RootStyleForm>
  );
};

export default ShipmentForm;
