import * as gqlb from "gql-query-builder";

export const LIST_WARE_HOUSES = gqlb.query({
  operation: "listWarehouses",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["count", "currentPage", "lastPage", "perPage", "total"],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "name",
        "date",
        "active",
        {
          branch: ["id", "name"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListWarehousesFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const WARE_HOUSE_ID = gqlb.query({
  operation: "warehouse",
  fields: [
    "id",
    "code",
    "name",
    "date",
    "active",
    {
      branch: ["id", "name"],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_WARE_HOUSE = gqlb.mutation({
  operation: "saveWarehouse",
  fields: ["id"],
  variables: {
    input: {
      type: "WarehouseInput",
      required: true,
    },
  },
});
export const DELETE_WARE_HOUSE = gqlb.mutation({
  operation: "deleteWarehouse",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
