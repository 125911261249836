import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Toolbar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { AddCircleOutline, Delete, Edit } from "@mui/icons-material";
import clsx from "clsx";
// import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { gql, useMutation } from "@apollo/client";
import {
  DELETE_PACKAGE_TYPE,
  DELETE_PRICE_LIST_ROUTE,
  DELETE_WEIGHT_ROUTE,
} from "./Graphql";
import CustomTab from "../HOC/FunctionComponents/CustomTab";
import CellLink from "../HOC/CustomComponents/CellLink";
import { usePriceListContext } from "./PriceListContext";

const PREFIX = "PriceListTables";

const classes = {
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  focus: `${PREFIX}-focus`,
  tableRow: `${PREFIX}-tableRow`,
  packageType: `${PREFIX}-packageType`,
  addIcon: `${PREFIX}-addIcon`,
  tableTitle: `${PREFIX}-tableTitle`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.table}`]: {
    display: "grid",
  },
  [`& .${classes.tableTitle}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.tableContainer}`]: {
    minHeight: "250px",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.toolbar}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.focus}`]: {
    backgroundColor: theme.palette.action.hover,
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      cursor: "pointer",
    },
  },

  [`& .${classes.packageType}`]: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },
  [`& .${classes.addIcon}`]: {
    color: theme.palette.success.main,
  },
}));

export default function PriceListTable(props) {
  const {
    RouteList = [],
    addRout,
    addPackage,
    setRoutesList,
    priceListId,
    addWeight,
    setDisabled,
  } = usePriceListContext();
  const { t } = useTranslation();

  //////////// Pickup Functions ////////////

  const [routePage, setRoutePage] = useState(0);
  const [routeRowsPerPage] = useState(20);
  const [routeIndex, setRouteIndex] = useState(0);

  const handleChangePage = (event, newPage) => {
    setRoutePage(newPage);
  };

  //////////// package Functions ////////////

  const [packagePage, setPackagePage] = useState(0);
  const [packageRowsPerPage, setPackageRowsPerPage] = useState(20);

  const handlePackagePage = (event, newPage) => {
    setPackagePage(newPage);
  };

  const handleRouteRowsPerPage = (event) => {
    setPackageRowsPerPage(parseInt(event.target.value));
    setPackagePage(0);
  };
  const packageType = RouteList?.[routeIndex]?.packageTypes;

  //////////// Weight Functions ////////////
  const [weightPage, setWeightPage] = useState(0);
  const [weightRowsPerPage, setWeighRowsPerPage] = useState(20);

  const handleWeightPage = (event, newPage) => {
    setWeightPage(newPage);
  };

  const handleWeightRowsPerPage = (event) => {
    setWeighRowsPerPage(parseInt(event.target.value));
    setWeightPage(0);
  };
  const weightRoute = RouteList?.[routeIndex]?.weights;
  // row.services.map(ele => ' ' + ele.name + ' , ')

  const [deletePriceListPickupMutation, { loading: deleteLoad }] = useMutation(
    gql`
      ${DELETE_PRICE_LIST_ROUTE.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );
  const [deletePackageTypeMutation, { loading: deletePackage }] = useMutation(
    gql`
      ${DELETE_PACKAGE_TYPE.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );
  const [deleteWeightTypeMutation, { loading: deleteWeight }] = useMutation(
    gql`
      ${DELETE_WEIGHT_ROUTE.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );
  const PackageTypeTable = (
    <Grid
      container
      item
      sm={12}
      md={12}
      className={classes.packageType}
      component={Paper}
    >
      {addPackage ? (
        <Toolbar variant="dense" className={classes.toolbar}>
          <Typography
            className={classes.title}
            variant="h6"
            color={"text.primary"}
          ></Typography>

          <IconButton
            aria-label="Add"
            disabled={
              Boolean(RouteList.length === 0) ||
              Boolean(!RouteList?.[routeIndex])
            }
            onClick={() => addPackage(routeIndex)}
            size="large"
            className={classes.addIcon}
          >
            <AddCircleOutline />
          </IconButton>
        </Toolbar>
      ) : null}
      <Grid container className={classes.table}>
        <TableFixedHeaderWraper className={classes.tableContainer}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <FixedTableCell>{t("packageType")}</FixedTableCell>
                <FixedTableCell>{t("price")}</FixedTableCell>
                {addPackage ? <FixedTableCell></FixedTableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {packageType &&
                (packageRowsPerPage > 0
                  ? packageType.slice(
                      packagePage * packageRowsPerPage,
                      packagePage * packageRowsPerPage + packageRowsPerPage
                    )
                  : packageType
                )?.map((row, index) => (
                  <TableRow hover className={classes.tableRow} key={index}>
                    <CellLink
                      pathname={`/admin/package-types/${row.packageType?.id}`}
                    >
                      {row?.packageType?.name}
                    </CellLink>
                    {/* <FixedTableCell>{row.packageType?.name}</FixedTableCell> */}
                    <FixedTableCell>{row.price}</FixedTableCell>

                    {addPackage ? (
                      <FixedTableCell>
                        <IconButton
                          size="small"
                          onClick={() =>
                            addPackage(
                              routeIndex,
                              packagePage * packageRowsPerPage + index
                            )
                          }
                        >
                          <Edit />
                        </IconButton>

                        <IconButton
                          size="small"
                          disabled={deletePackage}
                          onClick={() => {
                            setRoutesList((prev) => {
                              const filterd = prev[routeIndex][
                                "packageTypes"
                              ].filter(
                                (i, deleteIndex) =>
                                  deleteIndex !==
                                  packagePage * packageRowsPerPage + index
                              );
                              const updated = [...prev];

                              updated[routeIndex]["packageTypes"] = filterd;
                              Number.isInteger(
                                prev[routeIndex]["packageTypes"].length /
                                  packageRowsPerPage
                              ) && setPackagePage(packagePage);
                              return updated;
                            });
                            deletePackageTypeMutation({
                              variables: {
                                id: parseInt(row.id),
                              },
                            });
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </FixedTableCell>
                    ) : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableFixedHeaderWraper>
        <MUITablePagination
          count={packageType?.length ?? 0}
          rowsPerPage={packageRowsPerPage}
          page={packagePage}
          onPageChange={handlePackagePage}
          onRowsPerPageChange={handleRouteRowsPerPage}
        />
      </Grid>
    </Grid>
  );

  const weightTable = (
    <Grid
      container
      item
      sm={12}
      md={12}
      className={classes.packageType}
      component={Paper}
    >
      {addWeight ? (
        <Toolbar variant="dense" className={classes.toolbar}>
          <Typography
            className={classes.title}
            variant="h6"
            color={"text.primary"}
          ></Typography>

          <IconButton
            aria-label="Add"
            disabled={
              Boolean(RouteList.length === 0) ||
              Boolean(!RouteList?.[routeIndex])
            }
            onClick={() => addWeight(routeIndex)}
            size="large"
            className={classes.addIcon}
          >
            <AddCircleOutline />
          </IconButton>
        </Toolbar>
      ) : null}
      <Grid container className={classes.table}>
        <TableFixedHeaderWraper className={classes.tableContainer}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <FixedTableCell>{t("fromWeight")}</FixedTableCell>
                <FixedTableCell>{t("toWeight")}</FixedTableCell>
                <FixedTableCell>{t("price")}</FixedTableCell>
                {addPackage ? <FixedTableCell></FixedTableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {weightRoute &&
                (weightRowsPerPage > 0
                  ? weightRoute.slice(
                      weightPage * weightRowsPerPage,
                      weightPage * weightRowsPerPage + weightRowsPerPage
                    )
                  : weightRoute
                )?.map((row, index) => (
                  <TableRow hover className={classes.tableRow} key={index}>
                    <FixedTableCell>{row.weightFrom}</FixedTableCell>
                    <FixedTableCell>{row.weightTo}</FixedTableCell>
                    <FixedTableCell>{row.price}</FixedTableCell>

                    {addWeight ? (
                      <FixedTableCell>
                        <IconButton
                          size="small"
                          onClick={() =>
                            addWeight(
                              routeIndex,
                              weightPage * weightRowsPerPage + index
                            )
                          }
                        >
                          <Edit />
                        </IconButton>

                        <IconButton
                          size="small"
                          disabled={deleteWeight}
                          onClick={() => {
                            setRoutesList((prev) => {
                              const filterd = prev[routeIndex][
                                "weights"
                              ].filter(
                                (i, deleteIndex) =>
                                  deleteIndex !==
                                  weightPage * weightRowsPerPage + index
                              );
                              const updated = [...prev];

                              updated[routeIndex]["weights"] = filterd;
                              Number.isInteger(
                                prev[routeIndex]["weights"].length /
                                  weightRowsPerPage
                              ) && setWeightPage(weightPage);
                              return updated;
                            });
                            deleteWeightTypeMutation({
                              variables: {
                                id: parseInt(row.id),
                              },
                            });
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </FixedTableCell>
                    ) : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableFixedHeaderWraper>
        <MUITablePagination
          count={weightRoute?.length ?? 0}
          rowsPerPage={weightRowsPerPage}
          page={weightPage}
          onPageChange={handleWeightPage}
          onRowsPerPageChange={handleWeightRowsPerPage}
        />
      </Grid>
    </Grid>
  );
  const price_list_route = (
    <Grid
      container
      item
      sm={12}
      md={12}
      className={classes.packageType}
      component={Paper}
    >
      {addRout ? (
        <Toolbar variant="dense" className={classes.toolbar}>
          <Typography
            className={classes.title}
            variant="h6"
            color={"text.primary"}
          ></Typography>

          <IconButton
            aria-label="Add"
            disabled={priceListId !== 0 && !Boolean(priceListId)}
            onClick={() => {
              addRout();
              setPackagePage(0);
              setRouteIndex(0);
            }}
            size="large"
            className={classes.addIcon}
          >
            <AddCircleOutline />
          </IconButton>
        </Toolbar>
      ) : null}
      <Grid container item className={classes.table}>
        <TableFixedHeaderWraper className={classes.tableContainer}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <FixedTableCell>{t("origin")}</FixedTableCell>
                <FixedTableCell>{t("destination")}</FixedTableCell>
                <FixedTableCell>{t("price")}</FixedTableCell>

                {addRout ? <FixedTableCell></FixedTableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {RouteList.length > 0 &&
                (routeRowsPerPage > 0
                  ? RouteList.slice(
                      routePage * routeRowsPerPage,
                      routePage * routeRowsPerPage + routeRowsPerPage
                    )
                  : RouteList
                ).map((row, index) => (
                  <TableRow
                    className={clsx(classes.tableRow, {
                      [classes.focus]:
                        routePage * routeRowsPerPage + index === routeIndex,
                    })}
                    hover
                    key={index}
                    onClick={() => {
                      setPackagePage(0);
                      setRouteIndex(routePage * routeRowsPerPage + index);
                    }}
                  >
                    <CellLink
                      pathname={`/admin/shipping-ports/${row?.origin.id}`}
                    >
                      {row?.origin?.name}
                    </CellLink>
                    <CellLink
                      pathname={`/admin/shipping-ports/${row?.destination.id}`}
                    >
                      {row?.destination?.name}
                    </CellLink>
                    {/* <FixedTableCell>{row?.origin?.name}</FixedTableCell>
                    <FixedTableCell>{row?.destination?.name}</FixedTableCell> */}
                    <FixedTableCell>{row?.cpmPrice}</FixedTableCell>

                    {addRout ? (
                      <FixedTableCell>
                        <IconButton
                          size="small"
                          onClick={() =>
                            addRout(routePage * routeRowsPerPage + index)
                          }
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          size="small"
                          disabled={deleteLoad}
                          onClick={() => {
                            setRoutesList((prev) => {
                              const filterd = prev.filter(
                                (i, deleteIndex) =>
                                  deleteIndex !==
                                  routePage * routeRowsPerPage + index
                              );
                              return filterd;
                            });
                            routePage * routeRowsPerPage + index === 0 &&
                              setDisabled((prev) => ({
                                ...prev,
                                routeId: false,
                              }));

                            deletePriceListPickupMutation({
                              variables: {
                                id: parseInt(row.id),
                              },
                            });
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </FixedTableCell>
                    ) : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableFixedHeaderWraper>
        <MUITablePagination
          count={RouteList?.length ?? 0}
          rowsPerPage={routeRowsPerPage}
          page={routePage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[20]}
          disableLastPage
        />
      </Grid>
    </Grid>
  );

  const tapsArray = [
    {
      tabHead: t("packageTypePrice"),
      panel: PackageTypeTable,
    },
    {
      tabHead: t("weightPrice"),
      panel: weightTable,
    },
  ];
  const tapPriceRoutes = [
    {
      tabHead: t("listRoutes"),
      panel: price_list_route,
    },
  ];
  return (
    /*//////////// routes Table ////////////*/

    <StyledGrid container alignItems="flex-start" sx={{ width: "100%" }}>
      <Grid
        container
        item
        sm={12}
        md={4}
        className={classes.packageType}
        component={Paper}
      >
        <CustomTab tapDetails={tapPriceRoutes} />
      </Grid>

      <Grid
        container
        item
        sm={12}
        md={8}
        className={classes.packageType}
        component={Paper}
      >
        <CustomTab tapDetails={tapsArray} />
      </Grid>

      {/*//////////// Destination Table ////////////*/}
    </StyledGrid>
  );
}
