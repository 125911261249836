import React, { useRef, useState } from "react";

import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";

import useWidth from "../../Hooks/useWidth";
import PendingApproval from "./StatisticsCard";
import { Globals } from "../HOC/Classes/Globals";

import { Can } from "../HOC/CustomComponents/Secured";
import EmployeesList from "./EmployeesTable";
import TableChartShipment from "./TableChartShipment";
import PieChartsRequest from "./PieChartsRequest";
import { Alert, Avatar, Button, TableRow } from "@mui/material";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import config from "../../config.json";
import RenewalDialog from "../Renewal/RenewalDialog";
import { useTranslation } from "react-i18next";

const PREFIX = "Home";

const classes = {
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  statistics: `${PREFIX}-statistics`,
  select: `${PREFIX}-select`,
  tableRow: `${PREFIX}-tableRow`,
  shipmentTable_code: `shipmentTable_code`,
  shipmentTable_shipmentStatus: `shipmentTable_shipmentStatus`,
  pieChart: `${PREFIX}-pieChart`,
  colorPrimary: `${PREFIX}-colorPrimary`,
  colorSuccess: `${PREFIX}-colorSuccess`,
  root: `${PREFIX}-root`,
  fontSize: `${PREFIX}-fontSize`,
  link: `${PREFIX}-link`,
};
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.card}`]: {
    width: "inherit",
    borderRadius: 12,
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 5%), 0px 6px 10px 0px rgb(0 0 0 / 5%), 0px 1px 18px 0px rgb(0 0 0 / 5%)",
    minHeight: "150px",
  },

  [`& .${classes.statistics}`]: {
    flex: "1 1 100%",
    fontWeight: "600",
  },

  [`& .${classes.select}`]: {
    color: "#00000099",
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: `${theme.palette.background.hover} !important`,
      },
    },
  },

  [`& .${classes.shipmentTable_code}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      left: "0 !important",
    },
  },

  [`& .${classes.shipmentTable_shipmentStatus}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0 !important",
    },
  },

  [`& .${classes.colorPrimary}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${classes.colorSuccess}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.fontSize}`]: {
    fontWeight: 600,
    fontSize: theme.spacing(2.2),
    "& span::first-letter": {
      textTransform: "capitalize",
    },
  },
  [`& .${classes.link}`]: {
    backgroundColor: theme.palette.background.hover,
    justifyContent: "center",
    width: "80%",
    margin: "auto",
    borderRadius: "20px",
  },

  [`& .${classes.pieChart}`]: {
    [theme.breakpoints.down("330")]: {},
    "& .dxc-item": {
      "& g": {
        cursor: "pointer",
      },
    },
    "& .dxc-labels-group": {
      "& g g g text": {
        cursor: "pointer !important",
        // color: "#000000de !important",s
        fill: `${theme.palette.text.primary} !important`,
      },
    },
    "& .dxc-series-group": {
      "& g g path": {
        cursor: "pointer !important",
      },
    },
  },
}));

const formatDate = (date) => moment(date).locale("en").format("YYYY-MM-DD");
const initToDate = 30;

const Home = (props) => {
  const week = useRef(7);
  const month = useRef(30);
  const { t } = useTranslation();

  const [queryVariablesPieCharts, setQueryVariablesPieCharts] = useState({
    shipmentDate: {
      fromDays: month.current,
    },
    statusCode: null,
  });
  const [queryVariablesRequestPieCharts, setQueryVariablesRequestPieCharts] =
    useState({
      requestDate: {
        fromDays: month.current,
      },
      statusCode: null,
    });

  const onDateRangeRequestAccept = (date, closeDateRangeDialog, section) => {
    section === "pieChart" &&
      setQueryVariablesRequestPieCharts((prev) => ({
        ...prev,
        requestDate: {
          fromDate: formatDate(date[0]),
          toDate: formatDate(date[1]),
        },
      }));

    closeDateRangeDialog();
  };
  const onDateRangeAccept = (date, closeDateRangeDialog, section) => {
    section === "pieChart" &&
      setQueryVariablesPieCharts((prev) => ({
        ...prev,
        shipmentDate: {
          fromDate: formatDate(date[0]),
          toDate: formatDate(date[1]),
        },
      }));

    closeDateRangeDialog();
  };
  const handleDateRangeRequest = (
    value,
    setSelectValue,
    setDateRangeDialog,
    section
  ) => {
    let defaultParamter = {
      fromDays: initToDate,
    };

    switch (value) {
      case "month":
        setSelectValue("month");
        defaultParamter.fromDays = month.current;
        break;
      case "week":
        setSelectValue("week");
        defaultParamter.fromDays = week.current;
        break;
      case "all":
        setSelectValue("all");
        defaultParamter = {};
        break;

      case "period":
      default:
        setSelectValue("period");

        return setDateRangeDialog(true);
    }

    section === "pieChart" &&
      setQueryVariablesRequestPieCharts((prev) => ({
        statusCode: prev.statusCode,
        requestDate: { ...defaultParamter },
      }));
  };

  const handleDateRange = (
    value,
    setSelectValue,
    setDateRangeDialog,
    section
  ) => {
    let defaultParamter = {
      fromDays: initToDate,
    };

    switch (value) {
      case "month":
        setSelectValue("month");
        defaultParamter.fromDays = month.current;
        break;
      case "week":
        setSelectValue("week");
        defaultParamter.fromDays = week.current;
        break;
      case "all":
        setSelectValue("all");
        defaultParamter = {};
        break;

      case "period":
      default:
        setSelectValue("period");

        return setDateRangeDialog(true);
    }

    section === "pieChart" &&
      setQueryVariablesPieCharts((prev) => ({
        statusCode: prev.statusCode,
        shipmentDate: { ...defaultParamter },
      }));
  };

  const handleStatusRequest = (value, delivered) => {
    delete queryVariablesRequestPieCharts.delivered;
    setQueryVariablesRequestPieCharts((prev) => ({
      ...prev,
      statusCode: value,
    }));
  };

  // let DOM = null;
  /////////////////////////
  const userCanSubscription = Globals.user.hasPermission(
    "shipping.settings.renew_subscription"
  );
  const [open, setOpen] = React.useState(
    userCanSubscription && localStorage.getItem("renewalDateMsg") === "true"
  );
  const [openRenewalDialog, setOpenRenewalDialog] = useState(false);
  const closeShipmentDialogHandler = () => {
    setOpenRenewalDialog(false);
  };
  const screenWidth = useWidth();
  const isSmallScreen = ["md", "sm", "xs"].includes(screenWidth);
  const user = Globals.user;
  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;
    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };
  const queryFields = [
    "name",
    "id",
    "customersCount",
    "requestsCount",
    "code",
    { image: ["path"] },
  ];
  const tableHeadCell = [
    "code",
    "image",
    "name",
    "customersCount",
    "requestsCount",
  ];
  const tableBody = (employee, index) => (
    <TableRow className={classes.tableRow} key={index}>
      <CellLink pathname={`/admin/employees/${employee?.id}`}>
        {employee?.code}
      </CellLink>
      <FixedTableCell>
        <Avatar>
          {employee?.image?.path && (
            <img
              width="75px"
              src={getBackendUri(employee?.image?.path)}
              alt="RowImage"
            />
          )}
        </Avatar>
      </FixedTableCell>
      <CellLink pathname={`/admin/employees/${employee?.id}`}>
        {employee?.name}
      </CellLink>
      <FixedTableCell>{employee.customersCount}</FixedTableCell>
      <FixedTableCell>{employee.requestsCount}</FixedTableCell>
    </TableRow>
  );
  // Define components based on permissions
  const components = [];

  components.push(
    {
      id: 1,
      order: 1,
      component: (
        <Can
          showException
          permission={
            user.hasPermission("freight.shipment.list") ||
            user.hasPermission("freight.request.list")
          }
        >
          {" "}
          <PendingApproval {...props} />
        </Can>
      ),
    },
    {
      id: 2,
      order: 2,
      component: (
        <Can
          showException
          permission={user.hasPermission("freight.shipment.list")}
        >
          <TableChartShipment
            onDateRangeAccept={onDateRangeAccept}
            handleDateRange={handleDateRange}
            dateRange={{
              shipmentDate: queryVariablesPieCharts?.shipmentDate,
            }}
            classNamePieChart={classes.pieChart}
          />
        </Can>
      ),
    },
    {
      id: 3,
      order: 3,
      component: (
        <Can
          showException
          permission={user.hasPermission("freight.request.list")}
        >
          <PieChartsRequest
            onDateRangeAccept={onDateRangeRequestAccept}
            handleDateRange={handleDateRangeRequest}
            dateRange={{
              requestDate: queryVariablesRequestPieCharts?.requestDate,
            }}
            onFocus={(filterType, delivered) =>
              handleStatusRequest(filterType, delivered)
            }
            classNamePieChart={classes.pieChart}
          />
        </Can>
      ),
    },

    {
      id: 4,
      order: 4,
      component: (
        <Can
          showException
          permission={user.hasPermission("freight.employee.list")}
        >
          <EmployeesList
            sum={false}
            tableHeadCell={tableHeadCell}
            tableBody={tableBody}
            queryFields={queryFields}
          />
        </Can>
      ),
    }
  );

  // Sort components by `order`
  components.sort((a, b) => a.order - b.order);

  // Generate DOM elements
  const DOM = components.length > 0 && (
    <Grid
      container
      justifyContent={isSmallScreen ? "center" : "space-between"}
      alignContent="space-between"
      spacing={2}
      my={2}
    >
      {open && (
        <Grid item xs={12} px={2}>
          <Alert
            sx={{ width: "100%", background: "#f4bf4f", color: "black" }}
            variant="filled"
            severity="warning"
            action={
              <Button
                color="error"
                size="small"
                variant="contained"
                sx={{ boxShadow: "none" }}
                onClick={() => {
                  setOpenRenewalDialog(true);
                }}
              >
                {t("renew")}
              </Button>
            }
          >
            {t("renewalMsg", { date: Globals.settings.renewalDate })}
          </Alert>
        </Grid>
      )}
      {components.map((item) => (
        <Grid xs={12} key={item.id}>
          {item.component}
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Root>
      <RenewalDialog
        open={openRenewalDialog}
        onClose={closeShipmentDialogHandler}
        removeMsg={(obj) => {
          setOpen(obj);
        }}
      />
      <Container component="main" maxWidth="xl">
        {DOM}
      </Container>
    </Root>
  );
};

export default Home;
