/* eslint-disable no-useless-concat */
import React from "react";
import { useTranslation } from "react-i18next";
import { Paper, Icon } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { TRANSACTION_TYP_ID } from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";
const TransactionTypeView = (props) => {
  const { t } = useTranslation();
  const TransactionTypeId = props.match.params.id?.trim();
  const { loading, data } = useQuery(
    gql`
      ${TRANSACTION_TYP_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(TransactionTypeId) },
    }
  );

  const user = Globals.user;
  const branchId = data?.transactionType?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;

  const canEdit =
    user.hasPermission("freight.transaction_type.update") && canAccessBranch;

  const icons = [
    {
      id: "edit",
      action: () =>
        pushUrl(props, `/admin/transaction-types/${TransactionTypeId}/edit`),
      permission: canEdit,
    },
  ];

  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data ? (
    <NotFound />
  ) : (
    <>
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>
      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Paper container xs={12} className={classesView.paper} component={Grid}>
          <KeyValuePair title={t("code")} value={data?.transactionType?.code} />
          <KeyValuePair title={t("name")} value={data?.transactionType?.name} />
          <KeyValuePair
            title={t("active")}
            value={
              data?.transactionType?.active ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("documentType")}
            value={data?.transactionType?.type?.name}
          />
          <KeyValuePair
            title={t("sequence")}
            value={data?.transactionType?.sequence?.name}
          />
        </Paper>

        <Paper container xs={12} className={classesView.paper} component={Grid}>
          <KeyValuePair
            title={t("branch")}
            value={data?.transactionType?.branch?.name}
          />
          <KeyValuePair
            title={t("glAccount")}
            value={data?.transactionType?.glAccount?.name}
          />
          <KeyValuePair
            title={t("journalType")}
            value={data?.transactionType?.journalType?.name}
          />
          <KeyValuePair
            title={t("subsidiary")}
            value={data?.transactionType?.subsidiary?.name}
          />
          <KeyValuePair
            title={t("air")}
            value={
              data?.transactionType?.air ? (
                <>
                  <Icon className={classesView.iconColor}>
                    check_circle_outline
                  </Icon>
                </>
              ) : (
                <>
                  <Icon color="error">highlight_off</Icon>
                </>
              )
            }
          />
          <KeyValuePair
            title={t("land")}
            value={
              data?.transactionType?.land ? (
                <>
                  <Icon className={classesView.iconColor}>
                    check_circle_outline
                  </Icon>
                </>
              ) : (
                <>
                  <Icon color="error">highlight_off</Icon>
                </>
              )
            }
          />

          <KeyValuePair
            title={t("ocean")}
            value={
              data?.transactionType?.ocean ? (
                <>
                  <Icon className={classesView.iconColor}>
                    check_circle_outline
                  </Icon>
                </>
              ) : (
                <>
                  <Icon color="error">highlight_off</Icon>
                </>
              )
            }
          />
          <KeyValuePair
            title={t("import")}
            value={
              data?.transactionType?.import ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("export")}
            value={
              data?.transactionType?.export ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
        </Paper>
      </RootStyleView>
    </>
  );
};

export default TransactionTypeView;
