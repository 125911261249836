/* eslint-disable no-useless-concat */
import { gql, useQuery } from "@apollo/client";
import { Paper } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Globals } from "../HOC/Classes/Globals";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { ORGANIZATION } from "./Graphql";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";

import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";

const OrganizationDataView = (props) => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(
    gql`
      ${ORGANIZATION.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: {},
    }
  );

  const user = Globals.user;

  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/organization/edit`),
      permission: user.hasPermission("core.organization.update"),
    },
  ];

  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : (
    <>
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>
      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair
            title={t("organizationName")}
            value={data?.organization.name}
          />
          <KeyValuePair
            title={t("ownerName")}
            value={data?.organization.owner}
          />
          <KeyValuePair
            title={t("activity")}
            value={data?.organization.activity}
          />
          <KeyValuePair
            title={t("registrationNumber")}
            value={data?.organization.registrationNumber}
          />
          <KeyValuePair
            phoneStyle={true}
            valueStyle={{ inlineSize: "max-content" }}
            dir="ltr"
            title={t("mobile")}
            value={data?.organization.phone}
          />
          <KeyValuePair title={t("email")} value={data?.organization.email} />
          <KeyValuePair
            title={t("website")}
            value={data?.organization.website}
          />
          <KeyValuePair
            title={t("taxArea")}
            value={data?.organization.taxArea}
          />
          <KeyValuePair
            title={t("taxNumber")}
            value={data?.organization.taxNumber}
          />
          <KeyValuePair
            title={t("taxActivityCode")}
            value={data?.organization.taxActivityCode}
          />
          <KeyValuePair
            title={t("taxClientId")}
            value={data?.organization.taxClientId}
          />
          <KeyValuePair
            title={t("taxClientSecret")}
            value={data?.organization.taxClientSecret}
          />
          <KeyValuePair
            title={t("reportHeader")}
            value={data?.organization.reportHeader}
          />
          <KeyValuePair
            title={t("reportFooter")}
            value={data?.organization.reportFooter}
          />
        </Paper>
      </RootStyleView>
    </>
  );
};

export default OrganizationDataView;
