/* eslint-disable no-useless-concat */
import React, { Fragment } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Paper, Icon } from "@mui/material";
import { BRANCH } from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";
const BranchView = (props) => {
  const branchId = props.id ?? props.match?.params?.id?.trim();
  const { t } = useTranslation();
  const { loading, data } = useQuery(
    gql`
      ${BRANCH.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(branchId) },
    }
  );

  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/branches/${branchId}/edit`),
      permission: "core.branch.update",
    },
  ];

  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data ? (
    <NotFound />
  ) : (
    <>
      {" "}
      <Fragment>
        <TitleAppBar path={props.match.path}>
          <LongMenu icons={icons} />
        </TitleAppBar>
      </Fragment>
      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Fragment>
          <Paper
            container
            xs={12}
            className={classesView.paper}
            component={Grid}
          >
            <KeyValuePair title={t("code")} value={data?.branch.code} />
            <KeyValuePair title={t("name")} value={data?.branch.name} />
            <KeyValuePair
              title={t("active")}
              value={
                data?.branch?.active ? (
                  <Icon className={classesView.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
            <KeyValuePair
              title={t("main")}
              value={
                data?.branch?.main ? (
                  <Icon className={classesView.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
          </Paper>

          <Paper
            container
            xs={12}
            className={classesView.paper}
            component={Grid}
          >
            <KeyValuePair
              title={t("country")}
              value={
                <SpanLink
                  pathname={`/admin/countries/${data?.branch?.country?.id}`}
                >
                  {data?.branch?.country?.name}
                </SpanLink>
              }
            />
            <KeyValuePair title={t("state")} value={data?.branch.state?.name} />
            <KeyValuePair title={t("city")} value={data?.branch.city?.name} />

            <KeyValuePair
              title={t("phone")}
              valueStyle={{ inlineSize: "max-content" }}
              value={<span dir="ltr">{data?.branch.phon}</span>}
            />
            <KeyValuePair title={t("fax")} value={data?.branch.fax} />

            <KeyValuePair title={t("address")} value={data?.branch.address} />
          </Paper>
        </Fragment>
      </RootStyleView>
    </>
  );
};

export default BranchView;
