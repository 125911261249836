export const printLink = (
  code,
  batch,
  requestsId,
  filterValue,
  afterSaveFun,
  operation
) => {
  const basePrintUrl = `${window.location.origin}/waybill/${
    batch ? "batch" : "request"
  }`;
  console.log(code);

  const requestsIdStr = requestsId.toString();

  const printUrls = {
    SHEET_SAME_1: `${basePrintUrl}/print/${requestsIdStr}/1c${
      operation ? `/${operation}` : ""
    }`,
    copy: `${basePrintUrl}/print/${requestsIdStr}/2d${
      operation ? `/${operation}` : ""
    }`,
    SHEET_SAME_2: `${basePrintUrl}/print/${requestsIdStr}/2c${
      operation ? `/${operation}` : ""
    }`,
    SHEET_SAME_3: `${basePrintUrl}/print/${requestsIdStr}/3c${
      operation ? `/${operation}` : ""
    }`,
    SHEET_DIFF_2: `${basePrintUrl}/print/${requestsIdStr}/2d${
      operation ? `/${operation}` : ""
    }`,
    SHEET_DIFF_3: `${basePrintUrl}/print/${requestsIdStr}/3d${
      operation ? `/${operation}` : ""
    }`,
  };

  // Handle the sticker cases dynamically
  if (code.includes("X")) {
    window.open(
      `${basePrintUrl}/sticky-print/${code}/${filterValue}=${requestsId}${
        operation ? `/${operation}` : ""
      }`
    );
  } else if (printUrls[code]) {
    window.open(printUrls[code]);
  } else {
    return;
  }

  if (typeof afterSaveFun === "function") {
    afterSaveFun();
  }
};
