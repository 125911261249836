import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { TableBody, TableRow, Icon, Button } from "@mui/material";
import { Search } from "@mui/icons-material";
import { LIST_TAXES } from "./Graphql";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";
import useWidth from "../../Hooks/useWidth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import MUIselect from "../HOC/MUI/MUIselect";
import Grid from "@mui/material/Unstable_Grid2";
import {
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";

import { RootStyleList, classes } from "../../GlobalStyles/ListStyle";

const TaxList = (props) => {
  const { t } = useTranslation();
  const screenWidth = useWidth();
  const history = useHistory();
  const [refetch, setrefetch] = useState(true);
  const [noData, setNoData] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const [autocompleteValues, setAutocompleteValues] = useState({
    mode: [],
    shippingDirection: [],
    type: null,
    glAccount: null,
  });
  const [rowsPerPage, setRowsPerPage] = useState(
    urlQuery["rowsPerPage"] ?? config.app.pageSize
  );
  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      if (urlQuery["shippingMode"]) {
        urlQuery["shippingMode"] = urlQuery["shippingMode"].split(",");
      }
      if (urlQuery["shippingDirection"]) {
        urlQuery["shippingDirection"] =
          urlQuery["shippingDirection"].split(",");
      }

      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["active"] !== undefined && setValue("active", urlQuery["active"]);
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    setAutocompleteValues((prev) => ({
      ...prev,
      ...(urlQuery["shippingMode"] && {
        mode: urlQuery["shippingMode"],
      }),
      ...(urlQuery["shippingDirection"] && {
        shippingDirection: urlQuery["shippingDirection"],
      }),
      ...(urlQuery["type"] && {
        type: urlQuery["type"],
      }),
      ...(urlQuery["glAccountId"] && {
        glAccount: urlQuery["glAccountId"],
      }),
    }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/tax",
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    refetch ? setrefetch(false) : setrefetch(true);
    let handledData = {
      active: data.active,
      name: data.name,
      code: data.code,
      shippingMode: data.shippingMode,
      shippingDirection: data.shippingDirection,
      type: data.type,
      glAccountId: data.glAccountId,
      page: 0,
    };
    data["active"] === "" && delete handledData["active"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const parseData = (data) => {
    return data;
  };
  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };
  const { data, loading } = useQuery(
    gql`
      ${LIST_TAXES.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          active: search.active,
          ...(search.code && { code: search.code }),
          ...(search.name && { name: search.name }),
          ...(search.shippingMode && {
            shippingMode: search.shippingMode,
          }),
          ...(search.shippingDirection && {
            shippingDirection: search.shippingDirection,
          }),
          ...(search.glAccountId && {
            glAccountId: search.glAccountId,
          }),
          ...(search.type && {
            type: search.type,
          }),
        },
      },
      onCompleted: () => {
        setNoData(false);
      },
    }
  );

  const listTaxes = data?.listTaxes?.data;

  const icons = [
    {
      id: "filterList",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
    },
    {
      id: "add",
      action: () => pushUrl(props, `/admin/tax/create`),
      permission: "freight.tax.create",
    },
  ];

  return (
    <RootStyleList>
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={listTaxes?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"active"}
                  label={t("active")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("notActive"), value: false },
                    { key: t("active"), value: true },
                  ]}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MultipleAutocomplete
                  valueKey="code"
                  multiple
                  skip={noData}
                  control={control}
                  errors={errors}
                  name={"shippingMode"}
                  label={t("shippingMode")}
                  parseData={(data) => parseData(data)}
                  variables={{ input: { code: "FR_SHIPPING_MODE" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.mode}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MultipleAutocomplete
                  name={"shippingDirection"}
                  label={t("shippingDirection")}
                  skip={noData}
                  control={control}
                  errors={errors}
                  variables={{ input: { code: "FR_SHIPPING_DIRECTION" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  parseData={(data) => parseData(data)}
                  multiple
                  valueKey="code"
                  defaultValue={autocompleteValues.shippingDirection}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"glAccountId"}
                  label={t("glAccount")}
                  parseData={(data) => parseData(data)}
                  query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                  variables={{
                    input: {
                      typeCode: "SUB",
                    },
                  }}
                  defaultValue={autocompleteValues.glAccount}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  valueKey="code"
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"type"}
                  label={t("type")}
                  parseData={(data) => parseData(data)}
                  variables={{ input: { code: "FR_TAX_TYPE" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.type}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={[
          {
            name: "name",
          },
          {
            name: "code",
          },
          {
            name: "rate",
          },
          {
            name: "glAccount",
          },
          {
            name: "type",
          },
          {
            name: "ocean",
          },
          {
            name: "air",
          },
          {
            name: "land",
          },
          {
            name: "import",
          },
          {
            name: "export",
          },
          {
            name: "active",
          },
        ]}
        tableBody={
          <TableBody>
            {listTaxes &&
              listTaxes?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <CellLink pathname={`/admin/tax/${row?.id}`}>
                      {row?.name}
                    </CellLink>
                    <FixedTableCell>{row?.code}</FixedTableCell>
                    <FixedTableCell>{row?.rate}</FixedTableCell>
                    <FixedTableCell>{row?.glAccount?.name}</FixedTableCell>
                    <FixedTableCell>{row?.type?.name}</FixedTableCell>
                    <FixedTableCell>
                      {row?.ocean ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.air ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.land ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.import ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.export ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.active ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listTaxes?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listTaxes ? 0 : search.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </RootStyleList>
  );
};

export default TaxList;
