import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Typography, Paper, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { gql, useQuery } from "@apollo/client";
import { Close, Search } from "@mui/icons-material";
import { PERMISSIONS } from "./Graphql";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useForm } from "react-hook-form";
import Grid from "@mui/material/Unstable_Grid2";
import { Globals } from "../HOC/Classes/Globals";

const PREFIX = "PermissionSection";

const classes = {
  main: `${PREFIX}-main`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  field: `${PREFIX}-field`,
  search: `${PREFIX}-search`,
  dataResult: `${PREFIX}-dataResult`,
  searchColor: `${PREFIX}-searchColor`,
  closeIcon: `${PREFIX}-closeIcon`,
  chip: `${PREFIX}-chip`,
  section: `${PREFIX}-section`,
  moduleTitle: `${PREFIX}-moduleTitle`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.main}`]: {
    width: "100%",
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.search}`]: {
    // marginRight: `${theme.spacing(2)} !important`,
    paddingLeft: `0px !important`,
    paddingRight: `0px !important`,
    position: "relative",
  },

  [`& .${classes.dataResult}`]: {
    overflow: "hidden",
    overflowY: "auto",
    height: "auto",
    maxHeight: 150,
    position: "absolute",
    top: "88%",
    zIndex: 2,
  },

  [`& .${classes.searchColor}`]: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    margin: "5px",
  },

  [`& .${classes.closeIcon}`]: {
    cursor: "pointer",
  },

  [`& .${classes.chip}`]: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.section}`]: {
    backgroundColor: "#00000008",
    display: "flex",
    flexWrap: "wrap",
  },

  [`& .${classes.moduleTitle}`]: {
    margin: theme.spacing(2),
    color: theme.palette.text.primary,
  },
}));

let useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const PermissionSection = (props) => {
  const { permission, id, permissionIds } = props;
  const [rolePermissions, setRolePermissions] = useState(permission ?? []);
  const [dropDownPermissions, setDropDownPermissions] = useState([]);

  const { t } = useTranslation();
  const { control, formState } = useForm();
  const { errors } = formState;
  const sections = [
    {
      title: t("finance"),
      code: "accounting",
      subSections: [
        { title: t("glAccount"), patterns: ["gl_account"] },
        { title: t("subsidiary"), patterns: ["subsidiary"] },
        { title: t("journalType"), patterns: ["journal_type"] },
        { title: t("financeStatement"), patterns: ["finance_statement"] },
        { title: t("settings"), patterns: ["settings"] },
        { title: t("journalEntry"), patterns: ["journal_entry"] },
        { title: t("others"), patterns: [] },
      ],
    },
    {
      title: t("cash"),
      code: "cash",
      subSections: [
        { title: t("safe"), patterns: ["safe"] },
        { title: t("currency"), patterns: ["currency"] },
        { title: t("vouchers"), patterns: [] },
      ],
    },
    {
      title: t("basicConfig"),
      code: "core",
      subSections: [
        { title: t("lookup"), patterns: ["lookup"] },
        { title: t("sequence"), patterns: ["sequence"] },
        { title: t("log"), patterns: ["log"] },
        { title: t("country"), patterns: ["country"] },
        { title: t("state"), patterns: ["state"] },
        { title: t("city"), patterns: ["city"] },
        { title: t("area"), patterns: ["area"] },
        { title: t("users"), patterns: ["user"] },
        { title: t("role"), patterns: ["role"] },
        { title: t("branch"), patterns: ["branch"] },
        { title: t("organizationData"), patterns: ["organization"] },
        { title: t("others"), patterns: [] },
      ],
    },
    {
      title: t("freight"),
      code: "freight",
      subSections: [
        { title: t("request"), patterns: ["request"] },
        { title: t("customer"), patterns: ["customer"] },
        { title: t("employee"), patterns: ["employee"] },
        { title: t("packageType"), patterns: ["package_type"] },
        { title: t("settings"), patterns: ["settings"] },
        { title: t("customAgent"), patterns: ["custom_agent"] },
        { title: t("agency"), patterns: ["agency"] },
        { title: t("carrier"), patterns: ["carrier"] },
        { title: t("listPriceList"), patterns: ["price_list"] },
        { title: t("shippingPortList"), patterns: ["shipping_port"] },
        { title: t("vendor"), patterns: ["vendor"] },
        { title: t("incoTerm"), patterns: ["inco_term"] },
        { title: t("listChargeTypes"), patterns: ["charge_type"] },
        { title: t("commodity"), patterns: ["commodity"] },
        { title: t("transactionType"), patterns: ["transaction_type"] },
        { title: t("tax"), patterns: ["tax"] },
        { title: t("wareHouse"), patterns: ["warehouse"] },
        { title: t("bill"), patterns: ["bill"] },
        { title: t("invoice"), patterns: ["invoice"] },
        { title: t("adjustments"), patterns: ["adjustment"] },
        { title: t("product"), patterns: ["product"] },
        { title: t("operationListrcvd"), patterns: ["operation_rcvd"] },
        { title: t("packageDetails"), patterns: ["package_detail"] },
        { title: t("transaction"), patterns: ["transaction"] },
        { title: t("shipments"), patterns: ["shipment"] },
        { title: t("operationListload"), patterns: ["operation_loading"] },
        { title: t("operationListUnLoad"), patterns: ["operation_unloaded"] },
        {
          title: t("operationListDelivered"),
          patterns: ["operation_delivered"],
        },
        { title: t("others"), patterns: [] },
      ],
    },
    {
      title: t("crm"),
      code: "crm",
      subSections: [
        { title: t("category"), patterns: ["category"] },
        { title: t("others"), patterns: [] },
      ],
    },
    {
      title: t("shipping"),
      code: "shipping",
      subSections: [
        { title: t("customers"), patterns: ["customer_(merchant|individual)"] },
        { title: t("customersRequests"), patterns: ["customer_request(_.*)?"] },
        {
          title: t("conciergeRequests"),
          patterns: ["concierge_request(_.*)?"],
        },
        {
          title: t("conciergeProviders"),
          patterns: ["concierge_provider(_.*)?"],
        },
        {
          title: t("deliveryAgent"),
          patterns: ["delivery_agent", "delivery_agent_position"],
        },
        { title: t("priceList"), patterns: ["price_list_.*"] },
        { title: t("route"), patterns: ["route"] },
        { title: t("vehicle"), patterns: ["vehicle"] },
        { title: t("driver"), patterns: ["driver"] },
        { title: t("cancellationReasons"), patterns: ["cancellation_reason"] },
        { title: t("zone"), patterns: ["zone"] },
        { title: t("shippingService"), patterns: ["shipping_service"] },
        {
          title: t("transactionType"),
          patterns: ["transaction_type"],
        },
        { title: t("shipment"), patterns: ["shipment", "barcode_batch"] },
        {
          title: t("shipmentsTransactions"),
          patterns: ["shipment_transaction"],
        },
        { title: t("commissionList"), patterns: ["commission_list"] },
        { title: t("settings"), patterns: ["settings"] },
        { title: t("operationLists"), patterns: ["manifest_.*"] },
        { title: t("collection"), patterns: ["collection_.*"] },
        { title: t("payment"), patterns: ["payment_.*"] },
        { title: t("invoice"), patterns: ["invoice"] },
        { title: t("pickup"), patterns: ["pickup"] },
        { title: t("messages"), patterns: ["shipment_message"] },
        { title: t("registeredAccounts"), patterns: ["registration_.*"] },
        { title: t("recipient"), patterns: ["consignee"] },
        { title: t("product"), patterns: ["product"] },
        {
          title: t("warehousing"),
          patterns: [
            "warehouse_section",
            "warehouse_manifest",
            "product_transaction",
            "warehouse_pickup",
          ],
        },
        { title: t("others"), patterns: [] },
      ],
    },
    {
      title: t("others"),
      code: "others",
      subSections: [{ title: t("others"), patterns: [] }],
    },
  ];
  const { data } = useQuery(
    gql`
      ${PERMISSIONS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,

      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onError: (error) => {
        console.log(error);
      },
      onCompleted: (data) => {
        if (id) {
          const perm = data?.listPermissions.filter((value) => {
            return Globals.user.isSuper
              ? !rolePermissions.some((i) => i.id === value.id)
              : !rolePermissions.some((i) => i.id === value.id) &&
                  Globals.user.permissions.some((i) => i === value.slug);
          });
          setDropDownPermissions(perm);
        } else {
          setDropDownPermissions(data?.listPermissions);
        }
      },
    }
  );

  let rolePermissionsCopy = [...rolePermissions];

  sections.forEach((section, index) => {
    let sectionPermissions = [];
    section.subSections.forEach((subSection, index) => {
      const module = rolePermissionsCopy?.some(
        (i) => section.code.toLowerCase() === i.slug.split(".")[0]
      );

      if (module) {
        let other = section.subSections.length - 1 === index;
        const permissions = rolePermissionsCopy?.filter(
          (p) =>
            section.code.toLowerCase() === p.slug.split(".")[0] &&
            subSection.patterns.findIndex((e) => {
              e = "^" + e + "$";
              return new RegExp(e).test(p.slug.split(".")[1]);
            }) > -1
        );

        if (
          section.code.toLowerCase() ===
            (permissions.length > 0 && permissions[0].slug.split(".")[0]) &&
          !other
        ) {
          sectionPermissions = [...sectionPermissions, ...permissions];
          subSection.permissions = permissions;
        }

        if (other) {
          let otherPermission = rolePermissionsCopy.filter((p) => {
            let title = section.code.toLowerCase() === p.slug.split(".")[0];
            let notIncludes = !sectionPermissions.some((i) => {
              return i.id === p.id;
            });

            return Boolean(title && notIncludes);
          });
          subSection.permissions = otherPermission;
        }
      }
    });
  });

  const [filteredData, setFilteredData] = useState([]);
  const [wordIntered, setWordIntered] = useState("");

  const handelFilter = (e) => {
    const searchWord = e.target.value;
    setWordIntered(searchWord);
    const newFilter = dropDownPermissions.filter((value) => {
      return value.name.toLowerCase().includes(searchWord.toLowerCase());
    });
    searchWord === "" ? setFilteredData([]) : setFilteredData(newFilter);
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordIntered("");
  };

  const addPermission = (id) => {
    const newPermission = dropDownPermissions.filter((value) => {
      return value.id === id;
    });
    setRolePermissions([...rolePermissions, ...newPermission]);
    setFilteredData(
      filteredData.filter((value) => {
        return !newPermission.includes(value);
      })
    );
    setDropDownPermissions(
      dropDownPermissions.filter((value) => {
        return !newPermission.includes(value);
      })
    );
  };
  const deletePermission = (id) => {
    const deletePermission = rolePermissions.filter((value) => {
      return value.id !== id;
    });
    setRolePermissions([...deletePermission]);
    const add = data.listPermissions.find((i) => i.id === id);
    setDropDownPermissions((prev) => [...prev, add]);
  };

  useEffect(() => {
    permissionIds && permissionIds(permissionsId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolePermissions]);
  const permissionsId = rolePermissions?.map((i) => i.id);

  let domNode = useClickOutside(() => {
    setFilteredData([]);
    setWordIntered("");
  });

  return (
    <StyledGrid container spacing={2} sx={{ gap: 3, p: 1, flexGrow: 1 }}>
      {(permissionIds || filteredData.length !== 0) && (
        <Grid container xs={12} ref={domNode} className={classes.search}>
          {permissionIds && (
            <Grid container xs={12} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"search"}
                label={t("search")}
                value={wordIntered}
                onChange={handelFilter}
                InputProps={{
                  endAdornment:
                    filteredData.length === 0 ? (
                      <Search />
                    ) : (
                      <Close
                        className={classes.closeIcon}
                        onClick={clearInput}
                      />
                    ),
                }}
              />
            </Grid>
          )}
          {filteredData.length !== 0 && (
            <Paper
              container
              xs={12}
              alignItems="flex-start"
              component={Grid}
              className={classes.dataResult}
            >
              {filteredData.map((data, index) => {
                const name =
                  data.slug.split(".")[0] + " " + data.name.toLowerCase();
                return (
                  <Grid xs={12} key={index} className={classes.searchColor}>
                    <Typography
                      variant="h6"
                      onClick={() => addPermission(data.id)}
                      color={"text.primary"}
                    >
                      {name}
                    </Typography>
                  </Grid>
                );
              })}
            </Paper>
          )}
        </Grid>
      )}

      {sections.map((section, index) =>
        section.subSections.some((i) => i.permissions) ? (
          <Paper
            container
            xs={12}
            key={index}
            component={Grid}
            className={classes.card}
          >
            <Grid container justifyContent="space-between" xs={12}>
              <Typography variant="h6" className={classes.moduleTitle}>
                {section.title}
              </Typography>
            </Grid>
            {section.subSections.map((subSection, index) =>
              subSection?.permissions?.length > 0 ? (
                <Grid sx={{ display: "flex" }} xs={12} sm={6} key={index}>
                  <Paper
                    className={classes.section}
                    alignContent="flex-start"
                    xs={12}
                    elevation={4}
                    component={Grid}
                  >
                    <Grid container justifyContent="space-between" sm={12}>
                      <Typography variant="h6" color={"text.primary"}>
                        {subSection["title"]}
                      </Typography>
                    </Grid>
                    {subSection?.permissions?.map((row, index) => (
                      // <Grid container  md={6} key={index}>
                      <Chip
                        key={index}
                        className={classes.chip}
                        onDelete={
                          permissionIds
                            ? () => deletePermission(row.id)
                            : undefined
                        }
                        size="medium"
                        label={row.name}
                        color="primary"
                      />
                      // </Grid>
                    ))}
                  </Paper>
                </Grid>
              ) : null
            )}
          </Paper>
        ) : null
      )}
    </StyledGrid>
  );
};

export default PermissionSection;
