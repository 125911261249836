import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { TableBody, TableRow, Button, Icon } from "@mui/material";
import { Search } from "@mui/icons-material";
import { LIST_OPERATIONS } from "./Graphql";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useWidth from "../../Hooks/useWidth";
import config from "../../config.json";
import Grid from "@mui/material/Unstable_Grid2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { LIST_WAREHOUSE_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import { dateFormat } from "../../helpers/dateFunctions";
import moment from "moment";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import MUIselect from "../HOC/MUI/MUIselect";
import { RootStyleList, classes } from "../../GlobalStyles/ListStyle";

const OperationList = (props) => {
  const { t } = useTranslation();
  const listType = props.match.params.type;
  const screenWidth = useWidth();
  const history = useHistory();
  const [refetch, setrefetch] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    transactionType: null,
    warehouse: null,
  });
  const [noData, setNoData] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(
    urlQuery["rowsPerPage"] ?? config.app.pageSize
  );
  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });
  const initDate = moment(new Date()).locale("en").format("YYYY-MM-DD");
  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : validUrlParameters
    ? null
    : initDate;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : validUrlParameters
    ? null
    : initDate;
  const [date, setdate] = useState([initFromDate, initToDate]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      date: {
        ...(date[0] !== null && { fromDate: date[0] }),
        ...(date[1] !== null && { toDate: date[1] }),
      },
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      if (urlQuery["requestCode"])
        urlQuery["requestCode"] = urlQuery["requestCode"].toString();
      if (urlQuery.fromDate || urlQuery.toDate) {
        const date = {
          ...(urlQuery?.fromDate && {
            fromDate: urlQuery?.fromDate,
          }),
          ...(urlQuery?.toDate && {
            toDate: urlQuery?.toDate,
          }),
        };
        delete urlQuery["fromDate"];
        delete urlQuery["toDate"];
        urlQuery.date = date;
      }
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    urlQuery["requestCode"] && setValue("requestCode", urlQuery["requestCode"]);
    urlQuery["approved"] !== undefined &&
      setValue("approved", urlQuery["approved"]);
    setAutocompleteValues((prev) => ({
      ...(urlQuery["branchId"] && {
        branch: urlQuery["branchId"],
      }),
      ...(urlQuery["transactionTypeId"] && {
        transactionType: urlQuery["transactionTypeId"],
      }),
      ...(urlQuery["warehouseId"] && {
        warehouse: urlQuery["warehouseId"],
      }),
    }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetdate = () => {
    setdate([null, null]);
  };
  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: `/admin/operations/${listType}`,
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    refetch ? setrefetch(false) : setrefetch(true);
    let handledData = {
      code: data.code,

      requestCode: data.requestCode,
      branchId: data.branchId,
      transactionTypeId: data.transactionTypeId,
      warehouseId: data.warehouseId,
      approved: data.approved,
      ...(date[0] !== null && {
        fromDate: moment(date[0]).locale("en").format("YYYY-MM-DD"),
      }),
      ...(date[1] !== null && {
        toDate: moment(date[1]).locale("en").format("YYYY-MM-DD"),
      }),
      // fromDate: moment(date[0]).locale("en").format("YYYY-MM-DD"),
      // toDate: moment(date[1]).locale("en").format("YYYY-MM-DD"),
      page: 0,
    };
    data["approved"] === "" && delete handledData["approved"];
    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });

    if (handledData.fromDate || handledData.toDate) {
      const date = {
        ...(handledData?.fromDate && {
          fromDate: handledData?.fromDate,
        }),
        ...(handledData?.toDate && {
          toDate: handledData?.toDate,
        }),
      };
      delete handledData["fromDate"];
      delete handledData["toDate"];
      handledData.date = date;
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
  };

  const parseData = (data) => {
    return data;
  };
  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };
  const { data, loading } = useQuery(
    gql`
      ${LIST_OPERATIONS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          type: listType.toUpperCase(),
          ...(search.code && { code: search.code }),
          ...(search.date && { date: search.date }),
          ...(search.requestCode && {
            ...(listType.toUpperCase() === "RCVD"
              ? { requestCode: search.requestCode }
              : { shipmentCode: search.requestCode }),
          }),
          ...(search.branchId && {
            branchId: { value: search.branchId },
          }),
          ...(search.warehouseId && { warehouseId: search.warehouseId }),
          ...(search.transactionTypeId && {
            transactionTypeId: search.transactionTypeId,
          }),
          // ...(search.date
          //   ? { date: search.date }
          //   : date[0] !== null && {
          //       date: {
          //         ...(date[0] && { fromDate: date[0] }),
          //         ...(date[1] && { toDate: date[1] }),
          //       },
          //     }),
          approved: search.approved,
        },
      },
      onCompleted: () => {
        setNoData(false);
      },
    }
  );

  const listOperations = data?.listOperations?.data;

  const icons = [
    {
      id: "filterList",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
    },
    {
      id: "add",
      action: () => pushUrl(props, `/admin/operations/create/${listType}`),
      permission: `freight.operation_${listType}.create`,
    },
  ];
  const tableHeaders = [
    {
      name: "code",
    },
    {
      name: "date",
    },
    {
      name: "branch",
    },
    {
      ...(listType.toUpperCase() === "RCVD"
        ? { name: "request" }
        : { name: "shipment" }),
    },
    {
      name: "wareHouse",
    },
    {
      name: "totalPackages",
    },
    {
      name: "approved",
    },
  ];
  if (listType.toUpperCase() === "RCVD") {
    const insertIndex = tableHeaders.length - 1; // Index before the last element
    tableHeaders.splice(
      insertIndex,
      0,
      { name: "transactionType" },
      { name: "notes" }
    );
  }
  return (
    <RootStyleList>
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        type={listType}
        path={props.match.path}
        empty={listOperations?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  startText={t("date")}
                  value={date}
                  onChangeValue={(value) => setdate(value)}
                  resetDate={resetdate}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>

              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"requestCode"}
                  label={
                    listType.toUpperCase() === "RCVD"
                      ? t("requestCode")
                      : t("shipmentCode")
                  }
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"approved"}
                  label={t("approved")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("false"), value: false },
                    { key: t("true"), value: true },
                  ]}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ListBranches
                  control={control}
                  errors={errors}
                  name={"branchId"}
                  defaultValue={autocompleteValues.branch}
                  skipDefaultBranch={true}
                  skip={noData}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"warehouseId"}
                  label={t("wareHouse")}
                  parseData={(data) => parseData(data)}
                  query={LIST_WAREHOUSE_DROPDOWN.query}
                  defaultValue={autocompleteValues.warehouse}
                  skip={noData}
                />
              </Grid>

              {/* <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"transactionTypeId"}
                  label={t("transactionType")}
                  parseData={(data) => parseData(data)}
                  query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                  defaultValue={autocompleteValues.transactionType}
                  skip={noData}
                />
              </Grid> */}
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={tableHeaders}
        tableBody={
          <TableBody>
            {listOperations &&
              listOperations?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <CellLink pathname={`/admin/operations/${row?.id}`}>
                      {row?.code}
                    </CellLink>
                    <FixedTableCell>
                      {dateFormat(row?.operationDate)}
                    </FixedTableCell>
                    <CellLink pathname={`/admin/branches/${row?.branch?.id}`}>
                      {row?.branch?.name}
                    </CellLink>
                    {listType.toUpperCase() === "RCVD" ? (
                      <CellLink
                        pathname={`/admin/requests/${row?.request?.id}`}
                      >
                        {row?.request?.code}
                      </CellLink>
                    ) : (
                      <CellLink
                        pathname={`/admin/shipments/${row?.shipment?.id}`}
                      >
                        {row?.shipment?.code}
                      </CellLink>
                    )}
                    <CellLink
                      pathname={`/admin/warehouse/${row?.warehouse?.id}`}
                    >
                      {row?.warehouse?.name}
                    </CellLink>
                    {listType.toUpperCase() === "RCVD" ? (
                      <FixedTableCell>
                        {row.request?.detailsCount}
                      </FixedTableCell>
                    ) : (
                      <FixedTableCell>{row.manifests?.length}</FixedTableCell>
                    )}
                    {listType.toUpperCase() === "RCVD" && (
                      <>
                        <CellLink
                          pathname={`/admin/transaction-types/${row?.transactionType?.id}`}
                        >
                          {row?.transactionType?.name}
                        </CellLink>

                        <FixedTableCell>{row.remarks}</FixedTableCell>
                      </>
                    )}
                    <FixedTableCell>
                      {row?.approved ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>

                    {/* <FixedTableCell>
                      {row?.transactionType?.name}
                    </FixedTableCell> */}
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listOperations?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listOperations ? 0 : search.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </RootStyleList>
  );
};

export default OperationList;
