import React from "react";
import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { DELETE_ROLE, ROLE_ID } from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import PermissionSection from "./PermissionSection";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";

const RoleView = (props) => {
  const { t } = useTranslation();
  const roleId = props.match.params.id?.trim();
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const { loading, data } = useQuery(
    gql`
      ${ROLE_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,

      variables: { id: parseInt(roleId) },
    }
  );

  const permission = data?.role?.permissions;
  const id = data?.role?.id;

  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/roles/${roleId}/edit`),
      permission:
        (Globals.user.isSuper || data?.role?.code !== "ADMN") &&
        "core.role.update",
    },
    {
      id: "copy",
      action: () => pushUrl(props, `/admin/roles/${roleId}/copy`),
      permission: "core.role.create",
    },
    {
      id: "delete",
      action: handleOpenDialog,
      permission: data?.role.usersCount === 0,
    },
  ];
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.role ? (
    <NotFound />
  ) : (
    <>
      {openDialog && (
        <MutationWithDialog
          mutaion={DELETE_ROLE.query}
          setOpenDelete={setOpenDialog}
          openDelete={openDialog}
          dialogTitle={t("deleteRecord")}
          dialogContent={t("deleteRecordMessage")}
          mutaionProps={{
            variables: { id: parseInt(id) },
          }}
          onCompleted={() => pushUrl(props, `/admin/roles`)}
          onCompleteMessage={t("successfullyDeletedRecord")}
        />
      )}
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>

      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair title={t("code")} value={data?.role?.code} />
          <KeyValuePair title={t("name")} value={data?.role?.name} />
        </Paper>
        {permission && <PermissionSection permission={permission} id={id} />}
      </RootStyleView>
    </>
  );
};

export default RoleView;
