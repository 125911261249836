import React, { useEffect, useState } from "react";
import {
  Paper,
  Collapse,
  Stack,
  IconButton,
  Alert,
  Button,
  Box,
  useTheme,
} from "@mui/material";
import {
  APPROVE_MUTATION,
  CURRENCY_ID,
  GL_APPROVE_MUTATION,
  QUERY_ID,
  QUERY_TYPE,
  SAVE_QUERY,
} from "./Graphql";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_CURRENCIES_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import MUIDate from "../HOC/MUI/MUIDate";
import { dateFormat } from "../../helpers/dateFunctions";
import {
  AddCircleOutline,
  AttachMoney,
  Done,
  DoneAll,
} from "@mui/icons-material";
import { CustomerPaymentMethod } from "../HOC/CustomComponents/CustomListDialog/RequestData";
import moment from "moment";
import LineForm from "./LineForm";
import BillTables from "./BillTables";
import CustomButton from "../HOC/MUI/CustomButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { BillProvider } from "./Context/BillContext";
import { useAccountType } from "../../Hooks/useAccountType";
import CustomList from "../HOC/CustomComponents/CustomListDialog/CustomList";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import TaxForm from "./TaxForm";
import LongMenu from "../../Layout/MenuAppBar";

const BillForm = (props) => {
  const { requestData, onSave } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [pathURL] = useState(props?.match?.path ?? props?.pathURL);
  const initFromDate = moment(new Date())
    .locale("en")
    .subtract("month")
    .add("day")
    .format("YYYY-MM-DD");
  const [date, setDate] = useState({
    bill: initFromDate,
    invoice: initFromDate,
    reference: null,
  });
  let pathname = pathURL;
  if (pathURL.includes("invoice")) {
    pathname = "invoice";
  } else {
    pathname = "bill";
  }

  const capitalPathname = pathname.charAt(0).toUpperCase() + pathname.slice(1);

  const [id, setId] = useState(parseInt(props?.match?.params?.id));
  const [dialogDetails, setDialogDetails] = useState({
    state: false,
    function: null,
    confirmAction: true,
    title: "",
    content: "",
    data: null,
  });
  const [pickedRequest, setPickedRequest] = React.useState();

  const [updateTax, setUpdateTax] = useState(null);
  const [totalSum, setTotalSum] = React.useState({
    totalTaxAmount: 0,
    totalAmount: 0,
    totalAmountInLocalCurrency: 0,
  });

  const [autocompleteValues, setAutocompleteValues] = useState({
    customer: null,
    employee: null,
    vendor: null,
    customAgent: null,
    agency: null,
    carrier: null,
    paymentType: null,
    accountType: null,
    documentType: null,
    transactionType: null,
    branch: null,
    currency: null,
    priceMode: null,
    chargeElement: null,
    tax: null,
  });
  const { handleSubmit, control, formState, setValue, setError, watch } =
    useForm();
  const { errors } = formState;
  const [lineId, setLineId] = useState(null);
  const [tax, setTax] = useState(null);
  const [disabled, setDisabled] = useState({
    formEdit: false,
  });

  const [exchangeRate, setExchangeRate] = React.useState({
    updatable: true,
  });

  const accountType = watch("accountType");
  const documentType = watch("documentType");
  const dynamicAutocompleteProps = useAccountType(
    accountType,
    autocompleteValues,
    documentType
  );
  const dynamicId = watch(dynamicAutocompleteProps.name);
  const ACCOUNT_TYPE = QUERY_TYPE(dynamicAutocompleteProps.queryName);

  const SAVE = SAVE_QUERY(capitalPathname);

  const [save, { loading: saveLoading }] = useMutation(
    gql`
      ${SAVE.query}
    `
  );
  const GET_BY_ID = QUERY_ID(pathname);
  const {
    data,
    loading: updateLoading,
    refetch,
  } = useQuery(
    gql`
      ${GET_BY_ID.query}
    `,
    {
      skip: !id,
      variables: { id: id },
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        const billData = data?.[pathname];

        setDisabled((prev) => ({
          ...prev,
          formEdit: true,
        }));
        const params = [
          "id",
          "code",
          "exchangeRate",
          "referenceNumber",
          "referenceDate",
          "remarks",
          pathname === "bill" ? "billDate" : "invoiceDate",
        ];
        params.forEach((i) => {
          billData[i] !== null && setValue(i, billData[i]);
        });
        setDate((prev) => ({
          ...prev,
          bill: billData.billDate,
          reference: billData.referenceDate,
          invoice: billData?.invoiceDate,
        }));

        setLine(billData.lines);
        setAutocompleteValues((prev) => ({
          ...prev,
          customer: billData.customer,
          employee: billData.employee,
          vendor: billData.vendor,
          customAgent: billData.agent,
          agency: billData.agency,
          carrier: billData.carrier,
          paymentType: billData.paymentType,
          accountType: billData.accountType,
          documentType: billData.documentType,
          transactionType: billData.transactionType,
          branch: billData.branch,
          currency: billData.currency,
        }));
        if (billData?.shipment !== null) {
          setValue("shipmentId", billData?.shipment?.code);
          setPickedRequest(billData?.shipment);
        }

        if (billData?.request !== null) {
          setValue("requestId", billData?.request?.code);
          setPickedRequest(billData?.request);
        }
        setTotalSum((prev) => ({
          ...prev,
          totalTaxAmount: billData?.totalTaxAmount,
          totalAmount: billData?.totalAmount,
          totalAmountInLocalCurrency: billData?.totalAmountInLocalCurrency,
        }));
      },
    }
  );
  const billData = data?.[pathname];
  useEffect(() => {
    if (billData?.shipment !== null) {
      setValue("shipmentId", billData?.shipment?.code);
      setPickedRequest(billData?.shipment);
    }

    if (billData?.request !== null) {
      setValue("requestId", billData?.request?.code);
      setPickedRequest(billData?.request);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("documentType")]);

  useQuery(
    gql`
      ${CURRENCY_ID.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !watch("currencyId"),
      variables: {
        id: parseInt(watch("currencyId")),
        input: {},
      },
      onCompleted: (data) => {
        const exchangeRate = data.currency.exchangeRate;
        watch("exchangeRate") === "" &&
          setValue("exchangeRate", exchangeRate?.rate);
        setExchangeRate((prev) => ({
          ...prev,
          updatable: exchangeRate.updatable,
        }));
      },
    }
  );

  useQuery(
    gql`
      ${ACCOUNT_TYPE.query}
    `,

    {
      skip:
        !dynamicAutocompleteProps.queryName ||
        !dynamicId ||
        dynamicAutocompleteProps.queryName === "employee",
      fetchPolicy: "no-cache",
      variables: { id: dynamicId },
      onCompleted: (data) => {
        const queryName = dynamicAutocompleteProps.queryName;

        if (watch("currencyId") === "") {
          setValue("exchangeRate", "");
          setAutocompleteValues((prev) => ({
            ...prev,
            currency: data?.[queryName]?.currency,
          }));
        }
      },
    }
  );

  const [line, setLine] = useState(data?.[pathname].lines ?? []);

  const APPROVE = APPROVE_MUTATION(capitalPathname);

  const [approveMutation, { loading: approveLoad }] = useMutation(
    gql`
      ${APPROVE.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        // setCollectionData(data["approveCollection"]);
      },
    }
  );

  const GL_APPROVE = GL_APPROVE_MUTATION(capitalPathname);

  const [glApproveMutation, { loading: glApproveLoad }] = useMutation(
    gql`
      ${GL_APPROVE.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        // setCollectionData(data["glApproveCollection"]);
      },
    }
  );

  useEffect(() => {
    if (requestData) {
      setAutocompleteValues((prev) => ({
        ...prev,

        documentType: {
          code: "RQST",
          name: "Request",
        },
        accountType:
          pathname === "bill"
            ? {
                code: "VNDR",
                name: "Vendor",
              }
            : {
                code: "CUST",
                name: "Customer",
              },
        ...(pathname === "bill" && { vendor: requestData?.vendor }),
        ...(pathname === "invoice" && { customer: requestData?.customer }),
        paymentType: { code: "CREDIT", name: t("paymentCredit") },
        // currency: requestData?.currency,
      }));
      setValue("requestId", requestData?.code);
      setPickedRequest(requestData);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData]);
  const mutation = (data) => {
    save({
      variables: {
        input: {
          ...data,
          ...(data.billDate && {
            billDate: dateFormat(data.billDate),
          }),
          ...(data.invoiceDate && {
            invoiceDate: dateFormat(data.invoiceDate),
          }),
          ...(data.referenceDate && {
            referenceDate: dateFormat(data.referenceDate),
          }),
          ...(id && { id: id }),
          ...(pickedRequest && watch("documentType") === "SHMNT"
            ? { shipmentId: pickedRequest?.id, requestId: null }
            : { requestId: pickedRequest?.id, shipmentId: null }),
        },
      },
    })
      .then((data) => {
        setId(data?.data?.[`save${capitalPathname}`]?.id);
        setDisabled((prev) => ({
          ...prev,
          formEdit: true,
        }));
        pushUrl(
          props,
          `/admin/${pathname}s/${
            data?.data?.[`save${capitalPathname}`]?.id
          }/edit`
        );
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      });
  };

  const approve = () => {
    approveMutation({
      variables: {
        id: id,
      },
    })
      .then(() => {
        closeConfirmationDialog();
        enqueueSnackbar(t("successfullyApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        onSave && onSave();
        pushUrl(props, `/admin/${pathname}s/${id}`);
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const glApprove = () => {
    glApproveMutation({
      variables: {
        id: id,
      },
    })
      .then((data) => {
        closeConfirmationDialog();
        enqueueSnackbar(t("successfullyGlApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        closeConfirmationDialog();

        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const parseData = (data) => {
    return data;
  };

  const setSelect = (obj) => {
    setPickedRequest(obj?.data);
    if (watch("documentType") === "SHMNT") {
      setValue("shipmentId", obj?.data?.code);
      setValue("requestId", "");
    } else {
      setValue("requestId", obj?.data?.code);
      setValue("shipmentId", "");
    }
  };
  const closeConfirmationDialog = () => {
    setDialogDetails((prev) => ({
      ...prev,
      state: false,
    }));
    setAutocompleteValues((prev) => ({
      ...prev,
      tax: null,
    }));
    setUpdateTax(null);
  };
  const openPickedRequestDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: null,
      content: (
        <CustomList
          setSelected={(obj) => {
            setSelect(obj);
          }}
          filters={requestFilters}
          selected={pickedRequest}
          pathType={watch("documentType") === "SHMNT" ? "Shipment" : "Request"}
          done={() => {
            closeConfirmationDialog();
          }}
        />
      ),
      confirmAction: true,
      function: null,
    }));
  };

  const openApproveDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: t("approve"),
      content: t("approveRecordConfirmationMessage"),
      confirmAction: true,
      function: approve,
    }));
  };
  const openGlApproveDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: t("glApprove"),
      content: t("glApproveRecordMessage"),
      confirmAction: true,
      function: glApprove,
    }));
  };

  const openEditDialog = (data) => {
    setDialogDetails((prev) => ({
      state: true,
      data: data,
      title: t("updateRecord"),
      content: t("updateRecordMessage"),
      confirmAction: true,
      function: "edit",
    }));
  };

  const closeLineDialog = () => {
    closeConfirmationDialog();
    // setUpdateLine(null);
  };

  const closeTaxDialog = () => {
    closeConfirmationDialog();
  };

  const onSubmit = (data) => {
    const formChange =
      billData &&
      ((data.documentType &&
        billData.documentType?.code !== data.documentType) ||
        (data.requestId && billData.request?.code !== data.requestId) ||
        (data.shipmentId && billData.shipment?.code !== data.shipmentId));

    for (const key in data) {
      if (data[key] === "" && key === "code") {
        delete data[key];
      }
      if (["exchangeRate"].includes(key)) {
        data[key] = parseFloat(data[key]);
      }
      if (
        [
          "customerId",
          "vendorId",
          "customAgentId",
          "employeeId",
          "agencyId",
          "carrierId",
          "requestId",
          "shipmentId",
        ].includes(key) &&
        !id &&
        data[key] === ""
      ) {
        delete data[key];
      }
      if (data[key] === "") {
        data[key] = null;
        continue;
      }
    }

    if (line.length !== 0 && formChange) {
      openEditDialog(data);
    } else {
      mutation(data);
    }
  };

  const openTaxDialog = (subzone) => {
    setDialogDetails((prev) => ({
      state: true,
      title: "",
      content: <TaxForm />,
      confirmAction: true,
      function: null,
    }));

    subzone && setUpdateTax(subzone);
  };
  const openLineDialog = (subzone) => {
    setDialogDetails((prev) => ({
      state: true,
      title: "",
      content: (
        <LineForm
          id={id}
          data={data}
          pathname={pathname}
          capitalPathname={capitalPathname}
          updateLine={subzone}
          line={line}
          setLines={setLine}
          closeDialog={closeLineDialog}
          autocompleteValues={autocompleteValues}
          setAutocompleteValues={setAutocompleteValues}
          setTotalSum={setTotalSum}
        />
      ),
      confirmAction: true,
      function: null,
    }));
  };
  const body = (
    <Paper spacing={2} component={"form"} onSubmit={handleSubmit(onSubmit)}>
      <Stack sx={{ position: "relative" }}>
        {disabled.formEdit && (
          <Box
            className={classesForm.overlay}
            sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
          ></Box>
        )}
        <Grid
          container
          spacing={2}
          className={clsx(classesForm.spacing)}
          sx={{ p: 1 }}
        >
          <Grid xs={12} sm={3} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"code"}
              label={t("billCode")}
            />
          </Grid>
          <Grid xs={12} sm={3} alignItems="flex-start">
            {pathname === "bill" ? (
              <MUIDate
                name="billDate"
                label={t("billDate")}
                rules={{ required: t("fieldIsRequired") }}
                control={control}
                value={date.bill}
                defaultValue={date.bill}
                onChange={(date) => {
                  setDate((prev) => ({ ...prev, bill: date }));
                  setPickedRequest(null);
                  setValue("requestId", "");
                  setValue("shipmentId", "");
                }}
                onError={(resone, value) => {
                  setError("billDate", { message: resone });
                }}
              />
            ) : (
              <MUIDate
                name="invoiceDate"
                label={t("billDate")}
                rules={{ required: t("fieldIsRequired") }}
                control={control}
                value={date.invoice}
                defaultValue={date.invoice}
                onChange={(date) => {
                  setDate((prev) => ({ ...prev, invoice: date }));
                  setPickedRequest(null);
                  setValue("requestId", "");
                  setValue("shipmentId", "");
                }}
                onError={(resone, value) => {
                  setError("invoiceDate", { message: resone });
                }}
              />
            )}
          </Grid>
          <Grid sm={3} xs={12}>
            <ListBranches
              control={control}
              errors={errors}
              name={"branchId"}
              rules={{ required: t("fieldIsRequired") }}
              defaultValue={autocompleteValues.branch}
              // skipDefaultBranch={true}
            />
          </Grid>
          <Grid sm={3} xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              parseData={(data) => parseData(data)}
              skip={!watch("branchId")}
              name={"transactionTypeId"}
              label={t("transactionType")}
              rules={{ required: t("fieldIsRequired") }}
              selectFirst={true}
              query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
              variables={{
                input: {
                  branchId: {
                    value: watch("branchId"),
                    includeNull: true,
                  },

                  type: pathname === "bill" ? "BILL" : "INVC",
                },
              }}
              defaultValue={autocompleteValues.transactionType}
            />
          </Grid>
          <Grid xs={12} sm={3} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"referenceNumber"}
              label={t("referenceNumber")}
              // rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12} sm={3} alignItems="flex-start">
            <MUIDate
              name="referenceDate"
              label={t("referenceDate")}
              control={control}
              value={date.reference}
              defaultValue={""}
              onChange={(date) =>
                setDate((prev) => ({ ...prev, reference: date }))
              }
              onError={(resone, value) => {
                setError("deliveryDate", { message: resone });
              }}
            />
          </Grid>
          <Grid xs={12} sm={3} alignItems="flex-start">
            <CustomAutocomplete
              valueKey="code"
              control={control}
              errors={errors}
              disabled={Boolean(requestData)}
              name={"documentType"}
              label={t("documentType")}
              onChangeValue={(e) => {
                setPickedRequest(null);
                setValue("requestId", "");
                setValue("shipmentId", "");
              }}
              parseData={(data) => parseData(data)}
              rules={{ required: t("fieldIsRequired") }}
              variables={{ input: { code: "FR_DOCUMENT_TYPE" } }}
              query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
              defaultValue={autocompleteValues.documentType}
            />
          </Grid>

          <Grid xs={12} sm={3} alignItems="flex-start">
            <CustomAutocomplete
              valueKey="code"
              control={control}
              errors={errors}
              name={"paymentType"}
              label={t("paymentType")}
              parseData={(data) => parseData(data)}
              rules={{ required: t("fieldIsRequired") }}
              variables={{ input: { code: "PAYMENT_TYPES" } }}
              query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
              defaultValue={autocompleteValues.paymentType}
            />
          </Grid>

          <Grid xs={12} sm={6} alignItems="flex-start">
            <CustomAutocomplete
              valueKey="code"
              control={control}
              errors={errors}
              disabled={Boolean(requestData)}
              name={"accountType"}
              label={t("accountType")}
              parseData={(data) => parseData(data)}
              rules={{ required: t("fieldIsRequired") }}
              variables={{ input: { code: "FR_ACCOUNT_TYPE" } }}
              query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
              defaultValue={autocompleteValues.accountType}
              onChangeValue={(e) => {
                setPickedRequest(null);
                setValue("requestId", "");
                setValue("shipmentId", "");
                setValue("currencyId", "");
                setValue(dynamicAutocompleteProps.name, "");
              }}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            {/* {watch("accountType") && ( */}
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={!watch("accountType") || Boolean(requestData)}
              name={dynamicAutocompleteProps.name} // Dynamically set name
              label={dynamicAutocompleteProps.label}
              parseData={(data) => parseData(data)}
              query={dynamicAutocompleteProps.query}
              rules={{ required: t("fieldIsRequired") }}
              defaultValue={dynamicAutocompleteProps.defaultValue}
              variables={{
                input: dynamicAutocompleteProps?.input,
              }}
              onChangeValue={(e) => {
                setPickedRequest(null);
                setValue("requestId", "");
                setValue("shipmentId", "");
                setValue("currencyId", "");
                // setDisabled((prev) => ({
                //   ...prev,
                //   currency: false,
                // }));
              }}
            />
            {/* )} */}
          </Grid>
          <Grid xs={12} sm={4} alignItems="flex-start">
            <Stack direction={"row"}>
              <ControlMUItextField
                control={control}
                errors={errors}
                name={
                  watch("documentType") === "RQST" ? "requestId" : "shipmentId"
                }
                readOnly={true}
                label={t("code")}
                rules={{
                  required:
                    watch("documentType") === "ACCT"
                      ? false
                      : t("fieldIsRequired"),
                }}
              />

              <IconButton
                onClick={() => openPickedRequestDialog()}
                size="large"
                disabled={
                  !watch("documentType") ||
                  watch("documentType") === "ACCT" ||
                  !watch("accountType") ||
                  // watch("accountType") !== "AGNCY" ||
                  // dynamicAutocompleteProps.name !== "agencyId"

                  Boolean(requestData)
                }
              >
                <AddCircleOutline />
              </IconButton>
            </Stack>
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"currencyId"}
              label={t("currency")}
              onChangeValue={(e) => {
                setValue("exchangeRate", "");
              }}
              rules={{ required: t("fieldIsRequired") }}
              parseData={(data) => parseData(data)}
              query={LIST_CURRENCIES_DROPDOWN.query}
              defaultValue={autocompleteValues.currency}
            />
          </Grid>
          <Grid xs={12} sm={4} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              type="number"
              name={"exchangeRate"}
              label={t("exchangeRate")}
              rules={{ required: t("fieldIsRequired") }}
              disabled={!exchangeRate?.updatable}
            />
          </Grid>
          <Grid xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"remarks"}
              label={t("notes")}
              rows={2}
            />
          </Grid>
          {pickedRequest && (
            <Grid xs={12} sm={12} alignItems="flex-start">
              <CustomerPaymentMethod
                classes={classesForm}
                picked={pickedRequest}
              />
            </Grid>
          )}
          {/* </>
        )} */}
        </Grid>
      </Stack>

      <Grid sm={12} xs={12} container justifyContent="flex-end">
        {!disabled.formEdit && (
          <CustomButton
            customcolor={theme.palette.success.main}
            type="submit"
            className={classesForm.button}
            disabled={saveLoading || disabled.formEdit || billData?.approved}
            variant="contained"
            loading={saveLoading}
            startIcon={!saveLoading && <Done />}
          >
            {!saveLoading && t("save")}
          </CustomButton>
        )}
        {disabled.formEdit && (
          <Button
            disabled={saveLoading || billData?.approved || line.length === 0}
            className={classesForm.button}
            variant="contained"
            onClick={openApproveDialog}
            name="approved"
            startIcon={<DoneAll />}
          >
            {t("approve")}
          </Button>
        )}
        {billData?.approved && (
          <Button
            disabled={billData?.glApproved}
            className={classesForm.glApprovedButton}
            variant="contained"
            onClick={openGlApproveDialog}
            name="glApproved"
            startIcon={<AttachMoney />}
          >
            {t("glApprove")}
          </Button>
        )}
      </Grid>
    </Paper>
  );

  let DOM;
  if (id) {
    DOM = data ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }
  const requestFilters =
    watch("documentType") === "SHMNT"
      ? {
          ...(dynamicAutocompleteProps.name === "agencyId" && {
            agencyId: watch("agencyId"),
          }),
          ...(dynamicAutocompleteProps.name === "carrierId" && {
            carrierId: watch("carrierId"),
          }),
          shipmentDate: {
            toDate:
              pathname === "bill"
                ? dateFormat(date.bill)
                : dateFormat(date.invoice),
          },
        }
      : {
          ...(dynamicAutocompleteProps.name === "customerId" && {
            customerId: watch("customerId"),
          }),
          ...(dynamicAutocompleteProps.name === "vendorId" && {
            vendorId: watch("vendorId"),
          }),
          requestDate: {
            toDate:
              pathname === "bill"
                ? dateFormat(date.bill)
                : dateFormat(date.invoice),
          },
        };

  const value = {
    setSelect,
    pickedRequest,
    requestFilters,
    totalSum,
    setTotalSum,
    id,
    refetch,
    capitalPathname,
    closeTaxDialog,
    updateTax,
    setUpdateTax,
    openTaxDialog,
    openLineDialog,
    line,
    setLine,
    lineId,
    setLineId,
    tax,
    setTax,
    loading: saveLoading,
    autocompleteValues,
    setAutocompleteValues,
    billData: data?.[pathname],
  };

  const icons = [
    {
      id: "print",
      action: () =>
        window.open(
          `${window.location.origin}/report/print/${pathname}/${watch("id")}`
        ),
      disabled: !watch("id"),
    },
  ];
  return updateLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data && id ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={pathURL}>
        <LongMenu icons={icons} />
      </TitleAppBar>
      <BillProvider value={value}>
        {dialogDetails.state && (
          <CustomDialog
            styleContent={
              dialogDetails.title == null
                ? { overflow: "hidden", padding: 0 }
                : dialogDetails.title === "" && { overflow: "hidden" }
            }
            title={dialogDetails.title}
            fullWidth
            maxWidth={dialogDetails.title == null ? "md" : "xs"}
            onClose={closeConfirmationDialog}
            content={dialogDetails.content}
            open={dialogDetails.state}
            actions={
              dialogDetails.function !== null && (
                <>
                  <Button color="primary" onClick={closeConfirmationDialog}>
                    {dialogDetails.confirmAction ? t("cancel") : t("close")}
                  </Button>
                  {dialogDetails.confirmAction && (
                    <Button
                      color="primary"
                      disabled={approveLoad || glApproveLoad}
                      onClick={() => {
                        if (dialogDetails.function === "edit") {
                          closeConfirmationDialog();
                          mutation(dialogDetails.data);
                        } else {
                          dialogDetails.function();
                        }
                      }}
                    >
                      {approveLoad || glApproveLoad ? (
                        <ButtonLoading />
                      ) : (
                        t("confirm")
                      )}
                    </Button>
                  )}
                </>
              )
            }
          >
            {dialogDetails.function === null && ""}
          </CustomDialog>
        )}
        {id && disabled.formEdit ? (
          <Grid sm={12} xs={12}>
            <Alert
              severity="warning"
              action={
                !billData?.approved && (
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setDisabled((prev) => ({
                        ...prev,
                        formEdit: false,
                      }));
                    }}
                  >
                    {t("update")}
                  </Button>
                )
              }
            >
              {billData?.approved
                ? t("updateRecordsForbidden")
                : t("updateRecord")}
            </Alert>
          </Grid>
        ) : (
          ""
        )}
        <Stack spacing={2} p={2}>
          {DOM}
        </Stack>

        <Grid
          container
          spacing={2}
          mx={2}
          my={0}
          className={classesForm.mainForm}
          sx={{ position: "relative" }}
        >
          {(!id || !disabled?.formEdit) && (
            <Box className={classesForm.overlay}></Box>
          )}
          <BillTables />
        </Grid>
      </BillProvider>
    </RootStyleForm>
  );
};

export default BillForm;
