import React from "react";
import { Collapse, Typography, Button, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { BANK, SAVE_BANK } from "./Graphql";
import { useMutation, gql, useQuery } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import Grid from "@mui/material/Unstable_Grid2";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import { RootStyleForm } from "../../GlobalStyles/FormStyle";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";

const BankForm = (props) => {
  const { t } = useTranslation();
  const { id } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState, setValue, setError } = useForm({
    defaultValues: {
      active: true,
    },
  });
  const { errors } = formState;

  const { loading } = useQuery(
    gql`
      ${BANK.query}
    `,
    {
      skip: !id,
      notifyOnNetworkStatusChange: true,
      variables: { id: parseInt(id) },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const bankData = data.bank;
        const bankParams = ["code", "name", "active"];
        bankParams?.forEach((i) => {
          bankData[i] && setValue(i, bankData[i]);
        });
        setValue("active", bankData["active"]);
      },
    }
  );

  const [saveBank, { loading: saveBankLoading }] = useMutation(
    gql`
      ${SAVE_BANK.query}
    `
  );

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === undefined || data[key] === "" || data[key] === null) {
        delete data[key];
      }
    }
    saveBank({
      variables: {
        input: {
          ...data,
          ...(id && { id: id }),
        },
      },
    })
      .then((data) => {
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        props.handelCloseDialog();
        props.refetch();
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };

  const body = (
    <RootStyleForm>
      {loading ? (
        <FullScreenLoading minHeight="100px" />
      ) : (
        <Grid container justifyContent="flex-start" aligns="center" spacing={2}>
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"code"}
            label={t("code")}
            margin="normal"
          />
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"name"}
            margin="normal"
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
      )}
    </RootStyleForm>
  );

  const title = (
    <Grid container justifyContent="space-between" alignItems={"center"}>
      <Typography variant="h6" color={"text.primary"}>
        {t("addBank")}
      </Typography>
      <Box>
        <MuiSwitch
          edge="end"
          name="active"
          label={t("active")}
          control={control}
        />
      </Box>
    </Grid>
  );
  return (
    <RootStyleForm>
      {" "}
      {props.open && (
        <CustomDialog
          fullWidth
          maxWidth="xs"
          open={props.open}
          onClose={props.handelCloseDialog}
          title={title}
          content={body}
          actions={
            <>
              <Button onClick={props.handelCloseDialog}>{t("cancel")}</Button>
              <Button onClick={handleSubmit(onSubmit)}>
                {saveBankLoading ? <ButtonLoading /> : t("confirm")}
              </Button>
            </>
          }
        ></CustomDialog>
      )}
    </RootStyleForm>
  );
};

export default BankForm;
