import { useQuery, gql } from "@apollo/client";
import { Icon, Paper } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { DELETE_CHARGE_TYPES, CHARGE_TYPES } from "./Graphql";
import Grid from "@mui/material/Unstable_Grid2";
import { Globals } from "../HOC/Classes/Globals";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import ChargeTypeTables from "./ChargeTypeTables";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";

const ChargeTypeView = (props) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = React.useState(false);
  const chargeTyped = parseInt(props.match.params.id);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const { data, loading } = useQuery(
    gql`
      ${CHARGE_TYPES.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: { id: chargeTyped },
      onCompleted: (data) => {},
    }
  );
  const user = Globals.user;
  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/charge-types/${chargeTyped}/edit`),
      permission: user?.hasPermission("freight.charge_type.update"),
    },
    {
      id: "delete",
      action: handleOpenDialog,
      permission: user?.hasPermission("freight.charge_type.delete"),
    },
  ];
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.chargeType ? (
    <NotFound />
  ) : (
    <>
      {" "}
      {openDialog && (
        <MutationWithDialog
          mutaion={DELETE_CHARGE_TYPES.query}
          setOpenDelete={setOpenDialog}
          openDelete={openDialog}
          dialogTitle={t("deleteRecord")}
          dialogContent={t("deleteRecordMessage")}
          mutaionProps={{
            variables: { id: parseInt(chargeTyped) },
          }}
          onCompleted={() => pushUrl(props, `/admin/charge-types`)}
          onCompleteMessage={t("successfullyDeletedRecord")}
        />
      )}
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>
      <RootStyleView spacing={2} p={2} justifyContent="center">
        {loading ? (
          <FullScreenLoading minHeight="15%" />
        ) : (
          <>
            <Paper container className={classesView.paper} component={Grid}>
              <KeyValuePair
                md={6}
                title={t("code")}
                value={data?.chargeType?.code}
              />
              <KeyValuePair
                md={6}
                title={t("manifestName")}
                value={data?.chargeType.name}
              />
              <KeyValuePair
                title={t("payable")}
                md={6}
                value={
                  data?.chargeType?.payable ? (
                    <>
                      <Icon className={classesView.iconColor}>
                        check_circle_outline
                      </Icon>
                    </>
                  ) : (
                    <>
                      <Icon color="error">highlight_off</Icon>
                    </>
                  )
                }
              />
              <KeyValuePair
                md={6}
                title={t("payableGLAccount")}
                value={data?.chargeType?.payableGLAccount?.name}
              />
              <KeyValuePair
                md={6}
                title={t("receivable")}
                value={
                  data?.chargeType?.receivable ? (
                    <>
                      <Icon className={classesView.iconColor}>
                        check_circle_outline
                      </Icon>
                    </>
                  ) : (
                    <>
                      <Icon color="error">highlight_off</Icon>
                    </>
                  )
                }
              />
              <KeyValuePair
                md={6}
                title={t("receivableGLAccount")}
                value={data?.chargeType?.receivableGLAccount?.name}
              />

              <KeyValuePair
                title={t("active")}
                md={6}
                value={
                  data?.chargeType?.active ? (
                    <>
                      <Icon className={classesView.iconColor}>
                        check_circle_outline
                      </Icon>
                    </>
                  ) : (
                    <>
                      <Icon color="error">highlight_off</Icon>
                    </>
                  )
                }
              />
            </Paper>
          </>
        )}

        {data?.chargeType?.elements && (
          <ChargeTypeTables chargeTypeList={data.chargeType?.elements} />
        )}
      </RootStyleView>
    </>
  );
};

export default ChargeTypeView;
