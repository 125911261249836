import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import { Checkbox, Icon, TableRow } from "@mui/material";
import CellLink from "../../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";

const PREFIX = "CustomTableRow";

const classes = {
  commodityTable_checkbox: `${PREFIX}-commodityTable_checkbox`,
  iconColor: `${PREFIX}-iconColor`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(TableRow)(({ theme }) => ({
  [`& .${classes.commodityTable_checkbox}`]: {
    zIndex: "100",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: 0,
    left: 0,
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const CustomTableRow = (props) => {
  const { commodity, onChangeCheck, labelId, prepareShipment } = props;
  const commodityDetails = commodity?.commodity;

  return (
    <Fragment>
      <Root
        hover
        role="checkbox"
        aria-checked={commodity.select}
        tabIndex={-1}
        selected={commodity.select}
      >
        {!prepareShipment ? (
          <FixedTableCell
            className={classes.commodityTable_checkbox}
            padding="checkbox"
          >
            <Checkbox
              onChange={(event) => onChangeCheck(event)}
              checked={commodity.select}
              inputProps={{ "aria-labelledby": labelId }}
            />
          </FixedTableCell>
        ) : (
          <FixedTableCell>
            {commodity.select ? (
              <Icon className={classes.iconColor}>check_circle_outline</Icon>
            ) : (
              <Icon color="error">highlight_off</Icon>
            )}
          </FixedTableCell>
        )}

        <CellLink
          component="th"
          scope="commodity"
          pathname={`/admin/commodities/${commodityDetails.id}`}
        >
          {commodityDetails?.code}
        </CellLink>
        <FixedTableCell>{commodityDetails?.name}</FixedTableCell>
      </Root>
    </Fragment>
  );
};

export default CustomTableRow;
