import * as gqlb from "gql-query-builder";
export const LIST_REQUESTS = gqlb.query({
  operation: "listRequests",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "totalPackages",
        "requestDate",
        {
          operations: [{ warehouse: ["code", "id", "name"] }],
        },
        {
          branch: ["id", "name", "code"],
        },
        {
          shippingDirection: ["id", "name", "code"],
        },
        {
          shippingMode: ["id", "name", "code"],
        },
        {
          loadingMode: ["id", "name", "code"],
        },
        {
          originPort: ["id", "name", "code"],
        },
        {
          destinationPort: ["id", "name", "code"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListRequestsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});
export const PACKING_REQUESTS = gqlb.mutation({
  operation: "packingRequest",
  fields: ["id"],
  variables: {
    input: {
      type: "PackingRequestInput",
      required: true,
    },
  },
});
