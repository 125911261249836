import React, { useState } from "react";
import {
  Paper,
  Collapse,
  Stack,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { FREIGHT_SETTING, QUERY_ID, SAVE_QUERY } from "./Graphql";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import FormButton from "../CustomComponents/Buttons/FormButton";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_COUNTRIES_DROPDOWN,
  LIST_CURRENCIES_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_STATES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import MUIDate from "../HOC/MUI/MUIDate";
import { dateFormat } from "../../helpers/dateFunctions";
import MuiPhone from "../HOC/MUI/MUIphoneNumber";
import getMobileData, {
  getFormatNumber,
  validNumber,
} from "../../helpers/asYouType";

import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";
const CustomAgentForm = (props) => {
  const pathURL = props.match.path;
  let pathname = pathURL;
  if (pathURL.includes("custom-agents")) {
    pathname = "customAgent";
  } else if (pathURL.includes("carriers")) {
    pathname = "carrier";
  } else {
    pathname = "agency";
  }
  console.log(pathname);
  const [idIssueDate, setIdIssueDate] = useState(null);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(parseInt(props.match.params.id));
  const [active, setActive] = useState(true);

  const [autocompleteValues, setAutocompleteValues] = useState({
    idType: null,
    country: null,
    state: null,
    bank: null,
    glAccount: null,
    branch: null,
    currency: null,
  });
  const {
    handleSubmit,
    control,
    formState,
    setValue,
    setError,
    watch,
    getValues,
  } = useForm();
  const { errors } = formState;
  const capitalPathname = pathname.charAt(0).toUpperCase() + pathname.slice(1);
  const urlPath =
    pathname === "customAgent"
      ? "custom-agents"
      : pathname === "agency"
      ? "agencies"
      : "carriers";

  const SAVE = SAVE_QUERY(capitalPathname);

  const [save, { loading: saveLoading }] = useMutation(
    gql`
      ${SAVE.query}
    `
  );

  const SETTING = FREIGHT_SETTING(pathname);
  const { data: setting, loading } = useQuery(
    gql`
      ${SETTING.query}
    `,

    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {},
    }
  );
  const parentId = setting?.freightSettings?.mainCustomAgentGLAccount?.id;
  const parentIdCarrier = setting?.freightSettings?.mainCarrierGLAccount?.id;
  const parentIdAgency = setting?.freightSettings?.mainAgencyGLAccount?.id;

  const GET_BY_ID = QUERY_ID(pathname);

  const { data, loading: updateLoading } = useQuery(
    gql`
      ${GET_BY_ID.query}
    `,

    {
      skip: !id,
      variables: { id: id },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const customData = data?.[pathname];
        setActive(customData.active);
        setIdIssueDate(customData.idIssueDate);
        const employeeParams = [
          "id",
          "code",
          "name",
          "address",
          "registerationNumber",
          "postalCode",
          "mailBoxNumber",
          "idNumber",
          "idIssueDate",
          "active",
          "email",
          "taxCardNumber",
        ];
        employeeParams.forEach((i) => {
          customData[i] !== null && setValue(i, customData[i]);
        });
        const numbersObj = [
          {
            name: "mobile",
            codeName: "mobileCode",
            value: getMobileData(customData.mobile),
          },
          {
            name: "phone",
            codeName: "phoneCode",
            value: getMobileData(customData.phone),
          },
        ];
        numbersObj.forEach((i) => {
          if (i.value !== null) {
            setValue(i.codeName, i.value.country.toLowerCase());
            setValue(i.name, i.value.nationalNumber);
          }
        });
        setAutocompleteValues((prev) => ({
          ...prev,
          idType: customData.idType,
          country: customData.country,
          state: customData.state,
          bank: customData.bank,
          glAccount: customData.glAccount,
          branch: customData.branch,
          currency: customData.currency,
        }));
      },
    }
  );
  const parseData = (data) => {
    return data;
  };

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActive(active);
  };
  const selectDefaultValue = (data) => {
    return data;
  };
  const onSubmit = (data) => {
    const checkMobiles = validNumber(data.mobile, data.mobileCode);
    const checkPhone = validNumber(data.phone, data.phoneCode);
    let names = [
      {
        name: "mobile",
        validate: checkMobiles.valid,
        message: checkMobiles.message,
      },
      {
        name: "phone",
        validate: checkPhone.valid,
        message: checkPhone.message,
      },
    ];
    if (!checkMobiles.valid || !checkPhone.valid) {
      names.map(
        (e) =>
          !e.validate &&
          setError(e.name, { type: "custom", message: t(e.message) })
      );
      return;
    }
    data.mobile = getFormatNumber(data.mobile, data.mobileCode);
    data.phone = getFormatNumber(data.phone, data.phoneCode);
    for (const key in data) {
      if (
        (data[key] === "" && key === "code") ||
        key === "phoneCode" ||
        key === "mobileCode"
      ) {
        delete data[key];
      }
      if (data[key] === "") {
        data[key] = null;
        continue;
      }
    }

    save({
      variables: {
        input: {
          ...data,
          active: active,
          ...(data.idIssueDate && {
            idIssueDate: dateFormat(data.idIssueDate),
          }),
          ...(id && { id: id }),
        },
      },
    })
      .then((data) => {
        setId(data?.data?.[`save${capitalPathname}`]?.id);

        pushUrl(
          props,
          `/admin/${urlPath}/${data?.data?.[`save${capitalPathname}`]?.id}`
        );
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid
          justifyContent="end"
          sx={{ display: "flex", flexWrap: "wrap" }}
          xs={12}
        >
          <FormControlLabel
            checked={active}
            control={<Switch color="primary" />}
            label={t("active")}
            labelPlacement="start"
            onChange={handelActivate}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"code"}
            label={t("code")}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"name"}
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid sm={3} xs={12}>
          <ListBranches
            control={control}
            errors={errors}
            name={"branchId"}
            defaultValue={autocompleteValues.branch}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"glAccountId"}
            label={t("glAccount")}
            skip={!watch("branchId") || loading}
            parseData={(data) => parseData(data)}
            query={LIST_GL_ACCOUNTS_DROPDOWN.query}
            variables={{
              input: {
                typeCode: "SUB",
                ...(watch("branchId") && {
                  branchId: {
                    value: watch("branchId"),
                    includeNull: true,
                  },
                  parentId:
                    pathname === "customAgent"
                      ? parentId
                      : pathname === "carrier"
                      ? parentIdCarrier
                      : parentIdAgency,
                }),
              },
            }}
            defaultValue={autocompleteValues.glAccount}
          />
        </Grid>

        <Grid xs={12} sm={3} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"currencyId"}
            label={t("currency")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => selectDefaultValue(data)}
            query={LIST_CURRENCIES_DROPDOWN.query}
            defaultValue={autocompleteValues.currency}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <CustomAutocomplete
            valueKey="code"
            control={control}
            errors={errors}
            name={"idType"}
            label={t("idType")}
            parseData={(data) => selectDefaultValue(data)}
            variables={{ input: { code: "ID_TYPES" } }}
            query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
            defaultValue={autocompleteValues.idType}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"idNumber"}
            label={t("idNumber")}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <MUIDate
            name="idIssueDate"
            label={t("idIssueDate")}
            control={control}
            value={idIssueDate}
            defaultValue={""}
            onChange={(date) => setIdIssueDate(date)}
            onError={(resone, value) => {
              setError("deliveryDate", { message: resone });
            }}
          />
        </Grid>
      </Paper>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid xs={12} sm={6} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"countryId"}
            label={t("country")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => selectDefaultValue(data)}
            query={LIST_COUNTRIES_DROPDOWN.query}
            onChangeValue={(e) => {
              setValue("stateId", "");
            }}
            defaultValue={autocompleteValues.country}
          />
        </Grid>
        <Grid xs={12} sm={6} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            disabled={!watch("countryId")}
            skip={!watch("countryId")}
            name={"stateId"}
            label={t("state")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => selectDefaultValue(data)}
            query={LIST_STATES_DROPDOWN.query}
            variables={{
              countryId: watch("countryId"),
            }}
            defaultValue={autocompleteValues.state}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"address"}
            label={t("address")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"email"}
            label={t("email")}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <MuiPhone
            setValue={setValue}
            codeValue={getValues().phoneCode}
            control={control}
            errors={errors}
            name={"phone"}
            selectName={"phoneCode"}
            label={t("phone")}
            rules={{
              required: t("fieldIsRequired"),
            }}
            onBlur={(e) => {
              if (Boolean(!getValues().mobile)) {
                setValue("mobile", getValues().phone, {
                  shouldDirty: true,
                });
                setValue("mobileCode", getValues().phoneCode, {
                  shouldDirty: true,
                });
              }
            }}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <MuiPhone
            setValue={setValue}
            codeValue={watch("mobileCode")}
            control={control}
            errors={errors}
            name={"mobile"}
            selectName={"mobileCode"}
            label={t("mobile")}
            rules={{
              required: t("fieldIsRequired"),
            }}
          />
        </Grid>
      </Paper>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"postalCode"}
            label={t("postalCode")}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"mailBoxNumber"}
            label={t("mailBoxNumber")}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"taxCardNumber"}
            label={t("taxCardNumber")}
          />
        </Grid>

        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"registerationNumber"}
            label={t("registerationNumber")}
          />
        </Grid>
      </Paper>
      <Grid container justifyContent="flex-end" className={classesForm.spacing}>
        <FormButton disabled={saveLoading}>
          {saveLoading ? <ButtonLoading /> : t("save")}
        </FormButton>
      </Grid>
    </form>
  );
  let DOM;

  if (id) {
    DOM = data ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return updateLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data && id ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={pathURL} />
      <Stack spacing={2} p={2}>
        {DOM}
      </Stack>
    </RootStyleForm>
  );
};

export default CustomAgentForm;
