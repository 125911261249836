import { createContext, useContext } from "react";

const BillContext = createContext();
export const useBillContext = () => {
  const context = useContext(BillContext);
  if (context === "undefined") {
    throw new Error("BillContext was used outside of the provider");
  }
  return context;
};
export const BillProvider = ({ children, value }) => {
  return <BillContext.Provider value={value}>{children}</BillContext.Provider>;
};
