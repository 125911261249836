import { Icon, TableRow } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CellLink from "../../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";

// import ShipmentRegion from "../../HOC/CustomComponents/ShipmentRegion";

const useTableBodyDetails = ({ type, typeData }) => {
  const { t } = useTranslation();

  /* --------------------------------- CUSTM --------------------------------- */
  const billTableHead = [
    "priceModes",
    "chargeElementBill",
    "chargeableUnits",
    "chargePrice",
    "value",
    "tax",
    "total",
  ];

  const billTableBody = (tableDate, index) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        <FixedTableCell>{tableDate?.priceMode?.name}</FixedTableCell>
        <FixedTableCell>{tableDate?.chargeElement.name}</FixedTableCell>
        <FixedTableCell>{tableDate?.chargeableUnits}</FixedTableCell>
        <FixedTableCell>{tableDate?.chargePrice}</FixedTableCell>
        <FixedTableCell>{tableDate?.lineAmount}</FixedTableCell>
        <FixedTableCell>{tableDate?.taxAmount}</FixedTableCell>
        <FixedTableCell>{tableDate?.totalAmount}</FixedTableCell>
      </TableRow>
    );
  };
  /* --------------------------------- CUSTM --------------------------------- */
  const operationHeadRCVED = [
    "packageCode",
    "priceModes",
    "volume",
    "weight",
    "value",
    "currency",
    "type",
    "count",
    "totalWeight",
    "fragile",
  ];

  const operationBodyRCVED = (tableDate, index) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        <FixedTableCell>{tableDate?.code}</FixedTableCell>
        <FixedTableCell>{tableDate?.priceMode?.name}</FixedTableCell>
        <FixedTableCell>{tableDate?.volume}</FixedTableCell>
        <FixedTableCell>{tableDate?.weight}</FixedTableCell>
        <FixedTableCell>{tableDate?.chargeAmount}</FixedTableCell>
        <FixedTableCell>{tableDate?.currency?.name}</FixedTableCell>
        <FixedTableCell>{tableDate?.packageType?.name}</FixedTableCell>
        <FixedTableCell>{tableDate?.count}</FixedTableCell>
        <FixedTableCell>{tableDate?.totalWeight}</FixedTableCell>
        <FixedTableCell>
          {tableDate?.fragile ? (
            <Icon>check_circle_outline</Icon>
          ) : (
            <Icon color="error">highlight_off</Icon>
          )}
        </FixedTableCell>
      </TableRow>
    );
  };

  /* --------------------------------- DLVBY --------------------------------- */
  const operationHeadLOAD = [
    "containerNumber",
    "pieceNumber",
    "requestCode",
    "packageType",
    "product",
    "weight",
    "fragile",
    "sender",
    "originCountry",
    "recipient",
    "destinationCountry",
  ];

  const operationBodyLOAD = (tableDate, index) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        <FixedTableCell>{tableDate?.container?.containerNumber}</FixedTableCell>
        <FixedTableCell>
          {`${tableDate?.packageDetail?.pieceNumber}/${tableDate?.packageDetail?.package?.request?.detailsCount}`}
        </FixedTableCell>

        <CellLink
          pathname={`/admin/requests/${tableDate?.packageDetail?.package?.request?.id}`}
        >
          {tableDate?.packageDetail?.package?.request?.code}
        </CellLink>
        <FixedTableCell>
          {tableDate?.packageDetail?.package?.packageType?.name}
        </FixedTableCell>
        <FixedTableCell>
          {tableDate?.packageDetail?.package?.product
            ? tableDate?.packageDetail?.package?.product?.name
            : tableDate?.packageDetail?.package?.description}
        </FixedTableCell>
        <FixedTableCell>
          {tableDate?.packageDetail?.package?.weight}
        </FixedTableCell>
        <FixedTableCell>
          {tableDate?.packageDetail?.package?.fragile ? (
            <Icon>check_circle_outline</Icon>
          ) : (
            <Icon color="error">highlight_off</Icon>
          )}
        </FixedTableCell>
        <CellLink
          pathname={`/admin/vendors/${tableDate?.packageDetail?.package?.request?.vendor?.id}`}
        >
          {tableDate?.packageDetail?.package?.request?.vendor?.name}
        </CellLink>
        <CellLink
          pathname={`/admin/countries/${tableDate?.packageDetail?.package?.request?.originCountry?.id}`}
        >
          {tableDate?.packageDetail?.package?.request?.originCountry?.name}
        </CellLink>
        {/* <FixedTableCell>
                          {tableDate?.packageDetail?.package?.request?.originCountry}
                        </FixedTableCell> */}
        <CellLink
          pathname={`/admin/customer/${tableDate?.packageDetail?.package?.request?.customer?.id}`}
        >
          {tableDate?.packageDetail?.package?.request?.customer?.name}
        </CellLink>
        <CellLink
          pathname={`/admin/countries/${tableDate?.packageDetail?.package?.request?.destinationCountry?.id}`}
        >
          {tableDate?.packageDetail?.package?.request?.destinationCountry?.name}
        </CellLink>
      </TableRow>
    );
  };

  const sumRow = (
    <TableRow>
      <FixedTableCell colSpan={4} />
      <FixedTableCell>{t("total")}</FixedTableCell>
      <FixedTableCell>{typeData?.totalTaxAmount?.toFixed(2)}</FixedTableCell>
      <FixedTableCell>{typeData?.totalAmount?.toFixed(2)}</FixedTableCell>
    </TableRow>
  );
  /* --------------------------------- Manifests Object --------------------------------- */

  const tableBodyObject = {
    rcvd: {
      id: "1",
      label: t("manifestDetails"),
      tableHead: operationHeadRCVED,
      tableBody: operationBodyRCVED,
    },
    unloaded: {
      id: "2",
      label: t("unloaded"),
      tableHead: operationHeadLOAD,
      tableBody: operationBodyLOAD,
    },
    loading: {
      id: "3",
      label: t("loading"),
      tableHead: operationHeadLOAD,
      tableBody: operationBodyLOAD,
    },
    delivered: {
      id: "4",
      label: t("delivered"),
      tableHead: operationHeadLOAD,
      tableBody: operationBodyLOAD,
    },
    bill: {
      id: "5",
      label: t("bill"),
      tableHead: billTableHead,
      tableBody: billTableBody,
      tableTotal: sumRow,
    },
    invoice: {
      id: "6",
      label: t("invoice"),
      tableHead: billTableHead,
      tableBody: billTableBody,
      tableTotal: sumRow,
    },
  };

  return {
    details: tableBodyObject[type],
  };
};

export default useTableBodyDetails;
