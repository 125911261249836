import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Search } from "@mui/icons-material";
import { Button, Icon, TableBody, TableRow } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  LIST_COUNTRIES_DROPDOWN,
  LIST_EMPLOYEE_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_STATES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUIselect from "../HOC/MUI/MUIselect";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_CUSTOMERS } from "./Graphql";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import { RootStyleList, classes } from "../../GlobalStyles/ListStyle";
import { Globals } from "../HOC/Classes/Globals";
import { ROLE } from "../../helpers/helpersFunction";

const CustomersList = (props) => {
  const { t } = useTranslation();
  const screenWidth = useWidth();
  const history = useHistory();
  const user = Globals.user;

  const [refetch, setrefetch] = useState(true);
  const [noData, setNoData] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    // glAccount: null,
    country: null,
    state: null,
    type: null,
    category: null,
    identificationType: null,
    employee: null,
  });
  const [rowsPerPage, setRowsPerPage] = useState(
    urlQuery["rowsPerPage"] ?? config.app.pageSize
  );
  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["active"] !== undefined && setValue("active", urlQuery["active"]);
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    setAutocompleteValues((prev) => ({
      ...prev,
      ...(urlQuery["identificationType"] && {
        identificationType: urlQuery["identificationType"],
      }),
      ...(urlQuery["category"] && {
        category: urlQuery["category"],
      }),
      ...(urlQuery["type"] && {
        type: urlQuery["type"],
      }),
      ...(urlQuery["stateId"] && {
        state: urlQuery["stateId"],
      }),
      ...(urlQuery["countryId"] && {
        country: urlQuery["countryId"],
      }),
      // ...(urlQuery["glAccountId"] && {
      //   glAccount: urlQuery["glAccountId"],
      // }),
      ...(urlQuery["branchId"] && {
        branch: urlQuery["branchId"],
      }),
      ...(urlQuery["employeeId"] && {
        employee: urlQuery["employeeId"],
      }),
    }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/customers",
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    refetch ? setrefetch(false) : setrefetch(true);
    let handledData = {
      active: data.active,
      branchId: data.branchId,
      countryId: data.countryId,
      stateId: data.stateId,
      employeeId: data.employeeId,
      name: data.name,
      type: data.type,
      category: data.category,
      code: data.code,
      identificationType: data.identificationType,
      // glAccountId: data.glAccountId,
      page: 0,
    };
    data["active"] === "" && delete handledData["active"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const parseData = (data) => {
    return data;
  };
  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };
  // const { data: setting } = useQuery(
  //   gql`
  //     ${FREIGHT_SETTING.query}
  //   `,

  //   {
  //     fetchPolicy: "no-cache",
  //     onCompleted: (data) => {},
  //   }
  // );
  // const parentId = setting?.freightSettings?.mainCustomerGLAccount?.id;

  const queryBody = LIST_CUSTOMERS.query;
  const { data, loading } = useQuery(
    gql`
      ${queryBody}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          active: search.active,
          ...(search.code && { code: search.code }),
          ...(search.name && { name: search.name }),
          ...(search.type && { type: search.type }),
          ...(search.category && { category: search.category }),
          ...(search.identificationType && {
            identificationType: search.identificationType,
          }),
          ...(search.countryId && { countryId: search.countryId }),
          // ...(search.glAccountId && { glAccountId: search.glAccountId }),
          ...(search.stateId && { stateId: search.stateId }),
          ...(search.employeeId && { employeeId: search.employeeId }),
          ...(search.branchId && {
            branchId: search.branchId,
          }),
        },
      },
      onCompleted: () => {
        setNoData(false);
      },
    }
  );

  const CustomersList =
    data?.listCustomers?.data !== null ? data?.listCustomers?.data : null;

  const icons = [
    {
      id: "filterList",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
    },
    {
      id: "add",
      action: () => pushUrl(props, `/admin/customers/create`),
      permission: "freight.customer.create",
    },
  ];

  return (
    <RootStyleList>
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={CustomersList?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"active"}
                  label={t("active")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("notActive"), value: false },
                    { key: t("active"), value: true },
                  ]}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ListBranches
                  control={control}
                  errors={errors}
                  name={"branchId"}
                  defaultValue={autocompleteValues.branch}
                  skipDefaultBranch={true}
                  skip={noData}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"countryId"}
                  label={t("country")}
                  skip={noData}
                  parseData={(data) => parseData(data)}
                  query={LIST_COUNTRIES_DROPDOWN.query}
                  onChangeValue={(e) => {
                    setValue("stateId", "");
                  }}
                  defaultValue={autocompleteValues.country}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  disabled={!watch("countryId")}
                  skip={!watch("countryId")}
                  name={"stateId"}
                  label={t("state")}
                  parseData={(data) => parseData(data)}
                  query={LIST_STATES_DROPDOWN.query}
                  variables={{
                    countryId: watch("countryId"),
                  }}
                  defaultValue={autocompleteValues.state}
                />
              </Grid>
              {user?.account?.__typename !== ROLE.employee && (
                <Grid item sm={12} alignItems="flex-start">
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    // disabled={!watch("countryId")}
                    // skip={!watch("countryId")}
                    name={"employeeId"}
                    label={t("employee")}
                    parseData={(data) => parseData(data)}
                    query={LIST_EMPLOYEE_DROPDOWN.query}
                    variables={{
                      input: {
                        designation: "SALS",
                      },
                    }}
                    defaultValue={autocompleteValues.employee}
                  />
                </Grid>
              )}
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  valueKey="code"
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"type"}
                  label={t("type")}
                  parseData={(data) => parseData(data)}
                  variables={{ input: { code: "CUSTOMERS_TYPES" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.type}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  valueKey="code"
                  skip={noData}
                  control={control}
                  errors={errors}
                  name={"category"}
                  label={t("category")}
                  parseData={(data) => parseData(data)}
                  variables={{ input: { code: "FR_CUSTOMER_CATEGORIES" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.category}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  valueKey="code"
                  skip={noData}
                  control={control}
                  errors={errors}
                  name={"identificationType"}
                  label={t("identificationType")}
                  parseData={(data) => parseData(data)}
                  variables={{ input: { code: "IDENTIFICATION_TYPES" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.identificationType}
                />
              </Grid>
              {/* <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  skip={noData}
                  errors={errors}
                  name={"glAccountId"}
                  label={t("glAccount")}
                  parseData={(data) => parseData(data)}
                  query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                  variables={{
                    input: {
                      typeCode: "SUB",
                      ...(watch("branchId") && {
                        branchId: {
                          value: watch("branchId"),
                          includeNull: true,
                        },
                        parentId: parentId,
                      }),
                    },
                  }}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      props?.match?.params?.id,
                      "glAccount"
                    )
                  }
                  defaultValue={autocompleteValues.glAccount}
                />
              </Grid> */}
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={[
          {
            name: "name",
          },
          {
            name: "code",
          },
          {
            name: "branch",
          },
          {
            name: "country",
          },
          {
            name: "currency",
          },
          {
            name: "creditLimit",
          },
          {
            name: "type",
          },
          {
            name: "commission",
          },
          {
            name: "category",
          },
          {
            name: "active",
          },
        ]}
        tableBody={
          <TableBody>
            {CustomersList &&
              CustomersList?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    className={classes.tableBodyRow}
                  >
                    <CellLink pathname={`/admin/customers/${row?.id}`}>
                      {row?.name}
                    </CellLink>
                    <FixedTableCell>{row.code}</FixedTableCell>
                    <CellLink pathname={`/admin/branches/${row?.branch?.id}`}>
                      {row?.branch?.name}
                    </CellLink>
                    <CellLink pathname={`/admin/countries/${row?.country?.id}`}>
                      {row?.country?.name}
                    </CellLink>
                    <FixedTableCell>{row.currency?.name}</FixedTableCell>
                    <FixedTableCell>{row.creditLimit}</FixedTableCell>
                    <FixedTableCell>{row.type?.name}</FixedTableCell>
                    <FixedTableCell>{row.commission}</FixedTableCell>
                    <FixedTableCell>
                      {row.customerCategory?.name}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.active ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listCustomers?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listCustomers ? 0 : search.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </RootStyleList>
  );
};

export default CustomersList;
