import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Toolbar,
  Icon,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { AddCircleOutline, Delete, Edit } from "@mui/icons-material";
import clsx from "clsx";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { DELETE_CHARGE_TYPES_ELEMENT } from "./Graphql";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";

const PREFIX = "chargeTypeTables";

const classes = {
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  focus: `${PREFIX}-focus`,
  tableRow: `${PREFIX}-tableRow`,
  lineDetails: `${PREFIX}-lineDetails`,
  addIcon: `${PREFIX}-addIcon`,
  tableTitle: `${PREFIX}-tableTitle`,
  iconColor: `${PREFIX}-iconColor`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.table}`]: {
    display: "grid",
  },
  [`& .${classes.tableTitle}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.tableContainer}`]: {
    minHeight: "250px",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.toolbar}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.focus}`]: {
    backgroundColor: theme.palette.action.hover,
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      cursor: "pointer",
    },
  },

  [`& .${classes.lineDetails}`]: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },
  [`& .${classes.addIcon}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

export default function ChargeTypeTables(props) {
  const {
    chargeTypeList = [],
    addElements,
    chargeTypeId,
    setChargeTypeList,
  } = props;
  const { t } = useTranslation();

  //////////// Lines Functions ////////////
  const [openDialog, setOpenDialog] = React.useState(false);

  const [elementPage, setElementPage] = useState(0);
  const [elementRowsPerPage] = useState(20);
  const [elementIndex, setElementIndex] = useState(0);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleChangePage = (event, newPage) => {
    setElementPage(newPage);
  };

  // const [deleteChargeElementMutation, { loading: deleteLoad }] = useMutation(
  //   gql`
  //     ${DELETE_CHARGE_TYPES_ELEMENT.query}
  //   `,
  //   {
  //     fetchPolicy: "no-cache",
  //     nextFetchPolicy: "no-cache",
  //   }
  // );

  //////////// Detail Functions ////////////

  // row.services.map(ele => ' ' + ele.name + ' , ')
  const elementTable = (
    <Grid
      container
      item
      sm={12}
      md={12}
      className={classes.lineDetails}
      component={Paper}
    >
      <Toolbar variant="dense" className={classes.toolbar}>
        <Typography
          className={classes.title}
          variant="h6"
          color={"text.primary"}
        >
          {t("elements")}
        </Typography>

        {addElements ? (
          <IconButton
            aria-label="Add"
            disabled={chargeTypeId !== 0 && !Boolean(chargeTypeId)}
            onClick={() => {
              addElements();
              setElementIndex(0);
            }}
            size="large"
            className={classes.addIcon}
          >
            <AddCircleOutline />
          </IconButton>
        ) : null}
      </Toolbar>

      <Grid container item className={classes.table}>
        <TableFixedHeaderWraper className={classes.tableContainer}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <FixedTableCell>{t("name")}</FixedTableCell>
                <FixedTableCell>{t("code")}</FixedTableCell>
                <FixedTableCell>{t("ocean")}</FixedTableCell>
                <FixedTableCell>{t("air")}</FixedTableCell>
                <FixedTableCell>{t("land")}</FixedTableCell>
                <FixedTableCell>{t("import")}</FixedTableCell>
                <FixedTableCell>{t("export")}</FixedTableCell>
                <FixedTableCell>{t("active")}</FixedTableCell>
                {addElements ? <FixedTableCell></FixedTableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {chargeTypeList.length > 0 &&
                (elementRowsPerPage > 0
                  ? chargeTypeList.slice(
                      elementPage * elementRowsPerPage,
                      elementPage * elementRowsPerPage + elementRowsPerPage
                    )
                  : chargeTypeList
                ).map((row, index) => (
                  <TableRow
                    className={clsx(classes.tableRow, {
                      [classes.focus]:
                        elementPage * elementRowsPerPage + index ===
                        elementIndex,
                    })}
                    hover
                    key={index}
                    onClick={() => {
                      setElementIndex(elementPage * elementRowsPerPage + index);
                    }}
                  >
                    <FixedTableCell>{row?.name}</FixedTableCell>
                    <FixedTableCell>{row?.code}</FixedTableCell>
                    <FixedTableCell>
                      {row?.ocean ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.air ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.land ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.import ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.export ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.active ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    {addElements ? (
                      <FixedTableCell>
                        <IconButton
                          size="small"
                          onClick={() =>
                            addElements(
                              elementPage * elementRowsPerPage + index
                            )
                          }
                        >
                          <Edit />
                        </IconButton>
                        <MutationWithDialog
                          mutaion={DELETE_CHARGE_TYPES_ELEMENT.query}
                          setOpenDelete={setOpenDialog}
                          openDelete={openDialog}
                          dialogTitle={t("deleteRecord")}
                          dialogContent={t("deleteRecordMessage")}
                          mutaionProps={{
                            variables: { id: parseInt(parseInt(row.id)) },
                          }}
                          onCompleted={() =>
                            setChargeTypeList((prev) => {
                              const filterd = prev.filter(
                                (i, deleteIndex) =>
                                  deleteIndex !==
                                  elementPage * elementRowsPerPage + index
                              );
                              return filterd;
                            })
                          }
                          onCompleteMessage={t("successfullyDeletedRecord")}
                        />

                        <IconButton
                          size="small"
                          // disabled={deleteLoad}
                          onClick={() => {
                            handleOpenDialog();

                            //   deleteChargeElementMutation({
                            //     variables: {
                            //       id: parseInt(row.id),
                            //     },
                            //   });
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </FixedTableCell>
                    ) : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableFixedHeaderWraper>
        <MUITablePagination
          count={chargeTypeList?.length ?? 0}
          rowsPerPage={elementRowsPerPage}
          page={elementPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[20]}
          disableLastPage
        />
      </Grid>
    </Grid>
  );

  return (
    <StyledGrid container alignItems="flex-start" sx={{ mx: "16px" }}>
      <Grid
        container
        item
        sm={12}
        md={12}
        className={classes.lineDetails}
        component={Paper}
      >
        {elementTable}
      </Grid>
    </StyledGrid>
  );
}
