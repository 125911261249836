import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { TableBody, TableRow, Icon, Button } from "@mui/material";
import { Search } from "@mui/icons-material";
import { EXPOERT_BILLS, EXPOERT_INVOICES, QUERY_LIST } from "./Graphql";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useWidth from "../../Hooks/useWidth";
import config from "../../config.json";
import Grid from "@mui/material/Unstable_Grid2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_CURRENCIES_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import moment from "moment";
import MUIselect from "../HOC/MUI/MUIselect";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import formatMoney from "../../helpers/numbersDot";
import { useAccountType } from "../../Hooks/useAccountType";

import { RootStyleList, classes } from "../../GlobalStyles/ListStyle";
import { getEntityInfo } from "../../helpers/getEntityInfo";
import CustomExportExcel from "../HOC/CustomComponents/CustomExportExcel";
import { Globals } from "../HOC/Classes/Globals";
import { ROLE } from "../../helpers/helpersFunction";
const BillsList = (props) => {
  const { t } = useTranslation();
  const screenWidth = useWidth();
  const history = useHistory();
  const [pathURL] = useState(props.match.path);
  const [refetch, setrefetch] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const [autocompleteValues, setAutocompleteValues] = useState({
    currency: null,
    paymentType: null,
    branch: null,
    accountType: null,
    documentType: null,
    customer: null,
    employee: null,
    vendor: null,
    customAgent: null,
    agency: null,
    carrier: null,
  });
  const [noData, setNoData] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(
    urlQuery["rowsPerPage"] ?? config.app.pageSize
  );
  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });
  const [billDate, setBillDate] = useState([
    urlQuery["fromDate"] ? urlQuery["fromDate"] : null,
    urlQuery["toDate"] ? urlQuery["toDate"] : null,
  ]);
  const [invoiceDate, setInvoiceDate] = useState([
    urlQuery["invoiceFromDate"] ? urlQuery["invoiceFromDate"] : null,
    urlQuery["invoiceToDate"] ? urlQuery["invoiceToDate"] : null,
  ]);
  const [referenceDate, setReferenceDate] = useState([
    urlQuery["referenceFromDate"] ? urlQuery["referenceFromDate"] : null,
    urlQuery["referenceToDate"] ? urlQuery["referenceToDate"] : null,
  ]);
  const fromDate = moment(billDate[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(billDate[1]).locale("en").format("YYYY-MM-DD");
  const invoiceFromDate = moment(invoiceDate[0])
    .locale("en")
    .format("YYYY-MM-DD");
  const invoiceToDate = moment(invoiceDate[1])
    .locale("en")
    .format("YYYY-MM-DD");
  const referenceFromDate = moment(referenceDate[0])
    .locale("en")
    .format("YYYY-MM-DD");
  const referenceToDate = moment(referenceDate[1])
    .locale("en")
    .format("YYYY-MM-DD");

  let pathname = pathURL;
  if (pathURL.includes("invoice")) {
    pathname = "invoice";
  } else {
    pathname = "bill";
  }
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const [openExport, setOpenExport] = useState(false);
  const accountType = watch("accountType");
  const dynamicAutocompleteProps = useAccountType(
    accountType,
    autocompleteValues
  );
  const initSearch = () => {
    let searchParameters = {
      ...(billDate[0] && { fromDate }),
      ...(billDate[1] && { toDate }),
      ...(invoiceDate[0] && { invoiceFromDate }),
      ...(invoiceDate[1] && { invoiceToDate }),
      ...(referenceDate[0] && { referenceFromDate }),
      ...(referenceDate[1] && { referenceToDate }),
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      if (urlQuery["documentCode"])
        urlQuery["documentCode"] = urlQuery["documentCode"].toString();
      if (urlQuery["referenceNumber"])
        urlQuery["referenceNumber"] = urlQuery["referenceNumber"].toString();

      if (urlQuery.fromDate || urlQuery.toDate) {
        const billDate = {
          ...(urlQuery?.fromDate && { fromDate: urlQuery?.fromDate }),
          ...(urlQuery?.toDate && { toDate: urlQuery?.toDate }),
        };
        delete urlQuery["fromDate"];
        delete urlQuery["toDate"];
        urlQuery.billDate = billDate;
      }
      if (urlQuery.invoiceFromDate || urlQuery.invoiceToDate) {
        const invoiceDate = {
          ...(urlQuery?.invoiceFromDate && {
            fromDate: urlQuery?.invoiceFromDate,
          }),
          ...(urlQuery?.invoiceToDate && {
            toDate: urlQuery?.invoiceToDate,
          }),
        };
        delete urlQuery["invoiceFromDate"];
        delete urlQuery["invoiceToDate"];
        urlQuery.invoiceDate = invoiceDate;
      }
      if (urlQuery.referenceFromDate || urlQuery.referenceToDate) {
        const referenceDate = {
          ...(urlQuery?.referenceFromDate && {
            fromDate: urlQuery?.referenceFromDate,
          }),
          ...(urlQuery?.referenceToDate && {
            toDate: urlQuery?.referenceToDate,
          }),
        };
        delete urlQuery["referenceFromDate"];
        delete urlQuery["referenceToDate"];
        urlQuery.referenceDate = referenceDate;
      }

      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());
  const user = Globals.user;

  const resetBillDate = () => {
    setBillDate([null, null]);
  };
  const resetInvoiceDate = () => {
    setInvoiceDate([null, null]);
  };
  const resetReferenceDate = () => {
    setReferenceDate([null, null]);
  };

  useEffect(() => {
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    urlQuery["referenceNumber"] &&
      setValue("referenceNumber", urlQuery["referenceNumber"]);
    urlQuery["documentCode"] &&
      setValue("documentCode", urlQuery["documentCode"]);
    urlQuery["approved"] !== undefined &&
      setValue("approved", urlQuery["approved"]);
    urlQuery["glApproved"] !== undefined &&
      setValue("glApproved", urlQuery["glApproved"]);
    setAutocompleteValues((prev) => ({
      ...(urlQuery["branchId"] && {
        branch: urlQuery["branchId"],
      }),
      ...(urlQuery["paymentType"] && {
        paymentType: urlQuery["paymentType"],
      }),
      ...(urlQuery["accountType"] && {
        accountType: urlQuery["accountType"],
      }),
      ...(urlQuery["documentType"] && {
        documentType: urlQuery["documentType"],
      }),
      ...(urlQuery["currencyId"] && {
        currency: urlQuery["currencyId"],
      }),
      ...(urlQuery["customerId"] && {
        customer: urlQuery["customerId"],
      }),
      ...(urlQuery["employeeId"] && {
        employee: urlQuery["employeeId"],
      }),
      ...(urlQuery["vendorId"] && {
        vendor: urlQuery["vendorId"],
      }),
      ...(urlQuery["customAgentId"] && {
        customAgent: urlQuery["customAgentId"],
      }),
      ...(urlQuery["agencyId"] && {
        agency: urlQuery["agencyId"],
      }),
      ...(urlQuery["carrierId"] && {
        carrier: urlQuery["carrierId"],
      }),
    }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openExportDialog = () => {
    setOpenExport(true);
  };

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: `/admin/${pathname}s`,
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    refetch ? setrefetch(false) : setrefetch(true);
    let handledData = {
      ...(billDate[0] && { fromDate }),
      ...(billDate[1] && { toDate }),
      ...(invoiceDate[0] && { invoiceFromDate }),
      ...(invoiceDate[1] && { invoiceToDate }),
      ...(referenceDate[0] && { referenceFromDate }),
      ...(referenceDate[1] && { referenceToDate }),
      code: data.code,
      glApproved: data.glApproved,
      approved: data.approved,
      documentCode: data.documentCode,
      referenceNumber: data.referenceNumber,
      vendorId: data.vendorId,
      customerId: data.customerId,
      customAgentId: data.customAgentId,
      employeeId: data.employeeId,
      agencyId: data.agencyId,
      carrierId: data.carrierId,
      branchId: data.branchId,
      currencyId: data.currencyId,
      documentType: data.documentType,
      accountType: data.accountType,
      paymentType: data.paymentType,
      page: 0,
    };
    data["approved"] === "" && delete handledData["approvedapproved"];
    data["glApproved"] === "" && delete handledData["glApproved"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });

    if (handledData.fromDate || handledData.toDate) {
      const billDate = {
        ...(handledData?.fromDate && { fromDate: handledData?.fromDate }),
        ...(handledData?.toDate && { toDate: handledData?.toDate }),
      };
      delete handledData["fromDate"];
      delete handledData["toDate"];
      handledData.billDate = billDate;
    }
    if (handledData.invoiceFromDate || handledData.invoiceToDate) {
      const invoiceDate = {
        ...(handledData?.invoiceFromDate && {
          fromDate: handledData?.invoiceFromDate,
        }),
        ...(handledData?.invoiceToDate && {
          toDate: handledData?.invoiceToDate,
        }),
      };
      delete handledData["invoiceFromDate"];
      delete handledData["invoiceToDate"];
      handledData.invoiceDate = invoiceDate;
    }
    if (handledData.referenceFromDate || handledData.referenceToDate) {
      const referenceDate = {
        ...(handledData?.referenceFromDate && {
          fromDate: handledData?.referenceFromDate,
        }),
        ...(handledData?.referenceToDate && {
          toDate: handledData?.referenceToDate,
        }),
      };
      delete handledData["referenceFromDate"];
      delete handledData["referenceToDate"];
      handledData.referenceDate = referenceDate;
    }

    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    // for (const key in handledData) {
    //   if (handledData[key] === null) {
    //     delete handledData[key];
    //   }
    // }
  };

  const parseData = (data) => {
    return data;
  };
  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };
  const capitalPathname = pathname.charAt(0).toUpperCase() + pathname.slice(1);
  const LIST_QUERY = QUERY_LIST(
    `list${capitalPathname}s`,
    `${capitalPathname}s`
  );
  const { data, loading } = useQuery(
    gql`
      ${LIST_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          approved: search.approved,
          glApproved: search.glApproved,
          ...(search.code && { code: search.code }),
          ...(search.referenceNumber && {
            referenceNumber: search.referenceNumber,
          }),
          ...(search.documentCode && { documentCode: search.documentCode }),
          ...(search.billDate && { billDate: search.billDate }),
          ...(search.invoiceDate && {
            invoiceDate: search.invoiceDate,
          }),
          ...(search.referenceDate && {
            referenceDate: search.referenceDate,
          }),
          ...(search.branchId && {
            branchId: search.branchId,
          }),
          ...(search.currencyId && {
            currencyId: search.currencyId,
          }),
          ...(search.documentType && {
            documentType: search.documentType,
          }),
          ...(search.accountType && {
            accountType: search.accountType,
          }),
          ...(search.paymentType && {
            paymentType: search.paymentType,
          }),
          ...(search.carrierId && {
            carrierId: search.carrierId,
          }),
          ...(search.agencyId && {
            agencyId: search.agencyId,
          }),
          ...(search.employeeId && {
            employeeId: search.employeeId,
          }),
          ...(search.customAgentId && {
            customAgentId: search.customAgentId,
          }),
          ...(search.customerId && {
            customerId: search.customerId,
          }),
          ...(search.vendorId && {
            vendorId: search.vendorId,
          }),
        },
      },
      onCompleted: () => {
        setNoData(false);
      },
    }
  );
  const filters = {
    approved: search.approved,
    glApproved: search.glApproved,
    ...(search.code && { code: search.code }),
    ...(search.referenceNumber && {
      referenceNumber: search.referenceNumber,
    }),
    ...(search.documentCode && { documentCode: search.documentCode }),
    ...(search.billDate && { billDate: search.billDate }),
    ...(search.invoiceDate && {
      invoiceDate: search.invoiceDate,
    }),
    ...(search.referenceDate && {
      referenceDate: search.referenceDate,
    }),
    ...(search.branchId && {
      branchId: search.branchId,
    }),
    ...(search.currencyId && {
      currencyId: search.currencyId,
    }),
    ...(search.documentType && {
      documentType: search.documentType,
    }),
    ...(search.accountType && {
      accountType: search.accountType,
    }),
    ...(search.paymentType && {
      paymentType: search.paymentType,
    }),
    ...(search.carrierId && {
      carrierId: search.carrierId,
    }),
    ...(search.agencyId && {
      agencyId: search.agencyId,
    }),
    ...(search.employeeId && {
      employeeId: search.employeeId,
    }),
    ...(search.customAgentId && {
      customAgentId: search.customAgentId,
    }),
    ...(search.customerId && {
      customerId: search.customerId,
    }),
    ...(search.vendorId && {
      vendorId: search.vendorId,
    }),
  };
  const fields = [
    { name: "CODE", label: t("code") },
    { name: "DATE", label: t("date") },
    { name: "BRANCH", label: t("branch") },
    { name: "ACCOUNT_TYPE", label: t("accountType") },
    { name: "ACCOUNT_NAME", label: t("accountName") },
    { name: "DOCUMENT_TYPE", label: t("documentType") },
    { name: "DOCUMENT_CODE", label: t("documentCode") },
    { name: "TOTAL_AMOUNT", label: t("amount") },
    { name: "CURRENCY", label: t("currency") },
    { name: "PAYMENT_TYPE", label: t("paymentType") },
    { name: "APPROVED", label: t("approved") },
    { name: "GL_APPROVED", label: t("glApproved") },
  ];
  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find((i) => i.code === id);
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const listBills = data?.[`list${capitalPathname}s`]?.data;

  const icons = [
    {
      id: "filterList",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
    },
    {
      id: "add",
      action: () => pushUrl(props, `/admin/${pathname}s/create`),
      permission: `freight.${pathname}.create`,
    },
    {
      id: "export",
      action: openExportDialog,
      disabled: !Boolean(data),
    },
  ];
  console.log(pathname);

  return (
    <RootStyleList>
      {openExport &&
        (pathname === "bill" ? (
          <CustomExportExcel
            filters={filters}
            fields={fields}
            localStorageName={"export-bills"}
            ExportMutation={EXPOERT_BILLS}
            mutationName={"exportBills"}
            openExport={openExport}
            setOpenExport={setOpenExport}
          />
        ) : (
          <CustomExportExcel
            filters={filters}
            fields={fields}
            localStorageName={"export-invoices"}
            ExportMutation={EXPOERT_INVOICES}
            mutationName={"exportInvoices"}
            openExport={openExport}
            setOpenExport={setOpenExport}
          />
        ))}
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={listBills?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                {pathname === "bill" ? (
                  <MUIDateRangeCustom
                    startText={t("billDate")}
                    value={billDate}
                    onChangeValue={(value) => setBillDate(value)}
                    resetDate={resetBillDate}
                  />
                ) : (
                  <MUIDateRangeCustom
                    startText={t("billDate")}
                    value={invoiceDate}
                    onChangeValue={(value) => setInvoiceDate(value)}
                    resetDate={resetInvoiceDate}
                  />
                )}
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("billCode")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  valueKey="code"
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"documentType"}
                  label={t("documentType")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["documentType"],
                      "documentType"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  variables={{ input: { code: "FR_DOCUMENT_TYPE" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.documentType}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"documentCode"}
                  label={t("code")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"referenceNumber"}
                  label={t("referenceNumber")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  startText={t("referenceDate")}
                  value={referenceDate}
                  onChangeValue={(value) => setReferenceDate(value)}
                  resetDate={resetReferenceDate}
                />
              </Grid>

              <Grid item sm={12} alignItems="flex-start">
                <ListBranches
                  control={control}
                  errors={errors}
                  name={"branchId"}
                  defaultValue={autocompleteValues.branch}
                  skipDefaultBranch={true}
                  skip={noData}
                />
              </Grid>
              {user?.account?.__typename !== ROLE.employee && (
                <>
                  <Grid item sm={12} alignItems="flex-start">
                    <CustomAutocomplete
                      valueKey="code"
                      control={control}
                      errors={errors}
                      skip={noData}
                      name={"accountType"}
                      label={t("accountType")}
                      onCompleted={(data) =>
                        handelAutocompleteDefaultValue(
                          data,
                          urlQuery["accountType"],
                          "accountType"
                        )
                      }
                      parseData={(data) => parseData(data)}
                      variables={{ input: { code: "FR_ACCOUNT_TYPE" } }}
                      query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                      defaultValue={autocompleteValues.accountType}
                      onChangeValue={(e) => {
                        setValue(dynamicAutocompleteProps.name, "");
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} alignItems="flex-start">
                    <CustomAutocomplete
                      control={control}
                      errors={errors}
                      disabled={!watch("accountType")}
                      name={dynamicAutocompleteProps.name}
                      label={dynamicAutocompleteProps.label}
                      parseData={(data) => parseData(data)}
                      query={dynamicAutocompleteProps.query}
                      variables={{
                        input: dynamicAutocompleteProps?.input,
                      }}
                      defaultValue={dynamicAutocompleteProps.defaultValue}
                    />
                  </Grid>
                </>
              )}
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"approved"}
                  label={t("approved")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("false"), value: false },
                    { key: t("true"), value: true },
                  ]}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"glApproved"}
                  label={t("glApproved")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("false"), value: false },
                    { key: t("true"), value: true },
                  ]}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"currencyId"}
                  label={t("currency")}
                  parseData={(data) => parseData(data)}
                  query={LIST_CURRENCIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.currency}
                />
              </Grid>

              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  valueKey="code"
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"paymentType"}
                  label={t("paymentType")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["paymentType"],
                      "paymentType"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  variables={{ input: { code: "PAYMENT_TYPES" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.paymentType}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={[
          {
            name: "billCode",
          },
          {
            name: "billDate",
          },
          {
            name: "branch",
          },
          {
            name: "accountType",
          },
          {
            name: "accountName",
          },
          {
            name: "documentType",
          },
          {
            name: "code",
          },

          {
            name: "value",
          },
          {
            name: "currency",
          },

          {
            name: "paymentType",
          },

          {
            name: "approved",
          },
          {
            name: "glApproved",
          },
        ]}
        tableBody={
          <TableBody>
            {listBills &&
              listBills?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <CellLink pathname={`/admin/${pathname}s/${row?.id}`}>
                      {row?.code}
                    </CellLink>
                    <FixedTableCell>
                      {pathname === "bill" ? row?.billDate : row?.invoiceDate}
                    </FixedTableCell>
                    <CellLink pathname={`/admin/branches/${row?.branch?.id}`}>
                      {row?.branch?.name}
                    </CellLink>
                    <FixedTableCell>{row?.accountType?.name}</FixedTableCell>
                    <CellLink
                      pathname={`/admin/${getEntityInfo(row).entityPath}/${
                        getEntityInfo(row).id || ""
                      }`}
                    >
                      {getEntityInfo(row).entity
                        ? row[getEntityInfo(row).entity]?.name || ""
                        : ""}
                    </CellLink>
                    <FixedTableCell>{row?.documentType?.name}</FixedTableCell>
                    {row?.request ? (
                      <CellLink
                        pathname={`/admin/requests/${row?.request?.id}`}
                      >
                        {row?.request?.code}
                      </CellLink>
                    ) : (
                      <CellLink
                        pathname={`/admin/shipments/${row?.shipment?.id}`}
                      >
                        {row?.shipment?.code}
                      </CellLink>
                    )}

                    <FixedTableCell>
                      {formatMoney(row?.totalAmount)}
                    </FixedTableCell>
                    <FixedTableCell>{row?.currency?.name}</FixedTableCell>
                    <FixedTableCell>{row?.paymentType?.name}</FixedTableCell>
                    <FixedTableCell>
                      {row?.approved ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.glApproved ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.[`list${capitalPathname}s`]?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.[`list${capitalPathname}s`] ? 0 : search.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </RootStyleList>
  );
};

export default BillsList;
