import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button, Collapse, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { SAVE_LINES } from "./Graphql";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_CHARGE_ELEMENTS_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";

export default function LineForm(props) {
  const {
    id,
    updateLine,
    line,
    setLines,
    data,
    closeDialog,
    capitalPathname,
    pathname,
    setTotalSum,
  } = props;
  console.log(updateLine);

  const [autocompleteValues, setAutocompleteValues] = React.useState({
    chargeElement: null,
    priceMode: null,
  });

  const { handleSubmit, control, setError, errors, setValue } = useForm();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  // const capitalPathname = pathname.charAt(0).toUpperCase() + pathname.slice(1);
  const SAVE = SAVE_LINES(capitalPathname); // Pass the operation name

  const [save, { loading: saveLoading }] = useMutation(
    gql`
      ${SAVE.query}
    `
  );

  useEffect(() => {
    if (updateLine) {
      setValue("chargePrice", updateLine?.chargePrice);
      setValue("chargeableUnits", updateLine?.chargeableUnits);
      setAutocompleteValues((prev) => ({
        ...prev,
        priceMode: updateLine?.priceMode,
        chargeElement: updateLine?.chargeElement,
      }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const parseData = (data) => {
    return data;
  };
  const onSubmit = (data, billId, id) => {
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
      if (["chargeableUnits", "chargePrice"].includes(key)) {
        data[key] = parseFloat(data[key]);
      }
    }
    save({
      variables: {
        input: {
          ...(billId && capitalPathname === "Bill"
            ? { billId: billId }
            : { invoiceId: billId }),
          ...(id && { id: id }),
          ...data,
        },
      },
    })
      .then((data) => {
        const checksubZone = line.map(
          (ele) => ele.id === data.data?.[`save${capitalPathname}Line`].id
        );
        if (id) {
          setTotalSum((prev) => ({
            ...prev, // Spread the previous state to keep other values intact
            totalTaxAmount:
              prev.totalTaxAmount -
              updateLine?.taxAmount +
              data.data?.[`save${capitalPathname}Line`]?.taxAmount, // Update the specific value
            totalAmount:
              prev.totalAmount -
              updateLine?.totalAmount +
              data.data?.[`save${capitalPathname}Line`]?.totalAmount, // Update another value
          }));
        } else {
          setTotalSum((prev) => ({
            ...prev, // Spread the previous state to keep other values intact
            totalTaxAmount:
              prev.totalTaxAmount +
              data.data?.[`save${capitalPathname}Line`]?.taxAmount, // Update the specific value
            totalAmount:
              prev.totalAmount +
              data.data?.[`save${capitalPathname}Line`]?.totalAmount, // Update another value
          }));
        }

        const check = checksubZone.includes(true);
        if (check) {
          const index = line.findIndex(
            (x) => x.id === data.data?.[`save${capitalPathname}Line`].id
          );
          line[index] = data.data?.[`save${capitalPathname}Line`];
          setLines(line);
        } else {
          line.push(data.data?.[`save${capitalPathname}Line`]);
          setLines(line);
        }
        closeDialog();
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };
  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        component="form"
        onSubmit={handleSubmit((data) => onSubmit(data, id, updateLine?.id))}
      >
        <Grid
          container
          justifyContent="space-between"
          xs={12}
          alignItems={"center"}
        >
          <Typography variant="h6" color={"text.primary"}>
            {t("lines")}
          </Typography>
        </Grid>
        <Grid xs={12} sm={12}>
          <CustomAutocomplete
            valueKey="code"
            control={control}
            hideCode={true}
            errors={errors}
            rules={{ required: t("fieldIsRequired") }}
            name={"priceMode"}
            label={t("priceModes")}
            parseData={(data) => parseData(data)}
            variables={{ input: { code: "FR_PRICE_MODE" } }}
            query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
            defaultValue={autocompleteValues.priceMode}
          />
        </Grid>
        <Grid xs={12} sm={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"chargeElementId"}
            label={t(
              capitalPathname === "Bill"
                ? "chargeElementBill"
                : "chargeElementInvc"
            )}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => parseData(data)}
            query={LIST_CHARGE_ELEMENTS_DROPDOWN.query}
            variables={{
              input: {
                ...(capitalPathname === "Bill"
                  ? {
                      payable: true,
                    }
                  : {
                      receivable: true,
                    }),
                ...(data[pathname]?.request && {
                  shippingDirection:
                    data[pathname]?.request?.shippingDirection?.code,
                  shippingMode: data[pathname]?.request?.shippingMode?.code,
                }),
              },
            }}
            defaultValue={autocompleteValues.chargeElement}
          />
        </Grid>
        <Grid xs={12} sm={12}>
          <ControlMUItextField
            control={control}
            errors={errors}
            type="number"
            name={"chargeableUnits"}
            label={t("chargeableUnits")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid xs={12} sm={12}>
          <ControlMUItextField
            control={control}
            errors={errors}
            type="number"
            name={"chargePrice"}
            label={t("chargePrice")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>

        <Grid container sm={12} justifyContent="flex-end">
          <Button onClick={closeDialog}>{t("cancel")}</Button>
          <Button type="submit">
            {saveLoading ? <ButtonLoading /> : t("confirm")}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
