/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Paper, Icon } from "@mui/material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { DELETE_PRODUCT, PRODUCT_ID } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";

const ProductView = (props) => {
  const { t } = useTranslation();
  const id = props.match.params.id?.trim();
  const [openDelete, setOpenDelete] = useState(false);
  const user = Globals.user;

  const openDeleteDialog = (type) => {
    setOpenDelete(true);
  };

  const { loading, data } = useQuery(
    gql`
      ${PRODUCT_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(id) },
    }
  );
  const productData = data?.product;
  const canEdit = user.hasPermission("freight.product.update");
  const canDelete =
    user?.hasPermission("freight.product.delete") && productData?.deletable;

  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/product/${id}/edit`),
      permission: canEdit,
    },
    {
      id: "delete",
      action: openDeleteDialog,
      permission: Boolean(canDelete),
    },
  ];
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !productData ? (
    <NotFound />
  ) : (
    <>
      {openDelete && (
        <MutationWithDialog
          mutaion={DELETE_PRODUCT.query}
          setOpenDelete={setOpenDelete}
          openDelete={openDelete}
          dialogTitle={t("deleteRecord")}
          dialogContent={t("deleteRecordMessage")}
          mutaionProps={{
            variables: { id: parseInt(id) },
          }}
          onCompleted={() => pushUrl(props, `/admin/product`)}
          onCompleteMessage={t("successfullyDeletedRecord")}
        />
      )}
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>

      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair title={t("code")} value={productData?.code} />
          <KeyValuePair title={t("name")} value={productData?.name} />
          <KeyValuePair title={t("date")} value={productData?.date} />
          <KeyValuePair
            title={t("commodity")}
            value={productData?.commodity?.name}
          />
          <KeyValuePair
            title={t("active")}
            value={
              productData?.active ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
        </Paper>
      </RootStyleView>
    </>
  );
};

export default ProductView;
