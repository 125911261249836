/* eslint-disable no-useless-concat */
import React, { useCallback, useState } from "react";

import { styled } from "@mui/material/styles";

import {
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  Toolbar,
  Typography,
} from "@mui/material";

import { gql, useQuery } from "@apollo/client";
import { Cancel, CheckCircle } from "@mui/icons-material";
import clsx from "clsx";
import * as gqlb from "gql-query-builder";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import MUITablePagination from "../../HOC/MUI/TablePagination/MUITablePagination";
import CustomTableRow from "./CustomTableRow";
import { EnhancedTableHead } from "./CommoditiesListDetails";
import PackageCommoditiesProvider from "./PackageCommoditiesProvider";
import useWidth, { isWidthDown } from "../../../Hooks/useWidth";
import SearchFilter from "./SearchFilter";

const TOOLBAR_PREFIX = "styledToolbar";

const toolbarClasses = {
  highlight: `${TOOLBAR_PREFIX}-highlight`,
  title: `${TOOLBAR_PREFIX}-title`,
  filterButton: `${TOOLBAR_PREFIX}-filterButton`,
  toolbar: `${TOOLBAR_PREFIX}-toolbar`,
  shipmentCode: `${TOOLBAR_PREFIX}-shipmentCode`,
};

const StyledToolbar = styled("div")(({ theme }) => ({
  width: "100%",
  zIndex: 1,

  [`& .${toolbarClasses.highlight}`]:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.grey[300],
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.grey[800],
        },

  [`& .${toolbarClasses.title}`]: {
    flex: "1 1 15%",
  },

  [`& .${toolbarClasses.filterButton}`]: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  [`& .${toolbarClasses.toolbar}`]: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  [`& .${toolbarClasses.shipmentCode}`]: {
    display: "flex",
    whiteSpace: "nowrap",
  },
}));

const PREFIX = "CommodityList";

const classes = {
  track: `${PREFIX}-track`,
  paper: `${PREFIX}-paper`,
  filters: `${PREFIX}-filters`,
  header: `${PREFIX}-header`,
};

const Root = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0, 0),
  width: "100%",
  backgroundColor: theme.palette.background.default,

  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${classes.header}`]: {
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },

  [`& .${classes.paper}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    overflowX: "auto",
    maxHeight: `calc(100vh - 120px)`,
  },

  [`& .${classes.filters}`]: {
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 72px)",
    },
    [theme.breakpoints.up("sm")]: {
      width: "360px",
    },

    height: "100%",
    overflow: "hidden",
  },
}));

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const screenWidth = useWidth();
  const screenIsSmall = isWidthDown("sm", screenWidth);
  const { numSelected, done, clear, disabled, filter } = props;
  return (
    <StyledToolbar>
      <Toolbar
        className={clsx(
          toolbarClasses.toolbar,
          {
            [toolbarClasses.highlight]: numSelected > 0,
          },
          classes.header
        )}
      >
        {numSelected > 0 ? (
          <Typography
            className={toolbarClasses.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {t("productCount", { count: numSelected })}
          </Typography>
        ) : (
          <Typography
            className={toolbarClasses.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t("commodities")}
          </Typography>
        )}
        {!screenIsSmall && filter}
        <IconButton
          aria-label="done"
          sx={{ color: "success.main" }}
          disabled={disabled}
          onClick={done}
          size="large"
        >
          <CheckCircle />
        </IconButton>
        {/* </Tooltip> */}

        <IconButton
          aria-label="close"
          onClick={() => clear()}
          color={"primary"}
          size="large"
        >
          <Cancel />
        </IconButton>
        {screenIsSmall && filter}
      </Toolbar>
    </StyledToolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  done: PropTypes.func.isRequired,
};

//*********Table Function*********

const COMMODITY_QUERY = gqlb.query({
  operation: "listCommodities",
  fields: [
    {
      operation: "data",
      fields: ["id", "code", "name"],
      variables: {},
    },
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
      required: true,
    },
    page: {
      type: "Int",
    },
    input: {
      type: "ListCommoditiesFilterInput",
    },
  },
});

const CommodityList = (props) => {
  const { type, variables, done, setSelected } = props;

  const [commodities, setCommodities] = useState([]);
  const [selectedCommodities, setSelectedCommodities] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const [drawerState, setDrawerState] = useState(false);
  const [queryVariables, setQueryVariables] = useState({
    refetch: true,
  });

  const { refetch, ...restVariables } = queryVariables;

  const { t } = useTranslation();
  const { data, loading } = useQuery(
    gql`
      ${COMMODITY_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !variables,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          ...(restVariables.search && { search: [...restVariables.search] }),
        },
      },
      onCompleted: (data) => {
        const handledData =
          data?.listCommodities?.data !== null
            ? data?.listCommodities?.data
            : [];

        let updatedDate = handledData.map((i) => {
          const selectedCommodity = selectedCommodities.find(
            (ele) => ele.commodity.id === i.id
          );
          if (selectedCommodity) {
            return selectedCommodity;
          } else {
            return {
              commodity: i,
              select: false,
            };
          }
        });
        setCommodities(updatedDate);
      },
    }
  );

  const isCommoditySelected = (addCommodities, deleteCommodities) => {
    const mainCommodities = addCommodities
      ? [...addCommodities]
      : [...selectedCommodities];

    const filterCommodities = deleteCommodities
      ? [...deleteCommodities]
      : [...selectedCommodities];

    return mainCommodities.filter((newCommodity) => {
      return !filterCommodities.some(
        (current) => current.commodity.id === newCommodity.commodity.id
      );
    });
  };

  const updateSelectedCommodityByForm = useCallback(
    ({ id, checkIsEmpty, name, value }) => {
      const update = [...commodities];
      const productIndex = update.findIndex((i) => {
        return i.commodity.id === id;
      });
      if (checkIsEmpty !== null || checkIsEmpty !== undefined)
        update[productIndex]["valid"] = checkIsEmpty;
      update[productIndex][name] = value;
      setCommodities(update);
    },
    [commodities]
  );

  const handleSelectAllClick = (event, rows) => {
    const selected = commodities?.map((i) => {
      if (event.target.checked) {
        return {
          ...i,
          select: true,
        };
      } else {
        return {
          ...i,
          select: false,
        };
      }
    });
    if (event.target.checked) {
      const newCommodities = isCommoditySelected(selected);
      setSelectedCommodities((prev) => [...prev, ...newCommodities]);
    } else {
      const filteredCommodities = isCommoditySelected(null, selected);
      setSelectedCommodities(filteredCommodities);
    }
    setCommodities(selected);
  };

  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const toggleFilterDrawer = () => setDrawerState(!drawerState);

  const resetPage = () => setPage(0);

  const filtersForm = (
    <SearchFilter
      {...{ resetPage, loading }}
      preventPushUrl
      queryVariables={(variables) => setQueryVariables(variables)}
      onSubmitFunc={() => setDrawerState(false)}
    />
  );

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const selectedCommodityLength = selectedCommodities.length;

  let tableBody = null;

  let progress = (
    <Grid
      container
      item
      justifyContent="center"
      className={classes.track}
      marginBottom="50px"
    >
      <CircularProgress />
    </Grid>
  );

  const selectedCommodityParPage = () => {
    return commodities.filter((i) => i.select === true).length;
  };
  const validCommodities = () => {
    return type === "PADD"
      ? selectedCommodityLength <= 0 ||
          selectedCommodities.some((i) => i.valid === false)
      : selectedCommodityLength <= 0;
  };

  const arrayData = commodities && commodities;

  if (commodities && !loading) {
    tableBody = (
      <Grid container justifyContent="center" sx={{ width: "100%" }}>
        {/* *******Table******* */}
        <div className={classes.paper}>
          <TableFixedHeaderWraper containerScroll className={classes.noScroll}>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <EnhancedTableHead
                numSelected={selectedCommodityParPage()}
                onSelectAllClick={(e) => handleSelectAllClick(e, commodities)}
                rowCount={commodities?.length}
                type={type ?? "PADD"}
              />
              <TableBody>
                {arrayData.map((commodity, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <CustomTableRow
                      key={commodity.commodity.id}
                      commodity={commodity}
                      type={type}
                      onChangeCheck={(check) => {
                        setCommodities((prev) => {
                          let updated = [...prev];
                          updated[index] = {
                            ...updated[index],
                            select: !updated[index].select,
                          };

                          if (check.target.checked) {
                            setSelectedCommodities((prev) => [
                              ...prev,
                              updated[index],
                            ]);
                          } else {
                            setSelectedCommodities((prev) => {
                              return prev.filter(
                                (i) => i.commodity.id !== commodity.commodity.id
                              );
                            });
                          }

                          return updated;
                        });
                      }}
                      labelId={labelId}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
          <MUITablePagination
            onRowsPerPageChange={handleRowsPerPage}
            count={data?.listCommodities?.paginatorInfo?.total ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePage}
            rowsPerPageOptions={[5, 10, 15]}
            disableLastPage
          />
        </div>
      </Grid>
    );
  }
  return (
    <PackageCommoditiesProvider value={{ updateSelectedCommodityByForm }}>
      <Root container justifyContent="center">
        <Grid item container sm={12} alignContent="flex-start">
          <EnhancedTableToolbar
            numSelected={selectedCommodityLength}
            clear={done}
            toggleFilterDrawer={toggleFilterDrawer}
            done={() => {
              done();
              setSelected(selectedCommodities);
            }}
            disabled={validCommodities()}
            filter={filtersForm}
          />

          {loading
            ? progress
            : commodities && commodities?.length !== 0
            ? tableBody
            : !loading && (
                <EmptyTableMessage
                  loading={false}
                  message={t("noCommodities")}
                />
              )}
        </Grid>
      </Root>
    </PackageCommoditiesProvider>
  );
};

CommodityList.propTypes = {
  type: PropTypes.string.isRequired,
  variables: PropTypes.any,
  done: PropTypes.any,
  setSelected: PropTypes.any,
  shipmentId: PropTypes.array,
  onClosePrepareDialog: PropTypes.func,
};

export default CommodityList;
