import React, { memo } from "react";
import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import { useTranslation } from "react-i18next";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import StatisticsFilterHeader from "./StatisticsFilterHeader";
import { ImStatsBars } from "react-icons/im";
import { styled } from "@mui/material/styles";
import ChartTable from "./CharTable";
import BasicCard from "./AppSummary";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Paper } from "@mui/material";
import { VscPackage, VscRocket, VscHome, VscArchive } from "react-icons/vsc";
import { FaCheck } from "react-icons/fa6";
import { GrTransaction } from "react-icons/gr";
import { LuCalendarCheck2 } from "react-icons/lu";

const STATISTICS = gqlb.query(
  [
    "totalShipment",
    "bookingShipment",
    "scheduledShipment",
    "loadingShipment",
    "transportingShipment",
    "arrivedShipment",
    "unloadedShipment",
    "deliveredShipment",
  ].map((operation) => ({
    operation: `${operation}:listShipments`,
    fields: [
      {
        operation: "paginatorInfo",
        fields: ["total"],
        variables: {},
      },
    ],
    variables: {
      first: { type: "Int" },
      [`${operation}Input`]: {
        name: "input",
        type: "ListShipmentsFilterInput",
      },
    },
  }))
);

const PREFIX = "PieChart";
const classes = { textColor: `${PREFIX}-textColor` };

const StyledCard = styled(Paper)(({ theme }) => ({
  width: "100%",
  minHeight: "200px",
  borderRadius: "10px",

  [`& .${classes.textColor}`]: { color: theme.palette.text.primary },
}));
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.textColor}`]: {
    [`& text`]: {
      fill: `${theme.palette.text.primary} !important`,
      fontFamily: "Cairo !important",
    },
  },
}));
const ICONS = {
  transporting: GrTransaction,
  scheduled: VscHome,
  arrived: VscArchive,
  booking: LuCalendarCheck2,
  loading: VscRocket,
  unloaded: VscPackage,
  delivered: FaCheck,
};

const COLORS = {
  transporting: "#f8dddd",
  scheduled: "#a2b0d6",
  arrived: "#f0f09c",
  booking: "#e2dbdb",
  loading: "#e2dbdb",
  unloaded: "#e3b5cc",
  delivered: "#d3fcdc",
};

const ICON_COLORS = {
  transporting: "#bb4a4a",
  scheduled: "#d62728",
  arrived: "#9f9f00",
  booking: "#676363",
  loading: "#9467bd",
  unloaded: "#9467bd",
  delivered: "#37c563",
};

const STATUS_LABELS = {
  transporting: "transport",
  scheduled: "scheduled",
  arrived: "arrived",
  booking: "booking",
  loading: "loading",
  unloaded: "unloaded",
  delivered: "delivered",
};

const TableChartShipment = ({
  dateRange,
  onDateRangeAccept,
  handleDateRange,
  classNamePieChart,
}) => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(
    gql`
      ${STATISTICS.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: Object.fromEntries(
        Object.keys(STATUS_LABELS).map((status) => [
          `${status}ShipmentInput`,
          {
            status: [status.toUpperCase()],
            ...(dateRange || {}),
          },
        ])
      ),
    }
  );

  const createStatusData = (status) => ({
    name: t(STATUS_LABELS[status]),
    total: data?.[`${status}Shipment`]?.paginatorInfo?.total || 0,
    percentage:
      ((data?.[`${status}Shipment`]?.paginatorInfo?.total || 0) /
        (data?.totalShipment?.paginatorInfo?.total || 1)) *
      100,
    color: COLORS[status],
    icon: ICONS[status],
    iconColor: ICON_COLORS[status],
  });

  const statuses = ["booking", "transporting", "arrived", "delivered"];
  const additionalStatuses = [
    "booking",
    "scheduled",
    "loading",
    "transporting",
    "arrived",
    "unloaded",
    "delivered",
  ];
  const dataSource = statuses.map(createStatusData);
  const dataCard = additionalStatuses.map(createStatusData);

  return (
    <div style={{ paddingInline: "8px" }}>
      <StyledCard elevation={5}>
        <Grid container spacing={2} m={0}>
          <Grid xs={12}>
            <StatisticsFilterHeader
              section="pieChart"
              onDateRangeAccept={onDateRangeAccept}
              handleDateRange={handleDateRange}
              title={t("shipmentStatistics")}
              icon={ImStatsBars}
            />
          </Grid>

          {loading ? (
            <Grid
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ height: 250 }}
            >
              <CustomSpinner name="PulseLoader" size={12} margin={2} />
            </Grid>
          ) : (
            <Box width={"100%"}>
              <Grid container justifyContent="space-around" m={0} spacing={2}>
                <Grid xs={12} md={6} alignItems="center">
                  {" "}
                  <Root>
                    <ChartTable
                      dataTable={dataCard}
                      classes={classes}
                      classNamePieChart={classNamePieChart}
                    />{" "}
                  </Root>
                </Grid>
                <Grid xs={12} md={6}>
                  <Box>
                    <Grid container spacing={3} m={0}>
                      {dataSource.map((item, index) => (
                        <Grid xs={12} sm={6} key={index}>
                          <BasicCard item={item} />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </StyledCard>
    </div>
  );
};

export default memo(TableChartShipment);
