import * as gqlb from "gql-query-builder";

export const SAVE_CHARGE_TYPES = gqlb.mutation({
  operation: "saveChargeType",
  fields: ["id", "code", "name"],
  variables: {
    input: {
      type: "ChargeTypeInput",
      required: true,
    },
  },
});

export const SAVE_CHARGE_TYPES_ELEMENT = gqlb.mutation({
  operation: "saveChargeElement",
  fields: ["id", "code"],
  variables: {
    input: {
      type: "ChargeElementInput",
      required: true,
    },
  },
});

export const CHARGE_TYPES = gqlb.query({
  operation: "chargeType",
  fields: [
    "id",
    "name",
    "date",
    "code",
    "payable",
    "active",
    "receivable",
    {
      payableGLAccount: ["id", "code", "name"],
    },
    {
      receivableGLAccount: ["id", "code", "name"],
    },
    {
      elements: [
        "id",
        "name",
        "ocean",
        "code",
        "active",
        "air",
        "land",
        "export",
        "import",
      ],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const LIST_CHARGE_TYPES = gqlb.query({
  operation: "listChargeTypes",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "name",
        "date",
        "code",
        "payable",
        "active",
        "receivable",
        {
          payableGLAccount: ["id", "code", "name"],
        },
        {
          receivableGLAccount: ["id", "code", "name"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
    input: {
      type: "ListChargeTypesFilterInput",
    },
  },
});

export const DELETE_CHARGE_TYPES_ELEMENT = gqlb.mutation({
  operation: "deleteChargeElement",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const DELETE_CHARGE_TYPES = gqlb.mutation({
  operation: "deleteChargeType",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
