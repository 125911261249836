import { Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { useRequestContext } from "../Context/RequestContext";
import Grid from "@mui/material/Unstable_Grid2";
import { CustomAutocomplete } from "../../HOC/MUI/CustomAutocomplete";
import {
  LIST_COUNTRIES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_SHIPPING_PORTS_DROPDOWN,
  LIST_VENDORS_DROPDOWN,
} from "../../../GlobalsQuery/ListDropdown/ListDropdown";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";
import MuiPhone from "../../HOC/MUI/MUIphoneNumber";

export const Step2 = () => {
  const {
    control,
    errors,
    t,
    disabled,
    calcFeesHandler,
    getValues,
    setValue,
    autocompleteValues,
    watch,
    onChangeVendor,
    onChangeCustomer,
    dateFormat,
  } = useRequestContext();
  return (
    <Stack spacing={2}>
      <Stack direction={{ md: "row" }} gap={2}>
        <Paper
          container
          component={Grid}
          spacing={2}
          m={0}
          width={{ md: "50%" }}
        >
          <Grid xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              color={"text.primary"}
              paddingX={1}
            >
              {t("origin")}
            </Typography>
          </Grid>
          <Grid sm={6} xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"originCountryId"}
              label={t("originCountry")}
              rules={{ required: t("fieldIsRequired") }}
              parseData={(data) => data}
              query={LIST_COUNTRIES_DROPDOWN.query}
              onChangeValue={(e) => {
                setValue("originPortId", "");
                onChangeVendor();
                setValue("vendorId", "");
                // if (watch("destinationCountryId") === e?.id) {
                //   setValue("destinationCountryId", "");
                // }
              }}
              disabled={disabled.addPackage}
              defaultValue={autocompleteValues.originCountry}
            />
          </Grid>
          <Grid sm={6} xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={Boolean(
                !watch("originCountryId") ||
                  !watch("shippingMode") ||
                  disabled.addPackage
              )}
              skip={Boolean(
                !watch("originCountryId") ||
                  !watch("shippingMode") ||
                  !watch("requestDate") ||
                  !watch("shippingDirection")
              )}
              name={"originPortId"}
              label={t("originPort")}
              rules={{ required: t("fieldIsRequired") }}
              parseData={(data) => data}
              query={LIST_SHIPPING_PORTS_DROPDOWN.query}
              variables={{
                input: {
                  countryId: watch("originCountryId"),
                  mode: watch("shippingMode"),
                  inPriceList: {
                    direction: "ORIGIN",
                    priceListFilterInput: {
                      mode: watch("shippingMode"),
                      direction: watch("shippingDirection"),
                      date: dateFormat(watch("requestDate")),
                    },
                  },
                },
              }}
              onChangeValue={(e) => {
                setValue("destinationPortId", "");
              }}
              defaultValue={autocompleteValues.originPort}
            />
          </Grid>
        </Paper>

        <Paper
          container
          component={Grid}
          spacing={2}
          m={0}
          width={{ md: "50%" }}
        >
          <Grid xs={12} alignItems="flex-start">
            <Typography
              variant="h6"
              gutterBottom
              color={"text.primary"}
              paddingX={1}
            >
              {t("destination")}
            </Typography>
          </Grid>
          <Grid sm={6} xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"destinationCountryId"}
              label={t("destinationCountry")}
              rules={{ required: t("fieldIsRequired") }}
              parseData={(data) => data}
              query={LIST_COUNTRIES_DROPDOWN.query}
              onChangeValue={(e) => {
                setValue("destinationPortId", "");
                onChangeCustomer();
                setValue("customerId", "");
                setValue("commissionAmount", "");
              }}
              defaultValue={autocompleteValues.destinationCountry}
              disabled={disabled.addPackage}
            />
          </Grid>
          <Grid sm={6} xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={Boolean(
                !watch("destinationCountryId") ||
                  !watch("shippingMode") ||
                  !watch("originPortId") ||
                  disabled.addPackage
              )}
              skip={Boolean(
                !watch("destinationCountryId") ||
                  !watch("shippingMode") ||
                  !watch("requestDate") ||
                  !watch("shippingDirection") ||
                  !watch("originPortId")
              )}
              name={"destinationPortId"}
              label={t("destinationPort")}
              rules={{ required: t("fieldIsRequired") }}
              parseData={(data) => data}
              query={LIST_SHIPPING_PORTS_DROPDOWN.query}
              variables={{
                input: {
                  countryId: watch("destinationCountryId"),
                  mode: watch("shippingMode"),
                  inPriceList: {
                    direction: "DESTINATION",
                    priceListFilterInput: {
                      mode: watch("shippingMode"),
                      direction: watch("shippingDirection"),
                      date: dateFormat(watch("requestDate")),
                    },
                    fromOriginPortId: watch("originPortId"),
                  },
                },
              }}
              defaultValue={autocompleteValues.destinationPort}
            />
          </Grid>
        </Paper>
      </Stack>
      <Stack direction={{ md: "row" }} gap={2}>
        <Paper
          container
          component={Grid}
          spacing={2}
          m={0}
          width={{ md: "50%" }}
        >
          <Grid xs={12} alignItems="flex-start">
            <Typography
              variant="h6"
              gutterBottom
              color={"text.primary"}
              paddingX={1}
            >
              {t("senderDetails")}
            </Typography>
          </Grid>

          <Grid xs={12} sm={12} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              name={"vendorId"}
              label={t("sender")}
              disabled={!watch("originCountryId") || disabled.addPackage}
              parseData={(data) => data}
              query={LIST_VENDORS_DROPDOWN.query}
              skip={!watch("originCountryId")}
              variables={{
                input: {
                  countryId: watch("originCountryId"),
                },
              }}
              // onCompleted={(data) => {
              //   handelAutocompleteDefaultValue(data, "vendorId");
              // }}
              onChangeValue={(e) => {
                onChangeVendor(e);
              }}
              defaultValue={autocompleteValues.vendor}
            />
          </Grid>

          <Grid xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              disabled={true}
              name={"shipperName"}
              label={t("senderName")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              disabled={true}
              name={"shipperAddress"}
              label={t("senderAddress")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>

          <Grid xs={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              disabled={true}
              name={"shipperPostalCode"}
              label={t("senderPostalCode")}
            />
          </Grid>

          <Grid xs={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              disabled={true}
              name={"shipperStateId"}
              label={t("senderState")}
            />
          </Grid>
          <Grid xs={12} alignItems="flex-start">
            <MuiPhone
              setValue={setValue}
              codeValue={getValues().shipperMobileCode}
              control={control}
              errors={errors}
              disabled={true}
              disabledCode={true}
              name={"shipperMobile"}
              selectName={"shipperMobileCode"}
              label={t("phone")}
              rules={{
                required: t("fieldIsRequired"),
              }}
            />
          </Grid>
        </Paper>
        <Paper
          container
          component={Grid}
          spacing={2}
          m={0}
          width={{ md: "50%" }}
        >
          <Grid xs={12} alignItems="flex-start">
            <Typography
              variant="h6"
              gutterBottom
              color={"text.primary"}
              paddingX={1}
            >
              {t("recipientDetails")}
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              name={"customerId"}
              label={t("recipient")}
              disabled={!watch("destinationCountryId") || disabled.addPackage}
              parseData={(data) => data}
              query={LIST_CUSTOMERS_DROPDOWN.query}
              skip={!watch("destinationCountryId")}
              variables={{
                input: {
                  countryId: watch("destinationCountryId"),
                },
              }}
              // onCompleted={(data) => {
              //   handelAutocompleteDefaultValue(
              //     data,
              //     autocompleteValues.customer.id,
              //     "customer"
              //   );
              // }}
              onChangeValue={(e) => {
                calcFeesHandler({
                  value: parseInt(e?.id),
                  name: "customerId",
                });
                onChangeCustomer(e);
              }}
              defaultValue={autocompleteValues.customer}
            />
          </Grid>
          <Grid xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              disabled={true}
              name={"consigneeName"}
              label={t("consigneeName")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              disabled={true}
              name={"consigneeAddress"}
              label={t("recipientAddress")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              disabled={true}
              name={"consigneePostalCode"}
              label={t("recipientPostalCode")}
            />
          </Grid>
          <Grid xs={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              disabled={true}
              name={"consigneeStateId"}
              label={t("recipientState")}
            />
          </Grid>
          <Grid xs={12} alignItems="flex-start">
            <MuiPhone
              setValue={setValue}
              codeValue={getValues().consigneeMobileCode}
              control={control}
              errors={errors}
              disabled={true}
              disabledCode={true}
              name={"consigneeMobile"}
              selectName={"consigneeMobileCode"}
              label={t("phone")}
              rules={{
                required: t("fieldIsRequired"),
              }}
            />
          </Grid>
        </Paper>
      </Stack>
    </Stack>
  );
};
