import { useQuery, gql, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  CHARGE_TYPES,
  SAVE_CHARGE_TYPES,
  SAVE_CHARGE_TYPES_ELEMENT,
} from "./Graphql";
import ChargeTypeTables from "./ChargeTypeTables";
import {
  Button,
  FormHelperText,
  Paper,
  Collapse,
  FormControlLabel,
  Switch,
  Typography,
  Box,
} from "@mui/material";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { useSnackbar } from "notistack";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { LIST_GL_ACCOUNTS_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { useHistory } from "react-router";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";

const ChargeTypeForm = (props) => {
  const { id } = props;
  const history = useHistory();

  const [chargeTypeList, setChargeTypeList] = useState();
  const [chargeTypeIndex, setChargeTypeIndex] = useState({
    index: 0,
    update: false,
  });

  const [dialog, setDialog] = useState({
    dialog: false,
    elements: false,
  });
  const [chargeTypeErrorMessage, setChargeTypeErrorMessage] = useState();
  const [disabled, setDisabled] = useState({
    payableGLAccount: false,
    receivableGLAccount: false,
  });
  const [active, setActive] = useState(true);
  const handelActivate = (e) => {
    const active = e.target.checked;
    setActive(active);
  };
  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const [pathURL, setPathURL] = useState(props.match.path);

  const [chargeTypeId, setChargeTypeId] = useState(
    props?.match?.params?.id === "0" ? 0 : parseInt(props?.match?.params?.id)
  );
  useEffect(() => {
    id && setChargeTypeId(id === "0" ? 0 : parseInt(id));
    return () => {};
  }, [id]);

  const { t } = useTranslation();

  const { control, handleSubmit, setValue, errors, reset, setError } = useForm({
    defaultValues: {
      active: true,
    },
  });
  const {
    control: mainControl,
    formState: { errors: mainErrors },
    handleSubmit: mainHandleSubmit,
    setValue: mainSetValue,
    setError: mainSetError,
    watch: mainWatch,
  } = useForm({
    defaultValues: {
      name: "",
      receivable: true,
      payable: true,
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const [saveChargeType, { loading: saveChargeTypeLoad }] = useMutation(
    gql`
      ${SAVE_CHARGE_TYPES.query}
    `
  );
  const [saveChargeElement] = useMutation(
    gql`
      ${SAVE_CHARGE_TYPES_ELEMENT.query}
    `
  );
  const [autocompleteValues, setAutocompleteValues] = useState({
    payableGLAccount: null,
    receivableGLAccount: null,
  });

  const { data: chargeTypeData, loading: chargeTypeLoading } = useQuery(
    gql`
      ${CHARGE_TYPES.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !chargeTypeId && chargeTypeId !== 0,
      variables: { id: chargeTypeId },
      onCompleted: (data) => {
        if (data?.chargeType?.elements) {
          setChargeTypeList(data?.chargeType.elements);
        }
        mainSetValue("receivable", data?.chargeType?.receivable);
        mainSetValue("payable", data?.chargeType?.payable);
        (chargeTypeId || chargeTypeId === 0) &&
          mainSetValue("code", data?.chargeType.code);
        const nameValue = data?.chargeType.name;
        mainSetValue("name", nameValue);
        setActive(data?.chargeType?.active);
        data?.chargeType.receivableGLAccount &&
          setDisabled((prev) => ({
            ...prev,
            receivableGLAccount: true,
          }));
        data?.chargeType.payableGLAccount &&
          setDisabled((prev) => ({
            ...prev,
            payableGLAccount: true,
          }));
        setAutocompleteValues({
          receivableGLAccount: data?.chargeType.receivableGLAccount,
          payableGLAccount: data?.chargeType.payableGLAccount,
        });
      },
    }
  );

  const parseData = (data) => {
    return data;
  };

  const handelCloseDialog = () => {
    setDialog({
      dialog: false,
      elements: false,
    });
    setChargeTypeErrorMessage(false);
    setChargeTypeIndex((prev) => ({ ...prev, update: false }));
    reset();
  };

  const addElementDialog = (index) => {
    reset();
    if (index || index === 0) {
      setChargeTypeIndex((prev) => ({
        ...prev,
        index,
        update: true,
      }));
    } else {
      setChargeTypeIndex({
        index,
        update: false,
      });
    }
    setDialog({
      dialog: true,
      elements: true,
    });
  };
  const onSubmitElement = (data) => {
    for (const key in data) {
      if (
        [undefined, "", null].includes(data[key]) ||
        (Array.isArray(data[key]) && data[key].length === 0)
      ) {
        data[key] = null;
      }
    }
    const newElement = {
      export: data.export,
      import: data.import,
      ocean: data.ocean,
      air: data.air,
      land: data.land,
      name: data.name,
      code: data.code,
      active: data.active,
    };
    const updateElements = chargeTypeList ? [...chargeTypeList] : [];

    if (chargeTypeIndex.update) {
      const updatedElements = {
        ...updateElements[chargeTypeIndex.index],
        ...newElement,
      };
      updateElements[chargeTypeIndex.index] = updatedElements;
      saveChargeElement({
        variables: {
          input: {
            ...(chargeTypeIndex.update && { id: updatedElements.id }),
            chargeTypeId: chargeTypeId,
            ...(newElement.code && { code: newElement?.code }),
            name: newElement?.name,
            export: newElement.export,
            import: newElement.import,
            ocean: newElement.ocean,
            air: newElement.air,
            land: newElement.land,
            active: newElement.active,
          },
        },
      })
        .then((data) => {
          setChargeTypeList(updateElements);
          enqueueSnackbar(t("saveSuccessful"), {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
          handelCloseDialog();
        })
        .catch(({ graphQLErrors }) => {
          if (graphQLErrors?.[0]?.extensions?.category === "validation") {
            setValidationError(graphQLErrors, setError);
          } else {
            setChargeTypeErrorMessage(graphQLErrors[0]?.message);
          }

          console.log(graphQLErrors);
        });
    } else {
      saveChargeElement({
        variables: {
          input: {
            chargeTypeId: chargeTypeId,
            ...(newElement.code && { code: newElement?.code }),
            name: newElement?.name,
            export: newElement.export,
            import: newElement.import,
            ocean: newElement.ocean,
            air: newElement.air,
            land: newElement.land,
            active: newElement.active,
          },
        },
      })
        .then((data) => {
          newElement.id = data.data?.saveChargeElement?.id;
          newElement.code = data.data?.saveChargeElement?.code;
          updateElements.unshift(newElement);
          setChargeTypeIndex({
            index: 0,
            update: false,
            duplicate: false,
          });
          setChargeTypeList(updateElements);
          enqueueSnackbar(t("saveSuccessful"), {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
          handelCloseDialog();
        })
        .catch(({ graphQLErrors }) => {
          if (graphQLErrors?.[0]?.extensions?.category === "validation") {
            setValidationError(graphQLErrors, setError);
          } else {
            setChargeTypeErrorMessage(graphQLErrors?.[0]?.message);
          }
        });
    }
  };

  const submitMutation = (data) => {
    for (const key in data) {
      if (data[key] === "" && key === "code") {
        delete data[key];
      }

      if (data[key] === "") {
        data[key] = null;
        continue;
      }
    }
    const createData = {
      ...(data.code && { code: data.code }),
      ...data,
      ...((chargeTypeId || chargeTypeId === 0) && {
        id: chargeTypeId,
      }),
      active: active,
    };

    saveChargeType({
      variables: {
        input: createData,
      },
    })
      .then((data) => {
        const url = history.createHref({
          pathname: `/admin/charge-types/${data?.data?.saveChargeType?.id}/edit`,
        });
        pushUrl(props, url);
        setPathURL(url);
        // windowReplaceUrl(url);
        setChargeTypeId(data?.data?.saveChargeType?.id);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        if (graphQLErrors?.[0]?.extensions?.category === "validation") {
          setValidationError(graphQLErrors, mainSetError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      });
  };

  const elementForm = (
    <ElementForm
      handleSubmit={handleSubmit}
      onSubmitElement={onSubmitElement}
      control={control}
      errors={errors}
      chargeTypeIndex={chargeTypeIndex}
      chargeTypeList={chargeTypeList}
      setValue={setValue}
      chargeTypeErrorMessage={chargeTypeErrorMessage}
      handelCloseDialog={handelCloseDialog}
      open={dialog.dialog}
    />
  );

  return chargeTypeLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !chargeTypeData && chargeTypeId ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      {dialog.dialog && elementForm}
      {pathURL && <TitleAppBar path={pathURL} />}
      <Grid
        container
        spacing={2}
        className={classesForm.mainGrid}
        sx={{ position: "relative" }}
        component="form"
        onSubmit={mainHandleSubmit(submitMutation)}
      >
        <Paper container component={Grid} sx={{ margin: "0px", width: "100%" }}>
          <Grid
            justifyContent="end"
            sx={{ display: "flex", flexWrap: "wrap" }}
            xs={12}
          >
            <FormControlLabel
              checked={active}
              control={<Switch color="primary" />}
              label={t("active")}
              labelPlacement="start"
              onChange={handelActivate}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <ControlMUItextField
              control={mainControl}
              errors={mainErrors}
              name="code"
              label={t("code")}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <ControlMUItextField
              control={mainControl}
              errors={mainErrors}
              name="name"
              label={t("name")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid
            sm={6}
            xs={12}
            md={6}
            alignItems="flex-start"
            sx={{ paddingLeft: "24px" }}
          >
            <MuiSwitch
              edge="end"
              name="receivable"
              label={t("receivable")}
              disabled={disabled?.receivableGLAccount}
              control={mainControl}
              onChange={(e) => {
                mainSetValue("receivableGLAccountId", "");
              }}
            />
          </Grid>
          <Grid sm={6} xs={12} md={6} alignItems="flex-start">
            <CustomAutocomplete
              control={mainControl}
              errors={mainErrors}
              name={"receivableGLAccountId"}
              label={t("receivableGLAccount")}
              rules={{
                required: mainWatch("receivable")
                  ? t("fieldIsRequired")
                  : false,
              }}
              disabled={
                !mainWatch("receivable") || disabled?.receivableGLAccount
              }
              parseData={(data) => parseData(data)}
              query={LIST_GL_ACCOUNTS_DROPDOWN.query}
              variables={{
                input: {
                  typeCode: "SUB",
                },
              }}
              onCompleted={(data) =>
                handelAutocompleteDefaultValue(
                  data,
                  autocompleteValues.payableGLAccount,
                  "receivableGLAccountId"
                )
              }
              defaultValue={autocompleteValues.receivableGLAccount}
            />
          </Grid>
          <Grid
            sm={6}
            xs={12}
            md={6}
            alignItems="flex-start"
            sx={{ paddingLeft: "24px" }}
          >
            <MuiSwitch
              edge="end"
              disabled={disabled?.payableGLAccount}
              name="payable"
              label={t("payable")}
              control={mainControl}
              onChange={(e) => {
                mainSetValue("payableGLAccountId", "");
              }}
            />
          </Grid>
          <Grid sm={6} xs={12} md={6} alignItems="flex-start">
            <CustomAutocomplete
              control={mainControl}
              errors={mainErrors}
              rules={{
                required: mainWatch("payable") ? t("fieldIsRequired") : false,
              }}
              disabled={!mainWatch("payable") || disabled?.payableGLAccount}
              name={"payableGLAccountId"}
              label={t("payableGLAccount")}
              parseData={(data) => parseData(data)}
              query={LIST_GL_ACCOUNTS_DROPDOWN.query}
              variables={{
                input: {
                  typeCode: "SUB",
                },
              }}
              onCompleted={(data) =>
                handelAutocompleteDefaultValue(
                  data,
                  autocompleteValues.payableGLAccount,
                  "payableGLAccountId"
                )
              }
              defaultValue={autocompleteValues.payableGLAccount}
            />
          </Grid>
          <Grid xs={12} justifyContent="flex-end" display="flex">
            <FormButton
              className={classesForm.button}
              disabled={saveChargeTypeLoad}
            >
              {saveChargeTypeLoad ? <ButtonLoading /> : t("save")}
            </FormButton>
          </Grid>
        </Paper>
      </Grid>

      <Grid container sx={{ position: "relative" }}>
        {!active && (
          <Box
            className={classesForm.overlay}
            sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
          ></Box>
        )}
        <ChargeTypeTables
          chargeTypeId={chargeTypeId}
          chargeTypeList={chargeTypeList}
          addElements={addElementDialog}
          setChargeTypeList={setChargeTypeList}
        />
      </Grid>
    </RootStyleForm>
  );
};

export default ChargeTypeForm;
function ElementForm({
  handleSubmit,
  onSubmitElement,
  control,
  errors,
  chargeTypeIndex,
  chargeTypeList,
  setValue,
  chargeTypeErrorMessage,
  handelCloseDialog,
  open,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (chargeTypeIndex.update) {
      const update = chargeTypeList[chargeTypeIndex.index];
      setValue("active", update?.active);
      setValue("name", update?.name);
      setValue("code", update?.code);
      setValue("air", update?.air);
      setValue("land", update?.land);
      setValue("ocean", update?.ocean);
      setValue("import", update?.import);
      setValue("export", update?.export);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const body = (
    <Grid
      container
      justifyContent="flex-start"
      aligns="center"
      className={classesForm.mainGrid}
      spacing={2}
    >
      <Grid xs={12} sm={12} aligns="flex-start">
        <ControlMUItextField
          control={control}
          errors={errors}
          name="code"
          label={t("code")}
        />
      </Grid>
      <Grid xs={12} sm={12} aligns="flex-start">
        <ControlMUItextField
          control={control}
          errors={errors}
          name="name"
          label={t("name")}
          rules={{ required: t("fieldIsRequired") }}
        />
      </Grid>
      <Grid item xs={6}>
        <MuiSwitch edge="end" name="land" label={t("land")} control={control} />
      </Grid>
      <Grid item xs={6}>
        <MuiSwitch
          edge="end"
          name="export"
          label={t("export")}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <MuiSwitch
          edge="end"
          name="ocean"
          control={control}
          label={t("ocean")}
        />
      </Grid>
      <Grid item xs={6}>
        <MuiSwitch
          edge="end"
          name="import"
          label={t("import")}
          control={control}
        />
      </Grid>
      <Grid item xs={6}>
        <MuiSwitch edge="end" name="air" control={control} label={t("air")} />
      </Grid>
      {chargeTypeErrorMessage && (
        <FormHelperText error>{chargeTypeErrorMessage}</FormHelperText>
      )}
    </Grid>
  );

  const title = (
    <Grid container justifyContent="space-between" alignItems={"center"}>
      <Typography variant="h6" color={"text.primary"}>
        {t("elements")}
      </Typography>
      <Box>
        <MuiSwitch
          edge="end"
          name="active"
          label={t("active")}
          control={control}
        />
      </Box>
    </Grid>
  );
  return (
    <>
      {open && (
        <CustomDialog
          fullWidth
          maxWidth="xs"
          open={open}
          onClose={handelCloseDialog}
          title={title}
          content={body}
          actions={
            <>
              <Button onClick={handelCloseDialog}>{t("cancel")}</Button>
              <Button onClick={handleSubmit(onSubmitElement)}>
                {t("confirm")}
              </Button>
            </>
          }
        ></CustomDialog>
      )}
    </>
  );
}
