import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  DialogTitle,
  DialogContent,
  FormHelperText,
  DialogActions,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { LIST_GL_ACCOUNTS_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { SAVE_FINANCIAL_SHEET_DETAIL } from "./Graphql";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";

const PREFIX = "BranchList";

const classes = {
  mainGrid: `${PREFIX}-mainGrid`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  [`& .${classes.button}`]: {
    width: "100%",
    paddingRight: 0,
  },
}));

export default function FinancialSheetLineDetailForm(props) {
  const {
    LineList,
    lineIndex,
    setLineList,
    selectedNames,
    onChangeNames,
    handelCloseDialog,
    detailErrorMessage,
    setDetailErrorMessage,
    detailIndex,
    financialSheetData,
  } = props;
  const { handleSubmit, control, setError, errors, setValue } = useForm();
  const { t } = useTranslation();

  const [
    saveFinancialSheetLineDetail,
    { loading: saveFinancialSheetDetailLoad },
  ] = useMutation(
    gql`
      ${SAVE_FINANCIAL_SHEET_DETAIL.query}
    `
  );
  const [autocompleteValues, setAutocompleteValues] = useState({
    glAccountId: null,
  });
  useEffect(() => {
    if (detailIndex?.update) {
      const detail = LineList[lineIndex?.index]?.details[detailIndex?.index];
      setAutocompleteValues({
        glAccountId: detail?.glAccount,
      });
      setValue("number", detail?.number);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailIndex]);

  const onSubmit = (data) => {
    for (const key in data) {
      if (
        [undefined, "", null].includes(data[key]) ||
        (Array.isArray(data[key]) && data[key].length === 0)
      ) {
        delete data[key];
      }
    }
    const newDetail = {
      glAccount: {
        id: data.glAccountId,
        name: selectedNames.glAccountId,
      },
      number: data.number,
    };
    const details = LineList[lineIndex?.index]?.details
      ? [...LineList[lineIndex?.index]?.details]
      : [];
    const updateLines = LineList ? [...LineList] : [];

    if (detailIndex?.update) {
      saveFinancialSheetLineDetail({
        variables: {
          input: {
            id: details[detailIndex?.index]?.id,
            sheetLineId: LineList[lineIndex?.index]?.id,
            glAccountId: newDetail.glAccount?.id,
            ...(newDetail.number && { number: parseInt(newDetail.number) }),
          },
        },
      })
        .then((data) => {
          newDetail.id = data.data?.saveFinancialSheetLineDetail?.id;
          updateLines[lineIndex?.index].details[detailIndex?.index] = newDetail;
          setLineList(updateLines);
          handelCloseDialog();
        })
        .catch(({ graphQLErrors }) => {
          console.log(graphQLErrors);
          if (graphQLErrors?.[0]?.extensions?.category === "validation") {
            setValidationError(graphQLErrors, setError);
          } else {
            setDetailErrorMessage(graphQLErrors[0]?.message);
          }
        });
    } else {
      saveFinancialSheetLineDetail({
        variables: {
          input: {
            sheetLineId: LineList[lineIndex?.index]?.id,
            glAccountId: newDetail.glAccount?.id,
            ...(newDetail.number && { number: parseInt(newDetail.number) }),
          },
        },
      })
        .then((data) => {
          newDetail.id = data.data?.saveFinancialSheetLineDetail?.id;
          newDetail.number = data.data?.saveFinancialSheetLineDetail?.number;
          details.push(newDetail);
          updateLines[lineIndex.index] = {
            ...updateLines[lineIndex.index],
            details: details,
          };
          setLineList(updateLines);
          handelCloseDialog();
        })
        .catch(({ graphQLErrors }) => {
          console.log(graphQLErrors);
          if (graphQLErrors?.[0]?.extensions?.category === "validation") {
            setValidationError(graphQLErrors, setError);
          } else {
            setDetailErrorMessage(graphQLErrors[0]?.message);
          }
        });
    }
  };

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };

  const parseData = (data) => {
    return data;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle color={"text.primary"}>{t("lineDetail")}</DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Root>
          <Grid
            container
            justifyContent="flex-start"
            aligns="center"
            className={clsx(classes.mainGrid)}
            spacing={2}
          >
            <Grid xs={12} sm={12} aligns="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                type={"number"}
                name={"number"}
                label={t("numberLine")}
              />
            </Grid>
            <Grid xs={12} sm={12} alignItems="flex-start">
              <CustomAutocomplete
                control={control}
                errors={errors}
                rules={{ required: t("fieldIsRequired") }}
                name={"glAccountId"}
                label={t("accountName")}
                parseData={(data) => parseData(data)}
                query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                variables={{
                  input: {
                    // typeCode: "SUB",
                    ...(financialSheetData?.financialSheet?.branch?.id && {
                      branchId: {
                        value: financialSheetData?.financialSheet?.branch?.id,
                        includeNull: true,
                      },
                    }),
                  },
                }}
                onChangeValue={(e) => {
                  onChangeNames(e, "glAccountId");
                }}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    autocompleteValues.glAccountId,
                    "glAccountId"
                  )
                }
                defaultValue={autocompleteValues.glAccountId}
              />
            </Grid>
            {detailErrorMessage && (
              <FormHelperText error>{detailErrorMessage}</FormHelperText>
            )}
          </Grid>
        </Root>
      </DialogContent>
      <DialogActions>
        <Button onClick={handelCloseDialog}>{t("cancel")}</Button>
        <Button type="submit">
          {saveFinancialSheetDetailLoad ? <ButtonLoading /> : t("confirm")}
        </Button>
      </DialogActions>
    </form>
  );
}
