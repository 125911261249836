import React, { useState } from "react";
import {
  Paper,
  Collapse,
  Stack,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { SAVE_COMMODITY, COMMODITY_ID } from "./Graphql";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import FormButton from "../CustomComponents/Buttons/FormButton";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";

const CommodityForm = (props) => {
  const [activePort, setActivePort] = useState(true);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [commodityId, setShippingPortId] = useState(
    parseInt(props.match.params.id)
  );
  const { handleSubmit, control, formState, setValue, setError } = useForm();
  const { errors } = formState;

  const [saveCommodity, { loading: saveCommodityLoading }] = useMutation(
    gql`
      ${SAVE_COMMODITY.query}
    `
  );

  const { data: updateCommodity, loading: updateCommodityLoading } = useQuery(
    gql`
      ${COMMODITY_ID.query}
    `,

    {
      skip: !commodityId,
      variables: { id: commodityId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const commodityData = data.commodity;
        setActivePort(commodityData.active);
        const shippingPortParams = ["code", "name"];
        shippingPortParams.forEach((i) => {
          commodityData[i] && setValue(i, commodityData[i]);
        });
        setValue("active", commodityData["active"]);
      },
    }
  );

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActivePort(active);
    setValue("active", active);
  };
  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
        continue;
      }
    }

    saveCommodity({
      variables: {
        input: {
          ...data,
          active: activePort,
          ...(commodityId && { id: commodityId }),
        },
      },
    })
      .then((data) => {
        setShippingPortId(data?.data?.saveCommodity?.id);
        pushUrl(props, `/admin/commodities/${data?.data?.saveCommodity?.id}`);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid
          justifyContent="end"
          sx={{ display: "flex", flexWrap: "wrap" }}
          xs={12}
        >
          <FormControlLabel
            checked={activePort}
            control={<Switch color="primary" />}
            label={t("active")}
            labelPlacement="start"
            onChange={handelActivate}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"code"}
            label={t("code")}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"name"}
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
      </Paper>
      <Grid container justifyContent="flex-end" className={classesForm.spacing}>
        <FormButton disabled={saveCommodityLoading}>
          {saveCommodityLoading ? <ButtonLoading /> : t("save")}
        </FormButton>
      </Grid>
    </form>
  );
  let DOM;

  if (commodityId) {
    DOM = updateCommodity ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return updateCommodityLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !updateCommodity && commodityId ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={props.match.path} />
      <Stack spacing={2} p={2}>
        {DOM}
      </Stack>
    </RootStyleForm>
  );
};

export default CommodityForm;
