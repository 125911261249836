import React, { useState } from "react";
import {
  Paper,
  Collapse,
  Stack,
  FormControlLabel,
  Switch,
  Dialog,
  DialogContent,
} from "@mui/material";
import { SAVE_BANK_BRANCH, BANK_BRANCH } from "./Graphql";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import FormButton from "../CustomComponents/Buttons/FormButton";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_BANKS_DROPDOWN,
  LIST_COUNTRIES_DROPDOWN,
  LIST_STATES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import MuiPhone from "../HOC/MUI/MUIphoneNumber";
import getMobileData, {
  getFormatNumber,
  validNumber,
} from "../../helpers/asYouType";
import BankBranchesTable from "./BankBranchesTable";
import AccountsForm from "./AccountsForm";
import { Globals } from "../HOC/Classes/Globals";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";
const BankBranchForm = (props) => {
  const [pathURL] = useState(props.match.path);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const user = Globals.user;
  const [id, setId] = useState(parseInt(props.match.params.id));
  const [active, setActive] = useState(true);
  const [dialogState, setDialogState] = useState(false);
  const [updateAccounts, setUpdateAccounts] = useState(null);
  const [autocompleteValues, setAutocompleteValues] = useState({
    country: null,
    state: null,
    bank: null,
    area: null,
    city: null,
    currency: null,
    type: null,
    glAccount: null,
  });
  const {
    handleSubmit,
    control,
    formState,
    setValue,
    setError,
    watch,
    getValues,
  } = useForm();
  const { errors } = formState;

  const [saveBankBranch, { loading: saveBankBranchLoading }] = useMutation(
    gql`
      ${SAVE_BANK_BRANCH.query}
    `
  );

  const { data, loading: updateLoading } = useQuery(
    gql`
      ${BANK_BRANCH.query}
    `,

    {
      skip: !id,
      variables: { id: id },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const bankBranchData = data.bankBranch;
        setActive(bankBranchData.active);
        setAccounts(bankBranchData?.accounts);
        const bankBranchParams = [
          "id",
          "code",
          "name",
          "address",
          "faxNumber",
          "postalCode",
          "mailBoxNumber",
          "emailAddress",
          "active",
        ];
        bankBranchParams.forEach((i) => {
          bankBranchData[i] !== null && setValue(i, bankBranchData[i]);
        });
        const numbersObj = [
          {
            name: "phone",
            codeName: "phoneCode",
            value: getMobileData(bankBranchData.phone),
          },
        ];
        numbersObj.forEach((i) => {
          if (i.value !== null) {
            setValue(i.codeName, i.value.country.toLowerCase());
            setValue(i.name, i.value.nationalNumber);
          }
        });
        setAutocompleteValues((prev) => ({
          ...prev,

          country: bankBranchData.country,
          state: bankBranchData.state,
          bank: bankBranchData.bank,
          city: bankBranchData.city,
          area: bankBranchData.area,
        }));
      },
    }
  );
  const accountsArr = data?.bankBranch?.accounts ?? [];
  const [accounts, setAccounts] = useState(accountsArr);

  const parseData = (data) => {
    return data;
  };

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActive(active);
  };
  const closeDialog = () => {
    setDialogState(false);
    setUpdateAccounts(null);
    setAutocompleteValues((prev) => ({
      ...prev,
      currency: null,
      type: null,
      glAccount: null,
    }));
  };
  const onSubmit = (data) => {
    const checkPhone = validNumber(data.phone, data.phoneCode, "notRequired");
    let names = [
      {
        name: "phone",
        validate: checkPhone.valid,
        message: checkPhone.message,
      },
    ];
    if (!checkPhone.valid) {
      names.map(
        (e) =>
          !e.validate &&
          setError(e.name, { type: "custom", message: t(e.message) })
      );
      return;
    }

    data.phone = data.phone && getFormatNumber(data.phone, data.phoneCode);

    for (const key in data) {
      if (
        (data[key] === "" && key === "code") ||
        key === "phoneCode" ||
        key === "mobileCode"
      ) {
        delete data[key];
      }

      if (data[key] === "") {
        data[key] = null;
        continue;
      }
    }

    saveBankBranch({
      variables: {
        input: {
          ...data,
          active: active,
          ...(id && { id: id }),
        },
      },
    })
      .then((data) => {
        setId(data?.data?.saveBankBranch?.id);

        pushUrl(
          props,
          `/admin/bank-branches/${data?.data?.saveBankBranch?.id}`
        );

        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };

  const FormAccounts = (
    <AccountsForm
      closeDialog={closeDialog}
      autocompleteValues={autocompleteValues}
      setAutocomplete={setAutocompleteValues}
      accounts={accounts}
      setAccounts={setAccounts}
      bankBranchId={id}
      updateAccounts={updateAccounts}
    />
  );

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid
          justifyContent="end"
          sx={{ display: "flex", flexWrap: "wrap" }}
          xs={12}
        >
          <FormControlLabel
            checked={active}
            control={<Switch color="primary" />}
            label={t("active")}
            labelPlacement="start"
            onChange={handelActivate}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"code"}
            label={t("code")}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"name"}
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid sm={4} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"bankId"}
            disabled={Boolean(id)}
            label={t("bank")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => parseData(data)}
            query={LIST_BANKS_DROPDOWN.query}
            defaultValue={autocompleteValues.bank}
          />
        </Grid>
        <Grid xs={12} sm={4} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"countryId"}
            label={t("country")}
            parseData={(data) => parseData(data)}
            query={LIST_COUNTRIES_DROPDOWN.query}
            onChangeValue={(e) => {
              setValue("stateId", "");
            }}
            defaultValue={autocompleteValues.country}
          />
        </Grid>
        <Grid xs={12} sm={4} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            disabled={!watch("countryId")}
            skip={!watch("countryId")}
            name={"stateId"}
            label={t("state")}
            parseData={(data) => parseData(data)}
            query={LIST_STATES_DROPDOWN.query}
            variables={{
              countryId: watch("countryId"),
            }}
            defaultValue={autocompleteValues.state}
          />
        </Grid>

        <Grid xs={12} sm={4} alignItems="flex-start">
          <MuiPhone
            setValue={setValue}
            codeValue={getValues().phoneCode}
            control={control}
            errors={errors}
            name={"phone"}
            selectName={"phoneCode"}
            label={t("phone")}
          />
        </Grid>

        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"address"}
            label={t("address")}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"postalCode"}
            label={t("postalCode")}
          />
        </Grid>
      </Paper>

      <Grid container justifyContent="flex-end">
        <FormButton disabled={saveBankBranchLoading}>
          {saveBankBranchLoading ? <ButtonLoading /> : t("save")}
        </FormButton>
      </Grid>
    </form>
  );
  let DOM;

  if (id) {
    DOM = data ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return updateLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data && id ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={pathURL} />
      <Stack spacing={2} p={2}>
        {DOM}
      </Stack>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={dialogState}
        onClose={() => {
          closeDialog();
        }}
      >
        <DialogContent>{FormAccounts}</DialogContent>
      </Dialog>
      {user.hasPermission("cash.bank_account.list") && (
        <Stack spacing={2} px={2}>
          <BankBranchesTable
            accounts={accounts}
            bankBranchId={id}
            setDialogState={setDialogState}
            setUpdateAccounts={setUpdateAccounts}
            updateAccounts={updateAccounts}
            loading={updateLoading}
          />
        </Stack>
      )}
    </RootStyleForm>
  );
};

export default BankBranchForm;
