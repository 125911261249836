import * as gqlb from "gql-query-builder";
const fields = [
  "id",
  "code",
  "operationDate",
  "approved",
  "remarks",
  {
    branch: ["id", "name", "code"],
  },
  {
    transactionType: ["id", "name", "code"],
  },
  {
    warehouse: ["id", "name", "code"],
  },
  {
    request: ["id", "code", "detailsCount"],
  },
  {
    shipment: ["id", "code"],
  },
  { type: ["id", "code"] },
  { manifests: ["id"] },
];
const commonViewForm = [
  {
    request: [
      {
        packages: [
          "id",
          "code",
          "fragile",
          "height",
          "weight",
          "length",
          "breadth",
          "count",
          "totalWeight",
          "pieces",
          "volume",
          "volumetricWeight",
          "volumetricFactor",
          "chargeableUnits",
          "chargePrice",
          "chargeAmount",
          "description",
          "exchangeRate",
          "productPrice",
          { packageType: ["id", "name", "code"] },
          { product: ["id", "name", "code"] },
          { originCountry: ["id", "name", "code"] },
          { priceMode: ["id", "name", "code"] },
          { currency: ["id", "name", "code"] },
          {
            image: ["path"],
          },
          {
            commodities: [
              "count ",
              "id",
              { commodity: ["id", "name", "code"] },
            ],
          },
        ],
      },
    ],
    shipment: [
      {
        containers: [
          "id",
          "containerNumber",
          { packageType: ["id", "name", "code"] },
        ],
      },
      {
        originPort: ["id", "name", "code"],
      },
      {
        destinationPort: ["id", "name", "code"],
      },
      {
        shippingMode: ["id", "name", "code"],
      },
      {
        shippingDirection: ["id", "name", "code"],
      },
    ],
    // manifests: [
    //   "id",
    //   {
    //     container: [
    //       "id",
    //       "containerNumber",
    //       { packageType: ["id", "name", "code"] },
    //     ],
    //   },
    //   {
    //     packageDetail: [
    //       "id",
    //       "barcode",
    //       "pieceNumber",
    //       { warehouse: ["id", "name", "code"] },
    //     ],
    //   },
    // ],
  },
];
const viewFields = [
  {
    request: [
      {
        shippingDirection: ["id", "name", "code"],
      },
      {
        shippingMode: ["id", "name", "code"],
      },
      {
        loadingMode: ["id", "name", "code"],
      },
      {
        originPort: ["id", "name", "code"],
      },
      {
        destinationPort: ["id", "name", "code"],
      },
      {
        customer: [
          {
            priceModes: [
              { shippingMode: ["id", "name", "code"] },
              { priceMode: ["id", "name", "code"] },
            ],
          },
        ],
      },

      "requestDate",
    ],
  },
];
export const LIST_OPERATIONS = gqlb.query({
  operation: "listOperations",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: fields,
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListOperationsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});
export const FREIGHT_SETTING = gqlb.query({
  operation: "freightSettings",
  fields: ["volumetricFactor", "createRequestInPast"],
  variables: {},
});

export const OPERATION_ID = gqlb.query({
  operation: "operation",
  fields: [...fields, ...commonViewForm, ...viewFields],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const OPERATION_VIEW_ID = gqlb.query({
  operation: "operation",
  fields: [...fields, ...commonViewForm, "deletable"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const TRANSACTION_TYP_ID = gqlb.query({
  operation: "transactionType",
  fields: ["id", "import", "air", "land", "ocean", "export"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const REQUEST_PACKAGE_DETAILS = gqlb.query([
  {
    operation: "request",
    fields: [
      {
        packages: [
          "id",
          {
            operation: `details`,
            fields: [
              {
                operation: `paginatorInfo`,
                fields: [
                  "count",
                  "currentPage",
                  "total",
                  "firstItem",
                  "hasMorePages",
                  "lastItem",
                  "lastPage",
                  "perPage",
                ],
                variables: {},
              },
              {
                operation: `data`,
                fields: [
                  "id",
                  "missing",
                  "damaged",
                  "barcode",
                  "pieceNumber",
                  { warehouse: ["id", "name", "code"] },
                  {
                    package: ["id", { request: ["detailsCount"] }],
                  },
                ],
                variables: {},
              },
            ],
            variables: {
              first: {
                type: "Int",
              },
              page: {
                type: "Int",
              },
            },
          },
        ],
      },
    ],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
]);

export const SAVE_OPERATION = gqlb.mutation({
  operation: "saveOperation",
  fields: ["id", "code"],
  variables: {
    input: {
      type: "OperationInput",
      required: true,
    },
  },
});
export const SAVE_PACKAGE_DETAILS = gqlb.mutation({
  operation: "updatePackageDetails",
  fields: [
    "id",
    "missing",
    "damaged",
    "barcode",
    "pieceNumber",
    { warehouse: ["id", "name", "code"] },
    {
      package: ["id", { request: ["detailsCount"] }],
    },
  ],
  variables: {
    input: {
      type: "PackageDetailsInput",
      required: true,
    },
  },
});

export const DELETE_PACKAGE_COMMODITY = gqlb.mutation({
  operation: "deletePackageCommodity",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
const VendorFields = [
  "id",
  "code",
  "name",
  "address",
  "postalCode",
  "mobile",
  { state: ["id", "name", "code"] },
];

export const PACKAGE_ID = gqlb.query({
  operation: "packageType",
  fields: ["height", "length", "breadth", "volume"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_REQUEST_PACKAGE = gqlb.mutation({
  operation: "saveRequestPackage",
  fields: [
    "id",
    "code",
    "fragile",
    "height",
    "weight",
    "length",
    "breadth",
    "count",
    "totalWeight",
    "pieces",
    "volume",
    "volumetricWeight",
    "volumetricFactor",
    "chargeableUnits",
    "chargePrice",
    "chargeAmount",
    "description",
    "exchangeRate",
    "productPrice",
    { currency: ["id", "name", "code"] },
    { packageType: ["id", "name", "code"] },
    { priceMode: ["id", "name", "code"] },
    { originCountry: ["id", "name", "code"] },
    { warehouse: ["id", "name", "code"] },
    { product: ["id", "name", "code"] },

    {
      image: ["path"],
    },
    { commodities: ["count ", "id", { commodity: ["id", "name", "code"] }] },
  ],
  variables: {
    input: {
      type: "RequestPackageInput",
      required: true,
    },
  },
});
export const REQUEST_PACKAGE_ID = gqlb.query({
  operation: "requestPackage",
  fields: VendorFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const CALCULATE_FEES_QUERY = gqlb.query({
  operation: "calculateRequestPackageFees",
  fields: [
    "totalWeight",
    "volume",
    "volumetricWeight",
    "chargeAmount",
    "chargeableUnits",
    "chargePrice",
  ],
  variables: {
    input: {
      type: "CalculateRequestPackageFeesInput",
      required: true,
    },
  },
});

export const AVAILABLE_PRICE_LIST = gqlb.query({
  operation: "availablePriceList",
  fields: [
    {
      currency: ["id", "name", "code"],
    },
  ],
  variables: {
    input: {
      type: "AvailablePriceListInput",
      required: true,
    },
  },
});

export const CURRENCY_ID = gqlb.query({
  operation: "currency",
  fields: [{ exchangeRate: ["updatable", "rate", "id"] }],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const DELETE_REQUEST_PACKAGE = gqlb.mutation({
  operation: "deleteRequestPackage",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const LIST_PACKAGE_DETAILS_LOADING = gqlb.query({
  operation: "listPackageDetailsForLoading",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "missing",
        "damaged",
        "barcode",
        "pieceNumber",
        { warehouse: ["id", "name", "code"] },
        {
          package: [
            "id",
            "weight",
            "fragile",
            "description",
            { product: ["id", "name", "code"] },
            {
              request: [
                "code",
                "loaded",
                "unloaded",
                "id",
                "detailsCount",
                { originCountry: ["id", "name", "code"] },
                { destinationCountry: ["id", "name", "code"] },
                { loadingMode: ["id", "name", "code"] },
                { customer: ["id", "name", "code"] },
                { vendor: ["id", "name", "code"] },
              ],
            },
            {
              packageType: ["id", "name", "code"],
            },
          ],
        },
        "unloaded",
        "loaded",
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListPackageDetailsForLoadingFilterInput!",
      filter: "ListPackageDetailsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});
export const LIST_PACKAGE_DETAILS_UNLOADING = gqlb.query({
  operation: "listPackageDetailsForUnloading",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "missing",
        "damaged",
        "barcode",
        "pieceNumber",
        { warehouse: ["id", "name", "code"] },
        {
          package: [
            "id",
            "weight",
            "fragile",
            "description",
            { product: ["id", "name", "code"] },
            {
              request: [
                "code",
                "loaded",
                "unloaded",
                "id",
                "detailsCount",
                { originCountry: ["id", "name", "code"] },
                { destinationCountry: ["id", "name", "code"] },
                { loadingMode: ["id", "name", "code"] },
                { customer: ["id", "name", "code"] },
                { vendor: ["id", "name", "code"] },
              ],
            },
            {
              packageType: ["id", "name", "code"],
            },
          ],
        },

        "loaded",
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListPackageDetailsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});
export const LIST_PACKAGE_DETAILS_DELIVERED = gqlb.query({
  operation: "listPackageDetailsForDelivery",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "missing",
        "damaged",
        "barcode",
        "pieceNumber",
        { warehouse: ["id", "name", "code"] },
        {
          package: [
            "id",
            "weight",
            "fragile",
            "description",
            { product: ["id", "name", "code"] },
            {
              request: [
                "code",
                "loaded",
                "unloaded",
                "id",
                "detailsCount",
                { originCountry: ["id", "name", "code"] },
                { destinationCountry: ["id", "name", "code"] },
                { loadingMode: ["id", "name", "code"] },
                { customer: ["id", "name", "code"] },
                { vendor: ["id", "name", "code"] },
              ],
            },
            {
              packageType: ["id", "name", "code"],
            },
          ],
        },

        "loaded",
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListPackageDetailsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const LIST_PACKAGE_DETAILS = gqlb.query({
  operation: "listPackageDetails",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "missing",
        "damaged",
        "barcode",
        "pieceNumber",
        { warehouse: ["id", "name", "code"] },
        {
          package: [
            "id",
            "weight",
            "fragile",
            "description",
            { product: ["id", "name", "code"] },
            {
              request: [
                "code",
                "loaded",
                "unloaded",
                "id",
                "detailsCount",
                { originCountry: ["id", "name", "code"] },
                { destinationCountry: ["id", "name", "code"] },
                { loadingMode: ["id", "name", "code"] },
                { customer: ["id", "name", "code"] },
                { vendor: ["id", "name", "code"] },
              ],
            },
            {
              packageType: ["id", "name", "code"],
            },
          ],
        },

        "loaded",
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListPackageDetailsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const LIST_MANIFESTS = gqlb.query({
  operation: "listManifests",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        {
          container: [
            "id",
            "containerNumber",
            // { packageType: ["id", "name", "code"] },
          ],
        },
        {
          packageDetail: [
            "id",
            "pieceNumber",
            { warehouse: ["id", "name", "code"] },
            {
              package: [
                "id",
                "weight",
                "fragile",
                "description",
                { product: ["id", "name", "code"] },
                {
                  request: [
                    "code",
                    "loaded",
                    "unloaded",
                    "id",
                    "detailsCount",
                    { originCountry: ["id", "name", "code"] },
                    { destinationCountry: ["id", "name", "code"] },
                    { loadingMode: ["id", "name", "code"] },
                    { customer: ["id", "name", "code"] },
                    { vendor: ["id", "name", "code"] },
                  ],
                },
                {
                  packageType: ["id", "name", "code"],
                },
              ],
            },

            "loaded",
            "unloaded",
          ],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListManifestsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const APPROVE_MUTATION = gqlb.mutation({
  operation: "approveOperation",
  fields: fields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DELETE_OPERATION = gqlb.mutation({
  operation: "deleteOperation",
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DELETE_MANIFEST = gqlb.mutation({
  operation: "deleteManifest",
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const CREATE_MANIFEST = gqlb.mutation({
  operation: "createManifest",
  fields: ["id"],
  variables: {
    input: {
      type: "ManifestInput",
      required: true,
    },
  },
});
