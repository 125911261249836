import * as gqlb from "gql-query-builder";
const fields = [
  "id",
  "name",
  "code",
  "description",
  "toDate",
  "fromDate",
  {
    currency: ["id", "name", "code"],
  },
  {
    shippingMode: ["id", "name", "code"],
  },
  {
    shippingDirection: ["id", "name", "code"],
  },
  // { chargeElement: ["id", "name", "code"] },
];
export const SAVE_PRICE_LIST = gqlb.mutation({
  operation: "savePriceList",
  fields: ["id", "code", "name", "editable"],
  variables: {
    input: {
      type: "PriceListInput",
      required: true,
    },
  },
});

export const SAVE_PRICE_LIST_ROUTE = gqlb.mutation({
  operation: "savePriceListRoute",
  fields: ["id"],
  variables: {
    input: {
      type: "PriceListRouteInput",
      required: true,
    },
  },
});

export const PRICE_LIST = gqlb.query({
  operation: "priceList",
  fields: [
    ...fields,
    "date",
    "editable",
    {
      routes: [
        "id",
        { origin: ["id", "name", "code"] },
        { destination: ["id", "name", "code"] },
        "cpmPrice",
        { packageTypes: ["price", "id", { packageType: ["id", "name"] }] },
        { weights: ["price", "id", "weightTo ", "weightFrom"] },
      ],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const LIST_PRICE_LIST_PAGED = gqlb.query({
  operation: "listPriceLists",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: fields,
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
    input: {
      type: "ListPriceListsFilterInput",
    },
  },
});

export const DELETE_PRICE_LIST = gqlb.mutation({
  operation: "deletePriceList",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DELETE_PRICE_LIST_ROUTE = gqlb.mutation({
  operation: "deletePriceListRoute",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DELETE_PACKAGE_TYPE = gqlb.mutation({
  operation: "deletePackageTypeRoute",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DELETE_WEIGHT_ROUTE = gqlb.mutation({
  operation: "deleteRouteWeight",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_PACKAGE_TYPE_LIST_ROUTE = gqlb.mutation({
  operation: "savePackageTypeRoute",
  fields: ["id"],
  variables: {
    input: {
      type: "PackageTypeRouteInput",
      required: true,
    },
  },
});
export const SAVE_WEIGHT_LIST_ROUTE = gqlb.mutation({
  operation: "saveRouteWeight",
  fields: ["id"],
  variables: {
    input: {
      type: "RouteWeightInput",
      required: true,
    },
  },
});
