import * as gqlb from "gql-query-builder";
const fields = [
  "id",
  "code",
  "name",
  "phone",
  "mobile",
  "active",
  "operation",
  "deliveryAgent",
  "salesMan",
  "administration",
  "salary",
  "transferAmount",

  {
    nation: ["id", "code", "name"],
  },
  {
    country: ["id", "code", "name"],
  },
  {
    bank: ["id", "code", "name"],
  },
];
export const LIST_EMPLOYEES = gqlb.query({
  operation: "listEmployees",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: fields,
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListEmployeesFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const EMPLOYEE_ID = gqlb.query({
  operation: "employee",
  fields: [
    ...fields,
    "address",
    "photoMimeType",
    "birthDate",
    "birthPlace",
    "postalCode",
    "mailBoxNumber",
    "idNumber",
    "idIssueDate",
    "idExpiryDate",
    "idIssuePlace",
    "graduationName",
    "professionName",
    "universityName",
    "graduationYear",
    "graduationGrade",
    "bankAccountNumber",
    "date",
    {
      glAccount: ["id", "code", "name"],
    },
    {
      gender: ["id", "code", "name"],
    },
    {
      socialStatus: ["id", "code", "name"],
    },
    {
      image: ["path"],
    },
    {
      branch: ["id", "code", "name"],
    },

    {
      state: ["id", "code", "name"],
    },
    {
      idType: ["id", "code", "name"],
    },
    {
      users: ["id", "username"],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_EMPLOYEE = gqlb.mutation({
  operation: "saveEmployee",
  fields: ["id", "code", "name"],
  variables: {
    input: {
      type: "EmployeeInput",
      required: true,
    },
  },
});
export const FREIGHT_SETTING = gqlb.query({
  operation: "freightSettings",
  fields: [{ mainEmployeeGLAccount: ["id", "code", "name"] }],
  variables: {},
});
