import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../../HOC/MUI/CustomAutocomplete";
import { LIST_GL_ACCOUNTS_DROPDOWN } from "../../../GlobalsQuery/ListDropdown/ListDropdown";
import { useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import { SAVE_JOURNAL_ENTRY_RECORD } from "../Graphql";
import ButtonLoading from "../../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { setValidationError } from "../../HOC/CustomFunctions/setValidationError";

const RecordsForm = ({
  closeRecordFormDialog,
  branchId,
  entryId,
  callRefetchFunction,
  recordData,
  dispatch,
  records,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      glAccountId: "",
      credit: "",
      debit: "",
      description: "",
    },
  });

  const { t } = useTranslation(["translation", "validation"]);
  const [autocompleteValues, setAutocompleteValues] = useState({
    glAccount: null,
  });

  const [saveJournalEntryRecord, { loading: saveJournalLoading }] = useMutation(
    gql`
      ${SAVE_JOURNAL_ENTRY_RECORD.query}
    `
  );

  useEffect(() => {
    if (recordData) {
      setValue("credit", recordData.credit);
      setValue("debit", recordData.debit);
      setValue("description", recordData.description ?? "");
      setAutocompleteValues({
        glAccount: recordData.glAccount,
      });
    } else {
      setValue("credit", 0);
      setValue("debit", 0);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitRecord = (data) => {
    saveJournalEntryRecord({
      variables: {
        input: {
          ...(recordData && { id: recordData.id }),
          ...(!recordData?.id && { entryId: entryId }),
          debit: parseFloat(data.debit),
          credit: parseFloat(data.credit),
          description: data.description ? data.description : null,
          glAccountId: data.glAccountId,
        },
      },
    })
      .then((data) => {
        dispatch({
          payload: {
            debit: data.data.saveJournalEntryRecord.entry.debit,
            credit: data.data.saveJournalEntryRecord.entry.credit,
            ...(recordData && { records: records + 1 }),
          },
        });
        reset();
        callRefetchFunction();
        closeRecordFormDialog();
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        setValidationError(graphQLErrors, setError);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitRecord)}>
      <DialogTitle color={"text.primary"}>{t("addJournal")}</DialogTitle>
      <DialogContent>
        <ControlMUItextField
          control={control}
          errors={errors}
          name="debit"
          type="number"
          label={t("debit")}
          rules={{
            required: t("fieldIsRequired"),
            min: {
              value: 0,
              message: t("validation:min", {
                field: t("debit"),
                number: 0,
              }),
            },
          }}
          onBlur={(e) => {
            if (e.target.value === "") {
              setValue("debit", "0");
            }
          }}
          readOnly={parseFloat(watch("credit")) > 0}
          margin="normal"
        />
        <ControlMUItextField
          control={control}
          errors={errors}
          name="credit"
          type="number"
          label={t("credit")}
          rules={{
            required: t("fieldIsRequired"),
            min: {
              value: 0,
              message: t("validation:min", {
                field: t("credit"),
                number: 0,
              }),
            },
          }}
          onBlur={(e) => {
            if (e.target.value === "") {
              setValue("credit", "0");
            }
          }}
          readOnly={parseFloat(watch("debit")) > 0}
          margin="normal"
        />
        <CustomAutocomplete
          control={control}
          errors={errors}
          rules={{ required: t("fieldIsRequired") }}
          name={"glAccountId"}
          label={t("glAccount")}
          parseData={(data) => data}
          query={LIST_GL_ACCOUNTS_DROPDOWN.query}
          margin="normal"
          defaultValue={autocompleteValues.glAccount}
          // onChangeValue={(e) => onChangeNames(e, "glAccount")}
          variables={{
            input: {
              typeCode: "SUB",
              branchId: { value: branchId, includeNull: true },
            },
          }}
        />
        <ControlMUItextField
          control={control}
          errors={errors}
          name="description"
          type="number"
          rows={3}
          label={t("description")}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={closeRecordFormDialog}
          disabled={saveJournalLoading}
        >
          {t("cancel")}
        </Button>
        <Button color="primary" type="submit" disabled={saveJournalLoading}>
          {saveJournalLoading ? <ButtonLoading /> : t("confirm")}
        </Button>
      </DialogActions>
    </form>
  );
};

export default RecordsForm;
