import * as gqlb from "gql-query-builder";
const fields = [
  "id",
  "code",
  "name",
  "date",
  "active",
  { mode: ["id", "name", "code"] },
  { country: ["id", "name", "code"] },
  { state: ["id", "name", "code"] },
];
export const LIST_SHIPPING_PORT = gqlb.query({
  operation: "listShippingPorts",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: fields,
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListShippingPortsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const SHIPPING_PORT_ID = gqlb.query({
  operation: "shippingPort",
  fields: [...fields, "deletable"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_SHIPPING_PORT = gqlb.mutation({
  operation: "saveShippingPort",
  fields: ["id", "code", "name"],
  variables: {
    input: {
      type: "ShippingPortInput",
      required: true,
    },
  },
});

export const DELETE_SHIPPING_PORT = gqlb.mutation({
  operation: "deleteShippingPort",
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
