import * as gqlb from "gql-query-builder";

const RECORDS = {
  operation: `records`,
  fields: [
    {
      operation: `paginatorInfo`,
      fields: ["count", "total"],
      variables: {},
    },
    {
      operation: `data`,
      fields: [
        "id",
        "debit",
        "credit",
        "description",
        {
          glAccount: ["id", "name"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
    },
    page: {
      type: "Int",
    },
  },
};

const journalEntryFields = [
  "id",
  "date",
  "code",
  "description",
  "approved",
  "debit",
  "credit",
  "exchangeRate",
  {
    branch: ["id", "name"],
  },
  {
    type: ["id", "name"],
  },
  {
    currency: ["id", "name"],
  },
  {
    createdBy: ["username", "id"],
  },
  {
    updatedBy: ["username", "id"],
  },
  {
    subsidiary: ["id", "name"],
  },
  {
    sourceType: ["code"],
  },
  {
    operation: `records`,
    fields: [
      {
        operation: `paginatorInfo`,
        fields: ["total"],
        variables: {},
      },
    ],
    variables: {},
  },
];

export const LIST_JOURNAL_ENTRY = gqlb.query({
  operation: "listJournalEntries",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "date",
        "code",
        "description",
        "approved",
        "exchangeRate",
        {
          branch: ["id", "name"],
        },
        {
          type: ["id", "name"],
        },
        {
          currency: ["id", "name"],
        },
        {
          subsidiary: ["id", "name"],
        },
        {
          updatedBy: ["username", "id"],
        },
        {
          createdBy: ["username", "id"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListJournalEntriesInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const SAVE_JOURNAL_ENTRY = gqlb.mutation({
  operation: "saveJournalEntry",
  fields: journalEntryFields,
  variables: {
    input: {
      type: "JournalEntryInput",
      required: true,
    },
  },
});

export const APPROVE_JOURNAL_ENTRY = gqlb.mutation({
  operation: "approveJournalEntry",
  fields: journalEntryFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DELETE_JOURNAL_ENTRY = gqlb.mutation({
  operation: "deleteJournalEntry",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const JOURNAL_ENTRY = gqlb.query({
  operation: "journalEntry",
  fields: journalEntryFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const DISAPPROVE_JOURNAL_ENTRY_MUTATION = gqlb.mutation({
  operation: "disapproveJournalEntry",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_JOURNAL_ENTRY_RECORD = gqlb.mutation({
  operation: "saveJournalEntryRecord",
  fields: [
    "id",
    {
      entry: ["debit", "credit"],
    },
  ],
  variables: {
    input: {
      type: "JournalEntryRecordInput",
      required: true,
    },
  },
});

export const DELETE_JOURNAL_ENTRY_RECORD = gqlb.mutation({
  operation: "deleteJournalEntryRecord",
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const JOURNAL_ENTRY_RECORDS = gqlb.query({
  operation: "journalEntry",
  fields: ["debit", "credit", RECORDS],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const CURRENCY_ID = gqlb.query({
  operation: "currency",
  fields: [{ exchangeRate: ["updatable", "rate", "id"] }],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
