import * as gqlb from "gql-query-builder";

export const LIST_WAREHOUSE_PACKAGES = gqlb.query({
  operation: "listWarehousePackages",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "description",
        "count",
        "fragile",
        "pieces",
        "weight",
        "totalWeight",

        "date",

        {
          request: [
            "id",
            "code",
            { branch: ["id", "code", "name"] },
            { status: ["id", "code", "name"] },
          ],
        },
        { packageType: ["id", "code", "name"] },

        { warehouse: ["id", "code", "name"] },

        { image: ["path"] },
      ],
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
    input: {
      type: "ListWarehousePackagesFilterInput",
    },
  },
});
export const EXPOERT_WAREHOUSE_PACKAGES = gqlb.mutation({
  operation: "exportWarehousePackages",
  fields: [],
  variables: {
    input: {
      type: "ExportWarehousePackagesInput",
      required: true,
    },
  },
});
