import React from "react";
import { classesForm } from "../../../GlobalStyles/FormStyle";
import Grid from "@mui/material/Unstable_Grid2";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";
import {
  LIST_CARRIER_DROPDOWN,
  LIST_CURRENCIES_DROPDOWN,
  LIST_INCO_TERMS_DROPDOWN,
} from "../../../GlobalsQuery/ListDropdown/ListDropdown";
import { CustomAutocomplete } from "../../HOC/MUI/CustomAutocomplete";
import clsx from "clsx";
import { Paper, Stack } from "@mui/material";
import { useShipmentContext } from "../Context/ShipmentContext";

export const Step3 = () => {
  const { control, errors, t, autocompleteValues, watch } =
    useShipmentContext();

  return (
    <>
      <Paper
        container
        component={Grid}
        spacing={1}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid xs={12} sm={3} alignItems="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"incoTermId"}
            label={t("incoTerm")}
            parseData={(data) => data}
            query={LIST_INCO_TERMS_DROPDOWN.query}
            defaultValue={autocompleteValues.incoTerm}
            disabled={Boolean(
              !watch("shippingDirection") || !watch("shippingMode")
            )}
            skip={Boolean(
              !watch("shippingDirection") || !watch("shippingMode")
            )}
            variables={{
              input: {
                shippingDirection: watch("shippingDirection"),
                shippingMode: watch("shippingMode"),
              },
            }}
          />
        </Grid>
        <Grid xs={12} sm={3} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"carrierId"}
            label={t("carrier")}
            parseData={(data) => data}
            query={LIST_CARRIER_DROPDOWN.query}
            rules={{ required: t("fieldIsRequired") }}
            defaultValue={autocompleteValues.carrier}
          />
        </Grid>

        <Grid xs={12} sm={6} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"currencyId"}
            label={t("currency")}
            parseData={(data) => data}
            query={LIST_CURRENCIES_DROPDOWN.query}
            defaultValue={autocompleteValues.currency}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
      </Paper>
      <Stack p={1} component={Paper}>
        <ControlMUItextField
          control={control}
          errors={errors}
          name={"remarks"}
          label={t("notes")}
          rows={2}
        />
      </Stack>
    </>
  );
};
