export const getEntityInfo = (row) => {
  const keys = [
    "vendor",
    "employee",
    "customer",
    "agent",
    "agency",
    "carrier",
    "glAccount",
  ];
  const entity = keys.find((key) => row?.[key]); // Find the first non-null entity
  // const entity = keys.find((key) => row?.[key]); // Find the first non-null entity
  const id = entity ? row[entity]?.id : null; // Get the ID of the entity

  // Use "custom-agent" in the path for "agent", but keep the entity name as "agent"
  const entityPath =
    entity === "agent"
      ? "custom-agents"
      : entity === "agency"
      ? "agencies"
      : `${entity}s`;

  return { entity, entityPath, id };
};
