/* eslint-disable no-useless-concat */
import React, {
  lazy,
  Suspense,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import { gql, useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  Box,
  Dialog,
  DialogContent,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { DELETE_REQUEST, details, LIST_PACKAGE_DETAILS } from "./Graphql";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import { Print } from "@mui/icons-material";
import CustomizedSteppers from "../HOC/FunctionComponents/Stepper";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";
import { TabsProvider } from "../HOC/CustomComponents/PackagesTableView/TabContext";

import CustomTab from "../HOC/FunctionComponents/CustomTab";
import {
  useShipmentData,
  useShipmentDataByType,
} from "../HOC/CustomComponents/RequestShipment/shipmentData";
const DescriptionView = lazy(() => import("./DescriptionView"));
const BillOrInvoiceTable = lazy(() =>
  import("../HOC/CustomComponents/RequestShipment/Bill_InvoiceTable")
);
const Tabs = lazy(() =>
  import("../HOC/CustomComponents/PackagesTableView/Tabs")
);
const PackageDetails = lazy(() => import("./PackageDetails"));
const TransactionsTable = lazy(() => import("./TransactionsTable"));
const PREFIX = "RequestView";
const classes = {
  alertHover: `${PREFIX}-alertHover`,
  codeSection: `${PREFIX}-codeSection`,
  codeTitle: `${PREFIX}-codeTitle`,
};
const Header = styled(Grid)(({ theme }) => ({
  [`& .${classes.codeSection}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: theme.spacing(1),
  },

  [`& .${classes.codeTitle}`]: {
    float: "left",
    marginRight: theme.spacing(1),
  },
  [`& .${classes.alertHover}`]: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const initialState = {
  description: {
    data: null,
  },
  transactions: {
    data: null,
  },
  packages: {
    data: null,
  },
  bills: {
    data: null,
  },
  invoices: {
    data: null,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "description":
      return {
        ...state,
        description: { ...state.description, ...action.payload },
      };
    case "transactions":
      return {
        ...state,
        transactions: { ...state.transactions, ...action.payload },
      };
    case "packages":
      return {
        ...state,
        packages: { ...state.packages, ...action.payload },
      };
    case "packageDetails":
      return {
        ...state,
        packageDetails: { ...state.packageDetails, ...action.payload },
      };
    case "bills":
      return {
        ...state,
        bills: { ...state.bills, ...action.payload },
      };
    case "invoices":
      return {
        ...state,
        invoices: { ...state.invoices, ...action.payload },
      };
    default:
      return state;
  }
}

const RequestView = (props) => {
  const { t, i18n } = useTranslation();
  const id = props.match.params.id?.trim();
  const href = document.location.href.split("#");
  const [type] = useState(href[1] ?? "description");
  const operation = "request";
  const user = Globals.user;
  const BILL_PERMISSION = user?.hasPermission("freight.bill.list");
  const INVOICE_PERMISSION = user?.hasPermission("freight.invoice.list");
  const [shipmentViewManagement, dispatch] = useReducer(reducer, initialState);
  const queryByType = details({ type, operation });
  const [changedType, setChangedType] = useState(null);
  const { data, loading } = useShipmentData({
    operation,
    trackingId: id,
    type,
    dispatch,
    queryByType,
    user,
  });
  useShipmentDataByType({
    changedType: changedType ?? type,
    skip: changedType,
    dispatch,
    queryByType: details({ type: changedType ?? type, operation }),
    trackingId: id,
    shipmentViewManagement,
  });
  const step1 = useRef([
    t("created"),
    t("packed"),
    t("loaded"),
    t("delivered"),
  ]);

  const [dialogState, setDialogState] = useState({
    details: false,
  });
  const [packages, setPackages] = useState();

  const [openDialog, setOpenDialog] = React.useState(false);

  const requestData = data?.request;
  const canDelete =
    user.hasPermission(`freight.request.delete`) && requestData?.deletable;
  const [getPackageDetailsData] = useLazyQuery(
    gql`
      ${LIST_PACKAGE_DETAILS.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data?.listPackageDetails?.data?.length) {
          data.listPackageDetails.data.forEach((reqPackage) => {
            const index = packages?.findIndex(
              (x) => x.id === reqPackage.package?.id
            );
            if (index !== -1) {
              packages[index].details = data?.listPackageDetails;
            }
          });
        } else {
          console.log("No package details available");
        }
      },
    }
  );
  useEffect(() => {
    setPackages(shipmentViewManagement?.packages?.data?.packages ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentViewManagement?.packages]);

  const handleFetchPackageDetails = (
    requestPackageId,
    pageDetails,
    rowsPerPageDetails
  ) => {
    getPackageDetailsData({
      variables: {
        page: pageDetails + 1,
        first: rowsPerPageDetails,
        input: {
          requestPackageId: parseInt(requestPackageId),
        },
      },
    });
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const [updateDetails, setUpdateDetails] = useState({
    details: null,
    id: null,
  });
  const closeDialog = () => {
    setDialogState((prev) => ({
      ...prev,
      packages: false,
      details: false,
    }));
    setUpdateDetails({
      details: null,
      id: null,
    });
  };
  const value = {
    updateDetails,
    packages,
    setPackages,
    closeDialog,
    requestData: shipmentViewManagement?.description?.data,
    classes: classesView,
    tabsLoading: loading,
    transactionData: shipmentViewManagement?.transactions?.data?.transactions,
    billData: shipmentViewManagement?.bills?.data?.bills,
    invoiceData: shipmentViewManagement?.invoices?.data?.invoices,
    id: requestData?.id,
    handleFetchPackageDetails,
    setDialogState,
    setUpdateDetails,
    editDetails: true,
  };

  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/requests/${id}/edit`),
      permission:
        user.hasPermission("freight.request.update") && requestData?.editable,
    },
    {
      id: "delete",
      action: handleOpenDialog,
      permission: Boolean(canDelete),
    },
    {
      id: "print",
      title: "print",
      action: () =>
        window.open(`${window.location.origin}/waybill/Request/print/${id}/1c`),
      icon: Print,
      disabled: loading || !id,
      // permission: requestId,
    },
  ];
  let trackingBody = null;
  const hashKeys = ["description", "transactions", "packages"];
  const tapsArray = [
    {
      tabHead: t("description"),
      panel: (
        <Suspense
          fallback={
            <Paper sx={{ mt: 2 }}>
              <FullScreenLoading minHeight={"100px"} />
            </Paper>
          }
        >
          <DescriptionView />
        </Suspense>
      ),
    },
    {
      tabHead: t("transactions"),
      panel: (
        <Suspense
          fallback={
            <Paper sx={{ mt: 2 }}>
              <FullScreenLoading minHeight={"100px"} />
            </Paper>
          }
        >
          <TransactionsTable />
        </Suspense>
      ),
    },
    {
      tabHead: t("packages"),
      panel: (
        <Suspense
          fallback={
            <Paper sx={{ mt: 2 }}>
              <FullScreenLoading minHeight={"100px"} />
            </Paper>
          }
        >
          <Grid container className={classesView.tabpanel}>
            <Grid xs={12}>
              <Paper container component={Grid} p={0}>
                <Stack
                  direction="row"
                  sx={{
                    width: "100%",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Grid display={"flex"} p={2}>
                    <Box>{t("detailsCount")} </Box>
                    <Box mx={1}>
                      {shipmentViewManagement?.packages?.data?.detailsCount}
                    </Box>
                  </Grid>
                  <Grid display={"flex"}>
                    <Box>{t("totalDamaged")}</Box>
                    <Box mx={1}>
                      {
                        shipmentViewManagement?.packages?.data
                          ?.totalDamagedPackages
                      }
                    </Box>
                  </Grid>
                  <Grid display={"flex"}>
                    <Box>{t("totalMissing")}</Box>
                    <Box mx={1}>
                      {
                        shipmentViewManagement?.packages?.data
                          ?.totalMissingPackages
                      }
                    </Box>
                  </Grid>
                  <Grid display={"flex"}>
                    <Box>{t("totalLoaded")}</Box>
                    <Box mx={1}>
                      {
                        shipmentViewManagement?.packages?.data
                          ?.totalLoadedPackages
                      }
                    </Box>
                  </Grid>
                  <Grid display={"flex"}>
                    <Box>{t("totalUnloaded")}</Box>
                    <Box mx={1}>
                      {
                        shipmentViewManagement?.packages?.data
                          ?.totalUnloadedPackages
                      }
                    </Box>
                  </Grid>
                </Stack>
              </Paper>
            </Grid>
            <Tabs />
          </Grid>
        </Suspense>
      ),
    },
  ];
  if (BILL_PERMISSION) {
    hashKeys.push("bills");
    tapsArray.push({
      tabHead: t("bills"),
      panel: (
        <Suspense
          fallback={
            <Paper sx={{ mt: 2 }}>
              <FullScreenLoading minHeight={"100px"} />
            </Paper>
          }
        >
          <BillOrInvoiceTable />
        </Suspense>
      ),
    });
  }
  if (INVOICE_PERMISSION) {
    hashKeys.push("invoices");
    tapsArray.push({
      tabHead: t("invoices"),
      panel: (
        <Suspense
          fallback={
            <Paper sx={{ mt: 2 }}>
              <FullScreenLoading minHeight={"100px"} />
            </Paper>
          }
        >
          <BillOrInvoiceTable />
        </Suspense>
      ),
    });
  }
  if (id && !loading) {
    trackingBody = (
      <Header
        container
        spacing={3}
        justifyContent="center"
        sx={{ width: "100%", gap: 2 }}
      >
        <Grid container justifyContent="center" className={classes.track}>
          <Grid className={classes.codeSection}>
            <Typography
              className={classes.codeTitle}
              variant="h6"
              color="textSecondary"
            >
              {t("requestCode")}
            </Typography>
            <Typography variant="h5" color="primary" sx={{ ml: 1 }}>
              {requestData?.code}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} justifyContent="center">
          <CustomizedSteppers
            step1={step1}
            key={i18n.language}
            statusCode={requestData?.status?.code}
            type={"request"}
          />
        </Grid>
        <Grid xs={12} justifyContent="center">
          <CustomTab
            tapDetails={tapsArray}
            hashKeys={hashKeys}
            changeHashKeyFun={setChangedType}
          />
        </Grid>
      </Header>
    );
  }
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !requestData ? (
    <NotFound />
  ) : (
    <RootStyleView>
      <MutationWithDialog
        mutaion={DELETE_REQUEST.query}
        setOpenDelete={setOpenDialog}
        openDelete={openDialog}
        dialogTitle={t("deleteRecord")}
        dialogContent={t("deleteRecordMessage")}
        mutaionProps={{
          variables: { id: parseInt(id) },
        }}
        onCompleted={() => pushUrl(props, `/admin/requests`)}
        onCompleteMessage={t("successfullyDeletedRecord")}
      />
      <TabsProvider value={value}>
        <Dialog
          fullWidth
          maxWidth={"xs"}
          sx={{ height: "650px", margin: "auto" }}
          classes={{
            paper: classes.dialog,
          }}
          open={dialogState.details}
          onClose={() => {
            closeDialog();
          }}
        >
          <DialogContent>
            {dialogState.details ? <PackageDetails /> : null}
          </DialogContent>
        </Dialog>
        <Grid
          container
          spacing={3}
          m={0}
          justifyContent="center"
          sx={{ maxWidth: "100vw", width: "100%" }}
        >
          <TitleAppBar path={props.match.path}>
            <LongMenu icons={icons} />
          </TitleAppBar>
          {trackingBody}
        </Grid>
      </TabsProvider>
    </RootStyleView>
  );
};

export default RequestView;
