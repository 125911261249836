import * as gqlb from "gql-query-builder";
const fields = [
  "id",
  "code",
  "name",
  "active",
  "phone",
  "creditLimit",

  {
    category: ["id", "code", "name"],
  },
  {
    currency: ["id", "code", "name"],
  },
  {
    branch: ["id", "code", "name"],
  },
  { country: ["id", "name", "code"] },
  { state: ["id", "name", "code"] },
];

export const LIST_VENDORS = gqlb.query({
  operation: "listVendors",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: fields,
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListVendorsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const VENDOR_ID = gqlb.query({
  operation: "vendor",
  fields: [
    ...fields,
    "address",
    "postalCode",
    "mailBoxNumber",
    "mobile",
    "idNumber",
    "idIssueDate",
    "email",
    "taxCardNumber",
    "registerationNumber",
    "date",
    "businessName",
    "businessActivity",
    "businessField",
    "responsibleName",
    {
      image: ["path"],
    },
    {
      idType: ["id", "code", "name"],
    },
    {
      glAccount: ["id", "code", "name"],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_VENDOR = gqlb.mutation({
  operation: "saveVendor",
  fields: ["id", "code", "name"],
  variables: {
    input: {
      type: "VendorInput",
      required: true,
    },
  },
});
export const FREIGHT_SETTING = gqlb.query({
  operation: "freightSettings",
  fields: [{ mainVendorGLAccount: ["id", "code", "name"] }],
  variables: {},
});
