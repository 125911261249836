import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import { urlParameters } from "../../HOC/CustomFunctions/urlParameters";

import { fieldsBill, fieldsPrint } from "./operationGraphgl";
import useTableBodyDetails from "./DetailsPrint";

const usePagesCount = (props) => {
  const printType = props.match.params.type;
  const manifestId = +props.match.params.id;
  const urlQuery = urlParameters(window.location.search);
  const trxType = props.match.params?.trxType;

  const queriesDetails = {
    operation: {
      queryFields: fieldsPrint,
      withId: "operation",
      withList: "listOperation",
      listFilters: "ListOperationFilterInput",
      variableType: "transactionTypeCode",
      printTableDetails: useTableBodyDetails,
    },
    bill: {
      queryFields: fieldsBill,
      withId: "bill",
      withList: "listBill",
      listFilters: "ListBillFilterInput",
      printTableDetails: useTableBodyDetails,
    },
    invoice: {
      queryFields: fieldsBill,
      withId: "invoice",
      withList: "listInvoice",
      listFilters: "ListInvoiceFilterInput",
      printTableDetails: useTableBodyDetails,
    },
  };

  const listCondition = !manifestId;
  const queryType = listCondition ? "withList" : "withId";

  const {
    printTableDetails,
    printTablePickupsDetails,
    queryFields,
    listFilters,
    variableType,
    [queryType]: queryName,
  } = queriesDetails[printType];

  const PAGE_QUERY = gqlb.query({
    operation: queryName,
    fields: [
      {
        operation: "paginatorInfo",
        fields: ["lastPage"],
        variables: {},
      },
    ],
    variables: {
      first: {
        type: "Int",
      },
      input: {
        type: listFilters,
        required: true,
      },
    },
  });

  const { data: lastPage, loading: loadingLastPage } = useQuery(
    gql`
      ${PAGE_QUERY.query}
    `,
    {
      skip: !listCondition,
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      variables: {
        first: 100,
        input: {
          ...urlQuery,
          ...(urlQuery.code && { code: urlQuery.code.toString() }),
          ...(variableType && { [variableType]: trxType?.toUpperCase() }),
        },
      },
    }
  );

  const pagesCount = lastPage?.[queryName]?.paginatorInfo?.lastPage ?? 1;

  let operationBody;

  if (listCondition) {
    const queryArray = [];
    for (let i = 0; i < pagesCount; i++) {
      const initialQuery = {
        operation: `${queryName}_${i}:${queryName}`,
        fields: [{ data: queryFields }],
        variables: {
          [`page_${i + 1}`]: {
            name: "page",
            type: "Int",
            value: i + 1,
          },
          input: {
            type: listFilters,
            required: true,
          },
          first: {
            type: "Int",
          },
        },
      };
      queryArray.push(initialQuery);
    }

    operationBody = queryArray;
  }

  if (!listCondition) {
    operationBody = [
      {
        operation: queryName,
        fields: queryFields,
        variables: {
          id: {
            type: "Int",
            required: true,
          },
        },
      },
    ];
  }

  const OPERATION_QUERY = gqlb.query([
    ...operationBody,
    {
      operation: "organization",
      fields: [
        "name",
        "phone",
        "email",
        "website",
        "reportHeader",
        "reportFooter",
      ],
      variables: {},
    },
  ]);

  const { data, loading } = useQuery(
    gql`
      ${OPERATION_QUERY.query}
    `,
    {
      skip: listCondition && loadingLastPage,
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      variables: {
        ...(listCondition && {
          ...OPERATION_QUERY.variables,
          first: 100,
          input: {
            ...urlQuery,
            ...(urlQuery.code && { code: urlQuery.code.toString() }),
            ...(variableType && { [variableType]: trxType }),
          },
        }),
        ...(!listCondition && { id: manifestId }),
      },
    }
  );

  const organization = data?.organization;
  let reportDetails;

  if (listCondition) {
    if (data?.[`${queryName}_0`]) {
      let concatData = [];
      for (const key in data) {
        if (key.startsWith(queryName)) {
          const listData = data[key].data;
          concatData = concatData.concat(listData);
        }
      }
      reportDetails = concatData;
    }
  } else {
    reportDetails = data?.[queryName];
  }

  return {
    loading: loading || loadingLastPage,
    reportDetails,
    organization,
    printTableDetails,
    printTablePickupsDetails,
  };
};

export default usePagesCount;
