import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Paper, Icon } from "@mui/material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { QUERY_ID } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";
const CustomAgentView = (props) => {
  const { t } = useTranslation();
  const pathURL = props?.match?.path;
  let pathname = pathURL;
  if (pathURL.includes("custom-agents")) {
    pathname = "customAgent";
  } else if (pathURL.includes("carriers")) {
    pathname = "carrier";
  } else {
    pathname = "agency";
  }
  const urlPath =
    pathname === "customAgent"
      ? "custom-agents"
      : pathname === "agency"
      ? "agencies"
      : "carriers";
  const id = props.match.params.id?.trim();
  const user = Globals.user;

  const GET_BY_ID = QUERY_ID(pathname);
  const { loading, data } = useQuery(
    gql`
      ${GET_BY_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(id) },
    }
  );

  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/${urlPath}/${id}/edit`),
      permission: user.hasPermission(
        `freight.${
          pathname === "customAgent"
            ? "custom_agent"
            : pathname === "carrier"
            ? "carrier"
            : "agency"
        }.update`
      ),
    },
  ];
  const customData = data?.[pathname];
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !customData ? (
    <NotFound />
  ) : (
    <>
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>

      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair title={t("code")} value={customData?.code} />
          <KeyValuePair title={t("name")} value={customData?.name} />
          <KeyValuePair title={t("date")} value={customData?.date} />
          <KeyValuePair
            title={t("active")}
            value={
              customData?.active ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("branch")}
            value={
              <SpanLink pathname={`/admin/branches/${customData?.branch?.id}`}>
                {customData?.branch?.name}
              </SpanLink>
            }
          />
          <KeyValuePair
            title={t("glAccount")}
            value={customData?.glAccount?.name}
          />
          <KeyValuePair
            title={t("currency")}
            value={customData?.currency?.name}
          />
          <KeyValuePair title={t("idType")} value={customData?.idType?.name} />
          <KeyValuePair title={t("idNumber")} value={customData?.idNumber} />
          <KeyValuePair
            title={t("idIssueDate")}
            value={customData?.idIssueDate}
          />
          <KeyValuePair
            title={t("country")}
            value={
              <SpanLink
                pathname={`/admin/countries/${customData?.country?.id}`}
              >
                {customData?.country?.name}
              </SpanLink>
            }
          />

          <KeyValuePair title={t("state")} value={customData?.state?.name} />
          <KeyValuePair title={t("address")} value={customData?.address} />
          <KeyValuePair title={t("email")} value={customData?.email} />
          <KeyValuePair title={t("phone")} value={customData?.phone} />
          <KeyValuePair title={t("mobile")} value={customData?.mobile} />
          <KeyValuePair
            title={t("postalCode")}
            value={customData?.postalCode}
          />
          <KeyValuePair
            title={t("mailBoxNumber")}
            value={customData?.mailBoxNumber}
          />

          <KeyValuePair
            title={t("taxCardNumber")}
            value={customData?.taxCardNumber}
          />
          <KeyValuePair
            title={t("registerationNumber")}
            value={customData?.registerationNumber}
          />
        </Paper>
      </RootStyleView>
    </>
  );
};

export default CustomAgentView;
