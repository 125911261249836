import React, { useContext } from "react";
import { ModeContext } from "../Context/ModeContext";
import PropTypes from "prop-types";

function importAll(r) {
  return r
    .keys()
    .filter((key) => key.includes("logo")) // Filter files starting with "Temp_"
    .map((key) => {
      const module = r(key);
      let fileName = key.replace("./", "").replace(".png", "");

      return {
        fileName: fileName,
        module: module.default || module,
      };
    });
}

function LogoImg(props) {
  const { forceLight, className, style, height } = props;

  const context = require.context("../assets/Image", false, /\.png$/);
  const files = importAll(context);

  let logo;
  const { darkMode } = useContext(ModeContext);

  if (files.length === 1) {
    logo = files[0].module;
  } else {
    if (forceLight || !darkMode) {
      const file = files.find((file) => file.fileName.includes("light"));
      logo = file ? file.module : null;
    } else {
      const file = files.find((file) => file.fileName.includes("dark"));
      logo = file ? file.module : null;
    }
  }

  if (!logo) {
    // Handle the case where no logo is found
    return null;
  }

  return (
    <img
      src={logo}
      alt="logo"
      className={className ?? ""}
      style={style ?? {}}
      height={height ?? ""}
    />
  );
}

LogoImg.propTypes = {
  forceLight: PropTypes.bool,
  className: PropTypes.any,
  height: PropTypes.any,
  style: PropTypes.any,
};

export default LogoImg;
