import React from "react";
import Chart, {
  ArgumentAxis,
  Grid,
  Label,
  Legend,
  Series,
  ValueAxis,
  Tooltip,
} from "devextreme-react/chart";
import clsx from "clsx";
import { useTheme } from "@emotion/react";

function ChartTable(props) {
  const { dataTable, classNamePieChart, classes } = props;
  const theme = useTheme();
  const dir = theme.direction;
  const dataArray = dir === "ltr" ? dataTable : dataTable.reverse();
  return (
    <Chart
      dataSource={dataArray}
      id="chart"
      style={{ height: "16rem" }}
      rtlEnabled={true}
      className={clsx(classNamePieChart, classes.textColor)}
    >
      <ArgumentAxis>
        <Label format="decimal" />
        <Grid visible={false} />
      </ArgumentAxis>
      <ValueAxis position={dir === "ltr" ? "left" : "right"}>
        <Grid visible={false} />
        <Label visible={false} />
      </ValueAxis>

      <Series
        valueField="percentage"
        argumentField="name"
        barWidth={45}
        type="bar"
      >
        <Label
          visible={true}
          customizeText={(info) => `${info.value.toFixed(2)}%`}
          backgroundColor={"transparent"}
          position="inside"
          font={{
            size: ".6rem",
          }}
        />
      </Series>
      <Tooltip enabled={false} format={{ type: "fixedPoint", precision: 2 }} />
      <Legend visible={false} />
    </Chart>
  );
}

export default ChartTable;
