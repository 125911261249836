import React, { useState } from "react";
import {
  Paper,
  Collapse,
  Stack,
  FormControlLabel,
  Switch,
  Box,
  Badge,
} from "@mui/material";
import { FREIGHT_SETTING, SAVE_VENDOR, VENDOR_ID } from "./Graphql";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import FormButton from "../CustomComponents/Buttons/FormButton";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { useHistory } from "react-router";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_COUNTRIES_DROPDOWN,
  LIST_CURRENCIES_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_STATES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import MUIDate from "../HOC/MUI/MUIDate";
import { dateFormat } from "../../helpers/dateFunctions";
import MuiPhone from "../HOC/MUI/MUIphoneNumber";
import getMobileData, {
  getFormatNumber,
  validNumber,
} from "../../helpers/asYouType";
import { Clear } from "@mui/icons-material";
import UploadFile from "../HOC/MUI/UploadFile";
import config from "../../config.json";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";
const VendorForm = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [active, setActive] = useState(true);
  const [pathURL, setPathURL] = useState(props.match.path);
  const { enqueueSnackbar } = useSnackbar();
  const [idIssueDate, setIdIssueDate] = useState(null);
  const [id, setId] = useState(parseInt(props.match.params.id));
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    country: null,
    state: null,
    category: null,
    idType: null,
    glAccount: null,
    currencyId: null,
    image: null,
  });
  const {
    handleSubmit,
    control,
    formState,
    setValue,
    setError,
    watch,
    getValues,
  } = useForm();
  const { errors } = formState;
  const [previewImage, setPreviewImage] = useState(null);

  const [saveVendor, { loading: saveVendorLoading }] = useMutation(
    gql`
      ${SAVE_VENDOR.query}
    `
  );

  const { data: updateVendor, loading: updateVendorLoading } = useQuery(
    gql`
      ${VENDOR_ID.query}
    `,

    {
      skip: !id,
      variables: { id: id },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const vendorData = data.vendor;
        setActive(vendorData.active);
        setIdIssueDate(vendorData.idIssueDate);
        const vendorParams = [
          "code",
          "name",
          "active",
          "address",
          "postalCode",
          "phone",
          "mailBoxNumber",
          "mobile",
          "idNumber",
          "idIssueDate",
          "email",
          "creditLimit",
          "taxCardNumber",
          "registerationNumber",
          "businessName",
          "businessActivity",
          "businessField",
          "responsibleName",
        ];
        vendorParams.forEach((i) => {
          vendorData[i] !== null && setValue(i, vendorData[i]);
        });
        const numbersObj = [
          {
            name: "mobile",
            codeName: "mobileCode",
            value: getMobileData(vendorData.mobile),
          },
          {
            name: "phone",
            codeName: "phoneCode",
            value: getMobileData(vendorData.phone),
          },
        ];
        numbersObj.forEach((i) => {
          if (i.value !== null) {
            setValue(i.codeName, i.value.country.toLowerCase());
            setValue(i.name, i.value.nationalNumber);
          }
        });
        setValue("active", vendorData["active"]);
        setAutocompleteValues((prev) => ({
          ...prev,
          country: vendorData?.country,
          state: vendorData?.state,
          branch: vendorData?.branch,
          category: vendorData?.category,
          idType: vendorData?.idType,
          glAccount: vendorData?.glAccount,
          currencyId: vendorData?.currency,
          image: vendorData?.image?.path,
        }));
      },
    }
  );
  const { data: settingVendor } = useQuery(
    gql`
      ${FREIGHT_SETTING.query}
    `,

    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {},
    }
  );

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActive(active);
    setValue("active", active);
  };
  const selectDefaultValue = (data) => {
    return data;
  };

  const parseData = (data) => {
    return data;
  };

  const parentId = settingVendor?.freightSettings?.mainVendorGLAccount?.id;
  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;
    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };
  const setClear = () => {
    setAutocompleteValues((prev) => ({
      ...prev,
      image: "",
    }));
    setPreviewImage(null);
    setValue("fileName", "");
    setValue("image", "null");
  };

  const onSubmit = (data) => {
    const checkMobiles = validNumber(data.mobile, data.mobileCode);
    const checkPhone = validNumber(data.phone, data.phoneCode);
    let names = [
      {
        name: "mobile",
        validate: checkMobiles.valid,
        message: checkMobiles.message,
      },
      {
        name: "phone",
        validate: checkPhone.valid,
        message: checkPhone.message,
      },
    ];
    if (!checkMobiles.valid || !checkPhone.valid) {
      names.map(
        (e) =>
          !e.validate &&
          setError(e.name, { type: "custom", message: t(e.message) })
      );
      return;
    }
    data.mobile = data.mobile && getFormatNumber(data.mobile, data.mobileCode);
    data.phone = data.phone && getFormatNumber(data.phone, data.phoneCode);
    for (const key in data) {
      if (
        (data[key] === "" && key === "code") ||
        key === "phoneCode" ||
        key === "mobileCode"
      ) {
        delete data[key];
      }
      if (key === "fileName") {
        delete data[key];
      }
      if (data[key] === "" && key === "image") {
        delete data[key];
      }
      if (key === "image" && data[key] === "null") {
        data[key] = null;
      }
      if (data[key] === "") {
        data[key] = null;
        continue;
      }
      if (key === "creditLimit") {
        data[key] = parseFloat(data[key]);
      }
    }
    const image = data?.image && data?.image[0];

    saveVendor({
      variables: {
        input: {
          ...data,
          active: active,
          ...(data.idIssueDate && {
            idIssueDate: dateFormat(data.idIssueDate),
          }),
          ...(id && { id: id }),
          ...(data.image && {
            image: { file: image },
          }),
        },
      },
    })
      .then((data) => {
        setId(data?.data?.vendor?.id);
        true && pushUrl(props, `/admin/vendors/${data?.data?.saveVendor?.id}`);
        const url = history.createHref({
          pathname: `/admin/vendors/${data?.data?.saveVendor?.id}/edit`,
        });
        false && setPathURL(url);
        false && windowReplaceUrl(url);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid
          justifyContent="end"
          sx={{ display: "flex", flexWrap: "wrap" }}
          xs={12}
        >
          <FormControlLabel
            checked={active}
            control={<Switch color="primary" />}
            label={t("active")}
            labelPlacement="start"
            onChange={handelActivate}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"code"}
            label={t("code")}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"name"}
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"creditLimit"}
            label={t("creditLimit")}
            type="number"
          />
        </Grid>
        <Grid sm={3} xs={12}>
          <CustomAutocomplete
            rules={{ required: t("fieldIsRequired") }}
            control={control}
            errors={errors}
            name={"currencyId"}
            label={t("currency")}
            parseData={(data) => parseData(data)}
            query={LIST_CURRENCIES_DROPDOWN.query}
            defaultValue={autocompleteValues.currencyId}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            // selectFirst={false}
            name={"glAccountId"}
            label={t("glAccount")}
            parseData={(data) => parseData(data)}
            query={LIST_GL_ACCOUNTS_DROPDOWN.query}
            variables={{
              input: {
                typeCode: "SUB",
                ...(watch("branchId") && {
                  branchId: {
                    value: watch("branchId"),
                    includeNull: true,
                  },
                  parentId: parentId,
                }),
              },
            }}
            defaultValue={autocompleteValues.glAccount}
          />
        </Grid>
        <Grid sm={3} xs={12} alignItems="flex-start">
          <MUIDate
            name="idIssueDate"
            label={t("idIssueDate")}
            control={control}
            value={idIssueDate}
            defaultValue={""}
            onChange={(date) => setIdIssueDate(date)}
            onError={(resone, value) => {
              setError("deliveryDate", { message: resone });
            }}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <CustomAutocomplete
            valueKey="code"
            control={control}
            errors={errors}
            name={"idType"}
            label={t("idType")}
            parseData={(data) => selectDefaultValue(data)}
            variables={{ input: { code: "ID_TYPES" } }}
            query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
            defaultValue={autocompleteValues.idType}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"idNumber"}
            label={t("idNumber")}
          />
        </Grid>
      </Paper>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid sm={3} xs={12}>
          <ListBranches
            control={control}
            errors={errors}
            name={"branchId"}
            defaultValue={autocompleteValues.branch}
            skipDefaultBranch={true}
          />
        </Grid>

        <Grid xs={12} sm={3} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"countryId"}
            label={t("country")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => selectDefaultValue(data)}
            query={LIST_COUNTRIES_DROPDOWN.query}
            onChangeValue={(e) => {
              setValue("stateId", "");
            }}
            defaultValue={autocompleteValues.country}
          />
        </Grid>
        <Grid xs={12} sm={3} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            disabled={!watch("countryId")}
            skip={!watch("countryId")}
            name={"stateId"}
            label={t("state")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => selectDefaultValue(data)}
            query={LIST_STATES_DROPDOWN.query}
            variables={{
              countryId: watch("countryId"),
            }}
            defaultValue={autocompleteValues.state}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <CustomAutocomplete
            valueKey="code"
            control={control}
            errors={errors}
            name={"category"}
            label={t("category")}
            parseData={(data) => selectDefaultValue(data)}
            variables={{ input: { code: "VENDORS_CATGORIES" } }}
            query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
            defaultValue={autocompleteValues.category}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"address"}
            label={t("address")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"email"}
            label={t("email")}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <MuiPhone
            setValue={setValue}
            codeValue={getValues().phoneCode}
            control={control}
            errors={errors}
            name={"phone"}
            rules={{ required: t("fieldIsRequired") }}
            selectName={"phoneCode"}
            label={t("phone")}
            onBlur={(e) => {
              if (Boolean(!getValues().mobile)) {
                setValue("mobile", getValues().phone, {
                  shouldDirty: true,
                });
                setValue("mobileCode", getValues().phoneCode, {
                  shouldDirty: true,
                });
              }
            }}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <MuiPhone
            setValue={setValue}
            codeValue={watch("mobileCode")}
            control={control}
            errors={errors}
            name={"mobile"}
            rules={{ required: t("fieldIsRequired") }}
            selectName={"mobileCode"}
            label={t("mobile")}
          />
        </Grid>
      </Paper>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"businessName"}
            label={t("businessName")}
          />
        </Grid>{" "}
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"businessField"}
            label={t("businessField")}
          />
        </Grid>{" "}
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"businessActivity"}
            label={t("businessActivity")}
          />
        </Grid>{" "}
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"responsibleName"}
            label={t("responsibleName")}
          />
        </Grid>
        <Grid
          xs={autocompleteValues.image || previewImage ? 10 : 12}
          sm={autocompleteValues.image || previewImage ? 6 : 8}
          alignItems="flex-start"
        >
          <UploadFile
            control={control}
            setValue={setValue}
            name="image"
            icon={"add_photo_alternate"}
            label={t("uploadImage")}
            accept=".png,.jpg"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setPreviewImage(reader.result);
                };
                reader.readAsDataURL(file);
              } else {
                setPreviewImage(null);
              }
              setAutocompleteValues((prev) => ({
                ...prev,
                image: "",
              }));
            }}
          />
        </Grid>
        {(autocompleteValues.image || previewImage) && (
          <Grid xs={2} sm={2} textAlign="center">
            {previewImage && (
              <Badge
                style={{ padding: 0 }}
                onClick={setClear}
                badgeContent={<Clear sx={{ fontSize: "10px" }} />}
                color="error"
              >
                <img
                  src={previewImage}
                  alt="Preview"
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "1%",
                  }}
                  onClick={(event) => event.stopPropagation()}
                />
              </Badge>
            )}
            {autocompleteValues.image && (
              <Badge
                onClick={() => {
                  setClear();
                }}
                badgeContent={<Clear sx={{ fontSize: "10px" }} />}
                color="error"
              >
                <Box
                  sx={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "1%",
                  }}
                  component="img"
                  alt="signature"
                  src={getBackendUri(autocompleteValues.image)}
                  onClick={(event) => event.stopPropagation()}
                />
              </Badge>
            )}
          </Grid>
        )}
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"postalCode"}
            label={t("postalCode")}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"mailBoxNumber"}
            label={t("mailBoxNumber")}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"taxCardNumber"}
            label={t("taxCardNumber")}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"registerationNumber"}
            label={t("registerationNumber")}
          />
        </Grid>
      </Paper>
      <Grid container justifyContent="flex-end" className={classesForm.spacing}>
        <FormButton disabled={saveVendorLoading}>
          {saveVendorLoading ? <ButtonLoading /> : t("save")}
        </FormButton>
      </Grid>
    </form>
  );
  let DOM;

  if (id) {
    DOM = updateVendor ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return updateVendorLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !updateVendor && id ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={pathURL} />
      <Stack spacing={2} p={2}>
        {DOM}
      </Stack>
    </RootStyleForm>
  );
};

export default VendorForm;
