import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Toolbar,
  Icon,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { Add, Edit } from "@mui/icons-material";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { isEmpty } from "lodash";
import { Globals } from "../HOC/Classes/Globals";

const PREFIX = "BankBranchesTable";

const classes = {
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  tableContainerTabes: `${PREFIX}-tableContainerTabes`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  focus: `${PREFIX}-focus`,
  tableRow: `${PREFIX}-tableRow`,
  packageType: `${PREFIX}-packageType`,
  iconColor: `${PREFIX}-iconColor`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.tableContainer}`]: {
    minHeight: "250px",
  },
  [`& .${classes.tableContainerTabes}`]: {
    minHeight: "205px",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.toolbar}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.focus}`]: {
    backgroundColor: theme.palette.action.hover,
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      cursor: "pointer",
    },
  },

  [`& .${classes.packageType}`]: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

export default function BankBranchesTable(props) {
  const { setDialogState, setUpdateAccounts, bankBranchId, accounts, loading } =
    props;
  const { t } = useTranslation();

  //////////// package Functions ////////////

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const user = Globals.user;
  const addPermission = user.hasPermission("cash.bank_account.create");
  const updatePermission = user.hasPermission("cash.bank_account.update");
  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const openDialog = (subzone) => {
    setDialogState(true);
    subzone && setUpdateAccounts(subzone);
  };

  return (
    <StyledGrid container alignItems="flex-start">
      <Grid
        container
        item
        sm={12}
        md={12}
        className={classes.packageType}
        component={Paper}
      >
        <Grid
          container
          item
          sm={12}
          md={12}
          className={classes.packageType}
          component={Paper}
        >
          <Toolbar className={classes.toolbar} variant="dense">
            <Typography className={classes.title} color="inherit" variant="h6">
              {t("accounts")}
            </Typography>
            {bankBranchId && addPermission ? (
              <IconButton
                disabled={!bankBranchId}
                onClick={() => openDialog()}
                aria-label="Add accounts"
                color="primary"
                size="large"
              >
                <Add />
              </IconButton>
            ) : null}
          </Toolbar>
          <Grid container className={classes.table}>
            <TableFixedHeaderWraper>
              {loading || isEmpty(accounts) ? (
                <EmptyTableMessage loading={loading} message={t("noResult")} />
              ) : (
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <FixedTableCell>{t("code")}</FixedTableCell>
                      <FixedTableCell>{t("name")}</FixedTableCell>
                      <FixedTableCell>{t("currency")}</FixedTableCell>
                      <FixedTableCell>{t("type")}</FixedTableCell>
                      <FixedTableCell>{t("glAccount")}</FixedTableCell>
                      <FixedTableCell>{t("active")}</FixedTableCell>
                      <FixedTableCell> </FixedTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {accounts &&
                      (rowsPerPage > 0
                        ? accounts?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : accounts
                      )?.map((row, index) => (
                        <TableRow hover key={index}>
                          <FixedTableCell>{row?.code}</FixedTableCell>
                          <FixedTableCell>{row?.name}</FixedTableCell>
                          <FixedTableCell>{row?.currency?.name}</FixedTableCell>
                          <FixedTableCell>{row?.type?.name}</FixedTableCell>
                          <FixedTableCell>
                            {row?.glAccount?.name}
                          </FixedTableCell>
                          <FixedTableCell>
                            {row?.active ? (
                              <Icon className={classes.iconColor}>
                                check_circle_outline
                              </Icon>
                            ) : (
                              <Icon color="error">highlight_off</Icon>
                            )}
                          </FixedTableCell>
                          {bankBranchId && updatePermission ? (
                            <>
                              <FixedTableCell>
                                <IconButton
                                  size="small"
                                  onClick={() => openDialog(row)}
                                >
                                  <Edit />
                                </IconButton>
                              </FixedTableCell>
                            </>
                          ) : (
                            <>
                              <FixedTableCell> </FixedTableCell>
                            </>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
            </TableFixedHeaderWraper>
            <MUITablePagination
              count={accounts.length ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePage}
              onRowsPerPageChange={handleRowsPerPage}
            />
          </Grid>
        </Grid>
      </Grid>
    </StyledGrid>
  );
}
