import { Paper, Stack, Typography } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { CustomAutocomplete } from "../../HOC/MUI/CustomAutocomplete";
import {
  LIST_AGENCIES_DROPDOWN,
  LIST_COUNTRIES_DROPDOWN,
  LIST_SHIPPING_PORTS_DROPDOWN,
} from "../../../GlobalsQuery/ListDropdown/ListDropdown";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";
import MuiPhone from "../../HOC/MUI/MUIphoneNumber";
import { useShipmentContext } from "../Context/ShipmentContext";

export const Step2 = () => {
  const {
    control,
    errors,
    t,
    getValues,
    setValue,
    autocompleteValues,
    watch,
    onChangeCustomer,
    dateFormat,
    onChangeAgency,
  } = useShipmentContext();
  return (
    <Stack spacing={2}>
      <Stack direction={{ md: "row" }} gap={2}>
        <Paper
          container
          component={Grid}
          spacing={2}
          m={0}
          width={{ md: "50%" }}
        >
          <Grid xs={12} alignItems="flex-start">
            <Typography
              variant="h6"
              gutterBottom
              color={"text.primary"}
              paddingX={1}
            >
              {t("origin")}
            </Typography>
          </Grid>
          <Grid sm={6} xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"originCountryId"}
              label={t("originCountry")}
              rules={{ required: t("fieldIsRequired") }}
              parseData={(data) => data}
              query={LIST_COUNTRIES_DROPDOWN.query}
              onChangeValue={(e) => {
                setValue("originPortId", "");
                onChangeAgency();
                setValue("originAgencyId", "");
                // if (watch("destinationCountryId") === e?.id) {
                //   setValue("destinationCountryId", "");
                // }
              }}
              defaultValue={autocompleteValues.originCountry}
            />
          </Grid>
          <Grid sm={6} xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={Boolean(
                !watch("originCountryId") || !watch("shippingMode")
              )}
              skip={Boolean(
                !watch("originCountryId") ||
                  !watch("shippingMode") ||
                  !watch("shipmentDate") ||
                  !watch("shippingDirection")
              )}
              name={"originPortId"}
              label={t("originPort")}
              rules={{ required: t("fieldIsRequired") }}
              parseData={(data) => data}
              query={LIST_SHIPPING_PORTS_DROPDOWN.query}
              variables={{
                input: {
                  countryId: watch("originCountryId"),
                  mode: watch("shippingMode"),
                  inPriceList: {
                    direction: "ORIGIN",
                    priceListFilterInput: {
                      mode: watch("shippingMode"),
                      direction: watch("shippingDirection"),
                      date: dateFormat(watch("shipmentDate")),
                    },
                  },
                },
              }}
              onChangeValue={(e) => {
                setValue("destinationPortId", "");
              }}
              defaultValue={autocompleteValues.originPort}
            />
          </Grid>
        </Paper>

        <Paper
          container
          component={Grid}
          spacing={2}
          m={0}
          width={{ md: "50%" }}
        >
          <Grid xs={12} alignItems="flex-start">
            <Typography
              variant="h6"
              gutterBottom
              color={"text.primary"}
              paddingX={1}
            >
              {t("destination")}
            </Typography>
          </Grid>
          <Grid sm={6} xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"destinationCountryId"}
              label={t("destinationCountry")}
              rules={{ required: t("fieldIsRequired") }}
              parseData={(data) => data}
              query={LIST_COUNTRIES_DROPDOWN.query}
              onChangeValue={(e) => {
                setValue("destinationPortId", "");
                onChangeCustomer();
                setValue("destinationAgencyId", "");
                setValue("commissionAmount", "");
              }}
              defaultValue={autocompleteValues.destinationCountry}
            />
          </Grid>
          <Grid sm={6} xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={Boolean(
                !watch("destinationCountryId") ||
                  !watch("shippingMode") ||
                  !watch("originPortId")
              )}
              skip={Boolean(
                !watch("destinationCountryId") ||
                  !watch("shippingMode") ||
                  !watch("shipmentDate") ||
                  !watch("shippingDirection") ||
                  !watch("originPortId")
              )}
              name={"destinationPortId"}
              label={t("destinationPort")}
              rules={{ required: t("fieldIsRequired") }}
              parseData={(data) => data}
              query={LIST_SHIPPING_PORTS_DROPDOWN.query}
              variables={{
                input: {
                  countryId: watch("destinationCountryId"),
                  mode: watch("shippingMode"),
                  inPriceList: {
                    direction: "DESTINATION",
                    priceListFilterInput: {
                      mode: watch("shippingMode"),
                      direction: watch("shippingDirection"),
                      date: dateFormat(watch("shipmentDate")),
                    },
                    fromOriginPortId: watch("originPortId"),
                  },
                },
              }}
              defaultValue={autocompleteValues.destinationPort}
            />
          </Grid>
        </Paper>
      </Stack>
      <Stack direction={{ md: "row" }} gap={2}>
        <Paper
          container
          component={Grid}
          spacing={2}
          m={0}
          width={{ md: "50%" }}
        >
          <Grid xs={12} alignItems="flex-start">
            <Typography
              variant="h6"
              gutterBottom
              color={"text.primary"}
              paddingX={1}
            >
              {t("sendingAgency")}
            </Typography>
          </Grid>

          <Grid xs={12} sm={12} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              name={"originAgencyId"}
              label={t("sendingAgency")}
              disabled={!watch("originCountryId")}
              parseData={(data) => data}
              query={LIST_AGENCIES_DROPDOWN.query}
              skip={!watch("originCountryId")}
              variables={{
                input: {
                  countryId: watch("originCountryId"),
                },
              }}
              // onCompleted={(data) => {
              //   handelAutocompleteDefaultValue(data, "AgencyId");
              // }}
              onChangeValue={(e) => {
                onChangeAgency(e);
              }}
              defaultValue={autocompleteValues.originAgency}
            />
          </Grid>

          <Grid xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              disabled={true}
              name={"shipperName"}
              label={t("name")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              disabled={true}
              name={"shipperAddress"}
              label={t("address")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>

          <Grid xs={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              disabled={true}
              name={"shipperPostalCode"}
              label={t("postalCode")}
            />
          </Grid>

          <Grid xs={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              disabled={true}
              name={"shipperStateId"}
              label={t("state")}
            />
          </Grid>
          <Grid xs={12} alignItems="flex-start">
            <MuiPhone
              setValue={setValue}
              codeValue={getValues().shipperMobileCode}
              control={control}
              errors={errors}
              disabled={true}
              disabledCode={true}
              name={"shipperMobile"}
              selectName={"shipperMobileCode"}
              label={t("phone")}
              rules={{
                required: t("fieldIsRequired"),
              }}
            />
          </Grid>
        </Paper>
        <Paper
          container
          component={Grid}
          spacing={2}
          m={0}
          width={{ md: "50%" }}
        >
          <Grid xs={12} alignItems="flex-start">
            <Typography
              variant="h6"
              gutterBottom
              color={"text.primary"}
              paddingX={1}
            >
              {t("receivingAgency")}
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              name={"destinationAgencyId"}
              label={t("receivingAgency")}
              disabled={!watch("destinationCountryId")}
              parseData={(data) => data}
              query={LIST_AGENCIES_DROPDOWN.query}
              skip={!watch("destinationCountryId")}
              variables={{
                input: {
                  countryId: watch("destinationCountryId"),
                },
              }}
              onChangeValue={(e) => {
                onChangeCustomer(e);
              }}
              defaultValue={autocompleteValues.destinationAgency}
            />
          </Grid>
          <Grid xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              disabled={true}
              name={"consigneeName"}
              label={t("name")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              disabled={true}
              name={"consigneeAddress"}
              label={t("address")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              disabled={true}
              name={"consigneePostalCode"}
              label={t("postalCode")}
            />
          </Grid>
          <Grid xs={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              disabled={true}
              name={"consigneeStateId"}
              label={t("state")}
            />
          </Grid>
          <Grid xs={12} alignItems="flex-start">
            <MuiPhone
              setValue={setValue}
              codeValue={getValues().consigneeMobileCode}
              control={control}
              errors={errors}
              disabled={true}
              disabledCode={true}
              name={"consigneeMobile"}
              selectName={"consigneeMobileCode"}
              label={t("phone")}
              rules={{
                required: t("fieldIsRequired"),
              }}
            />
          </Grid>
        </Paper>
      </Stack>
    </Stack>
  );
};
