import { useQuery, gql } from "@apollo/client";
import { Paper } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { DELETE_PRICE_LIST, PRICE_LIST } from "./Graphql";
import Grid from "@mui/material/Unstable_Grid2";
import { Globals } from "../HOC/Classes/Globals";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import PriceListTable from "./PriceListTables";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";
import { PriceListProvider } from "./PriceListContext";

const PriceListView = (props) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = React.useState(false);
  // const [openCopyDialog, setOpenCopyDialog] = React.useState(false);
  const priceListId = parseInt(props.match.params.id);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const { data, loading } = useQuery(
    gql`
      ${PRICE_LIST.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: { id: priceListId },
      onCompleted: (data) => {},
    }
  );
  const user = Globals.user;
  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/price-lists/${priceListId}/edit`),
      permission:
        user?.hasPermission("freight.price_list.update") &&
        data?.priceList?.editable,
    },
    {
      id: "delete",
      action: handleOpenDialog,
      permission: user?.hasPermission("freight.price_list.delete"),
    },
  ];
  const value = {
    RouteList: data?.priceList?.routes,
  };
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.priceList ? (
    <NotFound />
  ) : (
    <>
      {openDialog && (
        <MutationWithDialog
          mutaion={DELETE_PRICE_LIST.query}
          setOpenDelete={setOpenDialog}
          openDelete={openDialog}
          dialogTitle={t("deleteRecord")}
          dialogContent={t("deleteRecordMessage")}
          mutaionProps={{
            variables: { id: parseInt(priceListId) },
          }}
          onCompleted={() => pushUrl(props, `/admin/price-lists`)}
          onCompleteMessage={t("successfullyDeletedRecord")}
        />
      )}
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>
      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair
            md={6}
            title={t("code")}
            value={data?.priceList?.code}
          />
          <KeyValuePair
            md={6}
            title={t("manifestName")}
            value={data?.priceList.name}
          />
          <KeyValuePair
            md={6}
            title={t("validFromDate")}
            value={data?.priceList.fromDate}
          />
          <KeyValuePair
            md={6}
            title={t("currency")}
            value={data?.priceList.currency?.name}
          />
          <KeyValuePair
            md={6}
            title={t("validToDate")}
            value={data?.priceList.toDate}
          />
          <KeyValuePair
            md={6}
            title={t("shippingMode")}
            value={data?.priceList.shippingMode?.name}
          />
          <KeyValuePair
            md={6}
            title={t("shippingDirection")}
            value={data?.priceList.shippingDirection?.name}
          />
          {/* <KeyValuePair
                  md={6}
                  title={t("elements")}
                  value={data?.priceList?.chargeElement?.name}
                /> */}
          <KeyValuePair
            md={6}
            title={t("listDescription")}
            value={data?.priceList?.description}
          />
        </Paper>
        <PriceListProvider value={value}>
          {data?.priceList?.routes && <PriceListTable />}
        </PriceListProvider>
      </RootStyleView>
    </>
  );
};

export default PriceListView;
