/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Paper, Icon } from "@mui/material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { DELETE_QUERY, DIS_APPROVE_MUTATION, QUERY_ID } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import { CustomerPaymentMethod } from "../HOC/CustomComponents/CustomListDialog/RequestData";
import BillTables from "./BillTables";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
// import { useAccountType } from "./useAccountType";
import { BillProvider } from "./Context/BillContext";
import { useAccountType } from "../../Hooks/useAccountType";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";

const BillView = (props) => {
  const [pathURL] = useState(props.match.path);

  let pathname = pathURL;
  if (pathURL.includes("invoice")) {
    pathname = "invoice";
  } else {
    pathname = "bill";
  }
  const capitalPathname = pathname.charAt(0).toUpperCase() + pathname.slice(1);
  const { t } = useTranslation();
  const id = props.match.params.id?.trim();
  const user = Globals.user;
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDisapprove, setOpenDisapprove] = useState(false);

  const openDisapproveDialog = (type) => {
    setOpenDisapprove(true);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const GET_BY_ID = QUERY_ID(pathname);
  const DELETE = DELETE_QUERY(capitalPathname);
  const DIS_APPROVE = DIS_APPROVE_MUTATION(capitalPathname);
  const { data, loading, refetch } = useQuery(
    gql`
      ${GET_BY_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      // partialRefetch: refetch,
      variables: { id: parseInt(id) },
    }
  );
  const billData = data?.[pathname];

  const canEdit =
    user.hasPermission(`freight.${pathname}.update`) && !billData?.glApproved;
  const canDelete =
    user.hasPermission(`freight.${pathname}.delete`) &&
    billData?.deletable &&
    !billData?.approved;

  const canDisapprove =
    user.hasPermission(`freight.${pathname}.disapprove`) && billData?.approved;
  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/${pathname}s/${id}/edit`),
      permission: canEdit,
    },
    {
      id: "delete",
      action: handleOpenDialog,
      permission: Boolean(canDelete),
    },
    {
      id: "disapprove",
      action: openDisapproveDialog,
      permission: Boolean(canDisapprove),
    },
    {
      id: "print",
      action: () =>
        window.open(`${window.location.origin}/report/print/${pathname}/${id}`),
    },
  ];
  const totalSum = {
    totalTaxAmount: billData?.totalTaxAmount || 0, // Default to 0 if undefined
    totalAmount: billData?.totalAmount || 0, // Default to 0 if undefined
    totalAmountInLocalCurrency: billData?.totalAmountInLocalCurrency || 0, // Default to 0 if undefined
  };
  const userValue = useAccountType(billData?.accountType?.code);
  const value = {
    line: billData?.lines,
    pathname,
    loading,
    billData,
    totalSum,
  };
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !billData ? (
    <NotFound />
  ) : (
    <>
      {openDialog && (
        <MutationWithDialog
          mutaion={DELETE.query}
          setOpenDelete={setOpenDialog}
          openDelete={openDialog}
          dialogTitle={t("deleteRecord")}
          dialogContent={t("deleteRecordMessage")}
          mutaionProps={{
            variables: { id: parseInt(id) },
          }}
          onCompleted={() => pushUrl(props, `/admin/${pathname}s`)}
          onCompleteMessage={t("successfullyDeletedRecord")}
        />
      )}
      {openDisapprove && (
        <MutationWithDialog
          mutaion={DIS_APPROVE.query}
          setOpenDelete={setOpenDisapprove}
          openDelete={openDisapprove}
          dialogTitle={t("disapprove")}
          dialogContent={t("disapprovedMessage")}
          mutaionProps={{
            variables: { id: parseInt(id) },
          }}
          onCompleted={() => refetch()}
          onCompleteMessage={t("successfullyDisapproved")}
        />
      )}
      <TitleAppBar path={pathURL}>
        <LongMenu icons={icons} />
      </TitleAppBar>
      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair title={t("billCode")} value={billData?.code} />
          {pathname === "bill" ? (
            <KeyValuePair title={t("billDate")} value={billData?.billDate} />
          ) : (
            <KeyValuePair title={t("billDate")} value={billData?.invoiceDate} />
          )}
          <KeyValuePair
            title={t("branch")}
            value={
              <SpanLink pathname={`/admin/branches/${billData?.branch?.id}`}>
                {billData?.branch?.name}
              </SpanLink>
            }
          />
          <KeyValuePair
            title={t("transactionType")}
            value={
              <SpanLink
                pathname={`/admin/transaction-types/${billData?.transactionType?.id}`}
              >
                {billData?.transactionType?.name}
              </SpanLink>
            }
          />
          <KeyValuePair
            title={t("referenceNumber")}
            value={billData?.referenceNumber}
          />
          <KeyValuePair
            title={t("referenceDate")}
            value={billData?.referenceDate}
          />

          <KeyValuePair
            title={t("documentType")}
            value={billData?.documentType?.name}
          />
          <KeyValuePair
            title={t("paymentType")}
            value={billData?.paymentType?.name}
          />
          <KeyValuePair
            title={t("journalEntry")}
            value={
              <SpanLink
                pathname={`/admin/finance/journal-entries/${billData?.journalEntry?.id}`}
              >
                {billData?.journalEntry?.code}
              </SpanLink>
            }
          />
        </Paper>
        <Paper
          container
          spacing={2}
          sx={{ p: 1 }}
          className={classesView.paper}
          component={Grid}
        >
          <KeyValuePair
            title={t("accountType")}
            value={billData?.accountType?.name}
          />

          <KeyValuePair
            title={userValue?.label}
            value={
              userValue?.queryName === "customAgent" ? (
                <SpanLink
                  pathname={`/admin/custom-agents/${billData?.agent?.id}`}
                >
                  {billData?.agent?.name}
                </SpanLink>
              ) : (
                <SpanLink
                  pathname={`/admin/${userValue?.queryName}/${
                    billData[userValue?.queryName]?.id
                  }`}
                >
                  {billData[userValue?.queryName]?.name}
                </SpanLink>
              )
            }
          />

          <KeyValuePair
            title={t("code")}
            value={
              billData?.request ? (
                <SpanLink pathname={`/admin/requests/${billData?.request?.id}`}>
                  {billData?.request?.code}
                </SpanLink>
              ) : (
                <SpanLink
                  pathname={`/admin/shipments/${billData?.shipment?.id}`}
                >
                  {billData?.shipment?.code}
                </SpanLink>
              )
            }
          />
          <KeyValuePair
            title={t("currency")}
            value={billData?.currency?.name}
          />
          <KeyValuePair
            title={t("exchangeRate")}
            value={billData?.exchangeRate}
          />
          <KeyValuePair title={t("notes")} value={billData?.remarks} />

          <KeyValuePair
            title={t("approved")}
            value={
              billData?.approved ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("glApproved")}
            value={
              billData?.glApproved ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          {billData?.request ? (
            <CustomerPaymentMethod
              // setValue={setValue}
              classes={classesView}
              picked={billData?.request}
            />
          ) : (
            <CustomerPaymentMethod
              // setValue={setValue}
              classes={classesView}
              picked={billData?.shipment}
            />
          )}
        </Paper>
        <BillProvider value={value}>
          <BillTables />
        </BillProvider>
      </RootStyleView>
    </>
  );
};

export default BillView;
