import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import {
  TableBody,
  TableRow,
  Button,
  Box,
  Icon,
  Checkbox,
  TableHead,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { EXPOERT_REQUESTS, LIST_REQUESTS } from "./Graphql";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useWidth from "../../Hooks/useWidth";
import config from "../../config.json";
import Grid from "@mui/material/Unstable_Grid2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_COUNTRIES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_SHIPPING_PORTS_DROPDOWN,
  LIST_VENDORS_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import { dateFormat } from "../../helpers/dateFunctions";
import { MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete";
import moment from "moment";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import MUIselect from "../HOC/MUI/MUIselect";
import { TableCellColor } from "../HOC/CustomComponents/TableCellColor";
import { RootStyleList, classes } from "../../GlobalStyles/ListStyle";
import clsx from "clsx";
import CustomExportExcel from "../HOC/CustomComponents/CustomExportExcel";
import { Globals } from "../HOC/Classes/Globals";
import { ROLE } from "../../helpers/helpersFunction";

const RequestList = (props) => {
  const { t } = useTranslation();
  const screenWidth = useWidth();
  const history = useHistory();
  const [refetch, setrefetch] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const [autocompleteValues, setAutocompleteValues] = useState({
    customer: null,
    vendor: null,
    branch: null,
    loadingMode: null,
    status: null,
    originCountry: null,
    destinationCountry: null,
    originPort: null,
    destinationPort: null,
    mode: [],
    shippingDirection: [],
  });
  const [noData, setNoData] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(
    urlQuery["rowsPerPage"] ?? config.app.pageSize
  );
  const user = Globals.user;
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";
  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const [shippingDate, setShippingDate] = useState([
    urlQuery["fromDate"] ? urlQuery["fromDate"] : null,
    urlQuery["toDate"] ? urlQuery["toDate"] : null,
  ]);
  const [createdDate, setCreatedDate] = useState([
    urlQuery["createdFromDate"] ? urlQuery["createdFromDate"] : null,
    urlQuery["createdToDate"] ? urlQuery["createdToDate"] : null,
  ]);
  const [finishDate, setFinishDate] = useState([
    urlQuery["finishFromDate"] ? urlQuery["finishFromDate"] : null,
    urlQuery["finishToDate"] ? urlQuery["finishToDate"] : null,
  ]);
  const [requestDate, setRequestDate] = useState([
    urlQuery["requestFromDate"] ? urlQuery["requestFromDate"] : null,
    urlQuery["requestToDate"] ? urlQuery["requestToDate"] : null,
  ]);
  const fromDate = moment(shippingDate[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(shippingDate[1]).locale("en").format("YYYY-MM-DD");
  const createdFromDate = moment(createdDate[0])
    .locale("en")
    .format("YYYY-MM-DD");
  const createdToDate = moment(createdDate[1])
    .locale("en")
    .format("YYYY-MM-DD");
  const finishFromDate = moment(finishDate[0])
    .locale("en")
    .format("YYYY-MM-DD");
  const finishToDate = moment(finishDate[1]).locale("en").format("YYYY-MM-DD");
  const requestFromDate = moment(requestDate[0])
    .locale("en")
    .format("YYYY-MM-DD");
  const requestToDate = moment(requestDate[1])
    .locale("en")
    .format("YYYY-MM-DD");
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      ...(shippingDate[0] && { fromDate }),
      ...(shippingDate[1] && { toDate }),
      ...(createdDate[0] && { createdFromDate }),
      ...(createdDate[1] && { createdToDate }),
      ...(finishDate[0] && { finishFromDate }),
      ...(finishDate[1] && { finishToDate }),
      ...(requestDate[0] && { requestFromDate }),
      ...(requestDate[1] && { requestToDate }),
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      urlQuery["hasPackages"] !== undefined &&
        setValue("hasPackages", urlQuery["hasPackages"]);
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      if (urlQuery["shippingMode"]) {
        urlQuery["shippingMode"] = urlQuery["shippingMode"].split(",");
      }
      if (urlQuery["shippingDirection"]) {
        urlQuery["shippingDirection"] =
          urlQuery["shippingDirection"].split(",");
      }
      if (urlQuery["loadingMode"]) {
        urlQuery["loadingMode"] = urlQuery["loadingMode"].split(",");
      }
      if (urlQuery["status"]) {
        urlQuery["status"] = urlQuery["status"].split(",");
      }
      if (urlQuery.fromDate || urlQuery.toDate) {
        const shippingDate = {
          ...(urlQuery?.fromDate && { fromDate: urlQuery?.fromDate }),
          ...(urlQuery?.toDate && { toDate: urlQuery?.toDate }),
        };
        delete urlQuery["fromDate"];
        delete urlQuery["toDate"];
        urlQuery.shippingDate = shippingDate;
      }
      if (urlQuery.createdFromDate || urlQuery.createdToDate) {
        const createdDate = {
          ...(urlQuery?.createdFromDate && {
            fromDate: urlQuery?.createdFromDate,
          }),
          ...(urlQuery?.createdToDate && { toDate: urlQuery?.createdToDate }),
        };
        delete urlQuery["createdFromDate"];
        delete urlQuery["createdToDate"];
        urlQuery.createdDate = createdDate;
      }
      if (urlQuery.finishFromDate || urlQuery.finishToDate) {
        const finishDate = {
          ...(urlQuery?.finishFromDate && {
            fromDate: urlQuery?.finishFromDate,
          }),
          ...(urlQuery?.finishToDate && {
            toDate: urlQuery?.finishToDate,
          }),
        };
        delete urlQuery["finishFromDate"];
        delete urlQuery["finishToDate"];
        urlQuery.finishDate = finishDate;
      }
      if (urlQuery.requestFromDate || urlQuery.requestToDate) {
        const requestDate = {
          ...(urlQuery?.requestFromDate && {
            fromDate: urlQuery?.requestFromDate,
          }),
          ...(urlQuery?.requestToDate && {
            toDate: urlQuery?.requestToDate,
          }),
        };
        delete urlQuery["requestFromDate"];
        delete urlQuery["requestToDate"];
        urlQuery.requestDate = requestDate;
      }
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());
  const [openExport, setOpenExport] = useState(false);

  const { data, loading } = useQuery(
    gql`
      ${LIST_REQUESTS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          ...(search.code && { code: search.code }),
          ...(search.branchId && {
            branchId: search.branchId,
          }),
          ...(search.shippingMode && {
            shippingMode: search.shippingMode,
          }),
          ...(search.shippingDirection && {
            shippingDirection: search.shippingDirection,
          }),
          ...(search.status && {
            status: search.status,
          }),
          ...(search.loadingMode && {
            loadingMode: search.loadingMode,
          }),
          ...(search.customerId && {
            customerId: search.customerId,
          }),
          ...(search.vendorId && {
            vendorId: search.vendorId,
          }),
          ...(search.destinationPortId && {
            destinationPortId: search.destinationPortId,
          }),
          ...(search.originPortId && { originPortId: search.originPortId }),
          ...(search.originCountryId && {
            originCountryId: search.originCountryId,
          }),
          ...(search.destinationCountryId && {
            destinationCountryId: search.destinationCountryId,
          }),
          ...(search.shippingDate && { shippingDate: search.shippingDate }),
          ...(search.createdDate && { createdDate: search.createdDate }),
          ...(search.finishDate && { finishDate: search.finishDate }),
          ...(search.requestDate && { requestDate: search.requestDate }),
          hasPackages: search.hasPackages,
        },
      },
      onCompleted: () => {
        setNoData(false);
      },
    }
  );

  const listRequests = data?.listRequests?.data;
  const filters = {
    ...(search.code && { code: search.code }),
    ...(search.branchId && {
      branchId: search.branchId,
    }),
    ...(search.shippingMode && {
      shippingMode: search.shippingMode,
    }),
    ...(search.shippingDirection && {
      shippingDirection: search.shippingDirection,
    }),
    ...(search.status && {
      status: search.status,
    }),
    ...(search.loadingMode && {
      loadingMode: search.loadingMode,
    }),
    ...(search.customerId && {
      customerId: search.customerId,
    }),
    ...(search.vendorId && {
      vendorId: search.vendorId,
    }),
    ...(search.destinationPortId && {
      destinationPortId: search.destinationPortId,
    }),
    ...(search.originPortId && { originPortId: search.originPortId }),
    ...(search.originCountryId && {
      originCountryId: search.originCountryId,
    }),
    ...(search.destinationCountryId && {
      destinationCountryId: search.destinationCountryId,
    }),
    ...(search.shippingDate && { shippingDate: search.shippingDate }),
    ...(search.createdDate && { createdDate: search.createdDate }),
    ...(search.finishDate && { finishDate: search.finishDate }),
    ...(search.requestDate && { requestDate: search.requestDate }),
    hasPackages: search.hasPackages,
  };
  const fields = [
    { name: "CODE", label: t("code") },
    { name: "DATE", label: t("date") },
    { name: "STATUS", label: t("status") },
    { name: "BRANCH", label: t("branch") },
    { name: "DIRECTION", label: t("shippingDirection") },
    { name: "MODE", label: t("shippingMode") },
    { name: "LOADING_MODE", label: t("loadingMode") },
    { name: "ORIGIN_COUNTRY", label: t("originCountry") },
    { name: "ORIGIN_PORT", label: t("originPort") },
    { name: "SHIPPER_NAME", label: t("senderName") },
    { name: "SHIPPER_MOBILE", label: t("senderMobile") },
    { name: "DESTINATION_COUNTRY", label: t("destinationCountry") },
    { name: "DESTINATION_PORT", label: t("destinationPort") },
    { name: "CONSIGNEE_NAME", label: t("consigneeName") },
    { name: "CONSIGNEE_MOBILE", label: t("recipientMobile") },
    { name: "SHIPPING_DATE", label: t("shippingDate") },
    { name: "FINISH_DATE", label: t("finishDate") },
    { name: "TOTAL_PACKAGES", label: t("totalPackages") },
    { name: "AMOUNT", label: t("amount") },
    { name: "CURRENCY", label: t("currency") },
    { name: "REMARKS", label: t("notes") },
    { name: "EXCHANGE_RATE", label: t("exchangeRate") },
    { name: "HAS_PACKAGES", label: t("hasPackages") },
  ];
  useEffect(() => {
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    urlQuery["hasPackages"] !== undefined &&
      setValue("hasPackages", urlQuery["hasPackages"]);
    setAutocompleteValues((prev) => ({
      ...(urlQuery["branchId"] && {
        branch: urlQuery["branchId"],
      }),
      ...(urlQuery["vendorId"] && {
        vendor: urlQuery["vendorId"],
      }),
      ...(urlQuery["customerId"] && {
        customer: urlQuery["customerId"],
      }),
      ...(urlQuery["shippingMode"] && {
        mode: urlQuery["shippingMode"],
      }),
      ...(urlQuery["shippingDirection"] && {
        shippingDirection: urlQuery["shippingDirection"],
      }),
      ...(urlQuery["loadingMode"] && {
        loadingMode: urlQuery["loadingMode"],
      }),
      ...(urlQuery["status"] && {
        status: urlQuery["status"],
      }),
      ...(urlQuery["destinationPortId"] && {
        destinationPort: urlQuery["destinationPortId"],
      }),
      ...(urlQuery["destinationCountryId"] && {
        destinationCountry: urlQuery["destinationCountryId"],
      }),
      ...(urlQuery["originCountryId"] && {
        originCountry: urlQuery["originCountryId"],
      }),
      ...(urlQuery["originPortId"] && {
        originPort: urlQuery["originPortId"],
      }),
    }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openExportDialog = () => {
    setOpenExport(true);
  };

  const resetShippingDate = () => {
    setShippingDate([null, null]);
  };
  const resetCreatedDate = () => {
    setCreatedDate([null, null]);
  };
  const resetFinishDate = () => {
    setFinishDate([null, null]);
  };
  const resetRequestDate = () => {
    setRequestDate([null, null]);
  };
  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: `/admin/requests`,
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };

  const [checkedIds, setCheckedIds] = useState([]); // Local state to manage checked IDs
  // const [id, setId] = useState([]); // Local state to manage checked IDs
  const currentIds = listRequests?.map((i) => i.id);
  const isChecked = (id) => {
    return checkedIds.includes(id);
  };
  const fieldSelected = currentIds?.filter((i) =>
    checkedIds.includes(i)
  )?.length; // Count of selected IDs in the current row

  // Determines if "Select All" should be in an indeterminate state
  const indeterminate = () =>
    fieldSelected > 0 && fieldSelected < currentIds?.length;
  // Determines if "Select All" should be fully checked
  const selectAllChecked = () =>
    currentIds?.length > 0 && fieldSelected === currentIds?.length;

  const onCheckAll = (e) => {
    let ids = [];
    if (e.target.checked) {
      const checkedAll = new Set([...checkedIds, ...currentIds]);
      ids = [...checkedAll];
    } else {
      ids = checkedIds.filter((i) => !currentIds.includes(i));
    }
    setCheckedIds(ids);
  };

  const toggleCheck = (e, id) => {
    const checked = e.target.checked;
    let updateCheckedIds = [...checkedIds];
    if (checked) {
      updateCheckedIds.push(id);
    } else {
      updateCheckedIds = updateCheckedIds.filter((i) => i !== id);
    }
    setCheckedIds(updateCheckedIds);
  };

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    refetch ? setrefetch(false) : setrefetch(true);
    let handledData = {
      code: data.code,
      branchId: data.branchId,
      vendorId: data.vendorId,
      customerId: data.customerId,
      shippingMode: data.shippingMode,
      shippingDirection: data.shippingDirection,
      status: data.status,
      loadingMode: data.loadingMode,
      destinationCountryId: data.destinationCountryId,
      destinationPortId: data.destinationPortId,
      originCountryId: data.originCountryId,
      originPortId: data.originPortId,
      hasPackages: data.hasPackages,
      ...(shippingDate[0] && { fromDate }),
      ...(shippingDate[1] && { toDate }),
      ...(createdDate[0] && { createdFromDate }),
      ...(createdDate[1] && { createdToDate }),
      ...(finishDate[0] && { finishFromDate }),
      ...(finishDate[1] && { finishToDate }),
      ...(requestDate[0] && { requestFromDate }),
      ...(requestDate[1] && { requestToDate }),
      page: 0,
    };
    data["hasPackages"] === "" && delete handledData["hasPackages"];
    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
    if (handledData.fromDate || handledData.toDate) {
      const shippingDate = {
        ...(handledData?.fromDate && { fromDate: handledData?.fromDate }),
        ...(handledData?.toDate && { toDate: handledData?.toDate }),
      };
      delete handledData["fromDate"];
      delete handledData["toDate"];
      handledData.shippingDate = shippingDate;
    }
    if (handledData.createdFromDate || handledData.createdToDate) {
      const createdDate = {
        ...(handledData?.createdFromDate && {
          fromDate: handledData?.createdFromDate,
        }),
        ...(handledData?.createdToDate && {
          toDate: handledData?.createdToDate,
        }),
      };
      delete handledData["createdFromDate"];
      delete handledData["createdToDate"];
      handledData.createdDate = createdDate;
    }
    if (handledData.finishFromDate || handledData.finishToDate) {
      const finishDate = {
        ...(handledData?.finishFromDate && {
          fromDate: handledData?.finishFromDate,
        }),
        ...(handledData?.finishToDate && {
          toDate: handledData?.finishToDate,
        }),
      };
      delete handledData["finishFromDate"];
      delete handledData["finishToDate"];
      handledData.finishDate = finishDate;
    }
    if (handledData.requestFromDate || handledData.requestToDate) {
      const requestDate = {
        ...(handledData?.requestFromDate && {
          fromDate: handledData?.requestFromDate,
        }),
        ...(handledData?.requestToDate && {
          toDate: handledData?.requestToDate,
        }),
      };
      delete handledData["requestFromDate"];
      delete handledData["requestToDate"];
      handledData.requestDate = requestDate;
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
  };

  const parseData = (data) => {
    return data;
  };
  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  const icons = [
    {
      id: "filterList",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
    },
    {
      id: "add",
      action: () => pushUrl(props, `/admin/requests/create`),
      permission: "freight.request.create",
    },
    {
      id: "export",
      action: openExportDialog,
      disabled: !Boolean(data),
    },
  ];

  return (
    <RootStyleList>
      {openExport && (
        <CustomExportExcel
          filters={filters}
          fields={fields}
          localStorageName={"export-requests"}
          ExportMutation={EXPOERT_REQUESTS}
          mutationName={"exportRequests"}
          openExport={openExport}
          setOpenExport={setOpenExport}
        />
      )}
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={listRequests?.length === 0}
        loading={loading}
        filters={
          <Grid container component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid sm={12} alignItems="flex-start">
                <MultipleAutocomplete
                  valueKey="code"
                  multiple
                  hideCode={true}
                  control={control}
                  errors={errors}
                  name={"status"}
                  label={t("status")}
                  parseData={(data) => parseData(data)}
                  variables={{ input: { code: "FR_REQUEST_STATUS" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.status}
                  skip={noData}
                />
              </Grid>
              <Grid sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  startText={t("createdAt")}
                  value={createdDate}
                  onChangeValue={(value) => setCreatedDate(value)}
                  resetDate={resetCreatedDate}
                />
              </Grid>
              <Grid sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  startText={t("requestDate")}
                  value={requestDate}
                  onChangeValue={(value) => setRequestDate(value)}
                  resetDate={resetRequestDate}
                />
              </Grid>
              <Grid sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  startText={t("shippingDate")}
                  value={shippingDate}
                  onChangeValue={(value) => setShippingDate(value)}
                  resetDate={resetShippingDate}
                />
              </Grid>

              <Grid sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  startText={t("finishDate")}
                  value={finishDate}
                  onChangeValue={(value) => setFinishDate(value)}
                  resetDate={resetFinishDate}
                />
              </Grid>

              <Grid sm={12} alignItems="flex-start">
                <ListBranches
                  control={control}
                  errors={errors}
                  name={"branchId"}
                  defaultValue={autocompleteValues.branch}
                  skipDefaultBranch={true}
                  skip={noData}
                />
              </Grid>
              <Grid sm={12} alignItems="flex-start">
                <MultipleAutocomplete
                  valueKey="code"
                  multiple
                  control={control}
                  errors={errors}
                  name={"shippingMode"}
                  label={t("shippingMode")}
                  parseData={(data) => parseData(data)}
                  variables={{ input: { code: "FR_SHIPPING_MODE" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.mode}
                  skip={noData}
                />
              </Grid>
              <Grid sm={12} alignItems="flex-start">
                <MultipleAutocomplete
                  name={"shippingDirection"}
                  label={t("shippingDirection")}
                  control={control}
                  errors={errors}
                  variables={{ input: { code: "FR_SHIPPING_DIRECTION" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  parseData={(data) => parseData(data)}
                  multiple
                  valueKey="code"
                  defaultValue={autocompleteValues.shippingDirection}
                  skip={noData}
                />
              </Grid>
              <Grid sm={12} alignItems="flex-start">
                <MultipleAutocomplete
                  name={"loadingMode"}
                  label={t("loadingMode")}
                  control={control}
                  errors={errors}
                  variables={{ input: { code: "FR_LOADING_MODE" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  parseData={(data) => parseData(data)}
                  multiple
                  valueKey="code"
                  defaultValue={autocompleteValues.loadingMode}
                  skip={noData}
                />
              </Grid>
              <Grid sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"originCountryId"}
                  label={t("originCountry")}
                  parseData={(data) => parseData(data)}
                  query={LIST_COUNTRIES_DROPDOWN.query}
                  onChangeValue={(e) => {
                    setValue("originPortId", "");
                  }}
                  defaultValue={autocompleteValues.originCountry}
                  skip={noData}
                />
              </Grid>
              <Grid sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"originPortId"}
                  label={t("originPort")}
                  parseData={(data) => parseData(data)}
                  query={LIST_SHIPPING_PORTS_DROPDOWN.query}
                  variables={{
                    input: {
                      countryId: watch("originCountryId"),
                    },
                  }}
                  defaultValue={autocompleteValues.originPort}
                  skip={noData || !watch("originCountryId")}
                />
              </Grid>
              <Grid sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"destinationCountryId"}
                  label={t("destinationCountry")}
                  parseData={(data) => parseData(data)}
                  query={LIST_COUNTRIES_DROPDOWN.query}
                  onChangeValue={(e) => {
                    setValue("destinationPortId", "");
                  }}
                  defaultValue={autocompleteValues.destinationCountry}
                  skip={noData}
                />
              </Grid>
              <Grid sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"destinationPortId"}
                  label={t("destinationPort")}
                  parseData={(data) => parseData(data)}
                  query={LIST_SHIPPING_PORTS_DROPDOWN.query}
                  variables={{
                    input: {
                      countryId: watch("destinationCountryId"),
                    },
                  }}
                  defaultValue={autocompleteValues.destinationPort}
                  skip={noData || !watch("destinationCountryId")}
                />
              </Grid>

              <Grid sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"vendorId"}
                  label={t("sender")}
                  parseData={(data) => parseData(data)}
                  query={LIST_VENDORS_DROPDOWN.query}
                  defaultValue={autocompleteValues.vendor}
                  skip={noData}
                />
              </Grid>
              {user?.account?.__typename !== ROLE.customer && (
                <Grid sm={12} alignItems="flex-start">
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    name={"customerId"}
                    label={t("customer")}
                    parseData={(data) => parseData(data)}
                    query={LIST_CUSTOMERS_DROPDOWN.query}
                    defaultValue={autocompleteValues.customer}
                    skip={noData}
                  />
                </Grid>
              )}

              <Grid sm={12} alignItems="flex-start">
                <MUIselect
                  name={"hasPackages"}
                  label={t("hasPackages")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("no"), value: false },
                    { key: t("yes"), value: true },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid
              container
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          <TableHead>
            <TableRow>
              <FixedTableCell
                className={clsx(classes.toolbar, classes.checkbox)}
              >
                <Checkbox
                  edge="start"
                  id="check-all-details"
                  indeterminate={indeterminate()}
                  checked={selectAllChecked()}
                  inputProps={{
                    "aria-label": "select all items",
                  }}
                  tabIndex={-1}
                  onChange={(e) => onCheckAll(e)}
                  disableRipple
                />
              </FixedTableCell>
              <FixedTableCell className={classes.checkboxTable_code}>
                {t("requestCode")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("status")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("requestDate")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("branch")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("originCountry")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("originPort")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("destinationCountry")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("destinationPort")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("shippingMode")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("shippingDirection")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("loadingMode")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("senderName")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("senderPhone")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("senderAddress")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("senderPostalCode")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("senderState")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("consigneeName")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("recipientPhone")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("recipientAddress")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("recipientPostalCode")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("recipientState")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("shippingDate")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("finishDate")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("hasPackages")}
              </FixedTableCell>
            </TableRow>
          </TableHead>
        }
        tableBody={
          <TableBody>
            {listRequests &&
              listRequests?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    className={classes.tableRow}
                  >
                    <FixedTableCell
                      key={`${index}-che`}
                      className={classes.bodyCheckbox}
                    >
                      <Checkbox
                        edge="start"
                        checked={isChecked(row?.id)}
                        onChange={(e) => toggleCheck(e, row?.id)}
                        disableRipple
                      />
                    </FixedTableCell>
                    <CellLink
                      pathname={`/admin/requests/${row?.id}`}
                      className={classes.checkboxTable_bodyCode}
                    >
                      {row?.code}
                    </CellLink>
                    <TableCellColor align="left" status={row?.status} />
                    <FixedTableCell>
                      {dateFormat(row?.requestDate)}
                    </FixedTableCell>
                    <CellLink pathname={`/admin/branches/${row?.branch?.id}`}>
                      {row?.branch?.name}
                    </CellLink>
                    <CellLink
                      pathname={`/admin/countries/${row?.originCountry?.id}`}
                    >
                      {row?.originCountry?.name}
                    </CellLink>
                    <CellLink
                      pathname={`/admin/countries/${row?.originPort?.id}`}
                    >
                      {row?.originPort?.name}
                    </CellLink>
                    <CellLink
                      pathname={`/admin/countries/${row?.destinationCountry?.id}`}
                    >
                      {row?.destinationCountry?.name}
                    </CellLink>
                    <CellLink
                      pathname={`/admin/countries/${row?.destinationPort?.id}`}
                    >
                      {row?.destinationPort?.name}
                    </CellLink>
                    <FixedTableCell>{row.shippingMode?.name}</FixedTableCell>
                    <FixedTableCell>
                      {row?.shippingDirection?.name}
                    </FixedTableCell>
                    <FixedTableCell>{row?.loadingMode?.name}</FixedTableCell>
                    <CellLink pathname={`/admin/requests/${row?.vendor?.id}`}>
                      <Box
                        component="span"
                        fontWeight="bold"
                      >{` (${row?.vendor?.code}) `}</Box>
                      <Box component="span">{row?.shipperName}</Box>
                    </CellLink>
                    <FixedTableCell dir={"ltr"}>
                      {row?.shipperMobile}
                    </FixedTableCell>
                    <FixedTableCell>{row?.shipperAddress}</FixedTableCell>
                    <FixedTableCell>{row?.shipperPostalCode}</FixedTableCell>
                    <FixedTableCell>{row?.shipperState?.name}</FixedTableCell>
                    <CellLink
                      pathname={`/admin/customers/${row?.customer?.id}`}
                    >
                      <Box
                        component="span"
                        fontWeight="bold"
                      >{` (${row?.customer?.code}) `}</Box>
                      <Box component="span">{row?.consigneeName}</Box>
                    </CellLink>
                    <FixedTableCell dir={"ltr"}>
                      {row?.consigneeMobile}
                    </FixedTableCell>
                    <FixedTableCell>{row?.consigneeAddress}</FixedTableCell>
                    <FixedTableCell>{row?.consigneePostalCode}</FixedTableCell>
                    <FixedTableCell>{row?.consigneeState?.name}</FixedTableCell>
                    <FixedTableCell>
                      {dateFormat(row?.shippingDate)}
                    </FixedTableCell>
                    <FixedTableCell>
                      {dateFormat(row?.finishDate)}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.hasPackages ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listRequests?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listRequests ? 0 : search.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </RootStyleList>
  );
};

export default RequestList;
