import * as gqlb from "gql-query-builder";
const fields = [
  "id",
  "code",
  "shippingDate",
  "finishDate",
  "requestDate",
  "consigneeName",
  "consigneeAddress",
  "consigneePostalCode",
  "consigneeMobile",
  "shipperName",
  "shipperAddress",
  "shipperPostalCode",
  "shipperMobile",
  {
    consigneeState: ["id", "name", "code"],
  },
  {
    shipperState: ["id", "name", "code"],
  },

  {
    branch: ["id", "name", "code"],
  },
  {
    shippingDirection: ["id", "name", "code"],
  },
  {
    shippingMode: ["id", "name", "code"],
  },
  {
    loadingMode: ["id", "name", "code"],
  },
  {
    originCountry: ["id", "name", "code"],
  },
  {
    originPort: ["id", "name", "code"],
  },
  {
    destinationCountry: ["id", "name", "code"],
  },
  {
    destinationPort: ["id", "name", "code"],
  },
  {
    status: ["id", "name", "code"],
  },
  {
    vendor: ["id", "code", "name"],
  },
  {
    customer: ["id", "code", "name"],
  },
];
const commonViewForm = [
  "remarks",
  "payToVendor",
  "payableAmount",
  "exchangeRate",
  "date",
  "totalPackages",
  "volume",
  "weight",
  "freightAmount",
  "commissionAmount",
  {
    payableCurrency: ["id", "name", "code"],
  },
  {
    shipment: ["id", "code"],
  },
  {
    currency: ["id", "name", "code"],
  },
  {
    transactionType: ["id", "name", "code"],
  },
];

const packagesFields = [
  "detailsCount",
  "totalLoadedPackages",
  "totalUnloadedPackages ",
  "totalDamagedPackages",
  "totalMissingPackages",
  {
    packages: [
      "id",
      "code",
      "description",
      "fragile",
      "height",
      "weight",
      "length",
      "breadth",
      "volume",
      "count",
      "totalWeight",
      "chargeAmount",
      "pieces",
      "volumetricWeight",
      "volumetricFactor",
      "chargeableUnits",
      "chargePrice",
      "exchangeRate",
      { packageType: ["id", "name", "code"] },
      { product: ["id", "name", "code"] },
      { priceMode: ["id", "name", "code"] },
      {
        image: ["path"],
      },
      { currency: ["id", "name", "code"] },
      { commodities: ["count ", "id", { commodity: ["id", "name", "code"] }] },
    ],
  },
];
const transactionsFields = [
  {
    transactions: [
      "id",
      "transactionDate",
      "totalPackages",
      {
        operation: [
          "code",
          "id",

          { branch: ["code", "id", "name"] },
          { warehouse: ["code", "id", "name"] },
        ],
      },
      {
        requestStatus: ["code", "name"],
      },
      {
        request: ["code"],
      },
      {
        createdBy: ["id", "username"],
      },
    ],
  },
];
const BILL_Invoice = [
  "id",
  "code",
  "date",
  "totalAmount",
  "exchangeRate",
  "glApproved",
  "approved",
  {
    branch: ["id", "code", "name"],
  },
  {
    lines: [
      "id",
      "chargePrice",
      "chargeableUnits",
      "lineAmount",
      "taxAmount",
      "totalAmount",
      "deletable",

      {
        taxes: [
          "id",
          "rate",
          "value",
          {
            tax: ["id", "code", "name"],
          },
        ],
      },
      {
        priceMode: ["id", "code", "name"],
      },
      {
        chargeElement: ["id", "code", "name"],
      },
    ],
  },
  // {
  //   request: [
  //     "id",
  //     "code",
  //     "shipperName",
  //     "consigneeName",
  //     {
  //       shippingMode: ["id", "name", "code"],
  //     },
  //   ],
  // },
  // {
  //   accountType: ["id", "code", "name"],
  // },
  // {
  //   vendor: ["id", "code", "name"],
  // },
  // {
  //   employee: ["id", "code", "name"],
  // },
  // {
  //   customer: ["id", "code", "name"],
  // },
  // {
  //   agent: ["id", "code", "name"],
  // },
  // {
  //   agency: ["id", "code", "name"],
  // },
  // {
  //   carrier: ["id", "code", "name"],
  // },

  {
    currency: ["id", "code", "name"],
  },

  {
    paymentType: ["id", "code", "name"],
  },
];

export const LIST_REQUESTS = gqlb.query({
  operation: "listRequests",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [...fields, "hasPackages"],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListRequestsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const REQUEST_ID = (BILL_PERMISSION, INVOICE_PERMISSION) =>
  gqlb.query({
    operation: "request",
    fields: [
      ...fields,
      ...commonViewForm,
      ...packagesFields,
      ...transactionsFields,
      BILL_PERMISSION ? { bills: [...BILL_Invoice, "billDate"] } : "",
      INVOICE_PERMISSION
        ? {
            invoices: [...BILL_Invoice, "invoiceDate"],
          }
        : "",
    ],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  });

export const REQUEST_PACKAGE_DETAILS = gqlb.query([
  {
    operation: "request",
    fields: [
      {
        packages: [
          "id",
          {
            operation: `details`,
            fields: [
              {
                operation: `paginatorInfo`,
                fields: [
                  "count",
                  "currentPage",
                  "total",
                  "firstItem",
                  "hasMorePages",
                  "lastItem",
                  "lastPage",
                  "perPage",
                ],
                variables: {},
              },
              {
                operation: `data`,
                fields: [
                  "id",
                  "missing",
                  "damaged",
                  "barcode",
                  "pieceNumber",
                  { warehouse: ["id", "name", "code"] },
                  {
                    package: ["id", { request: ["detailsCount"] }],
                  },
                ],
                variables: {},
              },
            ],
            variables: {
              first: {
                type: "Int",
              },
              page: {
                type: "Int",
              },
            },
          },
        ],
      },
    ],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
]);

export const SAVE_REQUEST = gqlb.mutation({
  operation: "saveRequest",
  fields: ["id", "code"],
  variables: {
    input: {
      type: "RequestInput",
      required: true,
    },
  },
});
export const SAVE_PACKAGE_DETAILS = gqlb.mutation({
  operation: "updatePackageDetails",
  fields: [
    "id",
    "missing",
    "damaged",
    "barcode",
    "pieceNumber",
    { warehouse: ["id", "name", "code"] },
    {
      package: ["id", { request: ["detailsCount"] }],
    },
  ],
  variables: {
    input: {
      type: "PackageDetailsInput",
      required: true,
    },
  },
});

export const DELETE_PACKAGE_COMMODITY = gqlb.mutation({
  operation: "deletePackageCommodity",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
const VendorFields = [
  "id",
  "code",
  "name",
  "address",
  "postalCode",
  "mobile",
  { state: ["id", "name", "code"] },
];

export const VENDOR_ID = gqlb.query({
  operation: "vendor",
  fields: VendorFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const CUSTOMER_ID = gqlb.query({
  operation: "customer",
  fields: [
    ...VendorFields,
    "commission",
    {
      priceModes: [
        "id",
        "date",
        { shippingMode: ["id", "code", "name"] },
        { priceMode: ["id", "code", "name"] },
      ],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const PACKAGE_ID = gqlb.query({
  operation: "packageType",
  fields: ["height", "length", "breadth", "volume"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const FREIGHT_SETTING = gqlb.query({
  operation: "freightSettings",
  fields: ["volumetricFactor", "createRequestInPast"],
  variables: {},
});

export const SAVE_REQUEST_PACKAGE = gqlb.mutation({
  operation: "saveRequestPackage",
  fields: [
    "id",
    "code",
    "fragile",
    "height",
    "weight",
    "length",
    "breadth",
    "count",
    "totalWeight",
    "pieces",
    "volume",
    "volumetricWeight",
    "volumetricFactor",
    "chargeableUnits",
    "chargePrice",
    "chargeAmount",
    "description",
    "exchangeRate",
    "productPrice",
    { currency: ["id", "name", "code"] },
    { packageType: ["id", "name", "code"] },
    { priceMode: ["id", "name", "code"] },
    { originCountry: ["id", "name", "code"] },
    { warehouse: ["id", "name", "code"] },
    { product: ["id", "name", "code"] },

    {
      image: ["path"],
    },
    { commodities: ["count ", "id", { commodity: ["id", "name", "code"] }] },
  ],
  variables: {
    input: {
      type: "RequestPackageInput",
      required: true,
    },
  },
});
export const REQUEST_PACKAGE_ID = gqlb.query({
  operation: "requestPackage",
  fields: VendorFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const CALCULATE_FEES_QUERY = gqlb.query({
  operation: "calculateRequestPackageFees",
  fields: [
    "totalWeight",
    "volume",
    "volumetricWeight",
    "chargeAmount",
    "chargeableUnits",
    "chargePrice",
  ],
  variables: {
    input: {
      type: "CalculateRequestPackageFeesInput",
      required: true,
    },
  },
});

export const AVAILABLE_PRICE_LIST = gqlb.query({
  operation: "availablePriceList",
  fields: [
    {
      currency: ["id", "name", "code"],
    },
  ],
  variables: {
    input: {
      type: "AvailablePriceListInput",
      required: true,
    },
  },
});

export const CURRENCY_ID = gqlb.query({
  operation: "currency",
  fields: [{ exchangeRate: ["updatable", "rate", "id"] }],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const DELETE_REQUEST_PACKAGE = gqlb.mutation({
  operation: "deleteRequestPackage",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const LIST_PACKAGE_DETAILS = gqlb.query({
  operation: "listPackageDetails",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "missing",
        "damaged",
        "barcode",
        "pieceNumber",
        { warehouse: ["id", "name", "code"] },
        {
          package: ["id", { request: ["detailsCount"] }],
        },
        "loaded",
        "unloaded",
        "editable",
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListPackageDetailsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});
export const DELETE_REQUEST = gqlb.mutation({
  operation: "deleteRequest",
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const CALCULATE_COMMISSION_AMOUNT = gqlb.query({
  operation: "calculateCommissionAmount",
  fields: ["value"],
  variables: {
    input: {
      type: "CalculateCommissionAmountInput",
      required: true,
    },
  },
});
export const EXPOERT_REQUESTS = gqlb.mutation({
  operation: "exportRequests",
  fields: [],
  variables: {
    input: {
      type: "ExportRequestsInput",
      required: true,
    },
  },
});
export const details = ({ type, operation }) => {
  const types = {
    description: {
      operation: `description: ${operation}`,
      fields: [...fields, ...commonViewForm],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
    transactions: {
      operation: "transactions: request",
      fields: [...transactionsFields],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
    packages: {
      operation: "packages: request",
      fields: [...packagesFields],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },

    invoices: {
      operation: "invoices: request",
      fields: [
        {
          invoices: [...BILL_Invoice, "invoiceDate"],
        },
      ],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
    bills: {
      operation: "bills: request",
      fields: [{ bills: [...BILL_Invoice, "billDate"] }],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
  };
  return types[type];
};
