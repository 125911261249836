import React, { useState, useEffect } from "react";
import { Paper, Stack, Box, Button, Typography, Collapse } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormButton from "../CustomComponents/Buttons/FormButton";
import MUIDate from "../HOC/MUI/MUIDate";
import moment from "moment";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import RecordsTable from "./RecordTable";
import {
  FINANCIAL_YEAR_ID,
  SAVE_FINANCIAL_YEAR,
  SAVE_FINANCIAL_YEAR_PERIOD,
} from "./Graphql";
import { gql, useMutation, useQuery } from "@apollo/client";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { dateFormat } from "../../helpers/dateFunctions";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { useSnackbar } from "notistack";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";

function FinancialYear(props) {
  const { enqueueSnackbar } = useSnackbar();
  const financialYearId = props.match?.params?.id?.trim();
  const addOneYear = (inputDateString) => {
    const newDate = new Date(inputDateString);
    newDate.setFullYear(newDate.getFullYear() + 1);

    // Subtract 1 day
    newDate.setDate(newDate.getDate() - 1);
    return newDate;
    // Update the state with the manipulated date
  };
  const { handleSubmit, control, errors, setValue, setError } = useForm({
    defaultValues: {},
  });
  useEffect(() => {
    if (!financialYearId) {
      setValue("active", true);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const initFromDate = moment(new Date())
    .locale("en")
    .subtract("month")
    .add("day")
    .format("YYYY-MM-DD");

  const [dateRange, setDateRange] = useState({
    startDate: initFromDate,
    endDate: addOneYear(initFromDate),
  });
  const { t } = useTranslation();

  const [recordIndex, setRecordIndex] = useState({
    index: 0,
    update: false,
  });
  const user = Globals.user;
  const canUpdate = user.hasPermission("cash.financial_year.update");
  const [recordsList, setRecordsList] = useState([]);
  const { data: financialYearData, loading } = useQuery(
    gql`
      ${FINANCIAL_YEAR_ID.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !financialYearId,
      variables: {
        id: parseInt(financialYearId),
      },
      onCompleted: (data) => {
        const financialYear = data.financialYear;

        const currencyParams = [
          "id",
          "code",
          "name",
          "active",
          "startDate",
          "endDate",
        ];
        currencyParams.forEach((i) => {
          financialYear[i] && setValue(i, financialYear[i]);
        });
        setDateRange((prev) => ({
          ...prev,
          startDate: financialYear?.startDate,
          endDate: financialYear?.endDate,
        }));

        setRecordsList(financialYear?.periods);
      },
    }
  );
  const [saveFinancialYear] = useMutation(
    gql`
      ${SAVE_FINANCIAL_YEAR.query}
    `
  );
  const [saveFinancialYearPeriod] = useMutation(
    gql`
      ${SAVE_FINANCIAL_YEAR_PERIOD.query}
    `
  );
  const [dialog, setDialog] = useState(false);
  const closeFormDialog = () => {
    setDialog(false);
    setRecordIndex((prev) => ({ ...prev, update: false }));
    recordsReset();
  };
  const addRecordDialog = (index) => {
    recordsReset();
    if (index || index === 0) {
      setRecordIndex({
        index,
        update: true,
      });
    } else {
      setRecordIndex({
        index,
        update: false,
      });
    }
    setDialog(true);
  };

  const onSubmitRecords = (data) => {
    const newRecord = {
      yearId: parseInt(financialYearId),
      name: data.name,
      startDate: dateFormat(data.startDate),
      endDate: dateFormat(data.endDate),
      code: data.code,
      active: data.active,
      ...(data.id && { id: data.id }),
    };
    const updateRecords = [...recordsList];

    if (recordIndex.update) {
      updateRecords[recordIndex.index] = {
        ...updateRecords[recordIndex.index],
        ...newRecord,
      };
      setRecordIndex({ index: recordIndex.index, update: false });
    } else {
      updateRecords.push(newRecord);
      setRecordIndex({
        index: 0,
        update: false,
      });
    }
    return saveFinancialYearPeriod({
      variables: {
        input: {
          ...newRecord,
        },
      },
    })
      .then((data) => {
        const recordObject = data.data.saveFinancialPeriod;
        const updateRecords = [...recordsList];

        if (recordIndex.update) {
          updateRecords[recordIndex.index] = {
            ...updateRecords[recordIndex.index],
            ...recordObject,
            yearId: parseInt(financialYearId),
          };
          setRecordIndex({ index: recordIndex.index, update: false });
        } else {
          updateRecords.push(recordObject);
          setRecordIndex({
            index: 0,
            update: false,
          });
        }
        setRecordsList(updateRecords);

        closeFormDialog();
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };
  const {
    control: recordsControl,
    formState: { errors: recordsErrors },
    handleSubmit: recordsHandleSubmit,
    setValue: recordsSetValue,
    watch: recordsWatch,
    reset: recordsReset,
    setError: setPeriodsError,
  } = useForm({
    defaultValues: {
      active: true,
      code: "",
      name: "",
      startDate: "",
      endDate: "",
    },
  });
  const recordForm = (
    <RecordsForm
      handleSubmit={recordsHandleSubmit}
      onSubmitRecord={onSubmitRecords}
      control={recordsControl}
      errors={recordsErrors}
      setValue={recordsSetValue}
      watch={recordsWatch}
      recordIndex={recordIndex}
      recordList={recordsList}
      closeFormDialog={closeFormDialog}
      onDateRange={dateRange}
      setPeriodsError={setPeriodsError}
      dialog={dialog}
    />
  );
  const onSubmit = (data) => {
    data["startDate"] = dateFormat(data["startDate"]);
    data["endDate"] = "";
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }
    return saveFinancialYear({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then((data) => {
        if (canUpdate) {
          pushUrl(
            props,
            `/admin/finance/financial-year/${data.data.saveFinancialYear.id}/edit`
          );
        } else {
          pushUrl(props, `/admin/finance/financial-year`);
        }
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };
  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction={"column"} p={2}>
        <Stack>
          <Paper
            container
            component={Grid}
            className={classesForm.spacing}
            spacing={2}
          >
            <Grid
              justifyContent="end"
              xs={12}
              sx={{ display: "flex", flexWrap: "wrap" }}
            >
              <Box>
                <MuiSwitch
                  edge="end"
                  name="active"
                  label={t("active")}
                  control={control}
                />
              </Box>
            </Grid>
            <Grid xs={12} md={6} aligns="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"code"}
                disabled={Boolean(financialYearId)}
                label={t("code")}
              />
            </Grid>
            <Grid xs={12} md={6} aligns="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"name"}
                label={t("name")}
                rules={{ required: t("fieldIsRequired") }}
              />
            </Grid>
            <Grid xs={12} md={6} aligns="flex-start">
              <MUIDate
                name={"startDate"}
                label={t("startDate")}
                control={control}
                disabled={Boolean(financialYearId)}
                value={dateRange.startDate}
                defaultValue={dateRange.startDate}
                onChange={(e) => {
                  setDateRange((prev) => ({
                    ...prev,
                    startDate: e,
                    endDate: addOneYear(e),
                  }));
                }}
              />
            </Grid>
            <Grid xs={12} md={6} aligns="flex-start">
              <MUIDate
                name={"endDate"}
                label={t("endDate")}
                control={control}
                value={dateRange.endDate}
                defaultValue={dateRange.endDate}
                disabled
              />
            </Grid>
            <Grid
              xs={12}
              container
              className={classesForm.spacing}
              justifyContent="flex-end"
            >
              <FormButton>{t("save")}</FormButton>
            </Grid>
          </Paper>
        </Stack>

        <Paper>
          <RecordsTable
            yearId={financialYearId}
            records={recordsList}
            addRecord={addRecordDialog}
            setRecordsList={setRecordsList}
          />
        </Paper>
      </Stack>
    </form>
  );
  return (
    <RootStyleForm>
      {dialog && recordForm}
      {loading ? (
        <StyledLoading
          container
          item
          justifyContent="center"
          className={classesLoad.main}
        >
          <FullScreenLoading height={"100%"} />
        </StyledLoading>
      ) : !financialYearData && financialYearId ? (
        <NotFound />
      ) : (
        <>
          <TitleAppBar path={props.match.path} />
          {body}
        </>
      )}
    </RootStyleForm>
  );
}

export default FinancialYear;
function RecordsForm({
  handleSubmit,
  onSubmitRecord,
  control,
  errors,
  recordIndex,
  recordList,
  setValue,
  closeFormDialog,
  onDateRange,
  dialog,
}) {
  const { t } = useTranslation(["translation", "validation"]);

  const addOneDay = (inputDateString) => {
    let startDate = new Date(inputDateString);

    // Add one day to the startDate
    return startDate.setDate(startDate.getDate() + 1);
  };
  const [updateFiled, setUpdateFiled] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    if (recordIndex.update) {
      const update = recordList[recordIndex.index];
      setValue("id", update?.id);
      setValue("active", update?.active);
      setValue("code", update?.code);
      setValue("name", update?.name);
      setValue("endDate", update?.endDate);
      setValue("startDate", update?.startDate);
      setUpdateFiled(true);
      setDateRange((prev) => ({
        ...prev,
        startDate: update?.startDate,
        endDate: update?.endDate,
      }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!recordIndex.update) {
      setDateRange((prev) => ({
        startDate: onDateRange?.startDate,
        endDate: addOneDay(onDateRange?.startDate),
      }));
      setValue("endDate", addOneDay(onDateRange?.startDate));
      setValue("startDate", onDateRange?.startDate);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const body = (
    <Stack direction={"column"} spacing={2} className={classesForm.margin}>
      <Grid container spacing={2}>
        <Grid xs={12} aligns="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"code"}
            label={t("code")}
            disabled={updateFiled}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>

        <Grid xs={12} aligns="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"name"}
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid xs={12} aligns="flex-start">
          <MUIDate
            name={"startDate"}
            label={t("startDate")}
            control={control}
            value={dateRange.startDate}
            defaultValue={dateRange.startDate}
            onChange={(e) => {
              setDateRange((prev) => ({
                ...prev,
                startDate: e,
                endDate: addOneDay(e),
              }));
              setValue("endDate", addOneDay(e));
            }}
            disabled={updateFiled}
          />
        </Grid>
        <Grid xs={12} aligns="flex-start">
          <MUIDate
            name={"endDate"}
            label={t("endDate")}
            control={control}
            value={dateRange.endDate}
            defaultValue={dateRange.endDate}
            minDate={addOneDay(dateRange.startDate)}
            onChange={(e) => setDateRange((prev) => ({ ...prev, endDate: e }))}
            disabled={updateFiled}
          />
        </Grid>
      </Grid>
    </Stack>
  );
  const title = (
    <Grid container justifyContent="space-between" xs={12}>
      <Typography variant="h6" color={"text.primary"}>
        {t("periodCreate")}
      </Typography>
      <Box>
        <MuiSwitch
          edge="end"
          name="active"
          label={t("active")}
          control={control}
        />
      </Box>
    </Grid>
  );
  return (
    <CustomDialog
      fullWidth
      maxWidth="xs"
      open={dialog}
      onClose={closeFormDialog}
      title={title}
      content={body}
      actions={
        <>
          <Button onClick={closeFormDialog}>{t("cancel")}</Button>
          <Button onClick={handleSubmit(onSubmitRecord)}>{t("confirm")}</Button>
        </>
      }
    ></CustomDialog>
  );
}
