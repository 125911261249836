import * as gqlb from "gql-query-builder";
const fields = [
  "id",
  "code",
  "name",
  "active",
  "length",
  "breadth",
  "height",
  "weight",
  "volume",
  "isRoro",
  "isContainer",
  "import",
  "export",
  "air",
  "land",
  "ocean",
];
export const LIST_PACKAGE_TYPE = gqlb.query({
  operation: "listPackageTypes",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: fields,
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListPackageTypesFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const PACKAGE_TYPE_ID = gqlb.query({
  operation: "packageType",
  fields: [...fields, "deletable", "date"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_PACKAGE_TYPE = gqlb.mutation({
  operation: "savePackageType",
  fields: ["id", "code", "name", "active"],
  variables: {
    input: {
      type: "PackageTypeInput",
      required: true,
    },
  },
});

export const DELETE_PACKAGE_TYPE = gqlb.mutation({
  operation: "deletePackageType",
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
