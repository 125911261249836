import React, { useState } from "react";
import {
  Paper,
  Collapse,
  Stack,
  FormControlLabel,
  Switch,
  Typography,
  Box,
} from "@mui/material";
import { INCO_TERM_ID, SAVE_INCO_TERM } from "./Graphql";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import FormButton from "../CustomComponents/Buttons/FormButton";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import FlightIcon from "@mui/icons-material/Flight";
import DirectionsBoatFilledOutlinedIcon from "@mui/icons-material/DirectionsBoatFilledOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import { LuContainer } from "react-icons/lu";
import { TbPackageExport } from "react-icons/tb";
import { TbPackageImport } from "react-icons/tb";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";

const IncoTermForm = (props) => {
  const [pathURL] = useState(props.match.path);
  const [active, setActive] = useState(true);
  // const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(parseInt(props.match.params.id));
  const { handleSubmit, control, formState, setValue, setError } = useForm();
  const { errors } = formState;

  const [saveIncoTerm, { loading: saveIncoTermLoading }] = useMutation(
    gql`
      ${SAVE_INCO_TERM.query}
    `
  );

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActive(active);
  };

  const { data: updateIncoTerm, loading: updateIncoTermLoading } = useQuery(
    gql`
      ${INCO_TERM_ID.query}
    `,

    {
      skip: !id,
      variables: { id: id },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const incoData = data.incoTerm;
        setActive(incoData.active);
        const incoParams = [
          "id",
          "code",
          "name",
          "shortName",
          "description",
          "active",
          "import",
          "export",
          "air",
          "land",
          "ocean",
          "cc",
          "cp",
          "pp",
        ];
        incoParams.forEach((i) => {
          setValue(i, incoData[i]);
        });
      },
    }
  );

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "" && key === "code") {
        delete data[key];
      }
      if (data[key] === "") {
        data[key] = null;
        continue;
      }
    }

    saveIncoTerm({
      variables: {
        input: {
          ...data,
          active: active,
          ...(id && { id: id }),
        },
      },
    })
      .then((data) => {
        setId(data?.data?.saveIncoTerm?.id);
        pushUrl(props, `/admin/inco-terms/${data?.data?.saveIncoTerm?.id}`);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid
          justifyContent="end"
          sx={{ display: "flex", flexWrap: "wrap" }}
          xs={12}
        >
          <FormControlLabel
            checked={active}
            control={<Switch color="primary" />}
            label={t("active")}
            labelPlacement="start"
            onChange={handelActivate}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"code"}
            label={t("code")}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"name"}
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"shortName"}
            label={t("shortName")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"description"}
            label={t("description")}
          />
        </Grid>
      </Paper>
      <Grid container spacing={1}>
        <Grid item sm={4} xs={12}>
          <Paper
            className={clsx(classesForm.spacing)}
            sx={{ p: 1, height: "10rem" }}
          >
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <LuContainer size={"20px"} />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("cc")}
                  </Typography>
                </Box>

                <MuiSwitch edge="end" name="cc" control={control} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <LuContainer size={"20px"} />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("cp")}
                  </Typography>
                </Box>

                <MuiSwitch edge="end" name="cp" control={control} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <LuContainer size={"20px"} />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("pp")}
                  </Typography>
                </Box>

                <MuiSwitch edge="end" name="pp" control={control} />
              </Stack>
            </Grid>
          </Paper>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Paper
            className={clsx(classesForm.spacing)}
            sx={{ p: 1, height: "10rem" }}
          >
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <DirectionsBoatFilledOutlinedIcon />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("ocean")}
                  </Typography>
                </Box>
                <MuiSwitch edge="end" name="ocean" control={control} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <LocalShippingOutlinedIcon />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("land")}
                  </Typography>
                </Box>

                <MuiSwitch edge="end" name="land" control={control} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <FlightIcon />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("air")}
                  </Typography>
                </Box>

                <MuiSwitch edge="end" name="air" control={control} />
              </Stack>
            </Grid>
          </Paper>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Paper
            className={clsx(classesForm.spacing)}
            sx={{ p: 1, height: "10rem" }}
          >
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <TbPackageExport size={"20px"} />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("export")}
                  </Typography>
                </Box>

                <MuiSwitch edge="end" name="export" control={control} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <TbPackageImport size={"20px"} />
                  <Typography variant="body2" color={"text.primary"}>
                    {t("import")}
                  </Typography>
                </Box>

                <MuiSwitch edge="end" name="import" control={control} />
              </Stack>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" className={classesForm.spacing}>
        <FormButton disabled={saveIncoTermLoading}>
          {saveIncoTermLoading ? <ButtonLoading /> : t("save")}
        </FormButton>
      </Grid>
    </form>
  );
  let DOM;

  if (id) {
    DOM = updateIncoTerm ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return updateIncoTermLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !updateIncoTerm && id ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={pathURL} />
      <Stack spacing={2} p={2}>
        {DOM}
      </Stack>
    </RootStyleForm>
  );
};

export default IncoTermForm;
