import React, { Fragment, useEffect } from "react";
import clsx from "clsx";
import { Paper, Collapse, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { SAVE_WARE_HOUSE, WARE_HOUSE_ID } from "./Graphql";
import { useMutation, gql, useQuery } from "@apollo/client";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useState } from "react";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { LIST_BRANCHES_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";
const WareHouseForm = (props) => {
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
  });

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control, formState, setValue, setError } = useForm();
  const { errors } = formState;
  const warehouseId = parseInt(props.match.params.id);

  const selectDefaultValue = (data) => {
    return data;
  };
  useEffect(() => {
    if (!warehouseId) {
      setValue("active", true);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: updateWareHouse, loading: updateWareHouseLoading } = useQuery(
    gql`
      ${WARE_HOUSE_ID.query}
    `,
    {
      skip: !warehouseId,
      notifyOnNetworkStatusChange: true,
      variables: { id: warehouseId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const wareHouseData = data.warehouse;
        const wareHouseParams = ["id", "name", "code"];
        wareHouseParams.forEach((i) => {
          wareHouseData[i] && setValue(i, wareHouseData[i]);
        });
        setValue("active", wareHouseData["active"]);

        setAutocompleteValues({
          branch: wareHouseData?.branch,
        });
      },
    }
  );

  const [saveWareHouse, { loading: saveWareHouseLoading }] = useMutation(
    gql`
      ${SAVE_WARE_HOUSE.query}
    `
  );

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "" && key === "code") {
        delete data[key];
      }
      if (data[key] === "") {
        data[key] = null;
      }
    }
    saveWareHouse({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then((data) => {
        pushUrl(props, `/admin/warehouse/${data?.data?.saveWarehouse?.id}`);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };

  const body = (
    <>
      <TitleAppBar path={props.match.path} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          justifyContent="flex-start"
          aligns="center"
          className={clsx(classesForm.mainGrid)}
          spacing={2}
        >
          <Paper
            container
            component={Grid}
            className={clsx(classesForm.spacing)}
          >
            <Grid container justifyContent="end" xs={12}>
              {/* <Typography variant="h6">{t("wareHouse")}</Typography> */}
              <Box>
                <MuiSwitch
                  edge="end"
                  name="active"
                  label={t("active")}
                  control={control}
                />
              </Box>
            </Grid>

            <Grid xs={12} sm={4} aligns="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"code"}
                label={t("code")}
              />
            </Grid>
            <Grid xs={12} sm={4} aligns="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"name"}
                label={t("name")}
                rules={{ required: t("fieldIsRequired") }}
              />
            </Grid>

            <Grid xs={12} sm={4} aligns="flex-start">
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"branchId"}
                label={t("branch")}
                parseData={(data) => selectDefaultValue(data)}
                query={LIST_BRANCHES_DROPDOWN.query}
                defaultValue={autocompleteValues.branch}
                rules={{ required: t("fieldIsRequired") }}
              />
            </Grid>
          </Paper>
          <Grid
            container
            justifyContent="flex-end"
            className={classesForm.formButton}
          >
            <FormButton disabled={saveWareHouseLoading}>
              {saveWareHouseLoading ? <ButtonLoading /> : t("save")}
            </FormButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
  return updateWareHouseLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !updateWareHouse && warehouseId ? (
    <NotFound />
  ) : (
    <RootStyleForm>{body}</RootStyleForm>
  );
};

export default WareHouseForm;
