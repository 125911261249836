import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button, Collapse, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { SAVE_CUSTOMER_PRICE_MODE } from "./Graphql";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { LIST_LOOKUP_ENTRIES_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { useCustomersContext } from "./Context/CustomersContext";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";

export default function PriceModesForm(props) {
  const {
    autocompleteValues,
    customerId,
    updatePriceModes,
    priceModes,
    setPriceModes,
    closeDialog,
    setAutocompleteValues,
    dialogState,
  } = useCustomersContext();
  const { handleSubmit, control, setError, errors } = useForm();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [savePriceModes, { loading: savePriceModesLoading }] = useMutation(
    gql`
      ${SAVE_CUSTOMER_PRICE_MODE.query}
    `
  );

  useEffect(() => {
    if (updatePriceModes) {
      setAutocompleteValues((prev) => ({
        ...prev,
        priceMode: updatePriceModes?.priceMode,
        shippingMode: updatePriceModes?.shippingMode,
      }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const parseData = (data) => {
    return data;
  };
  const onSubmit = (data, customerId, priceModesId) => {
    for (const key in data) {
      if (data[key] === "" || data[key] === null) {
        delete data[key];
      }
    }
    savePriceModes({
      variables: {
        input: {
          ...(customerId && { customerId: customerId }),
          ...(priceModesId && { id: priceModesId }),
          ...data,
        },
      },
    })
      .then((data) => {
        const checksubZone = priceModes.map(
          (ele) => ele.id === data.data.saveCustomerPriceMode.id
        );
        const check = checksubZone.includes(true);
        if (check) {
          const index = priceModes.findIndex(
            (x) => x.id === data.data.saveCustomerPriceMode.id
          );
          priceModes[index] = data.data.saveCustomerPriceMode;
          setPriceModes(priceModes);
        } else {
          priceModes.push(data.data.saveCustomerPriceMode);
          setPriceModes(priceModes);
        }
        closeDialog();
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });

        console.log(graphQLErrors);
      });
  };

  const body = (
    <Grid
      container
      justifyContent="space-between"
      spacing={2}
      alignItems={"center"}
    >
      <Grid xs={12}>
        <CustomAutocomplete
          valueKey="code"
          control={control}
          hideCode={true}
          errors={errors}
          rules={{ required: t("fieldIsRequired") }}
          name={"priceMode"}
          label={t("priceModes")}
          parseData={(data) => parseData(data)}
          variables={{ input: { code: "FR_PRICE_MODE" } }}
          query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
          defaultValue={autocompleteValues.priceMode}
        />
      </Grid>
      <Grid xs={12}>
        <CustomAutocomplete
          valueKey="code"
          control={control}
          hideCode={true}
          errors={errors}
          rules={{ required: t("fieldIsRequired") }}
          name={"shippingMode"}
          label={t("shippingMode")}
          parseData={(data) => parseData(data)}
          variables={{ input: { code: "FR_SHIPPING_MODE" } }}
          query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
          defaultValue={autocompleteValues?.shippingMode}
        />
      </Grid>
    </Grid>
  );
  const title = (
    <Grid
      container
      justifyContent="space-between"
      xs={12}
      alignItems={"center"}
    >
      <Typography variant="h6" color={"text.primary"}>
        {t("priceModes")}
      </Typography>
    </Grid>
  );
  return (
    <CustomDialog
      fullWidth
      maxWidth="xs"
      open={dialogState.priceModes}
      onClose={closeDialog}
      title={title}
      content={body}
      actions={
        <>
          <Button onClick={closeDialog}>{t("cancel")}</Button>
          <Button
            onClick={handleSubmit((data) =>
              onSubmit(data, customerId, updatePriceModes?.id)
            )}
          >
            {savePriceModesLoading ? <ButtonLoading /> : t("confirm")}
          </Button>
        </>
      }
    ></CustomDialog>
  );
}
