import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";

const ProductsTable = (props) => {
  const {
    page,
    rowsPerPage,
    headCells,
    data,
    parseBodyCell,
    classes,
    checkAll,
    total,

    ...restProps
  } = props;
  const { t } = useTranslation();
  const tableData =
    (rowsPerPage
      ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : data) ?? [];

  return (
    <Table
      {...restProps}
      aria-labelledby="tableTitle"
      aria-label="enhanced table"
    >
      <TableHead className={classes?.tableHead}>
        <TableRow>
          {checkAll && checkAll}
          {headCells.map((headCell, index) => {
            return headCell !== null ? (
              <FixedTableCell
                className={classes?.[`productTable_${headCell}`]}
                key={index}
              >
                {t(headCell)}
              </FixedTableCell>
            ) : null;
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableData.map((item, index) => parseBodyCell(item, index))}
        {total && total}
      </TableBody>
    </Table>
  );
};
ProductsTable.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  headCells: PropTypes.array.isRequired,
  data: PropTypes.array,
  parseBodyCell: PropTypes.func.isRequired,
  classes: PropTypes.any,
};
export default ProductsTable;
