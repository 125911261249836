import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { IconButton, Tooltip, TableBody, TableRow, Icon } from "@mui/material";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_ROLES } from "./Graphql";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import { RootStyleList } from "../../GlobalStyles/ListStyle";
const ListRoles = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const { data, loading } = useQuery(
    gql`
      ${LIST_ROLES.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
      },
      onCompleted: (data) => {},
    }
  );
  const listRoles =
    data?.listRoles?.data !== null ? data?.listRoles?.data : null;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const icons = [
    {
      id: "add",
      action: () => pushUrl(props, `/admin/roles/create`),
      permission: "core.role.create",
    },
  ];

  return (
    <RootStyleList>
      <ListWrapper
        drawerState={false}
        icons={icons}
        path={props.match.path}
        empty={listRoles?.length === 0}
        loading={loading}
        filters={false}
        tableHeaders={[
          {
            name: "name",
          },
          {
            name: "code",
          },
          {
            name: "users",
          },
          {
            name: "",
          },
        ]}
        tableBody={
          <TableBody>
            {listRoles &&
              listRoles?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <CellLink pathname={`/admin/roles/${row?.id}`}>
                      {row?.name}
                    </CellLink>
                    <FixedTableCell>{row?.code}</FixedTableCell>
                    <FixedTableCell>{row?.usersCount}</FixedTableCell>
                    <FixedTableCell>
                      <SecuredNavLink
                        permission="core.role.create"
                        to={{
                          pathname: `roles/${row.id}/copy`,
                        }}
                      >
                        <Tooltip title="copy role">
                          <IconButton aria-label="copy role" size="large">
                            <Icon>content_copy</Icon>
                          </IconButton>
                        </Tooltip>
                      </SecuredNavLink>
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listRoles?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listRoles ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </RootStyleList>
  );
};

export default ListRoles;
