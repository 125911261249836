/* eslint-disable no-useless-concat */
import React, { Fragment, useState } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  FormControlLabel,
  Icon,
  Paper,
  Radio,
  RadioGroup,
  useTheme,
} from "@mui/material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import {
  DELETE_OPERATION,
  LIST_MANIFESTS,
  LIST_PACKAGE_DETAILS,
  OPERATION_VIEW_ID,
} from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import Tabs from "../HOC/CustomComponents/PackagesTableView/Tabs";
import { TabsProvider } from "../HOC/CustomComponents/PackagesTableView/TabContext";
import ContainersTab from "./Manifests/ContainersTab";

import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";

const OperationView = (props) => {
  const { t } = useTranslation();
  const id = props.match.params.id?.trim();
  const user = Globals.user;
  const [listType, setListType] = useState();
  const [manifests, setManifests] = useState();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [pickedContainers, setPickedContainers] = React.useState();
  const [containerLoading, setContainerLoading] = React.useState(false);
  const [container, setContainer] = React.useState();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const { loading, data } = useQuery(
    gql`
      ${OPERATION_VIEW_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(id) },
      onCompleted: (data) => {
        setListType(data?.operation?.type?.code);
        // setManifests(data?.operation?.manifests);
        setPickedContainers(data.operation?.shipment?.containers);
        setContainerLoading(true);
        data.operation?.type?.code !== "RCVD" && handleManifest();
      },
    }
  );
  const operationData = data?.operation;
  const [getPackageDetailsData] = useLazyQuery(
    gql`
      ${LIST_PACKAGE_DETAILS.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data?.listPackageDetails?.data?.length) {
          data.listPackageDetails.data.forEach((reqPackage) => {
            const index = operationData?.request?.packages?.findIndex(
              (x) => x.id === reqPackage.package?.id
            );
            if (index !== -1) {
              operationData.request.packages[index].details =
                data?.listPackageDetails;
            }
          });
        } else {
          console.log("No package details available");
        }
      },
    }
  );

  const handleFetchPackageDetails = (
    operationPackageId,
    pageDetails,
    rowsPerPageDetails
  ) => {
    getPackageDetailsData({
      variables: {
        page: pageDetails + 1,
        first: rowsPerPageDetails,
        input: {
          requestPackageId: parseInt(operationPackageId),
        },
      },
    });
  };
  const [getManifestData] = useLazyQuery(
    gql`
      ${LIST_MANIFESTS.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setManifests(data?.listManifests);
        setContainerLoading(false);
      },
    }
  );
  const handleManifest = (containerId, pageDetails, rowsPerPageDetails) => {
    getManifestData({
      variables: {
        page: pageDetails ?? page + 1,
        first: rowsPerPageDetails ?? rowsPerPage,
        input: {
          operationId: parseInt(id),
          ...(containerId && { containerId: parseInt(containerId) }),
        },
      },
    });
  };
  function getManifest(id, pageDetails, rowsPerPageDetails) {
    if (id === "all") {
      setContainerLoading(true);
      handleManifest(null, pageDetails, rowsPerPageDetails);
      setContainer(null);
    } else {
      setContainerLoading(true);
      handleManifest(id, pageDetails, rowsPerPageDetails);
      setContainer(id);
    }
  }
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const theme = useTheme();
  const [state, setState] = React.useState({
    barcodeDarwerState: {
      left: false,
      bottom: false,
      right: false,
    },
    containers: {
      left: false,
      bottom: false,
      right: false,
    },
  });
  const value = {
    id: operationData?.request?.id,
    packages: operationData?.request?.packages,
    handleFetchPackageDetails,
    sales: [],
    tabsLoading: loading,
    manifests,
    container,
    handleManifest,
    pickedContainers,
    containerLoading,
    view: true,
    state,
    setState,
    theme,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    getManifest,
    type: listType?.toUpperCase(),
    operationId: parseInt(id),
  };

  const canEdit =
    !operationData?.approved &&
    user.hasPermission(
      `freight.operation_${operationData?.type?.code.toLowerCase()}.update`
    );
  const canDelete =
    user.hasPermission(
      `freight.operation_${operationData?.type?.code.toLowerCase()}.delete`
    ) && operationData?.deletable;
  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/operations/${id}/edit`),
      permission: Boolean(canEdit),
    },
    {
      id: "delete",
      action: handleOpenDialog,
      permission: Boolean(canDelete),
    },
    {
      id: "print",
      action: () =>
        window.open(
          `${
            window.location.origin
          }/report/print/operation/${id}/${listType.toLowerCase()}`
        ),
      disabled: loading || !id,
    },
  ];

  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !operationData ? (
    <NotFound />
  ) : (
    <>
      <MutationWithDialog
        mutaion={DELETE_OPERATION.query}
        setOpenDelete={setOpenDialog}
        openDelete={openDialog}
        dialogTitle={t("deleteRecord")}
        dialogContent={t("deleteRecordMessage")}
        mutaionProps={{
          variables: { id: parseInt(id) },
        }}
        onCompleted={() =>
          pushUrl(
            props,
            `/admin/operations/${operationData?.type?.code.toLowerCase()}`
          )
        }
        onCompleteMessage={t("successfullyDeletedRecord")}
      />{" "}
      <Fragment>
        <TitleAppBar
          path={props.match.path}
          type={operationData?.type?.code.toLowerCase()}
        >
          <LongMenu icons={icons} />
        </TitleAppBar>
      </Fragment>
      <RootStyleView spacing={2} p={2} justifyContent="center">
        <TabsProvider value={value}>
          <Paper container className={classesView.paper} component={Grid}>
            <KeyValuePair title={t("code")} value={operationData?.code} />
            <KeyValuePair
              title={t("date")}
              value={operationData?.operationDate}
            />
            <KeyValuePair
              title={t("branch")}
              value={
                <SpanLink
                  pathname={`/admin/branches/${operationData?.branch?.id}`}
                >
                  {operationData?.branch?.name}
                </SpanLink>
              }
            />

            <KeyValuePair
              title={t("transactionType")}
              value={
                <SpanLink
                  pathname={`/admin/transaction-types/${operationData?.transactionType?.id}`}
                >
                  {operationData?.transactionType?.name}
                </SpanLink>
              }
            />
            <KeyValuePair
              title={t("wareHouse")}
              value={
                <SpanLink
                  pathname={`/admin/warehouse/${operationData?.warehouse?.id}`}
                >
                  {operationData?.warehouse?.name}
                </SpanLink>
              }
            />
            {operationData?.request ? (
              <KeyValuePair
                title={t("requestCode")}
                value={
                  <SpanLink
                    pathname={`/admin/requests/${operationData?.request?.id}`}
                  >
                    {operationData?.request?.code}
                  </SpanLink>
                }
              />
            ) : (
              <KeyValuePair
                title={t("enterShipmentCode")}
                value={
                  <SpanLink
                    pathname={`/admin/shipments/${operationData?.shipment?.id}`}
                  >
                    {operationData?.shipment?.code}
                  </SpanLink>
                }
              />
            )}

            <KeyValuePair
              title={t("approved")}
              value={
                operationData?.approved ? (
                  <Icon className={classesView.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
          </Paper>
          {pickedContainers?.length > 0 && (
            <Paper component={Grid} xs={12} p={1}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="all" // Set the default selected value to "all"
                name="radio-buttons-group"
                row
                sx={{ display: "flex", justifyContent: "space-around" }}
              >
                {/* Static radio button with a predefined value */}
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="All"
                  onClick={() => getManifest("all")}
                />

                {/* Dynamically generated radio buttons from pickedContainers */}
                {pickedContainers?.map((i) => (
                  <FormControlLabel
                    onClick={() => {
                      getManifest(i?.id, 1, 20);
                      setPage(0);
                      setRowsPerPage(20);
                    }}
                    key={i?.id}
                    value={i.id}
                    control={<Radio />}
                    label={`\u202A${i.packageType?.name} (${i.containerNumber})`}
                  />
                ))}
              </RadioGroup>
            </Paper>
          )}
          {listType.toLowerCase() === "rcvd" ? <Tabs /> : <ContainersTab />}
        </TabsProvider>
      </RootStyleView>
    </>
  );
};

export default OperationView;
