import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  SwipeableDrawer,
  IconButton,
  Icon,
} from "@mui/material";
import * as colors from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { useTabsContext } from "../../HOC/CustomComponents/PackagesTableView/TabContext";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import BarcodeSelector from "./BarcodeSelector";
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";
import FullScreenLoading from "../../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import MUITablePagination from "../../HOC/MUI/TablePagination/MUITablePagination";
import PackageDetailsList from "./PackageDetailsList";
import { Delete } from "@mui/icons-material";
import { gql, useMutation } from "@apollo/client";
import { DELETE_MANIFEST } from "../Graphql";
import { StyledFixedTableCell } from "../../HOC/CustomComponents/TableCellColor";
import CellLink from "../../HOC/CustomComponents/CellLink";
const PREFIX = "CustomerTables";

const classes = {
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  tableContainerTabes: `${PREFIX}-tableContainerTabes`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  focus: `${PREFIX}-focus`,
  tableRow: `${PREFIX}-tableRow`,
  packageType: `${PREFIX}-packageType`,
  iconColor: `${PREFIX}-iconColor`,
  barcode: `${PREFIX}-barcode`,
  addIcon: `${PREFIX}-addIcon`,
  toolbars: `${PREFIX}-toolbars`,
  shipmentList: `${PREFIX}-shipmentList`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.tableContainer}`]: {
    minHeight: "250px",
  },
  [`& .${classes.tableContainerTabes}`]: {
    minHeight: "205px",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },
  [`& .${classes.shipmentList}`]: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  [`& .${classes.toolbar}`]: {
    // width: "100%",
    // backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid" + theme.palette.background.paper,
    // borderRight: "1px solid " + theme.palette.divider,
  },
  [`& .${classes.toolbars}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.focus}`]: {
    backgroundColor: theme.palette.action.hover,
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      cursor: "pointer",
    },
  },

  [`& .${classes.packageType}`]: {
    // marginTop: theme.spacing(1),
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 30,
  },
  [`& .${classes.addIcon}`]: {
    color: theme.palette.success.main,
  },
}));
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.appTitle,
}));

export default function ContainersTab(props) {
  const {
    tabsLoading,
    anchor,
    theme,
    state,
    setState,
    manifests,
    pickedContainers,
    handleManifest,
    containerLoading,
    container,
    view,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    getManifest,
    type,
  } = useTabsContext();
  const { t } = useTranslation();

  //////////// package Functions ////////////

  const [requestPackageDetails, setRequestPackageDetails] = useState({
    requestId: null,
  });
  const handlePage = (event, newPage) => {
    setPage(newPage);
    getManifest(container, newPage + 1);
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    getManifest(container, 1, parseInt(event.target.value));
  };

  const [deleteManifest, { loading: deleteLoad }] = useMutation(
    gql`
      ${DELETE_MANIFEST.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const shipmentDrawerHandler = (anchor, open) => {
    setState((prev) => ({
      ...prev,
      containers: { ...prev.containers, [anchor]: open },
    }));
  };
  const barcodeDrawerHandler = (anchor, open) => {
    setState((prev) => ({
      ...prev,
      barcodeDarwerState: { ...prev.barcodeDarwerState, [anchor]: open },
    }));
  };
  const colorLoad = (load) => {
    if (load) {
      return colors["green"]["900"];
    } else return colors["orange"]["900"];
  };
  const handleDelete = (id) => {
    // console.log(container);

    deleteManifest({
      variables: {
        id: parseInt(id),
      },
    })
      .then((data) => {
        handleManifest(container);
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
      });
  };
  // console.log(requestPackageDetails);

  const containersTab = (
    <Grid
      item
      sm={12}
      md={12}
      className={classes.packageType}
      component={Paper}
    >
      {/* {state?.containers[anchor] && ( */}
      <SwipeableDrawer
        variant="persistent"
        disableDiscovery={true}
        disableSwipeToOpen={true}
        anchor={anchor}
        open={state?.containers[anchor] ?? false}
        onClose={() => false}
        onOpen={() => shipmentDrawerHandler(anchor, true)}
        classes={{ paper: classes.shipmentList }}
        dir={theme.direction}
      >
        {state?.containers &&
          state?.containers[anchor] &&
          (requestPackageDetails?.requestId ? (
            <PackageDetailsList
              done={() => shipmentDrawerHandler(anchor, false)}
              requestPackageDetails={requestPackageDetails}
            />
          ) : (
            <PackageDetailsList
              done={() => shipmentDrawerHandler(anchor, false)}
            />
          ))}
      </SwipeableDrawer>
      {/* )} */}
      {/* {state?.barcodeDarwerState[anchor] && ( */}
      <SwipeableDrawer
        disableDiscovery={true}
        disableSwipeToOpen={true}
        anchor={anchor}
        open={state?.barcodeDarwerState[anchor] ?? false}
        onClose={() => false}
        onOpen={() => barcodeDrawerHandler(anchor, true)}
        sx={{
          width: "auto",
          "& .MuiDrawer-paper": {
            width: { md: "50%" },

            height: "100%",
            overflow: "hidden",
          },
        }}
        dir={theme?.direction}
      >
        {state?.barcodeDarwerState && state?.barcodeDarwerState[anchor] && (
          <BarcodeSelector
            // refetchShipmentDataQuery={refetchShipmentDataQuery}
            // type={manifestType}
            // selected={manifestShipments}
            type={type}
            done={() => barcodeDrawerHandler(anchor, false)}
          />
        )}
      </SwipeableDrawer>
      {/* )} */}

      <EnhancedTableToolbar
        {...props}
        classes={classes}
        view={view || pickedContainers?.length === 0 || !container}
        addShipment={() => {
          shipmentDrawerHandler(anchor, true);
          setRequestPackageDetails({
            requestId: null,
          });
        }}
        type={type}
        numSelected={manifests?.paginatorInfo?.total}
        barcode={() => barcodeDrawerHandler(anchor, true)}
        containerLoading={containerLoading}
      />
      <Grid container className={classes.table}>
        <TableFixedHeaderWraper>
          {tabsLoading || containerLoading ? (
            <Paper>
              <FullScreenLoading minHeight={"100px"} />
            </Paper>
          ) : (
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <StyledTableRow>
                    <FixedTableCell className={classes.headerTable}>
                      {t("containerNumber")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("pieceNumber")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("requestCode")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("packageType")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("product")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("weight")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("fragile")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("sender")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("originCountry")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("recipient")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.headerTable}>
                      {t("destinationCountry")}
                    </FixedTableCell>
                    {!view && (
                      <FixedTableCell className={classes.headerTable}>
                        {" "}
                      </FixedTableCell>
                    )}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {manifests?.data &&
                    manifests?.data?.map((row, index) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <FixedTableCell>
                          {row?.container?.containerNumber}
                        </FixedTableCell>
                        <FixedTableCell>
                          {`${row?.packageDetail?.pieceNumber}/${row?.packageDetail?.package?.request?.detailsCount}`}
                        </FixedTableCell>
                        <FixedTableCell>
                          <StyledFixedTableCell
                            onClick={() => {
                              setRequestPackageDetails({
                                requestId:
                                  row?.packageDetail?.package?.request?.id,
                              });
                              shipmentDrawerHandler(anchor, true);
                            }}
                            colorcode={colorLoad(
                              type?.toUpperCase() === "UNLOADED"
                                ? row?.packageDetail?.package?.request?.unloaded
                                : row?.packageDetail?.package?.request?.loaded
                            )}
                            size="small"
                            label={row?.packageDetail?.package?.request?.code}
                          />
                        </FixedTableCell>
                        <FixedTableCell>
                          {row?.packageDetail?.package?.packageType?.name}
                        </FixedTableCell>
                        <FixedTableCell>
                          {row?.packageDetail?.package?.product
                            ? row?.packageDetail?.package?.product?.name
                            : row?.packageDetail?.package?.description}
                        </FixedTableCell>
                        <FixedTableCell>
                          {row?.packageDetail?.package?.weight}
                        </FixedTableCell>
                        <FixedTableCell>
                          {row?.packageDetail?.package?.fragile ? (
                            <Icon className={classes.iconColor}>
                              check_circle_outline
                            </Icon>
                          ) : (
                            <Icon color="error">highlight_off</Icon>
                          )}
                        </FixedTableCell>
                        <CellLink
                          pathname={`/admin/vendors/${row?.packageDetail?.package?.request?.vendor?.id}`}
                        >
                          {row?.packageDetail?.package?.request?.vendor?.name}
                        </CellLink>
                        <CellLink
                          pathname={`/admin/countries/${row?.packageDetail?.package?.request?.originCountry?.id}`}
                        >
                          {
                            row?.packageDetail?.package?.request?.originCountry
                              ?.name
                          }
                        </CellLink>
                        {/* <FixedTableCell>
                          {row?.packageDetail?.package?.request?.originCountry}
                        </FixedTableCell> */}
                        <CellLink
                          pathname={`/admin/customers/${row?.packageDetail?.package?.request?.customer?.id}`}
                        >
                          {row?.packageDetail?.package?.request?.customer?.name}
                        </CellLink>
                        <CellLink
                          pathname={`/admin/countries/${row?.packageDetail?.package?.request?.destinationCountry?.id}`}
                        >
                          {
                            row?.packageDetail?.package?.request
                              ?.destinationCountry?.name
                          }
                        </CellLink>
                        {/* <FixedTableCell>
                          {
                            row?.packageDetail?.package?.request
                              ?.destinationCountry
                          }
                        </FixedTableCell> */}
                        {/* <FixedTableCell>

                        
                          {row?.packageDetail?.package?.request?.customer?.name}
                        </FixedTableCell> */}

                        {!view && (
                          <FixedTableCell>
                            <IconButton
                              size="small"
                              disabled={deleteLoad}
                              onClick={() => {
                                handleDelete(row?.id);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </FixedTableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              {!(manifests?.data?.length > 0) && (
                <EmptyTableMessage
                  loading={tabsLoading}
                  message={t("noResult")}
                />
              )}
            </TableContainer>
          )}
        </TableFixedHeaderWraper>
        <MUITablePagination
          count={manifests?.paginatorInfo?.total ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePage}
          onRowsPerPageChange={handleRowsPerPage}
        />
      </Grid>
    </Grid>
  );

  return (
    <StyledGrid container alignItems="flex-start">
      {containersTab}
    </StyledGrid>
  );
}
