import React, { useState } from "react";
import { Add, ChevronLeft, Delete, Edit } from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { gql, useMutation } from "@apollo/client";
import { DELETE_LINE_QUERY, DELETE_TAX_QUERY } from "./Graphql";
import { useSnackbar } from "notistack";
import formatMoney from "../../helpers/numbersDot";
import { useBillContext } from "./Context/BillContext";

const StyledTableRowChild = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));
const StyledTableBodyChild = styled(TableBody)(({ theme }) => ({
  backgroundColor: theme.palette.background.appTitle,
}));

export default function Row(props) {
  const {
    id,
    setTax,
    setLineId,
    openTaxDialog,
    openLineDialog,
    setLine,
    capitalPathname,
    billData,
    refetch,
    setTotalSum,
  } = useBillContext();
  const { classes, index, row } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    deleteLine({
      variables: {
        id: parseInt(row.id),
      },
    })
      .then((data) => {
        setLine((prev) => {
          const filterd = prev.filter(
            (i, deleteIndex) => deleteIndex !== index
          );
          return filterd;
        });

        setTotalSum((prev) => ({
          ...prev, // Spread the previous state to keep other values intact
          totalTaxAmount: prev.totalTaxAmount - row?.taxAmount, // Update the specific value
          totalAmount: prev.totalAmount - row?.totalAmount, // Update another value
        }));
      })
      .catch(({ graphQLErrors }) => {
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        // setValidationError(graphQLErrors, setError);
      });
  };

  const handleDeleteTax = (detail, i) => {
    deleteTax({
      variables: {
        id: parseInt(detail.id),
      },
    })
      .then((data) => {
        setLine((prev) => {
          // Create a shallow copy of the lines array
          const updated = [...prev];

          // Filter the taxes array of the specific line
          const filteredTaxes = updated[index]["taxes"].filter(
            (tax, taxIndex) => taxIndex !== i
          );

          // Update the taxes array for the current line
          updated[index]["taxes"] = filteredTaxes;
          return updated;
        });

        setTotalSum((prev) => ({
          ...prev, // Spread the previous state to keep other values intact
          totalTaxAmount: prev.totalTaxAmount - detail.value, // Update the specific value
          // Update another value
        }));
        refetch();
      })
      .catch(({ graphQLErrors }) => {
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        // setValidationError(graphQLErrors, setError);
      });
  };
  const DELETE_LINE = DELETE_LINE_QUERY(capitalPathname);
  const [deleteLine, { loading: deleteLoad }] = useMutation(
    gql`
      ${DELETE_LINE.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );
  const DELETE_TAX = DELETE_TAX_QUERY(capitalPathname);
  const [deleteTax, { loading: deleteTaxLoad }] = useMutation(
    gql`
      ${DELETE_TAX.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        {/* Main Table Row with Collapsible Icon and Data */}
        <FixedTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open);
              setTax && setTax(row.taxes);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <ChevronLeft />}
          </IconButton>
        </FixedTableCell>
        {/* <FixedTableCell>{row?.id}</FixedTableCell> */}
        <FixedTableCell>{row?.priceMode?.name}</FixedTableCell>
        <FixedTableCell>{row?.chargeElement.name}</FixedTableCell>
        <FixedTableCell>{row?.chargeableUnits}</FixedTableCell>
        <FixedTableCell>{row?.chargePrice}</FixedTableCell>
        <FixedTableCell>{row?.lineAmount}</FixedTableCell>
        <FixedTableCell>{row?.taxAmount}</FixedTableCell>
        <FixedTableCell>{row?.totalAmount}</FixedTableCell>
        <FixedTableCell>{billData?.currency?.name}</FixedTableCell>
        <FixedTableCell>
          {formatMoney(row?.totalAmountInLocalCurrency?.toFixed(2))}
        </FixedTableCell>
        {id && !billData?.approved && (
          <>
            <FixedTableCell>
              <IconButton
                disabled={!id}
                onClick={() => {
                  setLineId(row.id);
                  setTax(row.taxes);
                  openTaxDialog();
                }}
                aria-label="Add tax"
                color="primary"
                size="large"
              >
                <Add />
              </IconButton>
            </FixedTableCell>
            <FixedTableCell>
              <IconButton size="small" onClick={() => openLineDialog(row)}>
                <Edit />
              </IconButton>
              <IconButton
                size="small"
                disabled={deleteLoad}
                onClick={() => {
                  handleDelete();
                }}
              >
                <Delete />
              </IconButton>
            </FixedTableCell>
          </>
        )}
      </TableRow>

      {open && (
        <>
          {/* Collapsible Row Content */}
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <StyledTableRowChild>
                        <FixedTableCell>{t("tax")}</FixedTableCell>
                        <FixedTableCell>{t("taxRate")}</FixedTableCell>
                        <FixedTableCell>{t("taxValue")}</FixedTableCell>

                        <FixedTableCell> </FixedTableCell>
                      </StyledTableRowChild>
                    </TableHead>
                    <StyledTableBodyChild>
                      {row?.taxes &&
                        row?.taxes.map((detail, i) => (
                          <TableRow key={i}>
                            <FixedTableCell
                              component="th"
                              scope="row"
                              className={classes.toolbar}
                            >
                              {detail.tax?.name}
                            </FixedTableCell>
                            <FixedTableCell
                              component="th"
                              scope="row"
                              className={classes.toolbar}
                            >
                              {detail.rate}
                            </FixedTableCell>
                            <FixedTableCell
                              component="th"
                              scope="row"
                              className={classes.toolbar}
                            >
                              {detail.value}
                            </FixedTableCell>

                            {id && !billData?.approved ? (
                              <FixedTableCell className={classes.toolbar}>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    openTaxDialog(detail);
                                    setLineId(row.id);
                                    setTax(row.taxes);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  disabled={deleteTaxLoad}
                                  onClick={() => {
                                    handleDeleteTax(detail, i);
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              </FixedTableCell>
                            ) : (
                              <FixedTableCell className={classes.toolbar}>
                                {" "}
                              </FixedTableCell>
                            )}
                          </TableRow>
                        ))}
                    </StyledTableBodyChild>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
    </React.Fragment>
  );
}
