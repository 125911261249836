import { useQuery, gql, useMutation } from "@apollo/client";
import { Collapse, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormButton from "../CustomComponents/Buttons/FormButton";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import { ACCOUNTING_SETTINGS, UPDATE_ACCOUNTING_SETTINGS } from "./Graphql";
import Grid from "@mui/material/Unstable_Grid2";
import CustomTab from "../HOC/FunctionComponents/CustomTab";
import TitleAppBar from "../../Layout/TitleAppBar";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";
const accountingSettingsFields = [
  "previousDate",
  "updateSequence",
  "accountCostCenter",
  "costCenterAutoSave",
  "costCenterUpdatable",
  "displayAccountCode",
  "accountFixedLength",
  "closingAutoShare",
  "inheritJournalDescription",
  "accountMaxLength",
  "level1Length",
  "level2Length",
  "level3Length",
  "level4Length",
  "level5Length",
  "level6Length",
  "level7Length",
  "level8Length",
  "level9Length",
  "level10Length",
];

const AccountingSettings = (props) => {
  const {
    handleSubmit,
    setError,
    formState: { errors },
    control,
    setValue,
  } = useForm();
  const { t } = useTranslation(["translation", "validation"]);

  const { enqueueSnackbar } = useSnackbar();

  const { loading } = useQuery(
    gql`
      ${ACCOUNTING_SETTINGS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        const accountingSettings = data.accountingSettings;
        accountingSettingsFields.forEach((i) => {
          setValue(i, accountingSettings[i]);
        });
      },
    }
  );

  const [
    updateAccountingSettings,
    { loading: updateAccountingSettingsLoading },
  ] = useMutation(
    gql`
      ${UPDATE_ACCOUNTING_SETTINGS.query}
    `
  );

  const onSubmit = (data) => {
    for (const key in data) {
      if (typeof data[key] === "string") {
        data[key] = parseInt(data[key]);
      }
    }

    updateAccountingSettings({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then((data) => {
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };

  const encodeAccountsFields = [
    { name: "level1Length", label: t("level1Length") },
    { name: "level2Length", label: t("level2Length") },
    { name: "level3Length", label: t("level3Length") },
    { name: "level4Length", label: t("level4Length") },
    { name: "level5Length", label: t("level5Length") },
    { name: "level6Length", label: t("level6Length") },
    { name: "level7Length", label: t("level7Length") },
    { name: "level8Length", label: t("level8Length") },
    { name: "level9Length", label: t("level9Length") },
    { name: "level10Length", label: t("moreThan10Level") },
    { name: "accountMaxLength", label: t("accountMaxLength"), maxLength: 2 },
    {
      name: "displayAccountCode",
      label: t("displayAccountCode"),
      fieldType: "switch",
    },
    {
      name: "accountFixedLength",
      label: t("accountFixedLength"),
      fieldType: "switch",
    },
  ];
  const journalEntriesFields = [
    { name: "previousDate", label: t("previousDate") },
    { name: "updateSequence", label: t("updateSequence") },
    { name: "accountCostCenter", label: t("accountCostCenter") },
    { name: "costCenterAutoSave", label: t("costCenterAutoSave") },
    { name: "costCenterUpdatable", label: t("costCenterUpdatable") },

    { name: "closingAutoShare", label: t("closingAutoShare") },
    {
      name: "inheritJournalDescription",
      label: t("inheritJournalDescription"),
    },
  ];

  const encodeAccounts = (
    <Paper component={Grid} container spacing={3} margin={0} mt={2}>
      {encodeAccountsFields.map((field, index) => {
        return field.fieldType === "switch" ? (
          <Grid item xs={12} sm={6} key={index}>
            <MuiSwitch
              edge="end"
              name={field.name}
              label={t(field.label)}
              control={control}
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} key={index}>
            <ControlMUItextField
              control={control}
              errors={errors}
              name={field.name}
              label={t(field.label)}
              type="number"
              rules={{
                required: t("fieldIsRequired"),
                maxLength: {
                  value: field.maxLength ?? 1,
                  message: t("validation:max", {
                    field: t(field.label),
                    number: field.maxLength ?? 1,
                  }),
                },
              }}
            />
          </Grid>
        );
      })}
    </Paper>
  );
  const journalEntries = (
    <Paper component={Grid} container spacing={3} margin={0} mt={2}>
      {journalEntriesFields.map((field, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <MuiSwitch
            edge="end"
            name={field.name}
            label={t(field.label)}
            control={control}
          />
        </Grid>
      ))}
    </Paper>
  );
  const tapsArray = [
    {
      tabHead: t("encodeAccounts"),
      panel: encodeAccounts,
      panelFields: [
        "level1Length",
        "level2Length",
        "level3Length",
        "level4Length",
        "level5Length",
        "level6Length",
        "level7Length",
        "level8Length",
        "level9Length",
        "level10Length",
        "accountMaxLength",
      ].some((field) => errors.hasOwnProperty(field)),
    },
    {
      tabHead: t("journalEntries"),
      panel: journalEntries,
    },
  ];
  return (
    <RootStyleForm>
      <TitleAppBar path={props.match.path} />
      {loading ? (
        <FullScreenLoading minHeight="20%" />
      ) : (
        <Grid
          container
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ gap: 2, p: 2 }}
        >
          <CustomTab tapDetails={tapsArray} settings={true} />
          <Grid
            container
            justifyContent="flex-end"
            className={classesForm.formButton}
          >
            <FormButton disabled={updateAccountingSettingsLoading}>
              {updateAccountingSettingsLoading ? <ButtonLoading /> : t("save")}
            </FormButton>
          </Grid>
        </Grid>
      )}
    </RootStyleForm>
  );
};

export default AccountingSettings;
