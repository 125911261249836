/* eslint-disable no-useless-concat */
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Paper, Icon } from "@mui/material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { DELETE_WARE_HOUSE, WARE_HOUSE_ID } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";
const WareHouseView = (props) => {
  const warehouseId = props.match.params.id?.trim();
  const [openDialog, setOpenDialog] = React.useState(false);

  const { t } = useTranslation();

  const { loading, data } = useQuery(
    gql`
      ${WARE_HOUSE_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(warehouseId) },
    }
  );
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const user = Globals.user;
  const warehouseData = data?.warehouse;
  const branchId = data?.warehouse?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;

  const canEdit = user.hasPermission("freight.warehouse.update")
    ? canAccessBranch
    : false;

  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/warehouse/${warehouseId}/edit`),
      permission: canEdit,
    },
    {
      id: "delete",
      action: handleOpenDialog,
      permission: user?.hasPermission("freight.warehouse.delete"),
    },
  ];
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !warehouseData ? (
    <NotFound />
  ) : (
    <>
      {" "}
      {openDialog && (
        <MutationWithDialog
          mutaion={DELETE_WARE_HOUSE.query}
          setOpenDelete={setOpenDialog}
          openDelete={openDialog}
          dialogTitle={t("deleteRecord")}
          dialogContent={t("deleteRecordMessage")}
          mutaionProps={{
            variables: { id: parseInt(warehouseId) },
          }}
          onCompleted={() => pushUrl(props, `/admin/warehouse`)}
          onCompleteMessage={t("successfullyDeletedRecord")}
        />
      )}
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>
      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair title={t("code")} value={warehouseData?.code} />
          <KeyValuePair title={t("name")} value={warehouseData?.name} />
          <KeyValuePair
            title={t("active")}
            value={
              warehouseData?.active ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("branch")}
            value={
              warehouseData?.branch ? (
                <SpanLink
                  pathname={`/admin/branches/${warehouseData?.branch?.id}`}
                >
                  {warehouseData?.branch?.name}
                </SpanLink>
              ) : (
                "--"
              )
            }
          />
        </Paper>
      </RootStyleView>
    </>
  );
};

export default WareHouseView;
