import * as gqlb from "gql-query-builder";
const fields = [
  "id",
  "code",
  "name",
  "date",
  "active",
  "deletable",
  {
    commodity: ["id", "code", "name"],
  },
];

export const LIST_PRODUCTS = gqlb.query({
  operation: "listProducts",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: fields,
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListProductsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const PRODUCT_ID = gqlb.query({
  operation: "product",
  fields: fields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_PRODUCT = gqlb.mutation({
  operation: "saveProduct",
  fields: ["id", "code", "name"],
  variables: {
    input: {
      type: "ProductInput",
      required: true,
    },
  },
});
export const DELETE_PRODUCT = gqlb.mutation({
  operation: "deleteProduct",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
