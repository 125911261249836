import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Collapse,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
} from "@mui/material";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import {
  AutocompleteWithJson,
  CustomAutocomplete,
} from "../HOC/MUI/CustomAutocomplete";
import { SAVE_SETTINGS, SETTINGS } from "./Graphql";
import { useSnackbar } from "notistack";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import Grid from "@mui/material/Unstable_Grid2";
import CustomTab from "../HOC/FunctionComponents/CustomTab";
import TitleAppBar from "../../Layout/TitleAppBar";

import {
  LIST_CHARGE_ELEMENT_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import MUIselect from "../HOC/MUI/MUIselect";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { Clear, Visibility, VisibilityOff } from "@mui/icons-material";
import { Globals } from "../HOC/Classes/Globals";
import MUIDate from "../HOC/MUI/MUIDate";
import { dateFormat } from "../../helpers/dateFunctions";
// import { fileNames } from "../../utils/fileLoader";
// import { waybills } from "../../utils/waybills";

// function CustomTabPanel(props) {
//   const { children, value, index, ...other } = props;
//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// CustomTabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

const PREFIX = "Settings";

const classes = {
  root: `${PREFIX}-root`,
  paddingStyle: `${PREFIX}-paddingStyle`,
  marginStyle: `${PREFIX}-marginStyle`,
  button: `${PREFIX}-button`,
  card: `${PREFIX}-card`,
  mainTracking: `${PREFIX}-mainTracking`,
  main: `${PREFIX}-main`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    padding: theme.spacing(4),
  },

  [`& .${classes.paddingStyle}`]: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)} !important`,
  },

  [`& .${classes.marginStyle}`]: {
    marginTop: theme.spacing(4),
  },

  // [`& .${classes.button}`]: {
  //   marginTop: theme.spacing(2),
  // },

  [`& .${classes.card}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.mainTracking}`]: {
    margin: 0,
    width: "100%",
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

export default function Settings(props) {
  const { t } = useTranslation();
  const providerList = [
    {
      id: 1,
      name: t("Official"),
      code: "OFFICIAL",
    },
    {
      id: 2,
      name: t("Rdood"),
      code: "RDOOD",
    },
    {
      id: 3,
      name: t("Ultramsg"),
      code: "ULTRAMSG",
    },
    {
      id: 4,
      name: t("Wassenger"),
      code: "WASSENGER",
    },
  ];

  const [autocompleteValues, setAutocompleteValues] = useState({
    defaultShippingService: null,
    mainEmployeeGLAccount: null,
    mainVendorGLAccount: null,
    mainCustomerGLAccount: null,
    mainCarrierGLAccount: null,
    mainCustomAgentGLAccount: null,
    mainAgencyGLAccount: null,
    mainCashGLAccount: null,
    paidShipmentsGLAccount: null,
    taxGLAccount: null,
    mailGLAccount: null,
    commissionChargeElementId: null,
    shippingChargeElementId: null,
    purchaseChargeElementId: null,
    renewalDate: null,
    whatsappProvider: null,
  });
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, setError, formState, control, watch, setValue } =
    useForm();
  const { errors } = formState;
  const [saveSettings, { loading }] = useMutation(
    gql`
      ${SAVE_SETTINGS.query}
    `
  );

  // update-------------------------------------------------------------
  const { loading: settingLoading } = useQuery(
    gql`
      ${SETTINGS.query}
    `,

    {
      variables: {},
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const settingsData = data?.freightSettings;
        const settingsParams = [
          "createShipmentInPast",
          "updateShipmentSequence",
          "createRequestInPast",
          "updateRequestSequence",
          "createEmployeeAccount",
          "createVendorAccount",
          "createCutomerAccount",
          "createCarrierAccount",
          "createCustomAgentAccount",
          "createAgencyAccount",
          "volumetricFactor",
          "purchaseChargeElementId",
          "shippingChargeElementId",
          "commissionChargeElementId",
          "supportedVersion",
        ];
        settingsParams.forEach((i) => {
          (settingsData[i] || settingsData[i] === 0) &&
            setValue(i, settingsData[i]);
        });

        const whatsappProviderobj = providerList.find(
          (i) => i.code === settingsData?.whatsapp?.provider
        );

        setAutocompleteValues({
          defaultShippingService: settingsData?.defaultShippingService,
          mainEmployeeGLAccount: settingsData?.mainEmployeeGLAccount,
          mainVendorGLAccount: settingsData?.mainVendorGLAccount,
          mainCustomerGLAccount: settingsData?.mainCustomerGLAccount,
          mainCarrierGLAccount: settingsData?.mainCarrierGLAccount,
          mainCustomAgentGLAccount: settingsData?.mainCustomAgentGLAccount,
          mainAgencyGLAccount: settingsData?.mainAgencyGLAccount,
          commissionChargeElementId: settingsData?.commissionChargeElement,
          shippingChargeElementId: settingsData?.shippingChargeElement,
          purchaseChargeElementId: settingsData?.purchaseChargeElement,
          renewalDate: settingsData?.renewalDate,
          whatsappProvider: whatsappProviderobj,
        });
        if (settingsData?.whatsapp) {
          const whatsappSettingsParams = ["token", "id", "active"];
          whatsappSettingsParams.forEach((i) => {
            settingsData?.whatsapp[i] &&
              settingsData?.whatsapp[i] !== null &&
              setValue(`whatsapp.${i}`, settingsData?.whatsapp[i]);
          });
        }

        if (settingsData?.email) {
          const emailSettingsParams = [
            "host",
            "port",
            "username",
            "password",
            "active",
          ];
          emailSettingsParams.forEach((i) => {
            settingsData?.email[i] &&
              settingsData?.email[i] !== null &&
              setValue(`email.${i}`, settingsData?.email[i]);
          });
        }
      },
    }
  );

  const onSubmit = (data) => {
    for (const key in data) {
      if (key === "email" && data.email["port"] && data.email[key] !== "") {
        data.email["port"] = parseInt(data.email["port"]);
      }
    }
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
      if (
        [
          "volumetricFactor",
          // "commissionChargeElementId",
          // "shippingChargeElementId",
          // "purchaseChargeElementId",
        ].includes(key)
      ) {
        data[key] = parseInt(data[key]);
      }
    }
    const supportedVersion = data?.supportedVersion;
    const keysToDeleteIfNullOrEmpty = [
      "provider",
      "host",
      "token",
      "id",
      "username",
      "password",
      "port",
      "active",
      "renewalDate",
      "supportedVersion",
    ];

    for (const key of keysToDeleteIfNullOrEmpty) {
      if (data.email[key] === "" || data.email[key] === null) {
        delete data.email[key];
      }
      if (data.whatsapp[key] === "" || data.whatsapp[key] === null) {
        delete data.whatsapp[key];
      }
    }

    saveSettings({
      variables: {
        input: {
          ...data,
          ...(Globals.user.isSuper && { supportedVersion: supportedVersion }),
          ...(Globals.user.isSuper && {
            renewalDate: autocompleteValues.renewalDate
              ? dateFormat(autocompleteValues.renewalDate)
              : null,
          }),
        },
      },
    })
      .then((data) => {
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError, null, false, true);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };
  const isSuper = Globals.user.isSuper;
  const glAccountFields = [
    {
      name: "mainEmployeeGLAccountId",
      label: t("account"),
      switchName: "createEmployeeAccount",
      switchLabel: t("createEmployeeAccount"),
      xs: 12,
      defaultValue: autocompleteValues.mainEmployeeGLAccount,
    },
    {
      name: "mainVendorGLAccountId",
      label: t("account"),
      switchName: "createVendorAccount",
      switchLabel: t("createVendorAccount"),
      xs: 12,
      defaultValue: autocompleteValues.mainVendorGLAccount,
    },
    {
      name: "mainCustomerGLAccountId",
      label: t("account"),
      switchName: "createCutomerAccount",
      switchLabel: t("autoCreateCustomerGLAccount"),
      xs: 12,
      defaultValue: autocompleteValues.mainCustomerGLAccount,
    },
    {
      name: "mainCarrierGLAccountId",
      label: t("account"),
      switchName: "createCarrierAccount",
      switchLabel: t("autoCreateCarrierGLAccount"),
      xs: 12,
      defaultValue: autocompleteValues.mainCarrierGLAccount,
    },
    {
      name: "mainCustomAgentGLAccountId",
      label: t("account"),
      switchName: "createCustomAgentAccount",
      switchLabel: t("autoCreateCustomAgentGLAccount"),
      xs: 12,
      defaultValue: autocompleteValues.mainCustomAgentGLAccount,
    },
    {
      name: "mainAgencyGLAccountId",
      label: t("account"),
      switchName: "createAgencyAccount",
      switchLabel: t("autoCreateAgencyAccount"),
      xs: 12,
      defaultValue: autocompleteValues.mainAgencyGLAccount,
    },
  ];

  const shipments = (
    <GPContainer lg={12} component={Grid} className={classes.card}>
      <Grid xs={12} sm={6}>
        <MUIselect
          name={"volumetricFactor"}
          label={t("volumetricFactor")}
          control={control}
          errors={errors}
          data={[
            { key: t("5000"), value: 5000 },
            { key: t("6000"), value: 6000 },
          ]}
        />
        {/* <ControlMUItextField
          control={control}
          errors={errors}
          name={"volumetricFactor"}
          label={t("volumetricFactor")}
          type="number"
        /> */}
      </Grid>
      <Grid xs={12} sm={6}>
        <MuiSwitch
          edge="end"
          name="createRequestInPast"
          label={t("createRequestInPast")}
          control={control}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <MuiSwitch
          edge="end"
          name="createShipmentInPast"
          label={t("createShipmentInPast")}
          control={control}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <MuiSwitch
          edge="end"
          name="updateShipmentSequence"
          label={t("updateShipmentSequence")}
          control={control}
        />
      </Grid>
      {/* ww */}
      <Grid xs={12} sm={6}>
        <MuiSwitch
          edge="end"
          name="updateRequestSequence"
          label={t("updateRequestSequence")}
          control={control}
        />
      </Grid>
    </GPContainer>
  );

  const accounts = (
    <GPContainer lg={12} component={Grid} className={classes.card}>
      {glAccountFields.map((i, index) => (
        <GlAccount
          key={index}
          control={control}
          errors={errors}
          watch={watch}
          {...i}
        />
      ))}
      <Grid xs={12} sm={6}>
        <CustomAutocomplete
          control={control}
          errors={errors}
          name={"commissionChargeElementId"}
          label={t("commissionChargeElementId")}
          parseData={(data) => data}
          query={LIST_CHARGE_ELEMENT_DROPDOWN.query}
          defaultValue={autocompleteValues.commissionChargeElementId}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <CustomAutocomplete
          control={control}
          errors={errors}
          name={"shippingChargeElementId"}
          label={t("shippingChargeElementId")}
          parseData={(data) => data}
          query={LIST_CHARGE_ELEMENT_DROPDOWN.query}
          defaultValue={autocompleteValues.shippingChargeElementId}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <CustomAutocomplete
          control={control}
          errors={errors}
          name={"purchaseChargeElementId"}
          label={t("purchaseChargeElementId")}
          parseData={(data) => data}
          query={LIST_CHARGE_ELEMENT_DROPDOWN.query}
          defaultValue={autocompleteValues.purchaseChargeElementId}
        />
      </Grid>
    </GPContainer>
  );

  const emailSettings = (
    <GPContainer lg={12} component={Grid} className={classes.card}>
      <Grid alignItems="center" xs={12} sm={6}>
        <ControlMUItextField
          control={control}
          errors={errors}
          name="email.host"
          label={t("host")}
          type="text"
        />
      </Grid>
      <Grid alignItems="center" xs={12} sm={6}>
        <ControlMUItextField
          control={control}
          errors={errors}
          name="email.port"
          label={t("Port")}
          type="number"
        />
      </Grid>
      <Grid alignItems="center" xs={12} sm={6}>
        <ControlMUItextField
          control={control}
          errors={errors}
          name="email.username"
          label={t("username")}
          type="text"
        />
      </Grid>
      <Grid alignItems="center" xs={12} sm={6}>
        <ControlMUItextField
          control={control}
          errors={errors}
          name={"email.password"}
          label={t("password")}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge={"end"}
                  size="large"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <MuiSwitch
          edge="end"
          name="email.active"
          label={t("active")}
          control={control}
        />
      </Grid>
    </GPContainer>
  );
  const whatsAppSettings = (
    <GPContainer lg={12} component={Grid} className={classes.card}>
      <Grid alignItems="center" xs={12} sm={6}>
        <AutocompleteWithJson
          name="whatsapp.provider"
          label={t("provider")}
          control={control}
          errors={errors}
          hideCode={true}
          valueKey={"code"}
          data={providerList}
          defaultValue={autocompleteValues.whatsappProvider}
        />
      </Grid>
      <Grid alignItems="center" xs={12} sm={6}>
        <ControlMUItextField
          control={control}
          errors={errors}
          name="whatsapp.id"
          label={t("id")}
          type="text"
        />
      </Grid>
      <Grid alignItems="center" xs={12} sm={6}>
        <ControlMUItextField
          control={control}
          errors={errors}
          name="whatsapp.token"
          label={t("deviceToken")}
          type="text"
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <MuiSwitch
          edge="end"
          name="whatsapp.active"
          label={t("active")}
          control={control}
        />
      </Grid>
    </GPContainer>
  );
  const admin = (
    <GPContainer lg={12} component={Grid} className={classes.card}>
      <Grid xs={12} sm={6}>
        <MUIDate
          name="renewalDate"
          label={t("renewalDate")}
          control={control}
          value={autocompleteValues.renewalDate}
          InputProps={{
            ...{
              endAdornment: (
                <IconButton
                  size="small"
                  key={"renewalDate"}
                  onClick={() => {
                    setAutocompleteValues((prev) => ({
                      ...prev,
                      renewalDate: null,
                    }));
                    setValue("renewalDate", "");
                  }}
                >
                  <Clear size="small" />
                </IconButton>
              ),
            },
          }}
          onChange={(e) =>
            setAutocompleteValues((prev) => ({
              ...prev,
              renewalDate: e,
            }))
          }
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <ControlMUItextField
          control={control}
          errors={errors}
          name="supportedVersion"
          label={t("supportedVersion")}
          type="text"
        />
      </Grid>
    </GPContainer>
  );
  const tapsArray = [
    {
      tabHead: t("shipmentsSetting"),
      panel: shipments,
      panelFields: [
        "volumetricFactor",
        "createRequestInPast",
        "createShipmentInPast",
        "updateShipmentSequence",
        "updateRequestSequence",
      ].some((field) => errors.hasOwnProperty(field)),
    },
    {
      tabHead: t("accounts"),
      panel: accounts,
      panelFields: [
        "mainCustomerGLAccountId",
        "commissionChargeElementId",
        "shippingChargeElementId",
        "purchaseChargeElementId",
        "mainAgencyGLAccountId",
        "mainCustomAgentGLAccountId",
        "mainCarrierGLAccountId",
        "mainVendorGLAccountId",
        "mainEmployeeGLAccountId",
      ].some((field) => errors.hasOwnProperty(field)),
    },
    {
      tabHead: t("email"),
      panel: emailSettings,
      panelFields: ["email"].some((field) => errors.hasOwnProperty(field)),
    },
    {
      tabHead: t("whatsapp"),
      panel: whatsAppSettings,
      panelFields: ["whatsapp"].some((field) => errors.hasOwnProperty(field)),
    },
  ];
  if (isSuper) {
    tapsArray.push({
      tabHead: t("isSuper"),
      panel: admin,
    });
  }

  return settingLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classes.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : (
    <Root>
      <TitleAppBar path={props.match.path} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} p={2}>
          <CustomTab tapDetails={tapsArray} settings={true} />
          <Stack alignItems="flex-end">
            <FormButton disabled={loading}>
              {loading ? <ButtonLoading /> : t("save")}
            </FormButton>
          </Stack>
        </Stack>
      </form>
    </Root>
  );
}

const GPContainer = (props) => {
  return (
    <Paper component={Grid} container spacing={3} margin={0} mt={2}>
      {props.children}
    </Paper>
  );
};

const GlAccount = (props) => {
  const { switchName, watch, switchLabel, ...restProps } = props;
  return (
    <>
      {switchName && (
        <Grid alignItems="center" xs={12} sm={6}>
          <MuiSwitch
            edge="end"
            name={switchName}
            label={switchLabel}
            control={props.control}
          />
        </Grid>
      )}
      <Grid alignItems="center" xs={12} sm={6}>
        <CustomAutocomplete
          parseData={(data) => data}
          query={LIST_GL_ACCOUNTS_DROPDOWN.query}
          rules={
            switchName && {
              required: {
                value: watch(switchName),
                message: "this field is required",
              },
            }
          }
          variables={{
            input: {
              typeCode: "MAIN",
            },
          }}
          {...restProps}
        />
      </Grid>
    </>
  );
};
