import * as gqlb from "gql-query-builder";

export const SEARCH = gqlb.query({
  operation: "universalSearch",
  fields: [
    {
      operation: "...on Shipment",
      fields: ["id", "code", "__typename"],
      variables: {},
    },

    {
      operation: "...on Request",
      fields: ["id", "code", "__typename"],
      variables: {},
    },
  ],
  variables: {
    keyword: {
      type: "String",
      required: "true",
    },
  },
});
