/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Paper, Icon } from "@mui/material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { DELETE_COMMODITY, COMMODITY_ID } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import { dateFormat } from "../../helpers/dateFunctions";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";

const CommodityView = (props) => {
  const { t } = useTranslation();
  const commodityId = props.match.params.id?.trim();
  const [openDelete, setOpenDelete] = useState(false);
  const user = Globals.user;

  const openDeleteDialog = (type) => {
    setOpenDelete(true);
  };
  const { loading, data } = useQuery(
    gql`
      ${COMMODITY_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(commodityId) },
    }
  );
  const commodityData = data?.commodity;

  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/commodities/${commodityId}/edit`),
      permission: user.hasPermission("freight.commodity.update"),
    },
    {
      id: "delete",
      action: openDeleteDialog,
      permission: Boolean(user.hasPermission("freight.commodity.delete")),
    },
  ];
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.commodity ? (
    <NotFound />
  ) : (
    <>
      {openDelete && (
        <MutationWithDialog
          mutaion={DELETE_COMMODITY.query}
          setOpenDelete={setOpenDelete}
          openDelete={openDelete}
          dialogTitle={t("deleteRecord")}
          dialogContent={t("deleteRecordMessage")}
          mutaionProps={{
            variables: { id: parseInt(commodityId) },
          }}
          onCompleted={() => pushUrl(props, `/admin/commodities`)}
          onCompleteMessage={t("successfullyDeletedRecord")}
        />
      )}
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>

      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair md={6} title={t("code")} value={commodityData?.code} />
          <KeyValuePair md={6} title={t("name")} value={commodityData?.name} />
          <KeyValuePair
            md={6}
            title={t("date")}
            value={dateFormat(commodityData?.date)}
          />
          <KeyValuePair
            md={6}
            title={t("active")}
            value={
              commodityData?.active ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
        </Paper>
      </RootStyleView>
    </>
  );
};

export default CommodityView;
