import React, { Fragment, useContext, useEffect, useLayoutEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import MUItextField from "../HOC/MUI/MUItextField";
import { useForm } from "react-hook-form";
import config from "../../config.json";
import {
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  Edit,
  LockOutlined,
  NavigateNext,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { gql, useMutation } from "@apollo/client";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import * as gqlb from "gql-query-builder";
import { useTranslation } from "react-i18next";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import { useSnackbar } from "notistack";
import { pushUrl, replaceUrl } from "../HOC/CustomFunctions/pushUrl";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import { ModeContext } from "../../Context/ModeContext";

const PREFIX = "Login";

const classes = {
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  button: `${PREFIX}-button`,
  spinner: `${PREFIX}-spinner`,
  divider: `${PREFIX}-divider`,
  inestancePage: `${PREFIX}-inestancePage`,
  companyCodeRoot: `${PREFIX}-companyCodeRoot`,
  companyCode: `${PREFIX}-companyCode`,
  signup: `${PREFIX}-signup`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.paper}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.form}`]: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },

  [`& .${classes.button}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.spinner}`]: {
    width: 24,
    margin: theme.spacing(0, 1),
  },

  [`& .${classes.divider}`]: {
    backgroundColor: theme.palette.action.active,
    marginTop: theme.spacing(1),
  },

  [`& .${classes.inestancePage}`]: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1000,
    backgroundColor: "#fff",
  },

  [`& .${classes.companyCodeRoot}`]: { marginTop: theme.spacing(2) },
  [`& .${classes.companyCode}`]: { marginRight: theme.spacing(1) },
  [`& .${classes.signup}`]: { marginLeft: theme.spacing(1), textTransform: 'capitalize' },
}));

const userWithToken = [
  "token",
  {
    user: [
      "username",
      "isSuper",
      // {
      //   operation: "account",
      //   fields: [
      //     {
      //       operation: "...on Customer",
      //       fields: ["id", "name", "email"],
      //       variables: {},
      //     },
      //     {
      //       operation: "...on DeliveryAgent",
      //       fields: ["id", "name", "code"],
      //       variables: {},
      //     },
      //   ],
      //   variables: {},
      // },
      {
        roles: [
          "id",
          "name",
          "code",
          {
            permissions: ["id", "name", "slug"],
          },
        ],
      },
    ],
  },
];
const LOGIN = gqlb.mutation({
  operation: "login",
  fields: userWithToken,
  variables: {
    input: {
      type: "LoginInput",
      required: true,
    },
  },
});
const RESENDCODE = gqlb.mutation({
  operation: "resendVerificationCode",
  variables: {
    email: {
      type: "String",
      required: true,
    }

  },
});
const VERIFY = gqlb.mutation({
  operation: "verifyRegistrationEmail",
  fields: userWithToken,
  variables: {
    input: {
      type: "VerifyRegistrationEmailInput",
      required: true,
    },
  },
});
// const HAND_SHAKE = gqlb.query({
//   operation: "handshake",
//   fields: [],
//   variables: {},
// });

export default function Login(props) {
  useLayoutEffect(() => {
    if (localStorage.getItem("token") !== null) {
      replaceUrl(props, "/admin");
    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const multiInstance = config?.multiInstance;
  const storageInstanceCode = localStorage.getItem("instanceCode");
  const { setFirstLoad } = useContext(ModeContext)

  const [showPassword, setShowPassword] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [rememberme, setRememberme] = useState(true);
  const [holdVerifay, setHoldVerifay] = useState(false);
  const [hasValidInstanceCode, setHasValidInstanceCode] = useState(
    multiInstance && storageInstanceCode
  );
  const [disabled, setDisabled] = useState(false);
  const [timer, setTimer] = useState(30);
  const [email, seEmail] = useState('');

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    setFocus,
  } = useForm();

  const handelRememberme = () => {
    setRememberme((prev) => !prev);
  };
  const {
    handleSubmit: handleSubmitInestance,
    register: registerInestance,
    clearErrors: clearErrorsInestance,
    formState: { errors: inestanceErrors },
  } = useForm();

  useEffect(() => {
    hasValidInstanceCode && setFocus("email");

    return () => { };
  }, [setFocus, hasValidInstanceCode]);

  const closeDialog = () => {
    setDialog(false);
  };

  // const [handshake] = useLazyQuery(
  //   gql`
  //     ${HAND_SHAKE.query}
  //   `,
  //   {
  //     fetchPolicy: "no-cache",
  //     nextFetchPolicy: "no-cache",
  //     onCompleted: (data) => {
  //       localStorage.setItem("instanceCode", watchInestance("instanceCode"));
  //       setHasValidInstanceCode(true);
  //     },
  //     onError: ({ graphQLErrors }) => {
  //       const errorCode = graphQLErrors[0].extensions.code;
  //       if (errorCode === "INVALID_INSTANCE_CODE") {
  //         setErrorInestance("instanceCode", {
  //           message: graphQLErrors[0]?.message,
  //         });
  //       }
  //     },
  //   }
  // );

  const [loginMutation, { loading }] = useMutation(
    gql`
      ${LOGIN.query}
    `
  );
  const [resendMutation] = useMutation(
    gql`
      ${RESENDCODE.query}
    `
  );
  const [verify, { loading: verifayLoad }] = useMutation(
    gql`
      ${VERIFY.query}
    `
  );
  const verifyHandler = (datas) => {
    verify({
      variables: {
        input: {
          email: datas.email,
          code: datas.code,
        },
      },
    })
      .then((res) => {
        const verifyRegistrationEmail = res?.data?.verifyRegistrationEmail;
        if (verifyRegistrationEmail) {
          setDialog(false);
          const token = res.data.verifyRegistrationEmail.token;
          if (token) {
            setDialog(false);
            const verifyEmail = res.data.verifyRegistrationEmail;
            if (
              !verifyEmail.user.isSuper &&
              verifyEmail.user.roles.some((ele) => ele.code === "DLVRY")
            ) {
              enqueueSnackbar(t("loginProhibited"), {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                TransitionComponent: Collapse,
              });
            } else {
              // setUser override userDataLoader component
              // Globals.setUser(new User(verifyEmail.user));

              const token = verifyEmail.token;
              localStorage.setItem("token", token);
              pushUrl(props, `/admin`);
            }
          } else {
            setHoldVerifay(true);
          }
        } else {
          setHoldVerifay(true);
        }
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors[0]?.extensions.code === "INVALID_VERIFICATION_CODE") {
          setError("code", {
            type: "required",
            message: t("activationErrorMessage"),
            shouldFocus: true,
          });
        }
      });
  };
  const onSubmit = async (datas) => {
    setFirstLoad(false)
    seEmail(datas.email)
    await loginMutation({
      variables: {
        input: {
          username: datas.email.trim(),
          password: datas.password,
          rememberMe: rememberme,
        },
      },
    })
      .then((data) => {
        const login = data.data.login;
        if (
          !login.user.isSuper &&
          login.user.roles.some((ele) => ele.code === "DLVRY")
        ) {
          enqueueSnackbar(t("loginProhibited"), {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        } else {
          // setUser override userDataLoader component
          // Globals.setUser(new User(login.user));

          const token = login.token;
          localStorage.setItem("token", token);
          const prevUrl = window.history.state?.state?.prevUrl;
          const search = prevUrl?.split("?")[1]
          const pathname = prevUrl?.split("?")[0]
          const unAuthenticated =
            window.history.state?.state?.unAuthenticated &&
            prevUrl !== "/login";
          pushUrl(props, unAuthenticated ? pathname : `/admin`, "", search ? search : '');
        }
      })
      .catch(({ graphQLErrors }) => {
        const errorCode = graphQLErrors[0]?.extensions?.code;
        if (errorCode === "EMAIL_NOT_VERIFIED") {
          setDialog(true);
          setValidationError(graphQLErrors, setError);
        } else if (errorCode === "ACCOUNT_NOT_VERIFIED") {
          setDialog(true);
          setHoldVerifay(true);
        } else if (
          errorCode === "INVALID_CREDENTIALS"
          // errorCode === "ACCOUNT_DISABLED" ||
          // errorCode === "USER_DISABLED"
        ) {
          enqueueSnackbar(graphQLErrors[0]?.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  const onSubmitInstance = (data) => {
    // handshake({
    //   context: {
    //     headers: {
    //       "X-Instance-Code": data.instanceCode,
    //     },
    //   },
    // });
  };
  useEffect(() => {
    let interval;

    if (disabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [disabled]);

  useEffect(() => {
    if (timer === 0) {
      setDisabled(false);
      setTimer(30);
    }
  }, [timer]);

  const handleResend = async () => {
    setDisabled(true);
    await resendMutation({
      variables: { email }
    })
    setTimeout(() => {
    }, 30000);
  };
  const inestancePage = (
    <form
      className={classes.form}
      onSubmit={handleSubmitInestance(onSubmitInstance)}
    >
      <MUItextField
        name={"instanceCode"}
        label={t("companyCode")}
        register={registerInestance}
        errors={inestanceErrors}
        onChange={() => {
          clearErrorsInestance("instanceCode");
        }}
        margin="normal"
      />
      <Grid item container justifyContent="center">
        <Button
          fullWidth
          className={classes.button}
          size="large"
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<NavigateNext />}
        >
          {t("next")}
        </Button>
      </Grid>
    </form>
  );

  return (
    <Root>
      <Container maxWidth="xs">
        <CssBaseline />
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={dialog}
          onClose={closeDialog}
          aria-labelledby="form-dialog-title"
        >
          {holdVerifay ? (
            <>
              <DialogTitle id="form-dialog-title" color={"text.primary"}>
                {t("accountPendingApprovalMessage")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t("accountPendingApprovalTitle")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDialog} color="primary">
                  {t("close")}
                </Button>
              </DialogActions>
            </>
          ) : (
            <>
              <form onSubmit={handleSubmit(verifyHandler)}>
                <DialogTitle id="form-dialog-title" color={"text.primary"}>
                  {t("activationCode")}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>{t("codeSentToEmail")}</DialogContentText>
                  <MUItextField
                    name={"code"}
                    label={t("activationCode")}
                    margin="normal"
                    size="medium"
                    register={register}
                    errors={errors}
                  />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button onClick={handleResend} disabled={disabled} color="primary">
                      {t("resendCode")}
                    </Button>
                    {disabled &&
                      <DialogContentText>
                        {t('ResendTimer', { seconds: timer })}
                      </DialogContentText>}
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeDialog} color="primary">
                    {t("close")}
                  </Button>
                  <Button disabled={verifayLoad} type="submit" color="primary">
                    {verifayLoad ? (
                      <CustomSpinner name="BeatLoader" size={8} margin={2} />
                    ) : (
                      t("activateAccount")
                    )}
                  </Button>
                </DialogActions>
              </form>
            </>
          )}
        </Dialog>

        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" color={"text.primary"}>
            {t("login")}
          </Typography>

          {multiInstance && hasValidInstanceCode && (
            <Grid
              container
              className={classes.companyCodeRoot}
              justifyContent="center"
            >
              <Grid item className={classes.companyCode}>
                <Typography
                  component="span"
                  color="textSecondary"
                  variant="body1"
                >
                  {t("companyCode") + " : "}
                </Typography>
                <Typography
                  component="span"
                  color="textSecondary"
                  variant="overline"
                >
                  {storageInstanceCode}
                </Typography>
              </Grid>

              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={() => setHasValidInstanceCode(false)}
                startIcon={<Edit fontSize="small" />}
              >
                {t("update")}
              </Button>
            </Grid>
          )}

          {!hasValidInstanceCode && multiInstance ? (
            inestancePage
          ) : (
            <Fragment>
              <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <MUItextField
                  name={"email"}
                  label={t("usernameOrEmail")}
                  register={register}
                  errors={errors}
                  margin="normal"
                />
                <MUItextField
                  margin="normal"
                  name={"password"}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge={"end"}
                          size="large"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label={t("password")}
                  register={register}
                  errors={errors}
                />
                <Grid>
                  {/* <SpanLink pathname={`/Forgotpassword`}>
                    {t("forgotPassword")}
                  </SpanLink> */}
                  <FormControlLabel
                    sx={{ textTransform: 'capitalize' }}
                    checked={rememberme}
                    label={t("rememberme")}
                    control={<Checkbox color="primary" />}
                    labelPlacement="end"
                    onChange={handelRememberme}
                  />
                </Grid>
                <Grid item container justifyContent="center">
                  <Button
                    fullWidth
                    className={classes.button}
                    size="large"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                    startIcon={
                      loading ? (
                        <CustomSpinner
                          size={8}
                          css={{ fontSize: "10px !important" }}
                          name={"PulseLoader"}
                        />
                      ) : (
                        <LockOutlined />
                      )
                    }
                  >
                    {t("login")}
                  </Button>
                </Grid>
                <Grid fontSize={16} marginBottom={1}>
                  <SpanLink pathname={`/Forgotpassword`}>
                    {t("forgotPassword")}
                  </SpanLink>
                </Grid>
              </form>
              <Grid item container justifyContent="start" fontSize={16} marginY={1}>
                {t("haveaccount")}
                <SpanLink className={classes.signup} pathname={`/register`}>
                  {t("signup")}
                </SpanLink>
                {/* <Button
                  fullWidth
                  className={classes.button}
                  size="large"
                  onClick={() => {
                    pushUrl(props, `/register`);
                  }}
                  variant="contained"
                  sx={{ backgroundColor: blueGrey[500], color: "#fff" }}
                  type="submit"
                  startIcon={<AddCircleOutline />}
                >
                  {t("createNewAccount")}
                </Button> */}
              </Grid>
            </Fragment>
          )}
        </div>
      </Container>
    </Root>
  );
}
