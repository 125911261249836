import * as gqlb from "gql-query-builder";
const fields = [
  "id",
  "code",
  "name",
  "address",
  "phone",
  "active",
  {
    bank: ["id", "code", "name"],
  },
  {
    country: ["id", "code", "name"],
  },
  {
    state: ["id", "code", "name"],
  },
];

export const LIST_BANK_BRANCHES = gqlb.query({
  operation: "listBankBranches",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: fields,
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListBankBranchFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const BANK_BRANCH = gqlb.query({
  operation: "bankBranch",
  fields: [
    ...fields,
    "postalCode",
    {
      accounts: [
        "id",
        "code",
        "name",
        "active",
        "date",
        {
          bankBranch: ["id", "code", "name"],
        },
        {
          type: ["id", "code", "name"],
        },
        {
          currency: ["id", "code", "name"],
        },
        {
          glAccount: ["id", "code", "name"],
        },
      ],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_BANK_BRANCH = gqlb.mutation({
  operation: "saveBankBranch",
  fields: ["id", "code", "name"],
  variables: {
    input: {
      type: "BankBranchInput",
      required: true,
    },
  },
});
export const SAVE_BANK_ACCOUNT = gqlb.mutation({
  operation: "saveBankAccount",
  fields: [
    "id",
    "code",
    "name",
    "active",
    {
      type: ["id", "code", "name"],
    },
    {
      currency: ["id", "code", "name"],
    },
    {
      glAccount: ["id", "code", "name"],
    },
  ],
  variables: {
    input: {
      type: "BankAccountInput",
      required: true,
    },
  },
});
