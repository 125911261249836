import React, { useState } from "react";
import {
  Paper,
  Collapse,
  Stack,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { SAVE_SHIPPING_PORT, SHIPPING_PORT_ID } from "./Graphql";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import FormButton from "../CustomComponents/Buttons/FormButton";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_COUNTRIES_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_STATES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";

const ShippingPortForm = (props) => {
  const [activePort, setActivePort] = useState(true);
  const [pathURL] = useState(props.match.path);
  // const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [shippingPortId, setShippingPortId] = useState(
    parseInt(props.match.params.id)
  );
  const [autocompleteValues, setAutocompleteValues] = useState({
    mode: null,
    country: null,
    state: null,
  });
  const { handleSubmit, control, formState, setValue, setError, watch } =
    useForm();
  const { errors } = formState;

  const [saveShippingPort, { loading: saveShippingPortLoading }] = useMutation(
    gql`
      ${SAVE_SHIPPING_PORT.query}
    `
  );

  const { data: updateShippingPort, loading: updateShippingPortLoading } =
    useQuery(
      gql`
        ${SHIPPING_PORT_ID.query}
      `,

      {
        skip: !shippingPortId,
        variables: { id: shippingPortId },
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
          const shippingPortData = data.shippingPort;
          setActivePort(shippingPortData.active);
          const shippingPortParams = ["code", "name"];
          shippingPortParams.forEach((i) => {
            shippingPortData[i] !== null && setValue(i, shippingPortData[i]);
          });
          setValue("active", shippingPortData["active"]);
          setAutocompleteValues({
            mode: shippingPortData.mode,
            country: shippingPortData?.country,
            state: shippingPortData?.state,
          });
        },
      }
    );

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActivePort(active);
    setValue("active", active);
  };
  const selectDefaultValue = (data) => {
    return data;
  };

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
        continue;
      }
    }

    saveShippingPort({
      variables: {
        input: {
          ...data,
          active: activePort,
          ...(shippingPortId && { id: shippingPortId }),
        },
      },
    })
      .then((data) => {
        setShippingPortId(data?.data?.saveShippingPort?.id);
        pushUrl(
          props,
          `/admin/shipping-ports/${data?.data?.saveShippingPort?.id}`
        );
        // const url = history.createHref({
        //   pathname: `/admin/shipping-ports/${data?.data?.saveShippingPort?.id}/edit`,
        // });
        // false && setPathURL(url);
        // false && windowReplaceUrl(url);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid
          justifyContent="end"
          sx={{ display: "flex", flexWrap: "wrap" }}
          xs={12}
        >
          <FormControlLabel
            checked={activePort}
            control={<Switch color="primary" />}
            label={t("active")}
            labelPlacement="start"
            onChange={handelActivate}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"code"}
            label={t("code")}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"name"}
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <CustomAutocomplete
            valueKey="code"
            control={control}
            errors={errors}
            rules={{ required: t("fieldIsRequired") }}
            name={"mode"}
            label={t("shippingMode")}
            parseData={(data) => selectDefaultValue(data)}
            variables={{ input: { code: "FR_SHIPPING_MODE" } }}
            query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
            defaultValue={autocompleteValues.mode}
          />
        </Grid>
        <Grid xs={12} sm={6} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"countryId"}
            label={t("country")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => selectDefaultValue(data)}
            query={LIST_COUNTRIES_DROPDOWN.query}
            onChangeValue={(e) => {
              setValue("stateId", "");
            }}
            defaultValue={autocompleteValues.country}
          />
        </Grid>
        <Grid xs={12} sm={6} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            disabled={!watch("countryId")}
            skip={!watch("countryId")}
            name={"stateId"}
            label={t("state")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => selectDefaultValue(data)}
            query={LIST_STATES_DROPDOWN.query}
            variables={{
              countryId: watch("countryId"),
            }}
            defaultValue={autocompleteValues.state}
          />
        </Grid>
      </Paper>
      <Grid container justifyContent="flex-end" className={classesForm.spacing}>
        <FormButton disabled={saveShippingPortLoading}>
          {saveShippingPortLoading ? <ButtonLoading /> : t("save")}
        </FormButton>
      </Grid>
    </form>
  );
  let DOM;

  if (shippingPortId) {
    DOM = updateShippingPort ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return updateShippingPortLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !updateShippingPort && shippingPortId ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={pathURL} />
      <Stack spacing={2} p={2}>
        {DOM}
      </Stack>
    </RootStyleForm>
  );
};

export default ShippingPortForm;
