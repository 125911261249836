/* eslint-disable no-useless-concat */
import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Paper, Icon, Stack } from "@mui/material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { DELETE_SHIPPING_PORT, SHIPPING_PORT_ID } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import SpanLink from "../HOC/CustomComponents/SpanLink";

const PREFIX = "ShippingPortView";
const classes = {
  paper: `${PREFIX}-paper`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
  paperTitle: `${PREFIX}-paperTitle`,
};

const Root = styled(Stack)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    flexGrow: 1,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.paperTitle}`]: {
    justifyContent: "center",
    background: `${theme.palette.background.appTitle} !important`,
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const ShippingPortView = (props) => {
  const { t } = useTranslation();
  const shippingPortId = props.match.params.id?.trim();
  const [openDelete, setOpenDelete] = useState(false);
  const user = Globals.user;

  const openDeleteDialog = (type) => {
    setOpenDelete(true);
  };
  const { loading, data } = useQuery(
    gql`
      ${SHIPPING_PORT_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(shippingPortId) },
    }
  );
  const shippingPortData = data?.shippingPort;

  const canEdit = user.hasPermission("freight.shipping_port.update");
  const canDelete =
    user.hasPermission("freight.shipping_port.delete") &&
    shippingPortData?.deletable;

  const icons = [
    {
      id: "edit",
      action: () =>
        pushUrl(props, `/admin/shipping-ports/${shippingPortId}/edit`),
      permission: canEdit,
    },
    {
      id: "delete",
      action: openDeleteDialog,
      permission: Boolean(canDelete),
    },
  ];
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classes.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.shippingPort ? (
    <NotFound />
  ) : (
    <>
      <Fragment>
        <MutationWithDialog
          mutaion={DELETE_SHIPPING_PORT.query}
          setOpenDelete={setOpenDelete}
          openDelete={openDelete}
          dialogTitle={t("deleteRecord")}
          dialogContent={t("deleteRecordMessage")}
          mutaionProps={{
            variables: { id: parseInt(shippingPortId) },
          }}
          onCompleted={() => pushUrl(props, `/admin/shipping-ports`)}
          onCompleteMessage={t("successfullyDeletedRecord")}
        />
        <TitleAppBar path={props.match.path}>
          <LongMenu icons={icons} />
        </TitleAppBar>
      </Fragment>
      <Root spacing={2} p={2} justifyContent="center">
        <Paper container className={classes.paper} component={Grid}>
          <KeyValuePair title={t("code")} value={shippingPortData?.code} />
          <KeyValuePair title={t("name")} value={shippingPortData?.name} />
          <KeyValuePair title={t("date")} value={shippingPortData?.date} />
          <KeyValuePair
            title={t("shippingMode")}
            value={shippingPortData?.mode?.name}
          />
          <KeyValuePair
            title={t("country")}
            value={
              <SpanLink
                pathname={`/admin/countries/${shippingPortData?.country?.id}`}
              >
                {shippingPortData?.country?.name}
              </SpanLink>
            }
          />
          <KeyValuePair
            title={t("state")}
            value={shippingPortData?.state?.name}
          />
          <KeyValuePair
            title={t("active")}
            value={
              shippingPortData?.active ? (
                <Icon className={classes.iconColor}>check_circle_outline</Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
        </Paper>
      </Root>
    </>
  );
};

export default ShippingPortView;
