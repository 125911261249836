import React, { useEffect, useState } from "react";
import {
  Paper,
  Collapse,
  Stack,
  IconButton,
  Button,
  useTheme,
  Alert,
  Box,
} from "@mui/material";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_CURRENCIES_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import MUIDate from "../HOC/MUI/MUIDate";
import { dateFormat } from "../../helpers/dateFunctions";
import {
  AddCircleOutline,
  AttachMoney,
  Clear,
  Done,
  DoneAll,
} from "@mui/icons-material";
import moment from "moment";
import CustomButton from "../HOC/MUI/CustomButton";
import {
  ADJUSTMENT_BY_ID_QUERY,
  APPROVE_ADJUSTMENT_MUTATION,
  GL_APPROVE_ADJUSTMENT_MUTATION,
  SAVE_ADJUSTMENT_MUTATION,
} from "./Graphql";
import { CURRENCY_ID } from "../Request/Graphql";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useAccountType } from "../../Hooks/useAccountType";
import CustomList from "../HOC/CustomComponents/CustomListDialog/CustomList";
import { useDocumentTypeType } from "../../Hooks/useDocumentType";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";

const AdjustmentForm = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const initFromDate = moment(new Date())
    .locale("en")
    .subtract("month")
    .add("day")
    .format("YYYY-MM-DD");
  let pathname = "adjustments";

  const [pathURL] = useState(props?.match?.path ?? props?.pathURL);
  const [id, setId] = useState(parseInt(props?.match?.params?.id));
  const [date, setDate] = useState({
    adjustmentDate: initFromDate,
  });
  const [dialogDetails, setDialogDetails] = useState({
    state: false,
    function: null,
    confirmAction: true,
    title: "",
    content: "",
  });

  const [pickedRequest, setPickedRequest] = React.useState();

  const [autocompleteValues, setAutocompleteValues] = useState({
    customer: null,
    employee: null,
    vendor: null,
    customAgent: null,
    agency: null,
    carrier: null,
    type: null,
    accountType: null,
    transactionType: null,
    branch: null,
    currency: null,
    priceMode: null,
    chargeElement: null,
    tax: null,
  });

  const [disabled, setDisabled] = useState({
    formEdit: false,
  });
  const [exchangeRate, setExchangeRate] = React.useState({
    updatable: true,
  });
  const { handleSubmit, control, formState, setValue, setError, watch } =
    useForm();
  const { errors } = formState;

  useQuery(
    gql`
      ${CURRENCY_ID.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: pickedRequest || !parseInt(watch("currencyId")),
      variables: {
        id: parseInt(watch("currencyId")),
        input: {},
      },
      onCompleted: (data) => {
        const exchangeRate = data.currency.exchangeRate;
        watch("exchangeRate") === "" &&
          setValue("exchangeRate", exchangeRate?.rate);
        setExchangeRate((prev) => ({
          ...prev,
          updatable: exchangeRate.updatable,
        }));
      },
    }
  );
  const { data, loading: updateLoading } = useQuery(
    gql`
      ${ADJUSTMENT_BY_ID_QUERY.query}
    `,
    {
      skip: !id,
      variables: { id: id },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const adjustmentData = data?.adjustment;
        setDisabled((prev) => ({
          ...prev,
          formEdit: true,
        }));
        const params = [
          "id",
          "code",
          "exchangeRate",
          "amount",
          "remarks",
          "adjustmentDate",
        ];
        params.forEach((i) => {
          adjustmentData[i] !== null && setValue(i, adjustmentData[i]);
        });
        setDate((prev) => ({
          ...prev,
          adjustmentDate: adjustmentData.adjustmentDate,
        }));
        setPickedRequest(
          adjustmentData.type?.code === "CR_ADJ"
            ? adjustmentData?.invoice
            : adjustmentData?.bill
        );
        setAutocompleteValues((prev) => ({
          ...prev,
          customer: adjustmentData.customer,
          employee: adjustmentData.employee,
          vendor: adjustmentData.vendor,
          customAgent: adjustmentData.agent,
          agency: adjustmentData.agency,
          carrier: adjustmentData.carrier,
          glAccount: adjustmentData.glAccount,
          type: adjustmentData.type,
          accountType: adjustmentData.accountType,
          transactionType: adjustmentData.transactionType,
          branch: adjustmentData.branch,
          currency: adjustmentData.currency,
        }));
      },
    }
  );
  const [save, { loading: saveLoading }] = useMutation(
    gql`
      ${SAVE_ADJUSTMENT_MUTATION.query}
    `
  );
  const [approveMutation, { loading: approveLoad }] = useMutation(
    gql`
      ${APPROVE_ADJUSTMENT_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {},
    }
  );

  const [glApproveMutation, { loading: glApproveLoad }] = useMutation(
    gql`
      ${GL_APPROVE_ADJUSTMENT_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {},
    }
  );
  const accountType = watch("accountType");
  const dynamicAutocompleteProps = useAccountType(
    accountType,
    autocompleteValues
  );

  const adjustmentData = data?.adjustment;
  const payeeTransaction = watch("type");
  const dynamicTextFiled = useDocumentTypeType(
    payeeTransaction,
    setValue,
    adjustmentData
  );

  useEffect(() => {
    if (pickedRequest?.currency) {
      setValue("exchangeRate", pickedRequest?.exchangeRate);
      setAutocompleteValues((prev) => ({
        ...prev,
        currency: pickedRequest?.currency,
      }));
    }
    if (pickedRequest?.totalAmount) {
      setValue("amount", pickedRequest?.totalAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickedRequest]);

  const parseData = (data) => {
    return data;
  };
  const setSelect = (obj) => {
    setPickedRequest(obj?.data);
    setValue(dynamicTextFiled?.name, obj?.data?.code);
  };

  const billFilters = {
    ...(dynamicAutocompleteProps.name && {
      [dynamicAutocompleteProps.name]: watch(dynamicAutocompleteProps.name),
      glApproved: true,
    }),
  };
  const closeConfirmationDialog = () => {
    setDialogDetails((prev) => ({
      ...prev,
      state: false,
    }));
  };

  const approveAdjustment = () => {
    approveMutation({
      variables: {
        id: id,
      },
    })
      .then(() => {
        closeConfirmationDialog();
        enqueueSnackbar(t("successfullyApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        pushUrl(props, `/admin/${pathname}/${id}`);
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };
  const glApprove = () => {
    glApproveMutation({
      variables: {
        id: id,
      },
    })
      .then((data) => {
        closeConfirmationDialog();
        enqueueSnackbar(t("successfullyGlApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        closeConfirmationDialog();

        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const onSubmit = async (data) => {
    for (const key in data) {
      if (
        data[key] === "" &&
        [
          "customerId",
          "vendorId",
          "customAgentId",
          "employeeId",
          "agencyId",
          "carrierId",
        ].includes(key)
      ) {
        data[key] = null;
      }
    }
    for (const key in data) {
      if (
        (data[key] === "" && !["remarks"].includes(key)) ||
        ["billCode", "invoiceId", "billId"].includes(key)
      ) {
        delete data[key];
      }
      if (["exchangeRate", "amount"].includes(key)) {
        data[key] = parseFloat(data[key]);
      }
    }
    try {
      const data_1 = await save({
        variables: {
          input: {
            ...data,
            ...(data.adjustmentDate && {
              adjustmentDate: dateFormat(data.adjustmentDate),
            }),
            ...(parseInt(data.currencyId) && {
              ...(autocompleteValues.currency?.id
                ? { currencyId: parseInt(autocompleteValues.currency?.id) }
                : { currencyId: parseInt(data.currencyId) }),
            }),
            ...(id && { id: id }),

            ...(watch("type") === "CR_ADJ" && {
              invoiceId: pickedRequest?.id ?? null,
              billId: null,
            }),
            ...(watch("type") === "DB_ADJ" && {
              billId: pickedRequest?.id ?? null,
              invoiceId: null,
            }),
          },
        },
      });
      setId(data_1?.data?.saveAdjustment?.id);
      setDisabled((prev) => ({
        ...prev,
        formEdit: true,
      }));
      pushUrl(
        props,
        `/admin/${pathname}/${data_1?.data?.saveAdjustment?.id}/edit`
      );
      enqueueSnackbar(t("saveSuccessful"), {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        TransitionComponent: Collapse,
      });
    } catch ({ graphQLErrors }) {
      console.log(graphQLErrors);
      if (graphQLErrors?.[0]?.extensions.category === "validation") {
        setValidationError(graphQLErrors, setError);
      } else {
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      }
    }
  };

  const clearPayeeFeild = () => {
    setValue("customerId", "");
    setValue("vendorId", "");
    setValue("glAccountId", "");
    setValue("agencyId", "");
    setValue("employeeId", "");
    setValue("customAgentId", "");
    setValue("carrierId", "");
    setPickedRequest(null);
    setValue(dynamicTextFiled?.name, "");
    setValue("currencyId", "");
  };
  const clearCode = () => {
    setPickedRequest(null);
    setValue(dynamicTextFiled?.name, "");
  };
  const openPickedRequestDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: null,
      content: (
        <CustomList
          setSelected={(obj) => {
            setSelect(obj);
          }}
          filters={billFilters}
          selected={pickedRequest}
          pathType={watch("type") === "CR_ADJ" ? "Invoice" : "Bill"}
          done={() => {
            setDialogDetails((prev) => ({
              ...prev,
              state: false,
            }));
          }}
        />
      ),
      confirmAction: true,
      function: null,
    }));
  };
  const openApproveDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: t("approveAdjustment"),
      content: t("approveAdjustmentConfirmationMessage"),
      confirmAction: true,
      function: approveAdjustment,
    }));
  };
  const openGlApproveDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: t("glApprove"),
      content: t("glApproveRecordMessage"),
      confirmAction: true,
      function: glApprove,
    }));
  };

  const body = (
    <Paper spacing={2} component={"form"} onSubmit={handleSubmit(onSubmit)}>
      <Stack sx={{ position: "relative" }}>
        {disabled.formEdit && (
          <Box
            className={classesForm.overlay}
            sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
          ></Box>
        )}
        <Grid
          container
          spacing={2}
          className={clsx(classesForm.spacing)}
          sx={{ p: 1 }}
        >
          <Grid xs={12} sm={3} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"code"}
              label={t("code")}
            />
          </Grid>
          <Grid xs={12} sm={3} alignItems="flex-start">
            <MUIDate
              name="adjustmentDate"
              label={t("adjustmentDate")}
              rules={{ required: t("fieldIsRequired") }}
              control={control}
              value={date.adjustmentDate}
              defaultValue={date.adjustmentDate}
              onChange={(date) => {
                setDate((prev) => ({ ...prev, adjustmentDate: date }));
                setPickedRequest(null);
                setValue(dynamicTextFiled?.name, "");
              }}
              onError={(resone, value) => {
                setError("adjustmentDate", { message: resone });
              }}
            />
          </Grid>
          <Grid sm={3} xs={12}>
            <ListBranches
              control={control}
              errors={errors}
              name={"branchId"}
              rules={{ required: t("fieldIsRequired") }}
              defaultValue={autocompleteValues.branch}
            />
          </Grid>
          <Grid sm={3} xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              parseData={(data) => parseData(data)}
              skip={!watch("branchId")}
              name={"transactionTypeId"}
              label={t("transactionType")}
              rules={{ required: t("fieldIsRequired") }}
              selectFirst={true}
              query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
              variables={{
                input: {
                  branchId: {
                    value: watch("branchId"),
                    includeNull: true,
                  },
                  type: "ADJST",
                },
              }}
              defaultValue={autocompleteValues.transactionType}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <CustomAutocomplete
              valueKey="code"
              control={control}
              errors={errors}
              name={"accountType"}
              label={t("accountType")}
              parseData={(data) => parseData(data)}
              rules={{ required: t("fieldIsRequired") }}
              variables={{ input: { code: "FR_ACCOUNT_TYPE" } }}
              query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
              defaultValue={autocompleteValues.accountType}
              onChangeValue={(e) => {
                clearPayeeFeild();
              }}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={Boolean(!watch("accountType"))}
              name={dynamicAutocompleteProps.name}
              label={dynamicAutocompleteProps.label}
              parseData={(data) => parseData(data)}
              query={dynamicAutocompleteProps.query}
              defaultValue={dynamicAutocompleteProps.defaultValue}
              rules={{ required: t("fieldIsRequired") }}
              variables={{
                input: dynamicAutocompleteProps?.input,
              }}
              onChangeValue={(e) => {
                setPickedRequest(null);
                setValue(dynamicTextFiled?.name, "");
                setValue("currencyId", "");
              }}
            />
          </Grid>

          <Grid xs={12} sm={6} alignItems="flex-start">
            <CustomAutocomplete
              valueKey="code"
              control={control}
              errors={errors}
              name={"type"}
              label={t("type")}
              parseData={(data) => parseData(data)}
              rules={{ required: t("fieldIsRequired") }}
              variables={{ input: { code: "ACCOUNT_ADJUSTENTS" } }}
              query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
              defaultValue={autocompleteValues.type}
              onChangeValue={(e) => {
                setPickedRequest(null);
                setValue(dynamicTextFiled?.name, "");
                setValue("currencyId", "");
              }}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <Stack direction={"row"}>
              <ControlMUItextField
                control={control}
                errors={errors}
                name={dynamicTextFiled?.name}
                readOnly={true}
                label={t("documentCode")}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => clearCode()} size="large">
                      <Clear />
                    </IconButton>
                  ),
                }}
              />

              <IconButton
                onClick={() => openPickedRequestDialog()}
                size="large"
                disabled={Boolean(
                  !watch("type") || !watch(dynamicAutocompleteProps.name)
                )}
              >
                <AddCircleOutline />
              </IconButton>
            </Stack>
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={Boolean(pickedRequest)}
              name={"currencyId"}
              label={t("currency")}
              parseData={(data) => parseData(data)}
              query={LIST_CURRENCIES_DROPDOWN.query}
              defaultValue={autocompleteValues.currency}
              rules={{ required: t("fieldIsRequired") }}
              onChangeValue={(e) => {
                setValue("exchangeRate", "");
              }}
            />
          </Grid>
          <Grid xs={12} sm={4} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              type="number"
              name={"exchangeRate"}
              label={t("exchangeRate")}
              rules={{ required: t("fieldIsRequired") }}
              readOnly={
                Boolean(pickedRequest) || !exchangeRate?.updatable
                  ? true
                  : false
              }
            />
          </Grid>
          <Grid xs={12} sm={4} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              type="number"
              name={"amount"}
              label={t("amount")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"remarks"}
              label={t("notes")}
              rows={2}
            />
          </Grid>
        </Grid>
      </Stack>
      <Grid sm={12} xs={12} container justifyContent="flex-end">
        {!disabled.formEdit && (
          <CustomButton
            customcolor={theme.palette.success.main}
            type="submit"
            className={classesForm.button}
            disabled={saveLoading}
            variant="contained"
            loading={saveLoading}
            startIcon={!saveLoading && <Done />}
          >
            {!saveLoading && t("save")}
          </CustomButton>
        )}

        {disabled.formEdit && (
          <Button
            disabled={Boolean(saveLoading || adjustmentData?.approved)}
            className={classesForm.button}
            variant="contained"
            onClick={openApproveDialog}
            name="approved"
            startIcon={<DoneAll />}
          >
            {t("approve")}
          </Button>
        )}
        {adjustmentData?.approved && (
          <Button
            disabled={Boolean(adjustmentData?.glApproved)}
            className={classesForm.glApprovedButton}
            variant="contained"
            onClick={openGlApproveDialog}
            name="glApproved"
            startIcon={<AttachMoney />}
          >
            {t("glApprove")}
          </Button>
        )}
      </Grid>
    </Paper>
  );

  let DOM;
  if (id) {
    DOM = data ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }
  return updateLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data && id ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={pathURL} />
      {dialogDetails.state && (
        <CustomDialog
          styleContent={
            dialogDetails.title == null && { overflow: "hidden", padding: 0 }
          }
          title={dialogDetails.title}
          fullWidth
          maxWidth={dialogDetails.title == null ? "md" : "xs"}
          onClose={closeConfirmationDialog}
          content={dialogDetails.content}
          open={dialogDetails.state}
          actions={
            dialogDetails.function !== null && (
              <>
                <Button color="primary" onClick={closeConfirmationDialog}>
                  {dialogDetails.confirmAction ? t("cancel") : t("close")}
                </Button>
                {dialogDetails.confirmAction && (
                  <Button
                    color="primary"
                    disabled={approveLoad || glApproveLoad}
                    onClick={dialogDetails.function}
                  >
                    {approveLoad || glApproveLoad ? (
                      <ButtonLoading />
                    ) : (
                      t("confirm")
                    )}
                  </Button>
                )}
              </>
            )
          }
        >
          {dialogDetails.function === null && ""}
        </CustomDialog>
      )}
      {id && disabled.formEdit ? (
        <Grid sm={12} xs={12}>
          <Alert
            severity="warning"
            action={
              !adjustmentData?.approved && (
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setDisabled((prev) => ({
                      ...prev,
                      formEdit: false,
                    }));
                  }}
                >
                  {t("update")}
                </Button>
              )
            }
          >
            {adjustmentData?.approved
              ? t("updateRecordsForbidden")
              : t("updateAdjustment")}
          </Alert>
        </Grid>
      ) : (
        ""
      )}
      <Stack spacing={2} p={2}>
        {DOM}
      </Stack>
    </RootStyleForm>
  );
};

export default AdjustmentForm;
