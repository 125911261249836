import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { TableBody, TableRow, Button, Icon } from "@mui/material";
import { Search } from "@mui/icons-material";
import { EXPOERT_VOUCHERS, LIST_VOUCHER_QUERY } from "./Graphql";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useWidth from "../../Hooks/useWidth";
import config from "../../config.json";
import Grid from "@mui/material/Unstable_Grid2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_BANK_ACCOUNT_DROPDOWN,
  LIST_BANKS_DROPDOWN,
  LIST_CURRENCIES_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_SAFES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import moment from "moment";
import MUIselect from "../HOC/MUI/MUIselect";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import formatMoney from "../../helpers/numbersDot";
import { useAccountType } from "../../Hooks/useAccountType";
import { RootStyleList, classes } from "../../GlobalStyles/ListStyle";
import { getEntityInfo } from "../../helpers/getEntityInfo";
import CustomExportExcel from "../HOC/CustomComponents/CustomExportExcel";

const VouchersList = (props) => {
  const { t } = useTranslation();
  const screenWidth = useWidth();
  const history = useHistory();
  const listType = props.match.params.type;

  const [refetch, setrefetch] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const [autocompleteValues, setAutocompleteValues] = useState({
    currency: null,
    type: null,
    branch: null,
    payeeType: null,
    customer: null,
    employee: null,
    vendor: null,
    customAgent: null,
    agency: null,
    carrier: null,
    glAccount: null,
    payeeTransactionType: null,
    safe: null,
    bankAccount: null,
    bank: null,
  });
  const [noData, setNoData] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(
    urlQuery["rowsPerPage"] ?? config.app.pageSize
  );
  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });
  const [date, setvoucherDate] = useState([
    urlQuery["fromDate"] ? urlQuery["fromDate"] : null,
    urlQuery["toDate"] ? urlQuery["toDate"] : null,
  ]);

  const fromDate = moment(date[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(date[1]).locale("en").format("YYYY-MM-DD");

  let pathname = "vouchers";

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const [openExport, setOpenExport] = useState(false);
  const payeeType = watch("payeeType");
  const dynamicAutocompleteProps = useAccountType(
    payeeType,
    autocompleteValues
  );
  const initSearch = () => {
    let searchParameters = {
      ...(date[0] && { fromDate }),
      ...(date[1] && { toDate }),
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      if (urlQuery.fromDate || urlQuery.toDate) {
        const date = {
          ...(urlQuery?.fromDate && { fromDate: urlQuery?.fromDate }),
          ...(urlQuery?.toDate && { toDate: urlQuery?.toDate }),
        };
        delete urlQuery["fromDate"];
        delete urlQuery["toDate"];
        urlQuery.date = date;
      }
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  const resetvoucherDate = () => {
    setvoucherDate([null, null]);
  };

  useEffect(() => {
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    urlQuery["approved"] !== undefined &&
      setValue("approved", urlQuery["approved"]);
    urlQuery["glApproved"] !== undefined &&
      setValue("glApproved", urlQuery["glApproved"]);
    setAutocompleteValues((prev) => ({
      ...(urlQuery["branchId"] && {
        branch: urlQuery["branchId"],
      }),
      ...(urlQuery["safeId"] && {
        safe: urlQuery["safeId"],
      }),
      ...(urlQuery["bankId"] && {
        bank: urlQuery["bankId"],
      }),
      ...(urlQuery["bankAccountId"] && {
        bankAccount: urlQuery["bankAccountId"],
      }),
      // ...(urlQuery["type"] && {
      //   type: urlQuery["type"],
      // }),
      ...(urlQuery["payeeType"] && {
        payeeType: urlQuery["payeeType"],
      }),
      ...(urlQuery["payeeTransactionType"] && {
        payeeTransactionType: urlQuery["payeeTransactionType"],
      }),
      ...(urlQuery["currencyId"] && {
        currency: urlQuery["currencyId"],
      }),
      ...(urlQuery["customerId"] && {
        customer: urlQuery["customerId"],
      }),
      ...(urlQuery["employeeId"] && {
        employee: urlQuery["employeeId"],
      }),
      ...(urlQuery["vendorId"] && {
        vendor: urlQuery["vendorId"],
      }),
      ...(urlQuery["customAgentId"] && {
        customAgent: urlQuery["customAgentId"],
      }),
      ...(urlQuery["agencyId"] && {
        agency: urlQuery["agencyId"],
      }),
      ...(urlQuery["carrierId"] && {
        carrier: urlQuery["carrierId"],
      }),
      ...(urlQuery["glAccountId"] && {
        glAccount: urlQuery["glAccountId"],
      }),
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: `/admin/${pathname}/${listType}`,
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    refetch ? setrefetch(false) : setrefetch(true);
    let handledData = {
      ...(date[0] && { fromDate }),
      ...(date[1] && { toDate }),

      code: data.code,
      glApproved: data.glApproved,
      approved: data.approved,
      vendorId: data.vendorId,
      customerId: data.customerId,
      customAgentId: data.customAgentId,
      employeeId: data.employeeId,
      agencyId: data.agencyId,
      carrierId: data.carrierId,
      glAccountId: data.glAccountId,
      branchId: data.branchId,
      safeId: data.safeId,
      bankId: data.bankId,
      bankAccountId: data.bankAccountId,
      currencyId: data.currencyId,
      payeeType: data.payeeType,
      payeeTransactionType: data.payeeTransactionType,

      page: 0,
    };
    data["approved"] === "" && delete handledData["approved"];
    data["glApproved"] === "" && delete handledData["glApproved"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });

    if (handledData.fromDate || handledData.toDate) {
      const date = {
        ...(handledData?.fromDate && { fromDate: handledData?.fromDate }),
        ...(handledData?.toDate && { toDate: handledData?.toDate }),
      };
      delete handledData["fromDate"];
      delete handledData["toDate"];
      handledData.date = date;
    }

    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    // for (const key in handledData) {
    //   if (handledData[key] === null) {
    //     delete handledData[key];
    //   }
    // }
  };

  const parseData = (data) => {
    return data;
  };
  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  const { data, loading } = useQuery(
    gql`
      ${LIST_VOUCHER_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          type: listType.toUpperCase(),
          approved: search.approved,
          glApproved: search.glApproved,
          ...(search.code && { code: search.code }),
          ...(search.date && {
            date: search.date,
          }),
          ...(search.invoiceDate && {
            invoiceDate: search.invoiceDate,
          }),
          ...(search.referenceDate && {
            referenceDate: search.referenceDate,
          }),
          ...(search.branchId && {
            branchId: search.branchId,
          }),
          ...(search.safeId && {
            safeId: search.safeId,
          }),
          ...(search.bankId && {
            bankId: search.bankId,
          }),
          ...(search.bankAccountId && {
            bankAccountId: search.bankAccountId,
          }),
          ...(search.currencyId && {
            currencyId: search.currencyId,
          }),

          ...(search.payeeType && {
            payeeType: search.payeeType,
          }),
          ...(search.payeeTransactionType && {
            payeeTransactionType: search.payeeTransactionType,
          }),
          // ...(search.type && {
          //   type: search.type,
          // }),
          ...(search.carrierId && {
            carrierId: search.carrierId,
          }),
          ...(search.agencyId && {
            agencyId: search.agencyId,
          }),
          ...(search.employeeId && {
            employeeId: search.employeeId,
          }),
          ...(search.customAgentId && {
            customAgentId: search.customAgentId,
          }),
          ...(search.customerId && {
            customerId: search.customerId,
          }),
          ...(search.vendorId && {
            vendorId: search.vendorId,
          }),
          ...(search.glAccountId && {
            glAccountId: search.glAccountId,
          }),
        },
      },
      onCompleted: () => {
        setNoData(false);
      },
    }
  );
  const filters = {
    type: listType.toUpperCase(),
    approved: search.approved,
    glApproved: search.glApproved,
    ...(search.code && { code: search.code }),
    ...(search.date && {
      date: search.date,
    }),
    ...(search.invoiceDate && {
      invoiceDate: search.invoiceDate,
    }),
    ...(search.referenceDate && {
      referenceDate: search.referenceDate,
    }),
    ...(search.branchId && {
      branchId: search.branchId,
    }),
    ...(search.safeId && {
      safeId: search.safeId,
    }),
    ...(search.bankId && {
      bankId: search.bankId,
    }),
    ...(search.bankAccountId && {
      bankAccountId: search.bankAccountId,
    }),
    ...(search.currencyId && {
      currencyId: search.currencyId,
    }),

    ...(search.payeeType && {
      payeeType: search.payeeType,
    }),
    ...(search.payeeTransactionType && {
      payeeTransactionType: search.payeeTransactionType,
    }),
    // ...(search.type && {
    //   type: search.type,
    // }),
    ...(search.carrierId && {
      carrierId: search.carrierId,
    }),
    ...(search.agencyId && {
      agencyId: search.agencyId,
    }),
    ...(search.employeeId && {
      employeeId: search.employeeId,
    }),
    ...(search.customAgentId && {
      customAgentId: search.customAgentId,
    }),
    ...(search.customerId && {
      customerId: search.customerId,
    }),
    ...(search.vendorId && {
      vendorId: search.vendorId,
    }),
    ...(search.glAccountId && {
      glAccountId: search.glAccountId,
    }),
  };
  const openExportDialog = () => {
    setOpenExport(true);
  };
  const fields = [
    { name: "CODE", label: t("code") },
    { name: "DATE", label: t("date") },
    { name: "BRANCH", label: t("branch") },
    { name: "PAYEE_TYPE", label: t("payeeType") },
    { name: "PAYEE_NAME", label: t("payeeName") },
    { name: "DOCUMENT_TYPE", label: t("documentType") },
    { name: "DOCUMENT_CODE", label: t("documentCode") },
    { name: "CURRENCY", label: t("currency") },
    { name: "AMOUNT", label: t("amount") },
    { name: "APPROVED", label: t("approved") },
    { name: "GL_APPROVED", label: t("glApproved") },
  ];
  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find((i) => i.code === id);
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const listVouchers = data?.[`listVouchers`]?.data;
  const icons = [
    {
      id: "filterList",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
    },
    {
      id: "add",
      action: () => pushUrl(props, `/admin/${pathname}/create/${listType}`),
      permission: `freight.adjustment.create`,
    },
    {
      id: "export",
      action: openExportDialog,
      disabled: !Boolean(data),
    },
  ];
  const documentTypes = ["invoice", "bill", "voucher", "adjustment"];
  const renderDocumentLink = (row) => {
    const documentType = documentTypes.find((type) => row?.[type]);
    if (!documentType) return <FixedTableCell></FixedTableCell>;

    return (
      <CellLink pathname={`/admin/${documentType}s/${row[documentType]?.id}`}>
        {row[documentType]?.code}
      </CellLink>
    );
  };
  return (
    <RootStyleList>
      {openExport && (
        <CustomExportExcel
          filters={filters}
          fields={fields}
          localStorageName={"export-vouchers"}
          ExportMutation={EXPOERT_VOUCHERS}
          mutationName={"exportVouchers"}
          openExport={openExport}
          setOpenExport={setOpenExport}
        />
      )}
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={listVouchers?.length === 0}
        loading={loading}
        type={listType}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  startText={t("date")}
                  value={date}
                  onChangeValue={(value) => setvoucherDate(value)}
                  resetDate={resetvoucherDate}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>

              <Grid item sm={12} alignItems="flex-start">
                <ListBranches
                  control={control}
                  errors={errors}
                  name={"branchId"}
                  defaultValue={autocompleteValues.branch}
                  skipDefaultBranch={true}
                  skip={noData}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  valueKey="code"
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"payeeType"}
                  label={t("payeeType")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["payeeType"],
                      "payeeType"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  variables={{ input: { code: "PAYEE_TYPE" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.payeeType}
                  onChangeValue={(e) => {
                    setValue(dynamicAutocompleteProps.name, "");
                  }}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  disabled={!watch("payeeType")}
                  name={dynamicAutocompleteProps.name}
                  label={dynamicAutocompleteProps.label}
                  parseData={(data) => parseData(data)}
                  query={dynamicAutocompleteProps.query}
                  variables={{
                    input: dynamicAutocompleteProps?.input,
                  }}
                  defaultValue={dynamicAutocompleteProps.defaultValue}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"approved"}
                  label={t("approved")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("false"), value: false },
                    { key: t("true"), value: true },
                  ]}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"glApproved"}
                  label={t("glApproved")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("false"), value: false },
                    { key: t("true"), value: true },
                  ]}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"currencyId"}
                  label={t("currency")}
                  parseData={(data) => parseData(data)}
                  query={LIST_CURRENCIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.currency}
                />
              </Grid>

              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  valueKey="code"
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"payeeTransactionType"}
                  label={t("documentType")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["payeeTransactionType"],
                      "payeeTransactionType"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  variables={{ input: { code: "PAYEE_TRX_TYPES" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.payeeTransactionType}
                  onChangeValue={(e) => {
                    setValue(dynamicAutocompleteProps.name, "");
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"safeId"}
                  label={t("safe")}
                  skip={noData}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["safeId"],
                      "safe"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_SAFES_DROPDOWN.query}
                  defaultValue={autocompleteValues.safe}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"bankAccountId"}
                  label={t("bankAccount")}
                  skip={noData}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["bankAccountId"],
                      "bankAccount"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_BANK_ACCOUNT_DROPDOWN.query}
                  defaultValue={autocompleteValues.bankAccount}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"bankId"}
                  label={t("bank")}
                  skip={noData}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["bankId"],
                      "bank"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_BANKS_DROPDOWN.query}
                  defaultValue={autocompleteValues.bank}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={[
          {
            name: "code",
          },
          {
            name: "date",
          },
          {
            name: "branch",
          },
          {
            name: "payeeType",
          },
          {
            name: "payeeName",
          },
          {
            name: "documentType",
          },
          {
            name: "documentCode",
          },
          {
            name: "currency",
          },
          {
            name: "amount",
          },
          {
            name: "approved",
          },
          {
            name: "glApproved",
          },
        ]}
        tableBody={
          <TableBody>
            {listVouchers &&
              listVouchers?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <CellLink pathname={`/admin/${pathname}/${row?.id}`}>
                      {row?.code}
                    </CellLink>
                    <FixedTableCell>{row?.voucherDate}</FixedTableCell>

                    <CellLink pathname={`/admin/branches/${row?.branch?.id}`}>
                      {row?.branch?.name}
                    </CellLink>
                    <FixedTableCell>{row?.payeeType?.name}</FixedTableCell>

                    {listType === "receipt" ? (
                      row?.bankAccount ? (
                        <FixedTableCell>
                          {row?.bankAccount?.name}
                        </FixedTableCell>
                      ) : (
                        <FixedTableCell>{row?.safe?.name}</FixedTableCell>
                      )
                    ) : getEntityInfo(row).entity === "glAccount" ? (
                      <FixedTableCell>{row?.glAccount?.name}</FixedTableCell>
                    ) : (
                      <CellLink
                        pathname={`/admin/${getEntityInfo(row).entityPath}/${
                          getEntityInfo(row).id || ""
                        }`}
                      >
                        {getEntityInfo(row).entity
                          ? row[getEntityInfo(row).entity]?.name || ""
                          : ""}
                      </CellLink>
                    )}

                    <FixedTableCell>
                      {row?.payeeTransactionType?.name}
                    </FixedTableCell>
                    {renderDocumentLink(row)}
                    <FixedTableCell>{row?.currency?.name}</FixedTableCell>
                    <FixedTableCell>{formatMoney(row?.amount)}</FixedTableCell>
                    <FixedTableCell>
                      {row?.approved ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.glApproved ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listVouchers?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listVouchers ? 0 : search.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </RootStyleList>
  );
};

export default VouchersList;
