import React, { useState } from "react";
import {
  Paper,
  Collapse,
  Stack,
  FormControlLabel,
  Switch,
  Box,
  Badge,
} from "@mui/material";
import { CUSTOMER_ID, FREIGHT_SETTING, SAVE_CUSTOMER } from "./Graphql";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import FormButton from "../CustomComponents/Buttons/FormButton";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { useHistory } from "react-router";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_COUNTRIES_DROPDOWN,
  LIST_CURRENCIES_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_STATES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import MUIDate from "../HOC/MUI/MUIDate";
import { dateFormat } from "../../helpers/dateFunctions";
import PriceModesForm from "./PriceModesForm";
import SalesForm from "./SalesForm";
import CustomerTables from "./CustomerTables";
import MuiPhone from "../HOC/MUI/MUIphoneNumber";
import getMobileData, {
  getFormatNumber,
  validNumber,
} from "../../helpers/asYouType";
import { CustomersProvider } from "./Context/CustomersContext";
import { Clear } from "@mui/icons-material";
import UploadFile from "../HOC/MUI/UploadFile";
import config from "../../config.json";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";

const CustomerForm = (props) => {
  const [pathURL, setPathURL] = useState(props.match.path);
  const [registerationDate, setRegisterationDate] = useState(null);
  const [idIssueDate, setIdIssueDate] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [customerId, setCustomerId] = useState(parseInt(props.match.params.id));
  const [activeCustomer, setActiveCustomer] = useState(true);
  const [dialogState, setDialogState] = useState({
    priceModes: false,
    sales: false,
  });
  const [updatePriceModes, setUpdatePriceModes] = useState(null);
  const [updateSales, setUpdateSales] = useState(null);
  const [autocompleteValues, setAutocompleteValues] = useState({
    idType: null,
    country: null,
    state: null,
    currency: null,
    category: null,
    type: null,
    glAccount: null,
    branch: null,
    identificationType: null,
    priceMode: null,
    shippingMode: null,
    employee: null,
    image: null,
  });
  const {
    handleSubmit,
    control,
    formState,
    setValue,
    setError,
    watch,
    getValues,
  } = useForm();

  const { errors } = formState;
  const [previewImage, setPreviewImage] = useState(null);

  const [saveCustomer, { loading }] = useMutation(
    gql`
      ${SAVE_CUSTOMER.query}
    `
  );
  const { data: setting } = useQuery(
    gql`
      ${FREIGHT_SETTING.query}
    `,

    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {},
    }
  );
  const parentId = setting?.freightSettings?.mainCustomerGLAccount?.id;

  const { data, loading: updateCustomerLoading } = useQuery(
    gql`
      ${CUSTOMER_ID.query}
    `,
    {
      skip: !customerId,
      variables: { id: customerId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const customerData = data.customer;
        setActiveCustomer(customerData.active);
        setPriceModes(customerData.priceModes);
        setSales(customerData.sales);
        setRegisterationDate(customerData.registerationDate);
        setIdIssueDate(customerData.idIssueDate);
        const customerParams = [
          "id",
          "code",
          "creditLimit",
          "commission",
          "name",
          "active",
          "address",
          "postalCode",
          "mailBoxNumber",
          // "email",
          "idNumber",
          "idIssueDate",
          "taxCardNumber",
          "registerationNumber",
          "registerationDate",
          "businessName",
          "businessActivity",
          "businessField",
          "responsibleName",
        ];
        const numbersObj = [
          {
            name: "mobile",
            codeName: "mobileCode",
            value: getMobileData(customerData.mobile),
          },
          {
            name: "phone",
            codeName: "phoneCode",
            value: getMobileData(customerData.phone),
          },
        ];
        customerParams.forEach((i) => {
          customerData[i] !== null && setValue(i, customerData[i]);
        });
        numbersObj.forEach((i) => {
          if (i.value !== null) {
            setValue(i.codeName, i.value.country.toLowerCase());
            setValue(i.name, i.value.nationalNumber);
          }
        });

        setAutocompleteValues((prev) => ({
          ...prev,
          idType: customerData.idType,
          country: customerData.country,
          state: customerData.state,
          currency: customerData.currency,
          category: customerData.customerCategory,
          type: customerData.type,
          identificationType: customerData.identificationType,
          glAccount: customerData.glAccount,
          branch: customerData.branch,
          priceMode: customerData.priceModes?.priceMode,
          shippingMode: customerData.priceModes?.shippingMode,
          employee: customerData.sales?.employee,
          image: customerData?.image?.path,
        }));
      },
    }
  );
  const priceModesArr = data?.customer?.priceModes ?? [];
  const salesArr = data?.customer?.sales ?? [];
  const [priceModes, setPriceModes] = useState(priceModesArr);
  const [sales, setSales] = useState(salesArr);

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;
    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };
  const handelActivate = (e) => {
    const active = e.target.checked;
    setActiveCustomer(active);
  };
  const selectDefaultValue = (data) => {
    return data;
  };
  const closeDialog = () => {
    setDialogState((prev) => ({
      ...prev,
      priceModes: false,
    }));
    setUpdatePriceModes(null);
    setAutocompleteValues((prev) => ({
      ...prev,
      shippingMode: null,
      priceMode: null,
    }));
  };
  const closeSalesDialog = () => {
    setDialogState((prev) => ({
      ...prev,
      sales: false,
    }));
    setUpdateSales(null);
    setAutocompleteValues((prev) => ({
      ...prev,
      employee: null,
    }));
  };

  const setClear = () => {
    setAutocompleteValues((prev) => ({
      ...prev,
      image: "",
    }));
    setPreviewImage(null);
    setValue("fileName", "");
    setValue("image", "null");
  };

  const onSubmit = (data) => {
    const checkMobiles = validNumber(data.mobile, data.mobileCode);
    const checkPhone = validNumber(data.phone, data.phoneCode);
    let names = [
      {
        name: "mobile",
        validate: checkMobiles.valid,
        message: checkMobiles.message,
      },
      {
        name: "phone",
        validate: checkPhone.valid,
        message: checkPhone.message,
      },
    ];
    if (!checkMobiles.valid || !checkPhone.valid) {
      names.map(
        (e) =>
          !e.validate &&
          setError(e.name, { type: "custom", message: t(e.message) })
      );
      return;
    }
    data.mobile = getFormatNumber(data.mobile, data.mobileCode);
    data.phone = getFormatNumber(data.phone, data.phoneCode);

    for (const key in data) {
      if (
        (data[key] === "" && key === "code") ||
        key === "phoneCode" ||
        key === "mobileCode"
      ) {
        delete data[key];
      }
      if (key === "fileName") {
        delete data[key];
      }
      if (data[key] === "" && key === "image") {
        delete data[key];
      }
      if (key === "image" && data[key] === "null") {
        data[key] = null;
      }
      if (data[key] === "") {
        data[key] = null;
        continue;
      }
      if (["creditLimit", "commission"].includes(key)) {
        data[key] = parseFloat(data[key]);
      }
    }
    const image = data?.image && data?.image[0];

    saveCustomer({
      variables: {
        input: {
          ...data,
          active: activeCustomer,
          ...(data.idIssueDate && {
            idIssueDate: dateFormat(data.idIssueDate),
          }),
          ...(data.registerationDate && {
            registerationDate: dateFormat(data.registerationDate),
          }),
          ...(customerId && { id: customerId }),
          ...(data.image && {
            image: { file: image },
          }),
        },
      },
    })
      .then((data) => {
        setCustomerId(data?.data?.saveCustomer?.id);
        const url = history.createHref({
          pathname: `/admin/customers/${data?.data?.saveCustomer?.id}/edit`,
        });
        true && setPathURL(url);
        true && windowReplaceUrl(url);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };
  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid
          justifyContent="end"
          sx={{ display: "flex", flexWrap: "wrap" }}
          xs={12}
        >
          <FormControlLabel
            checked={activeCustomer}
            control={<Switch color="primary" />}
            label={t("active")}
            labelPlacement="start"
            onChange={handelActivate}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"code"}
            label={t("code")}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"name"}
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid sm={3} xs={12}>
          <ListBranches
            control={control}
            errors={errors}
            name={"branchId"}
            defaultValue={autocompleteValues.branch}
            skipDefaultBranch={true}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"glAccountId"}
            label={t("glAccount")}
            parseData={(data) => selectDefaultValue(data)}
            query={LIST_GL_ACCOUNTS_DROPDOWN.query}
            variables={{
              input: {
                typeCode: "SUB",
                ...(watch("branchId") && {
                  branchId: {
                    value: watch("branchId"),
                    includeNull: true,
                  },
                  parentId: parentId,
                }),
              },
            }}
            onCompleted={(data) =>
              handelAutocompleteDefaultValue(
                data,
                props?.match?.params?.id,
                "glAccount"
              )
            }
            defaultValue={autocompleteValues.glAccount}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"responsibleName"}
            label={t("responsibleName")}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"businessName"}
            label={t("businessName")}
          />
        </Grid>{" "}
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"businessActivity"}
            label={t("businessActivity")}
          />
        </Grid>{" "}
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"businessField"}
            label={t("businessField")}
          />
        </Grid>{" "}
        <Grid xs={12} sm={3} alignItems="flex-start">
          <CustomAutocomplete
            valueKey="code"
            control={control}
            errors={errors}
            name={"type"}
            label={t("type")}
            onChangeValue={(e) => {
              setValue("commission", "");
            }}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => selectDefaultValue(data)}
            variables={{ input: { code: "CUSTOMERS_TYPES" } }}
            query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
            defaultValue={autocompleteValues.type}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"commission"}
            label={t("commission")}
            disabled={Boolean(watch("type") === "L")}
            rules={{
              required: watch("type") === "O" ? t("fieldIsRequired") : null,
            }}
            type="number"
          />
        </Grid>
        <Grid
          xs={autocompleteValues.image || previewImage ? 10 : 12}
          sm={autocompleteValues.image || previewImage ? 4 : 6}
          alignItems="flex-start"
        >
          <UploadFile
            control={control}
            setValue={setValue}
            name="image"
            icon={"add_photo_alternate"}
            label={t("uploadImage")}
            accept=".png,.jpg"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setPreviewImage(reader.result);
                };
                reader.readAsDataURL(file);
              } else {
                setPreviewImage(null);
              }
              setAutocompleteValues((prev) => ({
                ...prev,
                image: "",
              }));
            }}
          />
        </Grid>
        {(autocompleteValues.image || previewImage) && (
          <Grid xs={2} sm={2} textAlign="center">
            {previewImage && (
              <Badge
                style={{ padding: 0 }}
                onClick={setClear}
                badgeContent={<Clear sx={{ fontSize: "10px" }} />}
                color="error"
              >
                <img
                  src={previewImage}
                  alt="Preview"
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "1%",
                  }}
                  onClick={(event) => event.stopPropagation()}
                />
              </Badge>
            )}
            {autocompleteValues.image && (
              <Badge
                onClick={() => {
                  setClear();
                }}
                badgeContent={<Clear sx={{ fontSize: "10px" }} />}
                color="error"
              >
                <Box
                  sx={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "1%",
                  }}
                  component="img"
                  alt="signature"
                  src={getBackendUri(autocompleteValues.image)}
                  onClick={(event) => event.stopPropagation()}
                />
              </Badge>
            )}
          </Grid>
        )}
      </Paper>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid xs={12} sm={6} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"countryId"}
            label={t("country")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => selectDefaultValue(data)}
            query={LIST_COUNTRIES_DROPDOWN.query}
            onChangeValue={(e) => {
              setValue("stateId", "");
            }}
            defaultValue={autocompleteValues.country}
          />
        </Grid>
        <Grid xs={12} sm={6} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            disabled={!watch("countryId")}
            skip={!watch("countryId")}
            name={"stateId"}
            label={t("state")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => selectDefaultValue(data)}
            query={LIST_STATES_DROPDOWN.query}
            variables={{
              countryId: watch("countryId"),
            }}
            defaultValue={autocompleteValues.state}
          />
        </Grid>

        <Grid xs={12} sm={4} alignItems="flex-start">
          <MuiPhone
            setValue={setValue}
            codeValue={getValues().phoneCode}
            control={control}
            errors={errors}
            name={"phone"}
            selectName={"phoneCode"}
            label={t("phone")}
            rules={{
              required: t("fieldIsRequired"),
            }}
            onBlur={(e) => {
              if (Boolean(!getValues().mobile)) {
                setValue("mobile", getValues().phone, {
                  shouldDirty: true,
                });
                setValue("mobileCode", getValues().phoneCode, {
                  shouldDirty: true,
                });
              }
            }}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <MuiPhone
            setValue={setValue}
            codeValue={watch("mobileCode")}
            control={control}
            errors={errors}
            name={"mobile"}
            selectName={"mobileCode"}
            label={t("mobile")}
            rules={{
              required: t("fieldIsRequired"),
            }}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"address"}
            label={t("address")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <CustomAutocomplete
            valueKey="code"
            control={control}
            errors={errors}
            name={"idType"}
            label={t("idType")}
            parseData={(data) => selectDefaultValue(data)}
            variables={{ input: { code: "ID_TYPES" } }}
            query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
            defaultValue={autocompleteValues.idType}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"idNumber"}
            label={t("idNumber")}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <MUIDate
            name="idIssueDate"
            label={t("idIssueDate")}
            control={control}
            value={idIssueDate}
            defaultValue={""}
            onChange={(date) => setIdIssueDate(date)}
            onError={(resone, value) => {
              setError("idIssueDate", { message: resone });
            }}
          />
        </Grid>
      </Paper>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid xs={12} sm={4} alignItems="flex-start">
          <CustomAutocomplete
            valueKey="code"
            control={control}
            errors={errors}
            name={"identificationType"}
            label={t("identificationType")}
            parseData={(data) => selectDefaultValue(data)}
            variables={{ input: { code: "IDENTIFICATION_TYPES" } }}
            query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
            defaultValue={autocompleteValues.identificationType}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"taxCardNumber"}
            label={t("taxCardNumber")}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"registerationNumber"}
            label={t("registerationNumber")}
          />
        </Grid>
        <Grid sm={4} xs={12} alignItems="flex-start">
          <MUIDate
            name="registerationDate"
            label={t("registerationDate")}
            control={control}
            value={registerationDate}
            defaultValue={""}
            onChange={(date) => setRegisterationDate(date)}
            readOnly={false}
            onError={(resone, value) => {
              setError("registerationDate", { message: resone });
            }}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"postalCode"}
            label={t("postalCode")}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"mailBoxNumber"}
            label={t("mailBoxNumber")}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"currencyId"}
            label={t("currency")}
            parseData={(data) => selectDefaultValue(data)}
            query={LIST_CURRENCIES_DROPDOWN.query}
            defaultValue={autocompleteValues.currency}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"creditLimit"}
            label={t("creditLimit")}
            type="number"
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <CustomAutocomplete
            valueKey="code"
            control={control}
            errors={errors}
            name={"category"}
            label={t("category")}
            parseData={(data) => selectDefaultValue(data)}
            variables={{ input: { code: "FR_CUSTOMER_CATEGORIES" } }}
            query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
            defaultValue={autocompleteValues.category}
          />
        </Grid>
      </Paper>
      <Grid container justifyContent="flex-end">
        <FormButton disabled={loading}>
          {loading ? <ButtonLoading /> : t("save")}
        </FormButton>
      </Grid>
    </form>
  );
  let DOM;
  const value = {
    dialogState,
    customerId,
    updateSales,
    sales,
    setSales,
    closeSalesDialog,
    autocompleteValues,
    setAutocompleteValues,
    closeDialog,
    setPriceModes,
    priceModes,
    updatePriceModes,
    setUpdatePriceModes,
    setDialogState,
    setUpdateSales,
    loading,
  };
  if (customerId) {
    DOM = data ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return updateCustomerLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data && customerId ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={pathURL} />
      <Stack spacing={2} p={2}>
        {DOM}
      </Stack>
      <CustomersProvider value={value}>
        {dialogState.priceModes && <PriceModesForm />}
        {dialogState.sales && <SalesForm />}
        <Stack p={2}>
          <CustomerTables />
        </Stack>
      </CustomersProvider>
    </RootStyleForm>
  );
};

export default CustomerForm;
