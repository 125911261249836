import React, { useState } from "react";
import {
  Paper,
  Collapse,
  Stack,
  FormControlLabel,
  Switch,
  Badge,
  Box,
  Typography,
} from "@mui/material";
import { SAVE_EMPLOYEE, EMPLOYEE_ID, FREIGHT_SETTING } from "./Graphql";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import FormButton from "../CustomComponents/Buttons/FormButton";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { useHistory } from "react-router";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_BANKS_DROPDOWN,
  LIST_COUNTRIES_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_STATES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import MUIDate from "../HOC/MUI/MUIDate";
import { dateFormat } from "../../helpers/dateFunctions";
import UploadFile from "../HOC/MUI/UploadFile";
import { Clear } from "@mui/icons-material";
import config from "../../config.json";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import MuiPhone from "../HOC/MUI/MUIphoneNumber";
import getMobileData, {
  getFormatNumber,
  validNumber,
} from "../../helpers/asYouType";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";
const EmployeeForm = (props) => {
  const [pathURL, setPathURL] = useState(props.match.path);
  const [birthDate, setBirthDate] = useState(null);
  const [idExpiryDate, setIdExpiryDate] = useState(null);
  const [idIssueDate, setIdIssueDate] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [employeeId, setemployeeId] = useState(parseInt(props.match.params.id));
  const [activeEmployee, setActiveEmployee] = useState(true);
  const [previewImage, setPreviewImage] = useState(null);

  const [autocompleteValues, setAutocompleteValues] = useState({
    idType: null,
    country: null,
    state: null,
    socialStatus: null,
    gender: null,
    bank: null,
    glAccount: null,
    branch: null,
    image: null,
    nationalityId: null,
  });
  const {
    handleSubmit,
    control,
    formState,
    setValue,
    setError,
    watch,
    getValues,
  } = useForm();
  const { errors } = formState;

  const [saveEmployee, { loading: saveEmployeeLoading }] = useMutation(
    gql`
      ${SAVE_EMPLOYEE.query}
    `
  );
  const { data: setting } = useQuery(
    gql`
      ${FREIGHT_SETTING.query}
    `,

    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {},
    }
  );
  const parentId = setting?.freightSettings?.mainEmployeeGLAccount?.id;
  const { data, loading: updateEmployeeLoading } = useQuery(
    gql`
      ${EMPLOYEE_ID.query}
    `,

    {
      skip: !employeeId,
      variables: { id: employeeId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const employeeData = data.employee;
        setActiveEmployee(employeeData.active);
        setBirthDate(employeeData.birthDate);
        setIdExpiryDate(employeeData.idExpiryDate);
        setIdIssueDate(employeeData.idIssueDate);
        const employeeParams = [
          "id",
          "code",
          "name",
          "address",
          "birthDate",
          "birthPlace",
          "postalCode",
          "mailBoxNumber",
          "nationalityId",
          "idNumber",
          "idIssueDate",
          "idExpiryDate",
          "idIssuePlace",
          "graduationName",
          "professionName",
          "universityName",
          "graduationYear",
          "graduationGrade",
          "salary",
          "bankAccountNumber",
          "transferAmount",
          "active",
          "operation",
          "deliveryAgent",
          "salesMan",
          "administration",
        ];
        employeeParams.forEach((i) => {
          employeeData[i] !== null && setValue(i, employeeData[i]);
        });

        const numbersObj = [
          {
            name: "mobile",
            codeName: "mobileCode",
            value: getMobileData(employeeData.mobile),
          },
          {
            name: "phone",
            codeName: "phoneCode",
            value: getMobileData(employeeData.phone),
          },
        ];

        numbersObj.forEach((i) => {
          if (i.value !== null) {
            setValue(i.codeName, i.value.country.toLowerCase());
            setValue(i.name, i.value.nationalNumber);
          }
        });
        setAutocompleteValues((prev) => ({
          ...prev,
          idType: employeeData.idType,
          country: employeeData.country,
          state: employeeData.state,
          socialStatus: employeeData.socialStatus,
          gender: employeeData.gender,
          bank: employeeData.bank,
          glAccount: employeeData.glAccount,
          branch: employeeData.branch,
          nationalityId: employeeData.nation,
          image: employeeData?.image?.path,
        }));
      },
    }
  );
  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;
    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };
  const parseData = (data) => {
    return data;
  };

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActiveEmployee(active);
  };
  const selectDefaultValue = (data) => {
    return data;
  };
  const setClear = () => {
    setAutocompleteValues((prev) => ({
      ...prev,
      image: "",
    }));
    setPreviewImage(null);
    setValue("fileName", "");
    setValue("image", "null");
  };
  const onSubmit = (data) => {
    const checkMobiles = validNumber(
      data.mobile,
      data.mobileCode,
      "notRequired"
    );
    const checkPhone = validNumber(data.phone, data.phoneCode, "notRequired");
    let names = [
      {
        name: "mobile",
        validate: checkMobiles.valid,
        message: checkMobiles.message,
      },
      {
        name: "phone",
        validate: checkPhone.valid,
        message: checkPhone.message,
      },
    ];
    if (!checkMobiles.valid || !checkPhone.valid) {
      names.map(
        (e) =>
          !e.validate &&
          setError(e.name, { type: "custom", message: t(e.message) })
      );
      return;
    }
    data.mobile = data.mobile && getFormatNumber(data.mobile, data.mobileCode);
    data.phone = data.phone && getFormatNumber(data.phone, data.phoneCode);

    for (const key in data) {
      if (
        (data[key] === "" && key === "code") ||
        key === "phoneCode" ||
        key === "mobileCode"
      ) {
        delete data[key];
      }
      if (key === "fileName") {
        delete data[key];
      }
      if (data[key] === "" && key === "image") {
        delete data[key];
      }
      if (key === "image" && data[key] === "null") {
        data[key] = null;
      }
      if (data[key] === "") {
        data[key] = null;
        continue;
      }

      if (["graduationYear", "transferAmount", "salary"].includes(key)) {
        data[key] = parseFloat(data[key]);
      }
    }

    const image = data?.image && data?.image[0];
    saveEmployee({
      variables: {
        input: {
          ...data,
          active: activeEmployee,
          ...(data.idIssueDate && {
            idIssueDate: dateFormat(data.idIssueDate),
          }),
          ...(data.idExpiryDate && {
            idExpiryDate: dateFormat(data.idExpiryDate),
          }),
          ...(data.birthDate && {
            birthDate: dateFormat(data.birthDate),
          }),
          ...(employeeId && { id: employeeId }),
          ...(data.image && {
            image: { file: image },
          }),
        },
      },
    })
      .then((data) => {
        setemployeeId(data?.data?.saveEmployee?.id);
        true &&
          pushUrl(props, `/admin/employees/${data?.data?.saveEmployee?.id}`);
        const url = history.createHref({
          pathname: `/admin/employees/${data?.data?.saveEmployee?.id}/edit`,
        });
        false && setPathURL(url);
        false && windowReplaceUrl(url);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid
          justifyContent="end"
          sx={{ display: "flex", flexWrap: "wrap" }}
          xs={12}
        >
          <FormControlLabel
            checked={activeEmployee}
            control={<Switch color="primary" />}
            label={t("active")}
            labelPlacement="start"
            onChange={handelActivate}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"code"}
            label={t("code")}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"name"}
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid sm={3} xs={12}>
          <ListBranches
            control={control}
            errors={errors}
            name={"branchId"}
            defaultValue={autocompleteValues.branch}
            skipDefaultBranch={true}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"nationalityId"}
            label={t("nationalityId")}
            parseData={(data) => selectDefaultValue(data)}
            query={LIST_COUNTRIES_DROPDOWN.query}
            defaultValue={autocompleteValues.nationalityId}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            type="number"
            errors={errors}
            name={"salary"}
            label={t("salary")}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            type="number"
            errors={errors}
            name={"transferAmount"}
            label={t("transferAmount")}
          />
        </Grid>
        <Grid sm={3} xs={12} alignItems="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"bankId"}
            label={t("bank")}
            parseData={(data) => selectDefaultValue(data)}
            query={LIST_BANKS_DROPDOWN.query}
            defaultValue={autocompleteValues.bank}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            type="number"
            errors={errors}
            name={"bankAccountNumber"}
            label={t("bankAccountNumber")}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"glAccountId"}
            label={t("glAccount")}
            parseData={(data) => parseData(data)}
            query={LIST_GL_ACCOUNTS_DROPDOWN.query}
            variables={{
              input: {
                typeCode: "SUB",
                ...(watch("branchId") && {
                  branchId: {
                    value: watch("branchId"),
                    includeNull: true,
                  },
                  parentId: parentId,
                }),
              },
            }}
            defaultValue={autocompleteValues.glAccount}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <CustomAutocomplete
            valueKey="code"
            control={control}
            errors={errors}
            name={"idType"}
            label={t("idType")}
            parseData={(data) => selectDefaultValue(data)}
            variables={{ input: { code: "ID_TYPES" } }}
            query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
            defaultValue={autocompleteValues.idType}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"idNumber"}
            label={t("idNumber")}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"idIssuePlace"}
            label={t("issuePlace")}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <MUIDate
            name="idIssueDate"
            label={t("idIssueDate")}
            control={control}
            value={idIssueDate}
            defaultValue={""}
            onChange={(date) => setIdIssueDate(date)}
            onError={(resone, value) => {
              setError("deliveryDate", { message: resone });
            }}
          />
        </Grid>
        <Grid sm={3} xs={12} alignItems="flex-start">
          <MUIDate
            name="idExpiryDate"
            label={t("expiryDate")}
            control={control}
            value={idExpiryDate}
            defaultValue={""}
            onChange={(date) => setIdExpiryDate(date)}
            readOnly={false}
            onError={(resone, value) => {
              setError("deliveryDate", { message: resone });
            }}
          />
        </Grid>
        <Grid
          xs={autocompleteValues.image || previewImage ? 10 : 12}
          sm={autocompleteValues.image || previewImage ? 4 : 6}
          alignItems="flex-start"
        >
          <UploadFile
            control={control}
            setValue={setValue}
            name="image"
            icon={"add_photo_alternate"}
            label={t("uploadImage")}
            accept=".png,.jpg"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setPreviewImage(reader.result);
                };
                reader.readAsDataURL(file);
              } else {
                setPreviewImage(null);
              }
              setAutocompleteValues((prev) => ({
                ...prev,
                image: "",
              }));
            }}
          />
        </Grid>
        {(autocompleteValues.image || previewImage) && (
          <Grid xs={2} sm={2} textAlign="center">
            {previewImage && (
              <Badge
                style={{ padding: 0 }}
                onClick={setClear}
                badgeContent={<Clear sx={{ fontSize: "10px" }} />}
                color="error"
              >
                <img
                  src={previewImage}
                  alt="Preview"
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "1%",
                  }}
                  onClick={(event) => event.stopPropagation()}
                />
              </Badge>
            )}
            {autocompleteValues.image && (
              <Badge
                onClick={() => {
                  setClear();
                }}
                badgeContent={<Clear sx={{ fontSize: "10px" }} />}
                color="error"
              >
                <Box
                  sx={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "1%",
                  }}
                  component="img"
                  alt="signature"
                  src={getBackendUri(autocompleteValues.image)}
                  onClick={(event) => event.stopPropagation()}
                />
              </Badge>
            )}
          </Grid>
        )}
      </Paper>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid xs={12} sm={3} aligns="flex-start">
          <CustomAutocomplete
            valueKey="code"
            control={control}
            errors={errors}
            name={"gender"}
            label={t("gender")}
            parseData={(data) => selectDefaultValue(data)}
            variables={{ input: { code: "GENDER_TYPE" } }}
            query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
            defaultValue={autocompleteValues.gender}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <CustomAutocomplete
            valueKey="code"
            control={control}
            errors={errors}
            name={"socialStatus"}
            label={t("socialStatus")}
            parseData={(data) => selectDefaultValue(data)}
            variables={{ input: { code: "SOCIAL_STATUS" } }}
            query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
            defaultValue={autocompleteValues.socialStatus}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <MUIDate
            name="birthDate"
            label={t("birthDate")}
            control={control}
            value={birthDate}
            defaultValue={""}
            onChange={(date) => setBirthDate(date)}
            onError={(resone, value) => {
              setError("deliveryDate", { message: resone });
            }}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"birthPlace"}
            label={t("birthPlace")}
          />
        </Grid>
        <Grid xs={12} sm={3} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"countryId"}
            label={t("country")}
            parseData={(data) => selectDefaultValue(data)}
            query={LIST_COUNTRIES_DROPDOWN.query}
            onChangeValue={(e) => {
              setValue("stateId", "");
            }}
            defaultValue={autocompleteValues.country}
          />
        </Grid>
        <Grid xs={12} sm={3} aligns="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            disabled={!watch("countryId")}
            skip={!watch("countryId")}
            name={"stateId"}
            label={t("state")}
            parseData={(data) => selectDefaultValue(data)}
            query={LIST_STATES_DROPDOWN.query}
            variables={{
              countryId: watch("countryId"),
            }}
            defaultValue={autocompleteValues.state}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"address"}
            label={t("address")}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <MuiPhone
            setValue={setValue}
            codeValue={getValues().phoneCode}
            control={control}
            errors={errors}
            name={"phone"}
            selectName={"phoneCode"}
            label={t("phone")}
            onBlur={(e) => {
              if (Boolean(!getValues().mobile)) {
                setValue("mobile", getValues().phone, {
                  shouldDirty: true,
                });
                setValue("mobileCode", getValues().phoneCode, {
                  shouldDirty: true,
                });
              }
            }}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <MuiPhone
            setValue={setValue}
            codeValue={watch("mobileCode")}
            control={control}
            errors={errors}
            name={"mobile"}
            selectName={"mobileCode"}
            label={t("mobile")}
          />
        </Grid>
      </Paper>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"graduationName"}
            label={t("graduationName")}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"professionName"}
            label={t("professionName")}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"universityName"}
            label={t("universityName")}
          />
        </Grid>
        <Grid xs={12} sm={3} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            type="number"
            errors={errors}
            name={"graduationYear"}
            label={t("graduationYear")}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"graduationGrade"}
            label={t("graduationGrade")}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"postalCode"}
            label={t("postalCode")}
          />
        </Grid>
        <Grid xs={12} sm={4} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"mailBoxNumber"}
            label={t("mailBoxNumber")}
          />
        </Grid>
      </Paper>
      <Paper
        container
        component={Grid}
        spacing={2}
        className={clsx(classesForm.spacing)}
        sx={{ p: 1 }}
      >
        <Grid xs={12} sm={12} alignItems="flex-start">
          <Typography color={"text.primary"} variant="h6">
            {t("workNature")}
          </Typography>
        </Grid>

        <Grid xs={12} sm={6} alignItems="flex-start">
          <MuiSwitch
            edge="end"
            name="administration"
            label={t("administration")}
            control={control}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <MuiSwitch
            edge="end"
            name="operation"
            label={t("operation")}
            control={control}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <MuiSwitch
            edge="end"
            name="salesMan"
            label={t("salesMan")}
            control={control}
          />
        </Grid>
        <Grid xs={12} sm={6} alignItems="flex-start">
          <MuiSwitch
            edge="end"
            name="deliveryAgent"
            label={t("deliveryAgent")}
            control={control}
          />
        </Grid>
      </Paper>
      <Grid container justifyContent="flex-end" className={classesForm.spacing}>
        <FormButton disabled={saveEmployeeLoading}>
          {saveEmployeeLoading ? <ButtonLoading /> : t("save")}
        </FormButton>
      </Grid>
    </form>
  );
  let DOM;

  if (employeeId) {
    DOM = data ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return updateEmployeeLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data && employeeId ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={pathURL} />
      <Stack spacing={2} p={2}>
        {DOM}
      </Stack>
    </RootStyleForm>
  );
};

export default EmployeeForm;
