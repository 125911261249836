import React, { useState, Fragment, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { Grid, Box, Toolbar, Typography, Collapse } from "@mui/material";
import { gql, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import * as gqlb from "gql-query-builder";
import { useTranslation } from "react-i18next";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useForm } from "react-hook-form";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import FormButton from "../CustomComponents/Buttons/FormButton";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import { Globals } from "../HOC/Classes/Globals";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import {
  LIST_CURRENCIES_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { useHistory } from "react-router";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { RiUserUnfollowLine } from "react-icons/ri";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import GlAccountTable from "../HOC/CustomComponents/GlAccountTable";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import { useSnackbar } from "notistack";
const PREFIX = "FinanceStatement";

const classes = {
  divider: `${PREFIX}-divider`,
  ingButton: `${PREFIX}-ingButton`,
  mainSearch: `${PREFIX}-mainSearch`,
  table: `${PREFIX}-table`,
  toolbar: `${PREFIX}-toolbar`,
  tableContainer: `${PREFIX}-tableContainer`,
  balanceFont: `${PREFIX}-balanceFont`,
  fieldsContainer: `${PREFIX}-fieldsContainer`,
  balance: `${PREFIX}-balance`,
  errorBox: `${PREFIX}-errorBox`,
  marginTop: `${PREFIX}-marginTop`,
  errorIcon: `${PREFIX}-errorIcon`,
  main: `${PREFIX}-main`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.divider}`]: {
    minWidth: "93%",
  },

  [`& .${classes.ingButton}`]: {
    margin: theme.spacing(2, 1, 2),
    height: "3.5em",
    padding: "0 30px",
    width: "100%",
  },

  [`& .${classes.mainSearch}`]: {
    margin: theme.spacing(0),
    width: "100%",
  },

  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.toolbar}`]: {
    padding: theme.spacing(1.5, 0),
    borderBottom: "1px solid #ccd1d6",
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.tableContainer}`]: {
    position: "relative",
    borderRadius: 0,
    boxShadow: "none",
  },

  [`& .${classes.balanceFont}`]: {
    "& .MuiTableCell-body": {
      fontWeight: 600,
      fontSize: "1rem",
    },
  },

  [`& .${classes.fieldsContainer}`]: {
    padding: theme.spacing(1),
    textAlign: "center",
  },

  [`& .${classes.balance}`]: {
    textAlign: "center",
  },

  [`& .${classes.errorBox}`]: {
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    position: "absolute",
    textAlign: "center",
  },

  [`& .${classes.marginTop}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.errorIcon}`]: {
    fontSize: "7rem",
    color: theme.palette.error.main,
  },
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

//*********Table Function*********
export const EXPOERT_ACCOUNT_STATEMENT = gqlb.mutation({
  operation: "exportJournalEntryRecords",
  fields: [],
  variables: {
    filters: {
      type: "ListJournalEntryRecordsInput",
      required: true,
    },
  },
});

export const FINANCE = gqlb.query([
  {
    operation: "glAccount",
    fields: [
      {
        operation: "startBalance:balance",
        fields: [],
        variables: {
          startBalanceDate: { name: "date", type: "Date", required: true },
          currencyId: { type: "Int" },
          localCurrency: { type: "Boolean" },
        },
      },
      {
        operation: "endBalance:balance",
        fields: [],
        variables: {
          endBalanceDate: { name: "date", type: "Date", required: true },
          endDayEdge: {
            name: "dayEdge",
            type: "DayStartEnd",
          },
          currencyId: { type: "Int" },
          localCurrency: { type: "Boolean" },
        },
      },
    ],
    variables: {
      glAccountId: {
        name: "id",
        type: "Int",
        required: true,
      },
    },
  },

  {
    operation: "sumJournalEntryRecords",
    fields: ["credit", "debit"],
    variables: {
      input: {
        type: "ListJournalEntryRecordsInput",
      },
    },
  },
  {
    operation: "listJournalEntryRecords",
    fields: [
      {
        paginatorInfo: ["total"],
      },
      {
        data: [
          "id",
          "debit",
          "credit",
          "description",
          "accountedCredit",
          "accountedDebit",
          { entry: ["id", "date", "code"] },
        ],
      },
    ],
    variables: {
      input: {
        type: "ListJournalEntryRecordsInput",
      },
      first: {
        type: "Int",
      },
      page: {
        type: "Int",
      },
    },
  },
]);

const initFromDate = moment(new Date())
  .locale("en")
  .subtract(1, "month")
  .add(1, "day")
  .format("YYYY-MM-DD");
const initToDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

const FinanceStatement = (props) => {
  const initial = useRef(0);
  const totalDebit = useRef(0);
  const totalCredit = useRef(0);

  const { t } = useTranslation();
  const currency = Globals?.settings?.localCurrency?.name ?? "";
  const {
    formState: { errors },
    handleSubmit,
    control,
    setError,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      localCurrency: true,
    },
  });
  // const theme = useTheme();
  // const dir = theme.direction

  const urlQuery = urlParameters(window.location.search);

  const initDateRange = [
    urlQuery["startDate"] ? urlQuery["startDate"] : initFromDate,
    urlQuery["endDate"] ? urlQuery["endDate"] : initToDate,
  ];
  const [dateRange, setDateRange] = useState(initDateRange);
  const [autocompleteValues, setAutocompleteValues] = useState({
    glAccount: null,
    currency: null,
  });
  const [SelectedCurrency, setSelectedCurrency] = useState({
    selected: null,
    currency: null,
    watchCurrency: null,
  });
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const noGlAccount =
    Globals?.user?.account && !Globals?.user?.account?.glAccount?.id;

  const userGlAccountId =
    Globals?.user?.account?.glAccount?.id ?? search?.["glAccountId"];

  const glAccountListPermission = Globals.user.hasPermission(
    "accounting.gl_account.list"
  );
  const startDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const endDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
  useEffect(() => {
    const initCurrency = urlQuery["localCurrency"];
    const initCurrencyID = urlQuery["currencyId"];
    if (initCurrency !== undefined) {
      setValue("localCurrency", initCurrency);
    }
    if (!glAccountListPermission) {
      setSearch({
        fromDate: startDate,
        startDate: startDate,
        toDate: endDate,
        endDate: endDate,
        renderState: true,
        localCurrency: initCurrency ?? true,
        ...(initCurrencyID && { currencyId: initCurrencyID }),
      });
    }
    if (props.match?.params?.id) {
      setSearch({
        fromDate: startDate,
        startDate: startDate,
        toDate: endDate,
        endDate: endDate,
        renderState: true,
        localCurrency: initCurrency ?? true,
        glAccountId: parseInt(props.match.params.id),
        ...(initCurrencyID && { currencyId: initCurrencyID }),

        // currencyId: parseInt(),
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();
  // const pushUrlSearch = (param, pathname, render) => {
  //   const queryParams = [];
  //   for (const i in param) {
  //     encodeURIComponent(param[i]) &&
  //       queryParams.push(
  //         encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
  //       );
  //   }
  //   const queryString = queryParams.join("&");

  //   const url = history.createHref({
  //     pathname,
  //     search: "?" + queryString,
  //   });
  //   render ? pushUrl(props, url) : windowUrl(url);
  //   //this will not effect on history.location.search
  // };

  const searchParams = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    return queryString;
  };

  const pushUrlSearch = (param, pathname, render) => {
    const params = searchParams(param);
    const url = history.createHref({
      pathname,
      search: "?" + params,
    });
    render ? pushUrl(props, url) : windowUrl(url);
  };

  const { data, loading } = useQuery(
    gql`
      ${FINANCE.query}
    `,
    {
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          fromDate: search?.["fromDate"],
          toDate: search?.["toDate"],
          ...(glAccountListPermission && {
            glAccountId: search?.["glAccountId"],
          }),
          approved: true,
          localCurrency: search?.localCurrency,
          ...(!search?.localCurrency &&
            search?.currencyId && {
              currencyId: parseInt(search?.currencyId),
            }),
        },
        glAccountId: userGlAccountId,
        startBalanceDate: search?.["startDate"],
        endBalanceDate: search?.["endDate"],
        localCurrency: search?.localCurrency,
        ...(!search?.localCurrency &&
          search?.currencyId && {
            currencyId: parseInt(search?.currencyId),
          }),
        endDayEdge: "END_OF_DAY",
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      notifyOnNetworkStatusChange: !search?.["renderState"],
      skip: !search || !userGlAccountId,
      onError: ({ graphQLErrors }) => {
        console.log(graphQLErrors);
        setValidationError(graphQLErrors, setError);
      },
      onCompleted: (data) => {
        setSelectedCurrency((prev) => ({
          ...prev,

          watchCurrency: watch("localCurrency"),
        }));
        if (initial.current === 0)
          initial.current = data?.glAccount?.startBalance;

        const records = data?.listJournalEntryRecords?.data.map((row) => {
          const journal = { ...row };
          if (watch("localCurrency")) {
            totalCredit.current = totalCredit.current + journal.accountedCredit;
            totalDebit.current = totalDebit.current + journal.accountedDebit;
            const balance =
              journal.accountedCredit -
              journal.accountedDebit +
              initial.current;
            if (journal.accountedCredit !== 0) {
              initial.current = journal.accountedCredit + initial.current;
            } else if (journal.accountedDebit !== 0) {
              initial.current = -journal.accountedDebit + initial.current;
            } else if (balance === 0) {
              initial.current = 0;
            }
            if (balance <= 0) {
              journal["totalDebit"] = Math.abs(balance);
              journal["totalCredit"] = 0;
            } else if (balance >= 0) {
              journal["totalCredit"] = Math.abs(balance);
              journal["totalDebit"] = 0;
            }
          } else {
            totalCredit.current = totalCredit.current + journal.credit;
            totalDebit.current = totalDebit.current + journal.debit;
            const balance = journal.credit - journal.debit + initial.current;
            if (journal.credit !== 0) {
              initial.current = journal.credit + initial.current;
            } else if (journal.debit !== 0) {
              initial.current = -journal.debit + initial.current;
            } else if (balance === 0) {
              initial.current = 0;
            }
            if (balance <= 0) {
              journal["totalDebit"] = Math.abs(balance);
              journal["totalCredit"] = 0;
            } else if (balance >= 0) {
              journal["totalCredit"] = Math.abs(balance);
              journal["totalDebit"] = 0;
            }
          }

          return journal;
        });

        let allRecords = [...tableData, ...records].reduce((acc, current) => {
          const x = acc.find((item) => item.id === current.id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        setTableData(allRecords);
      },
    }
  );
  const parseData = (data) => {
    return data;
  };
  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
    fieldName === "currency" &&
      setSelectedCurrency((prev) => ({
        ...prev,
        currency: defaultValue,
        selected: defaultValue,
      }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    totalCredit.current = 0;
    totalDebit.current = 0;
    initial.current = 0;

    setTableData([]);
  };
  const onSubmit = (data) => {
    const glAccount = data.glAccountId || "";
    const localCurrency = data.localCurrency;
    const currencyId = data.currencyId;
    pushUrlSearch(
      { startDate, endDate, localCurrency, ...(currencyId && { currencyId }) },
      `/admin/finance/statement/sub/${glAccount}`
    );
    totalCredit.current = 0;
    totalDebit.current = 0;
    initial.current = 0;
    setTableData([]);
    setPage(0);
    delete data["branchId"];
    data["fromDate"] = startDate;
    data["toDate"] = endDate;
    data["startDate"] = startDate;
    data["endDate"] = endDate;
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }
    setSelectedCurrency((prev) => ({
      ...prev,
      currency: SelectedCurrency?.selected,
      watchCurrency: watch("localCurrency"),
    }));

    setSearch({ ...data, renderState: !search?.["renderState"] });
  };

  const emptyInoiceList = data?.listJournalEntryRecords?.data.length === 0;
  // const queryInputs = {
  //   fromDate: search?.fromDate ?? search,
  //   toDate: search?.toDate ?? search,
  //   glAccountId: userGlAccountId,
  //   customer: noGlAccount === null ? false : true,
  //   // localCurrency: search?.localCurrency ?? null,
  //   // currencyId: parseInt(search?.currencyId),
  // };

  // const onPrint = (param) => {
  //   const params = searchParams(queryInputs);
  //   const domain = `${window.location.origin}`;

  //   window.open(`${domain}${param}?${params}`);
  // };

  // let getBackendUri = (imgPath) => {
  //   const domain = config.backend.domain
  //     ? config.backend.domain
  //     : window.location.hostname;

  //   return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  // };
  const { enqueueSnackbar } = useSnackbar();
  const [ExportAccountStatements] = useMutation(
    gql`
      ${EXPOERT_ACCOUNT_STATEMENT.query}
    `,
    {
      onCompleted: (data) => {
        if (data.exportJournalEntryRecords) {
          enqueueSnackbar(t("successExport"), {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        } else {
          enqueueSnackbar(t("errorExport"), {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const handelExportAccountStatement = () => {
    ExportAccountStatements({
      variables: {
        filters: {
          fromDate: search.fromDate,
          toDate: search.toDate,
          glAccountId: search.glAccountId,
          approved: true,
          localCurrency: search?.localCurrency,
          ...(!search?.localCurrency &&
            search?.currencyId && {
              currencyId: parseInt(search?.currencyId),
            }),
        },
      },
    });
  };

  const icons = [
    {
      id: "export",
      action: handelExportAccountStatement,
      disabled: !Boolean(data) || Boolean(emptyInoiceList) || noGlAccount,
    },
    // {
    //   id: "print",
    //   title: "print",
    //   action: () => onPrint("/finance/statement/sub"),
    //   icon: Print,
    //   disabled: !Boolean(data) || Boolean(emptyInoiceList) || noGlAccount,
    // },
  ];

  return (
    <Root>
      {noGlAccount ? (
        <Box className={classes.errorBox}>
          <RiUserUnfollowLine className={classes.errorIcon} />

          <Typography variant="h6" className={classes.marginTop}>
            {t("thereAreNoGlAccount")}
          </Typography>
        </Box>
      ) : (
        <Grid
          container
          justifyContent="center"
          className={clsx(classes.mainSearch)}
        >
          {/* *******Table******* */}
          <Grid container item className={classes.table}>
            <TitleAppBar path={props.match.path}>
              <LongMenu icons={icons} />
            </TitleAppBar>
            <Toolbar className={classes.toolbar} variant="regular">
              <Grid
                container
                item
                component={"form"}
                alignItems="center"
                justifyContent="flex-start"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  className={classes.fieldsContainer}
                >
                  <MUIDateRangeCustom
                    value={dateRange}
                    onChangeValue={(value) => setDateRange(value)}
                  />
                </Grid>
                {glAccountListPermission && (
                  <Fragment>
                    <Grid
                      item
                      sm={6}
                      md={4}
                      xs={12}
                      className={classes.fieldsContainer}
                    >
                      <ListBranches
                        label={t("branch")}
                        control={control}
                        errors={errors}
                        name={"branchId"}
                        onChangeValue={() => {
                          setValue("glAccountId", "");
                        }}
                        skipDefaultBranch={true}
                      />
                    </Grid>

                    <Grid
                      item
                      sm={6}
                      md={4}
                      xs={12}
                      className={classes.fieldsContainer}
                    >
                      <CustomAutocomplete
                        control={control}
                        errors={errors}
                        rules={{ required: t("fieldIsRequired") }}
                        name={"glAccountId"}
                        label={t("glAccount")}
                        parseData={(data) => parseData(data)}
                        query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                        variables={{
                          input: {
                            typeCode: "SUB",
                            ...(watch("branchId") && {
                              branchId: {
                                value: watch("branchId"),
                                includeNull: true,
                              },
                            }),
                          },
                        }}
                        onCompleted={(data) =>
                          handelAutocompleteDefaultValue(
                            data,
                            props?.match?.params?.id,
                            "glAccount"
                          )
                        }
                        defaultValue={autocompleteValues.glAccount}
                      />
                    </Grid>
                  </Fragment>
                )}
                <Grid
                  item
                  sm={6}
                  md={4}
                  xs={12}
                  p={1}
                  // className={classes.fieldsContainer}
                >
                  <Box>
                    <MuiSwitch
                      edge="end"
                      name="localCurrency"
                      label={t("localCurrency")}
                      control={control}
                      onChange={(e) => {
                        setValue("currencyId", "");
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={6}
                  md={4}
                  xs={12}
                  className={classes.fieldsContainer}
                >
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    disabled={watch("localCurrency")}
                    skip={watch("localCurrency")}
                    name={"currencyId"}
                    label={t("currency")}
                    // rules={
                    //   !watch("localCurrency") && {
                    //     required: {
                    //       message: "this field is required",
                    //     },
                    //   }
                    // }
                    onChangeValue={(e) => {
                      setSelectedCurrency((prev) => ({
                        ...prev,
                        selected: e,
                      }));
                    }}
                    parseData={(data) => parseData(data)}
                    query={LIST_CURRENCIES_DROPDOWN.query}
                    onCompleted={(data) => {
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["currencyId"],
                        "currency"
                      );
                    }}
                    defaultValue={autocompleteValues.currency}
                  />
                </Grid>
                <Grid
                  item
                  // sm={6}
                  // md={4}
                  xs={glAccountListPermission ? 4 : 12}
                  p={1}
                  sx={{ textAlign: "end" }}
                  // className={classes.fieldsContainer}
                >
                  <FormButton disabled={loading}>{t("search")}</FormButton>
                </Grid>
              </Grid>
            </Toolbar>
            {console.log(SelectedCurrency?.watchCurrency)}
            {search && loading ? (
              // <Grid container item justifyContent="center" alignItems="center">
              //   <Box textAlign="center" marginTop="50px" marginBottom="50px">
              //     <FullScreenLoading />
              //   </Box>
              // </Grid>

              <StyledLoading
                container
                item
                justifyContent="center"
                className={classes.main}
              >
                <FullScreenLoading height={"100%"} />
              </StyledLoading>
            ) : (
              data && (
                <GlAccountTable
                  page={page}
                  currency={
                    SelectedCurrency?.watchCurrency
                      ? currency
                      : SelectedCurrency?.currency?.name
                  }
                  classess={classes}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  tableData={tableData}
                  data={data}
                  count={data?.listJournalEntryRecords?.paginatorInfo?.total}
                  finance={true}
                  sub={SelectedCurrency?.watchCurrency}
                />
              )
            )}
          </Grid>
        </Grid>
      )}
    </Root>
  );
};
export default FinanceStatement;
