import * as gqlb from "gql-query-builder";
const fields = [
  "id",
  "code",
  "scheduledDepartureDate",
  "actualDepartureDate",
  "scheduledArrivalDate",
  "actualArrivalDate",
  "shipmentDate",
  "consigneeName",
  "consigneeAddress",
  "consigneePostalCode",
  "consigneeMobile",
  "shipperName",
  "shipperAddress",
  "shipperPostalCode",
  "shipperMobile",
  // "hasPackages",
  {
    consigneeState: ["id", "name", "code"],
  },
  {
    shipperState: ["id", "name", "code"],
  },

  {
    branch: ["id", "name", "code"],
  },
  {
    shippingDirection: ["id", "name", "code"],
  },
  {
    shippingMode: ["id", "name", "code"],
  },
  {
    loadingMode: ["id", "name", "code"],
  },
  {
    originCountry: ["id", "name", "code"],
  },
  {
    originPort: ["id", "name", "code"],
  },
  {
    destinationCountry: ["id", "name", "code"],
  },
  {
    destinationPort: ["id", "name", "code"],
  },
  {
    status: ["id", "name", "code"],
  },
  {
    originAgency: ["id", "code", "name"],
  },
  {
    destinationAgency: ["id", "code", "name"],
  },
];
const commonViewForm = [
  { incoTerm: ["id", "code", "name"] },
  { currency: ["id", "code", "name"] },
  { carrier: ["id", "code", "name"] },
  { transactionType: ["id", "code", "name"] },
  "flightNumber",
  "date",
  "ticketNumber",
  "truckType",
  "plateNumber",
  "mawbNumber",
  "bookingNumber",
  "remarks",
  "vesselName",
];
const BILL_Invoice = [
  "id",
  "code",
  "date",
  "totalAmount",
  "exchangeRate",
  "glApproved",
  "approved",
  {
    branch: ["id", "code", "name"],
  },
  {
    lines: [
      "id",
      "chargePrice",
      "chargeableUnits",
      "lineAmount",
      "taxAmount",
      "totalAmount",
      "deletable",

      {
        taxes: [
          "id",
          "rate",
          "value",
          {
            tax: ["id", "code", "name"],
          },
        ],
      },
      {
        priceMode: ["id", "code", "name"],
      },
      {
        chargeElement: ["id", "code", "name"],
      },
    ],
  },

  {
    currency: ["id", "code", "name"],
  },

  {
    paymentType: ["id", "code", "name"],
  },
];
const containersFields = [
  {
    containers: [
      "id",
      "containerNumber",
      "packagesCount",
      { packageType: ["id", "name", "code"] },
    ],
  },
];
const PackageDetailsFields = [
  {
    requests: [
      "id",
      "code",
      "totalLoadedPackages",
      "totalUnloadedPackages",
      "detailsCount",
    ],
  },
];
export const LIST_SHIPMENTS = gqlb.query({
  operation: "listShipments",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: fields,
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListShipmentsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const SHIPMENT_ID = gqlb.query({
  operation: "shipment",
  fields: [...fields, ...commonViewForm, ...containersFields],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SHIPMENT_VIEW_TRACKING = gqlb.query({
  operation: "shipment",
  fields: [
    "id",
    "code",
    { status: ["id", "name", "code"] },
    "deletable",
    "editable",
    {
      shippingMode: ["code"],
    },
    {
      loadingMode: ["code"],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const SHIPMENT_VIEW_DESCRIPTION = gqlb.query({
  operation: "shipment",
  fields: [...fields, ...commonViewForm],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SHIPMENT_VIEW_TRANSACTIONS = gqlb.query({
  operation: "shipment",
  fields: [
    {
      transactions: [
        { shipmentStatus: ["id", "code", "name"] },
        { createdBy: ["id", "username"] },
        "date",
        "transactionDate",
        "totalPackages",
        {
          operation: [
            "id",
            "code",
            // "totalPackagesCount",
            { warehouse: ["id", "name"] },
          ],
        },
      ],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SHIPMENT_VIEW_CONTAINER = gqlb.query({
  operation: "shipment",
  fields: [...containersFields],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SHIPMENT_VIEW_PACKAGE_DETAILS = gqlb.query({
  operation: "shipment",
  fields: [...PackageDetailsFields],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const SHIPMENT_VIEW_BILL = (BILL_PERMISSION) =>
  gqlb.query({
    operation: "shipment",
    fields: [BILL_PERMISSION ? { bills: [...BILL_Invoice, "billDate"] } : ""],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  });
export const SHIPMENT_VIEW_INVOICE = (INVOICE_PERMISSION) =>
  gqlb.query({
    operation: "shipment",
    fields: [
      INVOICE_PERMISSION
        ? {
            invoices: [...BILL_Invoice, "invoiceDate"],
          }
        : "",
      "deletable",
      "editable",
    ],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  });
export const REQUEST_PACKAGE_DETAILS = gqlb.query([
  {
    operation: "request",
    fields: [
      {
        packages: [
          "id",
          {
            operation: `details`,
            fields: [
              {
                operation: `paginatorInfo`,
                fields: [
                  "count",
                  "currentPage",
                  "total",
                  "firstItem",
                  "hasMorePages",
                  "lastItem",
                  "lastPage",
                  "perPage",
                ],
                variables: {},
              },
              {
                operation: `data`,
                fields: [
                  "id",
                  "missing",
                  "damaged",
                  "barcode",
                  "pieceNumber",
                  { warehouse: ["id", "name", "code"] },
                  {
                    package: ["id", { request: ["detailsCount"] }],
                  },
                ],
                variables: {},
              },
            ],
            variables: {
              first: {
                type: "Int",
              },
              page: {
                type: "Int",
              },
            },
          },
        ],
      },
    ],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
]);

export const SAVE_SHIPMENT = gqlb.mutation({
  operation: "saveShipment",
  fields: ["id", "code"],
  variables: {
    input: {
      type: "ShipmentInput",
      required: true,
    },
  },
});
export const SAVE_SHIPMENT_CONTAINER = gqlb.mutation({
  operation: "saveShipmentContatiner",
  fields: ["id", "containerNumber", { packageType: ["id", "name", "code"] }],
  variables: {
    input: {
      type: "ShipmentContainerInput",
      required: true,
    },
  },
});

export const DELETE_SHIPMENT_CONTAINER = gqlb.mutation({
  operation: "deleteShipmentContainer",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
const VendorFields = [
  "id",
  "code",
  "name",
  "address",
  "postalCode",
  "mobile",
  { state: ["id", "name", "code"] },
];

export const AGENCY_ID = gqlb.query({
  operation: "agency",
  fields: VendorFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const CUSTOMER_ID = gqlb.query({
  operation: "customer",
  fields: [
    ...VendorFields,
    "commission",
    {
      priceModes: [
        "id",
        "date",
        { shippingMode: ["id", "code", "name"] },
        { priceMode: ["id", "code", "name"] },
      ],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const PACKAGE_ID = gqlb.query({
  operation: "packageType",
  fields: ["height", "length", "breadth", "volume"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const FREIGHT_SETTING = gqlb.query({
  operation: "freightSettings",
  fields: ["volumetricFactor", "createShipmentInPast"],
  variables: {},
});

export const SAVE_REQUEST_PACKAGE = gqlb.mutation({
  operation: "saveRequestPackage",
  fields: [
    "id",
    "code",
    "fragile",
    "height",
    "weight",
    "length",
    "breadth",
    "count",
    "totalWeight",
    "pieces",
    "volume",
    "volumetricWeight",
    "volumetricFactor",
    "chargeableUnits",
    "chargePrice",
    "chargeAmount",
    "description",
    "exchangeRate",
    "productPrice",
    { currency: ["id", "name", "code"] },
    { packageType: ["id", "name", "code"] },
    { priceMode: ["id", "name", "code"] },
    { originCountry: ["id", "name", "code"] },
    { warehouse: ["id", "name", "code"] },
    { product: ["id", "name", "code"] },

    {
      image: ["path"],
    },
    { commodities: ["count ", "id", { commodity: ["id", "name", "code"] }] },
  ],
  variables: {
    input: {
      type: "RequestPackageInput",
      required: true,
    },
  },
});
export const REQUEST_PACKAGE_ID = gqlb.query({
  operation: "requestPackage",
  fields: VendorFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const CALCULATE_FEES_QUERY = gqlb.query({
  operation: "calculateRequestPackageFees",
  fields: [
    "totalWeight",
    "volume",
    "volumetricWeight",
    "chargeAmount",
    "chargeableUnits",
    "chargePrice",
  ],
  variables: {
    input: {
      type: "CalculateRequestPackageFeesInput",
      required: true,
    },
  },
});

export const AVAILABLE_PRICE_LIST = gqlb.query({
  operation: "availablePriceList",
  fields: [
    {
      currency: ["id", "name", "code"],
    },
  ],
  variables: {
    input: {
      type: "AvailablePriceListInput",
      required: true,
    },
  },
});

export const CURRENCY_ID = gqlb.query({
  operation: "currency",
  fields: [{ exchangeRate: ["updatable", "rate", "id"] }],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const DELETE_REQUEST_PACKAGE = gqlb.mutation({
  operation: "deleteRequestPackage",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const LIST_PACKAGE_DETAILS = gqlb.query({
  operation: "listPackageDetails",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "missing",
        "damaged",
        "barcode",
        "pieceNumber",
        { warehouse: ["id", "name", "code"] },
        {
          package: ["id", { request: ["detailsCount"] }],
        },
        "loaded",
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListPackageDetailsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});
export const DELETE_SHIPMENT = gqlb.mutation({
  operation: "deleteShipment",
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const CALCULATE_COMMISSION_AMOUNT = gqlb.query({
  operation: "calculateCommissionAmount",
  fields: ["value"],
  variables: {
    input: {
      type: "CalculateCommissionAmountInput",
      required: true,
    },
  },
});

export const EXPOERT_SHIPMENTS = gqlb.mutation({
  operation: "exportShipments",
  fields: [],
  variables: {
    input: {
      type: "ExportShipmentsInput",
      required: true,
    },
  },
});

export const details = ({ type, operation }) => {
  const types = {
    description: {
      operation: `description: ${operation}`,
      fields: [...fields, ...commonViewForm],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
    transactions: {
      operation: "transactions: shipment",
      fields: [
        {
          transactions: [
            { shipmentStatus: ["id", "code", "name"] },
            { createdBy: ["id", "username"] },
            "date",
            "transactionDate",
            "totalPackages",
            {
              operation: [
                "id",
                "code",
                // "totalPackagesCount",
                { warehouse: ["id", "name"] },
              ],
            },
          ],
        },
      ],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
    packageDetails: {
      operation: "packageDetails: shipment",
      fields: [...PackageDetailsFields],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
    containers: {
      operation: "containers: shipment",
      fields: [...containersFields],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
    invoices: {
      operation: "invoices: shipment",
      fields: [
        {
          invoices: [...BILL_Invoice, "invoiceDate"],
        },
      ],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
    bills: {
      operation: "bills: shipment",
      fields: [{ bills: [...BILL_Invoice, "billDate"] }],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
  };
  return types[type];
};
