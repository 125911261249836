import * as gqlb from "gql-query-builder";
const fields = [
  "id",
  "code",
  "name",
  "phone",
  "mobile",
  "active",
  {
    currency: ["id", "code", "name"],
  },
  {
    branch: ["id", "code", "name"],
  },
  {
    country: ["id", "code", "name"],
  },
];
export const QUERY_LIST = (operationName) =>
  gqlb.query({
    operation: `list${operationName}s`,
    fields: [
      {
        operation: "paginatorInfo",
        fields: [
          "count",
          "currentPage",
          "firstItem",
          "hasMorePages",
          "lastItem",
          "lastPage",
          "perPage",
          "total",
        ],
        variables: {},
      },
      {
        operation: "data",
        fields: fields,
        variables: {},
      },
    ],
    variables: {
      input: {
        type: `List${operationName}sFilterInput`,
      },
      page: {
        type: "Int",
      },
      first: {
        type: "Int",
      },
    },
  });

export const QUERY_ID = (operationName) =>
  gqlb.query({
    operation: `${operationName}`,
    fields: [
      ...fields,
      "address",
      "postalCode",
      "mailBoxNumber",
      "email",
      "idNumber",
      "idIssueDate",
      "taxCardNumber",
      "registerationNumber",
      "date",
      {
        glAccount: ["id", "code", "name"],
      },

      {
        state: ["id", "code", "name"],
      },
      {
        idType: ["id", "code", "name"],
      },
    ],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  });
export const SAVE_QUERY = (operationName) =>
  gqlb.mutation({
    operation: `save${operationName}`,
    fields: ["id", "code", "name"],
    variables: {
      input: {
        type: `${operationName}Input`,
        required: true,
      },
    },
  });

export const FREIGHT_SETTING = (operationName) =>
  gqlb.query({
    operation: "freightSettings",
    fields: [
      operationName === "customAgent"
        ? { mainCustomAgentGLAccount: ["id", "code", "name"] }
        : operationName === "carrier"
        ? { mainCarrierGLAccount: ["id", "code", "name"] }
        : { mainAgencyGLAccount: ["id", "code", "name"] },
    ],
    variables: {},
  });
