import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export const useDocumentTypeType = (type, setValue, data) => {
  const { t } = useTranslation();

  useEffect(() => {
    // Conditionally set the value based on the type and data
    switch (type) {
      case "INVOICE":
      case "CR_ADJ":
        setValue("invoiceId", data?.invoice?.code || "");
        break;
      case "BILL":
      case "DB_ADJ":
        setValue("billId", data?.bill?.code || "");
        break;
      case "VOUCHER":
        setValue("voucherId", data?.voucher?.code || "");
        break;
      case "ADJUSTMENT":
        setValue("adjustmentId", data?.adjustment?.code || "");
        break;
      default:
        setValue("documentId", "");
    }
  }, [type, setValue, data]); // Only run the effect when these values change

  // Return the label and other data to be used in the form
  switch (type) {
    case "INVOICE":
    case "CR_ADJ":
      return { label: t("vendor"), name: "invoiceId", queryName: "Invoice" };
    case "BILL":
    case "DB_ADJ":
      return { label: t("vendor"), name: "billId", queryName: "Bill" };
    case "VOUCHER":
      return { label: t("vendor"), name: "voucherId", queryName: "Voucher" };
    case "ADJUSTMENT":
      return {
        label: t("vendor"),
        name: "adjustmentId",
        queryName: "Adjustment",
      };
    default:
      return {
        label: t("documentCode"),
        name: "documentId",
        queryName: "Invoice",
      };
  }
};
