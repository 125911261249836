import { Collapse } from "@mui/material";
import { useSnackbar } from "notistack";

export const useEnqueueSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  return (message, typeOfMessage) => {
    enqueueSnackbar(message, {
      variant: typeOfMessage,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      TransitionComponent: Collapse,
    });
  };
};
