import React, { useState } from "react";
import { Collapse, Stack } from "@mui/material";
import {
  CALCULATE_COMMISSION_AMOUNT,
  CURRENCY_ID,
  CUSTOMER_ID,
  FREIGHT_SETTING,
  REQUEST_ID,
  SAVE_REQUEST,
  VENDOR_ID,
} from "./Graphql";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, gql, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { dateFormat } from "../../helpers/dateFunctions";
import getMobileData, {
  getFormatNumber,
  validNumber,
} from "../../helpers/asYouType";
import moment from "moment";
import LongMenu from "../../Layout/MenuAppBar";
import { Globals } from "../HOC/Classes/Globals";
import HorizontalLinearStepper from "../HOC/MUI/Stepper/stepper";

import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm } from "../../GlobalStyles/FormStyle";
import { StepperProvider } from "../HOC/MUI/Stepper/StepperContext";

import { RequestContext } from "./Context/RequestContext";
import { Step1 } from "./Steps/Step1";
import { Step2 } from "./Steps/Step2";
import { Step3 } from "./Steps/Step3";
import { TbTruckLoading } from "react-icons/tb";
import { CgNotes } from "react-icons/cg";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
const RequestForm = (props) => {
  const pathURL = props.match.path;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [requestId, setRequestId] = useState(parseInt(props.match.params.id));
  const [disabled, setDisabled] = useState({
    formEdit: false,
    addPackage: false,
  });
  const [calcFeesState, setCalcFeesState] = React.useState({
    customerId: null,
    payableAmount: null,
  });
  const [autocompleteValues, setAutocompleteValues] = useState({
    originCountry: null,
    originPort: null,
    destinationCountry: null,
    destinationPort: null,
    transactionType: null,
    branch: null,
    shippingMode: null,
    shippingDirection: null,
    loadingMode: null,
    customer: null,
    currency: null,
    payableCurrency: null,
    priceMode: null,
    packageTypeId: null,
    warehouseId: null,
    originCountryId: null,
    currencyId: null,
    warehouse: null,
    productId: null,
  });
  const firstCalcUpdate = React.useRef(true);
  const firstCalculate = React.useRef(true);
  const initFromDate = moment(new Date())
    .locale("en")
    .subtract("month")
    .add("day")
    .format("YYYY-MM-DD");
  const [dateRange, setDateRange] = useState({
    shippingDate: initFromDate,
    finishDate: null,
    requestDate: initFromDate,
  });
  const {
    handleSubmit,
    control,
    formState,
    setValue,
    setError,
    watch,
    getValues,
  } = useForm();
  const { errors } = formState;

  const [saveRequest, { loading }] = useMutation(
    gql`
      ${SAVE_REQUEST.query}
    `
  );
  const calcFeesHandler = ({ value, name }) => {
    let fieldValue = {
      // eslint-disable-next-line eqeqeq
      [name]: value || value === 0 ? value : null,
    };
    firstCalculate.current = false;
    setCalcFeesState((prev) => ({
      ...prev,
      ...fieldValue,
      render: !calcFeesState.render,
    }));
  };
  const user = Globals.user;
  const BILL_PERMISSION = user?.hasPermission("freight.bill.list");
  const INVOICE_PERMISSION = user?.hasPermission("freight.invoice.list");
  const RequestID = REQUEST_ID(BILL_PERMISSION, INVOICE_PERMISSION);
  const { data, loading: updateRequestLoading } = useQuery(
    gql`
      ${RequestID.query}
    `,
    {
      skip: !requestId,
      variables: { id: requestId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const requestData = data.request;
        if (
          requestData?.packages?.length > 0 ||
          requestData?.bills?.length > 0 ||
          requestData?.invoices?.length > 0
        ) {
          setDisabled((prev) => ({
            ...prev,
            addPackage: true,
          }));
        }

        const requestParams = [
          "code",
          "payToVendor",
          "payableAmount",
          "commissionAmount",
          "remarks",
          "consigneeName",
          "consigneeAddress",
          "consigneePostalCode",
          "shipperName",
          "shipperAddress",
          "shipperPostalCode",
          "exchangeRate",
        ];
        requestParams.forEach((i) => {
          requestData[i] !== null && setValue(i, requestData[i]);
        });
        setDateRange((prev) => ({
          shippingDate: requestData?.shippingDate,
          finishDate: requestData?.finishDate,
          requestDate: requestData?.requestDate,
        }));
        setAutocompleteValues((prev) => ({
          ...prev,
          currency: requestData.currency,
          branch: requestData.branch,
          shippingMode: requestData.shippingMode,
          originCountry: requestData.originCountry,
          originPort: requestData.originPort,
          destinationCountry: requestData.destinationCountry,
          destinationPort: requestData.destinationPort,
          transactionType: requestData.transactionType,
          shippingDirection: requestData?.shippingDirection,
          loadingMode: requestData.loadingMode,
          customer: requestData?.customer,
          vendor: requestData?.vendor,
          payableCurrency: requestData?.payableCurrency,
        }));
        setCalcFeesState((prev) => ({
          ...prev,
          payableAmount: requestData?.payableAmount,
          customerId: parseInt(requestData?.customer?.id),
        }));
        setDisabled((prev) => ({
          ...prev,
          formEdit: true,
        }));
      },
    }
  );

  useQuery(
    gql`
      ${CURRENCY_ID.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !watch("currencyId"),
      variables: {
        id: parseInt(watch("currencyId")),
        input: {},
      },
      onCompleted: (data) => {
        const exchangeRate = data.currency.exchangeRate;
        setValue("exchangeRate", exchangeRate?.rate);
      },
    }
  );
  const { data: vendorData } = useQuery(
    gql`
      ${VENDOR_ID.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !watch("vendorId"),
      variables: {
        id: parseInt(watch("vendorId")),
        input: {},
      },
      onCompleted: (data) => {
        const vendor = data.vendor;
        const numbersObj = [
          {
            name: "shipperMobile",
            codeName: "shipperMobileCode",
            value: getMobileData(vendor?.mobile),
          },
        ];
        numbersObj.forEach((i) => {
          if (i.value !== null) {
            setValue(i.codeName, i.value.country.toLowerCase());
            setValue(i.name, i.value.nationalNumber);
          }
        });
        vendor.address && setValue("shipperAddress", vendor.address);
        setValue("shipperName", vendor.name);

        vendor.postalCode && setValue("shipperPostalCode", vendor.postalCode);
        vendor.state && setValue("shipperStateId", vendor.state?.name);
      },
    }
  );

  const { data: customerData } = useQuery(
    gql`
      ${CUSTOMER_ID.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !watch("customerId"),
      variables: {
        id: parseInt(watch("customerId")),
        input: {},
      },
      onCompleted: (data) => {
        const customer = data.customer;
        const numbersObj = [
          {
            name: "consigneeMobile",
            codeName: "consigneeMobileCode",
            value: getMobileData(customer?.mobile),
          },
        ];
        numbersObj.forEach((i) => {
          if (i.value !== null) {
            setValue(i.codeName, i.value.country.toLowerCase());
            setValue(i.name, i.value.nationalNumber);
          }
        });
        customer.address && setValue("consigneeAddress", customer.address);
        setValue("consigneeName", customer.name);

        customer.postalCode &&
          setValue("consigneePostalCode", customer.postalCode);
        customer.state && setValue("consigneeStateId", customer.state?.name);
      },
    }
  );

  const { data: setting } = useQuery(
    gql`
      ${FREIGHT_SETTING.query}
    `,

    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {},
    }
  );

  const onSubmit = (data) => {
    const shipperMobile = validNumber(
      data.shipperMobile,
      data.shipperMobileCode
    );
    const consigneeMobile = validNumber(
      data.consigneeMobile,
      data.consigneeMobileCode
    );
    let names = [
      {
        name: "shipperMobile",
        validate: shipperMobile.valid,
        message: shipperMobile.message,
      },
      {
        name: "consigneeMobile",
        validate: consigneeMobile.valid,
        message: consigneeMobile.message,
      },
    ];
    if (!shipperMobile.valid || !consigneeMobile.valid) {
      names.map(
        (e) =>
          !e.validate &&
          setError(e.name, { type: "custom", message: t(e.message) })
      );
      return;
    }
    data.consigneeMobile = getFormatNumber(
      data.consigneeMobile,
      data.consigneeMobileCode
    );
    data.shipperMobile = getFormatNumber(
      data.shipperMobile,
      data.shipperMobileCode
    );

    for (const key in data) {
      if (
        (data[key] === "" && key === "code") ||
        key === "shipperMobileCode" ||
        key === "consigneeMobileCode"
      ) {
        delete data[key];
      }
      if (data[key] === "") {
        data[key] = null;
        continue;
      }
      if (["creditLimit"].includes(key)) {
        data[key] = parseFloat(data[key]);
      }
    }
    saveRequest({
      variables: {
        input: {
          ...(requestId && {
            id: requestId,
          }),
          ...(data.code && {
            code: data.code,
          }),
          branchId: data?.branchId,
          shippingDirection: data?.shippingDirection,
          shippingMode: data?.shippingMode,
          ...(data?.shippingMode !== "AIR" && {
            loadingMode: data?.loadingMode,
          }),
          ...(data?.shippingMode === "AIR" && {
            loadingMode: null,
          }),
          transactionTypeId: data?.transactionTypeId,
          originCountryId: data?.originCountryId,
          originPortId: data?.originPortId,
          destinationCountryId: data?.destinationCountryId,
          destinationPortId: data?.destinationPortId,
          vendorId: data?.vendorId,
          shipperName: data?.shipperName,
          shipperAddress: data?.shipperAddress,
          shipperPostalCode: data?.shipperPostalCode,
          shipperStateId: vendorData?.vendor?.state?.id,
          shipperMobile: data?.shipperMobile,
          customerId: data?.customerId,
          consigneeName: data?.consigneeName,
          consigneeMobile: data?.consigneeMobile,
          consigneeAddress: data?.consigneeAddress,
          consigneePostalCode: data?.consigneePostalCode,
          consigneeStateId: customerData?.customer?.state?.id,
          payToVendor: data?.payToVendor,
          payableAmount: parseFloat(data?.payableAmount),
          remarks: data?.remarks,
          currencyId: data?.currencyId,
          payableCurrencyId: data?.payableCurrencyId,
          finishDate: dateFormat(dateRange.finishDate),
          shippingDate: dateFormat(dateRange.shippingDate),
          ...(dateRange.requestDate && {
            requestDate: dateFormat(dateRange.requestDate),
          }),
        },
      },
    })
      .then((data) => {
        setRequestId(data?.data?.saveRequest?.id);
        true &&
          pushUrl(props, `/admin/requests/${data?.data?.saveRequest?.id}`);

        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      });
  };
  const validateCalcFeesField = (name) => calcFeesState[name] !== null;
  const canCalculate = () =>
    validateCalcFeesField("customerId") &&
    validateCalcFeesField("payableAmount");
  useQuery(
    gql`
      ${CALCULATE_COMMISSION_AMOUNT.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: calcFeesState.render,
      fetchPolicy: "no-cache",
      skip: !canCalculate() || firstCalculate.current,
      variables: {
        input: {
          customerId: parseInt(calcFeesState?.customerId),
          payableAmount: parseFloat(calcFeesState?.payableAmount),
        },
      },
      onCompleted: (data) => {
        const packageFees = data.calculateCommissionAmount;
        setValue("commissionAmount", packageFees?.value);
      },
      onError: ({ graphQLErrors }) => {
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      },
    }
  );
  const onChangeVendor = (data) => {
    setValue("shipperName", "");
    setValue("shipperAddress", " ");
    setValue("shipperPostalCode", "");
    setValue("shipperStateId", " ");
    setValue("shipperMobile", "");
    setValue("shipperMobileCode", "+20");
  };
  const onChangeCustomer = (data) => {
    setValue("consigneeName", "");
    setValue("consigneeAddress", " ");
    setValue("consigneePostalCode", "");
    setValue("consigneeStateId", " ");
    setValue("consigneeMobile", "");
    setValue("consigneeMobileCode", "+20");
    setValue("commissionAmount", "");
  };

  const parseLoadingMode = (data) => {
    const filteredData =
      watch("shippingMode") === "OCEAN"
        ? data?.filter((item) => item.code === "FCL" || item.code === "LCL")
        : watch("shippingMode") === "LAND"
        ? data?.filter((item) => item.code === "LTL" || item.code === "FTL")
        : data;
    return filteredData;
  };

  const tapsArray = [
    {
      tabHead: t("shippingData"),
      panel: <Step1 />,
      panelFields: [
        "code",
        "requestDate",
        "shippingDate",
        "finishDate",
        "branchId",
        "shippingDirection",
        "shippingMode",
        "loadingMode",
        "transactionTypeId",
      ].some((field) => errors.hasOwnProperty(field)),
      icon: <TbTruckLoading />,
    },
    {
      tabHead: t("requestDetails"),
      panel: <Step2 />,
      panelFields: [
        "vendorId",
        "shipperName",
        "shipperAddress",
        "shipperPostalCode",
        "shipperStateId",
        "shipperMobile",
        "customerId",
        "consigneeName",
        "consigneeAddress",
        "consigneePostalCode",
        "consigneeStateId",
        "consigneeMobile",
        "destinationPortId",
        "destinationCountryId",
        "originCountryId",
        "originPortId",
      ].some((field) => errors.hasOwnProperty(field)),
      icon: <CgNotes />,
    },
    {
      tabHead: t("financialDetails"),
      panel: <Step3 />,
      panelFields: [
        "payToVendor",
        "payableCurrencyId",
        "payableAmount",
        "commissionAmount",
        "currencyId",
        "exchangeRate",
      ].some((field) => errors.hasOwnProperty(field)),
      icon: <RiMoneyDollarCircleLine />,
    },
  ];

  const body = (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <HorizontalLinearStepper />
    </form>
  );
  let DOM;
  const value = {
    tapsArray,
    loading,
  };
  if (requestId) {
    DOM = data ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }
  const RequestValue = {
    control,
    errors,
    t,
    disabled,
    dateRange,
    setDateRange,
    setting,
    requestId,
    firstCalcUpdate,
    setValue,
    autocompleteValues,
    watch,
    loading,
    calcFeesHandler,
    getValues,
    onChangeVendor,
    onChangeCustomer,
    dateFormat,
    parseLoadingMode,
  };
  return updateRequestLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data && requestId ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={pathURL}>
        <LongMenu />
      </TitleAppBar>
      <StepperProvider value={value}>
        <Stack spacing={2} p={2} sx={{ position: "relative" }}>
          <RequestContext value={RequestValue}>{DOM}</RequestContext>
        </Stack>
      </StepperProvider>
    </RootStyleForm>
  );
};

export default RequestForm;
