import { useQuery, gql, useMutation } from "@apollo/client";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import {
  FINANCIAL_SHEET,
  SAVE_FINANCIAL_SHEET,
  SAVE_FINANCIAL_SHEET_LINE,
} from "./Graphql";
import FinancialSheetTables from "./FinancialSheetTables";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormHelperText,
  Paper,
  Collapse,
  Alert,
  Box,
  FormControlLabel,
  Switch,
} from "@mui/material";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { useSnackbar } from "notistack";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import {
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { useHistory } from "react-router";
import FinancialSheetLineDetailForm from "./FinancialSheetLineDetailForm";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";

const PREFIX = "FinancialSheetForm";

const classes = {
  mainForm: `${PREFIX}-mainForm`,
  paper: `${PREFIX}-paper`,
  main: `${PREFIX}-main`,
  overlay: `${PREFIX}-overlay`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.mainForm}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    margin: theme.spacing(2),
    // padding: theme.spacing(1),
  },
  [`& .${classes.overlay}`]: {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.7,
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const FinancialSheetForm = (props) => {
  const { id } = props;
  const history = useHistory();

  const [LineList, setLineList] = useState();
  const [lineIndex, setLineIndex] = useState({
    index: 0,
    update: false,
  });
  const [detailIndex, setDetailIndex] = useState({
    index: 0,
    update: false,
  });

  const [dialog, setDialog] = useState({
    dialog: false,
    lines: false,
    detail: false,
  });
  const [linesErrorMessage, setLinesErrorMessage] = useState();
  const [disabled, setDisabled] = useState({
    formEdit: false,
    branch: false,
  });
  const [detailErrorMessage, setDetailErrorMessage] = useState(false);
  const [selectedNames, setSelectedNames] = useState({
    description: "",
    glAccountId: "",
    display: "",
    type: "",
  });
  const [activeAgency, setActiveAgency] = useState(true);
  const handelActivate = (e) => {
    const active = e.target.checked;
    setActiveAgency(active);
  };

  const [pathURL, setPathURL] = useState(props.match.path);

  const [financialSheetId, setFinancialSheetId] = useState(
    props?.match?.params?.id === "0" ? 0 : parseInt(props?.match?.params?.id)
  );
  useEffect(() => {
    id && setFinancialSheetId(id === "0" ? 0 : parseInt(id));
    return () => {};
  }, [id]);

  const priceListCopyId = parseInt(props?.match?.params?.copyId);
  const { t } = useTranslation();

  const { control, handleSubmit, setValue, errors, reset, setError, watch } =
    useForm({
      defaultValues: {
        description: "",
        glAccountId: "",
        display: "",
        type: "",
      },
    });
  const {
    control: mainControl,
    formState: { errors: mainErrors },
    handleSubmit: mainHandleSubmit,
    setValue: mainSetValue,
    setError: mainSetError,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const [saveFinancialSheet, { loading: saveFinancialSheetLoad }] = useMutation(
    gql`
      ${SAVE_FINANCIAL_SHEET.query}
    `
  );
  const [saveFinancialSheetLine] = useMutation(
    gql`
      ${SAVE_FINANCIAL_SHEET_LINE.query}
    `
  );
  const [autocompleteValues, setAutocompleteValues] = useState({
    branchId: null,
  });

  const { data: financialSheetData, loading: financialSheetLoading } = useQuery(
    gql`
      ${FINANCIAL_SHEET.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !financialSheetId && financialSheetId !== 0,
      variables: { id: financialSheetId | priceListCopyId },
      onCompleted: (data) => {
        if (data?.financialSheet?.lines) {
          setLineList(data?.financialSheet.lines);
          setDisabled((prev) => ({
            ...prev,
            branch: true,
          }));
        }
        data?.financialSheet?.notes &&
          mainSetValue("notes", data?.financialSheet?.notes);
        (financialSheetId || financialSheetId === 0) &&
          mainSetValue("code", data?.financialSheet.code);
        const nameValue =
          priceListCopyId || priceListCopyId === 0
            ? t("copy") + " " + data?.financialSheet.name
            : data?.financialSheet.name;
        mainSetValue("name", nameValue);
        setActiveAgency(data?.financialSheet?.active);
        setDisabled((prev) => ({
          ...prev,
          formEdit: true,
        }));
        setAutocompleteValues({
          branchId: data?.financialSheet.branch,
        });
      },
    }
  );

  const parseData = (data) => {
    return data;
  };

  const handelCloseDialog = () => {
    setDialog({
      dialog: false,
      lines: false,
      detail: false,
    });
    setLinesErrorMessage(false);
    setDetailErrorMessage(false);
    setLineIndex((prev) => ({ ...prev, update: false }));
    setDetailIndex((prev) => ({ ...prev, update: false }));
    reset();
  };

  const onChangeNames = (e, parameter, value, remove) => {
    const name = value
      ? e.find((item) => item.code === (value === true ? e[0]?.code : value))
          ?.name
      : e?.name;
    setSelectedNames((prev) => ({
      ...prev,
      [parameter]: name,
      ...(remove && { [remove]: "" }),
    }));
    setLinesErrorMessage(false);
    setDetailErrorMessage(false);
  };

  const addLinesDialog = (index) => {
    reset();
    if (index || index === 0) {
      setLineIndex((prev) => ({
        ...prev,
        index,
        update: true,
      }));
    } else {
      setLineIndex({
        index,
        update: false,
      });
    }
    setDialog({
      dialog: true,
      lines: true,
      detail: false,
    });
  };
  const addDetailDialog = (lineIndex, detailIndex) => {
    reset();

    setLineIndex({
      index: lineIndex,
      update: false,
    });
    if (detailIndex || detailIndex === 0) {
      setDetailIndex({
        index: detailIndex,
        update: true,
      });
    }
    setDialog({
      dialog: true,
      detail: true,
      lines: false,
    });
  };

  const onSubmitLines = (data) => {
    for (const key in data) {
      if (
        [undefined, "", null].includes(data[key]) ||
        (Array.isArray(data[key]) && data[key].length === 0)
      ) {
        data[key] = null;
      }
    }
    const newLine = {
      glAccount: {
        id: data.glAccountId,
        name: selectedNames.glAccountId,
      },
      type: {
        code: data.type,
        name: selectedNames.type,
      },
      display: {
        code: data.display,
        name: selectedNames.display,
      },
      description: data.description,
      number: data.number,
    };
    const updateLines = LineList ? [...LineList] : [];

    if (lineIndex.update) {
      const updatedLines = {
        ...updateLines[lineIndex.index],
        ...newLine,
      };
      updateLines[lineIndex.index] = updatedLines;
      saveFinancialSheetLine({
        variables: {
          input: {
            ...(lineIndex.update && { id: updatedLines.id }),
            sheetId: financialSheetId,
            glAccountId: newLine.glAccount?.id,
            description: newLine.description,
            display: newLine.display?.code,
            type: newLine.type?.code,
            ...(newLine.number && { number: parseInt(newLine.number) }),
          },
        },
      })
        .then((data) => {
          setLineList(updateLines);
          handelCloseDialog();
        })
        .catch(({ graphQLErrors }) => {
          if (graphQLErrors?.[0]?.extensions?.category === "validation") {
            setValidationError(graphQLErrors, setError);
          } else {
            setLinesErrorMessage(graphQLErrors[0]?.message);
          }

          console.log(graphQLErrors);
        });
    } else {
      saveFinancialSheetLine({
        variables: {
          input: {
            sheetId: financialSheetId,
            ...(newLine.glAccount?.id && {
              glAccountId: newLine.glAccount?.id,
            }),
            ...(newLine.description && { description: newLine.description }),
            display: newLine.display?.code,
            type: newLine.type?.code,
            ...(newLine.number && { number: parseInt(newLine.number) }),
          },
        },
      })
        .then((data) => {
          newLine.id = data.data?.saveFinancialSheetLine?.id;
          newLine.number = data.data?.saveFinancialSheetLine?.number;
          updateLines.unshift(newLine);
          setLineIndex({
            index: 0,
            update: false,
            duplicate: false,
          });
          setDisabled((prev) => ({
            ...prev,
            branch: true,
          }));
          setLineList(updateLines);
          handelCloseDialog();
        })
        .catch(({ graphQLErrors }) => {
          if (graphQLErrors?.[0]?.extensions?.category === "validation") {
            setValidationError(graphQLErrors, setError);
          } else {
            setLinesErrorMessage(graphQLErrors?.[0]?.message);
          }
        });
    }
  };

  const submitMutation = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    const createData = {
      ...(data.code && { code: data.code }),
      ...((financialSheetId || financialSheetId === 0) && {
        id: financialSheetId,
      }),
      notes: data.notes,
      name: data.name,
      ...(!disabled.branch && { branchId: parseInt(data.branchId) }),
      active: activeAgency,
    };
    saveFinancialSheet({
      variables: {
        input: createData,
      },
    })
      .then((data) => {
        const url = history.createHref({
          pathname: `/admin/financial-sheets/${data?.data?.saveFinancialSheet?.id}/edit`,
        });
        pushUrl(props, url);
        setPathURL(url);
        windowReplaceUrl(url);
        setFinancialSheetId(data?.data?.saveFinancialSheet?.id);
        setDisabled((prev) => ({
          ...prev,
          formEdit: true,
        }));

        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        if (graphQLErrors?.[0]?.extensions?.category === "validation") {
          setValidationError(graphQLErrors, mainSetError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      });
  };

  const LinesForm = (
    <LineForm
      handleSubmit={handleSubmit}
      onSubmitLines={onSubmitLines}
      control={control}
      errors={errors}
      parseData={parseData}
      onChangeNames={onChangeNames}
      lineIndex={lineIndex}
      LineList={LineList}
      setValue={setValue}
      linesErrorMessage={linesErrorMessage}
      handelCloseDialog={handelCloseDialog}
      setSelectedNames={setSelectedNames}
      financialSheetData={financialSheetData}
      watch={watch}
    />
  );
  const DetailForm = (
    <FinancialSheetLineDetailForm
      onChangeNames={onChangeNames}
      detailIndex={detailIndex}
      LineList={LineList}
      lineIndex={lineIndex}
      detailErrorMessage={detailErrorMessage}
      setDetailErrorMessage={setDetailErrorMessage}
      handelCloseDialog={handelCloseDialog}
      setSelectedNames={setSelectedNames}
      setLineList={setLineList}
      setLineIndex={setLineIndex}
      selectedNames={selectedNames}
      financialSheetData={financialSheetData}
    />
  );

  return financialSheetLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classes.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !financialSheetData && financialSheetId ? (
    <NotFound />
  ) : (
    <Root>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={dialog.dialog}
        onClose={handelCloseDialog}
      >
        {dialog.detail ? DetailForm : null}
        {dialog.lines ? LinesForm : null}
      </Dialog>
      {pathURL && <TitleAppBar path={pathURL} />}
      <Paper
        className={classes.paper}
        component="form"
        onSubmit={mainHandleSubmit(submitMutation)}
        sx={{ marginTop: !props?.match?.path && "68px !important" }}
      >
        {financialSheetId && disabled.formEdit ? (
          <Grid sm={12} xs={12}>
            <Alert
              severity="warning"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setDisabled((prev) => ({
                      ...prev,
                      formEdit: false,
                    }));
                  }}
                >
                  {t("update")}
                </Button>
              }
            >
              {t("updateRecord")}
            </Alert>
          </Grid>
        ) : (
          ""
        )}
        <Grid
          container
          spacing={2}
          className={classes.mainForm}
          sx={{ position: "relative" }}
        >
          {disabled.formEdit && (
            <Box
              className={classes.overlay}
              sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
            ></Box>
          )}
          <Grid
            justifyContent="end"
            sx={{ display: "flex", flexWrap: "wrap" }}
            xs={12}
          >
            <FormControlLabel
              checked={activeAgency}
              control={<Switch color="primary" />}
              label={t("active")}
              labelPlacement="start"
              onChange={handelActivate}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <ControlMUItextField
              control={mainControl}
              errors={mainErrors}
              name="code"
              label={t("code")}
              disabled={disabled.formEdit}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <ControlMUItextField
              control={mainControl}
              errors={mainErrors}
              name="name"
              label={t("name")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>

          <Grid xs={12} sm={6}>
            <ListBranches
              control={mainControl}
              errors={mainControl}
              disabled={disabled.formEdit || disabled.branch}
              name={"branchId"}
              defaultValue={autocompleteValues.branchId}
              skipDefaultBranch
            />
          </Grid>

          <Grid xs={12} sm={6}>
            <ControlMUItextField
              control={mainControl}
              errors={mainErrors}
              disabled={disabled.formEdit}
              name="notes"
              label={t("notes")}
            />
          </Grid>

          <Grid xs={12} justifyContent="flex-end" display="flex">
            <FormButton
              className={classes.button}
              disabled={saveFinancialSheetLoad || disabled.formEdit}
            >
              {saveFinancialSheetLoad ? <ButtonLoading /> : t("save")}
            </FormButton>
          </Grid>
        </Grid>
      </Paper>
      <Grid
        container
        spacing={2}
        className={classes.mainForm}
        sx={{ position: "relative" }}
      >
        {(!financialSheetId || !disabled?.formEdit) && (
          <Box className={classes.overlay}></Box>
        )}
        <FinancialSheetTables
          financialSheetId={financialSheetId}
          LineList={LineList}
          addLines={addLinesDialog}
          addDetail={addDetailDialog}
          setLineList={setLineList}
          setDisabled={setDisabled}
        />
      </Grid>
    </Root>
  );
};

export default FinancialSheetForm;
function LineForm({
  handleSubmit,
  onSubmitLines,
  control,
  errors,
  parseData,
  onChangeNames,
  lineIndex,
  LineList,
  setValue,
  linesErrorMessage,
  handelCloseDialog,
  setSelectedNames,
  financialSheetData,
  watch,
}) {
  const { t } = useTranslation();
  const [autocompleteValues, setAutocompleteValues] = useState({
    glAccountId: null,
    type: null,
    display: null,
    description: null,
  });
  const [disableType, setDisableType] = useState(false);
  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  useEffect(() => {
    if (lineIndex.update) {
      const update = LineList[lineIndex.index];
      if (update?.details?.length > 0) {
        setDisableType(true);
      }
      setAutocompleteValues({
        glAccountId: update?.glAccount,
        type: update?.type,
        display: update?.display,
      });
      setValue("description", update?.description);
      setValue("number", update?.number);
      setSelectedNames((prev) => ({
        ...prev,
        ...(update?.glAccount?.name && {
          glAccountId: update?.glAccount?.name,
        }),
        ...(update?.display?.name && { display: update?.display?.name }),
        ...(update?.type?.name && { type: update?.type?.name }),
      }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmitLines)}>
      <DialogTitle color={"text.primary"}>{t("lines")}</DialogTitle>
      <DialogContent>
        <Grid
          container
          justifyContent="flex-start"
          aligns="center"
          className={classes.mainGrid}
          spacing={2}
        >
          <Grid xs={12} sm={12} aligns="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              type={"number"}
              name={"number"}
              label={t("numberLine")}
            />
          </Grid>
          <Grid xs={12} sm={12} alignItems="flex-start">
            <CustomAutocomplete
              valueKey="code"
              hideCode={true}
              disabled={disableType}
              control={control}
              errors={errors}
              selectFirst={true}
              name={"type"}
              label={t("typeLine")}
              rules={{ required: t("fieldIsRequired") }}
              parseData={(data) => parseData(data)}
              variables={{
                input: { code: "GL_SHEET_LINE_TYPES" },
              }}
              onChangeValue={(e) => {
                setValue("description", "");
                setValue("glAccountId", "");
                onChangeNames(e, "type");
              }}
              query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
              defaultValue={autocompleteValues?.type}
              onCompleted={(data) =>
                onChangeNames(
                  data?.listLookupEntriesDropdown,
                  "type",
                  autocompleteValues?.type?.code ?? true
                )
              }
            />
          </Grid>
          {watch("type") === "ACCOUNT" && (
            <Grid xs={12} sm={12} alignItems="flex-start">
              <CustomAutocomplete
                control={control}
                errors={errors}
                rules={{ required: t("fieldIsRequired") }}
                name={"glAccountId"}
                label={t("glAccount")}
                parseData={(data) => parseData(data)}
                query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                variables={{
                  input: {
                    ...(financialSheetData?.financialSheet?.branch?.id && {
                      branchId: {
                        value: financialSheetData?.financialSheet?.branch?.id,
                        includeNull: true,
                      },
                    }),
                  },
                }}
                onChangeValue={(e) => {
                  onChangeNames(e, "glAccountId");
                }}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    autocompleteValues.glAccountId,
                    "glAccountId"
                  )
                }
                defaultValue={autocompleteValues.glAccountId}
              />
            </Grid>
          )}

          {watch("type") === "DESCRIPTION" && (
            <Grid xs={12} sm={12} aligns="flex-start">
              <ControlMUItextField
                control={control}
                rules={{ required: t("fieldIsRequired") }}
                errors={errors}
                name="description"
                label={t("descriptionLine")}
                onChange={(e) => {
                  onChangeNames(e, "glAccountId", false, true);
                }}
              />
            </Grid>
          )}
          <Grid xs={12} sm={12} alignItems="flex-start">
            <CustomAutocomplete
              valueKey="code"
              hideCode={true}
              control={control}
              errors={errors}
              selectFirst={true}
              name={"display"}
              label={t("displayLine")}
              parseData={(data) => parseData(data)}
              rules={{ required: t("fieldIsRequired") }}
              variables={{
                input: { code: "GL_SHEET_LINE_DISPLAY" },
              }}
              onChangeValue={(e) => {
                onChangeNames(e, "display");
              }}
              query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
              defaultValue={autocompleteValues?.display}
              onCompleted={(data) =>
                onChangeNames(
                  data?.listLookupEntriesDropdown,
                  "display",
                  autocompleteValues?.display?.code ?? true
                )
              }
            />
          </Grid>
          {linesErrorMessage && (
            <FormHelperText error>{linesErrorMessage}</FormHelperText>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handelCloseDialog}>{t("cancel")}</Button>
        <Button type="submit">{t("confirm")}</Button>
      </DialogActions>
    </form>
  );
}
