/* eslint-disable no-useless-concat */
import React, { lazy, Suspense, useReducer, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Paper, Typography } from "@mui/material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { DELETE_SHIPMENT, details } from "./Graphql";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import CustomizedSteppers from "../HOC/FunctionComponents/Stepper";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";
import { ContainersProvider } from "./ShipmentContainerContext";
import { TabsProvider } from "../HOC/CustomComponents/PackagesTableView/TabContext";
import CustomTab from "../HOC/FunctionComponents/CustomTab";
import {
  useShipmentData,
  useShipmentDataByType,
} from "../HOC/CustomComponents/RequestShipment/shipmentData";
const DescriptionView = lazy(() => import("./DescriptionView"));
const BillOrInvoiceTable = lazy(() =>
  import("../HOC/CustomComponents/RequestShipment/Bill_InvoiceTable")
);
const ShipmentContainerTables = lazy(() => import("./ShipmentContainerTable"));
const PackageDetailsTable = lazy(() => import("./PackageDetailsTable"));
const TransactionsTable = lazy(() => import("./TransactionsTable"));
const PREFIX = "ShipmentView";
const classes = {
  alertHover: `${PREFIX}-alertHover`,
  codeSection: `${PREFIX}-codeSection`,
  codeTitle: `${PREFIX}-codeTitle`,
};
const Header = styled(Grid)(({ theme }) => ({
  [`& .${classes.codeSection}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: theme.spacing(1),
  },

  [`& .${classes.codeTitle}`]: {
    float: "left",
    marginRight: theme.spacing(1),
  },
  [`& .${classes.alertHover}`]: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const initialState = {
  description: {
    data: null,
  },
  transactions: {
    data: null,
  },
  containers: {
    data: null,
  },
  packageDetails: {
    data: null,
  },
  bills: {
    data: null,
  },
  invoices: {
    data: null,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "description":
      return {
        ...state,
        description: { ...state.description, ...action.payload },
      };
    case "transactions":
      return {
        ...state,
        transactions: { ...state.transactions, ...action.payload },
      };
    case "containers":
      return {
        ...state,
        containers: { ...state.containers, ...action.payload },
      };
    case "packageDetails":
      return {
        ...state,
        packageDetails: { ...state.packageDetails, ...action.payload },
      };
    case "bills":
      return {
        ...state,
        bills: { ...state.bills, ...action.payload },
      };
    case "invoices":
      return {
        ...state,
        invoices: { ...state.invoices, ...action.payload },
      };
    default:
      return state;
  }
}

const ShipmentView = (props) => {
  const { t, i18n } = useTranslation();
  const id = props.match.params.id?.trim();
  const user = Globals.user;
  const href = document.location.href.split("#");
  const [type] = useState(href[1] ?? "description");
  const operation = "shipment";
  const BILL_PERMISSION = user?.hasPermission("freight.bill.list");
  const INVOICE_PERMISSION = user?.hasPermission("freight.invoice.list");
  const [shipmentViewManagement, dispatch] = useReducer(reducer, initialState);
  const queryByType = details({ type, operation });
  const [changedType, setChangedType] = useState(null);

  const { data, loading } = useShipmentData({
    operation,
    trackingId: id,
    type,
    dispatch,
    queryByType,
    user,
  });
  useShipmentDataByType({
    changedType: changedType ?? type,
    skip: changedType,
    dispatch,
    queryByType: details({ type: changedType ?? type, operation }),
    trackingId: id,
    shipmentViewManagement,
  });

  const [openDialog, setOpenDialog] = React.useState(false);
  const step1 = useRef([
    t("booked"),
    // t("scheduled"),
    t("loaded"),
    t("shipped"),
    // t("arrived"),
    t("unloaded"),
    // t("delivered"),
  ]);
  const shipmentData = data?.shipment;
  const tabValue = {
    data: shipmentViewManagement?.transactions?.data?.transactions,
    shipmentData: shipmentViewManagement?.description?.data,
    classes: classesView,
    tabsLoading: loading,
    packageDetails: shipmentViewManagement?.packageDetails?.data?.requests,
    billData: shipmentViewManagement?.bills?.data?.bills,
    invoiceData: shipmentViewManagement?.invoices?.data?.invoices,
  };
  const canDelete =
    user.hasPermission(`freight.shipment.delete`) && shipmentData?.deletable;

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const value = {
    containers: shipmentViewManagement?.containers?.data?.containers,
    loading: loading,
    classes: classesView,
  };
  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/shipments/${id}/edit`),
      permission:
        user.hasPermission("freight.shipment.update") && shipmentData?.editable,
    },
    {
      id: "delete",
      action: handleOpenDialog,
      permission: Boolean(canDelete),
    },
  ];

  let trackingBody = null;
  const hashKeys = ["description", "transactions", "packageDetails"];
  const tapsArray = [
    {
      tabHead: t("description"),
      panel: (
        <Suspense
          fallback={
            <Paper sx={{ mt: 2 }}>
              <FullScreenLoading minHeight={"100px"} />
            </Paper>
          }
        >
          <DescriptionView />
        </Suspense>
      ),
    },
    {
      tabHead: t("transactions"),
      panel: (
        <Suspense
          fallback={
            <Paper sx={{ mt: 2 }}>
              <FullScreenLoading minHeight={"100px"} />
            </Paper>
          }
        >
          <TransactionsTable />
        </Suspense>
      ),
    },
    {
      tabHead: t("packageDetails"),
      panel: (
        <Suspense
          fallback={
            <Paper sx={{ mt: 2 }}>
              <FullScreenLoading minHeight={"100px"} />
            </Paper>
          }
        >
          <PackageDetailsTable />
        </Suspense>
      ),
    },
  ];
  if (!["LCL", "LTL"].includes(data?.shipment?.loadingMode?.code)) {
    const insertIndex = hashKeys.length - 1; // Index before the last element
    const insertTab = tapsArray.length - 1; // Index before the last element
    hashKeys.splice(insertIndex, 0, "containers");
    tapsArray.splice(insertTab, 0, {
      tabHead: t("containers"),
      panel: (
        <Suspense
          fallback={
            <Paper sx={{ mt: 2 }}>
              <FullScreenLoading minHeight={"100px"} />
            </Paper>
          }
        >
          <ContainersProvider value={value}>
            <ShipmentContainerTables />
          </ContainersProvider>
        </Suspense>
      ),
    });
  }
  if (BILL_PERMISSION) {
    hashKeys.push("bills");
    tapsArray.push({
      tabHead: t("bills"),
      panel: (
        <Suspense
          fallback={
            <Paper sx={{ mt: 2 }}>
              <FullScreenLoading minHeight={"100px"} />
            </Paper>
          }
        >
          <BillOrInvoiceTable />
        </Suspense>
      ),
    });
  }
  if (INVOICE_PERMISSION) {
    hashKeys.push("invoices");
    tapsArray.push({
      tabHead: t("invoices"),
      panel: (
        <Suspense
          fallback={
            <Paper sx={{ mt: 2 }}>
              <FullScreenLoading minHeight={"100px"} />
            </Paper>
          }
        >
          <BillOrInvoiceTable />
        </Suspense>
      ),
    });
  }
  if (id && !loading) {
    trackingBody = (
      <Header
        container
        spacing={3}
        justifyContent="center"
        sx={{ width: "100%", gap: 2 }}
      >
        <Grid container justifyContent="center" className={classes.track}>
          <Grid className={classes.codeSection}>
            <Typography
              className={classes.codeTitle}
              variant="h6"
              color="textSecondary"
            >
              {t("shipmentCode")}
            </Typography>
            <Typography variant="h5" color="primary" sx={{ ml: 1 }}>
              {shipmentData?.code}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} justifyContent="center">
          <CustomizedSteppers
            step1={step1}
            key={i18n.language}
            statusCode={shipmentData?.status?.code}
            type={"shipment"}
          />
        </Grid>
        <Grid xs={12} justifyContent="center">
          <CustomTab
            tapDetails={tapsArray}
            hashKeys={hashKeys}
            changeHashKeyFun={setChangedType}
          />
        </Grid>
      </Header>
    );
  }
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !shipmentData ? (
    <NotFound />
  ) : (
    <RootStyleView>
      <MutationWithDialog
        mutaion={DELETE_SHIPMENT.query}
        setOpenDelete={setOpenDialog}
        openDelete={openDialog}
        dialogTitle={t("deleteRecord")}
        dialogContent={t("deleteRecordMessage")}
        mutaionProps={{
          variables: { id: parseInt(id) },
        }}
        onCompleted={() => pushUrl(props, `/admin/shipments`)}
        onCompleteMessage={t("successfullyDeletedRecord")}
      />

      <Grid
        container
        spacing={3}
        m={0}
        justifyContent="center"
        sx={{ maxWidth: "100vw", width: "100%" }}
      >
        <TitleAppBar path={props.match.path}>
          <LongMenu icons={icons} />
        </TitleAppBar>
        <TabsProvider value={tabValue}>{trackingBody}</TabsProvider>
      </Grid>
    </RootStyleView>
  );
};

export default ShipmentView;
