import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button, Collapse, Typography } from "@mui/material";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect } from "react";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { CALCULATE_TAX, SAVE_TAX, TAX_ID } from "./Graphql";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { LIST_TAXES_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useBillContext } from "./Context/BillContext";

export default function TaxForm() {
  const {
    autocompleteValues,
    lineId,
    updateTax,
    tax,
    setTax,
    closeTaxDialog,
    setAutocompleteValues,
    capitalPathname,
    setLine,
    line,
    billData,
    setTotalSum,
    refetch,
  } = useBillContext();

  const { handleSubmit, control, setError, errors, setValue, watch } =
    useForm();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useQuery(
    gql`
      ${TAX_ID.query}
    `,
    {
      skip: !watch("taxId"),
      variables: { id: watch("taxId") },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        (watch("rate") === null || watch("rate") === "") &&
          setValue("rate", data?.tax?.rate);
      },
    }
  );
  useQuery(
    gql`
      ${CALCULATE_TAX.query}
    `,
    {
      skip: !watch("rate"),
      variables: {
        input: {
          ...(capitalPathname === "Bill"
            ? { billLineId: lineId, rate: parseFloat(watch("rate")) }
            : { invoiceLineId: lineId, rate: parseFloat(watch("rate")) }),
        },
      },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setValue("value", data?.calculateTaxFees?.value);
      },
    }
  );

  const SAVE = SAVE_TAX(capitalPathname); // Pass the operation name
  const [save, { loading: saveLoading }] = useMutation(
    gql`
      ${SAVE.query}
    `
  );

  useEffect(() => {
    if (updateTax) {
      setValue("rate", updateTax?.rate);
      setValue("value", updateTax?.value);
      setAutocompleteValues((prev) => ({
        ...prev,
        tax: updateTax?.tax,
      }));
    }
    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const parseData = (data) => {
    return data;
  };
  const onSubmit = (data, lineId, id) => {
    for (const key in data) {
      if (data[key] === "" || key === "value") {
        delete data[key];
      }
      if (["rate"].includes(key)) {
        data[key] = parseFloat(data[key]);
      }
    }
    save({
      variables: {
        input: {
          ...(lineId && capitalPathname === "Bill"
            ? { billLineId: lineId }
            : { invoiceLineId: lineId }),
          ...(id && { id: id }),
          ...data,
        },
      },
    })
      .then((data) => {
        const checksubZone = tax.map(
          (ele) => ele.id === data.data?.[`save${capitalPathname}Tax`].id
        );

        const check = checksubZone.includes(true);
        if (check) {
          const index = tax.findIndex(
            (x) => x.id === data.data?.[`save${capitalPathname}Tax`].id
          );

          tax[index] = data.data?.[`save${capitalPathname}Tax`];
          setTax(tax);
        } else {
          tax.push(data.data?.[`save${capitalPathname}Tax`]);
          setTax(tax);
        }
        const lineIndex = line.findIndex((line) => line.id === lineId);
        refetch();
        if (lineIndex !== -1) {
          // Update the specific line with the new data
          setTotalSum((prev) => ({
            ...prev, // Spread the previous state to keep other values intact
            totalTaxAmount:
              prev.totalTaxAmount -
              line[lineIndex]?.taxAmount +
              data.data?.[`save${capitalPathname}Tax`].line?.taxAmount, // Update the specific value
            totalAmount:
              prev.totalAmount -
              line[lineIndex]?.totalAmount +
              data.data?.[`save${capitalPathname}Tax`].line?.totalAmount, // Update another value
          }));
          line[lineIndex] = {
            ...line[lineIndex],
            ...data.data?.[`save${capitalPathname}Tax`].line,
          };

          // Set updated lines
          setLine([...line]);
        }
        closeTaxDialog();
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      });
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        // p={1}
        component="form"
        onSubmit={handleSubmit((data) => onSubmit(data, lineId, updateTax?.id))}
      >
        <Grid
          container
          justifyContent="space-between"
          xs={12}
          p={1}
          alignItems={"center"}
        >
          <Typography variant="h6" color={"text.primary"}>
            {t("tax")}
          </Typography>
        </Grid>
        <Grid xs={12} sm={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"taxId"}
            label={t("tax")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => parseData(data)}
            query={LIST_TAXES_DROPDOWN.query}
            variables={{
              input: {
                ...(billData?.request && {
                  shippingDirection: billData?.request?.shippingDirection?.code,
                }),
                ...(billData?.request && {
                  shippingMode: billData?.request?.shippingMode?.code,
                }),
                type: capitalPathname === "Bill" ? "INPUT" : "OUTPUT",
              },
            }}
            defaultValue={autocompleteValues.tax}
            onChangeValue={(e) => {
              setValue("rate", "");
            }}
          />
        </Grid>
        <Grid xs={12} sm={12}>
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"rate"}
            type="number"
            label={t("taxRate")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid xs={12} sm={12}>
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"value"}
            type="number"
            label={t("value")}
            readOnly={true}
          />
        </Grid>

        <Grid p={1} container sm={12} justifyContent="flex-end">
          <Button onClick={closeTaxDialog}>{t("cancel")}</Button>
          <Button type="submit">
            {saveLoading ? <ButtonLoading /> : t("confirm")}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
