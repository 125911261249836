import React from "react";
import {
  Paper,
  Typography,
  Collapse,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Toolbar,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { SAVE_LOOKUP_ENTRIES, LIST_LOOKUP_ID } from "./Graphql";
import { useMutation, gql, useQuery } from "@apollo/client";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useState } from "react";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";

import MuiSwitch from "../HOC/MUI/MUIswitch";
import Grid from "@mui/material/Unstable_Grid2";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { Fragment } from "react";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";

const LookupEntriesForm = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control, formState, setValue, setError } = useForm();

  const { errors } = formState;
  const lookupId = parseInt(props.match.params.id);

  const [items, setItems] = useState([]);

  const { data: lookupData, loading: lookupLoading } = useQuery(
    gql`
      ${LIST_LOOKUP_ID.query}
    `,
    {
      skip: !lookupId,
      notifyOnNetworkStatusChange: true,
      variables: { id: lookupId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const lookupEntries = data.lookup.entries;
        setItems(lookupEntries);
        lookupEntries.forEach((lookupEntrie) => {
          lookupEntrie["name"] &&
            setValue(`${lookupEntrie["id"]}.name`, lookupEntrie["name"]);
          lookupEntrie["active"] &&
            setValue(`${lookupEntrie["id"]}.active`, lookupEntrie["active"]);
          lookupEntrie["mobileActive"] &&
            setValue(
              `${lookupEntrie["id"]}.mobileActive`,
              lookupEntrie["mobileActive"]
            );
        });
      },
    }
  );

  const lookup = lookupData?.lookup;

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    for (let i = 0; i < reorderedItems.length; i++) {
      reorderedItems[i].weight = i;
    }
    setItems(reorderedItems);
  };

  const [saveLookupEntries, { loading: saveLookupEntriesLoading }] =
    useMutation(
      gql`
        ${SAVE_LOOKUP_ENTRIES.query}
      `
    );

  const onSubmit = (data) => {
    const entriesData = [];
    for (const key in data) {
      data[key].id = Number(key);
      entriesData.push(data[key]);
    }
    for (let i = 0; i < entriesData.length; i++) {
      for (let j = 0; j < items.length; j++) {
        if (entriesData[i].id === items[j].id) {
          entriesData[i].weight = items[j].weight;
        }
      }
    }

    saveLookupEntries({
      variables: {
        input: {
          id: lookupId,
          entries: entriesData,
        },
      },
    })
      .then((data) => {
        pushUrl(props, `/admin/lookups/${data?.data?.updateLookup?.id}`);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };

  return (
    <RootStyleForm
      container
      justifyContent="center"
      sx={{ margin: "0", width: "100%" }}
    >
      {" "}
      {lookupLoading ? (
        <StyledLoading
          container
          item
          justifyContent="center"
          className={classesLoad.main}
        >
          <FullScreenLoading height={"100%"} />
        </StyledLoading>
      ) : !lookupData && lookupId ? (
        <NotFound />
      ) : (
        <>
          {!props.id ? (
            <Fragment>
              <TitleAppBar path={props.match.path} />
            </Fragment>
          ) : null}
          <Grid
            container
            justifyContent="center"
            sx={{ width: "100%", p: 2, gap: 2 }}
          >
            <Fragment>
              <Paper
                container
                xs={12}
                className={classesForm.box}
                component={Grid}
              >
                <KeyValuePair title={t("name")} value={lookup?.name} />
                <KeyValuePair title={t("code")} value={lookup?.code} />
              </Paper>
              {/* </Fragment> */}
              <Paper
                container
                sm={12}
                md={8}
                className={classesForm.table}
                component={Grid}
              >
                <Toolbar variant="dense">
                  <Typography color="inherit" variant="h6">
                    {t("entries")}
                  </Typography>
                </Toolbar>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid
                    container
                    justifyContent="flex-end"
                    className={classesForm.spacing}
                  >
                    <TableFixedHeaderWraper component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <FixedTableCell> </FixedTableCell>
                            <FixedTableCell>{t("code")}</FixedTableCell>
                            <FixedTableCell>{t("name")}</FixedTableCell>
                            <FixedTableCell>{t("active")}</FixedTableCell>
                            <FixedTableCell>{t("mobileActive")}</FixedTableCell>
                          </TableRow>
                        </TableHead>
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable
                            droppableId="droppable"
                            direction="vertical"
                          >
                            {(provided, snapshot) => (
                              <TableBody
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {items &&
                                  items?.map((row, index) => (
                                    <Draggable
                                      key={row.id}
                                      draggableId={row?.code}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <TableRow
                                          hover
                                          role="checkbox"
                                          tabIndex={-1}
                                          key={index}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <FixedTableCell>
                                            <DragHandleIcon />
                                          </FixedTableCell>
                                          <FixedTableCell>
                                            {row?.code}
                                          </FixedTableCell>
                                          <FixedTableCell>
                                            <ControlMUItextField
                                              control={control}
                                              errors={errors}
                                              name={`${row?.id}.name`}
                                              label={t("name")}
                                              rules={{
                                                required: t("fieldIsRequired"),
                                              }}
                                              variant="outlined"
                                              sx={{ p: 1 }}
                                            />
                                          </FixedTableCell>
                                          <FixedTableCell>
                                            <MuiSwitch
                                              edge="end"
                                              name={`${row?.id}.active`}
                                              control={control}
                                            />
                                          </FixedTableCell>
                                          <FixedTableCell>
                                            <MuiSwitch
                                              edge="end"
                                              name={`${row?.id}.mobileActive`}
                                              control={control}
                                            />
                                          </FixedTableCell>
                                        </TableRow>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided.placeholder}
                              </TableBody>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </Table>
                    </TableFixedHeaderWraper>
                    <FormButton
                      disabled={saveLookupEntriesLoading}
                      className={classesForm.button}
                    >
                      {saveLookupEntriesLoading ? <ButtonLoading /> : t("save")}
                    </FormButton>
                  </Grid>
                </form>
              </Paper>
            </Fragment>
          </Grid>{" "}
        </>
      )}
    </RootStyleForm>
  );
};

export default LookupEntriesForm;
