import * as gqlb from "gql-query-builder";

export const LIST_COMMODITIES = gqlb.query({
  operation: "listCommodities",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: ["id", "code", "name", "date", "active"],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListCommoditiesFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const COMMODITY_ID = gqlb.query({
  operation: "commodity",
  fields: ["id", "code", "name", "date", "active"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_COMMODITY = gqlb.mutation({
  operation: "saveCommodity",
  fields: ["id", "code", "name"],
  variables: {
    input: {
      type: "CommodityInput",
      required: true,
    },
  },
});

export const DELETE_COMMODITY = gqlb.mutation({
  operation: "deleteCommodity",
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
