export const fieldsPrint = [
  "id",
  "code",
  "operationDate",
  "approved",
  "remarks",
  {
    branch: ["id", "name", "code"],
  },
  {
    transactionType: ["id", "name", "code"],
  },
  {
    warehouse: ["id", "name", "code"],
  },
  {
    request: [
      "id",
      "code",
      {
        packages: [
          "id",
          "code",
          "fragile",
          "height",
          "weight",
          "length",
          "breadth",
          "count",
          "totalWeight",
          "pieces",
          "volume",
          "volumetricWeight",
          "volumetricFactor",
          "chargeableUnits",
          "chargePrice",
          "chargeAmount",
          "description",
          "exchangeRate",
          "productPrice",
          { packageType: ["id", "name", "code"] },
          { product: ["id", "name", "code"] },
          { originCountry: ["id", "name", "code"] },
          { priceMode: ["id", "name", "code"] },
          { currency: ["id", "name", "code"] },
        ],
      },
    ],
  },
  {
    shipment: ["id", "code"],
  },
  { type: ["id", "code"] },
  {
    manifests: [
      "id",
      {
        container: [
          "id",
          "containerNumber",
          // { packageType: ["id", "name", "code"] },
        ],
      },
      {
        packageDetail: [
          "id",
          "pieceNumber",
          { warehouse: ["id", "name", "code"] },
          {
            package: [
              "id",
              "weight",
              "fragile",
              "description",
              { product: ["id", "name", "code"] },
              {
                request: [
                  "code",
                  "loaded",
                  "unloaded",
                  "id",
                  "detailsCount",
                  { originCountry: ["id", "name", "code"] },
                  { destinationCountry: ["id", "name", "code"] },
                  { loadingMode: ["id", "name", "code"] },
                  { customer: ["id", "name", "code"] },
                  { vendor: ["id", "name", "code"] },
                ],
              },
              {
                packageType: ["id", "name", "code"],
              },
            ],
          },

          "loaded",
          "unloaded",
        ],
      },
    ],
  },
];

export const fieldsBill = [
  "id",
  "code",
  "totalAmount",
  "approved",
  "glApproved",

  {
    branch: ["id", "code", "name"],
  },
  {
    accountType: ["id", "code", "name"],
  },
  {
    vendor: ["id", "code", "name"],
  },
  {
    employee: ["id", "code", "name"],
  },
  {
    customer: ["id", "code", "name"],
  },
  {
    agent: ["id", "code", "name"],
  },
  {
    agency: ["id", "code", "name"],
  },
  {
    carrier: ["id", "code", "name"],
  },
  {
    documentType: ["id", "code", "name"],
  },
  {
    currency: ["id", "code", "name"],
  },
  {
    paymentType: ["id", "code", "name"],
  },
  {
    request: ["id", "code"],
  },
  {
    shipment: ["id", "code"],
  },
  {
    lines: [
      "id",
      "chargePrice",
      "chargeableUnits",
      "lineAmount",
      "taxAmount",
      "totalAmount",
      "deletable",

      {
        taxes: [
          "id",
          "rate",
          "value",
          {
            tax: ["id", "code", "name"],
          },
        ],
      },
      {
        priceMode: ["id", "code", "name"],
      },
      {
        chargeElement: ["id", "code", "name"],
      },
    ],
  },
  "date",
  "exchangeRate",
  "referenceNumber",
  "referenceDate",
  "remarks",
  "deletable",
  "totalTaxAmount",
  {
    request: [
      "requestDate",
      "consigneeName",
      "consigneeAddress",
      "consigneeMobile",
      "shipperName",
      "shipperAddress",
      "shipperMobile",
      {
        consigneeState: ["id", "name", "code"],
      },
      {
        shipperState: ["id", "name", "code"],
      },
      {
        loadingMode: ["id", "name", "code"],
      },
      {
        shippingMode: ["id", "name", "code"],
      },
      {
        shippingDirection: ["id", "name", "code"],
      },
    ],
    shipment: [
      "shipmentDate",
      "consigneeName",
      "consigneeAddress",
      "consigneeMobile",
      "shipperName",
      "shipperAddress",
      "shipperMobile",
      {
        consigneeState: ["id", "name", "code"],
      },
      {
        shipperState: ["id", "name", "code"],
      },
      {
        loadingMode: ["id", "name", "code"],
      },
      {
        shippingMode: ["id", "name", "code"],
      },
      {
        shippingDirection: ["id", "name", "code"],
      },
    ],
  },

  {
    voucher: ["id", "code"],
  },
  {
    journalEntry: ["id", "code"],
  },

  {
    transactionType: ["id", "code", "name"],
  },
];
