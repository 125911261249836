/* eslint-disable no-useless-concat */
import React from "react";
import { useTranslation } from "react-i18next";
import { Paper, Icon, Stack } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import {
  DELETE_JOURNAL_ENTRY,
  DISAPPROVE_JOURNAL_ENTRY_MUTATION,
  JOURNAL_ENTRY,
} from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { useState } from "react";
import LongMenu from "../../Layout/MenuAppBar";
import RecordsTable from "./Components/RecordsTable";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";

const JournalEntryView = (props) => {
  const { t } = useTranslation();
  const journalEntryId = props.match.params.id?.trim();

  const { loading, data, refetch } = useQuery(
    gql`
      ${JOURNAL_ENTRY.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(journalEntryId) },
    }
  );

  const user = Globals.user;
  const branchId = data?.journalEntry?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;

  const canDisapprove =
    !loading &&
    data?.journalEntry?.approved &&
    data?.journalEntry?.sourceType?.code === "MANUAL" &&
    canAccessBranch &&
    user.hasPermission("accounting.journal_entry.disapprove");

  const canDelete =
    !loading &&
    user.hasPermission("accounting.journal_entry.delete") &&
    canAccessBranch &&
    !data?.journalEntry?.approved;

  const canEdit =
    !loading &&
    user.hasPermission("accounting.journal_entry.update") &&
    canAccessBranch &&
    !data?.journalEntry?.approved;

  const [openDelete, setOpenDelete] = useState(false);
  const [openDisapprove, setOpenDisapprove] = useState(false);

  const openDeleteDialog = (type) => {
    setOpenDelete(true);
  };
  const openDisapproveDialog = (type) => {
    setOpenDisapprove(true);
  };

  const editURL = () => {
    pushUrl(props, `/admin/finance/journal-entries/${journalEntryId}/edit`);
  };

  const icons = [
    {
      id: "edit",
      action: editURL,
      permission: canEdit,
    },
    {
      id: "delete",
      action: openDeleteDialog,
      permission: Boolean(canDelete),
    },
    {
      id: "disapprove",
      action: openDisapproveDialog,
      permission: Boolean(canDisapprove),
    },
  ];

  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.journalEntry ? (
    <NotFound />
  ) : (
    <>
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
        {openDelete && (
          <MutationWithDialog
            mutaion={DELETE_JOURNAL_ENTRY.query}
            setOpenDelete={setOpenDelete}
            openDelete={openDelete}
            dialogTitle={t("deleteJournalEntry")}
            dialogContent={t("deleteRecordMessage")}
            mutaionProps={{ variables: { id: parseInt(journalEntryId) } }}
            onCompleted={() => pushUrl(props, "/admin/finance/journal-entries")}
            onCompleteMessage={t("deleteMessage")}
          />
        )}

        {openDisapprove && (
          <MutationWithDialog
            mutaion={DISAPPROVE_JOURNAL_ENTRY_MUTATION.query}
            setOpenDelete={setOpenDisapprove}
            openDelete={openDisapprove}
            dialogTitle={t("disapprove")}
            dialogContent={t("disapprovedMessage")}
            mutaionProps={{ variables: { id: parseInt(journalEntryId) } }}
            onCompleted={() => refetch()}
            onCompleteMessage={t("successfullyDisapproved")}
          />
        )}
      </TitleAppBar>
      <RootStyleView>
        {" "}
        <Stack spacing={2} p={2}>
          <Grid container justifyContent="center" alignItems="center">
            <Paper container className={classesView.paper} component={Grid}>
              <KeyValuePair
                title={t("theDate")}
                value={data?.journalEntry?.date}
              />
              <KeyValuePair
                title={t("code")}
                value={data?.journalEntry?.code}
              />
              <KeyValuePair
                title={t("branch")}
                value={data?.journalEntry.branch.name}
              />
              <KeyValuePair
                title={t("type")}
                value={data?.journalEntry.type.name}
              />
              <KeyValuePair
                title={t("subsidiary")}
                value={data?.journalEntry?.subsidiary?.name}
              />
              <KeyValuePair
                title={t("description")}
                value={data?.journalEntry?.description}
              />

              <KeyValuePair
                title={t("createdBy")}
                value={
                  data?.journalEntry && (
                    <SpanLink
                      pathname={`/admin/users/${data?.journalEntry?.createdBy?.id}`}
                    >
                      {data?.journalEntry?.createdBy?.username}
                    </SpanLink>
                  )
                }
              />
              <KeyValuePair
                title={t("updatedBy")}
                value={
                  data?.journalEntry && (
                    <SpanLink
                      pathname={`/admin/users/${data?.journalEntry?.updatedBy?.id}`}
                    >
                      {data?.journalEntry?.updatedBy?.username}
                    </SpanLink>
                  )
                }
              />
              <KeyValuePair
                title={t("currency")}
                value={data?.journalEntry?.currency?.name}
              />
              <KeyValuePair
                title={t("exchangeRate")}
                value={data?.journalEntry?.exchangeRate}
              />
              <KeyValuePair
                title={t("approved")}
                value={
                  data?.journalEntry?.approved ? (
                    <Icon className={classesView.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
            </Paper>
          </Grid>
          <RecordsTable journalId={journalEntryId} />
        </Stack>{" "}
      </RootStyleView>{" "}
    </>
  );
};

export default JournalEntryView;
