import * as gqlb from "gql-query-builder";
const fields = [
  "id",
  "code",
  "name",
  "active",
  "shortName",
  "description",
  "import",
  "export",
  "ocean",
  "air",
  "land",
];
export const LIST_INCO_TERMS = gqlb.query({
  operation: "listIncoTerms",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: fields,
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListIncoTermsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const INCO_TERM_ID = gqlb.query({
  operation: "incoTerm",
  fields: [...fields, "cc", "cp", "pp", "date"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_INCO_TERM = gqlb.mutation({
  operation: "saveIncoTerm",
  fields: ["id", "code", "name", "active"],
  variables: {
    input: {
      type: "IncoTermInput",
      required: true,
    },
  },
});

export const DELETE_INCO_TERM = gqlb.mutation({
  operation: "deleteIncoTerm",
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
