import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Toolbar,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Add, Delete, Edit } from "@mui/icons-material";
import { gql, useMutation, useQuery } from "@apollo/client";
import { DELETE_JOURNAL_ENTRY_RECORD, JOURNAL_ENTRY_RECORDS } from "../Graphql";
import FullScreenLoading from "../../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import formatMoney from "../../../helpers/numbersDot";
import CustomSpinner from "../../HOC/FunctionComponents/CustomSpinner";
import MUITablePagination from "../../HOC/MUI/TablePagination/MUITablePagination";


const PREFIX = "RecordsTable";

const classes = {
  p: `${PREFIX}-p`,
  tap: `${PREFIX}-tap`,
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  focus: `${PREFIX}-focus`,
  destinations: `${PREFIX}-destinations`,
  deleteIcon: `${PREFIX}-deleteIcon`,
};

const StyledGrid = styled(Stack)(({ theme }) => ({
  [`& .${classes.p}`]: {
    fontSize: "14px",
    margin: "2px 1px",
    width: 100,
  },

  [`& .${classes.tap}`]: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    // flexDirection: "column",
    textTransform: "capitalize",
  },

  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.tableContainer}`]: {
    minHeight: "250px",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.toolbar}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.focus}`]: {
    backgroundColor: theme.palette.action.hover,
  },

  [`& .${classes.destinations}`]: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.deleteIcon}`]: {
    marginLeft: theme.spacing(1),
  },
}));


const RecordsTable = ({
  addRecord,
  notApproved,
  branchId,
  setExposeRefetchFunction,
  journalId,
  dispatch
}) => {
  const { t } = useTranslation();
  const [recordsPage, setRecordPage] = useState(0);
  const [recordRowsPerPage, setRecordRowsPerPage] = useState(20);
  const [deletedIds, setDeletedIds] = useState([]);

  const handleRecordsPage = (event, newPage) => {
    setRecordPage(newPage);
  };
  const handleRecordsRowsPerPage = (event) => {
    setRecordRowsPerPage(parseInt(event.target.value));
    setRecordPage(0);
  };

  const editable = notApproved && addRecord;

  const { data, loading, refetch } = useQuery(
    gql`
      ${JOURNAL_ENTRY_RECORDS.query}
    `,
    {
      variables: {
        first: recordRowsPerPage,
        page: recordsPage,
        id: parseInt(journalId)
      },
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        dispatch && dispatch({
          payload: {
            records: data.journalEntry.records.paginatorInfo?.total,
            debit: data.journalEntry.debit,
            credit: data.journalEntry.credit,
          }
        })
      },
    }
  );

  const [deleteRecord] = useMutation(
    gql`
      ${DELETE_JOURNAL_ENTRY_RECORD.query}
    `
  );

  const journalEntry = data?.journalEntry
  const recordsData = data?.journalEntry?.records

  // Use the callback to expose the child function to the parent
  useEffect(() => {
    setExposeRefetchFunction && setExposeRefetchFunction(() => refetch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setExposeRefetchFunction]);

  return (
    <StyledGrid component={Paper} alignItems="flex-start">
      {loading &&
        <FullScreenLoading minHeight={"100px"} />}
      {!loading && <Toolbar component={Paper} className={classes.toolbar} variant="dense">
        <Typography className={classes.title} color="inherit" variant="h6">
          {t("records")}
        </Typography>
        {editable ? (
          <IconButton
            color="primary"
            disabled={!branchId}
            onClick={() => {
              addRecord();
            }}
            aria-label="Add record"
            size="large"
          >
            <Add />
          </IconButton>
        ) : null}
      </Toolbar>}
      {!loading && <Grid container className={classes.table}>
        {recordsData?.data?.length <= 0 ? (
          <EmptyTableMessage loading={false} message={t("searchNoResult")} />
        ) : (
          <TableFixedHeaderWraper className={classes.tableContainer}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <FixedTableCell>{t("debit")}</FixedTableCell>
                  <FixedTableCell>{t("credit")}</FixedTableCell>
                  <FixedTableCell>{t("glAccount")}</FixedTableCell>
                  <FixedTableCell>{t("description")}</FixedTableCell>
                  {editable ? <FixedTableCell></FixedTableCell> : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {recordsData?.data?.length > 0 && recordsData?.data?.map((row, index) => (
                  <TableRow hover key={index}>
                    <FixedTableCell>{formatMoney(row.debit)}</FixedTableCell>
                    <FixedTableCell>{formatMoney(row.credit)}</FixedTableCell>
                    <FixedTableCell>{row.glAccount.name}</FixedTableCell>
                    <FixedTableCell>{row.description}</FixedTableCell>

                    {editable ? (
                      <FixedTableCell>
                        <IconButton
                          size="small"
                          onClick={() =>
                            addRecord(row)
                          }
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          size="small"
                          className={classes.deleteIcon}
                          disabled={deletedIds.includes(row.id)}
                          onClick={() => {
                            setDeletedIds(prev => [...prev, row.id])
                            deleteRecord({ variables: { id: row.id } })
                              .then(() => {
                                refetch()
                              })
                              .catch((err) => console.log(err))
                          }}
                        >
                          {deletedIds.includes(row.id) ?
                            <CustomSpinner name="ClockLoader" size={20} /> : <Delete />}
                        </IconButton>
                      </FixedTableCell>
                    ) : null}
                  </TableRow>
                ))}
                <TableRow>
                  <FixedTableCell>
                    <Typography component="span" variant="h6">
                      {formatMoney(Math.abs(journalEntry.debit))}
                    </Typography>
                  </FixedTableCell>
                  <FixedTableCell>
                    <Typography component="span" variant="h6">
                      {formatMoney(Math.abs(journalEntry.credit))}
                    </Typography>
                  </FixedTableCell>
                  <FixedTableCell>
                    {t("deviation")}
                    <Typography component="span" variant="h6">
                      {" ( " + formatMoney(Math.abs(journalEntry.credit - journalEntry.debit)) + " ) "}
                    </Typography>

                    {journalEntry.credit - journalEntry.debit !== 0 && (
                      <Typography
                        component="span"
                        variant="caption"
                        color="error"
                      >
                        {t("deviationValidation")}
                      </Typography>
                    )}
                  </FixedTableCell>
                  <FixedTableCell colSpan={2} />
                </TableRow>
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
        )}
        <MUITablePagination
          count={recordsData?.paginatorInfo?.total ?? 0}
          rowsPerPage={recordRowsPerPage}
          page={recordsPage}
          onPageChange={handleRecordsPage}
          onRowsPerPageChange={handleRecordsRowsPerPage}
        />
      </Grid>}
    </StyledGrid>
  );
};

export default RecordsTable;
