import * as gqlb from "gql-query-builder";
const adjustmentFields = [
  "code",
  "id",
  "adjustmentDate",
  "glApproved",
  "approved",
  "amount",
  "exchangeRate",
  {
    accountType: ["code", "name"],
  },
  {
    type: ["code", "name"],
  },
  {
    branch: ["id", "name"],
  },
  {
    vendor: ["id", "code", "name"],
  },
  {
    employee: ["id", "code", "name"],
  },
  {
    customer: ["id", "code", "name"],
  },
  {
    agent: ["id", "code", "name"],
  },
  {
    agency: ["id", "code", "name"],
  },
  {
    carrier: ["id", "code", "name"],
  },
  {
    currency: ["id", "name"],
  },
  {
    bill: ["id", "code"],
  },
  {
    invoice: ["id", "code"],
  },

  // {
  //   deliveryAgent: ["id", "name"],
  // },
  // {
  //   customer: ["id", "name"],
  // },
];
export const LIST_ADJUSTMENT_QUERY = gqlb.query({
  operation: "listAdjustments",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "total",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: adjustmentFields,
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListAdjustmentsFilterInput",
      required: true,
    },
    first: {
      type: "Int",
      required: true,
      value: 15,
    },
    page: {
      type: "Int",
      value: 1,
    },
  },
});

export const ADJUSTMENT_BY_ID_QUERY = gqlb.query({
  operation: "adjustment",
  fields: [
    ...adjustmentFields,
    "remarks",
    {
      transactionType: ["id", "code", "name"],
    },
    {
      journalEntry: ["id", "code"],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const ADJUSTMENT_ID_AND_PRINT = gqlb.query([
  {
    operation: "adjustment",
    fields: [
      ...adjustmentFields,
      "remarks",
      "date",
      "notes",
      {
        transactionType: ["id", "code", "name"],
      },
      {
        journalEntry: ["id", "code"],
      },
      {
        glAccount: ["id", "name"],
      },
      {
        safe: ["id", "name"],
      },
      {
        toSafe: ["id", "name"],
      },
    ],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
]);

export const SAVE_ADJUSTMENT_MUTATION = gqlb.mutation({
  operation: "saveAdjustment",
  fields: ["id", "code"],
  variables: {
    input: {
      type: "AdjustmentInput",
      required: true,
    },
  },
});

export const DELETE_ADJUSTMENT_MUTATION = gqlb.mutation({
  operation: "deleteVoucher",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const APPROVE_ADJUSTMENT_MUTATION = gqlb.mutation({
  operation: "approveAdjustment",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DISAPPROVE_ADJUSTMENT_MUTATION = gqlb.mutation({
  operation: "disapproveAdjustment",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const GL_APPROVE_ADJUSTMENT_MUTATION = gqlb.mutation({
  operation: "glApproveAdjustment",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const EXPOERT_ADJUSTMENTS = gqlb.mutation({
  operation: "exportAdjustments",
  fields: [],
  variables: {
    input: {
      type: "ExportAdjustmentsInput",
      required: true,
    },
  },
});
