import * as gqlb from "gql-query-builder";

export const ACCOUNTING_SETTINGS = gqlb.query({
  operation: "accountingSettings",
  fields: [
    "previousDate",
    "updateSequence",
    "accountCostCenter",
    "costCenterAutoSave",
    "costCenterUpdatable",
    "displayAccountCode",
    "accountFixedLength",
    "closingAutoShare",
    "inheritJournalDescription",
    "accountMaxLength",
    "level1Length",
    "level2Length",
    "level3Length",
    "level4Length",
    "level5Length",
    "level6Length",
    "level7Length",
    "level8Length",
    "level9Length",
    "level10Length",
  ],
  variables: {},
});

export const UPDATE_ACCOUNTING_SETTINGS = gqlb.mutation({
  operation: "updateAccountingSettings",
  fields: ["level1Length"],
  variables: {
    input: {
      type: " AccountingSettingsInput",
      required: true,
    },
  },
});
