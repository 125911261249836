import * as React from "react";
import { useTranslation } from "react-i18next";
import { FormHelperText, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useEffect } from "react";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { SAVE_WEIGHT_LIST_ROUTE } from "./Graphql";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { usePriceListContext } from "./PriceListContext";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";

export default function PriceListWeightRouts(props) {
  const {
    RouteList,
    routesIndex,
    setRoutesList,
    handelCloseDialog,
    weightErrorMessage,
    setWeightErrorMessage,
    weightIndex,
    dialog,
  } = usePriceListContext();
  const { handleSubmit, setValue, control, setError, errors } = useForm();
  const { t } = useTranslation();

  const [savePackageTypeRoutes, { loading: savePriceListLoad }] = useMutation(
    gql`
      ${SAVE_WEIGHT_LIST_ROUTE.query}
    `
  );

  useEffect(() => {
    if (weightIndex?.update) {
      const weight = RouteList[routesIndex?.index]?.weights[weightIndex?.index];
      setValue("price", weight?.price);
      setValue("weightTo", weight?.weightTo);
      setValue("weightFrom", weight?.weightFrom);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weightIndex]);

  const onSubmit = (data) => {
    for (const key in data) {
      if (
        [undefined, "", null].includes(data[key]) ||
        (Array.isArray(data[key]) && data[key].length === 0)
      ) {
        delete data[key];
      }
    }
    const newRoute = {
      weightFrom: parseFloat(data.weightFrom),
      weightTo: parseFloat(data.weightTo),
      price: parseFloat(data.price),
    };
    const weights = RouteList[routesIndex?.index]?.weights
      ? [...RouteList[routesIndex?.index]?.weights]
      : [];
    const updateRoutes = RouteList ? [...RouteList] : [];

    if (weightIndex?.update) {
      savePackageTypeRoutes({
        variables: {
          input: {
            id: weights[weightIndex?.index]?.id,
            routeId: RouteList[routesIndex?.index]?.id,
            price: parseFloat(data.price),
            weightTo: parseFloat(data.weightTo),
            weightFrom: parseFloat(data.weightFrom),
          },
        },
      })
        .then((data) => {
          newRoute.id = data.data?.saveRouteWeight?.id;
          updateRoutes[routesIndex?.index].weights[weightIndex?.index] =
            newRoute;
          setRoutesList(updateRoutes);
          handelCloseDialog();
        })
        .catch(({ graphQLErrors }) => {
          console.log(graphQLErrors);
          if (graphQLErrors?.[0]?.extensions?.category === "validation") {
            setValidationError(graphQLErrors, setError);
          } else {
            setWeightErrorMessage(graphQLErrors[0]?.message);
          }
        });
    } else {
      savePackageTypeRoutes({
        variables: {
          input: {
            routeId: RouteList[routesIndex?.index]?.id,
            price: parseFloat(data.price),
            weightTo: parseFloat(data.weightTo),
            weightFrom: parseFloat(data.weightFrom),
          },
        },
      })
        .then((data) => {
          newRoute.id = data.data?.saveRouteWeight?.id;
          weights.push(newRoute);
          updateRoutes[routesIndex.index] = {
            ...updateRoutes[routesIndex.index],
            weights: weights,
          };
          setRoutesList(updateRoutes);
          handelCloseDialog();
        })
        .catch(({ graphQLErrors }) => {
          console.log(graphQLErrors);
          if (graphQLErrors?.[0]?.extensions?.category === "validation") {
            setValidationError(graphQLErrors, setError);
          } else {
            setWeightErrorMessage(graphQLErrors[0]?.message);
          }
        });
    }
  };
  const body = (
    <Grid container justifyContent="flex-start" aligns="center" spacing={2}>
      <Grid xs={12} sm={12} aligns="flex-start">
        <ControlMUItextField
          control={control}
          errors={errors}
          type={"number"}
          name={"weightFrom"}
          label={t("fromWeight")}
          rules={{ required: t("fieldIsRequired") }}
        />
      </Grid>
      <Grid xs={12} sm={12} aligns="flex-start">
        <ControlMUItextField
          control={control}
          errors={errors}
          type={"number"}
          name={"weightTo"}
          label={t("toWeight")}
          rules={{ required: t("fieldIsRequired") }}
        />
      </Grid>

      <Grid xs={12} sm={12} aligns="flex-start">
        <ControlMUItextField
          control={control}
          errors={errors}
          type={"number"}
          name={"price"}
          label={t("price")}
          rules={{ required: t("fieldIsRequired") }}
        />
      </Grid>
      {weightErrorMessage && (
        <FormHelperText error>{weightErrorMessage}</FormHelperText>
      )}
    </Grid>
  );
  return (
    <CustomDialog
      fullWidth
      maxWidth="xs"
      open={dialog.dialog}
      onClose={handelCloseDialog}
      title={t("weightPrice")}
      content={body}
      actions={
        <>
          <Button onClick={handelCloseDialog}>{t("cancel")}</Button>
          <Button onClick={handleSubmit(onSubmit)}>
            {savePriceListLoad ? <ButtonLoading /> : t("confirm")}
          </Button>
        </>
      }
    ></CustomDialog>
  );
}
