/* eslint-disable no-useless-concat */
import React, { Fragment, useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import {
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  Toolbar,
  Icon,
  Dialog,
  DialogContent,
  Collapse,
  Stack,
} from "@mui/material";

import { COUNTRY_ID, SAVE_COUNTRY, SAVE_STATE } from "./Graphql";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useForm } from "react-hook-form";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { Add, Edit } from "@mui/icons-material";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { isEmpty } from "lodash";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { useSnackbar } from "notistack";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";

const CountryForm = (props) => {
  const [active, setActive] = useState({
    country: true,
    states: true,
  });
  const [dialogState, setDialogState] = useState(false);
  const [countryLoading, setCountryLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [updateStates, setUpdateStates] = useState(null);
  const [autocompleteValues, setAutocompleteValues] = useState({
    zoneBranch: null,
    subzoneBranch: null,
  });
  const { enqueueSnackbar } = useSnackbar();

  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const openDialog = (subzone) => {
    setDialogState(true);
    subzone && setUpdateStates(subzone);
  };
  const closeDialog = () => {
    setDialogState(false);
    setUpdateStates(null);
    setStatesValue("code", "");
    setStatesValue("name", "");
    setAutocompleteValues((prev) => ({
      ...prev,
      subzoneBranch: null,
    }));
    setActive((prev) => ({
      ...prev,
      states: true,
    }));
  };

  const handelActivate = (e, parameter) => {
    const active = e.target.checked;
    setActive((prev) => ({
      ...prev,
      [parameter]: active,
    }));
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
  } = useForm();

  const {
    handleSubmit: handleStatesSubmit,
    control: statesControl,
    formState: { errors: statesErrors },
    setValue: setStatesValue,
    setError: setStatesError,
  } = useForm();

  const countryId = parseInt(props.match.params.id);
  const { t } = useTranslation();
  const { loading, data } = useQuery(
    gql`
      ${COUNTRY_ID.query}
    `,
    {
      skip: !countryId,
      fetchPolicy: "network-only",
      variables: { id: parseInt(countryId) },
      onCompleted: (data) => {
        const states = data?.country?.states ?? [];
        setStates(states);
        setValue("code", data?.country?.code);
        setValue("name", data?.country?.name);
        setActive((prev) => ({
          ...prev,
          country: data?.country?.active,
        }));
      },
    }
  );
  const statesArr = data?.country?.states ?? [];
  const [states, setStates] = useState(statesArr);

  const [saveCountry, { loading: saveCountryLoading }] = useMutation(
    gql`
      ${SAVE_COUNTRY.query}
    `
  );
  const [saveState] = useMutation(
    gql`
      ${SAVE_STATE.query}
    `
  );

  const onSubmitStates = (data, parentId, statesId) => {
    for (const key in data) {
      if (data[key] === "" || data[key] === null) {
        delete data[key];
      }
    }
    saveState({
      variables: {
        input: {
          ...(parentId && { countryId: parentId }),
          ...(statesId && { id: statesId }),
          active: active.states,
          ...data,
        },
      },
    })
      .then((data) => {
        const checksubZone = states.map(
          (ele) => ele.id === data.data.saveState.id
        );
        const check = checksubZone.includes(true);
        if (check) {
          const index = states.findIndex(
            (x) => x.id === data.data.saveState.id
          );
          states[index] = data.data.saveState;
          setStates(states);
        } else {
          states.push(data.data.saveState);
          setStates(states);
        }
        closeDialog();
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setStatesError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "" || data[key] === null) {
        delete data[key];
      }
    }
    setCountryLoading(true);
    saveCountry({
      variables: {
        input: {
          ...(countryId && { id: countryId }),
          active: active.country,
          ...data,
        },
      },
    })
      .then((data) => {
        setCountryLoading(false);

        !countryId && pushUrl(props, `${data.data.saveCountry.id}/edit`);
        countryId &&
          pushUrl(props, `/admin/countries/${data?.data?.saveCountry?.id}`);
      })
      .catch(({ graphQLErrors }) => {
        setCountryLoading(false);

        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.country && countryId ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={props.match.path} />
      <Stack spacing={2} p={2}>
        <Paper className={classesForm.box}>
          <Form
            setActive={setActive}
            setAutocomplete={setAutocompleteValues}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            control={control}
            errors={errors}
            active={active}
            handelActivate={handelActivate}
            autocomplete={autocompleteValues}
            saveCountryLoading={saveCountryLoading}
            countryLoading={countryLoading}
            countryId={countryId}
          />
        </Paper>
      </Stack>
      <Dialog fullWidth maxWidth="xs" open={dialogState} onClose={closeDialog}>
        <DialogContent>
          <Form
            parentId={countryId}
            updateStates={updateStates}
            setStatesValue={setStatesValue}
            setActive={setActive}
            setAutocomplete={setAutocompleteValues}
            handleStatesSubmit={handleStatesSubmit}
            onSubmitStates={onSubmitStates}
            statesControl={statesControl}
            statesErrors={statesErrors}
            active={active}
            handelActivate={handelActivate}
            autocomplete={autocompleteValues}
            saveCountryLoading={saveCountryLoading}
            countryId={countryId}
          />
        </DialogContent>
      </Dialog>
      <Stack spacing={2} style={{ paddingInline: "16px" }}>
        <Paper
          container
          sm={12}
          md={12}
          className={classesForm.table}
          component={Grid}
        >
          <Toolbar className={classesForm.toolbar} variant="dense">
            <Typography color="inherit" variant="h6">
              {t("state")}
            </Typography>

            <IconButton
              disabled={!countryId}
              onClick={() => openDialog()}
              aria-label="Add states"
              color="primary"
              size="large"
            >
              <Add />
            </IconButton>
          </Toolbar>
          <TableFixedHeaderWraper>
            {loading || isEmpty(states) ? (
              <EmptyTableMessage
                loading={loading}
                message={t("searchNoResult")}
              />
            ) : (
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell>{t("code")}</FixedTableCell>
                    <FixedTableCell>{t("name")}</FixedTableCell>
                    <FixedTableCell>{t("active")}</FixedTableCell>
                    <FixedTableCell></FixedTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {states &&
                    (rowsPerPage > 0
                      ? states.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : states
                    )?.map((row, index) => (
                      <TableRow hover key={index}>
                        <FixedTableCell>{row.code}</FixedTableCell>
                        <FixedTableCell>{row.name}</FixedTableCell>
                        <FixedTableCell>
                          {row?.active ? (
                            <Icon className={classesForm.addIcon}>
                              check_circle_outline
                            </Icon>
                          ) : (
                            <Icon color="error">highlight_off</Icon>
                          )}
                        </FixedTableCell>
                        <FixedTableCell>
                          <IconButton
                            size="small"
                            onClick={() => openDialog(row)}
                          >
                            <Edit />
                          </IconButton>
                        </FixedTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </TableFixedHeaderWraper>
          <MUITablePagination
            count={states.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePage}
            onRowsPerPageChange={handleRowsPerPage}
          />
        </Paper>
      </Stack>
    </RootStyleForm>
  );
};

export default CountryForm;

const Form = (props) => {
  const {
    parentId,
    updateStates,
    setStatesValue,
    setActive,
    setAutocomplete,
    handleStatesSubmit,
    onSubmitStates,
    handleSubmit,
    onSubmit,
    statesControl,
    control,
    statesErrors,
    errors,
    active,
    handelActivate,
    saveCountryLoading,
    countryLoading,
  } = props;
  useEffect(() => {
    if (updateStates) {
      setStatesValue("code", updateStates?.code);
      setStatesValue("name", updateStates?.name);
      setActive((prev) => ({
        ...prev,
        states: updateStates?.active,
      }));
      setAutocomplete((prev) => ({
        ...prev,
        subzoneBranch: updateStates?.branch,
      }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();

  return (
    <Fragment>
      <Grid
        container
        spacing={2}
        component="form"
        onSubmit={
          parentId
            ? handleStatesSubmit((data) =>
                onSubmitStates(data, parentId, updateStates?.id)
              )
            : handleSubmit(onSubmit)
        }
      >
        <Grid
          container
          justifyContent="space-between"
          sm={12}
          alignItems={"center"}
        >
          <Grid xs={6}>
            <Typography variant="h6" color={"text.primary"}>
              {t(parentId && "state")}
            </Typography>
          </Grid>
          <Grid xs={6} textAlign={"end"}>
            <FormControlLabel
              checked={parentId ? active.states : active.country}
              control={<Switch color="primary" />}
              label={t("active")}
              labelPlacement="start"
              onChange={(e) =>
                handelActivate(e, parentId ? "states" : "country")
              }
            />
          </Grid>
        </Grid>

        <Grid xs={12} sm={parentId ? 12 : 6}>
          <ControlMUItextField
            control={parentId ? statesControl : control}
            errors={parentId ? statesErrors : errors}
            name={"code"}
            label={t("code")}
          />
        </Grid>
        <Grid xs={12} sm={parentId ? 12 : 6}>
          <ControlMUItextField
            control={parentId ? statesControl : control}
            errors={parentId ? statesErrors : errors}
            name={"name"}
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>

        <Grid container sm={12} justifyContent="flex-end" m={0}>
          {parentId ? (
            <FormButton disabled={saveCountryLoading}>
              {saveCountryLoading ? <ButtonLoading /> : t("save")}
            </FormButton>
          ) : (
            <FormButton disabled={countryLoading}>
              {countryLoading ? <ButtonLoading /> : t("save")}
            </FormButton>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};
