/* eslint-disable no-useless-concat */
import React, { useState } from "react";

import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Paper, Icon } from "@mui/material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { DELETE_PACKAGE_TYPE, PACKAGE_TYPE_ID } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";

const PackageTypeView = (props) => {
  const packageTypeId = props.match.params.id?.trim();
  const { t } = useTranslation();
  const user = Globals.user;
  const [openDelete, setOpenDelete] = useState(false);

  const openDeleteDialog = (type) => {
    setOpenDelete(true);
  };
  const { loading, data } = useQuery(
    gql`
      ${PACKAGE_TYPE_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(packageTypeId) },
    }
  );
  const packageData = data?.packageType;

  const canDelete =
    user?.hasPermission("freight.package_type.delete") &&
    packageData?.deletable;

  const icons = [
    {
      id: "edit",
      action: () =>
        pushUrl(props, `/admin/package-types/${packageTypeId}/edit`),
      permission: user?.hasPermission("freight.package_type.update"),
    },
    {
      id: "delete",
      action: openDeleteDialog,
      permission: Boolean(canDelete),
    },
  ];
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.packageType ? (
    <NotFound />
  ) : (
    <>
      {openDelete && (
        <MutationWithDialog
          mutaion={DELETE_PACKAGE_TYPE.query}
          setOpenDelete={setOpenDelete}
          openDelete={openDelete}
          dialogTitle={t("deleteRecord")}
          dialogContent={t("deleteRecordMessage")}
          mutaionProps={{
            variables: { id: parseInt(packageTypeId) },
          }}
          onCompleted={() => pushUrl(props, `/admin/package-types`)}
          onCompleteMessage={t("successfullyDeletedRecord")}
        />
      )}
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>

      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair title={t("code")} value={packageData?.code} />
          <KeyValuePair title={t("name")} value={packageData?.name} />
          <KeyValuePair title={t("length")} value={packageData?.length} />
          <KeyValuePair title={t("breadth")} value={packageData?.breadth} />
          <KeyValuePair title={t("height")} value={packageData?.height} />
          <KeyValuePair
            title={t("weight")}
            value={packageData?.isContainer ? packageData?.weight : null}
          />
          <KeyValuePair title={t("volume")} value={packageData?.volume} />
          <KeyValuePair
            title={t("container")}
            value={
              packageData?.isContainer ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("roro")}
            value={
              !packageData?.isContainer ? (
                <Icon color="error">highlight_off</Icon>
              ) : packageData?.isRoro ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("air")}
            value={
              packageData?.air ? (
                <>
                  <Icon className={classesView.iconColor}>
                    check_circle_outline
                  </Icon>
                </>
              ) : (
                <>
                  <Icon color="error">highlight_off</Icon>
                </>
              )
            }
          />
          <KeyValuePair
            title={t("land")}
            value={
              packageData?.land ? (
                <>
                  <Icon className={classesView.iconColor}>
                    check_circle_outline
                  </Icon>
                </>
              ) : (
                <>
                  <Icon color="error">highlight_off</Icon>
                </>
              )
            }
          />

          <KeyValuePair
            title={t("ocean")}
            value={
              packageData?.ocean ? (
                <>
                  <Icon className={classesView.iconColor}>
                    check_circle_outline
                  </Icon>
                </>
              ) : (
                <>
                  <Icon color="error">highlight_off</Icon>
                </>
              )
            }
          />
          <KeyValuePair
            title={t("active")}
            value={
              packageData?.active ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("import")}
            value={
              packageData?.import ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("export")}
            value={
              packageData?.export ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
        </Paper>
      </RootStyleView>
    </>
  );
};

export default PackageTypeView;
