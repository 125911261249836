import { useQuery, gql } from "@apollo/client";
import { Paper } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { FINANCIAL_SHEET } from "./Graphql";
// import FinancialSheetTables from "./PriceListTables";
import Grid from "@mui/material/Unstable_Grid2";

import { Globals } from "../HOC/Classes/Globals";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import FinancialSheetTables from "./FinancialSheetTables";
import SpanLink from "../HOC/CustomComponents/SpanLink";

import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";

const FinancialSheetView = (props) => {
  const { t } = useTranslation();
  const financialSheetId = parseInt(props.match.params.id);

  const { data, loading } = useQuery(
    gql`
      ${FINANCIAL_SHEET.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: { id: financialSheetId },
      onCompleted: (data) => {},
    }
  );
  const user = Globals.user;
  const icons = [
    {
      id: "edit",
      action: () =>
        pushUrl(props, `/admin/financial-sheets/${financialSheetId}/edit`),
      permission:
        user?.hasPermission("accounting.financial_sheet.update") &&
        data?.financialSheet?.editable,
    },
  ];
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.financialSheet ? (
    <NotFound />
  ) : (
    <>
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>
      <RootStyleView spacing={2} p={2} justifyContent="center">
        {loading ? (
          <FullScreenLoading minHeight="15%" />
        ) : (
          <>
            <Paper container className={classesView.paper} component={Grid}>
              <KeyValuePair
                md={6}
                title={t("code")}
                value={data?.financialSheet?.code}
              />
              <KeyValuePair
                md={6}
                title={t("manifestName")}
                value={data?.financialSheet.name}
              />
              <KeyValuePair
                md={6}
                title={t("branch")}
                value={
                  data?.financialSheet?.branch?.name && (
                    <SpanLink
                      pathname={`/admin/branches/${data?.financialSheet?.branch?.id}`}
                    >
                      {data?.financialSheet?.branch?.name}
                    </SpanLink>
                  )
                }
              />
              <KeyValuePair
                md={6}
                title={t("notes")}
                value={data?.financialSheet.notes}
              />
            </Paper>
          </>
        )}

        {data?.financialSheet?.lines && (
          <FinancialSheetTables LineList={data.financialSheet?.lines} />
        )}
      </RootStyleView>
    </>
  );
};

export default FinancialSheetView;
