import React, { useEffect, useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import {
  TableBody,
  TableRow,
  Button,
  Icon,
  Checkbox,
  TableHead,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Collapse,
} from "@mui/material";
import { Search } from "@mui/icons-material";
// import { LIST_REQUESTS } from "../";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useWidth from "../../Hooks/useWidth";
import config from "../../config.json";
import Grid from "@mui/material/Unstable_Grid2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_AGENCIES_DROPDOWN,
  LIST_COUNTRIES_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_SHIPPING_PORTS_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import { MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete";
import moment from "moment";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
// import { LIST_REQUESTS } from "./Graphql";

import {
  ARRIVED_SHIPMENTS,
  LIST_SHIPMENTS,
  SCHEDULE_SHIPMENTS,
  TRANSPORTING_SHIPMENTS,
} from "./Graphql";
import { useSnackbar } from "notistack";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { RootStyleList, classes } from "../../GlobalStyles/ListStyle";
import { dateFormat } from "../../helpers/dateFunctions";
import clsx from "clsx";

const ScheduleShipments = (props) => {
  const listType =
    props.match.params.type === "schedule"
      ? "booking"
      : props.match.params.type === "transport"
      ? "loading"
      : props.match.params.type === "arrived" && "transporting";

  const { t } = useTranslation();
  const screenWidth = useWidth();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [refetch, setrefetch] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const [autocompleteValues, setAutocompleteValues] = useState({
    destinationAgency: null,
    originAgency: null,
    branch: null,
    loadingMode: null,
    status: null,
    originCountry: null,
    destinationCountry: null,
    originPort: null,
    destinationPort: null,
    mode: [],
    shippingDirection: [],
  });
  const [noData, setNoData] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(
    urlQuery["rowsPerPage"] ?? config.app.pageSize
  );
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";
  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const [scheduledDepartureDate, setScheduledDepartureDate] = useState([
    urlQuery["fromDateSDD"] ? urlQuery["fromDateSDD"] : null,
    urlQuery["toDateSDD"] ? urlQuery["toDateSDD"] : null,
  ]);
  const [scheduledArrivalDate, setScheduledArrivalDate] = useState([
    urlQuery["fromDateSAD"] ? urlQuery["fromDateSAD"] : null,
    urlQuery["toDateSAD"] ? urlQuery["toDateSAD"] : null,
  ]);
  const [actualDepartureDate, setActualDepartureDate] = useState([
    urlQuery["fromDateADD"] ? urlQuery["fromDateADD"] : null,
    urlQuery["toDateADD"] ? urlQuery["toDateADD"] : null,
  ]);
  const [actualArrivalDate, setActualArrivalDate] = useState([
    urlQuery["fromDateAAD"] ? urlQuery["fromDateAAD"] : null,
    urlQuery["toDateAAD"] ? urlQuery["toDateAAD"] : null,
  ]);
  const [shipmentDate, setShipmentDate] = useState([
    urlQuery["shipmentFromDate"] ? urlQuery["shipmentFromDate"] : null,
    urlQuery["shipmentToDate"] ? urlQuery["shipmentToDate"] : null,
  ]);
  const fromDateSDD = moment(scheduledDepartureDate[0])
    .locale("en")
    .format("YYYY-MM-DD");
  const toDateSDD = moment(scheduledDepartureDate[1])
    .locale("en")
    .format("YYYY-MM-DD");
  const fromDateSAD = moment(scheduledArrivalDate[0])
    .locale("en")
    .format("YYYY-MM-DD");
  const toDateSAD = moment(scheduledArrivalDate[1])
    .locale("en")
    .format("YYYY-MM-DD");

  const fromDateADD = moment(actualDepartureDate[0])
    .locale("en")
    .format("YYYY-MM-DD");
  const toDateADD = moment(actualDepartureDate[1])
    .locale("en")
    .format("YYYY-MM-DD");
  const fromDateAAD = moment(actualArrivalDate[0])
    .locale("en")
    .format("YYYY-MM-DD");
  const toDateAAD = moment(actualArrivalDate[1])
    .locale("en")
    .format("YYYY-MM-DD");
  const shipmentFromDate = moment(shipmentDate[0])
    .locale("en")
    .format("YYYY-MM-DD");
  const shipmentToDate = moment(shipmentDate[1])
    .locale("en")
    .format("YYYY-MM-DD");
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      ...(scheduledDepartureDate[0] && { fromDateSDD }),
      ...(scheduledDepartureDate[1] && { toDateSDD }),
      ...(scheduledArrivalDate[0] && { fromDateSAD }),
      ...(scheduledArrivalDate[1] && { toDateSAD }),
      ...(actualDepartureDate[0] && { fromDateADD }),
      ...(actualDepartureDate[1] && { toDateADD }),
      ...(actualArrivalDate[0] && { fromDateAAD }),
      ...(actualArrivalDate[1] && { toDateAAD }),
      ...(shipmentDate[0] && { shipmentFromDate }),
      ...(shipmentDate[1] && { shipmentToDate }),
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      if (urlQuery["shippingMode"]) {
        urlQuery["shippingMode"] = urlQuery["shippingMode"].split(",");
      }
      if (urlQuery["shippingDirection"]) {
        urlQuery["shippingDirection"] =
          urlQuery["shippingDirection"].split(",");
      }
      if (urlQuery["loadingMode"]) {
        urlQuery["loadingMode"] = urlQuery["loadingMode"].split(",");
      }
      if (urlQuery["status"]) {
        urlQuery["status"] = urlQuery["status"].split(",");
      }
      if (urlQuery.fromDateSDD || urlQuery.toDateSDD) {
        const scheduledDepartureDate = {
          ...(urlQuery?.fromDateSDD && { fromDate: urlQuery?.fromDateSDD }),
          ...(urlQuery?.toDateSDD && { toDate: urlQuery?.toDateSDD }),
        };
        delete urlQuery["fromDateSDD"];
        delete urlQuery["toDateSDD"];
        urlQuery.scheduledDepartureDate = scheduledDepartureDate;
      }
      if (urlQuery.fromDateSAD || urlQuery.toDateSAD) {
        const scheduledArrivalDate = {
          ...(urlQuery?.fromDateSAD && {
            fromDate: urlQuery?.fromDateSAD,
          }),
          ...(urlQuery?.toDateSAD && {
            toDate: urlQuery?.toDateSAD,
          }),
        };
        delete urlQuery["fromDateSAD"];
        delete urlQuery["toDateSAD"];
        urlQuery.scheduledArrivalDate = scheduledArrivalDate;
      }

      if (urlQuery.fromDateADD || urlQuery.toDateADD) {
        const actualDepartureDate = {
          ...(urlQuery?.fromDateADD && { fromDate: urlQuery?.fromDateADD }),
          ...(urlQuery?.toDateADD && { toDate: urlQuery?.toDateADD }),
        };
        delete urlQuery["fromDateADD"];
        delete urlQuery["toDateADD"];
        urlQuery.actualDepartureDate = actualDepartureDate;
      }
      if (urlQuery.fromDateAAD || urlQuery.toDateAAD) {
        const actualArrivalDate = {
          ...(urlQuery?.fromDateAAD && {
            fromDate: urlQuery?.fromDateAAD,
          }),
          ...(urlQuery?.toDateAAD && {
            toDate: urlQuery?.toDateAAD,
          }),
        };
        delete urlQuery["fromDateAAD"];
        delete urlQuery["toDateAAD"];
        urlQuery.actualArrivalDate = actualArrivalDate;
      }

      if (urlQuery.shipmentFromDate || urlQuery.shipmentToDate) {
        const shipmentDate = {
          ...(urlQuery?.shipmentFromDate && {
            fromDate: urlQuery?.shipmentFromDate,
          }),
          ...(urlQuery?.shipmentToDate && {
            toDate: urlQuery?.shipmentToDate,
          }),
        };
        delete urlQuery["shipmentFromDate"];
        delete urlQuery["shipmentToDate"];
        urlQuery.shipmentDate = shipmentDate;
      }
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());
  // const [openExport, setOpenExport] = useState(false);
  const STATUS_SHIPMENT =
    listType === "booking"
      ? SCHEDULE_SHIPMENTS
      : listType === "loading"
      ? TRANSPORTING_SHIPMENTS
      : ARRIVED_SHIPMENTS;
  const [schedulingShipment, { loading: schedulingShipmentLoading }] =
    useMutation(
      gql`
        ${STATUS_SHIPMENT.query}
      `
    );
  const {
    data,
    loading,
    refetch: refetchList,
  } = useQuery(
    gql`
      ${LIST_SHIPMENTS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          status: listType?.toUpperCase(),
          ...(listType.toUpperCase() === "BOOKING" && {
            loadingMode: { loadingMode: ["FTL", "FCL"], includeNull: true },
          }),
          ...(search.code && { code: search.code }),
          ...(search.branchId && {
            branchId: search.branchId,
          }),
          ...(search.shippingMode && {
            shippingMode: search.shippingMode,
          }),
          ...(search.shippingDirection && {
            shippingDirection: search.shippingDirection,
          }),
          // ...(search.status && {
          //   status: search.status,
          // }),
          //
          ...(search.loadingMode && {
            loadingMode: search.loadingMode,
          }),
          ...(search.destinationAgencyId && {
            destinationAgencyId: search.destinationAgencyId,
          }),
          ...(search.originAgencyId && {
            originAgencyId: search.originAgencyId,
          }),
          ...(search.destinationPortId && {
            destinationPortId: search.destinationPortId,
          }),
          ...(search.originPortId && { originPortId: search.originPortId }),
          ...(search.originCountryId && {
            originCountryId: search.originCountryId,
          }),
          ...(search.destinationCountryId && {
            destinationCountryId: search.destinationCountryId,
          }),
          ...(search.scheduledDepartureDate && {
            scheduledDepartureDate: search.scheduledDepartureDate,
          }),
          ...(search.scheduledArrivalDate && {
            scheduledArrivalDate: search.scheduledArrivalDate,
          }),
          ...(search.actualDepartureDate && {
            actualDepartureDate: search.actualDepartureDate,
          }),
          ...(search.actualArrivalDate && {
            actualArrivalDate: search.actualArrivalDate,
          }),
          ...(search.shipmentDate && { shipmentDate: search.shipmentDate }),
        },
      },
      onCompleted: () => {
        setNoData(false);
      },
    }
  );
  const schedulShipments = (ids) => {
    // refetch ? setrefetch(false) : setrefetch(true);
    schedulingShipment({
      variables: {
        input: {
          shipmentId: [...ids],
        },
      },
    })
      .then((data) => {
        refetchList();
        setCheckedIds([]);
        handleDialogClose();
        // setrefetch(true);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };
  const listShipments = data?.listShipments?.data;
  const [openDialog, setOpenDialog] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]); // Local state to manage checked IDs
  // const [id, setId] = useState([]); // Local state to manage checked IDs
  const currentIds = listShipments?.map((i) => i.id);
  const isChecked = (id) => {
    return checkedIds.includes(id);
  };
  const fieldSelected = currentIds?.filter((i) =>
    checkedIds.includes(i)
  )?.length; // Count of selected IDs in the current row

  // Determines if "Select All" should be in an indeterminate state
  const indeterminate = () =>
    fieldSelected > 0 && fieldSelected < currentIds?.length;
  // Determines if "Select All" should be fully checked
  const selectAllChecked = () =>
    currentIds?.length > 0 && fieldSelected === currentIds?.length;

  const onCheckAll = (e) => {
    let ids = [];
    if (e.target.checked) {
      const checkedAll = new Set([...checkedIds, ...currentIds]);
      ids = [...checkedAll];
    } else {
      ids = checkedIds.filter((i) => !currentIds.includes(i));
    }
    setCheckedIds(ids);
  };

  const toggleCheck = (e, id) => {
    const checked = e.target.checked;
    let updateCheckedIds = [...checkedIds];
    if (checked) {
      updateCheckedIds.push(id);
    } else {
      updateCheckedIds = updateCheckedIds.filter((i) => i !== id);
    }
    setCheckedIds(updateCheckedIds);
  };

  // const filters = {
  //   ...(search.code && { code: search.code }),
  //   ...(search.branchId && {
  //     branchId: search.branchId,
  //   }),
  //   ...(search.shippingMode && {
  //     shippingMode: search.shippingMode,
  //   }),
  //   ...(search.shippingDirection && {
  //     shippingDirection: search.shippingDirection,
  //   }),
  //   ...(search.status && {
  //     status: search.status,
  //   }),
  //   ...(search.loadingMode && {
  //     loadingMode: search.loadingMode,
  //   }),
  //   ...(search.destinationAgencyId && {
  //     destinationAgencyId: search.destinationAgencyId,
  //   }),
  //   ...(search.originAgencyId && {
  //     originAgencyId: search.originAgencyId,
  //   }),
  //   ...(search.destinationPortId && {
  //     destinationPortId: search.destinationPortId,
  //   }),
  //   ...(search.originPortId && { originPortId: search.originPortId }),
  //   ...(search.originCountryId && {
  //     originCountryId: search.originCountryId,
  //   }),
  //   ...(search.destinationCountryId && {
  //     destinationCountryId: search.destinationCountryId,
  //   }),
  //   ...(search.scheduledDepartureDate && {
  //     scheduledDepartureDate: search.scheduledDepartureDate,
  //   }),
  //   ...(search.scheduledArrivalDate && {
  //     scheduledArrivalDate: search.scheduledArrivalDate,
  //   }),
  //   ...(search.actualDepartureDate && {
  //     actualDepartureDate: search.actualDepartureDate,
  //   }),
  //   ...(search.actualArrivalDate && {
  //     actualArrivalDate: search.actualArrivalDate,
  //   }),
  //   ...(search.shipmentDate && { shipmentDate: search.shipmentDate }),
  // };
  useEffect(() => {
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    setAutocompleteValues((prev) => ({
      ...(urlQuery["branchId"] && {
        branch: urlQuery["branchId"],
      }),
      ...(urlQuery["originAgencyId"] && {
        originAgency: urlQuery["originAgencyId"],
      }),
      ...(urlQuery["destinationAgencyId"] && {
        destinationAgency: urlQuery["destinationAgencyId"],
      }),
      ...(urlQuery["shippingMode"] && {
        mode: urlQuery["shippingMode"],
      }),
      ...(urlQuery["shippingDirection"] && {
        shippingDirection: urlQuery["shippingDirection"],
      }),
      ...(urlQuery["loadingMode"] && {
        loadingMode: urlQuery["loadingMode"],
      }),
      ...(urlQuery["status"] && {
        status: urlQuery["status"],
      }),
      ...(urlQuery["destinationPortId"] && {
        destinationPort: urlQuery["destinationPortId"],
      }),
      ...(urlQuery["destinationCountryId"] && {
        destinationCountry: urlQuery["destinationCountryId"],
      }),
      ...(urlQuery["originCountryId"] && {
        originCountry: urlQuery["originCountryId"],
      }),
      ...(urlQuery["originPortId"] && {
        originPort: urlQuery["originPortId"],
      }),
    }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetScheduledDepartureDate = () => {
    setScheduledDepartureDate([null, null]);
  };
  const resetScheduledArrivalDate = () => {
    setScheduledArrivalDate([null, null]);
  };
  const resetActualDepartureDate = () => {
    setActualDepartureDate([null, null]);
  };
  const resetActualArrivalDate = () => {
    setActualArrivalDate([null, null]);
  };
  const resetShipmentDate = () => {
    setShipmentDate([null, null]);
  };
  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    const url = history.createHref({
      pathname: `/admin/status-shipments/${
        listType === "booking"
          ? "schedule"
          : listType === "loading"
          ? "transport"
          : "arrive"
      }`,
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    refetch ? setrefetch(false) : setrefetch(true);
    let handledData = {
      code: data.code,
      branchId: data.branchId,
      originAgencyId: data.originAgencyId,
      destinationAgencyId: data.destinationAgencyId,
      shippingMode: data.shippingMode,
      shippingDirection: data.shippingDirection,
      status: data.status,
      loadingMode: data.loadingMode,
      destinationCountryId: data.destinationCountryId,
      destinationPortId: data.destinationPortId,
      originCountryId: data.originCountryId,
      originPortId: data.originPortId,
      ...(scheduledDepartureDate[0] && { fromDateSDD }),
      ...(scheduledDepartureDate[1] && { toDateSDD }),
      ...(scheduledArrivalDate[0] && { fromDateSAD }),
      ...(scheduledArrivalDate[1] && { toDateSAD }),
      ...(actualDepartureDate[0] && { fromDateADD }),
      ...(actualDepartureDate[1] && { toDateADD }),
      ...(actualArrivalDate[0] && { fromDateAAD }),
      ...(actualArrivalDate[1] && { toDateAAD }),
      ...(shipmentDate[0] && { shipmentFromDate }),
      ...(shipmentDate[1] && { shipmentToDate }),
      page: 0,
    };
    console.log(handledData);

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
    if (handledData.fromDateSDD || handledData.toDateSDD) {
      const scheduledDepartureDate = {
        ...(handledData?.fromDateSDD && {
          fromDate: handledData?.fromDateSDD,
        }),
        ...(handledData?.toDateSDD && { toDate: handledData?.toDateSDD }),
      };
      delete handledData["fromDateSDD"];
      delete handledData["toDateSDD"];
      handledData.scheduledDepartureDate = scheduledDepartureDate;
    }
    if (handledData.fromDateSAD || handledData.toDateSAD) {
      const scheduledArrivalDate = {
        ...(handledData?.fromDateSAD && {
          fromDate: handledData?.fromDateSAD,
        }),
        ...(handledData?.toDateSAD && {
          toDate: handledData?.toDateSAD,
        }),
      };
      delete handledData["fromDateSAD"];
      delete handledData["toDateSAD"];
      handledData.scheduledArrivalDate = scheduledArrivalDate;
    }
    if (handledData.fromDateADD || handledData.toDateADD) {
      const actualDepartureDate = {
        ...(handledData?.fromDateADD && {
          fromDate: handledData?.fromDateADD,
        }),
        ...(handledData?.toDateADD && { toDate: handledData?.toDateADD }),
      };
      delete handledData["fromDateADD"];
      delete handledData["toDateADD"];
      handledData.actualDepartureDate = actualDepartureDate;
    }
    if (handledData.fromDateAAD || handledData.toDateAAD) {
      const actualArrivalDate = {
        ...(handledData?.fromDateAAD && {
          fromDate: handledData?.fromDateAAD,
        }),
        ...(handledData?.toDateAAD && {
          toDate: handledData?.toDateAAD,
        }),
      };
      delete handledData["fromDateAAD"];
      delete handledData["toDateAAD"];
      handledData.actualArrivalDate = actualArrivalDate;
    }
    if (handledData.shipmentFromDate || handledData.shipmentToDate) {
      const shipmentDate = {
        ...(handledData?.shipmentFromDate && {
          fromDate: handledData?.shipmentFromDate,
        }),
        ...(handledData?.shipmentToDate && {
          toDate: handledData?.shipmentToDate,
        }),
      };
      delete handledData["shipmentFromDate"];
      delete handledData["shipmentToDate"];
      handledData.shipmentDate = shipmentDate;
    }
    console.log(handledData);

    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
  };

  const parseData = (data) => {
    return data;
  };
  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };
  const handelShipmentInWarehouse = (id) => {
    // checkedIds.length >= 2 && setUpdateMultiple(true);
    setCheckedIds(id);
    // setShipmentId(id);
    setOpenDialog(true);
  };
  const icons = [
    {
      id: "filterList",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
    },
    {
      id: "receive",
      title:
        listType === "booking"
          ? t("schedule")
          : listType === "loading"
          ? t("transport")
          : t("arrive"),
      action: () => handelShipmentInWarehouse(checkedIds),
      disabled: checkedIds.length === 0,
    },
  ];
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  return (
    <RootStyleList>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle id="alert-dialog-title" color={"text.primary"}>
          {t("confirmation")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("confirmationMsg")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>{t("cancel")}</Button>
          <Button
            onClick={() => {
              schedulShipments(checkedIds);
            }}
            color="primary"
            autoFocus
          >
            {t("confirmation")}

            {schedulingShipmentLoading && <ButtonLoading />}
          </Button>
        </DialogActions>
      </Dialog>
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={listShipments?.length === 0}
        loading={loading}
        type={listType}
        filters={
          <Grid container component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              {/* <Grid item sm={12} alignItems="flex-start">
                <MultipleAutocomplete
                  valueKey="code"
                  multiple
                  hideCode={true}
                  control={control}
                  errors={errors}
                  name={"status"}
                  label={t("status")}
                  parseData={(data) => parseData(data)}
                  variables={{ input: { code: "FR_SHIPMENT_STATUS" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.status}
                  skip={noData}
                />
              </Grid> */}
              <Grid item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  startText={t("shipmentDate")}
                  value={shipmentDate}
                  onChangeValue={(value) => setShipmentDate(value)}
                  resetDate={resetShipmentDate}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  startText={t("scheduledDepartureDate")}
                  value={scheduledDepartureDate}
                  onChangeValue={(value) => setScheduledDepartureDate(value)}
                  resetDate={resetScheduledDepartureDate}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  startText={t("scheduledArrivalDate")}
                  value={scheduledArrivalDate}
                  onChangeValue={(value) => setScheduledArrivalDate(value)}
                  resetDate={resetScheduledArrivalDate}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  startText={t("actualDepartureDate")}
                  value={actualDepartureDate}
                  onChangeValue={(value) => setActualDepartureDate(value)}
                  resetDate={resetActualDepartureDate}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  startText={t("actualArrivalDate")}
                  value={actualArrivalDate}
                  onChangeValue={(value) => setActualArrivalDate(value)}
                  resetDate={resetActualArrivalDate}
                />
              </Grid>

              <Grid item sm={12} alignItems="flex-start">
                <ListBranches
                  control={control}
                  errors={errors}
                  name={"branchId"}
                  defaultValue={autocompleteValues.branch}
                  skipDefaultBranch={true}
                  skip={noData}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MultipleAutocomplete
                  valueKey="code"
                  multiple
                  control={control}
                  errors={errors}
                  name={"shippingMode"}
                  label={t("shippingMode")}
                  parseData={(data) => parseData(data)}
                  variables={{ input: { code: "FR_SHIPPING_MODE" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.mode}
                  skip={noData}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MultipleAutocomplete
                  name={"shippingDirection"}
                  label={t("shippingDirection")}
                  control={control}
                  errors={errors}
                  variables={{ input: { code: "FR_SHIPPING_DIRECTION" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  parseData={(data) => parseData(data)}
                  multiple
                  valueKey="code"
                  defaultValue={autocompleteValues.shippingDirection}
                  skip={noData}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MultipleAutocomplete
                  name={"loadingMode"}
                  label={t("loadingMode")}
                  control={control}
                  errors={errors}
                  variables={{ input: { code: "FR_LOADING_MODE" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  parseData={(data) => parseData(data)}
                  multiple
                  valueKey="code"
                  defaultValue={autocompleteValues.loadingMode}
                  skip={noData}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"originCountryId"}
                  label={t("originCountry")}
                  parseData={(data) => parseData(data)}
                  query={LIST_COUNTRIES_DROPDOWN.query}
                  onChangeValue={(e) => {
                    setValue("originPortId", "");
                  }}
                  defaultValue={autocompleteValues.originCountry}
                  skip={noData}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"originPortId"}
                  label={t("originPort")}
                  parseData={(data) => parseData(data)}
                  query={LIST_SHIPPING_PORTS_DROPDOWN.query}
                  variables={{
                    input: {
                      countryId: watch("originCountryId"),
                    },
                  }}
                  defaultValue={autocompleteValues.originPort}
                  skip={noData || !watch("originCountryId")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"destinationCountryId"}
                  label={t("destinationCountry")}
                  parseData={(data) => parseData(data)}
                  query={LIST_COUNTRIES_DROPDOWN.query}
                  onChangeValue={(e) => {
                    setValue("destinationPortId", "");
                  }}
                  defaultValue={autocompleteValues.destinationCountry}
                  skip={noData}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"destinationPortId"}
                  label={t("destinationPort")}
                  parseData={(data) => parseData(data)}
                  query={LIST_SHIPPING_PORTS_DROPDOWN.query}
                  variables={{
                    input: {
                      countryId: watch("destinationCountryId"),
                    },
                  }}
                  defaultValue={autocompleteValues.destinationPort}
                  skip={noData || !watch("destinationCountryId")}
                />
              </Grid>

              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"originAgencyId"}
                  label={t("sender")}
                  parseData={(data) => parseData(data)}
                  query={LIST_AGENCIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.originAgency}
                  skip={noData}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"destinationAgencyId"}
                  label={t("destinationAgency")}
                  parseData={(data) => parseData(data)}
                  query={LIST_AGENCIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.destinationAgency}
                  skip={noData}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          <TableHead>
            <TableRow>
              <FixedTableCell
                className={clsx(classes.toolbar, classes.checkbox)}
              >
                <Checkbox
                  edge="start"
                  id="check-all-details"
                  indeterminate={indeterminate()}
                  checked={selectAllChecked()}
                  inputProps={{
                    "aria-label": "select all items",
                  }}
                  tabIndex={-1}
                  onChange={(e) => onCheckAll(e)}
                  disableRipple
                />
              </FixedTableCell>
              <FixedTableCell className={classes.checkboxTable_code}>
                {t("shipmentCode")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("shipmentDate")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("countainersCount")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("shippingMode")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("shippingDirection")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("originPort")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("destinationPort")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("scheduledDepartureDate")}
              </FixedTableCell>
              {/* <FixedTableCell className={classes.headerTable}>
                {t("status")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("branch")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("originCountry")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("destinationCountry")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("loadingMode")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("scheduledArrivalDate")}
              </FixedTableCell> */}
              <FixedTableCell className={classes.last_header_item}>
                {" "}
              </FixedTableCell>
            </TableRow>
          </TableHead>
        }
        tableBody={
          <TableBody>
            {listShipments &&
              listShipments?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <FixedTableCell
                      key={`${index}-che`}
                      className={classes.bodyCheckbox}
                    >
                      <Checkbox
                        edge="start"
                        checked={isChecked(row?.id)}
                        onChange={(e) => toggleCheck(e, row?.id)}
                        disableRipple
                      />
                    </FixedTableCell>
                    <CellLink
                      className={classes.checkboxTable_bodyCode}
                      pathname={`/admin/shipments/${row?.id}`}
                    >
                      {row?.code}
                    </CellLink>
                    <FixedTableCell>
                      {dateFormat(row?.shipmentDate)}
                    </FixedTableCell>
                    <FixedTableCell>{row?.containers?.length}</FixedTableCell>
                    <FixedTableCell>{row.shippingMode?.name}</FixedTableCell>
                    <FixedTableCell>
                      {row?.shippingDirection?.name}
                    </FixedTableCell>
                    <CellLink
                      pathname={`/admin/countries/${row?.originPort?.id}`}
                    >
                      {row?.originPort?.name}
                    </CellLink>
                    <CellLink
                      pathname={`/admin/countries/${row?.destinationPort?.id}`}
                    >
                      {row?.destinationPort?.name}
                    </CellLink>
                    <FixedTableCell>
                      {dateFormat(row?.scheduledDepartureDate)}
                    </FixedTableCell>

                    {/* <TableCellColor align="left" status={row?.status} />
                    <CellLink pathname={`/admin/branches/${row?.branch?.id}`}>
                      {row?.branch?.name}
                    </CellLink>
                    <CellLink
                      pathname={`/admin/countries/${row?.originCountry?.id}`}
                    >
                      {row?.originCountry?.name}
                    </CellLink>

                    <CellLink
                      pathname={`/admin/countries/${row?.destinationCountry?.id}`}
                    >
                      {row?.destinationCountry?.name}
                    </CellLink>

                    <FixedTableCell>{row?.loadingMode?.name}</FixedTableCell>

                    <FixedTableCell>
                      {dateFormat(row?.scheduledArrivalDate)}
                    </FixedTableCell> */}
                    <FixedTableCell
                      // className={classes.shipmentTable_button}
                      className={classes.last_header_item}
                      component="th"
                      scope="row"
                      key={index}
                    >
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        // disabled={!canReceiveShipment}
                        onClick={() => {
                          setCheckedIds([row?.id]);
                          setOpenDialog(true);
                        }}
                        startIcon={<Icon>task_alt</Icon>}
                      >
                        {listType === "booking"
                          ? t("schedule")
                          : listType === "loading"
                          ? t("transport")
                          : t("arrive")}
                      </Button>
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listShipments?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listShipments ? 0 : search.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </RootStyleList>
  );
};

export default ScheduleShipments;
