import * as gqlb from "gql-query-builder";
const fields = [
  "id",
  "code",
  "totalAmount",
  "approved",
  "glApproved",

  {
    branch: ["id", "code", "name"],
  },
  {
    accountType: ["id", "code", "name"],
  },
  {
    vendor: ["id", "code", "name"],
  },
  {
    employee: ["id", "code", "name"],
  },
  {
    customer: ["id", "code", "name"],
  },
  {
    agent: ["id", "code", "name"],
  },
  {
    agency: ["id", "code", "name"],
  },
  {
    carrier: ["id", "code", "name"],
  },
  {
    documentType: ["id", "code", "name"],
  },
  {
    currency: ["id", "code", "name"],
  },
  {
    paymentType: ["id", "code", "name"],
  },
  {
    request: ["id", "code"],
  },
  {
    shipment: ["id", "code"],
  },
];

export const QUERY_LIST = (operationName, input) =>
  gqlb.query({
    operation: operationName,
    fields: [
      {
        operation: "paginatorInfo",
        fields: [
          "count",
          "currentPage",
          "firstItem",
          "hasMorePages",
          "lastItem",
          "lastPage",
          "perPage",
          "total",
        ],
        variables: {},
      },
      {
        operation: "data",
        fields: [
          ...fields,
          operationName === "listBills" ? "billDate" : "invoiceDate",
        ],
        variables: {},
      },
    ],
    variables: {
      input: {
        type: `List${input}FilterInput`,
      },
      page: {
        type: "Int",
      },
      first: {
        type: "Int",
      },
    },
  });

export const QUERY_ID = (operationName) =>
  gqlb.query({
    operation: operationName,
    fields: [
      ...fields,
      "date",
      "exchangeRate",
      "referenceNumber",
      "referenceDate",
      "remarks",
      "deletable",
      "totalTaxAmount",
      "totalAmountInLocalCurrency",
      {
        request: [
          "requestDate",
          "consigneeName",
          "consigneeAddress",
          "consigneeMobile",
          "shipperName",
          "shipperAddress",
          "shipperMobile",
          {
            consigneeState: ["id", "name", "code"],
          },
          {
            shipperState: ["id", "name", "code"],
          },
          {
            loadingMode: ["id", "name", "code"],
          },
          {
            shippingMode: ["id", "name", "code"],
          },
          {
            shippingDirection: ["id", "name", "code"],
          },
        ],
        shipment: [
          "shipmentDate",
          "consigneeName",
          "consigneeAddress",
          "consigneeMobile",
          "shipperName",
          "shipperAddress",
          "shipperMobile",
          {
            consigneeState: ["id", "name", "code"],
          },
          {
            shipperState: ["id", "name", "code"],
          },
          {
            loadingMode: ["id", "name", "code"],
          },
          {
            shippingMode: ["id", "name", "code"],
          },
          {
            shippingDirection: ["id", "name", "code"],
          },
        ],
      },

      {
        voucher: ["id", "code"],
      },
      {
        journalEntry: ["id", "code"],
      },
      {
        lines: [
          "id",
          "chargePrice",
          "chargeableUnits",
          "lineAmount",
          "taxAmount",
          "totalAmount",
          "deletable",
          "totalAmountInLocalCurrency",
          {
            taxes: [
              "id",
              "rate",
              "value",
              {
                tax: ["id", "code", "name"],
              },
            ],
          },
          {
            priceMode: ["id", "code", "name"],
          },
          {
            chargeElement: ["id", "code", "name"],
          },
        ],
      },
      {
        transactionType: ["id", "code", "name"],
      },
      operationName === "bill" ? "billDate" : "invoiceDate",
    ],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  });
export const QUERY_TYPE = (operationName) =>
  gqlb.query({
    operation: operationName,
    fields: [{ currency: ["id", "code", "name"] }],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  });

export const SAVE_QUERY = (operationName) =>
  gqlb.mutation({
    operation: `save${operationName}`,
    fields: ["id", "code"],
    variables: {
      input: {
        type: `${operationName}Input`,
        required: true,
      },
    },
  });
export const APPROVE_MUTATION = (operationName) =>
  gqlb.mutation({
    operation: `approve${operationName}`,
    fields: ["id", "code"],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  });
export const DIS_APPROVE_MUTATION = (operationName) =>
  gqlb.mutation({
    operation: `disapprove${operationName}`,
    fields: ["id", "code"],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  });
export const GL_APPROVE_MUTATION = (operationName) =>
  gqlb.mutation({
    operation: `glApprove${operationName}`,
    fields: ["id", "code"],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  });

export const CURRENCY_ID = gqlb.query({
  operation: "currency",
  fields: [{ exchangeRate: ["updatable", "rate", "id"] }],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const LIST_REQUESTS = gqlb.query({
  operation: "listRequests",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["count", "currentPage", "perPage", "total"],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "requestDate",

        "consigneeName",
        "consigneeAddress",
        "consigneeMobile",
        "shipperName",
        "shipperAddress",
        "shipperMobile",
        {
          consigneeState: ["id", "name", "code"],
        },
        {
          shipperState: ["id", "name", "code"],
        },
        {
          loadingMode: ["id", "name", "code"],
        },
        {
          shippingMode: ["id", "name", "code"],
        },
        {
          shippingDirection: ["id", "name", "code"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListRequestsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const SAVE_LINES = (operationName) =>
  gqlb.mutation({
    operation: `save${operationName}Line`,
    fields: [
      "id",
      "chargePrice",
      "chargeableUnits",
      "lineAmount",
      "taxAmount",
      "totalAmount",
      "deletable",
      {
        taxes: [
          "id",
          "rate",
          "value",
          {
            tax: ["id", "code", "name"],
          },
        ],
      },
      {
        priceMode: ["id", "code", "name"],
      },
      {
        chargeElement: ["id", "code", "name"],
      },
    ],
    variables: {
      input: {
        type: `${operationName}LineInput`,
        required: true,
      },
    },
  });
export const SAVE_TAX = (operationName) =>
  gqlb.mutation({
    operation: `save${operationName}Tax`,
    fields: [
      "id",
      "rate",
      "value",
      {
        tax: ["id", "code", "name"],
      },
      {
        line: ["taxAmount", "totalAmount"],
      },
    ],
    variables: {
      input: {
        type: `${operationName}TaxInput`,
        required: true,
      },
    },
  });

export const TAX_ID = gqlb.query({
  operation: "tax",
  fields: ["rate"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const CALCULATE_TAX = gqlb.query({
  operation: "calculateTaxFees",
  fields: ["value"],
  variables: {
    input: {
      type: `CalculateTaxFeesInput`,
      required: true,
    },
  },
});
export const DELETE_QUERY = (operationName) =>
  gqlb.mutation({
    operation: `delete${operationName}`,
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  });
export const DELETE_LINE_QUERY = (operationName) =>
  gqlb.mutation({
    operation: `delete${operationName}Line`,
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  });
export const DELETE_TAX_QUERY = (operationName) =>
  gqlb.mutation({
    operation: `delete${operationName}Tax`,
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  });
export const EXPOERT_BILLS = gqlb.mutation({
  operation: "exportBills",
  fields: [],
  variables: {
    input: {
      type: "ExportBillsInput",
      required: true,
    },
  },
});
export const EXPOERT_INVOICES = gqlb.mutation({
  operation: "exportInvoices",
  fields: [],
  variables: {
    input: {
      type: "ExportInvoicesInput",
      required: true,
    },
  },
});
