import * as gqlb from "gql-query-builder";
const fields = [
  "id",
  "code",
  "name",
  "date",
  "active",
  "rate",
  "import",
  "export",
  "air",
  "land",
  "ocean",
  "editable",
  "deletable",
  { type: ["id", "code", "name"] },
  { glAccount: ["id", "code", "name"] },
];
export const LIST_TAXES = gqlb.query({
  operation: "listTaxes",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: fields,
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListTaxesFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const TAX_ID = gqlb.query({
  operation: "tax",
  fields: fields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_TAX = gqlb.mutation({
  operation: "saveTax",
  fields: ["id", "code", "name", "active"],
  variables: {
    input: {
      type: "TaxInput",
      required: true,
    },
  },
});

export const DELETE_TAX = gqlb.mutation({
  operation: "deleteTax",
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
