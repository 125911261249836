import React from "react";
import { Toolbar, IconButton, Typography, Icon, Stack } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import clsx from "clsx";

const TOOLBAR_PREFIX = "styledToolbar";

const toolbarClasses = {
  highlight: `${TOOLBAR_PREFIX}-highlight`,
  title: `${TOOLBAR_PREFIX}-title`,
  filterButton: `${TOOLBAR_PREFIX}-filterButton`,
  addIcon: `${TOOLBAR_PREFIX}-addIcon`,
};

const StyledToolbar = styled(Stack)(({ theme }) => ({
  width: "100%",
  zIndex: 1,

  [`& .${toolbarClasses.highlight}`]:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.text.primary,
        }
      : {
          color: theme.palette.text.primary,
        },

  [`& .${toolbarClasses.title}`]: {
    flex: "1 1 15%",
  },

  [`& .${toolbarClasses.filterButton}`]: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  [`& .${toolbarClasses.addIcon}`]: {
    color: theme.palette.success.main,
  },
}));

export const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();

  const { numSelected, addShipment, view, barcode, containerLoading } = props;

  return (
    <StyledToolbar>
      <Toolbar
        className={clsx(
          toolbarClasses.toolbar,
          {
            [toolbarClasses.highlight]: numSelected > 0,
          },
          toolbarClasses.header
        )}
      >
        {
          !containerLoading && (
            // (numSelected > 0 ? (
            <Typography
              className={toolbarClasses.title}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {t("packagesCount", { count: numSelected })}
            </Typography>
          )
          // ) : (
          //   <Typography
          //     className={toolbarClasses.title}
          //     variant="h6"
          //     id="tableTitle"
          //     component="div"
          //   >
          //     {t("packages")}
          //   </Typography>
          // ))
        }
        {addShipment && !view ? (
          <IconButton
            aria-label="Add"
            className={!view ? toolbarClasses.addIcon : null}
            onClick={addShipment}
            disabled={view}
            size="large"
          >
            <AddCircleOutline />
          </IconButton>
        ) : null}
        {barcode && !view ? (
          <IconButton
            aria-label="Add"
            onClick={barcode}
            disabled={view}
            size="large"
          >
            <Icon>qr_code_scanner</Icon>
          </IconButton>
        ) : null}
      </Toolbar>
    </StyledToolbar>
  );
};
