/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  IconButton,
  TableBody,
  Toolbar,
  Typography,
  Collapse,
  TableHead,
  TableRow,
  Checkbox,
  Icon,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Cancel, CheckCircle, Search } from "@mui/icons-material";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useWidth from "../../../Hooks/useWidth";

import MUITablePagination from "../../HOC/MUI/TablePagination/MUITablePagination";
import {
  CREATE_MANIFEST,
  LIST_PACKAGE_DETAILS_UNLOADING,
  LIST_PACKAGE_DETAILS_LOADING,
  LIST_PACKAGE_DETAILS_DELIVERED,
} from "../Graphql";
import ListWrapper from "../../CustomComponents/ListWrapper/ListWrapper";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import { useTabsContext } from "../../HOC/CustomComponents/PackagesTableView/TabContext";
import CellLink from "../../HOC/CustomComponents/CellLink";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";
import { useForm } from "react-hook-form";
import moment from "moment";
import MUIDateRangeCustom from "../../HOC/MUI/MUIDateRangeCustom";
import {
  LIST_CUSTOMERS_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_VENDORS_DROPDOWN,
} from "../../../GlobalsQuery/ListDropdown/ListDropdown";
import { CustomAutocomplete } from "../../HOC/MUI/CustomAutocomplete";
import { MultipleAutocomplete } from "../../HOC/MUI/MultipleAutocomplete";

const TOOLBAR_PREFIX = "styledToolbar";

const toolbarClasses = {
  title: `${TOOLBAR_PREFIX}-title`,
};

const StyledToolbar = styled("div")(({ theme }) => ({
  width: "100%",
  zIndex: 1,
  top: 72,
  [`& .${toolbarClasses.title}`]: {
    flex: "1 1 100%",
  },
}));

const PREFIX = "PackageDetailsList";

const classes = {
  track: `${PREFIX}-track`,
  paper: `${PREFIX}-paper`,
  filters: `${PREFIX}-filters`,
  barcode: `${PREFIX}-barcode`,
  iconColor: `${PREFIX}-iconColor`,
  filterField: `${PREFIX}-filterField`,
  button: `${PREFIX}-button`,
};

const Root = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0, 0),
  width: "100%",
  height: "100%",
  backgroundColor: theme.palette.background.default,

  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 30,
  },
  [`& .${classes.filterField}`]: {
    padding: 0,
    width: "100%",
    margin: 0,
    flexDirection: "column",
  },
  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    // width: "100%",
  },
  [`& .${classes.paper}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    overflowX: "auto",
    maxHeight: `calc(100vh - ${theme.mixins.toolbar["minHeight"]}px)`,
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.filters}`]: {
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 72px)",
    },
    [theme.breakpoints.up("sm")]: {
      width: "360px",
    },

    height: "100%",
    overflow: "hidden",
  },
}));

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const { numSelected, done, clear, disabled, requestPackageDetails } = props;

  return (
    <StyledToolbar>
      <Toolbar
        className={clsx(
          toolbarClasses.toolbar,
          {
            [toolbarClasses.highlight]: numSelected > 0,
          },
          classes.header
        )}
      >
        {numSelected > 0 ? (
          <Typography
            className={toolbarClasses.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {t("packagesCount", { count: numSelected })}
          </Typography>
        ) : (
          <Typography
            className={toolbarClasses.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t("packages")}
          </Typography>
        )}
        {!requestPackageDetails && (
          <IconButton
            aria-label="done"
            sx={{ color: "success.main" }}
            disabled={disabled}
            onClick={done}
            size="large"
          >
            <CheckCircle />
          </IconButton>
        )}

        <IconButton
          aria-label="close"
          onClick={() => clear()}
          color={"primary"}
          size="large"
        >
          <Cancel />
        </IconButton>
      </Toolbar>
    </StyledToolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  done: PropTypes.func.isRequired,
};

//*********Table Function*********

const PackageDetailsList = (props) => {
  const { done, requestPackageDetails } = props;
  const { filters, container, operationId, handleManifest, type } =
    useTabsContext();
  const [page, setPage] = useState(0);
  const [shippingDate, setShippingDate] = useState([null, null]);
  const [finishDate, setFinishDate] = useState([null, null]);
  const [requestDate, setRequestDate] = useState([null, null]);
  const fromDate = moment(shippingDate[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(shippingDate[1]).locale("en").format("YYYY-MM-DD");
  const finishFromDate = moment(finishDate[0])
    .locale("en")
    .format("YYYY-MM-DD");
  const finishToDate = moment(finishDate[1]).locale("en").format("YYYY-MM-DD");
  const requestFromDate = moment(requestDate[0])
    .locale("en")
    .format("YYYY-MM-DD");
  const requestToDate = moment(requestDate[1])
    .locale("en")
    .format("YYYY-MM-DD");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  // const [drawerState, setDrawerState] = useState(false);
  // const screenWidth = useWidth();

  // const isScreenSmall = isWidthDown("xs", screenWidth);
  const [checkedIds, setCheckedIds] = useState([]);
  const [disableIds, setDisableIds] = useState([]);
  const [typePackageMode, setTypePackageMode] = useState();
  const [listPackageDetails, setListPackageDetails] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const screenWidth = useWidth();

  const [drawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });
  const { t } = useTranslation();

  const [search, setSearch] = useState();
  const ListDetaile =
    type === "LOADING"
      ? LIST_PACKAGE_DETAILS_LOADING
      : type === "UNLOADED"
      ? LIST_PACKAGE_DETAILS_UNLOADING
      : LIST_PACKAGE_DETAILS_DELIVERED;
  const { data, loading } = useQuery(
    gql`
      ${ListDetaile.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          ...(requestPackageDetails
            ? {
                ...(type === "LOADING"
                  ? {
                      operationId: operationId,
                      filters: {
                        requestId: requestPackageDetails?.requestId,
                        ...search,
                      },
                    }
                  : { requestId: requestPackageDetails?.requestId, ...search }),
              }
            : {
                ...(type === "LOADING"
                  ? {
                      operationId: operationId,
                      filters: { ...filters, ...search },
                    }
                  : { ...filters, ...search }),
              }),
        },
      },
      onCompleted: (data) => {
        handleCompleted(data);
      },
    }
  );
  const handleCompleted = (data) => {
    // Only proceed if there are checked IDs and packed filters
    if (
      (checkedIds?.length !== 0 || type === "LOADING") &&
      filters?.shippingMode !== "AIR"
    ) {
      let result;
      // Find the relevant package details based on `checkedIds`
      if (!typePackageMode) {
        result =
          data?.listPackageDetailsForLoading?.data.find(
            (item) => item?.id === checkedIds[0]
          ) || listPackageDetails.find((item) => item?.id === checkedIds[0]);
        setTypePackageMode(result);
      }
      const loadingModeCode =
        typePackageMode?.package?.request ?? result?.package?.request;
      let newDisableIds = [];
      if (["FTL", "FCL"].includes(loadingModeCode?.loadingMode?.code)) {
        newDisableIds = data?.listPackageDetailsForLoading?.data.map((row) =>
          row?.package?.request.code === loadingModeCode?.code ? false : true
        );
      }
      // Case for LCL loading mode
      else if (["LTL", "LCL"].includes(loadingModeCode?.loadingMode?.code)) {
        newDisableIds = data?.listPackageDetailsForLoading?.data.map((row) =>
          ["FTL", "FCL"].includes(row?.package?.request?.loadingMode?.code)
            ? true
            : false
        );
      }
      setDisableIds(newDisableIds ?? []);
    }

    // Set the new disable IDs and package details list

    setListPackageDetails(
      type === "LOADING"
        ? data?.listPackageDetailsForLoading?.data
        : type === "UNLOADED"
        ? data?.listPackageDetailsForUnloading?.data
        : data?.listPackageDetailsForDelivery?.data
    );
  };
  // const listPackageDetails = data?.listPackageDetails?.data;
  // Local state to manage checked IDs
  const currentIds = listPackageDetails?.map((i) => i.id);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const isChecked = (id) => {
    return checkedIds.includes(id);
  };
  const isDisable = (e, row) => {
    const checked = e.target.checked;

    let newDisableIds = [];

    if (checked) {
      // Case for FCL loading mode
      if (["FTL", "FCL"].includes(row?.package?.request?.loadingMode?.code)) {
        newDisableIds = listPackageDetails.map((data) =>
          data?.package?.request.code === row?.package?.request.code
            ? false
            : true
        );
      }
      // Case for LCL loading mode
      else if (
        ["LTL", "LCL"].includes(row?.package?.request?.loadingMode?.code)
      ) {
        newDisableIds = listPackageDetails.map((data) =>
          ["FTL", "FCL"].includes(data?.package?.request?.loadingMode?.code)
            ? true
            : false
        );
      }
    }
    // Case when unchecked and only one item is selected
    else if (!checked && checkedIds?.length === 1) {
      newDisableIds = listPackageDetails.map(() => false);
      setTypePackageMode();
    }

    // Set disableIds only once
    setDisableIds(newDisableIds);
  };

  const fieldSelected = currentIds?.filter((i) =>
    checkedIds.includes(i)
  )?.length;

  const indeterminate = () =>
    fieldSelected > 0 && fieldSelected < currentIds?.length;

  const selectAllChecked = () =>
    currentIds?.length > 0 && fieldSelected === currentIds?.length;

  const onCheckAll = (e) => {
    let ids = [];
    if (e.target.checked) {
      const checkedAll = new Set([...checkedIds, ...currentIds]);
      ids = [...checkedAll];
    } else {
      ids = checkedIds.filter((i) => !currentIds.includes(i));
    }
    setCheckedIds(ids);
  };

  const toggleCheck = (e, id) => {
    const checked = e.target.checked;
    let updateCheckedIds = [...checkedIds];
    if (checked) {
      updateCheckedIds.push(id);
    } else {
      updateCheckedIds = updateCheckedIds.filter((i) => i !== id);
    }
    setCheckedIds(updateCheckedIds);
  };
  const resetShippingDate = () => {
    setShippingDate([null, null]);
  };
  const resetFinishDate = () => {
    setFinishDate([null, null]);
  };
  const resetRequestDate = () => {
    setRequestDate([null, null]);
  };
  const [createManifest] = useMutation(
    gql`
      ${CREATE_MANIFEST.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const onSaveManifest = (data) => {
    data.length > 0 &&
      createManifest({
        variables: {
          input: {
            operationId: operationId,
            containerId: container,
            packageDetailId: data,
          },
        },
      })
        .then(() => {
          enqueueSnackbar(t("saveSuccessful"), {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
          handleManifest(container);
          done();
        })
        .catch((error) => {
          enqueueSnackbar(error.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        });
  };
  const parseData = (data) => {
    return data;
  };

  const onSubmit = (data) => {
    let handledData = {
      requestCode: data.requestCode,
      barcode: data.barcode,
      loadingMode: data.loadingMode,
      vendorId: data.vendorId,
      customerId: data.customerId,
      ...(shippingDate[0] && { fromDate }),
      ...(shippingDate[1] && { toDate }),
      ...(finishDate[0] && { finishFromDate }),
      ...(finishDate[1] && { finishToDate }),
      ...(requestDate[0] && { requestFromDate }),
      ...(requestDate[1] && { requestToDate }),
    };
    if (handledData.fromDate || handledData.toDate) {
      const shippingDate = {
        ...(handledData?.fromDate && { fromDate: handledData?.fromDate }),
        ...(handledData?.toDate && { toDate: handledData?.toDate }),
      };
      delete handledData["fromDate"];
      delete handledData["toDate"];
      handledData.shippingDate = shippingDate;
    }
    if (handledData.finishFromDate || handledData.finishToDate) {
      const finishDate = {
        ...(handledData?.finishFromDate && {
          fromDate: handledData?.finishFromDate,
        }),
        ...(handledData?.finishToDate && {
          toDate: handledData?.finishToDate,
        }),
      };
      delete handledData["finishFromDate"];
      delete handledData["finishToDate"];
      handledData.finishDate = finishDate;
    }
    if (handledData.requestFromDate || handledData.requestToDate) {
      const requestDate = {
        ...(handledData?.requestFromDate && {
          fromDate: handledData?.requestFromDate,
        }),
        ...(handledData?.requestToDate && {
          toDate: handledData?.requestToDate,
        }),
      };
      delete handledData["requestFromDate"];
      delete handledData["requestToDate"];
      handledData.requestDate = requestDate;
    }
    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    setCheckedIds([]);
    const newDisableIds = listPackageDetails.map(() => false);
    setDisableIds(newDisableIds);
    setSearch((prev) => ({
      ...handledData,
      // refetch: !prev.refetch,
    }));
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const shouldReturnTrue = (result) => {
    if (result?.requestCode || filters?.shippingMode === "AIR") return true;
    if (
      search?.loadingMode &&
      !(
        search?.loadingMode.includes("FTL") ||
        search?.loadingMode.includes("FCL")
      )
    )
      return true;

    // Return `false` if `FTL` or `FCL` is present, otherwise `true`
    return false;
  };
  return (
    <>
      <Root>
        <EnhancedTableToolbar
          clear={done}
          done={() => {
            onSaveManifest(checkedIds);
          }}
          numSelected={checkedIds.length}
          disabled={checkedIds.length === 0}
          requestPackageDetails={requestPackageDetails}
        />
        <ListWrapper
          drawerState={!requestPackageDetails ? drawerState["left"] : false}
          empty={listPackageDetails?.length === 0}
          loading={loading}
          noHeader={true}
          filters={
            !requestPackageDetails ? (
              <Grid
                container
                component="form"
                m={0}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid
                  container
                  item
                  sm={12}
                  justifyContent="flex-start"
                  spacing={1}
                  className={classes.filterField}
                >
                  <Grid item sm={12} alignItems="flex-start">
                    <ControlMUItextField
                      control={control}
                      errors={errors}
                      name={"barcode"}
                      label={t("barcode")}
                    />
                  </Grid>
                  <Grid item sm={12} alignItems="flex-start">
                    <ControlMUItextField
                      control={control}
                      errors={errors}
                      name={"requestCode"}
                      label={t("requestCode")}
                    />
                  </Grid>
                  <Grid item sm={12} alignItems="flex-start">
                    <MUIDateRangeCustom
                      startText={t("requestDate")}
                      value={requestDate}
                      onChangeValue={(value) => setRequestDate(value)}
                      resetDate={resetRequestDate}
                    />
                  </Grid>
                  <Grid item sm={12} alignItems="flex-start">
                    <MUIDateRangeCustom
                      startText={t("shippingDate")}
                      value={shippingDate}
                      onChangeValue={(value) => setShippingDate(value)}
                      resetDate={resetShippingDate}
                    />
                  </Grid>
                  <Grid item sm={12} alignItems="flex-start">
                    <MUIDateRangeCustom
                      startText={t("finishDate")}
                      value={finishDate}
                      onChangeValue={(value) => setFinishDate(value)}
                      resetDate={resetFinishDate}
                    />
                  </Grid>
                  <Grid item sm={12} alignItems="flex-start">
                    <MultipleAutocomplete
                      name={"loadingMode"}
                      label={t("loadingMode")}
                      control={control}
                      errors={errors}
                      variables={{ input: { code: "FR_LOADING_MODE" } }}
                      query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                      parseData={(data) => parseData(data)}
                      multiple
                      valueKey="code"
                    />
                  </Grid>
                  <Grid item sm={12} alignItems="flex-start">
                    <CustomAutocomplete
                      control={control}
                      errors={errors}
                      name={"vendorId"}
                      label={t("sender")}
                      parseData={(data) => parseData(data)}
                      query={LIST_VENDORS_DROPDOWN.query}
                    />
                  </Grid>
                  <Grid item sm={12} alignItems="flex-start">
                    <CustomAutocomplete
                      control={control}
                      errors={errors}
                      name={"customerId"}
                      label={t("customer")}
                      parseData={(data) => parseData(data)}
                      query={LIST_CUSTOMERS_DROPDOWN.query}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  sm={12}
                  className={classes.button}
                  alignItems="flex-end"
                  justifyContent="center"
                >
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="medium"
                    color="primary"
                    startIcon={<Search />}
                  >
                    {t("search")}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              false
            )
          }
          tableHeaders={
            <TableHead>
              <TableRow>
                <FixedTableCell
                  className={clsx(classes.toolbar, classes.checkbox)}
                >
                  {requestPackageDetails ? (
                    t("add")
                  ) : (
                    <Checkbox
                      edge="start"
                      id="check-all-details"
                      indeterminate={indeterminate()}
                      checked={selectAllChecked()}
                      inputProps={{
                        "aria-label": "select all items",
                      }}
                      tabIndex={-1}
                      onChange={(e) => onCheckAll(e)}
                      disabled={
                        type !== "LOADING" || filters?.shippingMode === "AIR"
                          ? false
                          : shouldReturnTrue(search)
                          ? false
                          : true
                      }
                      disableRipple
                    />
                  )}
                </FixedTableCell>
                <FixedTableCell className={classes.headerTable}>
                  {t("pieceNumber")}
                </FixedTableCell>
                <FixedTableCell className={classes.headerTable}>
                  {t("requestCode")}
                </FixedTableCell>
                <FixedTableCell className={classes.headerTable}>
                  {t("loadingMode")}
                </FixedTableCell>
                <FixedTableCell className={classes.headerTable}>
                  {t("packageType")}
                </FixedTableCell>
                <FixedTableCell className={classes.headerTable}>
                  {t("product")}
                </FixedTableCell>
                <FixedTableCell className={classes.headerTable}>
                  {t("weight")}
                </FixedTableCell>
                <FixedTableCell className={classes.headerTable}>
                  {t("fragile")}
                </FixedTableCell>
                <FixedTableCell className={classes.headerTable}>
                  {t("sender")}
                </FixedTableCell>
                <FixedTableCell className={classes.headerTable}>
                  {t("originCountry")}
                </FixedTableCell>
                <FixedTableCell className={classes.headerTable}>
                  {t("recipient")}
                </FixedTableCell>
                <FixedTableCell className={classes.headerTable}>
                  {t("destinationCountry")}
                </FixedTableCell>
              </TableRow>
            </TableHead>
          }
          tableBody={
            <TableBody>
              {listPackageDetails &&
                listPackageDetails?.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <FixedTableCell key={`${index}-che`}>
                        {requestPackageDetails ? (
                          row?.loaded ? (
                            <Icon className={classes.iconColor}>
                              check_circle_outline
                            </Icon>
                          ) : (
                            <Icon color="error">highlight_off</Icon>
                          )
                        ) : (
                          <Checkbox
                            edge="start"
                            checked={isChecked(row?.id)}
                            onChange={(e) => {
                              toggleCheck(e, row?.id);
                              if (type === "LOADING") {
                                isDisable(e, row); // Call isDisable only if filters.packed is defined
                              }
                            }}
                            disabled={disableIds[index]}
                            disableRipple
                          />
                        )}
                      </FixedTableCell>
                      <FixedTableCell>
                        {`${row?.pieceNumber}/${row?.package?.request?.detailsCount}`}
                      </FixedTableCell>
                      <CellLink
                        pathname={`/admin/requests/${row?.package?.request?.id}`}
                      >
                        {row?.package?.request?.code}
                      </CellLink>
                      <FixedTableCell>
                        {row?.package?.request?.loadingMode?.name}
                      </FixedTableCell>
                      <FixedTableCell>
                        {row?.package?.packageType?.name}
                      </FixedTableCell>
                      <FixedTableCell>
                        {row?.package?.product
                          ? row?.package?.product?.name
                          : row?.package?.description}
                      </FixedTableCell>
                      <FixedTableCell>{row?.package?.weight}</FixedTableCell>
                      <FixedTableCell>
                        {row?.package?.fragile ? (
                          <Icon className={classes.iconColor}>
                            check_circle_outline
                          </Icon>
                        ) : (
                          <Icon color="error">highlight_off</Icon>
                        )}
                      </FixedTableCell>
                      <CellLink
                        pathname={`/admin/vendors/${row?.package?.request?.vendor?.id}`}
                      >
                        {row?.package?.request?.vendor?.name}
                      </CellLink>
                      <CellLink
                        pathname={`/admin/countries/${row?.package?.request?.originCountry?.id}`}
                      >
                        {row?.package?.request?.originCountry?.name}
                      </CellLink>
                      {/* <FixedTableCell>
                          {row?.package?.request?.originCountry}
                        </FixedTableCell> */}
                      <CellLink
                        pathname={`/admin/customer/${row?.package?.request?.customer?.id}`}
                      >
                        {row?.package?.request?.customer?.name}
                      </CellLink>
                      <CellLink
                        pathname={`/admin/countries/${row?.package?.request?.destinationCountry?.id}`}
                      >
                        {row?.package?.request?.destinationCountry?.name}
                      </CellLink>
                    </TableRow>
                  );
                })}
            </TableBody>
          }
          pagination={
            <MUITablePagination
              count={
                type === "LOADING"
                  ? data?.listPackageDetailsForLoading?.paginatorInfo?.total
                  : type === "UNLOADED"
                  ? data?.listPackageDetailsForUnloading?.paginatorInfo?.total
                  : data?.listPackageDetailsForDelivery?.paginatorInfo?.total
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          }
        />
      </Root>
    </>
  );
};

export default PackageDetailsList;
