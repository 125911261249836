/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Paper,
  Toolbar,
  Table,
  TableRow,
  TableHead,
  TableBody,
} from "@mui/material";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_LOOKUP_ID } from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import Grid from "@mui/material/Unstable_Grid2";
import CheckCrossIcons from "../HOC/FunctionComponents/CheckCrossIcons";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";

const LookupView = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const lookupId = props.id ?? props.match?.params?.id?.trim();
  const { t } = useTranslation();
  const { loading, data } = useQuery(
    gql`
      ${LIST_LOOKUP_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(lookupId) },
    }
  );

  const lookup = data?.lookup;
  const entries = data?.lookup?.entries;

  // const user = Globals.user;
  // // const branchId = data?.warehouseSection?.branch?.id;
  // const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;

  // const canEdit = user.hasPermission("shipping.warehouse_section.update")
  //   ? canAccessBranch
  //   : false;

  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/lookups/${lookupId}/edit`),
      permission: "core.lookup_entry.update",
    },
  ];

  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !lookup && lookupId ? (
    <NotFound />
  ) : (
    <>
      {!props.id ? (
        <TitleAppBar path={props.match.path}>
          <LongMenu icons={icons} />
        </TitleAppBar>
      ) : null}
      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Paper container xs={12} className={classesView.paper} component={Grid}>
          <KeyValuePair title={t("name")} value={lookup?.name} />
          <KeyValuePair title={t("code")} value={lookup?.code} />
        </Paper>

        <Paper
          container
          sm={12}
          md={8}
          className={classesView.table}
          component={Grid}
        >
          <Toolbar className={classesView.toolbarTable} variant="dense">
            <Typography
              className={classesView.title}
              color="inherit"
              variant="h6"
            >
              {t("entries")}
            </Typography>
          </Toolbar>
          <TableFixedHeaderWraper component={Paper}>
            {loading || data?.lookup.entries.length <= 0 ? (
              <EmptyTableMessage loading={loading} message={t("noResult")} />
            ) : (
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell>{t("code")}</FixedTableCell>
                    <FixedTableCell>{t("name")}</FixedTableCell>
                    <FixedTableCell>{t("mobileActive")}</FixedTableCell>
                    <FixedTableCell>{t("active")}</FixedTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entries &&
                    (rowsPerPage > 0
                      ? entries.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : entries
                    )?.map((row, index) => (
                      <TableRow hover key={index}>
                        <FixedTableCell>{row?.code}</FixedTableCell>
                        <FixedTableCell>{row?.name}</FixedTableCell>
                        <FixedTableCell>
                          <CheckCrossIcons active={row?.mobileActive} />
                        </FixedTableCell>
                        <FixedTableCell>
                          <CheckCrossIcons active={row?.active} />
                        </FixedTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </TableFixedHeaderWraper>
          <MUITablePagination
            count={entries.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePage}
            onRowsPerPageChange={handleRowsPerPage}
          />
        </Paper>
      </RootStyleView>
    </>
  );
};

export default LookupView;
