import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Paper,
  Collapse,
  Stack,
  Dialog,
  DialogContent,
  Alert,
  Button,
  Box,
  IconButton,
  useTheme,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  LIST_PACKAGE_DETAILS,
  OPERATION_ID,
  SAVE_OPERATION,
  APPROVE_MUTATION,
  LIST_MANIFESTS,
} from "./Graphql";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery, useLazyQuery } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_TRANSACTION_TYPES_DROPDOWN,
  LIST_WAREHOUSE_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import moment from "moment";
import PackagesForm from "./Packages/PackagesForm";
import LongMenu from "../../Layout/MenuAppBar";
import { AddCircleOutline, DoneAll } from "@mui/icons-material";
import CustomList from "../HOC/CustomComponents/CustomListDialog/CustomList";
import useWidth from "../../Hooks/useWidth";
import CustomButton from "../HOC/MUI/CustomButton";
import { Can } from "../HOC/CustomComponents/Secured";
import { Globals } from "../HOC/Classes/Globals";
import FormButton from "../CustomComponents/Buttons/FormButton";
import MUIDateTime from "../HOC/MUI/MUIDateTime";
import Tabs from "../HOC/CustomComponents/PackagesTableView/Tabs";
import { TabsProvider } from "../HOC/CustomComponents/PackagesTableView/TabContext";
import ContainersTab from "./Manifests/ContainersTab";
import { dateFormat } from "../../helpers/dateFunctions";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";
const PREFIX = "OperationForm";
const classes = {
  background: `${PREFIX}-background`,
};

const StyledDialog = styled(DialogContent)(({ theme }) => ({
  [`&.${classes.background}`]: {
    backgroundColor: `${theme.palette.background.default} !important`,
  },
}));

const OperationForm = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const user = Globals.user;
  const theme = useTheme();
  const screenWidth = useWidth();
  const anchor = screenWidth === "xs" ? "bottom" : "right";
  const dateFormatTime = (date) =>
    moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");
  const { handleSubmit, control, formState, setValue, setError, watch } =
    useForm();
  const { errors } = formState;

  const [pathURL] = useState(props.match.path);
  const [listType, setListType] = useState(props.match.params.type);
  const [operationId, setRequestId] = useState(parseInt(props.match.params.id));
  const [disabled, setDisabled] = useState({
    formEdit: false,
    addPackage: false,
  });
  const [autocompleteValues, setAutocompleteValues] = useState({
    transactionType: null,
    branch: null,
    warehouse: null,
    priceMode: null,
    currencyId: null,
    productId: null,
    originCountryId: null,
    packageTypeId: null,
  });

  const [dialogState, setDialogState] = useState({
    packages: false,
    details: false,
    containers: false,
  });
  const [state, setState] = React.useState({
    productList: {
      left: false,
      bottom: false,
      right: false,
    },
    containers: {
      left: false,
      bottom: false,
      right: false,
    },
    barcodeDarwerState: {
      left: false,
      bottom: false,
      right: false,
    },
  });
  const [pickedRequest, setPickedRequest] = React.useState();
  const [pickedContainers, setPickedContainers] = React.useState();
  const [manifests, setManifests] = React.useState();

  const [container, setContainer] = React.useState();
  const [containerLoading, setContainerLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [dateRange, setDateRange] = useState({
    operationDate: new Date(),
  });
  const [packages, setPackages] = useState();
  const [updatePackages, setUpdatePackages] = useState(null);

  const setSelect = (obj) => {
    if (listType && listType?.toUpperCase() !== "RCVD") {
      setValue("shipmentId", obj?.data?.code);
      setPickedRequest(obj?.data);
      setPickedContainers(obj?.data?.containers);
      setContainer(obj?.data?.containers[0]?.id);
    } else {
      setPickedRequest(obj?.data);
      setValue("requestId", obj?.data?.code);
    }
  };

  const [saveOperation, { loading }] = useMutation(
    gql`
      ${SAVE_OPERATION.query}
    `
  );

  const { data, loading: updateRequestLoading } = useQuery(
    gql`
      ${OPERATION_ID.query}
    `,
    {
      skip: !operationId,
      variables: { id: operationId },
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        const operationData = data.operation;
        setDisabled((prev) => ({
          ...prev,
          formEdit: true,
        }));
        setPackages(data?.operation?.request?.packages ?? []);
        if (operationData?.request?.packages?.length > 0) {
          setDisabled((prev) => ({
            ...prev,
            addPackage: true,
          }));
        }
        setListType(data.operation?.type?.code);
        setPickedContainers(data.operation?.shipment?.containers);
        setContainerLoading(true);
        data.operation?.type?.code !== "RCVD" &&
          handleManifest(data.operation?.shipment?.containers[0]?.id);
        setContainer(data.operation?.shipment?.containers[0]?.id);
        const requestParams = ["code", "remarks"];
        requestParams.forEach((i) => {
          operationData[i] !== null && setValue(i, operationData[i]);
        });
        setDateRange((prev) => ({
          operationDate: operationData?.operationDate,
        }));
        if (data?.operation?.type?.code !== "RCVD") {
          setValue("shipmentId", operationData?.shipment?.code);
          setPickedRequest(operationData?.shipment);
        } else {
          setValue("requestId", operationData?.request?.code);
          setPickedRequest(operationData?.request);
        }
        setAutocompleteValues((prev) => ({
          ...prev,
          branch: operationData.branch,
          transactionType: operationData.transactionType,
          warehouse: operationData.warehouse,
        }));
      },
    }
  );

  const [approveMutation, { loading: approveLoad }] = useMutation(
    gql`
      ${APPROVE_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        // setCollectionData(data["approveCollection"]);
      },
    }
  );

  const operationData = data?.operation;
  const approve = () => {
    approveMutation({
      variables: {
        id: operationId,
      },
    })
      .then(() => {
        closeConfirmationDialog();
        enqueueSnackbar(t("successfullyApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        pushUrl(props, `/admin/operations/${operationId}`);
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const [getPackageDetailsData] = useLazyQuery(
    gql`
      ${LIST_PACKAGE_DETAILS.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data?.listPackageDetails?.data?.length) {
          data.listPackageDetails.data.forEach((reqPackage) => {
            const index = packages?.findIndex(
              (x) => x.id === reqPackage.package?.id
            );
            if (index !== -1) {
              packages[index].details = data?.listPackageDetails;
            }
          });
        } else {
          console.log("No package details available");
        }
      },
    }
  );
  const handleFetchPackageDetails = (
    requestPackageId,
    pageDetails,
    rowsPerPageDetails
  ) => {
    getPackageDetailsData({
      variables: {
        page: pageDetails + 1,
        first: rowsPerPageDetails,
        input: {
          requestPackageId: parseInt(requestPackageId),
        },
      },
    });
  };
  const [getManifestData] = useLazyQuery(
    gql`
      ${LIST_MANIFESTS.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setManifests(data?.listManifests);
        // console.log(data?.listManifests);

        setContainerLoading(false);
      },
    }
  );
  const handleManifest = (containerId, pageDetails, rowsPerPageDetails) => {
    getManifestData({
      variables: {
        page: pageDetails ?? page + 1,
        first: rowsPerPageDetails ?? rowsPerPage,
        input: {
          operationId: operationId,
          ...(containerId && { containerId: parseInt(containerId) }),
        },
      },
    });
  };
  function getManifest(id, pageDetails, rowsPerPageDetails) {
    if (id === "all") {
      setContainerLoading(true);
      handleManifest(null, pageDetails, rowsPerPageDetails);
    } else {
      setContainerLoading(true);
      handleManifest(id, pageDetails, rowsPerPageDetails);
      setContainer(id);
    }
  }
  const parseData = (data) => {
    return data;
  };
  const closeDialog = () => {
    setDialogState((prev) => ({
      ...prev,
      packages: false,
      details: false,
    }));
    setUpdatePackages(null);

    setAutocompleteValues((prev) => ({
      ...prev,
      packageTypeId: null,
      priceMode: null,
      originCountryId: null,
      warehouseId: null,
      currencyId: null,
      productId: null,
    }));
  };

  const requestFilters = {
    transactionTypeId: watch("transactionTypeId"),
    ...(listType && listType?.toUpperCase() !== "RCVD"
      ? {
          loadingMode: { loadingMode: ["FCL", "FTL"], includeNull: true },
          shipmentDate: {
            toDate: dateFormat(dateRange.operationDate),
          },
        }
      : {
          requestDate: {
            toDate: dateFormat(dateRange.operationDate),
          },
        }),
    ...(listType &&
      listType?.toUpperCase() !== "RCVD" && { hasContainers: true }),
    ...(listType &&
      listType?.toUpperCase() === "DELIVERED" && {
        unloadedWarehouseId: watch("warehouseId"),
      }),
    status:
      listType && listType?.toUpperCase() === "RCVD"
        ? "RQST"
        : listType?.toUpperCase() === "LOADING"
        ? "SCHEDULED"
        : listType?.toUpperCase() === "UNLOADED"
        ? "ARRIVED"
        : "UNLOADED",
    inOperation: {
      inOperation: false,
      ...(listType &&
        listType?.toUpperCase() === "LOADING" && {
          type: "LOADING",
        }),
      ...(listType &&
        listType?.toUpperCase() === "UNLOADED" && {
          type: "UNLOADED",
        }),
      ...(listType &&
        listType?.toUpperCase() === "DELIVERED" && {
          type: "DELIVERED",
        }),
      ...(operationId && { operationId: operationId }),
    },
  };

  const onSubmit = (data) => {
    for (const key in data) {
      if (
        (data[key] === "" && key === "code") ||
        key === "shipperMobileCode" ||
        key === "consigneeMobileCode"
      ) {
        delete data[key];
      }
      if (data[key] === "") {
        data[key] = null;
        continue;
      }
      if (["creditLimit"].includes(key)) {
        data[key] = parseFloat(data[key]);
      }
    }

    mutation(data);
  };
  const mutation = (data) => {
    saveOperation({
      variables: {
        input: {
          ...(data.code && {
            code: data.code,
          }),
          branchId: data?.branchId,
          transactionTypeId: data?.transactionTypeId,
          remarks: data?.remarks,
          warehouseId: data?.warehouseId,
          ...(dateRange.operationDate && {
            operationDate: dateFormatTime(dateRange.operationDate),
          }),
          ...(pickedRequest && data?.requestId
            ? { requestId: pickedRequest.id }
            : { shipmentId: pickedRequest.id }),
        },
      },
    })
      .then((data) => {
        setRequestId(data?.data?.saveOperation?.id);
        true &&
          pushUrl(
            props,
            `/admin/operations/${data?.data?.saveOperation?.id}/edit`
          );
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      });
  };
  // console.log(pickedRequest);
  const [filters, setFilter] = React.useState({});
  useEffect(() => {
    setFilter((prev) => ({
      shippingDirection: pickedRequest?.shippingDirection?.code,
      shippingMode: pickedRequest?.shippingMode?.code,
      originPortId: pickedRequest?.originPort?.id,
      destinationPortId: pickedRequest?.destinationPort?.id,
      ...(listType &&
        (listType?.toUpperCase() === "UNLOADED" ||
          listType?.toUpperCase() === "DELIVERED") && {
          shipmentId: pickedRequest?.id,
          containerId: container,
          // damaged: false,
          // missing: false,
        }),
      ...(listType &&
        listType?.toUpperCase() === "LOADING" && {
          warehouseId: watch("warehouseId"),
          requestDate: {
            toDate: dateFormat(dateRange.operationDate),
          },
          ...(manifests &&
            manifests?.data?.length !== 0 &&
            pickedRequest?.shippingMode?.code !== "AIR" && {
              ...(["FTL", "FCL"].includes(
                manifests?.data[0]?.packageDetail?.package?.request?.loadingMode
                  ?.code
              )
                ? {
                    requestId:
                      manifests?.data[0]?.packageDetail?.package?.request?.id,
                  }
                : {
                    loadingMode: ["LCL", "LTL"],
                  }),
            }),
        }),
      ...(listType &&
        listType?.toUpperCase() === "DELIVERED" && {
          unloadedWarehouseId: watch("warehouseId"),
        }),
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manifests]);

  // console.log(filters);

  const [dialogDetails, setDialogDetails] = useState({
    state: false,
    function: null,
    confirmAction: true,
    title: "",
    content: "",
  });
  const closeConfirmationDialog = () => {
    setDialogDetails((prev) => ({
      ...prev,
      state: false,
    }));
  };
  const openShipmentRequestDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: null,
      content: (
        <CustomList
          setSelected={(obj) => {
            setSelect(obj);
          }}
          filters={requestFilters}
          selected={pickedRequest}
          pathType={
            listType && listType?.toUpperCase() === "RCVD"
              ? "Request"
              : "Shipment"
          }
          done={() => {
            closeConfirmationDialog();
          }}
        />
      ),
      confirmAction: true,
      function: null,
    }));
  };

  const openApproveDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: t("confirmation"),
      content: t("confirmationMsg"),
      confirmAction: true,
      function: approve,
    }));
  };

  const value = {
    operationData,
    updatePackages,
    packages,
    setPackages,
    handleFetchPackageDetails,
    closeDialog,
    autocompleteValues,
    setAutocompleteValues,
    setDisabled,
    requestId: operationData?.request
      ? operationData?.request?.id
      : operationData?.shipment?.id,
    setDialogState,
    setUpdatePackages,
    tabsLoading: loading,
    requestData: operationData?.request,
    theme,
    anchor,
    state,
    setState,
    pickedRequest,
    container,
    operationId,
    handleManifest,
    manifests,
    pickedContainers,
    containerLoading,
    filters,
    type: listType?.toUpperCase(),
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    getManifest,
  };

  const icons = [
    {
      id: "print",
      action: () =>
        window.open(
          `${
            window.location.origin
          }/report/print/operation/${operationId}/${listType.toLowerCase()}`
        ),
    },
  ];
  const body = (
    <Paper spacing={2} component={"form"} onSubmit={handleSubmit(onSubmit)}>
      <Stack sx={{ position: "relative" }}>
        {disabled.formEdit && (
          <Box
            className={classesForm.overlay}
            sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
          ></Box>
        )}{" "}
        <Grid
          container
          spacing={2}
          className={clsx(classesForm.spacing)}
          sx={{ p: 1 }}
        >
          <Grid xs={12} sm={4} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"code"}
              label={t("code")}
            />
          </Grid>
          <Grid xs={12} sm={4}>
            <MUIDateTime
              name={"operationDate"}
              label={t("date")}
              control={control}
              value={dateRange.operationDate}
              defaultValue={dateRange.operationDate}
              onChange={(e) => {
                setDateRange((prev) => ({ ...prev, operationDate: e }));
              }}
            />
          </Grid>
          <Grid sm={4} xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              selectFirst={true}
              parseData={(data) => parseData(data)}
              name={"transactionTypeId"}
              label={t("transactionType")}
              skip={!watch("branchId") || Boolean(operationId)}
              rules={{ required: t("fieldIsRequired") }}
              query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
              onChangeValue={(e) => {
                listType && listType?.toUpperCase() === "RCVD"
                  ? setValue("requestId", "")
                  : setValue("shipmentId", "");
                setPickedRequest(null);
              }}
              variables={{
                input: {
                  ...(watch("branchId") && {
                    branchId: {
                      value: watch("branchId"),
                      includeNull: true,
                    },
                  }),
                  ...(listType && { type: listType.toUpperCase() }),
                },
              }}
              defaultValue={autocompleteValues.transactionType}
              // onCompleted={(data) =>
              //   handelAutocompleteDefaultValue(data, "transactionTypeId")
              // }
            />
          </Grid>
          <Grid sm={4} xs={12}>
            <ListBranches
              control={control}
              errors={errors}
              name={"branchId"}
              skip={Boolean(operationId)}
              rules={{ required: t("fieldIsRequired") }}
              defaultValue={autocompleteValues.branch}
              onChangeValue={(e) => {
                setValue("warehouseId", "");
                setValue("transactionTypeId", "");
              }}
              disabled={
                listType &&
                listType?.toUpperCase() === "RCVD" &&
                pickedContainers?.length > 0
              }
            />
          </Grid>

          <Grid sm={4} xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={
                listType &&
                listType?.toUpperCase() === "RCVD" &&
                (!Boolean(watch("branchId")) || pickedContainers?.length > 0)
              }
              skip={!Boolean(watch("branchId")) || Boolean(operationId)}
              name={"warehouseId"}
              label={t("wareHouse")}
              parseData={(data) => parseData(data)}
              rules={{ required: t("fieldIsRequired") }}
              variables={{
                input: {
                  branchId: watch("branchId"),
                },
              }}
              query={LIST_WAREHOUSE_DROPDOWN.query}
              defaultValue={autocompleteValues.warehouse}
            />
          </Grid>

          <Grid xs={12} sm={4} alignItems="flex-start">
            <Stack direction={"row"}>
              <ControlMUItextField
                control={control}
                errors={errors}
                name={
                  listType && listType?.toUpperCase() === "RCVD"
                    ? "requestId"
                    : "shipmentId"
                }
                readOnly={true}
                label={
                  listType && listType?.toUpperCase() === "RCVD"
                    ? t("requestCode")
                    : t("shipmentCode")
                }
                rules={{ required: t("fieldIsRequired") }}
              />

              <IconButton
                onClick={() => openShipmentRequestDialog()}
                size="large"
                disabled={
                  !Boolean(watch("transactionTypeId")) ||
                  (listType?.toUpperCase() === "DELIVERED" &&
                    !Boolean(watch("warehouseId")))
                }
              >
                <AddCircleOutline />
              </IconButton>
            </Stack>
          </Grid>
          <Grid xs={12} sm={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"remarks"}
              label={t("notes")}
            />
          </Grid>
        </Grid>
      </Stack>
      <Grid sm={12} xs={12} container justifyContent="flex-end">
        {!disabled.formEdit && (
          <FormButton
            disabled={loading || disabled.formEdit || operationData?.posted}
            className={classesForm.button}
          >
            {loading ? <ButtonLoading /> : t("save")}
          </FormButton>
        )}
        {disabled.formEdit && listType && (
          <Can
            permission={Boolean(
              user.hasPermission(
                `freight.operation_${listType.toLowerCase()}.approve`
              )
            )}
            showException
          >
            <CustomButton
              customcolor={theme.palette.success.main}
              disabled={
                Boolean(loading) ||
                Boolean(operationData?.approved) ||
                Boolean(
                  listType && listType?.toUpperCase() === "RCVD"
                    ? packages?.length === 0
                    : manifests?.data?.length === 0
                )
              }
              className={classesForm.button}
              loading={loading}
              variant="contained"
              onClick={openApproveDialog}
              startIcon={<DoneAll />}
            >
              {t("approve")}
            </CustomButton>
          </Can>
        )}
      </Grid>
    </Paper>
  );
  let DOM;

  if (operationId) {
    DOM = data ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return updateRequestLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data && operationId ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={pathURL} type={listType?.toLowerCase()}>
        <LongMenu icons={operationId ? icons : []} />
      </TitleAppBar>
      {operationId && disabled.formEdit ? (
        <Grid sm={12} xs={12}>
          <Alert severity="warning">{t("unUpdatable")}</Alert>
        </Grid>
      ) : (
        ""
      )}

      <TabsProvider value={value}>
        <Stack spacing={2} p={2}>
          {" "}
          {DOM}
        </Stack>

        {dialogDetails.state && (
          <CustomDialog
            styleContent={
              dialogDetails.title == null
                ? { overflow: "hidden", padding: 0 }
                : dialogDetails.title === "" && { overflow: "hidden" }
            }
            title={dialogDetails.title}
            fullWidth
            maxWidth={dialogDetails.title == null ? "md" : "xs"}
            onClose={closeConfirmationDialog}
            content={dialogDetails.content}
            open={dialogDetails.state}
            actions={
              dialogDetails.function !== null && (
                <>
                  <Button color="primary" onClick={closeConfirmationDialog}>
                    {dialogDetails.confirmAction ? t("cancel") : t("close")}
                  </Button>
                  {dialogDetails.confirmAction && (
                    <Button
                      color="primary"
                      disabled={approveLoad}
                      onClick={dialogDetails.function}
                    >
                      {approveLoad ? <ButtonLoading /> : t("confirm")}
                    </Button>
                  )}
                </>
              )
            }
          >
            {dialogDetails.function === null && ""}
          </CustomDialog>
        )}
        {dialogState.packages && (
          <Dialog
            fullWidth
            maxWidth={"md"}
            sx={{ height: "650px", margin: "auto" }}
            classes={{
              paper: classesForm.dialog,
            }}
            open={dialogState.packages}
            onClose={() => {
              closeDialog();
            }}
          >
            <StyledDialog className={classes.background}>
              {<PackagesForm />}
            </StyledDialog>
          </Dialog>
        )}

        <Grid container spacing={2} mx={2} my={0} sx={{ position: "relative" }}>
          {pickedContainers?.length > 0 && (
            <Paper spacing={2} component={Grid} xs={12}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={pickedContainers[0]?.id} // Set the default selected value to "all"
                name="radio-buttons-group"
                row
                sx={{ display: "flex", justifyContent: "space-around" }}
              >
                {pickedContainers?.map((i) => (
                  <FormControlLabel
                    // dir="ltr"
                    onClick={() => {
                      getManifest(i?.id, 1, 20);
                      setPage(0);
                      setRowsPerPage(20);
                    }}
                    key={i?.id}
                    value={i.id}
                    control={<Radio />}
                    label={`\u202A${i.packageType?.name} (${i.containerNumber})`}
                  />
                ))}
              </RadioGroup>
            </Paper>
          )}
          {(!operationId || !disabled?.formEdit) && (
            <Box className={classesForm.overlay}></Box>
          )}
          {listType && listType?.toUpperCase() === "RCVD" ? (
            <Tabs />
          ) : (
            <Paper component={Grid} xs={12} p={0} mt={2}>
              <ContainersTab />
            </Paper>
          )}
        </Grid>
      </TabsProvider>
    </RootStyleForm>
  );
};

export default OperationForm;
