import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button, Collapse, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { SAVE_CUSTOMER_SALES } from "./Graphql";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { LIST_EMPLOYEE_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import moment from "moment";
import MUIDate from "../HOC/MUI/MUIDate";
import { dateFormat } from "../../helpers/dateFunctions";
import { useCustomersContext } from "./Context/CustomersContext";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";

export default function SalesForm(props) {
  const {
    autocompleteValues,
    customerId,
    updateSales,
    sales,
    setSales,
    closeSalesDialog,
    setAutocompleteValues,
    dialogState,
  } = useCustomersContext();
  const { handleSubmit, control, setError, errors } = useForm();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const initFromDate = moment(new Date())
    .locale("en")
    .subtract("month")
    .add("day")
    .format("YYYY-MM-DD");
  const [dateRange, setDateRange] = React.useState({
    fromDate: initFromDate,
    toDate: null,
  });

  const [saveCustomerSales, { loading: saveCustomerSalesLoading }] =
    useMutation(
      gql`
        ${SAVE_CUSTOMER_SALES.query}
      `
    );

  useEffect(() => {
    if (updateSales) {
      setDateRange((prev) => ({
        ...prev,
        fromDate: updateSales.fromDate,
        toDate: updateSales.toDate,
      }));
      setAutocompleteValues((prev) => ({
        ...prev,
        employee: updateSales?.employee,
      }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const parseData = (data) => {
    return data;
  };
  const onSubmit = (data, id) => {
    for (const key in data) {
      if (data[key] === "" || data[key] === null) {
        delete data[key];
      }
    }
    saveCustomerSales({
      variables: {
        input: {
          ...(customerId && { customerId: customerId }),
          ...(id && { id: id }),
          fromDate: dateFormat(dateRange["fromDate"]),
          ...(dateRange?.toDate && { toDate: dateFormat(dateRange["toDate"]) }),
          employeeId: parseInt(data.employeeId),
        },
      },
    })
      .then((data) => {
        const checksubZone = sales.map(
          (ele) => ele.id === data.data.saveCustomerSales.id
        );
        const check = checksubZone.includes(true);
        if (check) {
          const index = sales.findIndex(
            (x) => x.id === data.data.saveCustomerSales.id
          );
          sales[index] = data.data.saveCustomerSales;
          setSales(sales);
        } else {
          sales.push(data.data.saveCustomerSales);
          setSales(sales);
        }
        closeSalesDialog();
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });

        console.log(graphQLErrors);
      });
  };
  const body = (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      alignItems={"center"}
    >
      <Grid xs={12} sm={12}>
        <CustomAutocomplete
          valueKey="code"
          control={control}
          errors={errors}
          disabled={Boolean(autocompleteValues.employee)}
          rules={{ required: t("fieldIsRequired") }}
          name={"employeeId"}
          label={t("employeeName")}
          variables={{
            input: {
              designation: "SALS",
            },
          }}
          parseData={(data) => parseData(data)}
          query={LIST_EMPLOYEE_DROPDOWN.query}
          defaultValue={autocompleteValues.employee}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <MUIDate
          name={"fromDate"}
          label={t("fromDate")}
          control={control}
          disabled={Boolean(updateSales?.fromDate)}
          value={dateRange.fromDate}
          defaultValue={dateRange.fromDate}
          disablePast
          onChange={(e) => {
            setDateRange((prev) => ({ ...prev, fromDate: e }));
          }}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <MUIDate
          name={"toDate"}
          label={t("toDate")}
          control={control}
          value={dateRange.toDate}
          defaultValue={dateRange.toDate}
          // disablePast
          onChange={(e) => {
            setDateRange((prev) => ({ ...prev, toDate: e }));
          }}
        />
      </Grid>
    </Grid>
  );
  const title = (
    <Grid
      container
      justifyContent="space-between"
      xs={12}
      alignItems={"center"}
    >
      <Typography variant="h6" color={"text.primary"}>
        {t("sales")}
      </Typography>
    </Grid>
  );
  return (
    <CustomDialog
      fullWidth
      maxWidth="xs"
      open={dialogState.sales}
      onClose={closeSalesDialog}
      title={title}
      content={body}
      actions={
        <>
          <Button onClick={closeSalesDialog}>{t("cancel")}</Button>
          <Button
            onClick={handleSubmit((data) => onSubmit(data, updateSales?.id))}
          >
            {saveCustomerSalesLoading ? <ButtonLoading /> : t("confirm")}
          </Button>
        </>
      }
    ></CustomDialog>
  );
}
