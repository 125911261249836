import React from "react";
import { useTranslation } from "react-i18next";
import { Paper, Icon } from "@mui/material";

import { gql, useQuery } from "@apollo/client";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { USER_ID } from "./Graphql";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import BranchesTable from "./BranchesTable";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";

const UserView = (props) => {
  const { t } = useTranslation();
  const userId = props.match.params.id?.trim();
  const { loading, data } = useQuery(
    gql`
      ${USER_ID.query}
    `,

    {
      skip: !userId,
      variables: { id: parseInt(userId) },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {},
    }
  );

  const userBranches = data?.user?.userBranches?.map((i) => ({
    ...i,
    isDefault: i.default,
  }));

  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/users/${userId}/edit`),
      permission: data?.user?.editable,
    },
  ];
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.user ? (
    <NotFound />
  ) : (
    <>
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>
      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair title={t("username")} value={data?.user?.username} />
          <KeyValuePair
            title={t("active")}
            value={
              data?.user?.active ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("role")}
            value={
              data?.user?.roles &&
              data?.user?.roles?.map((role, index) => (
                <SpanLink
                  key={role.id}
                  className={classesView.span}
                  pathname={`/admin/roles/${role.id}`}
                >
                  {role.name}
                  {!(data?.user?.roles.length - 1 === index) && " , "}
                </SpanLink>
              ))
            }
          />

          <KeyValuePair
            title={t("account")}
            value={
              data?.user?.account ? (
                <SpanLink
                  pathname={
                    data?.user?.roles?.some((el) => el.id === 2)
                      ? `/admin/customers/${data?.user?.account?.id}`
                      : `/admin/employees/${data?.user?.account?.id}`
                  }
                >
                  {data?.user?.account?.name}
                </SpanLink>
              ) : (
                "--"
              )
            }
          />
        </Paper>
      </RootStyleView>
      <BranchesTable branches={userBranches} />
    </>
  );
};

export default UserView;
