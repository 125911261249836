import React, { Fragment, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import MUItextField from "../HOC/MUI/MUItextField";
import { useForm } from "react-hook-form";
import { Collapse } from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import { gql, useMutation } from "@apollo/client";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import * as gqlb from "gql-query-builder";
import { useTranslation } from "react-i18next";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import { useSnackbar } from "notistack";
import { pushUrl, replaceUrl } from "../HOC/CustomFunctions/pushUrl";
import SpanLink from "../HOC/CustomComponents/SpanLink";

const PREFIX = "Login";

const classes = {
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  button: `${PREFIX}-button`,
  spinner: `${PREFIX}-spinner`,
  divider: `${PREFIX}-divider`,
  inestancePage: `${PREFIX}-inestancePage`,
  companyCodeRoot: `${PREFIX}-companyCodeRoot`,
  companyCode: `${PREFIX}-companyCode`,
  login: `${PREFIX}-loginSpan`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.paper}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.form}`]: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(2, 0),
  },

  [`& .${classes.spinner}`]: {
    width: 24,
    margin: theme.spacing(0, 1),
  },

  [`& .${classes.divider}`]: {
    backgroundColor: theme.palette.action.active,
    marginTop: theme.spacing(1),
  },

  [`& .${classes.inestancePage}`]: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1000,
    backgroundColor: "#fff",
  },

  [`& .${classes.companyCodeRoot}`]: { marginTop: theme.spacing(2) },
  [`& .${classes.companyCode}`]: { marginRight: theme.spacing(1) },
  [`& .${classes.login}`]: {
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
  },
}));

export default function Forgotpassword(props) {
  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      replaceUrl(props, "/admin");
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm();

  const RESET_PASSWORD = gqlb.mutation({
    operation: "resetPassword",
    variables: {
      email: {
        type: "String",
        required: true,
      },
    },
  });

  const [reset, { loading }] = useMutation(
    gql`
      ${RESET_PASSWORD.query}
    `
  );

  const onSubmit = (data) => {
    const email = data.email;
    reset({
      variables: {
        email,
      },
    })
      .then((data) => {
        pushUrl(props, `/admin`);
        enqueueSnackbar(t("sentEmailSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };

  return (
    <Root>
      <Container maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("forgotPassword")}
          </Typography>
          <Fragment>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <MUItextField
                name={"email"}
                label={t("email")}
                register={register}
                errors={errors}
                margin="normal"
              />
              <Grid item container justifyContent="center">
                <Button
                  fullWidth
                  className={classes.button}
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                  startIcon={
                    loading ? (
                      <CustomSpinner
                        size={8}
                        css={{ fontSize: "10px !important" }}
                        name={"PulseLoader"}
                      />
                    ) : (
                      <LockOutlined />
                    )
                  }
                >
                  {t("confirm")}
                </Button>
              </Grid>
            </form>
            <Grid item container justifyContent="start" fontSize={16}>
              {t("alreadyhaveaccount")}
              <SpanLink className={classes.login} pathname={`/login`}>
                {t("login")}
              </SpanLink>
              {/* <Button
                fullWidth
                className={classes.button}
                size="large"
                onClick={() => {
                  pushUrl(props, `/login`);
                }}
                variant="contained"
                sx={{ backgroundColor: blueGrey[500], color: "#fff" }}
                type="submit"
                startIcon={<AddCircleOutline />}
              >
                {t("login")}
              </Button> */}
            </Grid>
          </Fragment>
        </div>
      </Container>
    </Root>
  );
}
