import * as gqlb from "gql-query-builder";

export const SAVE_SETTINGS = gqlb.mutation({
  operation: "updateFreightSettings",
  fields: ["createShipmentInPast"],
  variables: {
    input: {
      type: "FreightSettingsInput",
      required: true,
    },
  },
});

export const SETTINGS = gqlb.query({
  operation: "freightSettings",
  fields: [
    "createShipmentInPast",
    "updateShipmentSequence",
    "createRequestInPast",
    "updateRequestSequence",
    "createEmployeeAccount",
    "volumetricFactor",
    { mainEmployeeGLAccount: ["code", "name", "id"] },
    { mainVendorGLAccount: ["code", "name", "id"] },
    "createVendorAccount",
    { mainCustomerGLAccount: ["code", "name", "id"] },
    "createCutomerAccount",

    "createCarrierAccount",
    { mainCarrierGLAccount: ["code", "name", "id"] },
    "createCustomAgentAccount",
    { mainCustomAgentGLAccount: ["code", "name", "id"] },
    "createAgencyAccount",
    { mainCustomerGLAccount: ["code", "name", "id"] },

    { mainAgencyGLAccount: ["code", "name", "id"] },
    { purchaseChargeElement: ["code", "name", "id"] },
    { shippingChargeElement: ["code", "name", "id"] },
    { commissionChargeElement: ["code", "name", "id"] },
    "supportedVersion",
    "renewalDate",
    {
      email: ["password", "username", "port", "host", "active"],
    },
    {
      whatsapp: ["id", "token", "active", "provider"],
    },
  ],
  variables: {},
});
