import { fileNames } from "./fileLoader";

export const waybills = (t) => [
  {
    // code: "SHEET_SAME_1",
    // printLink: "SHEET_SAME_1",
    // name: t("oneCopy"),
  },
  ...fileNames(t),
];
