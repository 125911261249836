import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, MenuItem, Select, Typography, Toolbar } from "@mui/material";
import { DateRange } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import MUIStaticDateRangePicker from "../HOC/MUI/MUIStaticDateRangePicker";

const StatisticsFilterHeader = (props) => {
  const { onDateRangeAccept, handleDateRange, title, section } = props;

  const [dateRange, setDateRange] = useState([null, null]);
  const [selectValue, setSelectValue] = useState("month");
  const [dateRangeDialog, setDateRangeDialog] = useState(false);
  const { t } = useTranslation();

  const closeDateRangeDialog = () => {
    setDateRangeDialog(false);
  };

  const PREFIX = "Statistics";

  const classes = {
    toolbar: `${PREFIX}-toolbar`,
  };

  const Root = styled("div")(({ theme, ...props }) => ({
    width: "100%",
    [`& .${classes.toolbar}`]: {
      padding: theme.spacing(0, 2),
    },
  }));

  return (
    <>
      <CustomDialog
        open={dateRangeDialog}
        onClose={closeDateRangeDialog}
        content={
          <MUIStaticDateRangePicker
            value={dateRange}
            onChangeValue={(value) => setDateRange(value)}
            onAccept={(e) =>
              onDateRangeAccept(e, closeDateRangeDialog, section)
            }
          />
        }
        actions={<Button onClick={closeDateRangeDialog}>{t("cancel")}</Button>}
      />

      <Root>
        <Toolbar className={classes.toolbar} sx={{ width: "100%" }}>
          <Typography
            sx={{ fontWeight: "600", display: "flex", mr: 1 }}
            variant="body1"
          >
            <props.icon />
          </Typography>
          <Typography
            sx={{ flex: "1 1 100%", fontWeight: "600" }}
            variant="body1"
          >
            {title}
          </Typography>
          {onDateRangeAccept && (
            <Select
              variant="standard"
              disableUnderline
              sx={{ fontSize: "0.8rem" }}
              onChange={(e) =>
                handleDateRange(
                  e.target.value,
                  setSelectValue,
                  setDateRangeDialog,
                  section
                )
              }
              value={selectValue}
              startAdornment={
                <DateRange
                  color="action"
                  sx={{ margin: "0 4px", fontSize: "0.9rem" }}
                />
              }
            >
              <MenuItem value={"all"}>{t("all")}</MenuItem>
              <MenuItem value={"month"}>{t("thirtyDays")}</MenuItem>
              <MenuItem value={"week"}>{t("sevenDay")}</MenuItem>
              <MenuItem
                onClick={() => setDateRangeDialog(true)}
                value={"period"}
              >
                {t("duringPeriod")}
              </MenuItem>
            </Select>
          )}
        </Toolbar>
      </Root>
    </>
  );
};

export default StatisticsFilterHeader;
