import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button, Collapse, DialogActions, DialogContent } from "@mui/material";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { SAVE_SHIPMENT_CONTAINER } from "./Graphql";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { LIST_PACKAGE_TYPES_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { useContainersContext } from "./ShipmentContainerContext";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";

export default function ShipmentContainer(props) {
  const {
    closeDialog,
    shipmentId,
    containers,
    setContainers,
    updateContainers,
    shipmentDate,
    setDisabled,
  } = useContainersContext();
  const [autocompleteValues, setAutocompleteValues] = React.useState({
    packageType: null,
  });
  const { handleSubmit, control, setError, errors, setValue } = useForm();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [savePriceModes, { loading: savePriceModesLoading }] = useMutation(
    gql`
      ${SAVE_SHIPMENT_CONTAINER.query}
    `
  );

  useEffect(() => {
    if (updateContainers) {
      setAutocompleteValues((prev) => ({
        ...prev,
        packageType: updateContainers?.packageType,
      }));
      setValue("containerNumber", updateContainers?.containerNumber);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const parseData = (data) => {
    return data;
  };
  const onSubmit = (data, shipmentId, containersId) => {
    for (const key in data) {
      if (data[key] === "" || data[key] === null) {
        delete data[key];
      }
    }
    savePriceModes({
      variables: {
        input: {
          ...(shipmentId && { shipmentId: shipmentId }),
          ...(containersId && { id: containersId }),
          ...data,
        },
      },
    })
      .then((data) => {
        const checksubZone = containers.map(
          (ele) => ele.id === data.data.saveShipmentContatiner.id
        );
        const check = checksubZone.includes(true);
        if (check) {
          const index = containers.findIndex(
            (x) => x.id === data.data.saveShipmentContatiner.id
          );
          containers[index] = data.data.saveShipmentContatiner;
          setContainers(containers);
        } else {
          containers.push(data.data.saveShipmentContatiner);
          setContainers(containers);
        }
        setDisabled((prev) => ({
          ...prev,
          addContainer: true,
        }));
        closeDialog();
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }

        console.log(graphQLErrors);
      });
  };
  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit((data) =>
          onSubmit(data, shipmentId, updateContainers?.id)
        )}
      >
        <DialogContent sx={{ padding: "2" }}>
          <CustomAutocomplete
            rules={{ required: t("fieldIsRequired") }}
            control={control}
            errors={errors}
            name={"packageTypeId"}
            margin="normal"
            label={t("packageType")}
            parseData={(data) => parseData(data)}
            query={LIST_PACKAGE_TYPES_DROPDOWN.query}
            variables={{
              input: {
                shippingMode: shipmentDate?.shippingMode?.code,
                shippingDirection: shipmentDate?.shippingDirection?.code,
                isContainer: true,
              },
            }}
            defaultValue={autocompleteValues.packageType}
          />

          <ControlMUItextField
            control={control}
            errors={errors}
            name={"containerNumber"}
            label={t("containerNumber")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={closeDialog}>{t("cancel")}</Button>
          <Button type="submit">
            {savePriceModesLoading ? <ButtonLoading /> : t("confirm")}
          </Button>
        </DialogActions>
      </form>
    </React.Fragment>
  );
}
