import React, { useEffect, useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import {
  TableBody,
  TableRow,
  Button,
  Icon,
  Checkbox,
  TableHead,
  Collapse,
  DialogContentText,
} from "@mui/material";
import { Search } from "@mui/icons-material";
// import { LIST_REQUESTS } from "../";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useWidth from "../../Hooks/useWidth";
import config from "../../config.json";
import Grid from "@mui/material/Unstable_Grid2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_COUNTRIES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_SHIPPING_PORTS_DROPDOWN,
  LIST_VENDORS_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import { MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete";
import moment from "moment";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import clsx from "clsx";
import { PACKING_REQUESTS, LIST_REQUESTS } from "./Graphql";
import { useSnackbar } from "notistack";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { RootStyleList, classes } from "../../GlobalStyles/ListStyle";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";

const RequestList = (props) => {
  const { t } = useTranslation();
  const screenWidth = useWidth();
  const history = useHistory();
  const [refetch, setrefetch] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const [autocompleteValues, setAutocompleteValues] = useState({
    customer: null,
    vendor: null,
    branch: null,
    loadingMode: null,
    status: null,
    originCountry: null,
    destinationCountry: null,
    originPort: null,
    destinationPort: null,
    mode: [],
    shippingDirection: [],
  });
  const { enqueueSnackbar } = useSnackbar();

  const [noData, setNoData] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(
    urlQuery["rowsPerPage"] ?? config.app.pageSize
  );
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";
  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const [shippingDate, setShippingDate] = useState([
    urlQuery["fromDate"] ? urlQuery["fromDate"] : null,
    urlQuery["toDate"] ? urlQuery["toDate"] : null,
  ]);
  const [finishDate, setFinishDate] = useState([
    urlQuery["finishFromDate"] ? urlQuery["finishFromDate"] : null,
    urlQuery["finishToDate"] ? urlQuery["finishToDate"] : null,
  ]);
  const [requestDate, setRequestDate] = useState([
    urlQuery["requestFromDate"] ? urlQuery["requestFromDate"] : null,
    urlQuery["requestToDate"] ? urlQuery["requestToDate"] : null,
  ]);
  const fromDate = moment(shippingDate[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(shippingDate[1]).locale("en").format("YYYY-MM-DD");
  const finishFromDate = moment(finishDate[0])
    .locale("en")
    .format("YYYY-MM-DD");
  const finishToDate = moment(finishDate[1]).locale("en").format("YYYY-MM-DD");
  const requestFromDate = moment(requestDate[0])
    .locale("en")
    .format("YYYY-MM-DD");
  const requestToDate = moment(requestDate[1])
    .locale("en")
    .format("YYYY-MM-DD");
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      ...(shippingDate[0] && { fromDate }),
      ...(shippingDate[1] && { toDate }),
      ...(finishDate[0] && { finishFromDate }),
      ...(finishDate[1] && { finishToDate }),
      ...(requestDate[0] && { requestFromDate }),
      ...(requestDate[1] && { requestToDate }),
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      urlQuery["hasPackages"] !== undefined &&
        setValue("hasPackages", urlQuery["hasPackages"]);
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      if (urlQuery["shippingMode"]) {
        urlQuery["shippingMode"] = urlQuery["shippingMode"].split(",");
      }
      if (urlQuery["shippingDirection"]) {
        urlQuery["shippingDirection"] =
          urlQuery["shippingDirection"].split(",");
      }
      if (urlQuery["loadingMode"]) {
        urlQuery["loadingMode"] = urlQuery["loadingMode"].split(",");
      }
      if (urlQuery.fromDate || urlQuery.toDate) {
        const shippingDate = {
          ...(urlQuery?.fromDate && { fromDate: urlQuery?.fromDate }),
          ...(urlQuery?.toDate && { toDate: urlQuery?.toDate }),
        };
        delete urlQuery["fromDate"];
        delete urlQuery["toDate"];
        urlQuery.shippingDate = shippingDate;
      }
      if (urlQuery.finishFromDate || urlQuery.finishToDate) {
        const finishDate = {
          ...(urlQuery?.finishFromDate && {
            fromDate: urlQuery?.finishFromDate,
          }),
          ...(urlQuery?.finishToDate && {
            toDate: urlQuery?.finishToDate,
          }),
        };
        delete urlQuery["finishFromDate"];
        delete urlQuery["finishToDate"];
        urlQuery.finishDate = finishDate;
      }
      if (urlQuery.requestFromDate || urlQuery.requestToDate) {
        const requestDate = {
          ...(urlQuery?.requestFromDate && {
            fromDate: urlQuery?.requestFromDate,
          }),
          ...(urlQuery?.requestToDate && {
            toDate: urlQuery?.requestToDate,
          }),
        };
        delete urlQuery["requestFromDate"];
        delete urlQuery["requestToDate"];
        urlQuery.requestDate = requestDate;
      }
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());
  const [packingRequest, { loading: packingLoading }] = useMutation(
    gql`
      ${PACKING_REQUESTS.query}
    `
  );
  const {
    data,
    loading,
    refetch: refetchList,
  } = useQuery(
    gql`
      ${LIST_REQUESTS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          status: "RCVD",
          ...(search.code && { code: search.code }),
          ...(search.branchId && {
            branchId: search.branchId,
          }),
          ...(search.shippingMode && {
            shippingMode: search.shippingMode,
          }),
          ...(search.shippingDirection && {
            shippingDirection: search.shippingDirection,
          }),
          // ...(search.status && {
          //   status: search.status,
          // }),
          ...(search.loadingMode && {
            loadingMode: search.loadingMode,
          }),
          ...(search.customerId && {
            customerId: search.customerId,
          }),
          ...(search.vendorId && {
            vendorId: search.vendorId,
          }),
          ...(search.destinationPortId && {
            destinationPortId: search.destinationPortId,
          }),
          ...(search.originPortId && { originPortId: search.originPortId }),
          ...(search.originCountryId && {
            originCountryId: search.originCountryId,
          }),
          ...(search.destinationCountryId && {
            destinationCountryId: search.destinationCountryId,
          }),
          ...(search.shippingDate && { shippingDate: search.shippingDate }),
          ...(search.finishDate && { finishDate: search.finishDate }),
          ...(search.requestDate && { requestDate: search.requestDate }),
          hasPackages: search.hasPackages,
        },
      },
      onCompleted: () => {
        setNoData(false);
      },
    }
  );

  const listRequests = data?.listRequests?.data;
  useEffect(() => {
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    urlQuery["hasPackages"] !== undefined &&
      setValue("hasPackages", urlQuery["hasPackages"]);
    setAutocompleteValues((prev) => ({
      ...(urlQuery["branchId"] && {
        branch: urlQuery["branchId"],
      }),
      ...(urlQuery["vendorId"] && {
        vendor: urlQuery["vendorId"],
      }),
      ...(urlQuery["customerId"] && {
        customer: urlQuery["customerId"],
      }),
      ...(urlQuery["shippingMode"] && {
        mode: urlQuery["shippingMode"],
      }),
      ...(urlQuery["shippingDirection"] && {
        shippingDirection: urlQuery["shippingDirection"],
      }),
      ...(urlQuery["loadingMode"] && {
        loadingMode: urlQuery["loadingMode"],
      }),
      ...(urlQuery["status"] && {
        status: urlQuery["status"],
      }),
      ...(urlQuery["destinationPortId"] && {
        destinationPort: urlQuery["destinationPortId"],
      }),
      ...(urlQuery["destinationCountryId"] && {
        destinationCountry: urlQuery["destinationCountryId"],
      }),
      ...(urlQuery["originCountryId"] && {
        originCountry: urlQuery["originCountryId"],
      }),
      ...(urlQuery["originPortId"] && {
        originPort: urlQuery["originPortId"],
      }),
    }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [openDialog, setOpenDialog] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]); // Local state to manage checked IDs
  // const [id, setId] = useState([]); // Local state to manage checked IDs
  const currentIds = listRequests?.map((i) => i.id);
  const isChecked = (id) => {
    return checkedIds.includes(id);
  };
  const fieldSelected = currentIds?.filter((i) =>
    checkedIds.includes(i)
  )?.length; // Count of selected IDs in the current row

  // Determines if "Select All" should be in an indeterminate state
  const indeterminate = () =>
    fieldSelected > 0 && fieldSelected < currentIds?.length;
  // Determines if "Select All" should be fully checked
  const selectAllChecked = () =>
    currentIds?.length > 0 && fieldSelected === currentIds?.length;

  const onCheckAll = (e) => {
    let ids = [];
    if (e.target.checked) {
      const checkedAll = new Set([...checkedIds, ...currentIds]);
      ids = [...checkedAll];
    } else {
      ids = checkedIds.filter((i) => !currentIds.includes(i));
    }
    setCheckedIds(ids);
  };

  const toggleCheck = (e, id) => {
    const checked = e.target.checked;
    let updateCheckedIds = [...checkedIds];
    if (checked) {
      updateCheckedIds.push(id);
    } else {
      updateCheckedIds = updateCheckedIds.filter((i) => i !== id);
    }
    setCheckedIds(updateCheckedIds);
  };

  const resetShippingDate = () => {
    setShippingDate([null, null]);
  };
  const resetFinishDate = () => {
    setFinishDate([null, null]);
  };
  const resetRequestDate = () => {
    setRequestDate([null, null]);
  };
  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: `/admin/packing-requests`,
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    refetch ? setrefetch(false) : setrefetch(true);
    let handledData = {
      code: data.code,
      branchId: data.branchId,
      vendorId: data.vendorId,
      customerId: data.customerId,
      shippingMode: data.shippingMode,
      shippingDirection: data.shippingDirection,
      status: data.status,
      loadingMode: data.loadingMode,
      destinationCountryId: data.destinationCountryId,
      destinationPortId: data.destinationPortId,
      originCountryId: data.originCountryId,
      originPortId: data.originPortId,
      hasPackages: data.hasPackages,
      ...(shippingDate[0] && { fromDate }),
      ...(shippingDate[1] && { toDate }),
      ...(finishDate[0] && { finishFromDate }),
      ...(finishDate[1] && { finishToDate }),
      ...(requestDate[0] && { requestFromDate }),
      ...(requestDate[1] && { requestToDate }),
      page: 0,
    };
    data["hasPackages"] === "" && delete handledData["hasPackages"];
    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
    if (handledData.fromDate || handledData.toDate) {
      const shippingDate = {
        ...(handledData?.fromDate && { fromDate: handledData?.fromDate }),
        ...(handledData?.toDate && { toDate: handledData?.toDate }),
      };
      delete handledData["fromDate"];
      delete handledData["toDate"];
      handledData.shippingDate = shippingDate;
    }
    if (handledData.finishFromDate || handledData.finishToDate) {
      const finishDate = {
        ...(handledData?.finishFromDate && {
          fromDate: handledData?.finishFromDate,
        }),
        ...(handledData?.finishToDate && {
          toDate: handledData?.finishToDate,
        }),
      };
      delete handledData["finishFromDate"];
      delete handledData["finishToDate"];
      handledData.finishDate = finishDate;
    }
    if (handledData.requestFromDate || handledData.requestToDate) {
      const requestDate = {
        ...(handledData?.requestFromDate && {
          fromDate: handledData?.requestFromDate,
        }),
        ...(handledData?.requestToDate && {
          toDate: handledData?.requestToDate,
        }),
      };
      delete handledData["requestFromDate"];
      delete handledData["requestToDate"];
      handledData.requestDate = requestDate;
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
  };
  const receiveInWarehouse = (ids) => {
    // refetch ? setrefetch(false) : setrefetch(true);
    packingRequest({
      variables: {
        input: {
          requestId: [...ids],
        },
      },
    })
      .then((data) => {
        refetchList();
        setCheckedIds([]);
        handleDialogClose();
        // setrefetch(true);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const parseData = (data) => {
    return data;
  };
  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };
  const handelShipmentInWarehouse = (id) => {
    // checkedIds.length >= 2 && setUpdateMultiple(true);
    setCheckedIds(id);
    // setShipmentId(id);
    setOpenDialog(true);
  };
  const icons = [
    {
      id: "filterList",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
    },
    {
      id: "receive",
      action: () => handelShipmentInWarehouse(checkedIds),
      disabled: checkedIds.length === 0,
    },
  ];
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <RootStyleList>
      {openDialog && (
        <CustomDialog
          fullWidth
          maxWidth="xs"
          open={openDialog}
          onClose={handleDialogClose}
          title={t("confirmation")}
          content={
            <DialogContentText id="alert-dialog-description">
              {t("confirmationMsg")}
            </DialogContentText>
          }
          actions={
            <>
              <Button onClick={handleDialogClose}>{t("cancel")}</Button>
              <Button
                onClick={() => {
                  receiveInWarehouse(checkedIds);
                }}
              >
                {packingLoading ? <ButtonLoading /> : t("confirmation")}
              </Button>
            </>
          }
        ></CustomDialog>
      )}

      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={listRequests?.length === 0}
        loading={loading}
        filters={
          <Grid container component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>

              <Grid item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  startText={t("requestDate")}
                  value={requestDate}
                  onChangeValue={(value) => setRequestDate(value)}
                  resetDate={resetRequestDate}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  startText={t("shippingDate")}
                  value={shippingDate}
                  onChangeValue={(value) => setShippingDate(value)}
                  resetDate={resetShippingDate}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  startText={t("finishDate")}
                  value={finishDate}
                  onChangeValue={(value) => setFinishDate(value)}
                  resetDate={resetFinishDate}
                />
              </Grid>

              <Grid item sm={12} alignItems="flex-start">
                <ListBranches
                  control={control}
                  errors={errors}
                  name={"branchId"}
                  defaultValue={autocompleteValues.branch}
                  skipDefaultBranch={true}
                  skip={noData}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MultipleAutocomplete
                  valueKey="code"
                  multiple
                  control={control}
                  errors={errors}
                  name={"shippingMode"}
                  label={t("shippingMode")}
                  parseData={(data) => parseData(data)}
                  variables={{ input: { code: "FR_SHIPPING_MODE" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.mode}
                  skip={noData}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MultipleAutocomplete
                  name={"shippingDirection"}
                  label={t("shippingDirection")}
                  control={control}
                  errors={errors}
                  variables={{ input: { code: "FR_SHIPPING_DIRECTION" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  parseData={(data) => parseData(data)}
                  multiple
                  valueKey="code"
                  defaultValue={autocompleteValues.shippingDirection}
                  skip={noData}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MultipleAutocomplete
                  name={"loadingMode"}
                  label={t("loadingMode")}
                  control={control}
                  errors={errors}
                  variables={{ input: { code: "FR_LOADING_MODE" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  parseData={(data) => parseData(data)}
                  multiple
                  valueKey="code"
                  defaultValue={autocompleteValues.loadingMode}
                  skip={noData}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"originCountryId"}
                  label={t("originCountry")}
                  parseData={(data) => parseData(data)}
                  query={LIST_COUNTRIES_DROPDOWN.query}
                  onChangeValue={(e) => {
                    setValue("originPortId", "");
                  }}
                  defaultValue={autocompleteValues.originCountry}
                  skip={noData}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"originPortId"}
                  label={t("originPort")}
                  parseData={(data) => parseData(data)}
                  query={LIST_SHIPPING_PORTS_DROPDOWN.query}
                  variables={{
                    input: {
                      countryId: watch("originCountryId"),
                    },
                  }}
                  defaultValue={autocompleteValues.originPort}
                  skip={noData || !watch("originCountryId")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"destinationCountryId"}
                  label={t("destinationCountry")}
                  parseData={(data) => parseData(data)}
                  query={LIST_COUNTRIES_DROPDOWN.query}
                  onChangeValue={(e) => {
                    setValue("destinationPortId", "");
                  }}
                  defaultValue={autocompleteValues.destinationCountry}
                  skip={noData}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"destinationPortId"}
                  label={t("destinationPort")}
                  parseData={(data) => parseData(data)}
                  query={LIST_SHIPPING_PORTS_DROPDOWN.query}
                  variables={{
                    input: {
                      countryId: watch("destinationCountryId"),
                    },
                  }}
                  defaultValue={autocompleteValues.destinationPort}
                  skip={noData || !watch("destinationCountryId")}
                />
              </Grid>

              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"vendorId"}
                  label={t("sender")}
                  parseData={(data) => parseData(data)}
                  query={LIST_VENDORS_DROPDOWN.query}
                  defaultValue={autocompleteValues.vendor}
                  skip={noData}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"customerId"}
                  label={t("customer")}
                  parseData={(data) => parseData(data)}
                  query={LIST_CUSTOMERS_DROPDOWN.query}
                  defaultValue={autocompleteValues.customer}
                  skip={noData}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          <TableHead>
            <TableRow>
              <FixedTableCell
                className={clsx(classes.toolbar, classes.checkbox)}
              >
                <Checkbox
                  edge="start"
                  id="check-all-details"
                  indeterminate={indeterminate()}
                  checked={selectAllChecked()}
                  inputProps={{
                    "aria-label": "select all items",
                  }}
                  tabIndex={-1}
                  onChange={(e) => onCheckAll(e)}
                  disableRipple
                />
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("requestCode")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("wareHouse")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("totalPackages")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("branch")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("originPort")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("destinationPort")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("shippingMode")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("shippingDirection")}
              </FixedTableCell>
              <FixedTableCell className={classes.headerTable}>
                {t("loadingMode")}
              </FixedTableCell>
              <FixedTableCell className={classes.last_header_item}>
                {" "}
              </FixedTableCell>
            </TableRow>
          </TableHead>
        }
        tableBody={
          <TableBody>
            {listRequests &&
              listRequests?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <FixedTableCell key={`${index}-che`}>
                      <Checkbox
                        edge="start"
                        checked={isChecked(row?.id)}
                        onChange={(e) => toggleCheck(e, row?.id)}
                        disableRipple
                      />
                    </FixedTableCell>
                    <CellLink pathname={`/admin/requests/${row?.id}`}>
                      {row?.code}
                    </CellLink>
                    <FixedTableCell>
                      {row?.operations[0]?.warehouse?.name}
                    </FixedTableCell>
                    <FixedTableCell>{row?.totalPackages}</FixedTableCell>

                    <CellLink pathname={`/admin/branches/${row?.branch?.id}`}>
                      {row?.branch?.name}
                    </CellLink>
                    <CellLink
                      pathname={`/admin/countries/${row?.originPort?.id}`}
                    >
                      {row?.originPort?.name}
                    </CellLink>
                    <CellLink
                      pathname={`/admin/countries/${row?.destinationPort?.id}`}
                    >
                      {row?.destinationPort?.name}
                    </CellLink>
                    <FixedTableCell>{row.shippingMode?.name}</FixedTableCell>
                    <FixedTableCell>
                      {row?.shippingDirection?.name}
                    </FixedTableCell>
                    <FixedTableCell>{row?.loadingMode?.name}</FixedTableCell>
                    <FixedTableCell
                      // className={classes.shipmentTable_button}
                      className={classes.last_header_item}
                      component="th"
                      scope="row"
                      key={index}
                    >
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        // disabled={!canReceiveShipment}
                        onClick={() => {
                          setCheckedIds([row?.id]);
                          setOpenDialog(true);
                        }}
                        startIcon={<Icon>task_alt</Icon>}
                      >
                        {t("pack")}
                      </Button>
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listRequests?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listRequests ? 0 : search.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </RootStyleList>
  );
};

export default RequestList;
