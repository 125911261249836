import React, { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { Button, Collapse, ListItemText, Paper, Stack } from "@mui/material";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { updateExchangeRatesMutationBuilder } from "./Graphql";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import { Globals } from "../HOC/Classes/Globals";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";

export default function UpdateExchangeRates(props) {
  const { exchangeRate, setRefetchQueries, currency } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState, setError, setValue } = useForm();

  const { t } = useTranslation();
  const { errors } = formState;

  const user = Globals.user;

  const canUpdateExchange = user.hasPermission("cash.exchange_rate.update");
  const arrayOfIds = exchangeRate.map((rate) => rate.id);
  const UPDATE_EXCHANGE_RATES = updateExchangeRatesMutationBuilder(arrayOfIds);

  const [updateExchangeRateMutation, { loading: updateStatusLoad }] =
    useMutation(
      gql`
        ${UPDATE_EXCHANGE_RATES.query}
      `,
      {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
      }
    );

  const onSubmit = (data) => {
    let variables = {};

    for (const id of arrayOfIds) {
      variables["input" + id] = {
        rate: parseFloat(data[`rate_${id}`]),
        updatable: data[`updatable_${id}`],
        id: id,
      };
    }
    updateExchangeRateMutation({
      variables,
    })
      .then((res) => {
        props.onClose();
        setRefetchQueries((prev) => !prev);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        // setValidationError(graphQLErrors, setError);
        const match = graphQLErrors[0].message.match(/exchange_(\d+)/);
        const parts = graphQLErrors[0].extensions?.validation;
        const numberValue = match[1];
        setError(`rate_${numberValue}`, {
          type: "custom",
          message: parts?.["input.rate"][0],
        });
        if (!graphQLErrors[0]["extensions"]?.validation)
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
      });
  };

  useEffect(() => {
    if (exchangeRate) {
      exchangeRate.map((e) => {
        setValue(`updatable_${e.id}`, e.updatable);
        setValue(`id_${e.id}`, e.id);
        setValue(`rate_${e.id}`, e.rate);
        return "";
      });
    }
  }, [exchangeRate, setValue]);

  const body = (
    <Grid container spacing={2}>
      {exchangeRate.map((row, index) => (
        <Grid xs={12} sm={6} key={index}>
          <Paper sx={{ p: 1, m: 0.5 }} elevation={6}>
            <Stack spacing={1}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <ListItemText
                  id={row.currency?.id}
                  primary={row.currency?.name}
                />
                <MuiSwitch
                  edge="end"
                  name={`updatable_${row.id}`}
                  label={t("updatable")}
                  control={control}
                />
              </Stack>
              <Stack>
                <ControlMUItextField
                  rules={{ required: t("fieldIsRequired") }}
                  control={control}
                  errors={errors}
                  variant="outlined"
                  name={`rate_${row.id}`}
                  label={t("exchangeRate")}
                  type="number"
                  disabled={
                    !canUpdateExchange || currency === row.currency?.name
                  }
                />
              </Stack>
            </Stack>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={props.open}
      onClose={props.handleClose}
      title={t("updateExchangeRate")}
      content={body}
      actions={
        <>
          <Button onClick={props.handleClose}>{t("cancel")}</Button>
          <Button onClick={handleSubmit(onSubmit)}>
            {" "}
            {updateStatusLoad ? <ButtonLoading /> : t("save")}
          </Button>
        </>
      }
    ></CustomDialog>
  );
}
