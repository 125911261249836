/* eslint-disable no-useless-concat */
import React from "react";
import { useTranslation } from "react-i18next";
import { Paper, Icon } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { SAFE_GL_ACCOUNT, SAFE_ID } from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import TreasurersTable from "./TreasurersTable";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import CustomTab from "../HOC/FunctionComponents/CustomTab";
import GlAccountTable from "../HOC/CustomComponents/GlAccountTable";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";
const SafeView = (props) => {
  const { t } = useTranslation();
  const currency = Globals?.settings?.localCurrency?.name ?? "";
  const user = Globals.user;
  const safeId = props.match.params.id?.trim();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [valueTab, setValueTab] = React.useState();

  const { loading, data } = useQuery(
    gql`
      ${SAFE_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(safeId) },
    }
  );
  const { loading: glLoading, data: glData } = useQuery(
    gql`
      ${SAFE_GL_ACCOUNT.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: parseInt(safeId),
        first: rowsPerPage,
        page: page + 1,
      },
    }
  );

  const glAccountData = glData?.safe?.glAccount;
  const branchId = data?.safe?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;
  const canEdit = user.hasPermission("cash.safe.update");
  const canShowAccountStatement = user.hasPermission("cash.safe.use_any")
    ? canAccessBranch
    : false;
  // const paymentTypes = data?.safe?.paymentTypes
  //   ?.map((i) => i.name)
  //   .toString()
  //   .replace(/,/g, " , ");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setValueTab(1);
  };

  const handleChangeRowsPerPage = (event) => {
    setValueTab(1);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/safes/${safeId}/edit`),
      permission: canEdit,
    },
  ];

  const tapsArray = [
    {
      tabHead: t("treasurers"),
      panel: <TreasurersTable treasurers={data?.safe?.treasurers} />,
      valueTab: 1,
    },
  ];
  const showAccountStatement = data?.safe?.treasurers.some(
    (treasurer) => treasurer?.user?.username === user?.username
  );
  if (showAccountStatement || canShowAccountStatement) {
    tapsArray.push({
      tabHead: t("accountStatement"),
      panel: (
        <GlAccountTable
          page={page}
          currency={currency}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          tableData={glAccountData?.entryRecords?.data}
          count={glAccountData?.entryRecords?.paginatorInfo?.total}
          data={glAccountData}
          loading={glLoading}
        />
      ),
    });
  }

  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.safe ? (
    <NotFound />
  ) : (
    <>
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>
      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair title={t("code")} value={data?.safe?.code} />
          <KeyValuePair title={t("name")} value={data?.safe?.name} />
          <KeyValuePair
            title={t("active")}
            value={
              data?.safe?.active ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("branch")}
            value={
              data?.safe?.branch ? (
                <SpanLink
                  pathname={`/admin/branches/${data?.safe?.branch?.id}`}
                >
                  {data?.safe?.branch?.name}
                </SpanLink>
              ) : (
                ""
              )
            }
          />
          <KeyValuePair
            title={t("glAccount")}
            value={data?.safe?.glAccount?.name}
          />
        </Paper>
        <CustomTab tapDetails={tapsArray} valueTab={valueTab} />
      </RootStyleView>
    </>
  );
};

export default SafeView;
